.world{
  .gallery{
    &-img{
      position: relative;

      span{
        width: 100%;
        height: 100%;
        display: none;
        background-color: rgba(0, 0, 0, .3);
        @include poa(0, auto, auto, 0);

        img{
          width: auto;
          @include poa-center;
        }
      }
    }

    &-wrap{
      li{
        &:hover{
          .gallery-img span{display: inline-block;}
        }
      }
    }
  }
}