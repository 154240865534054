.cn{
    font-family: 'Noto Sans SC';
    word-wrap: break-word;

    .header{
        display: block !important;
        top: 0;
    }

    .side-area{
        height: 100vh;
        display: block!important;
        top: 0;
    }

    .section{
        &0{
            height: 100vh;

            @media (max-width: 991px) {
                margin-top: 115px;
                height: auto!important;
            }

            @media (max-width: 767px) {
                margin-top: 50px;
            }
        }

        &1{
            // height: auto;
            background-color: #fbf9f2;
        }

        &2{
            padding-bottom: 0;
            background-color: #fff;
        }
    }

    .footer{
        &__top{
            padding-bottom: 0;
            border-bottom: none;
        }

        &__link{
            margin-top: 0;
            margin-bottom: 10px;
            text-align: left;

            li{
                @media (max-width: 991px) {
                    margin-right: 0;
                }

                &:after{
                    bottom: auto;
                }
            }
        }
    }

    .one-depth{
        word-break: break-all;
    }
}