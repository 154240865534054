@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&family=Noto+Sans+SC:wght@100;300;400;500;700;900&display=swap");
@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard.css");
@import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSansNeo.css);
main,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section {
  display: block;
}

audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
}

audio:not([controls]) {
  display: none;
}

[hidden] {
  display: none;
}

html {
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

body {
  margin: 0;
  word-break: keep-all;
}

body.stop {
  overflow: hidden;
}

button,
input,
select,
textarea {
  font-family: inherit;
}

a {
  display: inline-block;
  color: inherit;
  text-decoration: none;
}

a:focus {
  outline: 0;
}

a:hover,
a:active {
  outline: 0;
}

b,
strong {
  font-weight: bold;
}

blockquote {
  margin: 1em 40px;
}

dfn {
  font-style: italic;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  padding: 0;
}

ins {
  text-decoration: underline;
}

del {
  text-decoration: line-through;
}

mark {
  background: #ff0;
  font-style: italic;
}

pre,
code,
kbd,
samp {
  font-family: monospace;
  _font-family: monospace;
  font-size: 1em;
}

pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
}

p {
  margin: 0;
}

q {
  quotes: none;
}

q:before,
q:after {
  content: "";
  content: none;
}

small {
  font-size: 85%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

ul,
ol {
  list-style: none;
  list-style-image: none;
  margin: 0;
  padding: 0;
}

dl,
dt,
dd {
  margin: 0;
}

img {
  max-width: 100%;
  border: 0;
  -ms-interpolation-mode: bicubic;
  vertical-align: top;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 0;
}

form {
  margin: 0;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  display: none;
  border: 0;
  *margin-left: -7px;
  padding: 0;
}

label {
  cursor: pointer;
}

button {
  padding: 0;
  outline: 0;
  border: none;
  background: transparent;
}

button,
input,
select,
textarea {
  font-size: 100%;
  font-family: inherit;
  margin: 0;
  /*vertical-align: baseline;*/
  vertical-align: middle;
  border-radius: 0;
  background-image: -webkit-gradient(linear, 0% 0%, 0% 100%, from(rgba(255, 255, 255, 0)), to(rgba(255, 255, 255, 0)));
  background-image: -webkit-linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0));
}

button,
input {
  line-height: normal;
  *overflow: visible;
}

table button,
table input {
  *overflow: auto;
}

button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  cursor: pointer;
  -webkit-appearance: button;
}

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
}

input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box;
}

input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical;
}

input:invalid,
textarea:invalid {
  border-color: #C82E2D;
}

button[disabled],
input[disabled] {
  cursor: default;
}

input::-ms-clear {
  display: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td {
  vertical-align: top;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
  margin: 0;
}

address,
em,
i {
  font-style: normal;
}

caption {
  position: absolute;
  top: 0;
  left: -9999px;
  height: 0 !important;
  width: 0 !important;
  overflow: hidden;
  font-size: 0;
}

/*
-----------*/
.in_length30 {
  width: 30px;
}

.in_length40 {
  width: 40px;
}

.in_length50 {
  width: 50px;
}

.in_length60 {
  width: 60px;
}

.in_length70 {
  width: 70px;
}

.in_length80 {
  width: 80px;
}

.in_length90 {
  width: 90px;
}

.in_length100 {
  width: 100px;
}

.in_length110 {
  width: 110px;
}

.in_length120 {
  width: 120px;
}

.in_length130 {
  width: 130px;
}

.in_length140 {
  width: 140px;
}

.in_length150 {
  width: 150px;
}

.in_length160 {
  width: 160px;
}

.in_length170 {
  width: 170px;
}

.in_length180 {
  width: 180px;
}

.in_length190 {
  width: 190px;
}

.in_length200 {
  width: 200px;
}

.in_length250 {
  width: 250px;
}

.in_length300 {
  width: 300px;
}

.in_length350 {
  width: 350px;
}

.in_length400 {
  width: 400px;
}

.in_length450 {
  width: 450px;
}

.in_length500 {
  width: 500px;
}

.in_length600 {
  width: 600px;
}

.in_length98pro {
  width: 98%;
}

.float_wrap:after {
  content: ' ';
  clear: both;
  display: block;
  visibility: hidden;
}

* + html .float_wrap {
  display: inline-block;
}

* html .float_wrap {
  height: 1%;
}

.float-wrap:after {
  content: ' ';
  clear: both;
  visibility: hidden;
  display: block;
}

* + html .float-wrap {
  display: inline-block;
}

.align_right {
  text-align: right;
}

.align_left {
  text-align: left;
}

.align_center {
  text-align: center;
}

.hidden_word {
  position: absolute;
  top: 0;
  left: -9999px;
  text-indent: -9999px;
  display: block;
  font-size: 0;
}

.mgn_b5 {
  margin-bottom: 5px;
}

.mgn_b8 {
  margin-bottom: 8px;
}

.mgn_b10 {
  margin-bottom: 10px;
}

.mgn_b15 {
  margin-bottom: 15px;
}

.mgn_b18 {
  margin-bottom: 18px;
}

.mgn_b20 {
  margin-bottom: 20px;
}

.mgn_b25 {
  margin-bottom: 25px;
}

.mgn_b30 {
  margin-bottom: 30px;
}

.mgn_b40 {
  margin-bottom: 40px;
}

.mgn_b50 {
  margin-bottom: 50px;
}

.mgn_t5 {
  margin-top: 5px;
}

.mgn_t8 {
  margin-top: 8px;
}

.mgn_t10 {
  margin-top: 10px;
}

.mgn_t15 {
  margin-top: 15px;
}

.mgn_t18 {
  margin-top: 18px;
}

.mgn_t20 {
  margin-top: 20px;
}

.mgn_t25 {
  margin-top: 25px;
}

.mgn_t30 {
  margin-top: 30px;
}

.mgn_t40 {
  margin-top: 40px;
}

.mgn_t50 {
  margin-top: 50px;
}

.mgn_t70 {
  margin-top: 70px;
}

.mgn_t80 {
  margin-top: 80px;
}

.mgn_t105 {
  margin-top: 105px;
}

.mgn_l10 {
  margin-left: 10px;
}

.mgn_l15 {
  margin-left: 15px;
}

.mgn_l20 {
  margin-left: 20px;
}

.mgn_l30 {
  margin-left: 30px;
}

.mgn_r10 {
  margin-right: 10px;
}

.mgn_r20 {
  margin-right: 20px;
}

.mgn_r30 {
  margin-right: 30px;
}

.mgn_r40 {
  margin-right: 40px;
}

.left_10 {
  padding-left: 10px;
}

.left_15 {
  padding-left: 15px;
}

.left_20 {
  padding-left: 20px;
}

.left_30 {
  padding-left: 30px;
}

.left_40 {
  padding-left: 40px;
}

.left_50 {
  padding-left: 50px;
}

.left_100 {
  padding-left: 100px;
}

.left_150 {
  padding-left: 150px;
}

.right_10 {
  padding-right: 10px;
}

.right_20 {
  padding-right: 20px;
}

.right_30 {
  padding-right: 30px;
}

.right_50 {
  padding-right: 50px;
}

.right_100 {
  padding-right: 100px;
}

.right_150 {
  padding-right: 150px;
}

.col_brown {
  color: #996630;
}

.line_14 {
  line-height: 1.4;
}

.line_16 {
  line-height: 1.6;
}

.line_18 {
  line-height: 1.8;
}

.col_red {
  color: #8b1b2b;
}

.col_gold {
  color: #CD883D;
}

.col_black {
  color: #000000;
}

.col_org {
  color: #f05914;
}

.col_gray {
  color: #818283;
}

.col_black {
  color: #000;
}

.col_pul {
  color: #3c5e80;
}

.col_blue {
  color: #21539b;
}

.col_d_green {
  color: #0ba3ac;
}

.col_l_green {
  color: #5d9b21;
}

.col_gold {
  color: #999900;
}

.col_yellow {
  color: #fff585;
}

.font_bold {
  font-weight: bold;
}

.font_11 {
  font-size: 11px;
  font-size: 1.1rem;
}

.font_12 {
  font-size: 12px;
  font-size: 1.2rem;
}

.font_13 {
  font-size: 13px;
  font-size: 1.3rem;
}

.font_14 {
  font-size: 14px;
  font-size: 1.4rem;
}

.font_15 {
  font-size: 15px;
  font-size: 1.5rem;
}

.font_16 {
  font-size: 16px;
  font-size: 1.6rem;
}

.font_17 {
  font-size: 17px;
  font-size: 1.7rem;
}

.font_18 {
  font-size: 18px;
  font-size: 1.8rem;
}

.font_20 {
  font-size: 20px;
  font-size: 2.0rem;
}

.font_22 {
  font-size: 22px;
  font-size: 2.2rem;
}

.chk_box {
  height: 13px;
  margin: 0;
}

.chk_box, label {
  vertical-align: middle;
}

@font-face {
  font-family: 'GmarketSans';
  src: url("/template/resources/fonts/GmarketSansTTFLight.woff") format("woff");
  src: url("/template/resources/fonts/GmarketSansTTFLight.eot?#iefix") format("embedded-opentype"), url("/template/resources/fonts/GmarketSansTTFLight.woff") format("woff"), url("/template/resources/fonts/GmarketSansTTFLight.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'GmarketSans';
  src: url("/template/resources/fonts/GmarketSansTTFMedium.woff") format("woff");
  src: url("/template/resources/fonts/GmarketSansTTFMedium.eot?#iefix") format("embedded-opentype"), url("/template/resources/fonts/GmarketSansTTFMedium.woff") format("woff"), url("/template/resources/fonts/GmarketSansTTFMedium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'GmarketSans';
  src: url("/template/resources/fonts/GmarketSansTTFBold.woff") format("woff");
  src: url("/template/resources/fonts/GmarketSansTTFBold.eot?#iefix") format("embedded-opentype"), url("/template/resources/fonts/GmarketSansTTFBold.woff") format("woff"), url("/template/resources/fonts/GmarketSansTTFBold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'mkgd';
  src: url("/template/resources/fonts/mkgd.woff") format("woff");
  src: url("/template/resources/fonts/mkgd.eot?#iefix") format("embedded-opentype"), url("/template/resources/fonts/mkgd.woff") format("woff"), url("/template/resources/fonts/mkgd.ttf") format("truetype");
  font-style: normal;
}

@font-face {
  font-family: 'NanumBarunGothic';
  src: url("/template/resources/fonts/NanumBarunGothicLight.woff") format("woff");
  src: url("/template/resources/fonts/NanumBarunGothicLight.eot?#iefix") format("embedded-opentype"), url("/template/resources/fonts/NanumBarunGothicLight.woff") format("woff"), url("/template/resources/fonts/NanumBarunGothicLight.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'NanumBarunGothic';
  src: url("/template/resources/fonts/NanumBarunGothic.woff") format("woff");
  src: url("/template/resources/fonts/NanumBarunGothic.eot?#iefix") format("embedded-opentype"), url("/template/resources/fonts/NanumBarunGothic.woff") format("woff"), url("/template/resources/fonts/NanumBarunGothic.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'NanumBarunGothic';
  src: url("/template/resources/fonts/NanumBarunGothicBold.woff") format("woff");
  src: url("/template/resources/fonts/NanumBarunGothicBold.eot?#iefix") format("embedded-opentype"), url("/template/resources/fonts/NanumBarunGothicBold.woff") format("woff"), url("/template/resources/fonts/NanumBarunGothicBold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'NanumMyeongjo';
  src: url("/template/resources/fonts/NanumMyeongjo.woff") format("woff");
  src: url("/template/resources/fonts/NanumMyeongjo.eot?#iefix") format("embedded-opentype"), url("/template/resources/fonts/NanumMyeongjo.woff") format("woff"), url("/template/resources/fonts/NanumMyeongjo.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'NanumMyeongjo';
  src: url("/template/resources/fonts/NanumMyeongjoBold.woff") format("woff");
  src: url("/template/resources/fonts/NanumMyeongjoBold.eot?#iefix") format("embedded-opentype"), url("/template/resources/fonts/NanumMyeongjoBold.woff") format("woff"), url("/template/resources/fonts/NanumMyeongjoBold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'NanumMyeongjo';
  src: url("/template/resources/fonts/NanumMyeongjoExtraBold.woff") format("woff");
  src: url("/template/resources/fonts/NanumMyeongjoExtraBold.eot?#iefix") format("embedded-opentype"), url("/template/resources/fonts/NanumMyeongjoExtraBold.woff") format("woff"), url("/template/resources/fonts/NanumMyeongjoExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'HakgyoansimGaeulsopung';
  src: url("/resources/fonts/HakgyoansimGaeulsopungL.woff") format("woff");
  src: url("/resources/fonts/HakgyoansimGaeulsopungL.eot?#iefix") format("embedded-opentype"), url("/resources/fonts/HakgyoansimGaeulsopungL.woff") format("woff"), url("/resources/fonts/HakgyoansimGaeulsopungL.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'HakgyoansimGaeulsopung';
  src: url("/resources/fonts/HakgyoansimGaeulsopungB.woff") format("woff");
  src: url("/resources/fonts/HakgyoansimGaeulsopungB.eot?#iefix") format("embedded-opentype"), url("/resources/fonts/HakgyoansimGaeulsopungB.woff") format("woff"), url("/resources/fonts/HakgyoansimGaeulsopungB.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

* {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  font-family: 'Noto Sans KR';
}

html.websize-1 {
  font-size: 62.5%;
}

html.websize-2 {
  font-size: 68.5%;
}

html.websize-3 {
  font-size: 72.5%;
}

html.websize-4 {
  font-size: 78.5%;
}

html.websize-5 {
  font-size: 82.5%;
}

.float-wrap:after {
  content: ' ';
  clear: both;
  visibility: hidden;
  display: block;
}

* + html .float-wrap {
  display: inline-block;
}

.float_wrap:after {
  content: ' ';
  clear: both;
  visibility: hidden;
  display: block;
}

* + html .float_wrap {
  display: inline-block;
}

/**/
.size-realtime {
  display: none;
  font-size: 12px;
  padding-left: 0 !important;
}

html.websize-1 .size-realtime.s1 {
  display: inline-block;
}

html.websize-2 .size-realtime.s2 {
  display: inline-block;
}

html.websize-3 .size-realtime.s3 {
  display: inline-block;
}

html.websize-4 .size-realtime.s4 {
  display: inline-block;
}

html.websize-5 .size-realtime.s5 {
  display: inline-block;
}

* {
  margin: 0;
  padding: 0;
}

*:focus {
  outline: 2px dashed #9c7e29 !important;
}

ul {
  font-size: 0;
  list-style: none;
}

img {
  max-width: 100%;
  display: inline-block;
}

a {
  display: inline-block;
}

.hidden-text {
  display: none;
}

.visible-sm {
  display: none !important;
}

.visible-sm-dib {
  display: none !important;
}

.visible-xs {
  display: none !important;
}

.visible-xs-ib {
  display: none !important;
}

input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
}

input[type="radio"], input[type="checkbox"] {
  opacity: 0;
  position: absolute;
  left: -9999px;
}

input[type="radio"]:focus + label, input[type="checkbox"]:focus + label {
  outline: 2px dashed #9c7e29 !important;
}

.main-container {
  width: 100%;
  margin: 0 auto;
  padding: 0 100px;
  font-size: 0;
}

.main-container .wrap {
  width: 100%;
}

.container {
  width: 1600px;
  margin: 0 auto;
  font-size: 0;
}

.container .wrap {
  width: 100%;
}

@media (max-width: 1720px) {
  .container {
    width: 1400px;
  }
}

@media (max-width: 1520px) {
  .container {
    width: 1200px;
  }
}

@media (max-width: 1320px) {
  .container {
    width: 1000px;
  }
}

@media (max-width: 1260px) {
  .main-container {
    padding: 0 50px;
  }
}

@media (max-width: 1200px) {
  .container {
    width: 850px;
  }
}

@media (max-width: 991px) {
  .main-container {
    padding: 0 30px;
  }
  .container {
    width: 100%;
    padding: 0 30px;
  }
  .visible-sm {
    display: block !important;
  }
  .visible-sm-dib {
    display: inline-block !important;
  }
  .hidden-sm {
    display: none !important;
  }
}

@media (max-width: 767px) {
  .main-container {
    padding: 0 15px;
  }
  .container {
    padding: 0 15px;
  }
  .visible-xs {
    display: block !important;
  }
  .visible-xs-ib {
    display: inline-block !important;
  }
  .hidden-xs {
    display: none !important;
  }
}

@media (max-width: 320px) {
  .container {
    padding: 0 15px;
  }
}

.sound-only {
  width: 0 !important;
  height: 0 !important;
  opacity: 0 !important;
  overflow: hidden !important;
  position: absolute !important;
}

.btn-css {
  font-size: 0;
}

/* 다음맵 접근성 추가 .warp_map으로 감쌈 */
.wrap_map a:focus,
.wrap_map button:focus,
.roughmap_maker_label a:focus .roughmap_lebel_text,
.root_daum_roughmap .wrap_btn_zoom button:focus {
  border: 2px dashed #9c7e29;
}

.wrap_controllers.hide {
  display: none;
}

@keyframes progress_bar {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Slider */
/* Icons */
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}

.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}

.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}

[dir="rtl"] .slick-prev {
  left: auto;
  right: -25px;
}

.slick-prev:before {
  content: "←";
}

[dir="rtl"] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}

[dir="rtl"] .slick-next {
  left: -25px;
  right: auto;
}

.slick-next:before {
  content: "→";
}

[dir="rtl"] .slick-next:before {
  content: "←";
}

/* Dots */
.slick-dots {
  list-style: none;
  display: inline-block;
  text-align: center;
  padding: 0;
  margin: 0;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  cursor: pointer;
}

.skip {
  padding: 10px 15px;
  background-color: #000;
  text-align: center;
  position: fixed;
  top: -44px;
  right: auto;
  bottom: auto;
  left: 50%;
  transform: translateX(-50%);
  z-index: 22;
}

.skip.focus {
  top: 0;
}

.skip a {
  font-size: 1.6rem;
  font-weight: 500;
  color: #fff;
  vertical-align: middle;
}

.skip a:first-child {
  margin-right: 30px;
}

@media (max-width: 767px) {
  .skip a:first-child {
    margin-right: 0;
    margin-bottom: 15px;
  }
}

@media (max-width: 767px) {
  .skip {
    width: 100%;
    position: fixed;
    top: -77px;
    right: auto;
    bottom: auto;
    left: 50%;
  }
}

.prev_page_btn {
  width: 100%;
  height: 25px;
  display: block;
  display: none;
  background-color: #003554;
  position: relative;
}

@media (max-width: 991px) {
  .prev_page_btn {
    display: none;
  }
}

.prev_page_btn img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.header {
  width: calc(100% - 100px);
  height: 115px;
  font-family: 'GmarketSans';
  position: fixed;
  top: 0;
  right: auto;
  bottom: auto;
  left: 100px;
  background-color: #fff;
  z-index: 3;
  overflow: hidden;
}

@media (max-width: 991px) {
  .header {
    top: 0;
    background-color: #fff;
    overflow: unset;
  }
}

@media (max-width: 767px) {
  .header {
    width: calc(100% - 50px);
    height: 50px;
    border-bottom: 1px solid #ddd;
    left: 50px;
  }
}

.header:before {
  content: '';
  width: 100%;
  height: 1px;
  display: none;
  background-color: #ddd;
  position: absolute;
  top: 115px;
  right: auto;
  bottom: auto;
  left: 0;
  z-index: 1;
}

.header .gnb-bg {
  width: 100%;
  height: 0px;
  border-bottom: 1px solid #ddd;
  display: block;
  transition-duration: .3s;
}

@media (max-width: 991px) {
  .header .gnb-bg {
    display: none;
  }
}

.header.n-first {
  top: 0;
  transition-duration: .3s;
}

.header.n-first .header-top {
  display: none;
}

.header.hover {
  height: auto;
  background-color: #fff;
  transition-duration: .3s;
}

.header.hover:before {
  display: block;
}

.header-bottom {
  width: 100%;
  padding: 0 100px;
  font-size: 0;
}

@media (max-width: 1260px) {
  .header-bottom {
    padding: 0 50px;
  }
}

@media (max-width: 767px) {
  .header-bottom {
    width: 100%;
    padding: 0 15px;
  }
}

.header-bottom__logo {
  width: 200px;
  height: 115px;
  display: inline-block;
  vertical-align: top;
  position: relative;
}

@media (max-width: 1420px) {
  .header-bottom__logo {
    width: 160px;
  }
}

@media (max-width: 767px) {
  .header-bottom__logo {
    width: calc(100% - 60px);
    height: 50px;
  }
}

.header-bottom__logo a {
  position: absolute;
  top: 50%;
  left: 0;
  right: auto;
  transform: translateY(-50%);
}

@media (max-width: 767px) {
  .header-bottom__logo a {
    width: 120px;
    left: auto;
    right: 0;
  }
}

.header-bottom__gnb {
  width: calc(100% - 430px);
  padding: 0 30px;
  display: inline-block;
  vertical-align: top;
}

@media (max-width: 1420px) {
  .header-bottom__gnb {
    width: calc(100% - 340px);
  }
}

@media (max-width: 1260px) {
  .header-bottom__gnb {
    width: calc(100% - 280px);
    padding: 0 15px;
  }
}

@media (max-width: 767px) {
  .header-bottom__gnb {
    display: none;
  }
}

.header-bottom__lang {
  width: 115px;
  height: 115px;
  display: inline-block;
  vertical-align: top;
  text-align: right;
  position: relative;
}

@media (max-width: 1420px) {
  .header-bottom__lang {
    width: 90px;
  }
}

@media (max-width: 1260px) {
  .header-bottom__lang {
    width: 60px;
  }
}

@media (max-width: 767px) {
  .header-bottom__lang {
    height: 50px;
    display: none;
  }
}

.header-bottom__lang.m-lang {
  display: none;
}

@media (max-width: 767px) {
  .header-bottom__lang.m-lang {
    display: inline-block;
  }
}

.header-bottom__lang > a {
  padding-right: 20px;
  font-size: 1.4rem;
  font-weight: 700;
  color: #222;
  position: absolute;
  top: 50%;
  left: auto;
  right: 40px;
  transform: translateY(-50%);
}

.header-bottom__lang > a:after {
  content: '';
  width: 8px;
  height: 8px;
  border-top: 2px solid #000;
  border-right: 2px solid #000;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: auto;
  right: 0;
  transform: translateY(-50%);
  transform: translateY(-70%) rotate(135deg);
  box-sizing: border-box;
}

@media (max-width: 1320px) {
  .header-bottom__lang > a {
    font-size: 1.2rem;
  }
}

@media (max-width: 1260px) {
  .header-bottom__lang > a {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 0;
    right: auto;
    transform: translateY(-50%);
  }
}

.header-bottom__lang__list {
  height: 0;
  border-radius: 5px;
  display: none;
  background-color: #fff;
  position: absolute;
  top: 80px;
  right: 40px;
  bottom: auto;
  left: auto;
  overflow: hidden;
  z-index: 1;
}

@media (max-width: 1260px) {
  .header-bottom__lang__list {
    left: 0;
    right: auto;
  }
}

@media (max-width: 767px) {
  .header-bottom__lang__list {
    top: 50px;
  }
}

.header-bottom__lang__list .active {
  border: 1px solid #ddd;
}

.header-bottom__lang__list li {
  padding: 3px 10px;
}

.header-bottom__lang__list li:first-child {
  padding: 10px 10px 3px 10px;
}

.header-bottom__lang__list li:last-child {
  padding: 3px 10px 10px 10px;
}

.header-bottom__lang__list a {
  font-size: 1.4rem;
  font-weight: 700;
  color: #222;
}

@media (max-width: 1320px) {
  .header-bottom__lang__list a {
    font-size: 1.2rem;
  }
}

.header-bottom__link {
  width: 115px;
  height: 115px;
  display: inline-block;
  vertical-align: top;
}

@media (max-width: 1420px) {
  .header-bottom__link {
    width: 90px;
  }
}

@media (max-width: 1260px) {
  .header-bottom__link {
    width: 60px;
  }
}

@media (max-width: 767px) {
  .header-bottom__link {
    display: none;
  }
}

.header-bottom__link a {
  width: 100%;
  height: 100%;
  background-image: url("/template/resources/images/cont/q-link_bg.png");
  background-size: cover;
  position: relative;
}

.header-bottom__link a div {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.header-bottom__link a p {
  margin-bottom: 5px;
  font-size: 1.8rem;
  font-weight: 700;
  color: #fff;
  line-height: 2.3rem;
}

@media (max-width: 1260px) {
  .header-bottom__link a p {
    font-size: 1.6rem;
  }
}

.header__all-menu {
  width: calc(100% - 100px);
  height: 100vh;
  display: inline-block;
  background-color: #162038;
  position: fixed;
  top: 0;
  right: auto;
  bottom: auto;
  left: 100px;
  z-index: 3;
}

@media (max-width: 767px) {
  .header__all-menu {
    width: calc(100% - 50px);
    left: 50px;
  }
}

.side-area {
  width: 100px;
  height: calc(100vh);
  border-right: 1px solid #ddd;
  display: inline-block !important;
  position: fixed;
  top: 0;
  right: auto;
  bottom: auto;
  left: 0;
  background-color: #fff;
  z-index: 3;
}

@media (max-width: 991px) {
  .side-area {
    height: 100vh;
    top: 0;
    display: inline-block !important;
  }
}

@media (max-width: 767px) {
  .side-area {
    width: 50px;
    height: 50px;
    border-right: none;
    border-bottom: 1px solid #ddd;
    top: 0;
  }
}

.side-area.all-menu {
  height: 100vh !important;
  top: 0;
  transition-duration: .3s;
}

@media (max-width: 767px) {
  .side-area.all-menu {
    transition-duration: 0s;
  }
}

.side-area.all-menu ul {
  display: inline-block;
}

.side-area.n-first {
  height: 100vh;
  top: 0;
  transition-duration: .3s;
}

@media (max-width: 767px) {
  .side-area.n-first {
    width: 50px;
    height: 50px;
    border-right: none;
    border-bottom: 1px solid #ddd;
    top: 0;
  }
}

.side-area > div {
  width: 100px;
  height: 115px;
  position: relative;
}

@media (max-width: 767px) {
  .side-area > div {
    width: 50px;
    height: 50px;
  }
}

.side-area .all-btn {
  width: 24px;
  height: 24px;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.side-area .all-btn span {
  width: 6px;
  height: 6px;
  display: inline-block;
  background-color: #000;
  position: absolute;
  transition-duration: .3s;
}

.side-area .all-btn span:nth-child(1) {
  top: 0;
  left: 0;
}

.side-area .all-btn span:nth-child(2) {
  top: 0;
  left: 9px;
}

.side-area .all-btn span:nth-child(3) {
  top: 0;
  left: 18px;
}

.side-area .all-btn span:nth-child(4) {
  top: 9px;
  left: 0;
}

.side-area .all-btn span:nth-child(5) {
  top: 9px;
  left: 9px;
}

.side-area .all-btn span:nth-child(6) {
  top: 9px;
  left: 18px;
}

.side-area .all-btn span:nth-child(7) {
  top: 18px;
  left: 0;
}

.side-area .all-btn span:nth-child(8) {
  top: 18px;
  left: 9px;
}

.side-area .all-btn span:nth-child(9) {
  top: 18px;
  left: 18px;
}

.side-area .all-btn.active span:nth-child(2) {
  top: 9px;
  left: 9px;
}

.side-area .all-btn.active span:nth-child(4) {
  top: 9px;
  left: 9px;
}

.side-area .all-btn.active span:nth-child(6) {
  top: 9px;
  left: 9px;
}

.side-area .all-btn.active span:nth-child(8) {
  top: 9px;
  left: 9px;
}

.side-area ul {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (max-width: 767px) {
  .side-area ul {
    display: none;
  }
}

.side-area li {
  margin: 15px 0;
  text-align: center;
}

.nav-bg {
  width: 100%;
  height: 100vh;
  display: none;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  right: auto;
  bottom: auto;
  left: 0;
  z-index: 1;
}

.nav-bg.visible {
  display: block;
}

.z-index {
  z-index: 10;
}

.header-bottom__gnb {
  word-break: break-all;
}

@media (max-width: 991px) {
  .header-bottom__gnb .one-depth {
    display: none;
  }
}

.header-bottom__gnb .one-depth__list {
  display: inline-block;
  vertical-align: top;
}

.header-bottom__gnb .one-depth__list .two-depth:first-child {
  border-left: 1px solid #ddd;
}

.header-bottom__gnb .one-depth__list:hover > a p {
  color: #9c7e29;
}

.header-bottom__gnb .one-depth__list:hover .two-depth {
  background-color: #fafafa;
  border-bottom: 1px solid #ddd;
}

.header-bottom__gnb .one-depth__list:hover .two-depth__tit {
  color: #9c7e29;
}

.header-bottom__gnb .one-depth__list > a {
  padding: 0 30px;
  font-size: 2.4rem;
  font-weight: 700;
  color: #222;
  line-height: 115px;
}

@media (max-width: 1800px) {
  .header-bottom__gnb .one-depth__list > a {
    font-size: 2.2rem;
    padding: 0 15px;
  }
}

@media (max-width: 1600px) {
  .header-bottom__gnb .one-depth__list > a {
    font-size: 1.8rem;
  }
}

@media (max-width: 1460px) {
  .header-bottom__gnb .one-depth__list > a {
    font-size: 1.6rem;
  }
}

@media (max-width: 1340px) {
  .header-bottom__gnb .one-depth__list > a {
    padding: 0 10px;
    font-size: 1.4rem;
  }
}

@media (max-width: 1150px) {
  .header-bottom__gnb .one-depth__list > a {
    padding: 0 5px;
  }
}

.header-bottom__gnb .two-depth {
  padding: 40px;
  border-right: 1px solid #ddd;
  position: absolute;
  top: 115px;
  right: auto;
  bottom: auto;
  left: 0;
}

@media (max-width: 1320px) {
  .header-bottom__gnb .two-depth {
    padding: 30px;
  }
}

@media (max-width: 1260px) {
  .header-bottom__gnb .two-depth {
    padding: 20px;
  }
}

@media (max-width: 1150px) {
  .header-bottom__gnb .two-depth {
    padding: 20px 10px;
  }
}

.header-bottom__gnb .two-depth:first-child {
  border-left: 1px solid #ddd;
}

.header-bottom__gnb .two-depth__list {
  margin-bottom: 20px;
}

@media (max-width: 1420px) {
  .header-bottom__gnb .two-depth__list {
    margin-bottom: 10px;
  }
}

.header-bottom__gnb .two-depth__list:last-child {
  margin-bottom: 0;
}

.header-bottom__gnb .two-depth__list > a {
  font-size: 1.6rem;
  font-weight: 500;
  color: #222;
}

@media (max-width: 1420px) {
  .header-bottom__gnb .two-depth__list > a {
    font-size: 1.4rem;
  }
}

.header-bottom__gnb .two-depth__list > a:hover {
  color: #222;
  text-decoration: underline;
}

.header-bottom__gnb .two-depth__tit {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ddd;
  font-size: 1.8rem;
  font-weight: 700;
  color: #222;
}

@media (max-width: 1420px) {
  .header-bottom__gnb .two-depth__tit {
    margin-bottom: 10px;
    padding-bottom: 10px;
    font-size: 1.8rem;
  }
}

@media (max-width: 1260px) {
  .header-bottom__gnb .two-depth__tit {
    font-size: 1.6rem;
  }
}

.header-bottom__gnb .three-depth {
  padding-top: 10px;
}

.header-bottom__gnb .three-depth__list {
  margin-bottom: 10px;
}

.header-bottom__gnb .three-depth__list:last-child {
  margin-bottom: 0;
}

.header-bottom__gnb .three-depth__list a {
  padding-left: 12px;
  font-size: 1.4rem;
  font-weight: 500;
  color: #999;
  position: relative;
}

.header-bottom__gnb .three-depth__list a:before {
  content: '';
  width: 3px;
  height: 3px;
  display: inline-block;
  background-color: #999;
  position: absolute;
  top: 7px;
  right: auto;
  bottom: auto;
  left: 0;
}

.header-bottom__gnb .three-depth__list a:hover {
  color: #222;
  text-decoration: underline;
}

.header:not(.n-first) .header-bottom__lang a {
  color: #fff;
}

@media (max-width: 991px) {
  .header:not(.n-first) .header-bottom__lang a {
    color: #222;
  }
}

.header:not(.n-first) .header-bottom__lang a:after {
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
}

@media (max-width: 991px) {
  .header:not(.n-first) .header-bottom__lang a:after {
    border-top: 2px solid #222;
    border-right: 2px solid #222;
  }
}

.header.hover .header-bottom__gnb .one-depth__list > a {
  color: #222;
}

.header.hover .header-bottom__lang a {
  color: #222;
}

.header.hover .header-bottom__lang a:after {
  border-top: 2px solid #222;
  border-right: 2px solid #222;
}

.header__all-menu {
  padding: 0 100px;
  display: none;
}

@media (max-width: 991px) {
  .header__all-menu {
    padding: 0 50px;
  }
}

@media (max-width: 767px) {
  .header__all-menu {
    padding: 0 15px;
  }
}

.header__all-menu.visible {
  display: block;
}

.header__all-menu__logo {
  height: 115px;
  margin-bottom: 40px;
  position: relative;
}

@media (max-width: 991px) {
  .header__all-menu__logo {
    margin-bottom: 20px;
  }
}

@media (max-width: 767px) {
  .header__all-menu__logo {
    width: 100%;
    height: 50px;
    margin-bottom: 10px;
  }
}

.header__all-menu__logo img {
  width: 200px;
  position: absolute;
  top: 50%;
  left: 0;
  right: auto;
  transform: translateY(-50%);
}

@media (max-width: 767px) {
  .header__all-menu__logo img {
    width: 120px;
    position: absolute;
    top: 50%;
    left: auto;
    right: 0;
    transform: translateY(-50%);
  }
}

.header__all-menu .one-depth {
  max-height: calc(100% - 155px);
  padding: 3px 3px 30px;
  overflow-y: auto;
}

@media (max-width: 991px) {
  .header__all-menu .one-depth {
    max-height: calc(100vh - 135px);
  }
}

@media (max-width: 767px) {
  .header__all-menu .one-depth {
    height: calc(100vh - 50px);
    overflow-y: auto;
  }
}

.header__all-menu .one-depth__list {
  width: calc((100% - 150px) / 6);
  margin-right: 30px;
  display: inline-block;
  vertical-align: top;
}

@media (max-width: 1260px) {
  .header__all-menu .one-depth__list {
    width: calc((100% - 75px) / 6);
    margin-right: 15px;
  }
}

@media (max-width: 991px) {
  .header__all-menu .one-depth__list {
    width: calc((100% - 30px) / 3);
    margin-bottom: 30px;
  }
  .header__all-menu .one-depth__list:nth-child(3n) {
    margin-right: 0;
  }
}

@media (max-width: 767px) {
  .header__all-menu .one-depth__list {
    width: 100%;
    margin-right: 0;
    margin-bottom: 30px;
  }
}

.header__all-menu .one-depth__list:last-child {
  margin-right: 0;
}

.header__all-menu .one-depth__list > a {
  font-size: 3rem;
  font-weight: 700;
  color: #fff;
}

@media (max-width: 1420px) {
  .header__all-menu .one-depth__list > a {
    font-size: 2.4rem;
  }
}

@media (max-width: 1260px) {
  .header__all-menu .one-depth__list > a {
    font-size: 2rem;
  }
}

@media (max-width: 1050px) {
  .header__all-menu .one-depth__list > a {
    font-size: 1.8rem;
  }
}

@media (max-width: 991px) {
  .header__all-menu .one-depth__list > a {
    font-size: 2.2rem;
  }
}

@media (max-width: 767px) {
  .header__all-menu .one-depth__list > a {
    font-size: 2.2rem;
  }
}

.header__all-menu .two-depth {
  margin-top: 20px;
  padding-top: 30px;
  border-top: 2px solid #fff;
}

@media (max-width: 767px) {
  .header__all-menu .two-depth {
    margin-top: 10px;
    padding-top: 10px;
  }
}

.header__all-menu .two-depth__tit {
  display: none;
}

.header__all-menu .two-depth__list {
  margin-bottom: 20px;
}

.header__all-menu .two-depth__list:last-child {
  margin-bottom: 0;
}

.header__all-menu .two-depth__list > a {
  font-size: 1.6rem;
  font-weight: 500;
  color: #fff;
}

.header__all-menu .three-depth {
  padding-top: 10px;
}

.header__all-menu .three-depth__list {
  width: 50%;
  margin-bottom: 10px;
  display: inline-block;
  vertical-align: top;
}

.header__all-menu .three-depth__list:last-child {
  margin-bottom: 0;
}

.header__all-menu .three-depth__list a {
  padding-left: 10px;
  font-size: 1.3rem;
  font-weight: 500;
  color: #aeb4c6;
  position: relative;
}

.header__all-menu .three-depth__list a:before {
  content: '';
  width: 3px;
  height: 3px;
  display: inline-block;
  background-color: #aeb4c6;
  position: absolute;
  top: 5px;
  right: auto;
  bottom: auto;
  left: 0;
}

.header__all-menu .hasThirdDepth + .three-depth {
  padding-top: 20px;
}

.footer {
  padding: 90px 0;
  background-color: #162038;
}

@media (max-width: 1250px) {
  .footer {
    padding: 50px 0;
  }
}

@media (max-width: 767px) {
  .footer {
    padding: 30px 0;
  }
}

.footer__top {
  padding-bottom: 45px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
}

@media (max-width: 767px) {
  .footer__top {
    padding-bottom: 15px;
  }
}

.footer__logo {
  width: 170px;
  display: inline-block;
  vertical-align: middle;
}

@media (max-width: 991px) {
  .footer__logo {
    width: 100%;
    margin-bottom: 15px;
  }
}

.footer__info {
  width: calc(100% - 277px);
  padding-left: 60px;
  padding-right: 10px;
  display: inline-block;
  vertical-align: middle;
}

@media (max-width: 991px) {
  .footer__info {
    width: 100%;
    padding: 0;
  }
}

.footer__info li {
  margin-right: 45px;
  display: inline-block;
  font-size: 1.6rem;
  font-weight: 500;
  color: #aeb4c6;
  vertical-align: middle;
}

@media (max-width: 1520px) {
  .footer__info li {
    margin-right: 25px;
  }
}

@media (max-width: 991px) {
  .footer__info li {
    margin-right: 15px;
    font-size: 1.4rem;
  }
}

.footer__info li.address {
  width: 100%;
  margin: 0 0 15px 0;
}

.footer__mark {
  width: 107px;
  display: inline-block;
  vertical-align: middle;
}

@media (max-width: 767px) {
  .footer__mark {
    width: 100%;
    margin-top: 5px;
    text-align: right;
  }
}

.footer__bottom {
  padding-top: 50px;
}

@media (max-width: 767px) {
  .footer__bottom {
    padding-top: 15px;
  }
}

.footer__bottom p {
  width: 350px;
  display: inline-block;
  font-size: 1.4rem;
  font-weight: 500;
  color: #aeb4c6;
  vertical-align: middle;
}

@media (max-width: 1250px) {
  .footer__bottom p {
    width: 100%;
  }
}

@media (max-width: 991px) {
  .footer__bottom p {
    font-size: 1.2rem;
  }
}

.footer__link {
  width: calc(100% - 350px);
  display: inline-block;
  text-align: right;
  vertical-align: middle;
}

@media (max-width: 1250px) {
  .footer__link {
    width: 100%;
    margin-top: 10px;
  }
}

.footer__link li {
  display: inline-block;
  vertical-align: middle;
  text-align: left;
}

.footer__link li:after {
  content: '';
  width: 1px;
  height: 14px;
  margin: 0 25px;
  display: inline-block;
  background-color: rgba(255, 255, 255, 0.4);
  vertical-align: middle;
  position: relative;
  bottom: 5px;
}

@media (max-width: 1520px) {
  .footer__link li:after {
    margin: 0 10px;
  }
}

.footer__link li:last-child:after {
  display: none;
}

.footer__link a {
  font-size: 1.6rem;
  font-weight: 500;
  color: #aeb4c6;
}

@media (max-width: 991px) {
  .footer__link a {
    font-size: 1.2rem;
  }
}

.footer__link a.c-w {
  color: #fff;
}

@media (max-width: 991px) {
  main {
    transform: none !important;
  }
}

@media (max-width: 991px) {
  .main {
    overflow: auto;
  }
}

.main .section {
  overflow: hidden;
  position: relative;
}

@media (max-width: 991px) {
  .main .section {
    height: auto !important;
    min-height: auto;
  }
}

.main .section.ha {
  height: auto !important;
  min-height: auto;
}

.main .section article {
  width: calc(100% - 100px);
  height: 100%;
  margin-left: 100px;
}

@media (max-width: 767px) {
  .main .section article {
    width: 100%;
    margin-left: 0;
  }
}

.main .section article.intro {
  width: 100%;
  height: 100vh;
  margin-left: 0;
}

@media (max-width: 767px) {
  .main .section article.intro {
    padding-bottom: 30px;
    height: auto;
  }
}

@media (max-width: 991px) {
  .main .section0 {
    height: 100vh !important;
  }
}

@media (max-width: 767px) {
  .main .section0 {
    height: auto !important;
  }
}

.main .section1 {
  height: 100vh;
}

.main .section1 article:nth-child(2) {
  height: 100%;
}

.main .section2 {
  background-color: #fbf9f2;
}

@media (max-width: 991px) {
  .main .section2 {
    padding-bottom: 60px;
  }
}

.main-banner {
  width: 100%;
  height: 100%;
  position: relative;
}

.main-banner__slide {
  height: 100%;
}

.main-banner__slide .slick-list {
  height: 100%;
}

.main-banner__slide .slick-track {
  height: 100%;
}

.main-banner .item {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: left;
  background-repeat: no-repeat;
  overflow: hidden;
}

@media (max-width: 991px) {
  .main-banner .item {
    background-image: none !important;
  }
}

.main-banner .item:focus {
  outline: none;
  border: 2px dashed #9c7e29;
}

.main-banner .item img {
  width: 100%;
  display: none;
}

@media (max-width: 991px) {
  .main-banner .item img {
    display: block;
  }
}

.main-banner__tools {
  width: 100%;
  padding: 0 25px 0 200px;
  position: absolute;
  top: auto;
  right: 0;
  bottom: 160px;
  left: auto;
}

@media (max-width: 1150px) {
  .main-banner__tools {
    padding-left: 100px;
  }
}

@media (max-width: 991px) {
  .main-banner__tools {
    padding-left: 40px;
    bottom: 20px;
  }
}

@media (max-width: 767px) {
  .main-banner__tools {
    display: none;
  }
}

.main-banner__tools__paging {
  margin-right: 20px;
  display: inline-block;
  vertical-align: middle;
}

.main-banner__tools__paging p {
  display: inline-block;
  font-size: 1.8rem;
  font-weight: 700;
  color: #bcbcbc;
  vertical-align: middle;
}

.main-banner__tools__paging p.current-num {
  color: #fff;
}

.main-banner__tools a {
  margin-right: 30px;
  font-size: 0;
  vertical-align: middle;
  position: relative;
}

.main-banner__tools a:last-child {
  margin-right: 0;
}

.main-banner__tools__prev {
  padding: 5px 0;
  position: relative;
}

.main-banner__tools__prev:before {
  content: '';
  width: 44px;
  height: 2px;
  background-color: #bcbcbc;
  display: inline-block;
  box-sizing: border-box;
}

.main-banner__tools__prev:after {
  content: '';
  width: 8px;
  height: 8px;
  border-top: 2px solid #bcbcbc;
  border-left: 2px solid #bcbcbc;
  display: inline-block;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 0;
  right: auto;
  transform: translateY(-50%);
  transform: translateY(-50%) rotate(-45deg);
}

.main-banner__tools__pause:before {
  content: '';
  width: 12px;
  height: 12px;
  border-left: 4px solid #bcbcbc;
  border-right: 4px solid #bcbcbc;
  display: inline-block;
  box-sizing: border-box;
}

.main-banner__tools__play {
  display: none;
}

.main-banner__tools__play:before {
  content: '';
  border-left: 10px solid #bcbcbc;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-right: none;
  display: inline-block;
}

.main-banner__tools__next {
  padding: 5px 0;
  position: relative;
}

.main-banner__tools__next:before {
  content: '';
  width: 44px;
  height: 2px;
  background-color: #bcbcbc;
  display: inline-block;
  box-sizing: border-box;
}

.main-banner__tools__next:after {
  content: '';
  width: 8px;
  height: 8px;
  border-top: 2px solid #bcbcbc;
  border-left: 2px solid #bcbcbc;
  display: inline-block;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: auto;
  right: 0;
  transform: translateY(-50%);
  transform: translateY(-50%) rotate(135deg);
}

.main-tit h3 {
  font-size: 6rem;
  font-weight: 700;
  color: #222;
}

@media (max-width: 1520px) {
  .main-tit h3 {
    font-size: 5.2rem;
  }
}

@media (max-width: 1350px) {
  .main-tit h3 {
    font-size: 4.6rem;
  }
}

@media (max-width: 1250px) {
  .main-tit h3 {
    font-size: 4rem;
  }
}

@media (max-width: 991px) {
  .main-tit h3 {
    font-size: 3.6rem;
  }
}

@media (max-height: 860px) {
  .main-tit h3 {
    font-size: 4rem;
  }
}

@media (max-width: 767px) {
  .main-tit h3 {
    font-size: 2.6rem;
  }
}

.main-tit p {
  margin-top: 10px;
  font-size: 1.6rem;
  font-weight: 500;
  color: #666;
}

@media (max-width: 991px) {
  .main-tit p {
    font-size: 1.4rem;
  }
}

.main-notice {
  padding-top: 170px;
}

@media (max-width: 991px) {
  .main-notice {
    padding-top: 60px;
  }
}

.main-notice__tab {
  text-align: right;
}

@media (max-width: 1250px) {
  .main-notice__tab {
    margin-bottom: 15px;
  }
}

@media (max-width: 991px) {
  .main-notice__tab {
    margin-top: 30px;
  }
}

@media (max-width: 767px) {
  .main-notice__tab {
    text-align: left;
  }
}

.main-notice__tab a {
  margin-left: 60px;
  padding-bottom: 5px;
  font-size: 2rem;
  font-weight: 700;
  color: #666;
  text-align: center;
  position: relative;
}

@media (max-width: 1350px) {
  .main-notice__tab a {
    margin-left: 30px;
  }
}

@media (max-width: 767px) {
  .main-notice__tab a {
    width: calc(100% / 3);
    margin-left: 0;
    margin-bottom: 5px;
    font-size: 1.8rem;
  }
}

.main-notice__tab a:first-child {
  margin-left: 0;
}

.main-notice__tab a:after {
  content: '';
  width: 5px;
  height: 5px;
  display: none;
  background-color: #003554;
  position: absolute;
  top: auto;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.main-notice__tab a.selected {
  color: #003554;
}

.main-notice__tab a.selected:after {
  display: inline-block;
}

.main-notice__list {
  position: relative;
}

.main-notice__list__tit {
  width: 460px;
  height: 520px;
  padding: 100px 70px;
  display: inline-block;
  background-image: url("/template/resources/images/cont/main-notice-bg.png");
  background-size: cover;
  vertical-align: middle;
}

@media (max-width: 1350px) {
  .main-notice__list__tit {
    width: 400px;
    padding: 50px 40px;
  }
}

@media (max-height: 860px) {
  .main-notice__list__tit {
    height: 460px;
  }
}

@media (max-height: 800px) {
  .main-notice__list__tit {
    height: 400px;
  }
}

@media (max-width: 991px) {
  .main-notice__list__tit {
    width: 300px;
    height: 400px;
  }
}

@media (max-width: 767px) {
  .main-notice__list__tit {
    width: calc(100% + 30px);
    height: 520px;
    padding: 50px 15px;
    position: relative;
    left: -15px;
  }
}

.main-notice__list__tit h3 {
  margin-bottom: 20px;
  font-size: 4.6rem;
  font-weight: 500;
  color: #fff;
}

@media (max-width: 1250px) {
  .main-notice__list__tit h3 {
    font-size: 3.6rem;
  }
}

@media (max-width: 991px) {
  .main-notice__list__tit h3 {
    font-size: 3rem;
  }
}

@media (max-width: 767px) {
  .main-notice__list__tit h3 {
    width: calc(100% - 93px);
    margin-bottom: 0;
    font-size: 2.6rem;
    display: inline-block;
  }
}

.main-notice__list__tit > a {
  padding-right: 30px;
  font-size: 1.8rem;
  font-weight: 500;
  color: #fff;
  line-height: 1;
  position: relative;
}

@media (max-width: 991px) {
  .main-notice__list__tit > a {
    font-size: 1.6rem;
  }
}

@media (max-width: 767px) {
  .main-notice__list__tit > a {
    padding-right: 15px;
    font-size: 1.4rem;
  }
}

.main-notice__list__tit > a:before {
  content: '';
  width: 14px;
  height: 2px;
  display: inline-block;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: auto;
  right: 0;
  transform: translateY(-50%);
  top: 53%;
}

@media (max-width: 991px) {
  .main-notice__list__tit > a:before {
    width: 12px;
  }
}

@media (max-width: 767px) {
  .main-notice__list__tit > a:before {
    width: 10px;
  }
}

.main-notice__list__tit > a:after {
  content: '';
  width: 2px;
  height: 14px;
  display: inline-block;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: auto;
  right: 6px;
  transform: translateY(-50%);
  top: 53%;
}

@media (max-width: 991px) {
  .main-notice__list__tit > a:after {
    height: 12px;
    right: 5px;
  }
}

@media (max-width: 767px) {
  .main-notice__list__tit > a:after {
    height: 10px;
    right: 4px;
  }
}

.main-notice__list__progress {
  width: 250px;
  height: 3px;
  margin-top: 140px;
  background-color: rgba(255, 255, 255, 0.4);
  position: relative;
}

@media (max-width: 991px) {
  .main-notice__list__progress {
    width: 150px;
    margin-top: 80px;
  }
}

@media (max-height: 800px) {
  .main-notice__list__progress {
    margin-top: 80px;
  }
}

@media (max-width: 767px) {
  .main-notice__list__progress {
    width: 100%;
    margin-top: 320px;
  }
}

.main-notice__list__progress span {
  width: 100px;
  height: 3px;
  background-color: #fff;
  position: absolute;
  top: 0;
  right: auto;
  bottom: auto;
  left: 0;
  transition-duration: .3s;
}

.main-notice__list__progress .slick-dots {
  width: 100%;
}

.main-notice__list__progress .slick-dots li {
  height: 3px;
  margin: 0;
  padding: 0;
  cursor: revert;
  background-color: transparent;
}

.main-notice__list__progress .slick-dots button {
  display: none;
}

.main-notice__list__tools {
  margin-top: 30px;
}

@media (max-width: 767px) {
  .main-notice__list__tools {
    margin-top: 15px;
  }
}

.main-notice__list__tools a {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  border-radius: 50px;
  border: 2px solid #fff;
  vertical-align: middle;
  position: relative;
}

.main-notice__list__tools a:last-child {
  margin-right: 0;
}

.main-notice__list__tools a:before {
  content: '';
  width: 13px;
  height: 13px;
  border-top: 2px solid #fff;
  border-left: 2px solid #fff;
  display: inline-block;
  box-sizing: border-box;
}

.main-notice__list__tools a:hover {
  background-color: #fff;
}

.main-notice__list__tools a:hover:before {
  border-top: 2px solid #222;
  border-left: 2px solid #222;
  transition-duration: .3s;
}

.main-notice__list__tools a.main-notice__list__tools__pause:before {
  width: 8px;
  border-top: none;
  border-right: 2px solid #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.main-notice__list__tools a.main-notice__list__tools__pause:hover:before {
  border-top: none;
  border-right: 2px solid #000;
}

.main-notice__list__tools a.main-notice__list__tools__play {
  display: none;
}

.main-notice__list__tools a.main-notice__list__tools__play:before {
  width: 7px;
  height: 14px;
  border-left: 7px solid #fff;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-right: none;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.main-notice__list__tools a.main-notice__list__tools__play:hover:before {
  border-left: 7px solid #000;
}

.main-notice__list__tools__prev:before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: translate(-30%, -50%) rotate(-45deg);
}

.main-notice__list__tools__next:before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: translate(-70%, -50%) rotate(135deg);
}

.main-notice__list__wrap {
  width: calc((100% - 460px) + 175px);
  margin-left: -75px;
  position: absolute;
  top: 50%;
  left: auto;
  right: -100px;
  transform: translateY(-50%);
}

@media (max-width: 1350px) {
  .main-notice__list__wrap {
    width: calc((100% - 400px) + 175px);
  }
}

@media (max-width: 991px) {
  .main-notice__list__wrap {
    width: calc((100% - 300px) + 175px);
  }
}

@media (max-width: 767px) {
  .main-notice__list__wrap {
    width: 100%;
    margin-left: 0;
    position: absolute;
    top: 50%;
    left: 0;
    right: auto;
    transform: translateY(-50%);
  }
}

.main-notice__list__wrap .item {
  width: 380px;
  height: 380px;
  margin-right: 40px;
  padding: 55px 45px;
  border: 5px solid #fff;
  background-color: #fff;
  word-break: break-all;
  position: relative;
}

@media (max-height: 800px) {
  .main-notice__list__wrap .item {
    width: 300px;
    height: 300px;
    padding: 45px;
  }
}

@media (max-width: 991px) {
  .main-notice__list__wrap .item {
    width: 300px;
    height: 300px;
    padding: 30px;
  }
}

@media (max-width: 767px) {
  .main-notice__list__wrap .item {
    width: 250px;
    height: 250px;
    padding: 20px;
  }
}

.main-notice__list__wrap .item:hover {
  border: 5px solid #9c7e29;
}

.main-notice__list__wrap .item:hover a .link-arrow {
  background-color: #9c7e29;
}

.main-notice__list__wrap .item:hover a .link-arrow img:first-child {
  display: none;
}

.main-notice__list__wrap .item:hover a .link-arrow img:last-child {
  display: inline-block;
}

.main-notice__list__wrap .item h3 {
  margin-bottom: 20px;
  font-size: 2.4rem;
  font-weight: 700;
  color: #222;
  height: auto;
  max-height: 13.6rem;
  line-height: 3.4rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  white-space: normal;
}

@media (max-height: 800px) {
  .main-notice__list__wrap .item h3 {
    margin-bottom: 10px;
    height: auto;
    max-height: 10.2rem;
    line-height: 3.4rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    white-space: normal;
  }
}

@media (max-width: 767px) {
  .main-notice__list__wrap .item h3 {
    font-size: 2.2rem;
    height: auto;
    max-height: 9.6rem;
    line-height: 3.2rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    white-space: normal;
  }
}

.main-notice__list__wrap .item p {
  font-size: 1.8rem;
  font-weight: 500;
  color: #666;
}

.main-notice__list__wrap .item a {
  width: calc(100% - 90px);
  position: absolute;
  top: auto;
  right: auto;
  bottom: 55px;
  left: 45px;
}

@media (max-height: 800px) {
  .main-notice__list__wrap .item a {
    width: calc(100% - 90px);
    left: 45px;
  }
}

@media (max-width: 991px) {
  .main-notice__list__wrap .item a {
    width: calc(100% - 60px);
    bottom: 20px;
    left: 30px;
  }
}

@media (max-width: 767px) {
  .main-notice__list__wrap .item a {
    width: calc(100% - 40px);
    left: 20px;
  }
}

.main-notice__list__wrap .item a:after {
  content: '';
  display: block;
  clear: both;
}

.main-notice__list__wrap .item a p {
  margin-top: 12px;
  border-bottom: 1px solid #000;
  display: inline-block;
  font-size: 1.8rem;
  font-weight: 500;
  color: #222;
  vertical-align: middle;
}

.main-notice__list__wrap .item a .link-arrow {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  float: right;
}

.main-notice__list__wrap .item a .link-arrow img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: translate(-50%, -70%);
}

.main-notice__list__wrap .item a .link-arrow img:last-child {
  display: none;
}

.main-notice__list__slide {
  display: none;
}

.main-notice__list__slide.visible {
  display: block;
}

.main-sns {
  height: auto !important;
  padding-top: 170px;
}

@media (max-height: 800px) {
  .main-sns {
    padding-top: 130px;
  }
}

@media (max-width: 991px) {
  .main-sns {
    padding-top: 60px;
  }
}

.main-sns__left {
  width: calc(100% - 1080px);
  padding-right: 10px;
  display: inline-block;
  vertical-align: middle;
}

@media (max-width: 1650px) {
  .main-sns__left {
    width: calc(100% - 800px);
  }
}

@media (max-width: 1410px) {
  .main-sns__left {
    width: calc(100% - 700px);
  }
}

@media (max-height: 860px) {
  .main-sns__left {
    width: calc(100% - 700px);
  }
}

@media (max-width: 1250px) {
  .main-sns__left {
    width: 100%;
    margin-right: 0;
    margin-bottom: 15px;
    padding-right: 0;
  }
  .main-sns__left .main-tit {
    width: 50%;
    display: inline-block;
    vertical-align: middle;
  }
}

@media (max-height: 740px) {
  .main-sns__left {
    margin-bottom: 0;
  }
}

@media (max-width: 767px) {
  .main-sns__left .main-tit {
    width: 100%;
  }
}

.main-sns__left__link {
  margin-top: 60px;
}

@media (max-width: 1250px) {
  .main-sns__left__link {
    width: 50%;
    margin-top: 0;
    display: inline-block;
    vertical-align: middle;
    text-align: right;
  }
}

@media (max-width: 767px) {
  .main-sns__left__link {
    width: 100%;
    margin-top: 10px;
  }
}

.main-sns__left__link a {
  margin-right: 10px;
  font-size: 0;
  display: inline-block;
  vertical-align: middle;
}

@media (max-width: 1350px) {
  .main-sns__left__link a {
    margin-right: 5px;
  }
}

.main-sns__left__link a:last-child {
  margin-right: 0;
}

.main-sns__tools {
  margin-top: 70px;
}

@media (max-width: 1260px) {
  .main-sns__tools {
    margin-top: 30px;
  }
}

@media (max-height: 800px) {
  .main-sns__tools {
    margin-top: 15px;
  }
}

@media (max-width: 767px) {
  .main-sns__tools {
    margin-top: 15px;
  }
}

.main-sns__tools a {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  border-radius: 50px;
  border: 2px solid #ddd;
  vertical-align: middle;
  position: relative;
}

.main-sns__tools a:last-child {
  margin-right: 0;
}

.main-sns__tools a:before {
  content: '';
  width: 13px;
  height: 13px;
  border-top: 2px solid #ddd;
  border-left: 2px solid #ddd;
  display: inline-block;
  box-sizing: border-box;
}

.main-sns__tools a:hover {
  background-color: #ddd;
}

.main-sns__tools a:hover:before {
  border-top: 2px solid #fff;
  border-left: 2px solid #fff;
  transition-duration: .3s;
}

.main-sns__tools a.main-sns__tools__pause:before {
  width: 8px;
  border-top: none;
  border-right: 2px solid #ddd;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.main-sns__tools a.main-sns__tools__pause:hover:before {
  border-top: none;
  border-right: 2px solid #fff;
}

.main-sns__tools a.main-sns__tools__play {
  display: none;
}

.main-sns__tools a.main-sns__tools__play:before {
  width: 7px;
  height: 14px;
  border-left: 7px solid #ddd;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-right: none;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.main-sns__tools a.main-sns__tools__play:hover:before {
  border-left: 7px solid #fff;
}

.main-sns__tools__prev:before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: translate(-30%, -50%) rotate(-45deg);
}

.main-sns__tools__next:before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: translate(-70%, -50%) rotate(135deg);
}

.main-sns__right {
  width: 1080px;
  display: inline-block;
  vertical-align: middle;
}

@media (max-width: 1650px) {
  .main-sns__right {
    width: 800px;
  }
}

@media (max-width: 1410px) {
  .main-sns__right {
    width: 700px;
  }
}

@media (max-height: 860px) {
  .main-sns__right {
    width: 700px;
  }
}

@media (max-width: 1250px) {
  .main-sns__right {
    width: 100%;
  }
}

.main-sns__right .item {
  margin: 0 30px;
  padding: 5px;
}

@media (max-width: 1650px) {
  .main-sns__right .item {
    margin: 0 15px;
  }
}

@media (max-width: 1250px) {
  .main-sns__right .item {
    margin: 0 calc(15px / 2);
  }
}

@media (max-width: 767px) {
  .main-sns__right .item {
    margin: 0;
  }
}

.main-sns__right .item a {
  width: 100%;
}

.main-sns__right .item a:hover {
  outline: 5px solid #9c7e29;
}

.main-sns__right__img {
  padding-bottom: 100%;
  position: relative;
  overflow: hidden;
}

.main-sns__right__img img {
  max-width: none;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: auto;
  left: 50%;
  transform: translateX(-50%);
}

.main-sns__right__text {
  padding: 30px 25px;
}

@media (max-width: 1650px) {
  .main-sns__right__text {
    padding: 20px 15px;
  }
}

@media (max-width: 1250px) {
  .main-sns__right__text {
    padding: 15px;
  }
}

.main-sns__right__text h3 {
  margin-bottom: 10px;
  font-size: 2.4rem;
  font-weight: 700;
  color: #222;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media (max-width: 1650px) {
  .main-sns__right__text h3 {
    font-size: 2.2rem;
  }
}

@media (max-width: 1250px) {
  .main-sns__right__text h3 {
    font-size: 1.8rem;
  }
}

.main-sns__right__text p {
  padding-left: 30px;
  font-size: 1.8rem;
  font-weight: 500;
  color: #666;
  position: relative;
}

@media (max-width: 1250px) {
  .main-sns__right__text p {
    font-size: 1.6rem;
  }
}

.main-sns__right__text p img {
  position: absolute;
  top: 50%;
  left: 0;
  right: auto;
  transform: translateY(-50%);
}

.main-sns__slide {
  padding: 5px;
}

.main-information {
  margin-top: 80px;
}

@media (max-width: 1250px) {
  .main-information {
    margin-top: 20px;
  }
}

@media (max-height: 900px) {
  .main-information {
    margin-top: 20px;
  }
}

.main-information .wrap > div {
  padding: 43px 0;
  background-image: url("/template/resources/images/cont/information-bg.png");
  background-size: cover;
  background-position: center;
}

.main-information__left {
  width: 430px;
  display: inline-block;
  vertical-align: middle;
}

@media (max-width: 991px) {
  .main-information__left {
    width: 100%;
    text-align: center;
  }
}

@media (max-width: 767px) {
  .main-information__left {
    padding: 0 10px;
  }
}

.main-information__right {
  width: calc(100% - 430px);
  display: inline-block;
  vertical-align: middle;
  text-align: right;
}

@media (max-width: 991px) {
  .main-information__right {
    width: 100%;
    margin-top: 30px;
    text-align: center;
  }
}

.main-information__right li {
  padding: 0 100px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  text-align: center;
}

@media (max-width: 1650px) {
  .main-information__right li {
    padding: 0 50px;
  }
}

@media (max-width: 1350px) {
  .main-information__right li {
    padding: 0 30px;
  }
}

@media (max-width: 1150px) {
  .main-information__right li {
    padding: 0 15px;
  }
}

@media (max-width: 991px) {
  .main-information__right li {
    padding: 0 30px;
  }
}

@media (max-width: 767px) {
  .main-information__right li {
    width: 50%;
    padding: 0 10px;
  }
}

.main-information__right li:first-child:after {
  content: '';
  width: 2px;
  height: 50px;
  display: inline-block;
  background-color: #ddd;
  position: absolute;
  top: 50%;
  left: auto;
  right: 0;
  transform: translateY(-50%);
}

.main-information__right h4 {
  margin-bottom: 20px;
  font-size: 1.8rem;
  font-weight: 500;
  color: #9c7e29;
}

@media (max-width: 1150px) {
  .main-information__right h4 {
    margin-bottom: 10px;
  }
}

@media (max-width: 991px) {
  .main-information__right h4 {
    margin-bottom: 0;
  }
}

@media (max-width: 767px) {
  .main-information__right h4 {
    font-size: 1.6rem;
  }
}

.main-information__right p {
  font-size: 4.8rem;
  font-weight: 700;
  color: #222;
}

@media (max-width: 1450px) {
  .main-information__right p {
    font-size: 3.8rem;
  }
}

@media (max-width: 1150px) {
  .main-information__right p {
    font-size: 3rem;
  }
}

@media (max-width: 767px) {
  .main-information__right p {
    font-size: 2.2rem;
  }
}

.main-quick {
  padding-top: 100px;
}

@media (max-width: 991px) {
  .main-quick {
    padding-top: 60px;
  }
}

@media (max-width: 767px) {
  .main-quick {
    padding-top: 30px;
  }
}

.main-quick__left {
  width: calc(100% - 720px);
  display: inline-block;
  vertical-align: top;
}

@media (max-width: 1520px) {
  .main-quick__left {
    width: calc(100% - 600px);
    padding-right: 20px;
  }
}

@media (max-width: 1150px) {
  .main-quick__left {
    width: calc(100% - 450px);
  }
}

@media (max-width: 991px) {
  .main-quick__left {
    width: 100%;
    padding-right: 0;
    margin-bottom: 30px;
  }
}

.main-quick__left__link {
  margin-top: 60px;
}

@media (max-width: 1520px) {
  .main-quick__left__link {
    margin-top: 30px;
  }
}

@media (max-width: 1410px) {
  .main-quick__left__link {
    margin-top: 15px;
  }
}

.main-quick__left__link a {
  margin-right: 80px;
  vertical-align: top;
}

@media (max-width: 1780px) {
  .main-quick__left__link a {
    margin-right: 20px;
  }
}

@media (max-width: 1650px) {
  .main-quick__left__link a {
    width: calc(100% / 4);
    padding: 0 5px;
    margin-right: 0;
  }
}

@media (max-width: 767px) {
  .main-quick__left__link a {
    width: 50%;
  }
}

.main-quick__left__link a:last-child {
  margin-right: 0;
}

.main-quick__left__link br {
  display: none;
}

@media (max-width: 991px) {
  .main-quick__left__link br {
    display: none;
  }
}

.main-quick__left__link__img {
  width: 120px;
  height: 120px;
  margin: 0 auto;
  border-radius: 120px;
  background-color: #f5f5f5;
  position: relative;
}

@media (max-width: 1750px) {
  .main-quick__left__link__img {
    width: 100px;
    height: 100px;
  }
}

@media (max-width: 1650px) {
  .main-quick__left__link__img {
    width: 100%;
    height: auto;
    padding-bottom: 100%;
  }
}

@media (max-width: 767px) {
  .main-quick__left__link__img {
    border-radius: 300px;
  }
}

.main-quick__left__link__img img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (max-width: 1520px) {
  .main-quick__left__link__img img {
    max-width: 80%;
  }
}

.main-quick__left__link__text {
  margin-top: 30px;
}

@media (max-width: 1750px) {
  .main-quick__left__link__text {
    margin-top: 15px;
  }
}

@media (max-width: 1410px) {
  .main-quick__left__link__text {
    margin-top: 5px;
  }
}

@media (max-height: 920px) {
  .main-quick__left__link__text {
    margin-top: 15px;
  }
}

@media (max-height: 860px) {
  .main-quick__left__link__text {
    margin-top: 5px;
  }
}

@media (max-width: 991px) {
  .main-quick__left__link__text {
    margin-top: 10px;
  }
}

.main-quick__left__link__text p {
  font-size: 1.8rem;
  font-weight: 500;
  color: #666;
  text-align: center;
  word-break: break-all;
}

@media (max-width: 1750px) {
  .main-quick__left__link__text p {
    font-size: 1.6rem;
  }
}

@media (max-height: 800px) {
  .main-quick__left__link__text p {
    font-size: 1.6rem;
  }
}

@media (max-width: 1650px) {
  .main-quick__left__link__text p {
    font-size: 1.4rem;
  }
}

@media (max-width: 1150px) {
  .main-quick__left__link__text p {
    font-size: 1.2rem;
  }
}

@media (max-width: 991px) {
  .main-quick__left__link__text p {
    font-size: 1.6rem;
  }
}

.main-quick__right {
  width: 720px;
  display: inline-block;
  vertical-align: top;
  text-align: right;
}

@media (max-width: 1520px) {
  .main-quick__right {
    width: 600px;
  }
}

@media (max-width: 1150px) {
  .main-quick__right {
    width: 450px;
  }
}

@media (max-width: 991px) {
  .main-quick__right {
    width: 100%;
  }
}

.main-quick__right a {
  display: inline-block;
  font-size: 0;
  vertical-align: top;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 1;
}

@media (max-width: 1520px) {
  .main-quick__right a {
    width: calc(50% - 20px);
  }
}

@media (max-width: 1410px) {
  .main-quick__right a {
    width: calc(50% - 10px);
  }
}

@media (max-width: 767px) {
  .main-quick__right a {
    width: calc((100% - 15px) / 2);
  }
}

.main-quick__right a:first-child {
  margin-right: 40px;
}

@media (max-width: 1410px) {
  .main-quick__right a:first-child {
    margin-right: 20px;
  }
}

@media (max-width: 767px) {
  .main-quick__right a:first-child {
    margin-right: 15px;
  }
}

.main-quick__right a img {
  height: auto;
}

@media (max-height: 920px) {
  .main-quick__right a img {
    max-height: 550px;
  }
}

@media (max-height: 860px) {
  .main-quick__right a img {
    max-height: 500px;
  }
}

@media (max-height: 800px) {
  .main-quick__right a img {
    max-height: 420px;
  }
}

@media (max-width: 991px) {
  .main-quick__right a img {
    width: 100%;
    max-height: none;
  }
}

.main-quick__bottom {
  width: 100%;
  margin-top: 30px;
}

@media (max-width: 991px) {
  .main-quick__bottom {
    margin-top: 15px;
    position: initial;
    transform: none;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
  }
}

.main-quick__bottom__link {
  padding: 75px 100px;
  background-image: url("/template/resources/images/cont/main-quick-bg.png");
  background-size: cover;
  background-position: center;
}

@media (max-width: 1650px) {
  .main-quick__bottom__link {
    padding: 38px 100px 38px 100px;
  }
}

@media (max-width: 991px) {
  .main-quick__bottom__link {
    padding: 30px;
  }
}

@media (max-width: 767px) {
  .main-quick__bottom__link {
    padding: 30px 15px;
  }
}

.main-quick__bottom__link__wrap {
  width: 100%;
}

@media (max-width: 1150px) {
  .main-quick__bottom__link__wrap {
    width: 100%;
  }
}

@media (max-height: 830px) {
  .main-quick__bottom__link__wrap {
    width: calc(100% - 400px);
  }
}

@media (max-width: 991px) {
  .main-quick__bottom__link__wrap {
    width: 100%;
    padding-right: 0;
  }
}

.main-quick__bottom__link__wrap a {
  width: calc((100% - 75px) / 6);
  height: 58px;
  margin-right: 15px;
  margin-bottom: 15px;
  padding: 17px 20px;
  border: 1px solid rgba(255, 255, 255, 0.6);
  display: inline-block;
  font-size: 1.8rem;
  font-weight: 500;
  color: #fff;
  vertical-align: top;
  position: relative;
}

@media (max-width: 1750px) {
  .main-quick__bottom__link__wrap a {
    padding: 17px 15px;
    font-size: 1.6rem;
  }
}

@media (max-width: 1650px) {
  .main-quick__bottom__link__wrap a {
    width: calc((100% - 30px) / 3);
  }
}

@media (max-width: 1410px) {
  .main-quick__bottom__link__wrap a {
    height: 48px;
    padding: 12px 20px;
  }
}

@media (max-width: 1350px) {
  .main-quick__bottom__link__wrap a {
    padding: 14px 15px;
    font-size: 1.4rem;
  }
}

@media (max-width: 767px) {
  .main-quick__bottom__link__wrap a {
    width: 100%;
    margin-right: 0;
  }
}

.main-quick__bottom__link__wrap a:hover {
  background-color: rgba(156, 126, 41, 0.4);
}

@media (max-width: 1650px) {
  .main-quick__bottom__link__wrap a:nth-child(3n) {
    margin-right: 0;
  }
}

@media (max-width: 1150px) and (min-height: 831px) {
  .main-quick__bottom__link__wrap a:nth-child(3n) {
    margin-right: 0;
  }
}

@media (max-width: 1150px) and (min-height: 831px) {
  .main-quick__bottom__link__wrap a:nth-child(2n) {
    margin-right: 15px;
  }
}

@media (max-width: 767px) {
  .main-quick__bottom__link__wrap a:nth-child(2n) {
    margin-right: 0;
  }
}

.main-quick__bottom__link__wrap a:nth-child(6n) {
  margin-right: 0;
}

@media (max-width: 1150px) {
  .main-quick__bottom__link__wrap a:nth-child(6n) {
    margin-right: 0;
  }
}

.main-quick__bottom__link__wrap a:nth-child(n+4) {
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .main-quick__bottom__link__wrap a:nth-child(n+4) {
    margin-bottom: 15px;
  }
}

@media (max-width: 767px) {
  .main-quick__bottom__link__wrap a:last-child {
    margin-bottom: 0;
  }
}

.main-quick__bottom__link__wrap a img {
  position: absolute;
  top: 50%;
  left: auto;
  right: 20px;
  transform: translateY(-50%);
}

@media (max-width: 1750px) {
  .main-quick__bottom__link__wrap a img {
    right: 15px;
  }
}

.main-quick__bottom__site {
  height: 105px;
  padding: 0 100px 0 200px;
}

@media (max-width: 1250px) {
  .main-quick__bottom__site {
    padding: 0 50px 0 150px;
  }
}

@media (max-width: 991px) {
  .main-quick__bottom__site {
    height: auto;
    padding: 20px 0;
  }
}

.main-quick__bottom__site h4 {
  width: 170px;
  font-size: 2.4rem;
  font-weight: 700;
  color: #222;
  line-height: 105px;
  display: inline-block;
  vertical-align: middle;
}

@media (max-width: 991px) {
  .main-quick__bottom__site h4 {
    width: 120px;
    line-height: 1;
    font-size: 2rem;
  }
}

@media (max-width: 767px) {
  .main-quick__bottom__site h4 {
    width: 100%;
  }
}

.main-quick__bottom__site__slide {
  width: calc(100% - 340px);
  padding-right: 40px;
  display: inline-block;
  vertical-align: middle;
}

@media (max-width: 991px) {
  .main-quick__bottom__site__slide {
    width: calc(100% - 230px);
    padding-right: 15px;
  }
}

@media (max-width: 767px) {
  .main-quick__bottom__site__slide {
    width: 100%;
  }
}

.main-quick__bottom__site__slide .item {
  margin-right: 3px;
}

.main-quick__bottom__site__slide .slick-list {
  padding: 3px;
}

.main-quick__bottom__site__slide a {
  font-size: 0;
}

.main-quick__bottom__site__tools {
  width: 170px;
  display: inline-block;
  vertical-align: middle;
}

@media (max-width: 991px) {
  .main-quick__bottom__site__tools {
    width: 110px;
  }
}

@media (max-width: 767px) {
  .main-quick__bottom__site__tools {
    display: none;
  }
}

.main-quick__bottom__site__tools a {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  border: 2px solid #ddd;
  border-radius: 50px;
  position: relative;
}

@media (max-width: 991px) {
  .main-quick__bottom__site__tools a {
    width: 30px;
    height: 30px;
  }
}

.main-quick__bottom__site__tools a:last-child {
  margin-right: 0;
}

.main-quick__bottom__site__tools a:before {
  content: '';
  display: inline-block;
  box-sizing: border-box;
}

.main-quick__bottom__site__prev:before {
  width: 12px;
  height: 12px;
  border-top: 2px solid #999;
  border-left: 2px solid #999;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: translate(-30%, -50%) rotate(-45deg);
}

@media (max-width: 991px) {
  .main-quick__bottom__site__prev:before {
    width: 8px;
    height: 8px;
  }
}

.main-quick__bottom__site__next:before {
  width: 12px;
  height: 12px;
  border-top: 2px solid #999;
  border-left: 2px solid #999;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: translate(-70%, -50%) rotate(135deg);
}

@media (max-width: 991px) {
  .main-quick__bottom__site__next:before {
    width: 8px;
    height: 8px;
  }
}

.main-quick__bottom__site__pause:before {
  width: 10px;
  height: 17px;
  border-left: 2px solid #999;
  border-right: 2px solid #999;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (max-width: 991px) {
  .main-quick__bottom__site__pause:before {
    width: 8px;
    height: 14px;
  }
}

.main-quick__bottom__site__play {
  display: none;
}

.main-quick__bottom__site__play:before {
  border-left: 10px solid #999;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-right: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.main__popup {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  right: auto;
  bottom: auto;
  left: 0;
  z-index: 1002;
}

.main__popup .main-cont {
  width: 1200px;
  height: 100%;
  margin: 0 auto;
  position: relative;
}

@media (max-width: 1220px) {
  .main__popup .main-cont {
    width: 970px;
  }
}

@media (max-width: 991px) {
  .main__popup .main-cont {
    width: 750px;
  }
}

@media (max-width: 767px) {
  .main__popup .main-cont {
    width: 100%;
  }
}

.main__popup__wrap {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  right: auto;
  transform: translateY(-50%);
}

.main__popup__wrap__tools {
  width: calc(90% - 30px);
  margin: 0 auto 30px;
  text-align: right;
}

.main__popup__wrap__tools__paging {
  height: 45px;
  margin-right: 20px;
  padding: 0 20px;
  border-radius: 45px;
  display: inline-block;
  vertical-align: middle;
  background-color: rgba(0, 0, 0, 0.8);
}

.main__popup__wrap__tools p {
  display: inline-block;
  font-size: 1.4rem;
  font-weight: 500;
  color: #999;
  line-height: 45px;
  vertical-align: middle;
}

.main__popup__wrap__tools p.main__popup__wrap__tools__current {
  color: #fff;
}

.main__popup__wrap__tools a {
  vertical-align: middle;
}

.main__popup__wrap__tools a:first-of-type {
  margin-right: 15px;
}

.main__popup__wrap__slide {
  width: 90%;
  margin: 0 auto;
}

.main__popup .item {
  max-height: 75vh;
  margin: 0 15px;
  overflow-y: auto;
}

.main__popup .item p {
  width: 100%;
}

.main__popup .item a {
  width: 100%;
}

.main__popup .item img {
  width: 100% !important;
  height: auto !important;
}

.main__popup__btn {
  margin-top: 30px;
  text-align: center;
  white-space: nowrap;
}

.main__popup__btn label {
  min-width: 170px;
  position: relative;
}

.main__popup__btn label:before {
  content: '';
  width: 12px;
  height: 12px;
  margin-right: 5px;
  display: inline-block;
  border: 1px solid #9c7e29;
  background-color: #fff;
  vertical-align: middle;
  position: relative;
  bottom: 1px;
}

.main__popup__btn input:checked + label:before {
  background-color: #9c7e29;
}

.main__popup__btn > * {
  height: 40px;
  margin: 0 10px;
  padding: 0 15px;
  border-radius: 40px;
  display: inline-block;
  background-color: rgba(0, 0, 0, 0.8);
  vertical-align: middle;
  font-size: 14px;
  font-weight: 500;
  line-height: 38px;
  color: #fff;
}

.side-popup {
  position: absolute;
  top: auto;
  right: -610px;
  bottom: 0;
  left: auto;
  z-index: -1;
}

.side-popup.z-idx {
  z-index: 1;
}

@media (max-width: 767px) {
  .side-popup {
    width: calc(100% - 40px);
    right: calc(-100% + 40px);
  }
}

.side-popup.visible {
  right: 0;
  transition-duration: .3s;
}

.side-popup > a {
  width: 48px;
  height: 106px;
  background-color: #9c7e29;
  position: absolute;
  top: 0;
  right: auto;
  bottom: auto;
  left: -48px;
}

@media (max-width: 991px) {
  .side-popup > a {
    width: 40px;
    left: -40px;
  }
}

.side-popup > a p {
  padding-right: 20px;
  font-size: 1.4rem;
  font-weight: 500;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: translate(-50%, -50%) rotate(-90deg);
}

.side-popup > a p:before {
  content: '';
  width: 8px;
  height: 8px;
  border-top: 2px solid #fff;
  border-left: 2px solid #fff;
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 5px;
  bottom: auto;
  left: auto;
  transform: translateY(-10%) rotate(45deg);
  box-sizing: border-box;
}

.side-popup > a.active p:before {
  content: '';
  width: 1px;
  height: 13px;
  border-top: none;
  border-left: none;
  display: inline-block;
  background-color: #fff;
  position: absolute;
  top: 55%;
  right: 5px;
  bottom: auto;
  left: auto;
  transform: translateY(-50%) rotate(135deg);
}

.side-popup > a.active p:after {
  content: '';
  width: 13px;
  height: 1px;
  display: inline-block;
  background-color: #fff;
  position: absolute;
  top: 55%;
  right: -1px;
  bottom: auto;
  left: auto;
  transform: translateY(-45%) rotate(-45deg);
}

.side-popup__slide {
  width: 610px;
  max-width: 610px;
}

.side-popup__slide .item {
  width: 100%;
}

.side-popup__slide .item img {
  width: 100%;
}

.side-popup__tools {
  width: 100%;
  padding: 0 25px;
  text-align: right;
  position: absolute;
  top: auto;
  right: 0;
  bottom: 25px;
  left: auto;
}

@media (max-width: 767px) {
  .side-popup__tools {
    bottom: 10px;
  }
}

.side-popup__tools__paging {
  margin-right: 20px;
  display: inline-block;
  vertical-align: middle;
}

.side-popup__tools__paging p {
  display: inline-block;
  font-size: 1.6rem;
  font-weight: 700;
  color: #bcbcbc;
  vertical-align: middle;
}

.side-popup__tools__paging p.current-num {
  color: #fff;
}

.side-popup__tools a {
  width: 28px;
  height: 28px;
  margin-right: 5px;
  border-radius: 28px;
  background-color: rgba(0, 0, 0, 0.6);
  font-size: 0;
  vertical-align: middle;
  position: relative;
}

.side-popup__tools a:last-child {
  margin-right: 0;
}

.side-popup__tools__prev:before {
  content: '';
  width: 8px;
  height: 8px;
  border-top: 2px solid #fff;
  border-left: 2px solid #fff;
  display: inline-block;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: translate(-30%, -50%) rotate(-45deg);
}

.side-popup__tools__pause:before {
  content: '';
  width: 8px;
  height: 10px;
  border-left: 2px solid #fff;
  border-right: 2px solid #fff;
  display: inline-block;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.side-popup__tools__play {
  display: none;
}

.side-popup__tools__play:before {
  content: '';
  border-left: 8px solid #fff;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-right: none;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: translate(-40%, -50%);
}

.side-popup__tools__next:before {
  content: '';
  width: 8px;
  height: 8px;
  border-top: 2px solid #fff;
  border-left: 2px solid #fff;
  display: inline-block;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: translate(-70%, -50%) rotate(135deg);
}

.intro {
  height: 100%;
  background-image: url("/template/resources/images/cont/intro-bg.png");
  background-size: cover;
  position: relative;
  z-index: 5;
}

.intro-top {
  padding: 45px 0 110px;
  text-align: center;
}

@media (max-height: 880px) {
  .intro-top {
    padding: 15px 0;
  }
}

@media (max-width: 1450px) {
  .intro-top {
    padding: 45px 0 40px;
  }
}

@media (max-width: 767px) {
  .intro-top {
    padding: 60px 15px 40px;
  }
}

.intro-top img {
  margin: 0 auto;
  display: block;
}

.intro-top h3 {
  margin-top: 40px;
  display: inline-block;
  font-size: 3rem;
  font-weight: 500;
  color: #606060;
  position: relative;
}

.intro-top h3:after {
  content: '';
  width: 100%;
  height: 16px;
  display: inline-block;
  background-color: #f0ebcb;
  position: absolute;
  top: auto;
  right: auto;
  bottom: 0;
  left: 0;
  z-index: -1;
}

@media (max-width: 767px) {
  .intro-top h3:after {
    bottom: -5px;
  }
}

@media (max-height: 880px) {
  .intro-top h3 {
    font-size: 3rem;
  }
}

@media (max-width: 767px) {
  .intro-top h3 {
    font-size: 1.6rem;
  }
}

.intro-list {
  width: 1240px;
  margin: 0 auto;
}

@media (max-width: 1260px) {
  .intro-list {
    width: 850px;
  }
}

@media (max-width: 991px) {
  .intro-list {
    width: 670px;
  }
}

@media (max-width: 767px) {
  .intro-list {
    width: 100%;
    padding: 0 15px;
  }
}

@media (max-width: 991px) {
  .intro-list ul {
    text-align: center;
  }
}

.intro-list li {
  width: calc((100% - 100px) / 5);
  height: 340px;
  margin: 0 10px;
  display: inline-block;
  vertical-align: top;
}

@media (max-width: 1260px) {
  .intro-list li {
    width: calc((100% - 50px) / 5);
    margin: 0 5px;
  }
}

@media (max-width: 991px) {
  .intro-list li {
    width: calc((100% - 30px) / 3);
    height: 220px;
    margin: 5px;
  }
}

@media (max-width: 767px) {
  .intro-list li {
    width: 100%;
    height: 180px;
    margin: 5px 0;
  }
}

.intro-list li.c1 a {
  background-image: url("/template/resources/images/cont/intro-list-bg1.png");
}

.intro-list li.c1 a:hover {
  background-image: url("/template/resources/images/cont/intro-list-bg1_hover.png");
}

.intro-list li.c1 a h4 {
  color: #111;
}

.intro-list li.c1 a p {
  color: #888;
}

.intro-list li.c1 .intro-list__text:after {
  background-color: #888;
}

.intro-list li.c2 a {
  background-image: url("/template/resources/images/cont/intro-list-bg2.png");
}

.intro-list li.c2 a:hover {
  background-image: url("/template/resources/images/cont/intro-list-bg2_hover.png");
}

.intro-list li.c2 a h4 {
  color: #111;
}

.intro-list li.c2 a p {
  color: #888;
}

.intro-list li.c3 a {
  background-image: url("/template/resources/images/cont/intro-list-bg3.png");
}

.intro-list li.c3 a:hover {
  background-image: url("/template/resources/images/cont/intro-list-bg3_hover.png");
}

.intro-list li.c4 a {
  background-image: url("/template/resources/images/cont/intro-list-bg5.png");
}

.intro-list li.c4 a:hover {
  background-image: url("/template/resources/images/cont/intro-list-bg4_hover.png");
}

.intro-list li.c5 a {
  background-image: url("/template/resources/images/cont/intro-list-bg4.png");
}

.intro-list li.c5 a:hover {
  background-image: url("/template/resources/images/cont/intro-list-bg5_hover.png");
}

.intro-list li a {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
  position: relative;
  box-shadow: 3px 3px 10px 3px rgba(0, 0, 0, 0.05);
}

.intro-list li a:hover {
  border: none;
  background-image: url("/template/resources/images/cont/intro-hover-bg.png");
}

.intro-list li a:hover .intro-list__text {
  height: 93px;
  margin-top: 0;
  margin-bottom: 40px;
  padding-top: 0;
}

@media (max-width: 991px) {
  .intro-list li a:hover .intro-list__text {
    height: 69px;
    margin-bottom: 15px;
  }
}

.intro-list li a:hover .intro-list__text:after {
  display: none;
}

.intro-list li a:hover > div {
  padding: 4px 15px;
}

@media (max-width: 1450px) {
  .intro-list li a:hover > div {
    padding: 0 14px;
  }
}

@media (max-width: 767px) {
  .intro-list li a:hover > div {
    padding: 15px 14px;
  }
}

.intro-list li a:hover img:first-of-type {
  display: none;
}

.intro-list li a:hover img:last-of-type {
  display: block;
}

.intro-list li a > div {
  width: 100%;
  padding: 0 15px;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 0;
  right: auto;
  transform: translateY(-50%);
}

@media (max-width: 1450px) {
  .intro-list li a > div {
    padding: 0 10px;
  }
}

.intro-list__text {
  margin-top: 50px;
  padding-top: 50px;
  text-align: center;
  position: relative;
}

@media (max-width: 991px) {
  .intro-list__text {
    margin-top: 15px;
    padding-top: 15px;
  }
}

.intro-list__text p {
  margin-bottom: 10px;
  font-size: 1.5rem;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.5);
}

@media (max-width: 1450px) {
  .intro-list__text p {
    font-size: 1.2rem;
  }
}

.intro-list__text h4 {
  font-size: 2.6rem;
  font-weight: 700;
  color: #fff;
  line-height: 3.1rem;
  word-break: break-all;
}

@media (max-width: 1650px) {
  .intro-list__text h4 {
    font-size: 2.2rem;
  }
}

@media (max-width: 1450px) {
  .intro-list__text h4 {
    font-size: 2rem;
    line-height: 2.5rem;
  }
}

@media (max-width: 1260px) {
  .intro-list__text h4 {
    font-size: 1.6rem;
    line-height: 2.1rem;
  }
}

.intro-list__text:after {
  content: '';
  width: 100%;
  max-width: 40px;
  height: 1px;
  display: inline-block;
  background-color: #fff;
  position: absolute;
  top: 0;
  bottom: auto;
  left: 50%;
  transform: translateX(-50%);
}

.intro-list img {
  margin: 0 auto;
  display: block;
}

.intro-list img:last-of-type {
  display: none;
}

.intro-bottom {
  margin-top: 145px;
}

@media (max-height: 760px) {
  .intro-bottom {
    margin-top: 100px;
  }
}

@media (max-width: 991px) {
  .intro-bottom {
    margin-top: 30px;
  }
}

.intro-bottom {
  padding: 10px 0;
  text-align: center;
}

@media (max-width: 767px) {
  .intro-bottom {
    display: none;
  }
}

.null-page .img-box {
  margin-top: 100px;
  text-align: center;
}

.sub .header {
  background-color: #fff;
}

.sub .header-bottom {
  width: 1600px;
  margin: 0 auto;
  padding: 0;
}

@media (max-width: 1720px) {
  .sub .header-bottom {
    width: 1400px;
  }
}

@media (max-width: 1520px) {
  .sub .header-bottom {
    width: 1200px;
  }
}

@media (max-width: 1320px) {
  .sub .header-bottom {
    width: 1000px;
  }
}

@media (max-width: 1200px) {
  .sub .header-bottom {
    width: 850px;
  }
}

@media (max-width: 991px) {
  .sub .header-bottom {
    width: 100%;
    padding: 0 30px;
  }
}

@media (max-width: 767px) {
  .sub .header-bottom {
    padding: 0 15px;
  }
}

.sub .side-area.n-first {
  transition-duration: 0s;
}

.sub-footer {
  margin-top: 100px;
  padding-left: 100px;
  position: relative;
  z-index: 3;
}

@media (max-width: 767px) {
  .sub-footer {
    margin-top: 50px;
    padding-left: 0;
  }
}

.sub-top {
  margin-top: 145px;
  margin-bottom: 80px;
  padding-left: 100px;
}

@media (max-width: 767px) {
  .sub-top {
    margin-top: 50px;
    margin-bottom: 30px;
    padding-left: 0;
  }
  .sub-top .container {
    padding: 0;
  }
}

.sub-banner {
  height: 400px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  text-align: center;
  position: relative;
}

@media (max-width: 991px) {
  .sub-banner {
    height: 230px;
  }
}

.sub-banner.bg1 {
  background-image: url("/template/resources/images/cont/sub-bg1.png");
}

.sub-banner.bg2 {
  background-image: url("/template/resources/images/cont/sub-bg2.png");
}

.sub-banner.bg3 {
  background-image: url("/template/resources/images/cont/sub-bg3.png");
}

.sub-banner.bg4 {
  background-image: url("/template/resources/images/cont/sub-bg4.png");
}

.sub-banner.bg5 {
  background-image: url("/template/resources/images/cont/sub-bg5.png");
}

.sub-banner.bg6 {
  background-image: url("/template/resources/images/cont/sub-bg6.png");
}

.sub-banner div {
  width: 100%;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 0;
  right: auto;
  transform: translateY(-50%);
}

.sub-banner h3 {
  font-size: 6rem;
  font-weight: 700;
  color: #fff;
}

@media (max-width: 991px) {
  .sub-banner h3 {
    font-size: 4rem;
  }
}

.sub-banner h4 {
  margin-bottom: 20px;
  font-size: 2rem;
  font-weight: 500;
  color: #fff;
}

@media (max-width: 991px) {
  .sub-banner h4 {
    font-size: 1.8rem;
  }
}

.sub-route {
  margin-top: -30px;
  padding: 30px 50px;
  background-color: #222;
  display: inline-block;
  position: relative;
  z-index: 1;
}

@media (max-width: 991px) {
  .sub-route {
    padding: 20px 40px;
  }
}

@media (max-width: 767px) {
  .sub-route {
    padding: 15px;
  }
}

.sub-route li {
  display: inline-block;
  vertical-align: middle;
  font-size: 1.8rem;
  font-weight: 500;
  color: #fff;
}

@media (max-width: 991px) {
  .sub-route li {
    font-size: 1.6rem;
  }
}

@media (max-width: 767px) {
  .sub-route li {
    font-size: 1.2rem;
  }
}

@media (max-width: 767px) {
  .sub-route li img {
    height: 16px;
  }
}

.sub-route li:after {
  content: '';
  width: 3px;
  height: 3px;
  margin: 0 30px;
  display: inline-block;
  background-color: #fff;
  vertical-align: middle;
  position: relative;
  bottom: 2px;
}

@media (max-width: 991px) {
  .sub-route li:after {
    margin: 0 15px;
  }
}

@media (max-width: 767px) {
  .sub-route li:after {
    margin: 0 10px;
    bottom: 1px;
  }
}

.sub-route li:last-child:after {
  display: none;
}

.sub-contents {
  padding-left: 100px;
}

@media (max-width: 767px) {
  .sub-contents {
    padding-left: 0;
  }
}

.sub-contents > div {
  display: none;
}

.sub-contents > div.visible {
  display: block;
}

.sub-tab-wrap {
  margin-bottom: 60px;
}

@media (max-width: 767px) {
  .sub-tab-wrap {
    margin-bottom: 30px;
  }
}

.sub-tab-wrap--two-depth {
  margin-bottom: 80px;
}

@media (max-width: 767px) {
  .sub-tab-wrap--two-depth {
    margin-bottom: 30px;
  }
}

.sub-tab a {
  height: 70px;
  border: 1px solid #ddd;
  background-color: #fff;
  position: relative;
}

@media (max-width: 767px) {
  .sub-tab a {
    width: 100% !important;
    height: 50px;
  }
}

.sub-tab a p {
  width: 100%;
  padding: 0 10px;
  font-size: 1.8rem;
  font-weight: 500;
  color: #666;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 0;
  right: auto;
  transform: translateY(-50%);
  word-break: break-all;
}

.sub-tab a.selected {
  border: 1px solid #9c7e29;
  background-color: #9c7e29;
}

.sub-tab a.selected p {
  color: #fff;
}

.sub-tab-contents > div {
  display: none;
}

.sub-tab-contents > div.visible {
  display: block;
}

.sub-tab--two-depth {
  border-bottom: 1px solid #ddd;
  text-align: center;
}

@media (max-width: 767px) {
  .sub-tab--two-depth {
    border-bottom: none;
  }
}

.sub-tab--two-depth a {
  padding: 0 50px 20px;
  font-size: 1.8rem;
  font-weight: 500;
  color: #999;
  text-align: center;
  position: relative;
}

@media (max-width: 767px) {
  .sub-tab--two-depth a {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
  }
}

.sub-tab--two-depth a.selected {
  color: #222;
}

@media (max-width: 767px) {
  .sub-tab--two-depth a.selected {
    border: 1px solid #222;
    background-color: #222;
    color: #fff;
  }
}

.sub-tab--two-depth a.selected:after {
  content: '';
  width: 100%;
  height: 2px;
  display: inline-block;
  background-color: #222;
  position: absolute;
  top: auto;
  right: auto;
  bottom: -1px;
  left: 0;
}

.paging {
  margin-top: 60px;
  text-align: center;
}

.paging > a {
  width: 40px;
  height: 40px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
}

.paging > a img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.paging ul {
  display: inline-block;
  vertical-align: middle;
}

@media (max-width: 767px) {
  .paging ul {
    margin: 0 5px;
  }
}

.paging li {
  margin: 0 5px;
  display: inline-block;
  vertical-align: middle;
}

@media (max-width: 767px) {
  .paging li {
    margin: 0;
  }
}

.paging li a {
  width: 40px;
  height: 40px;
  font-size: 1.4rem;
  font-weight: 500;
  color: #666;
  line-height: 40px;
}

@media (max-width: 767px) {
  .paging li a {
    width: 30px;
    height: 30px;
    line-height: 30px;
  }
}

.paging li a.selected {
  background-color: #000;
  font-weight: 700;
  color: #fff;
}

@media (max-width: 767px) {
  .paging__first, .paging__last {
    display: none !important;
  }
}

.board-text {
  margin-bottom: 30px;
  padding-right: 100px;
  display: inline-block;
  font-size: 3.6rem;
  font-weight: 700;
  color: #003554;
  position: relative;
}

@media (max-width: 991px) {
  .board-text {
    font-size: 3rem;
  }
}

@media (max-width: 767px) {
  .board-text {
    margin-bottom: 20px;
    font-size: 2.2rem;
    padding-right: 0;
  }
}

.board-text:before {
  content: '';
  width: 67px;
  height: 1px;
  display: inline-block;
  background-color: #003554;
  position: absolute;
  top: auto;
  right: 20px;
  bottom: 0;
  left: auto;
}

@media (max-width: 767px) {
  .board-text:before {
    display: none;
  }
}

.board-text:after {
  content: '';
  width: 10px;
  height: 10px;
  display: inline-block;
  background-color: #003554;
  position: absolute;
  top: auto;
  right: 0;
  bottom: 0;
  left: auto;
}

@media (max-width: 767px) {
  .board-text:after {
    display: none;
  }
}

.board-link {
  margin-bottom: 40px;
}

@media (max-width: 767px) {
  .board-link {
    margin-bottom: 30px;
  }
}

.board-link a {
  margin-right: 10px;
  padding: 0 30px;
  background-color: #003554;
  font-size: 1.8rem;
  font-weight: 500;
  color: #fff;
  vertical-align: middle;
  line-height: 64px;
}

@media (max-width: 767px) {
  .board-link a {
    width: 100%;
    margin-right: 0;
    margin-bottom: 10px;
    text-align: center;
  }
}

.board-link a:last-child {
  margin-right: 0;
  margin-bottom: 0;
}

.board-link a img {
  margin-left: 10px;
  vertical-align: middle;
  position: relative;
  bottom: 2px;
}

.board-search-area {
  margin-bottom: 40px;
  padding: 50px 15px;
  background-color: #f9f9f9;
  text-align: center;
}

@media (max-width: 767px) {
  .board-search-area {
    margin-bottom: 30px;
    padding: 30px 15px;
  }
}

.board-search-option, .board-search-text {
  height: 50px;
  padding: 0 20px;
  border: 1px solid #ddd;
  display: inline-block;
  background-color: #fff;
  font-size: 1.4rem;
  font-weight: 500;
  color: #666;
}

.board-search-option {
  width: 150px;
  margin-right: 5px;
}

@media (max-width: 767px) {
  .board-search-option {
    width: 100%;
    margin-right: 0;
    margin-bottom: 5px;
  }
}

.board-search-text {
  width: 320px;
}

@media (max-width: 767px) {
  .board-search-text {
    width: 100%;
  }
}

.board-search-text::placeholder {
  font-size: 1.4rem;
  font-weight: 500;
  color: #666;
}

.board-search-enter {
  width: 85px;
  height: 50px;
  display: inline-block;
  background-color: #222;
  font-size: 1.4rem;
  font-weight: 500;
  color: #fff;
  text-align: center;
  line-height: 50px;
}

@media (max-width: 767px) {
  .board-search-enter {
    width: 100%;
  }
}

.board-wrap {
  width: 100%;
  word-break: break-all;
}

.board-wrap.ofa {
  overflow-x: auto;
}

.board-wrap.ofa table {
  min-width: 1200px;
}

@media (max-width: 767px) {
  .board-wrap.ofa table {
    width: 800px;
  }
}

.board-wrap table {
  width: 100%;
  border-top: 2px solid #222;
  table-layout: fixed;
}

.board-wrap .w-5 {
  width: 5%;
}

.board-wrap .w-10 {
  width: 10%;
}

.board-wrap .w-15 {
  width: 15%;
}

.board-wrap .w-20 {
  width: 20%;
}

.board-wrap .w-25 {
  width: 25%;
}

.board-wrap .w-30 {
  width: 30%;
}

.board-wrap .w-35 {
  width: 35%;
}

.board-wrap .w-40 {
  width: 40%;
}

.board-wrap .w-45 {
  width: 45%;
}

.board-wrap .w-50 {
  width: 50%;
}

.board-wrap .w-55 {
  width: 55%;
}

.board-wrap .w-60 {
  width: 60%;
}

.board-wrap .w-65 {
  width: 65%;
}

.board-wrap .w-70 {
  width: 70%;
}

.board-wrap .w-75 {
  width: 75%;
}

.board-wrap .w-80 {
  width: 80%;
}

.board-wrap .w-85 {
  width: 85%;
}

.board-wrap .w-90 {
  width: 90%;
}

.board-wrap .w-95 {
  width: 95%;
}

.board-wrap .w-100 {
  width: 100%;
}

.board-wrap .ta-l {
  text-align: left;
}

.board-wrap .ta-c {
  text-align: center;
}

.board-wrap .ta-r {
  text-align: right;
}

.board-wrap .va-t {
  vertical-align: top;
}

.board-wrap .va-m {
  vertical-align: middle;
}

.board-wrap .va-b {
  vertical-align: bottom;
}

@media (max-width: 1200px) {
  .board-wrap .w-md-5 {
    width: 5%;
  }
  .board-wrap .w-md-10 {
    width: 10%;
  }
  .board-wrap .w-md-15 {
    width: 15%;
  }
  .board-wrap .w-md-20 {
    width: 20%;
  }
  .board-wrap .w-md-25 {
    width: 25%;
  }
  .board-wrap .w-md-30 {
    width: 30%;
  }
  .board-wrap .w-md-35 {
    width: 35%;
  }
  .board-wrap .w-md-40 {
    width: 40%;
  }
  .board-wrap .w-md-45 {
    width: 45%;
  }
  .board-wrap .w-md-50 {
    width: 50%;
  }
  .board-wrap .w-md-55 {
    width: 55%;
  }
  .board-wrap .w-md-60 {
    width: 60%;
  }
  .board-wrap .w-md-65 {
    width: 65%;
  }
  .board-wrap .w-md-70 {
    width: 70%;
  }
  .board-wrap .w-md-75 {
    width: 75%;
  }
  .board-wrap .w-md-80 {
    width: 80%;
  }
  .board-wrap .w-md-85 {
    width: 85%;
  }
  .board-wrap .w-md-90 {
    width: 90%;
  }
  .board-wrap .w-md-95 {
    width: 95%;
  }
  .board-wrap .w-md-100 {
    width: 100%;
  }
  .board-wrap .w-md-n {
    display: none;
  }
}

@media (max-width: 991px) {
  .board-wrap .w-sm-5 {
    width: 5%;
  }
  .board-wrap .w-sm-10 {
    width: 10%;
  }
  .board-wrap .w-sm-15 {
    width: 15%;
  }
  .board-wrap .w-sm-20 {
    width: 20%;
  }
  .board-wrap .w-sm-25 {
    width: 25%;
  }
  .board-wrap .w-sm-30 {
    width: 30%;
  }
  .board-wrap .w-sm-35 {
    width: 35%;
  }
  .board-wrap .w-sm-40 {
    width: 40%;
  }
  .board-wrap .w-sm-45 {
    width: 45%;
  }
  .board-wrap .w-sm-50 {
    width: 50%;
  }
  .board-wrap .w-sm-55 {
    width: 55%;
  }
  .board-wrap .w-sm-60 {
    width: 60%;
  }
  .board-wrap .w-sm-65 {
    width: 65%;
  }
  .board-wrap .w-sm-70 {
    width: 70%;
  }
  .board-wrap .w-sm-75 {
    width: 75%;
  }
  .board-wrap .w-sm-80 {
    width: 80%;
  }
  .board-wrap .w-sm-85 {
    width: 85%;
  }
  .board-wrap .w-sm-90 {
    width: 90%;
  }
  .board-wrap .w-sm-95 {
    width: 95%;
  }
  .board-wrap .w-sm-100 {
    width: 100%;
  }
  .board-wrap .w-sm-n {
    display: none;
  }
}

@media (max-width: 767px) {
  .board-wrap .w-xs-5 {
    width: 5%;
  }
  .board-wrap .w-xs-10 {
    width: 10%;
  }
  .board-wrap .w-xs-15 {
    width: 15%;
  }
  .board-wrap .w-xs-20 {
    width: 20%;
  }
  .board-wrap .w-xs-25 {
    width: 25%;
  }
  .board-wrap .w-xs-30 {
    width: 30%;
  }
  .board-wrap .w-xs-35 {
    width: 35%;
  }
  .board-wrap .w-xs-40 {
    width: 40%;
  }
  .board-wrap .w-xs-45 {
    width: 45%;
  }
  .board-wrap .w-xs-50 {
    width: 50%;
  }
  .board-wrap .w-xs-55 {
    width: 55%;
  }
  .board-wrap .w-xs-60 {
    width: 60%;
  }
  .board-wrap .w-xs-65 {
    width: 65%;
  }
  .board-wrap .w-xs-70 {
    width: 70%;
  }
  .board-wrap .w-xs-75 {
    width: 75%;
  }
  .board-wrap .w-xs-80 {
    width: 80%;
  }
  .board-wrap .w-xs-85 {
    width: 85%;
  }
  .board-wrap .w-xs-90 {
    width: 90%;
  }
  .board-wrap .w-xs-95 {
    width: 95%;
  }
  .board-wrap .w-xs-100 {
    width: 100%;
  }
  .board-wrap .w-xs-n {
    display: none;
  }
}

.board-wrap tr {
  border-bottom: 1px solid #ddd;
}

.board-wrap tr.bg {
  background-color: #f9f9f9;
}

.board-wrap th {
  height: 70px;
  font-size: 1.4rem;
  font-weight: 500;
  color: #222;
}

.board-wrap td {
  padding: 30px 5px;
  vertical-align: middle;
}

.board-wrap p {
  font-size: 1.6rem;
  font-weight: 500;
  color: #666;
  text-align: center;
}

.board-wrap h4 {
  width: 45px;
  height: 23px;
  margin: 0 auto;
  background-color: #9c7e29;
  font-size: 1.4rem;
  font-weight: 500;
  color: #fff;
  text-align: center;
  line-height: 23px;
}

.board-wrap a {
  max-width: 100%;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 1.8rem;
  font-weight: 500;
  color: #222;
}

.board-wrap a span {
  margin-right: 5px;
  display: inline-block;
  color: #003554;
}

.board-wrap h3 {
  width: 100%;
}

.board-wrap h3 span {
  margin-right: 5px;
  display: inline-block;
  color: #003554;
}

.board-wrap img {
  margin: 0 auto;
  display: block;
}

.gallery-wrap {
  width: 100%;
}

.gallery-wrap + .paging {
  margin-top: 20px;
}

.gallery-wrap ul {
  padding-top: 40px;
  border-top: 2px solid #222;
}

@media (max-width: 1260px) {
  .gallery-wrap ul {
    padding-top: 20px;
  }
}

.gallery-wrap li {
  width: calc((100% - 80px) / 3);
  margin: 0 40px 40px 0;
  border: 1px solid #ddd;
  display: inline-block;
  vertical-align: top;
}

@media (max-width: 1260px) {
  .gallery-wrap li {
    width: calc((100% - 40px) / 3);
    margin: 0 20px 20px 0;
  }
}

@media (max-width: 991px) {
  .gallery-wrap li {
    width: calc((100% - 20px) / 2);
  }
}

@media (max-width: 767px) {
  .gallery-wrap li {
    width: 100%;
    margin-right: 0;
  }
}

.gallery-wrap li:nth-child(3n) {
  margin-right: 0;
}

@media (max-width: 991px) {
  .gallery-wrap li:nth-child(3n) {
    margin-right: 20px;
  }
}

@media (max-width: 767px) {
  .gallery-wrap li:nth-child(3n) {
    margin-right: 0;
  }
}

@media (max-width: 991px) {
  .gallery-wrap li:nth-child(2n) {
    margin-right: 0;
  }
}

.gallery-wrap a {
  width: 100%;
}

.gallery-img {
  width: 100%;
  padding-bottom: 56.25%;
  position: relative;
  overflow: hidden;
}

.gallery-img img {
  width: 100%;
  position: absolute;
  top: 0;
  right: auto;
  bottom: auto;
  left: 0;
}

.gallery-img.ha {
  padding-bottom: 0;
}

.gallery-img.ha img {
  position: initial;
  transform: none;
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
}

.gallery-text {
  padding: 20px 0;
  border-top: 1px solid #ddd;
  border-top: none;
  text-align: center;
}

.gallery-tit {
  padding: 0 10px;
  font-size: 1.8rem;
  font-weight: 500;
  color: #222;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media (max-width: 1260px) {
  .gallery-tit {
    font-size: 1.6rem;
  }
}

@media (max-width: 767px) {
  .gallery-tit {
    font-size: 1.4rem;
  }
}

.poomsae-board-wrap {
  border-top: 2px solid #222;
}

.poomsae-board-wrap li {
  padding: 40px;
  border-bottom: 1px solid #ddd;
}

@media (max-width: 1150px) {
  .poomsae-board-wrap li {
    padding: 20px;
  }
}

.poomsae-board-wrap li a {
  width: 100%;
}

.poomsae-board-wrap li a > p {
  width: 130px;
  height: 130px;
  display: inline-block;
  background-color: #003554;
  vertical-align: middle;
  position: relative;
}

@media (max-width: 991px) {
  .poomsae-board-wrap li a > p {
    width: 90px;
    height: 215px;
  }
}

@media (max-width: 767px) {
  .poomsae-board-wrap li a > p {
    width: 100%;
    height: 40px;
  }
}

.poomsae-board-wrap li a > p span {
  width: 100%;
  padding-bottom: 30px;
  font-size: 1.6rem;
  font-weight: 500;
  color: #fff;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 0;
  right: auto;
  transform: translateY(-50%);
}

@media (max-width: 991px) {
  .poomsae-board-wrap li a > p span {
    font-size: 1.4rem;
  }
}

@media (max-width: 767px) {
  .poomsae-board-wrap li a > p span {
    padding-bottom: 0;
  }
}

.poomsae-board-wrap li a > p span:after {
  content: '';
  width: 10px;
  height: 10px;
  border-top: 1px solid #fff;
  border-left: 1px solid #fff;
  display: inline-block;
  position: absolute;
  top: auto;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  transform: translateX(-70%) rotate(135deg);
  box-sizing: border-box;
}

@media (max-width: 767px) {
  .poomsae-board-wrap li a > p span:after {
    display: none;
  }
}

.poomsae-board__img {
  width: 320px;
  height: 215px;
  border: 1px solid #ddd;
  display: inline-block;
  background-color: #f9f9f9;
  vertical-align: middle;
  position: relative;
  overflow: hidden;
}

@media (max-width: 1150px) {
  .poomsae-board__img {
    width: 250px;
  }
}

@media (max-width: 991px) {
  .poomsae-board__img {
    width: 180px;
  }
}

@media (max-width: 767px) {
  .poomsae-board__img {
    width: 100%;
    height: auto;
    padding-bottom: 100%;
  }
}

.poomsae-board__img img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (max-width: 991px) {
  .poomsae-board__img img {
    max-height: 90%;
  }
}

.poomsae-board__text {
  width: calc(100% - 450px);
  padding: 0 60px;
  display: inline-block;
  vertical-align: middle;
}

@media (max-width: 1420px) {
  .poomsae-board__text {
    padding: 0 30px;
  }
}

@media (max-width: 1150px) {
  .poomsae-board__text {
    width: calc(100% - 380px);
  }
}

@media (max-width: 991px) {
  .poomsae-board__text {
    width: calc(100% - 270px);
  }
}

@media (max-width: 767px) {
  .poomsae-board__text {
    width: 100%;
    padding: 10px 0;
  }
}

.poomsae-board__text h4 {
  margin-bottom: 20px;
  font-size: 3rem;
  font-weight: 700;
  color: #222;
  line-height: 1;
}

@media (max-width: 1420px) {
  .poomsae-board__text h4 {
    font-size: 2.4rem;
  }
}

@media (max-width: 991px) {
  .poomsae-board__text h4 {
    margin-bottom: 10px;
    font-size: 2.2rem;
  }
}

.poomsae-board__text div {
  height: 14rem;
  font-size: 1.6rem !important;
  font-weight: 500;
  color: #666;
  line-height: 2.6rem;
  overflow: hidden;
}

.poomsae-board__text div * {
  font-size: 1.6rem !important;
  font-weight: 500;
  color: #666;
  line-height: 2.6rem !important;
}

@media (max-width: 991px) {
  .poomsae-board__text div {
    font-size: 1.4rem !important;
    line-height: 2.4rem;
  }
  .poomsae-board__text div * {
    font-size: 1.4rem !important;
  }
}

.view-top {
  padding: 0 40px;
  border-top: 2px solid #222;
  border-bottom: 1px solid #ddd;
}

@media (max-width: 991px) {
  .view-top {
    padding: 0 20px;
  }
}

.view-top__tit {
  padding: 40px 0 30px;
  border-bottom: 1px dashed #ddd;
  position: relative;
}

@media (max-width: 991px) {
  .view-top__tit {
    padding: 20px 0 15px;
  }
}

.view-top__tit h4 {
  max-width: 74px;
  height: 23px;
  margin: 12px 15px 0 auto;
  padding: 0 10px;
  display: inline-block;
  background-color: #9c7e29;
  font-size: 1.4rem;
  font-weight: 500;
  color: #fff;
  text-align: center;
  line-height: 23px;
  vertical-align: top;
}

@media (max-width: 991px) {
  .view-top__tit h4 {
    margin: 0 auto 0;
  }
}

@media (max-width: 767px) {
  .view-top__tit h4 {
    margin: 0 0 10px 0;
    display: block;
  }
}

.view-top__tit h3 {
  max-width: calc(100% - 89px);
  display: inline-block;
  font-size: 3rem;
  font-weight: 700;
  color: #222;
  vertical-align: top;
}

@media (max-width: 991px) {
  .view-top__tit h3 {
    font-size: 2.2rem;
  }
}

@media (max-width: 767px) {
  .view-top__tit h3 {
    padding-left: 0;
    font-size: 2rem;
  }
}

.view-top__info {
  padding: 30px 0 40px;
}

@media (max-width: 991px) {
  .view-top__info {
    padding: 15px 0 20px;
  }
}

.view-top__info li {
  margin: 0 230px 30px 0;
  display: inline-block;
  vertical-align: top;
}

@media (max-width: 1260px) {
  .view-top__info li {
    margin-right: 100px;
  }
}

@media (max-width: 991px) {
  .view-top__info li {
    margin-right: 50px;
  }
}

@media (max-width: 767px) {
  .view-top__info li {
    width: 100%;
    margin: 0 0 15px 0;
  }
}

.view-top__info li:nth-child(3n) {
  margin-right: 0;
}

.view-top__info li.file {
  width: 100%;
  margin: 0;
}

.view-top__info li p {
  padding-left: 20px;
  display: inline-block;
  font-size: 1.6rem;
  font-weight: 500;
  color: #666;
  vertical-align: top;
  position: relative;
}

.view-top__info li p:first-child {
  padding-left: 13px;
}

@media (max-width: 767px) {
  .view-top__info li p:first-child {
    width: 61px;
  }
}

.view-top__info li p:first-child:before {
  content: '';
  width: 3px;
  height: 3px;
  display: inline-block;
  background-color: #666;
  position: absolute;
  top: 50%;
  left: 0;
  right: auto;
  transform: translateY(-50%);
}

.view-top__info li a {
  max-width: 100%;
  margin-bottom: 10px;
  padding-left: 25px;
  font-size: 1.6rem;
  font-weight: 500;
  color: #666;
  text-decoration: underline;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
}

.view-top__info li a:before {
  content: '';
  width: 16px;
  height: 19px;
  display: inline-block;
  background-image: url("/template/resources/images/icons/view-file-icon.png");
  position: absolute;
  top: 50%;
  left: 0;
  right: auto;
  transform: translateY(-50%);
}

.view-top__info__file {
  width: calc(100% - 45px);
  padding-left: 35px;
  display: inline-block;
  vertical-align: top;
}

@media (max-width: 767px) {
  .view-top__info__file {
    width: 100%;
    margin-top: 5px;
    padding-left: 13px;
  }
}

.view-cont {
  padding: 60px 140px 100px;
  border-bottom: 1px solid #ddd;
  font-size: 1.8rem;
  font-weight: 500;
  color: #666;
}

.view-cont img {
  height: auto !important;
  margin: 0 auto;
  display: block;
}

@media (max-width: 1320px) {
  .view-cont {
    padding: 60px 100px 100px;
  }
}

@media (max-width: 1260px) {
  .view-cont {
    padding: 60px 80px 100px;
  }
}

@media (max-width: 991px) {
  .view-cont {
    padding: 30px 20px 50px;
  }
}

@media (max-width: 767px) {
  .view-cont {
    font-size: 1.6rem;
  }
}

.view-cont__video {
  width: 100%;
  padding-bottom: 56.25%;
  position: relative;
}

.view-cont__video video {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: auto;
  bottom: auto;
  left: 0;
}

.view-cont .cont {
  margin-top: 60px;
}

@media (max-width: 767px) {
  .view-cont .cont {
    margin-top: 30px;
  }
}

.view-cont .step {
  margin-top: 10px;
}

.view-cont .step ul {
  margin-top: 0;
}

.view-cont .step ul li {
  width: 220px;
  margin-right: 45px;
  margin-bottom: 0;
  padding-left: 0;
  border: 2px solid #003554;
  display: inline-block;
  font-size: 0;
  position: relative;
  vertical-align: top;
}

@media (max-width: 1200px) {
  .view-cont .step ul li {
    width: 180px;
    margin-right: 30px;
  }
}

@media (max-width: 767px) {
  .view-cont .step ul li {
    width: 100%;
    margin-right: 0;
    margin-bottom: 30px;
  }
}

.view-cont .step ul li:before {
  content: '';
  width: 45px;
  height: 1px;
  border-top: 1px dotted #ccc;
  background-color: #fff;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: calc(100% + 2px);
  right: auto;
  transform: translateY(-50%);
  box-sizing: border-box;
}

@media (max-width: 1200px) {
  .view-cont .step ul li:before {
    width: 30px;
  }
}

@media (max-width: 767px) {
  .view-cont .step ul li:before {
    width: 1px;
    height: 30px;
    border-left: 1px dotted #ccc;
    border-top: none;
    position: absolute;
    top: calc(100% + 2px);
    bottom: auto;
    left: 50%;
    transform: translateX(-50%);
  }
}

.view-cont .step ul li:after {
  content: '';
  width: 12px;
  height: 12px;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 12px solid #ccc;
  border-right: none;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: calc(100% + 20px);
  right: auto;
  transform: translateY(-50%);
  box-sizing: border-box;
}

@media (max-width: 1200px) {
  .view-cont .step ul li:after {
    left: calc(100% + 12px);
  }
}

@media (max-width: 767px) {
  .view-cont .step ul li:after {
    border-top: 12px solid #ccc;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: none;
    position: absolute;
    top: calc(100% + 12px);
    bottom: auto;
    left: 50%;
    transform: translateX(-50%);
  }
}

.view-cont .step ul li:last-child {
  margin-right: 0;
}

.view-cont .step ul li:last-child:before {
  display: none;
}

.view-cont .step ul li:last-child:after {
  display: none;
}

.view-cont .step-tit {
  padding: 9px 5px;
  background-color: #003554;
  font-size: 1.8rem;
  font-weight: 700;
  color: #fff;
  text-align: center;
}

@media (max-width: 1200px) {
  .view-cont .step-tit {
    font-size: 1.6rem;
  }
}

.view-cont .step-text {
  height: 70px;
  position: relative;
}

.view-cont .step-text p {
  width: 100%;
  padding: 0 5px;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 0;
  right: auto;
  transform: translateY(-50%);
  text-align: center;
  box-sizing: border-box;
  line-height: 1.3;
}

@media (max-width: 1200px) {
  .view-cont .step-text p {
    font-size: 1.6rem;
  }
}

.view-cont h3 {
  margin-bottom: 50px;
  display: block;
  font-size: 3.6rem;
  font-weight: 700;
  color: #222;
  text-align: center;
}

@media (max-width: 767px) {
  .view-cont h3 {
    margin-bottom: 30px;
    font-size: 2.6rem;
  }
}

.view-cont h4 {
  margin-bottom: 30px;
  padding-left: 14px;
  font-size: 2.4rem;
  font-weight: 700;
  color: #222;
  position: relative;
}

@media (max-width: 767px) {
  .view-cont h4 {
    margin-bottom: 15px;
    font-size: 1.8rem;
  }
}

.view-cont h4:before {
  content: '';
  width: 4px;
  height: 24px;
  display: inline-block;
  background-color: #9c7e29;
  position: absolute;
  top: 7px;
  right: auto;
  bottom: auto;
  left: 0;
}

@media (max-width: 767px) {
  .view-cont h4:before {
    height: 18px;
    top: 6px;
  }
}

.view-cont h5 {
  margin-bottom: 15px;
  font-size: 1.8rem;
  font-weight: 700;
  color: #9c7e29;
}

@media (max-width: 767px) {
  .view-cont h5 {
    margin-bottom: 10px;
    font-size: 1.6rem;
  }
}

.view-cont b {
  display: block;
  font-size: 2rem;
  font-weight: 700;
  color: #222;
}

@media (max-width: 767px) {
  .view-cont b {
    font-size: 1.8rem;
  }
}

.view-cont p {
  font-size: 1.8rem;
  font-weight: 400;
  color: #666;
  line-height: 3.3rem;
}

@media (max-width: 767px) {
  .view-cont p {
    font-size: 1.6rem;
    line-height: 3rem;
  }
}

.view-cont ul {
  margin-top: 10px;
}

.view-cont ul li {
  margin-bottom: 10px;
  padding-left: 13px;
  font-size: 1.8rem;
  font-weight: 400;
  color: #666;
  position: relative;
}

.view-cont ul li:before {
  content: '';
  width: 3px;
  height: 3px;
  display: inline-block;
  background-color: #666;
  position: absolute;
  top: 12px;
  right: auto;
  bottom: auto;
  left: 0;
}

.view-cont ul li:last-child {
  margin-bottom: 0;
}

.view-cont .bg {
  padding: 20px 40px;
  background-color: #f7f7f7;
}

.view-cont .view-table {
  margin: 20px 0 40px;
  overflow-x: auto;
}

@media (max-width: 767px) {
  .view-cont .view-table {
    margin: 15px 0 25px;
  }
}

.view-cont .view-table .w-5 {
  width: 5%;
}

.view-cont .view-table .w-10 {
  width: 10%;
}

.view-cont .view-table .w-15 {
  width: 15%;
}

.view-cont .view-table .w-20 {
  width: 20%;
}

.view-cont .view-table .w-25 {
  width: 25%;
}

.view-cont .view-table .w-30 {
  width: 30%;
}

.view-cont .view-table .w-35 {
  width: 35%;
}

.view-cont .view-table .w-40 {
  width: 40%;
}

.view-cont .view-table .w-45 {
  width: 45%;
}

.view-cont .view-table .w-50 {
  width: 50%;
}

.view-cont .view-table .w-55 {
  width: 55%;
}

.view-cont .view-table .w-60 {
  width: 60%;
}

.view-cont .view-table .w-65 {
  width: 65%;
}

.view-cont .view-table .w-70 {
  width: 70%;
}

.view-cont .view-table .w-75 {
  width: 75%;
}

.view-cont .view-table .w-80 {
  width: 80%;
}

.view-cont .view-table .w-85 {
  width: 85%;
}

.view-cont .view-table .w-90 {
  width: 90%;
}

.view-cont .view-table .w-95 {
  width: 95%;
}

.view-cont .view-table .w-100 {
  width: 100%;
}

.view-cont .view-table .ta-l {
  text-align: left;
}

.view-cont .view-table .ta-c {
  text-align: center;
}

.view-cont .view-table .ta-r {
  text-align: right;
}

.view-cont .view-table .va-t {
  vertical-align: top;
}

.view-cont .view-table .va-m {
  vertical-align: middle;
}

.view-cont .view-table .va-b {
  vertical-align: bottom;
}

@media (max-width: 1200px) {
  .view-cont .view-table .w-md-5 {
    width: 5%;
  }
  .view-cont .view-table .w-md-10 {
    width: 10%;
  }
  .view-cont .view-table .w-md-15 {
    width: 15%;
  }
  .view-cont .view-table .w-md-20 {
    width: 20%;
  }
  .view-cont .view-table .w-md-25 {
    width: 25%;
  }
  .view-cont .view-table .w-md-30 {
    width: 30%;
  }
  .view-cont .view-table .w-md-35 {
    width: 35%;
  }
  .view-cont .view-table .w-md-40 {
    width: 40%;
  }
  .view-cont .view-table .w-md-45 {
    width: 45%;
  }
  .view-cont .view-table .w-md-50 {
    width: 50%;
  }
  .view-cont .view-table .w-md-55 {
    width: 55%;
  }
  .view-cont .view-table .w-md-60 {
    width: 60%;
  }
  .view-cont .view-table .w-md-65 {
    width: 65%;
  }
  .view-cont .view-table .w-md-70 {
    width: 70%;
  }
  .view-cont .view-table .w-md-75 {
    width: 75%;
  }
  .view-cont .view-table .w-md-80 {
    width: 80%;
  }
  .view-cont .view-table .w-md-85 {
    width: 85%;
  }
  .view-cont .view-table .w-md-90 {
    width: 90%;
  }
  .view-cont .view-table .w-md-95 {
    width: 95%;
  }
  .view-cont .view-table .w-md-100 {
    width: 100%;
  }
  .view-cont .view-table .w-md-n {
    display: none;
  }
}

@media (max-width: 991px) {
  .view-cont .view-table .w-sm-5 {
    width: 5%;
  }
  .view-cont .view-table .w-sm-10 {
    width: 10%;
  }
  .view-cont .view-table .w-sm-15 {
    width: 15%;
  }
  .view-cont .view-table .w-sm-20 {
    width: 20%;
  }
  .view-cont .view-table .w-sm-25 {
    width: 25%;
  }
  .view-cont .view-table .w-sm-30 {
    width: 30%;
  }
  .view-cont .view-table .w-sm-35 {
    width: 35%;
  }
  .view-cont .view-table .w-sm-40 {
    width: 40%;
  }
  .view-cont .view-table .w-sm-45 {
    width: 45%;
  }
  .view-cont .view-table .w-sm-50 {
    width: 50%;
  }
  .view-cont .view-table .w-sm-55 {
    width: 55%;
  }
  .view-cont .view-table .w-sm-60 {
    width: 60%;
  }
  .view-cont .view-table .w-sm-65 {
    width: 65%;
  }
  .view-cont .view-table .w-sm-70 {
    width: 70%;
  }
  .view-cont .view-table .w-sm-75 {
    width: 75%;
  }
  .view-cont .view-table .w-sm-80 {
    width: 80%;
  }
  .view-cont .view-table .w-sm-85 {
    width: 85%;
  }
  .view-cont .view-table .w-sm-90 {
    width: 90%;
  }
  .view-cont .view-table .w-sm-95 {
    width: 95%;
  }
  .view-cont .view-table .w-sm-100 {
    width: 100%;
  }
  .view-cont .view-table .w-sm-n {
    display: none;
  }
}

@media (max-width: 767px) {
  .view-cont .view-table .w-xs-5 {
    width: 5%;
  }
  .view-cont .view-table .w-xs-10 {
    width: 10%;
  }
  .view-cont .view-table .w-xs-15 {
    width: 15%;
  }
  .view-cont .view-table .w-xs-20 {
    width: 20%;
  }
  .view-cont .view-table .w-xs-25 {
    width: 25%;
  }
  .view-cont .view-table .w-xs-30 {
    width: 30%;
  }
  .view-cont .view-table .w-xs-35 {
    width: 35%;
  }
  .view-cont .view-table .w-xs-40 {
    width: 40%;
  }
  .view-cont .view-table .w-xs-45 {
    width: 45%;
  }
  .view-cont .view-table .w-xs-50 {
    width: 50%;
  }
  .view-cont .view-table .w-xs-55 {
    width: 55%;
  }
  .view-cont .view-table .w-xs-60 {
    width: 60%;
  }
  .view-cont .view-table .w-xs-65 {
    width: 65%;
  }
  .view-cont .view-table .w-xs-70 {
    width: 70%;
  }
  .view-cont .view-table .w-xs-75 {
    width: 75%;
  }
  .view-cont .view-table .w-xs-80 {
    width: 80%;
  }
  .view-cont .view-table .w-xs-85 {
    width: 85%;
  }
  .view-cont .view-table .w-xs-90 {
    width: 90%;
  }
  .view-cont .view-table .w-xs-95 {
    width: 95%;
  }
  .view-cont .view-table .w-xs-100 {
    width: 100%;
  }
  .view-cont .view-table .w-xs-n {
    display: none;
  }
}

.view-cont .view-table table {
  width: 100%;
  min-width: 800px;
  border-top: 2px solid #9c7e29;
}

.view-cont .view-table th {
  padding: 25px 10px;
  border: 1px solid #ddd;
  background-color: #f9f9f9;
  font-size: 1.8rem;
  font-weight: 500;
  color: #222;
  text-align: center;
  vertical-align: middle;
}

.view-cont .view-table td {
  padding: 25px 30px;
  border: 1px solid #ddd;
}

.view-cont .view-table ul {
  margin-top: 0;
}

.view-bottom {
  margin-top: 40px;
}

@media (max-width: 991px) {
  .view-bottom {
    margin-top: 20px;
  }
}

.view-bottom > a {
  width: 240px;
  height: 64px;
  margin: 0 auto 40px;
  display: block;
  background-color: #003554;
  font-size: 1.8rem;
  font-weight: 500;
  color: #fff;
  text-align: center;
  line-height: 64px;
}

@media (max-width: 991px) {
  .view-bottom > a {
    margin-bottom: 20px;
  }
}

@media (max-width: 767px) {
  .view-bottom > a {
    width: 100%;
    height: 50px;
    font-size: 1.6rem;
    line-height: 50px;
  }
}

.view-bottom__link li {
  width: calc(50% - 10px);
  display: inline-block;
  vertical-align: top;
}

@media (max-width: 767px) {
  .view-bottom__link li {
    width: 100%;
  }
}

.view-bottom__link li:nth-child(1) {
  margin-right: 20px;
}

@media (max-width: 767px) {
  .view-bottom__link li:nth-child(1) {
    margin-right: 0;
    margin-bottom: 10px;
  }
}

.view-bottom__link li:nth-child(1) a {
  padding: 50px 40px 50px 90px;
  text-align: right;
}

@media (max-width: 991px) {
  .view-bottom__link li:nth-child(1) a {
    padding: 25px 20px 25px 45px;
  }
}

.view-bottom__link li:nth-child(1) a:before {
  content: '';
  width: 13px;
  height: 13px;
  border-top: 2px solid #999;
  border-left: 2px solid #999;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 40px;
  right: auto;
  transform: translateY(-50%);
  transform: translateY(-50%) rotate(-45deg);
}

@media (max-width: 991px) {
  .view-bottom__link li:nth-child(1) a:before {
    left: 20px;
  }
}

.view-bottom__link li:nth-child(2) a {
  padding: 50px 90px 50px 40px;
}

@media (max-width: 991px) {
  .view-bottom__link li:nth-child(2) a {
    padding: 25px 45px 25px 20px;
  }
}

.view-bottom__link li:nth-child(2) a:before {
  content: '';
  width: 13px;
  height: 13px;
  border-top: 2px solid #999;
  border-left: 2px solid #999;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: auto;
  right: 40px;
  transform: translateY(-50%);
  transform: translateY(-50%) rotate(135deg);
}

@media (max-width: 991px) {
  .view-bottom__link li:nth-child(2) a:before {
    right: 20px;
  }
}

.view-bottom__link li a {
  width: 100%;
  border: 1px solid #ddd;
  position: relative;
}

.view-bottom__link li a:hover {
  border: 1px solid #003554;
  background-color: #003554;
  transition-duration: .3s;
}

.view-bottom__link li a:hover:before {
  border-top: 2px solid #fff;
  border-left: 2px solid #fff;
}

.view-bottom__link li a:hover .view-bottom__link__date {
  color: #fff;
}

.view-bottom__link li a:hover .view-bottom__link__tit {
  color: #fff;
}

.view-bottom__link__date {
  margin-bottom: 10px;
  font-size: 1.4rem;
  font-weight: 500;
  color: #666;
}

.view-bottom__link__tit {
  font-size: 1.8rem;
  font-weight: 500;
  color: #666;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.poomsae-view-cont__img {
  width: calc(50% - 30px);
  height: 490px;
  margin-right: 60px;
  border: 1px solid #ddd;
  display: inline-block;
  background-color: #f9f9f9;
  vertical-align: top;
  position: relative;
}

@media (max-width: 991px) {
  .poomsae-view-cont__img {
    width: calc(50% - 15px);
    margin-right: 30px;
  }
}

@media (max-width: 767px) {
  .poomsae-view-cont__img {
    width: 100%;
    height: auto;
    margin-right: 0;
    padding-bottom: 100%;
  }
}

.poomsae-view-cont__img img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 90%;
}

.poomsae-view-cont__text {
  width: calc(50% - 30px);
  padding-bottom: 150px;
  display: inline-block;
  vertical-align: top;
  position: relative;
}

@media (max-width: 991px) {
  .poomsae-view-cont__text {
    width: calc(50% - 15px);
  }
}

@media (max-width: 767px) {
  .poomsae-view-cont__text {
    width: 100%;
    padding-top: 20px;
  }
}

.poomsae-view-cont__text h4 {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px dashed #ddd;
  font-size: 3rem;
  font-weight: 700;
  color: #222;
}

@media (max-width: 767px) {
  .poomsae-view-cont__text h4 {
    font-size: 2.2rem;
  }
}

.poomsae-view-cont__text p {
  font-size: 1.8rem;
  font-weight: 500;
  color: #666;
  line-height: 2.8rem;
}

@media (max-width: 767px) {
  .poomsae-view-cont__text p {
    font-size: 1.6rem;
    line-height: 2.6rem;
  }
}

.poomsae-view-cont__text img {
  position: absolute;
  top: auto;
  right: 0;
  bottom: 0;
  left: auto;
}

.card-view-wrap {
  text-align: center;
}

.card-view-wrap__slide {
  width: 600px;
  margin: 0 auto;
}

@media (max-width: 991px) {
  .card-view-wrap__slide {
    width: 450px;
  }
}

@media (max-width: 767px) {
  .card-view-wrap__slide {
    width: 100%;
  }
}

.card-view-wrap__slide div span {
  width: 100%;
  padding-bottom: 100%;
  display: inline-block;
  position: relative;
}

.card-view-wrap__slide div span img {
  max-width: none;
  height: 100% !important;
  position: absolute;
  top: 0;
  right: auto;
  bottom: auto;
  left: 0;
}

.card-view-wrap .slick-prev {
  width: 60px;
  height: 60px;
  border: 2px solid #000;
  border-radius: 60px;
  background-color: #fff;
  z-index: 2;
  left: -100px;
}

@media (max-width: 767px) {
  .card-view-wrap .slick-prev {
    display: none !important;
  }
}

.card-view-wrap .slick-prev:before {
  content: '';
  width: 17px;
  height: 17px;
  border-top: 2px solid #000;
  border-right: 2px solid #000;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: translate(-30%, -50%) rotate(-135deg);
}

.card-view-wrap .slick-prev:hover {
  border: 2px solid #9c7e29;
  background-color: #9c7e29;
}

.card-view-wrap .slick-prev:hover:before {
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
}

.card-view-wrap .slick-next {
  width: 60px;
  height: 60px;
  border: 2px solid #000;
  border-radius: 60px;
  background-color: #fff;
  z-index: 2;
  right: -100px;
}

@media (max-width: 767px) {
  .card-view-wrap .slick-next {
    display: none !important;
  }
}

.card-view-wrap .slick-next:before {
  content: '';
  width: 17px;
  height: 17px;
  border-top: 2px solid #000;
  border-right: 2px solid #000;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: translate(-70%, -50%) rotate(45deg);
}

.card-view-wrap .slick-next:hover {
  border: 2px solid #9c7e29;
  background-color: #9c7e29;
}

.card-view-wrap .slick-next:hover:before {
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
}

.card-view-wrap__dots .slick-dots li {
  width: 40px;
  height: 40px;
  margin: 0;
  padding: 0;
}

@media (max-width: 767px) {
  .card-view-wrap__dots .slick-dots li {
    width: 30px;
    height: 30px;
  }
}

.card-view-wrap__dots .slick-dots li:before {
  display: none;
}

.card-view-wrap__dots .slick-dots li button {
  width: 100%;
  height: 100%;
  position: relative;
}

.card-view-wrap__dots .slick-dots li button span {
  width: 100%;
  text-align: center;
  font-size: 1.4rem;
  font-weight: 500;
  color: #999;
  position: absolute;
  top: 50%;
  left: 0;
  right: auto;
  transform: translateY(-50%);
}

.card-view-wrap__dots .slick-dots li.slick-active {
  background-color: #000;
}

.card-view-wrap__dots .slick-dots li.slick-active button span {
  color: #fff;
}

.about-contents1__img {
  width: 350px;
  display: inline-block;
  vertical-align: top;
  position: relative;
  text-align: right;
}

@media (max-width: 1420px) {
  .about-contents1__img {
    width: 250px;
  }
}

@media (max-width: 991px) {
  .about-contents1__img {
    width: 350px;
    margin: 0 auto;
    display: block;
    text-align: left;
  }
}

@media (max-width: 767px) {
  .about-contents1__img {
    width: 100%;
    text-align: center;
  }
}

.about-contents1__img img {
  vertical-align: bottom;
}

@media (max-width: 991px) {
  .about-contents1__img img {
    width: 100%;
  }
}

.about-contents1__img__text {
  width: 100%;
  padding: 30px 40px;
  display: inline-block;
  background-color: #13233b;
  text-align: center;
}

@media (max-width: 767px) {
  .about-contents1__img__text {
    padding: 15px;
  }
}

.about-contents1__img__text h4 {
  margin-right: 15px;
  display: inline-block;
  font-size: 1.8rem;
  font-weight: 500;
  color: #fff;
  vertical-align: middle;
}

.about-contents1__img__text p {
  display: inline-block;
  font-size: 3rem;
  font-weight: 700;
  color: #fff;
  font-family: 'HakgyoansimGaeulsopung';
  vertical-align: middle;
}

.about-contents1__text {
  width: calc(100% - 350px);
  padding-left: 80px;
  display: inline-block;
  vertical-align: top;
}

@media (max-width: 1420px) {
  .about-contents1__text {
    width: calc(100% - 250px);
  }
}

@media (max-width: 1050px) {
  .about-contents1__text {
    padding-left: 40px;
  }
}

@media (max-width: 991px) {
  .about-contents1__text {
    width: 100%;
    margin-top: 30px;
    padding-left: 0;
  }
}

@media (max-width: 767px) {
  .about-contents1__text {
    margin-top: 0;
  }
}

.about-contents1__text__top {
  margin-bottom: 50px;
  position: relative;
}

@media (max-width: 767px) {
  .about-contents1__text__top {
    margin-bottom: 15px;
    padding: 15px;
    border: 5px solid #003554;
  }
  .about-contents1__text__top img {
    display: none;
  }
}

.about-contents1__text__top p {
  width: 100%;
  font-size: 4rem;
  font-weight: 700;
  color: #003554;
  line-height: 5.2rem;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 0;
  right: auto;
  transform: translateY(-50%);
  top: 53%;
}

@media (max-width: 1650px) {
  .about-contents1__text__top p {
    font-size: 3rem;
    line-height: 4.2rem;
  }
}

@media (max-width: 1550px) {
  .about-contents1__text__top p {
    font-size: 2.2rem;
    line-height: 3.4rem;
  }
}

@media (max-width: 1050px) {
  .about-contents1__text__top p {
    font-size: 2rem;
    line-height: 3.2rem;
  }
}

@media (max-width: 767px) {
  .about-contents1__text__top p {
    padding: 0 10px;
    font-size: 1.6rem;
    line-height: 2.2rem;
    position: initial;
    transform: none;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
  }
  .about-contents1__text__top p br {
    display: none;
  }
}

.about-contents1__text__top p span {
  margin-bottom: 5px;
  display: block;
  font-size: 2.4rem;
  font-weight: 500;
  color: #003554;
}

@media (max-width: 1550px) {
  .about-contents1__text__top p span {
    margin-bottom: 0;
    font-size: 2rem;
  }
}

@media (max-width: 1050px) {
  .about-contents1__text__top p span {
    font-size: 1.6rem;
  }
}

@media (max-width: 767px) {
  .about-contents1__text__top p span {
    font-size: 1.4rem;
  }
}

.about-contents1__text__bottom p {
  font-size: 2rem;
  font-weight: 500;
  color: #666;
  line-height: 2.8rem;
}

@media (max-width: 1050px) {
  .about-contents1__text__bottom p {
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
}

.about-contents1__list {
  margin-top: 80px;
}

@media (max-width: 767px) {
  .about-contents1__list {
    margin-top: 30px;
  }
}

.about-contents1__list__col {
  width: calc(50% - 20px);
  margin-right: 40px;
  padding: 55px;
  border: 1px solid #ddd;
  display: inline-block;
  vertical-align: top;
}

@media (max-width: 991px) {
  .about-contents1__list__col {
    width: 100%;
    height: auto !important;
    margin-right: 0;
    margin-bottom: 20px;
  }
}

@media (max-width: 767px) {
  .about-contents1__list__col {
    padding: 20px;
  }
}

.about-contents1__list__col:last-child {
  margin-right: 0;
  margin-bottom: 0;
}

.about-contents1__list h4 {
  margin-bottom: 30px;
  padding-right: 100px;
  display: inline-block;
  font-size: 3.6rem;
  font-weight: 700;
  color: #003554;
  line-height: 1;
  position: relative;
}

.about-contents1__list h4:before {
  content: '';
  width: 67px;
  height: 1px;
  display: inline-block;
  background-color: #003554;
  position: absolute;
  top: auto;
  right: 20px;
  bottom: 0;
  left: auto;
}

.about-contents1__list h4:after {
  content: '';
  width: 10px;
  height: 10px;
  display: inline-block;
  background-color: #003554;
  position: absolute;
  top: auto;
  right: 0;
  bottom: 0;
  left: auto;
}

.about-contents1__list ul {
  width: calc((100% - 70px) / 2);
  margin-right: 70px;
  display: inline-block;
  vertical-align: top;
}

@media (max-width: 1050px) {
  .about-contents1__list ul {
    width: calc((100% - 30px) / 2);
    margin-right: 30px;
  }
}

@media (max-width: 767px) {
  .about-contents1__list ul {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
}

.about-contents1__list ul:last-child {
  margin-right: 0;
  margin-bottom: 0;
}

.about-contents1__list li {
  margin-bottom: 20px;
  padding-left: 13px;
  font-size: 1.8rem;
  font-weight: 500;
  color: #666;
  position: relative;
}

@media (max-width: 1420px) {
  .about-contents1__list li {
    font-size: 1.6rem;
  }
}

.about-contents1__list li:last-child {
  margin-bottom: 0;
}

.about-contents1__list li:before {
  content: '';
  width: 3px;
  height: 3px;
  display: inline-block;
  background-color: #666;
  position: absolute;
  top: 10px;
  right: auto;
  bottom: auto;
  left: 0;
}

.history-banner {
  width: 100%;
}

.history-wrap {
  padding: 0 80px;
  background-color: #f9f9f9;
  position: relative;
}

@media (max-width: 1150px) {
  .history-wrap {
    padding: 0 40px;
  }
}

@media (max-width: 991px) {
  .history-wrap {
    padding: 0 20px;
  }
}

@media (max-width: 767px) {
  .history-wrap {
    padding: 0 15px;
  }
}

.history-fixed {
  width: 350px;
  margin-top: -80px;
  padding: 30px 50px 30px 0;
  display: inline-block;
  background-color: #003554;
  vertical-align: top;
  position: sticky;
  top: 115px;
  box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.3);
  z-index: 1;
}

@media (max-width: 1150px) {
  .history-fixed {
    width: 250px;
    padding: 30px 20px 30px 0;
  }
}

@media (max-width: 991px) {
  .history-fixed {
    width: calc(100% + 40px);
    margin-left: -20px;
    padding: 20px 10px 20px 0;
  }
}

@media (max-width: 767px) {
  .history-fixed {
    width: calc(100% + 30px);
    margin: -5px 0 0 -15px;
    padding: 10px 10px 10px 0;
    top: 50px;
  }
}

.history-fixed .bg-text {
  margin: 0 -50px 10px 0;
  font-size: 4rem;
  font-weight: 700;
  color: rgba(255, 255, 255, 0.1);
  text-align: right;
}

@media (max-width: 1150px) {
  .history-fixed .bg-text {
    margin: 0 -20px 10px 0;
  }
}

@media (max-width: 991px) {
  .history-fixed .bg-text {
    margin: 0 -10px 0 0;
    font-size: 3rem;
  }
}

@media (max-width: 767px) {
  .history-fixed .bg-text {
    display: none;
  }
}

.history-fixed h3 {
  margin-bottom: 15px;
  padding: 0 0 20px 40px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  font-size: 3.6rem;
  font-weight: 700;
  color: #fff;
}

@media (max-width: 1150px) {
  .history-fixed h3 {
    padding-left: 20px;
    font-size: 3.2rem;
  }
}

@media (max-width: 991px) {
  .history-fixed h3 {
    padding: 0 0 10px 10px;
    font-size: 2.6rem;
  }
}

@media (max-width: 767px) {
  .history-fixed h3 {
    margin-bottom: 0;
    padding: 0 0 0 10px;
    border-bottom: none;
  }
}

.history-fixed p {
  padding-left: 40px;
  font-size: 1.6rem;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.5);
}

@media (max-width: 1150px) {
  .history-fixed p {
    padding-left: 20px;
    font-size: 1.4rem;
  }
}

@media (max-width: 991px) {
  .history-fixed p {
    padding-left: 10px;
  }
}

@media (max-width: 767px) {
  .history-fixed p {
    display: none;
  }
}

.history-list {
  width: calc(100% - 350px);
  padding: 20px 0 0 210px;
  display: inline-block;
  vertical-align: top;
  position: relative;
  overflow: hidden;
}

@media (max-width: 1420px) {
  .history-list {
    padding: 20px 0 0 40px;
  }
}

@media (max-width: 1150px) {
  .history-list {
    width: calc(100% - 250px);
  }
}

@media (max-width: 991px) {
  .history-list {
    width: 100%;
    padding: 20px 0 0 0;
  }
}

@media (max-width: 767px) {
  .history-list {
    padding: 0;
  }
}

.history-list__line {
  width: 1px;
  background-color: #ddd;
  position: absolute;
  top: 0;
}

@media (max-width: 767px) {
  .history-list__line {
    display: none;
  }
}

.history-list__wrap {
  padding-top: 80px;
}

@media (max-width: 767px) {
  .history-list__wrap {
    padding-top: 40px;
  }
}

.history-list__wrap:last-child {
  padding-bottom: 100px;
}

@media (max-width: 767px) {
  .history-list__wrap:last-child {
    padding-bottom: 40px;
  }
}

.history-list__wrap h3 {
  width: 160px;
  padding-right: 60px;
  display: inline-block;
  vertical-align: top;
  font-size: 3.6rem;
  font-weight: 700;
  color: #222;
  line-height: 1;
  position: relative;
}

@media (max-width: 1150px) {
  .history-list__wrap h3 {
    width: 130px;
    padding-right: 30px;
  }
}

@media (max-width: 767px) {
  .history-list__wrap h3 {
    width: 100%;
    margin-bottom: 5px;
    padding-right: 0;
  }
}

.history-list__wrap h3:after {
  content: '';
  width: 23px;
  height: 23px;
  display: inline-block;
  background-image: url("/template/resources/images/icons/history-list-icon.png");
  background-size: cover;
  position: absolute;
  top: 50%;
  left: auto;
  right: -12px;
  transform: translateY(-50%);
}

@media (max-width: 767px) {
  .history-list__wrap h3:after {
    display: none;
  }
}

.history-list__info {
  width: calc(100% - 160px);
  padding: 5px 0 0 50px;
  display: inline-block;
  vertical-align: top;
}

@media (max-width: 1150px) {
  .history-list__info {
    width: calc(100% - 130px);
    padding: 5px 0 0 30px;
  }
}

@media (max-width: 767px) {
  .history-list__info {
    width: 100%;
    padding: 5px 0 0 0;
  }
}

.history-list__info li {
  margin-bottom: 15px;
  padding-left: 80px;
  position: relative;
}

@media (max-width: 767px) {
  .history-list__info li {
    padding-left: 0;
  }
}

.history-list__info li:last-child {
  margin-bottom: 0;
}

.history-list__info__date {
  width: 80px;
  display: inline-block;
  font-size: 1.6rem;
  font-weight: 700;
  color: #9c7e29;
  vertical-align: top;
  position: absolute;
  top: 0;
  right: auto;
  bottom: auto;
  left: 0;
}

@media (max-width: 767px) {
  .history-list__info__date {
    width: 100%;
    margin-bottom: 5px;
    position: initial;
    transform: none;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
  }
}

.history-list__info__text {
  width: 100%;
  padding-left: 13px;
  display: inline-block;
  font-size: 1.6rem;
  font-weight: 500;
  color: #666;
  vertical-align: top;
  position: relative;
}

.history-list__info__text:before {
  content: '';
  width: 3px;
  height: 3px;
  display: inline-block;
  background-color: #666;
  position: absolute;
  top: 12px;
  right: auto;
  bottom: auto;
  left: 0;
}

.faq-wrap {
  border-top: 2px solid #222;
}

.faq-wrap li {
  border-bottom: 1px solid #ddd;
}

.faq-q {
  width: 100%;
  padding: 30px 40px;
  position: relative;
}

@media (max-width: 767px) {
  .faq-q {
    padding: 15px 15px 30px;
  }
}

.faq-q__mark {
  width: 50px;
  height: 50px;
  margin-right: 30px;
  border-radius: 50px;
  display: inline-block;
  background-color: #9c7e29;
  font-size: 2.5rem;
  font-weight: 700;
  color: #fff;
  text-align: center;
  line-height: 48px;
  vertical-align: middle;
}

@media (max-width: 767px) {
  .faq-q__mark {
    width: 30px;
    height: 30px;
    margin-right: 15px;
    font-size: 1.6rem;
    line-height: 28px;
  }
}

.faq-q__text {
  width: calc(100% - 80px);
  padding-right: 100px;
  display: inline-block;
  font-size: 1.8rem;
  font-weight: 500;
  color: #222;
  vertical-align: middle;
  position: relative;
}

@media (max-width: 767px) {
  .faq-q__text {
    width: calc(100% - 45px);
    padding-right: 10px;
    font-size: 1.4rem;
    position: initial;
    transform: none;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
  }
}

.faq-q__text:after {
  content: '';
  width: 18px;
  height: 18px;
  border-top: 2px solid #999;
  border-left: 2px solid #999;
  position: absolute;
  top: 50%;
  left: auto;
  right: 40px;
  transform: translateY(-50%);
  transform: translateY(-70%) rotate(-135deg);
}

@media (max-width: 767px) {
  .faq-q__text:after {
    width: 15px;
    height: 15px;
    position: absolute;
    top: auto;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    transform: translateX(-50%) rotate(-135deg);
  }
}

.faq-q.click .faq-q__text:after {
  transform: translateY(-30%) rotate(45deg);
}

@media (max-width: 767px) {
  .faq-q.click .faq-q__text:after {
    transform: translateX(-50%) translateY(8px) rotate(45deg);
  }
}

.faq-a {
  height: 0;
  background-color: #f9f9f9;
  overflow: hidden;
}

.faq-a > div {
  padding: 40px;
}

@media (max-width: 767px) {
  .faq-a > div {
    padding: 15px;
  }
}

.faq-a__mark {
  width: 50px;
  height: 50px;
  margin-right: 30px;
  border-radius: 50px;
  display: inline-block;
  background-color: #003554;
  font-size: 2.5rem;
  font-weight: 700;
  color: #fff;
  text-align: center;
  line-height: 48px;
  vertical-align: top;
}

@media (max-width: 767px) {
  .faq-a__mark {
    width: 30px;
    height: 30px;
    margin-bottom: 10px;
    font-size: 1.6rem;
    line-height: 28px;
  }
}

.faq-a__text {
  width: calc(100% - 80px);
  display: inline-block;
  vertical-align: top;
}

.faq-a__text p {
  font-size: 1.8rem;
  font-weight: 500;
  color: #222;
}

@media (max-width: 767px) {
  .faq-a__text p {
    font-size: 1.4rem;
  }
}

@media (max-width: 767px) {
  .faq-a__text {
    width: 100%;
  }
}

.director-list > ul > li {
  width: calc(50% - 20px);
  margin: 0 40px 40px 0;
  border: 1px solid #ddd;
  border-top: 2px solid #9c7e29;
  display: inline-block;
  background-color: #f9f9f9;
  vertical-align: top;
}

@media (max-width: 1260px) {
  .director-list > ul > li {
    width: calc(50% - 10px);
    margin: 0 20px 20px 0;
  }
}

@media (max-width: 991px) {
  .director-list > ul > li {
    width: 100%;
    margin-right: 0;
  }
}

.director-list > ul > li:nth-child(2n) {
  margin-right: 0;
}

.director-list > ul > li:nth-last-child(1) {
  margin-bottom: 0;
}

.director-list > ul > li:nth-last-child(2) {
  margin-bottom: 0;
}

@media (max-width: 991px) {
  .director-list > ul > li:nth-last-child(2) {
    margin-bottom: 20px;
  }
}

.director-list__img {
  width: 225px;
  display: inline-block;
  background-color: #f9f9f9;
  vertical-align: middle;
  position: relative;
}

@media (max-width: 1420px) {
  .director-list__img {
    width: 180px;
  }
}

@media (max-width: 767px) {
  .director-list__img {
    width: 100%;
    padding: 15px 0;
    text-align: center;
  }
}

.director-list__img img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (max-width: 767px) {
  .director-list__img img {
    position: initial;
    transform: none;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
  }
}

.director-list__text {
  width: calc(100% - 225px);
  padding: 50px;
  display: inline-block;
  background-color: #fff;
  vertical-align: middle;
}

@media (max-width: 1420px) {
  .director-list__text {
    width: calc(100% - 180px);
    padding: 30px;
  }
}

@media (max-width: 991px) {
  .director-list__text {
    height: auto !important;
  }
}

@media (max-width: 767px) {
  .director-list__text {
    width: 100%;
    padding: 15px;
  }
}

.director-list__text h3 {
  padding-bottom: 10px;
  border-bottom: 1px dashed #ddd;
  font-size: 2.4rem;
  font-weight: 500;
  color: #222;
}

.director-list__text h3 span {
  color: #9c7e29;
}

.director-list__text ul {
  padding-top: 20px;
}

.director-list__text li {
  margin-bottom: 20px;
  padding-left: 12px;
  font-size: 1.6rem;
  font-weight: 500;
  color: #666;
  word-break: break-all;
  position: relative;
}

.director-list__text li:before {
  content: '';
  width: 5px;
  height: 1px;
  display: inline-block;
  background-color: #666;
  position: absolute;
  top: 12px;
  right: auto;
  bottom: auto;
  left: 0;
}

.director-list__text li:last-child {
  margin-bottom: 0;
}

.organization-list__area0 {
  margin-bottom: 30px;
  text-align: center;
  position: relative;
}

.organization-list__area0:after {
  content: '';
  width: 1px;
  height: 30px;
  display: inline-block;
  background-color: #ddd;
  position: absolute;
  top: 100%;
  bottom: auto;
  left: 50%;
  transform: translateX(-50%);
}

.organization-list__area0 a {
  width: 140px;
  height: 140px;
  border-radius: 140px;
  display: inline-block;
  background-color: #162038;
  font-size: 2rem;
  font-weight: 500;
  color: #fff;
  line-height: 140px;
  position: relative;
  z-index: 1;
}

@media (max-width: 767px) {
  .organization-list__area0 a {
    width: 75px;
    height: 75px;
    line-height: 75px;
    font-size: 1.8rem;
  }
}

.organization-list__area0 div {
  padding-top: 79px;
  position: absolute;
  top: 50%;
  left: auto;
  right: calc((50% - 97px) - 250px);
  transform: translateY(-50%);
  z-index: 0;
}

@media (max-width: 991px) {
  .organization-list__area0 div {
    right: calc((50% - 97px) - 200px);
  }
}

@media (max-width: 767px) {
  .organization-list__area0 div {
    width: 80px;
    padding-top: 51px;
    right: calc((50% - 40px) - 85px);
  }
}

.organization-list__area0 div:after {
  content: '';
  width: 150px;
  height: 1px;
  display: inline-block;
  background-color: #ddd;
  position: absolute;
  top: 50%;
  left: auto;
  right: 100%;
  transform: translateY(-50%);
}

@media (max-width: 767px) {
  .organization-list__area0 div:after {
    width: 100%;
  }
}

.organization-list__area0 div a {
  width: 195px;
  height: 60px;
  border-radius: 0;
  display: block;
  background-color: #444;
  font-size: 1.8rem;
  font-weight: 500;
  color: #fff;
  line-height: 60px;
  position: relative;
}

@media (max-width: 767px) {
  .organization-list__area0 div a {
    width: 75px;
    height: 40px;
    font-size: 1.4rem;
    line-height: 40px;
  }
}

.organization-list__area0 div a:first-child {
  margin-bottom: 20px;
}

@media (max-width: 767px) {
  .organization-list__area0 div a:first-child {
    margin-bottom: 10px;
  }
}

.organization-list__area0 div a:first-child:before {
  content: '';
  width: 1px;
  height: calc(100% + 20px);
  display: inline-block;
  background-color: #ddd;
  position: absolute;
  top: 50%;
  right: auto;
  bottom: auto;
  left: -20px;
}

@media (max-width: 767px) {
  .organization-list__area0 div a:first-child:before {
    height: calc(100% + 10px);
    left: -5px;
  }
}

.organization-list__area0 div a:last-child:before {
  content: '';
  width: 20px;
  height: 1px;
  display: inline-block;
  background-color: #ddd;
  position: absolute;
  top: 50%;
  left: auto;
  right: 100%;
  transform: translateY(-50%);
}

@media (max-width: 767px) {
  .organization-list__area0 div a:last-child:before {
    width: 5px;
  }
}

.organization-list__area1 {
  text-align: center;
  position: relative;
}

.organization-list__area1 a {
  width: 140px;
  height: 140px;
  border-radius: 140px;
  display: inline-block;
  background-color: #162038;
  font-size: 2rem;
  font-weight: 500;
  color: #fff;
  line-height: 140px;
}

@media (max-width: 767px) {
  .organization-list__area1 a {
    width: 75px;
    height: 75px;
    line-height: 75px;
    font-size: 1.8rem;
  }
}

.organization-list__area2 {
  padding: 60px 0;
  position: relative;
}

@media (max-width: 767px) {
  .organization-list__area2 {
    padding: 15px 0 0;
  }
}

.organization-list__area2:after {
  content: '';
  width: 1px;
  height: 100%;
  display: inline-block;
  background-color: #ddd;
  position: absolute;
  top: 0;
  bottom: auto;
  left: 50%;
  transform: translateX(-50%);
}

.organization-list__area2 > div {
  width: 50%;
  display: inline-block;
  vertical-align: middle;
}

.organization-list__area2 a {
  width: 200px;
  height: 60px;
  margin-bottom: 20px;
  background-color: #444;
  font-size: 1.8rem;
  font-weight: 500;
  color: #fff;
  text-align: center;
  position: relative;
  line-height: 60px;
}

@media (max-width: 1200px) {
  .organization-list__area2 a {
    width: 130px;
  }
}

@media (max-width: 991px) {
  .organization-list__area2 a {
    width: 115px;
  }
}

@media (max-width: 767px) {
  .organization-list__area2 a {
    width: 55px;
    height: 40px;
    font-size: 1.2rem;
    line-height: 40px;
  }
}

.organization-list__area2 a:last-child {
  margin-bottom: 0;
}

.organization-list__area2__left {
  padding-right: 150px;
  text-align: right;
}

@media (max-width: 991px) {
  .organization-list__area2__left {
    padding-right: 60px;
  }
}

@media (max-width: 767px) {
  .organization-list__area2__left {
    padding-right: 0;
    padding-bottom: 0;
    text-align: left;
  }
}

.organization-list__area2__left a:nth-of-type(1) {
  margin-bottom: 80px;
}

@media (max-width: 767px) {
  .organization-list__area2__left a:nth-of-type(1) {
    margin-bottom: 20px;
  }
}

.organization-list__area2__left a:nth-of-type(1):after {
  content: '';
  width: 150px;
  height: 1px;
  display: inline-block;
  background-color: #ddd;
  position: absolute;
  top: 50%;
  left: 100%;
  right: auto;
  transform: translateY(-50%);
}

@media (max-width: 991px) {
  .organization-list__area2__left a:nth-of-type(1):after {
    width: 60px;
  }
}

@media (max-width: 767px) {
  .organization-list__area2__left a:nth-of-type(1):after {
    display: none;
  }
}

.organization-list__area2__right {
  padding-left: 150px;
  position: relative;
}

@media (max-width: 1320px) {
  .organization-list__area2__right {
    padding-left: 80px;
  }
}

@media (max-width: 991px) {
  .organization-list__area2__right {
    padding-left: 40px;
  }
}

@media (max-width: 767px) {
  .organization-list__area2__right {
    padding-left: 10px;
  }
}

.organization-list__area2__right a {
  margin-right: 20px;
}

@media (max-width: 767px) {
  .organization-list__area2__right a {
    margin-right: 5px;
  }
}

.organization-list__area2__right a:after {
  content: '';
  width: 1px;
  height: 20px;
  display: inline-block;
  background-color: #ddd;
  position: absolute;
  top: auto;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 767px) {
  .organization-list__area2__right a:after {
    height: 10px;
  }
}

.organization-list__area2__right a:nth-of-type(1) {
  margin: 0 0 20px 0;
}

@media (max-width: 767px) {
  .organization-list__area2__right a:nth-of-type(1) {
    margin: 0 0 20px 0;
  }
}

.organization-list__area2__right a:nth-of-type(1):after {
  content: '';
  width: 150px;
  height: 1px;
  display: inline-block;
  background-color: #ddd;
  position: absolute;
  top: 50%;
  left: auto;
  right: 100%;
  transform: translateY(-50%);
}

@media (max-width: 1320px) {
  .organization-list__area2__right a:nth-of-type(1):after {
    width: 80px;
  }
}

@media (max-width: 991px) {
  .organization-list__area2__right a:nth-of-type(1):after {
    width: 40px;
  }
}

@media (max-width: 767px) {
  .organization-list__area2__right a:nth-of-type(1):after {
    width: 10px;
  }
}

.organization-list__area2__right a:nth-of-type(1):before {
  content: '';
  width: 1px;
  height: 20px;
  display: inline-block;
  background-color: #ddd;
  position: absolute;
  top: 100%;
  bottom: auto;
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 767px) {
  .organization-list__area2__right a:nth-of-type(1):before {
    height: 10px;
  }
}

.organization-list__area2__right a:last-child {
  margin-right: 0;
}

.organization-list__area3 {
  padding-top: 60px;
  position: relative;
  word-break: break-all;
}

@media (max-width: 767px) {
  .organization-list__area3 {
    padding-top: 15px;
  }
}

.organization-list__area3 > span {
  content: '';
  height: 1px;
  display: inline-block;
  background-color: #ddd;
}

@media (max-width: 767px) {
  .organization-list__area3 > span {
    display: none;
  }
}

.organization-list__area3 a {
  width: 100%;
  max-width: 244px;
  height: 60px;
}

@media (max-width: 1720px) {
  .organization-list__area3 a {
    max-width: 211px;
  }
}

@media (max-width: 1520px) {
  .organization-list__area3 a {
    max-width: 178px;
  }
}

@media (max-width: 1320px) {
  .organization-list__area3 a {
    max-width: 145px;
  }
}

@media (max-width: 1200px) {
  .organization-list__area3 a {
    max-width: 120px;
  }
}

@media (max-width: 767px) {
  .organization-list__area3 a {
    max-width: none;
  }
}

.organization-list__area3 .null {
  width: calc(((100% - (65px * 2)) / 6) * 1);
  margin: 60px 0 30px;
  height: 60px;
}

@media (max-width: 767px) {
  .organization-list__area3 .null {
    display: none;
  }
}

.organization-list__area3__col {
  display: inline-block;
  vertical-align: top;
  text-align: center;
}

@media (max-width: 767px) {
  .organization-list__area3__col {
    padding-bottom: 30px;
    border-bottom: 1px solid #ddd;
  }
}

.organization-list__area3__col .f-depth > li > a {
  background-color: #003554;
  position: relative;
  z-index: 1;
}

.organization-list__area3__col .f-depth > li > a p {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  right: auto;
  transform: translateY(-50%);
  font-size: 1.8rem;
  font-weight: 500;
  color: #fff;
}

@media (max-width: 1150px) {
  .organization-list__area3__col .f-depth > li > a p {
    font-size: 1.4rem;
  }
}

.organization-list__area3__col .f-depth > li > a:before {
  content: '';
  width: 1px;
  height: 60px;
  display: inline-block;
  background-color: #ddd;
  position: absolute;
  top: auto;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.organization-list__area3__col .f-depth > li > .null {
  margin-top: 0;
}

.organization-list__area3__col .s-depth > li > a {
  margin: 60px 0 30px;
  border: 2px solid #003554;
  background-color: #fff;
  position: relative;
  z-index: 1;
}

@media (max-width: 767px) {
  .organization-list__area3__col .s-depth > li > a {
    margin: 30px 0 0;
  }
}

.organization-list__area3__col .s-depth > li > a p {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  right: auto;
  transform: translateY(-50%);
  font-size: 1.8rem;
  font-weight: 500;
  color: #003554;
}

@media (max-width: 1150px) {
  .organization-list__area3__col .s-depth > li > a p {
    font-size: 1.4rem;
  }
}

.organization-list__area3__col .s-depth > li > a:before {
  content: '';
  width: 1px;
  height: 60px;
  display: inline-block;
  background-color: #ddd;
  position: absolute;
  top: auto;
  bottom: calc(100% + 2px);
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 767px) {
  .organization-list__area3__col .s-depth > li > a:before {
    display: none;
  }
}

.organization-list__area3__col .s-depth > li > a:after {
  content: '';
  width: 1px;
  height: 30px;
  display: inline-block;
  background-color: #ddd;
  position: absolute;
  top: calc(100% + 2px);
  bottom: auto;
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 767px) {
  .organization-list__area3__col .s-depth > li > a:after {
    display: none;
  }
}

.organization-list__area3__col__wrap {
  padding-top: 30px;
  position: relative;
}

@media (max-width: 767px) {
  .organization-list__area3__col__wrap {
    padding-top: 0;
  }
}

.organization-list__area3__col__wrap.n-line:before {
  display: none;
}

.organization-list__area3__col__wrap.n-line:before {
  content: '';
  width: 1px;
  height: 300px;
  display: inline-block;
  background-color: #ddd;
  position: absolute;
  top: auto;
  bottom: calc(100% - 30px);
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 767px) {
  .organization-list__area3__col__wrap.n-line:before {
    display: none;
  }
}

.organization-list__area3__col__wrap.n-line li a:first-child:before {
  display: none;
}

.organization-list__area3__col__wrap:before {
  content: '';
  width: calc(66% + 8px);
  height: 1px;
  display: inline-block;
  background-color: #ddd;
  position: absolute;
  top: 0;
  bottom: auto;
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 767px) {
  .organization-list__area3__col__wrap:before {
    display: none;
  }
}

.organization-list__area3__col__wrap .t-depth li.col-1 {
  width: 100%;
}

.organization-list__area3__col__wrap .t-depth li:last-child {
  margin-right: 0;
}

.organization-list__area3__col__wrap .t-depth li a {
  margin-top: 10px;
  border: 2px solid #ccc;
  position: relative;
}

.organization-list__area3__col__wrap .t-depth li a p {
  width: 100%;
  font-size: 1.8rem;
  font-weight: 500;
  color: #666;
  position: absolute;
  top: 50%;
  left: 0;
  right: auto;
  transform: translateY(-50%);
}

@media (max-width: 1200px) {
  .organization-list__area3__col__wrap .t-depth li a p {
    font-size: 1.4rem;
  }
}

.organization-list__area3__col__wrap .t-depth > li {
  width: calc((100% - 20px) / 3);
  margin-top: 0;
  margin-right: 10px;
  display: inline-block;
  vertical-align: top;
  position: relative;
}

@media (max-width: 767px) {
  .organization-list__area3__col__wrap .t-depth > li {
    width: 100%;
    margin-top: 15px;
    margin-right: 0;
  }
}

.organization-list__area3__col__wrap .t-depth > li > a {
  margin-top: 0;
  border: 2px solid #9c7e29;
  background-color: #9c7e29;
}

.organization-list__area3__col__wrap .t-depth > li > a p {
  width: 100%;
  font-size: 1.8rem;
  font-weight: 500;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 0;
  right: auto;
  transform: translateY(-50%);
}

@media (max-width: 1200px) {
  .organization-list__area3__col__wrap .t-depth > li > a p {
    font-size: 1.4rem;
  }
}

.organization-list__area3__col__wrap .t-depth > li:before {
  content: '';
  width: 1px;
  height: 30px;
  display: inline-block;
  background-color: #ddd;
  position: absolute;
  top: auto;
  bottom: calc(100%);
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 767px) {
  .organization-list__area3__col__wrap .t-depth > li:before {
    display: none;
  }
}

.organization-list__area3__col.col1 {
  width: calc(((100% - (65px * 2 + 30px)) / 6) * 4 + 30px);
  margin-right: 65px;
}

@media (max-width: 991px) {
  .organization-list__area3__col.col1 {
    width: calc(((100% - (10px * 2 + 30px)) / 6) * 4 + 30px);
    margin-right: 10px;
  }
}

@media (max-width: 767px) {
  .organization-list__area3__col.col1 {
    width: 100%;
    margin-right: 0;
    margin-bottom: 30px;
  }
}

.organization-list__area3__col.col1 .f-depth > li > a {
  margin-right: 250px;
  position: relative;
}

@media (max-width: 1720px) {
  .organization-list__area3__col.col1 .f-depth > li > a {
    margin-right: 200px;
  }
}

@media (max-width: 1520px) {
  .organization-list__area3__col.col1 .f-depth > li > a {
    margin-right: 170px;
  }
}

@media (max-width: 1320px) {
  .organization-list__area3__col.col1 .f-depth > li > a {
    margin-right: 130px;
  }
}

@media (max-width: 1200px) {
  .organization-list__area3__col.col1 .f-depth > li > a {
    margin-right: 110px;
  }
}

@media (max-width: 767px) {
  .organization-list__area3__col.col1 .f-depth > li > a {
    margin-right: 0;
  }
}

.organization-list__area3__col.col1 .f-depth > li > a:after {
  content: '';
  width: 1px;
  height: 30px;
  display: inline-block;
  background-color: #ddd;
  position: absolute;
  top: 100%;
  bottom: auto;
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 767px) {
  .organization-list__area3__col.col1 .f-depth > li > a:after {
    display: none;
  }
}

.organization-list__area3__col.col1 .f-depth > li > div {
  position: relative;
}

.organization-list__area3__col.col1 .f-depth > li > div:before {
  content: '';
  width: calc(((100% / 4) * 2) + 6px);
  height: 1px;
  display: inline-block;
  background-color: #ddd;
  position: absolute;
  top: 30px;
  right: auto;
  bottom: auto;
  left: calc((((100% - 30px) / 4) * .5) + 2px);
}

@media (max-width: 767px) {
  .organization-list__area3__col.col1 .f-depth > li > div:before {
    display: none;
  }
}

.organization-list__area3__col.col1 .s-depth {
  display: inline-block;
  vertical-align: top;
}

.organization-list__area3__col.col1 .s-depth:first-of-type {
  width: calc(((100% - 10px) / 4) * 1);
  margin-right: 10px;
}

@media (max-width: 767px) {
  .organization-list__area3__col.col1 .s-depth:first-of-type {
    width: 100%;
  }
}

.organization-list__area3__col.col1 .s-depth:first-of-type .organization-list__area3__col__wrap:before {
  display: none;
}

.organization-list__area3__col.col1 .s-depth:last-of-type {
  width: calc(((100% - 10px) / 4) * 3);
}

@media (max-width: 767px) {
  .organization-list__area3__col.col1 .s-depth:last-of-type {
    width: 100%;
  }
}

.organization-list__area3__col.col1 .s-depth > li a:before {
  height: 30px;
}

.organization-list__area3__col.col2 {
  width: calc(((100% - (65px * 2 + 30px)) / 6) * 1);
  margin-right: 65px;
}

@media (max-width: 991px) {
  .organization-list__area3__col.col2 {
    width: calc(((100% - (10px * 2 + 30px)) / 6) * 1);
    margin-right: 10px;
  }
}

@media (max-width: 767px) {
  .organization-list__area3__col.col2 {
    width: 100%;
    margin-right: 0;
    margin-bottom: 30px;
  }
}

.organization-list__area3__col.col2 .f-depth > li a:before {
  display: none;
}

.organization-list__area3__col.col2 .s-depth > li > a:before {
  display: none;
}

.organization-list__area3__col.col2 .s-depth > li > a:after {
  display: none;
}

.organization-list__area3__col.col2 .t-depth > li:before {
  display: none;
}

.organization-list__area3__col.col3 {
  width: calc(((100% - (65px * 2 + 30px)) / 6) * 1);
}

@media (max-width: 991px) {
  .organization-list__area3__col.col3 {
    width: calc(((100% - (10px * 2 + 30px)) / 6) * 1);
  }
}

@media (max-width: 767px) {
  .organization-list__area3__col.col3 {
    width: 100%;
    margin-right: 0;
    margin-bottom: 30px;
  }
}

.organization-list__area3__col.col3 .f-depth > li a:before {
  display: none;
}

.organization-list__area3__col.col3 .s-depth > li > a:before {
  display: none;
}

.organization-list__area3__col.col3 .s-depth > li > a:after {
  display: none;
}

.organization-list__area3__col.col3 .t-depth > li:before {
  display: none;
}

.organization-info {
  margin-top: 80px;
}

.organization-info div {
  display: none;
  overflow-x: auto;
}

.organization-info div.visible {
  display: block;
}

.organization-info table {
  width: 100%;
  min-width: 767px;
  border-top: 2px solid #9c7e29;
  table-layout: fixed;
}

.organization-info tr {
  border-bottom: 1px solid #ddd;
}

.organization-info th {
  height: 70px;
  border-right: 1px solid #ddd;
  background-color: #f9f9f9;
  font-size: 1.8rem;
  font-weight: 500;
  color: #222;
}

.organization-info th:last-child {
  border-right: none;
}

.organization-info td {
  height: 70px;
  border-right: 1px solid #ddd;
  font-size: 1.8rem;
  font-weight: 500;
  color: #666;
}

.organization-info td:last-child {
  border-right: none;
}

.organization-info .w-5 {
  width: 5%;
}

.organization-info .w-10 {
  width: 10%;
}

.organization-info .w-15 {
  width: 15%;
}

.organization-info .w-20 {
  width: 20%;
}

.organization-info .w-25 {
  width: 25%;
}

.organization-info .w-30 {
  width: 30%;
}

.organization-info .w-35 {
  width: 35%;
}

.organization-info .w-40 {
  width: 40%;
}

.organization-info .w-45 {
  width: 45%;
}

.organization-info .w-50 {
  width: 50%;
}

.organization-info .w-55 {
  width: 55%;
}

.organization-info .w-60 {
  width: 60%;
}

.organization-info .w-65 {
  width: 65%;
}

.organization-info .w-70 {
  width: 70%;
}

.organization-info .w-75 {
  width: 75%;
}

.organization-info .w-80 {
  width: 80%;
}

.organization-info .w-85 {
  width: 85%;
}

.organization-info .w-90 {
  width: 90%;
}

.organization-info .w-95 {
  width: 95%;
}

.organization-info .w-100 {
  width: 100%;
}

.organization-info .ta-l {
  text-align: left;
}

.organization-info .ta-c {
  text-align: center;
}

.organization-info .ta-r {
  text-align: right;
}

.organization-info .va-t {
  vertical-align: top;
}

.organization-info .va-m {
  vertical-align: middle;
}

.organization-info .va-b {
  vertical-align: bottom;
}

@media (max-width: 1200px) {
  .organization-info .w-md-5 {
    width: 5%;
  }
  .organization-info .w-md-10 {
    width: 10%;
  }
  .organization-info .w-md-15 {
    width: 15%;
  }
  .organization-info .w-md-20 {
    width: 20%;
  }
  .organization-info .w-md-25 {
    width: 25%;
  }
  .organization-info .w-md-30 {
    width: 30%;
  }
  .organization-info .w-md-35 {
    width: 35%;
  }
  .organization-info .w-md-40 {
    width: 40%;
  }
  .organization-info .w-md-45 {
    width: 45%;
  }
  .organization-info .w-md-50 {
    width: 50%;
  }
  .organization-info .w-md-55 {
    width: 55%;
  }
  .organization-info .w-md-60 {
    width: 60%;
  }
  .organization-info .w-md-65 {
    width: 65%;
  }
  .organization-info .w-md-70 {
    width: 70%;
  }
  .organization-info .w-md-75 {
    width: 75%;
  }
  .organization-info .w-md-80 {
    width: 80%;
  }
  .organization-info .w-md-85 {
    width: 85%;
  }
  .organization-info .w-md-90 {
    width: 90%;
  }
  .organization-info .w-md-95 {
    width: 95%;
  }
  .organization-info .w-md-100 {
    width: 100%;
  }
  .organization-info .w-md-n {
    display: none;
  }
}

@media (max-width: 991px) {
  .organization-info .w-sm-5 {
    width: 5%;
  }
  .organization-info .w-sm-10 {
    width: 10%;
  }
  .organization-info .w-sm-15 {
    width: 15%;
  }
  .organization-info .w-sm-20 {
    width: 20%;
  }
  .organization-info .w-sm-25 {
    width: 25%;
  }
  .organization-info .w-sm-30 {
    width: 30%;
  }
  .organization-info .w-sm-35 {
    width: 35%;
  }
  .organization-info .w-sm-40 {
    width: 40%;
  }
  .organization-info .w-sm-45 {
    width: 45%;
  }
  .organization-info .w-sm-50 {
    width: 50%;
  }
  .organization-info .w-sm-55 {
    width: 55%;
  }
  .organization-info .w-sm-60 {
    width: 60%;
  }
  .organization-info .w-sm-65 {
    width: 65%;
  }
  .organization-info .w-sm-70 {
    width: 70%;
  }
  .organization-info .w-sm-75 {
    width: 75%;
  }
  .organization-info .w-sm-80 {
    width: 80%;
  }
  .organization-info .w-sm-85 {
    width: 85%;
  }
  .organization-info .w-sm-90 {
    width: 90%;
  }
  .organization-info .w-sm-95 {
    width: 95%;
  }
  .organization-info .w-sm-100 {
    width: 100%;
  }
  .organization-info .w-sm-n {
    display: none;
  }
}

@media (max-width: 767px) {
  .organization-info .w-xs-5 {
    width: 5%;
  }
  .organization-info .w-xs-10 {
    width: 10%;
  }
  .organization-info .w-xs-15 {
    width: 15%;
  }
  .organization-info .w-xs-20 {
    width: 20%;
  }
  .organization-info .w-xs-25 {
    width: 25%;
  }
  .organization-info .w-xs-30 {
    width: 30%;
  }
  .organization-info .w-xs-35 {
    width: 35%;
  }
  .organization-info .w-xs-40 {
    width: 40%;
  }
  .organization-info .w-xs-45 {
    width: 45%;
  }
  .organization-info .w-xs-50 {
    width: 50%;
  }
  .organization-info .w-xs-55 {
    width: 55%;
  }
  .organization-info .w-xs-60 {
    width: 60%;
  }
  .organization-info .w-xs-65 {
    width: 65%;
  }
  .organization-info .w-xs-70 {
    width: 70%;
  }
  .organization-info .w-xs-75 {
    width: 75%;
  }
  .organization-info .w-xs-80 {
    width: 80%;
  }
  .organization-info .w-xs-85 {
    width: 85%;
  }
  .organization-info .w-xs-90 {
    width: 90%;
  }
  .organization-info .w-xs-95 {
    width: 95%;
  }
  .organization-info .w-xs-100 {
    width: 100%;
  }
  .organization-info .w-xs-n {
    display: none;
  }
}

.past-list li {
  width: calc((100% - 120px) / 4);
  height: 285px;
  margin: 0 40px 40px 0;
  border: 1px solid #ddd;
  display: inline-block;
  vertical-align: top;
  position: relative;
}

@media (max-width: 1650px) {
  .past-list li {
    width: calc((100% - 60px) / 4);
    margin: 0 20px 20px 0;
  }
}

@media (max-width: 1440px) {
  .past-list li {
    width: calc((100% - 40px) / 3);
  }
}

@media (max-width: 991px) {
  .past-list li {
    width: calc(50% - 10px);
  }
}

@media (max-width: 767px) {
  .past-list li {
    width: 100%;
    margin-right: 0px;
  }
}

.past-list li:nth-child(4n) {
  margin-right: 0;
}

@media (max-width: 1440px) {
  .past-list li:nth-child(4n) {
    margin-right: 20px;
  }
}

@media (max-width: 1440px) {
  .past-list li:nth-child(3n) {
    margin-right: 0;
  }
}

@media (max-width: 991px) {
  .past-list li:nth-child(3n) {
    margin-right: 20px;
  }
}

@media (max-width: 767px) {
  .past-list li:nth-child(3n) {
    margin-right: 0px;
  }
}

@media (max-width: 991px) {
  .past-list li:nth-child(2n) {
    margin-right: 0px;
  }
}

.past-list li > div {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  right: auto;
  transform: translateY(-50%);
  text-align: center;
}

.past-list li > div > img {
  margin-bottom: 15px;
}

.past-list__text img {
  display: inline-block;
  vertical-align: middle;
}

.past-list__text h3 {
  padding: 0 5px;
  display: inline-block;
  font-size: 2.4rem;
  font-weight: 700;
  color: #003554;
  vertical-align: middle;
}

@media (max-width: 1790px) {
  .past-list__text h3 {
    font-size: 2.1rem;
  }
}

@media (max-width: 1650px) {
  .past-list__text h3 {
    font-size: 2rem;
  }
}

@media (max-width: 1550px) {
  .past-list__text h3 {
    font-size: 1.8rem;
  }
}

@media (max-width: 1050px) {
  .past-list__text h3 {
    font-size: 1.7rem;
  }
}

@media (max-width: 767px) {
  .past-list__text h3 {
    font-size: 1.6rem;
  }
}

.past-list__text p {
  margin-top: 5px;
  font-size: 1.6rem;
  font-weight: 500;
  color: #666;
}

@media (max-width: 1050px) {
  .past-list__text p {
    font-size: 1.4rem;
  }
}

@media (max-width: 767px) {
  .ci .board-text {
    margin-bottom: 10px;
  }
}

.ci-contents1 {
  margin-bottom: 60px;
}

@media (max-width: 767px) {
  .ci-contents1 {
    margin-bottom: 30px;
  }
}

.ci-contents1 img {
  margin: 0 100px;
  vertical-align: middle;
}

@media (max-width: 1320px) {
  .ci-contents1 img {
    margin: 0 50px;
  }
}

@media (max-width: 991px) {
  .ci-contents1 img {
    margin: 0 30px;
  }
}

.ci-contents2 {
  margin-bottom: 60px;
}

@media (max-width: 767px) {
  .ci-contents2 {
    margin-bottom: 30px;
  }
}

.ci p {
  font-size: 1.8rem;
  font-weight: 500;
  color: #666;
  line-height: 2.8rem;
}

@media (max-width: 767px) {
  .ci p {
    font-size: 1.6rem;
    line-height: 2.6rem;
  }
}

.ci-img {
  margin-top: 40px;
  padding: 60px 15px;
  border: 1px solid #ddd;
  text-align: center;
}

@media (max-width: 767px) {
  .ci-img {
    margin-top: 15px;
  }
}

.ci li {
  margin-bottom: 30px;
  font-size: 1.8rem;
  font-weight: 500;
  color: #666;
  line-height: 2.8rem;
}

@media (max-width: 767px) {
  .ci li {
    margin-bottom: 15px;
    font-size: 1.6rem;
    line-height: 2.6rem;
  }
}

.ci li span {
  font-weight: 700;
  color: #9c7e29;
}

.ci-btn {
  margin-top: 80px;
  text-align: center;
}

@media (max-width: 767px) {
  .ci-btn {
    margin-top: 15px;
  }
}

.ci-btn a {
  width: 240px;
  height: 62px;
  margin-right: 10px;
  border: 1px solid #003554;
  font-size: 1.8rem;
  font-weight: 500;
  color: #003554;
  text-align: center;
  line-height: 60px;
}

@media (max-width: 1320px) {
  .ci-btn a {
    width: 180px;
    font-size: 1.6rem;
  }
}

@media (max-width: 991px) {
  .ci-btn a {
    width: calc(50% - 5px);
    margin-bottom: 10px;
  }
  .ci-btn a:nth-child(2n) {
    margin-right: 0;
  }
}

@media (max-width: 767px) {
  .ci-btn a {
    width: 100%;
    margin-right: 0;
  }
}

@media (max-width: 991px) {
  .ci-btn a:nth-child(2n) {
    margin-right: 0;
  }
}

.ci-btn a:last-child {
  margin-right: 0;
}

.ci-btn a:hover {
  background-color: #003554;
  transition-duration: .3s;
  color: #fff;
}

.ci-btn a:hover img:nth-of-type(1) {
  display: none;
}

.ci-btn a:hover img:nth-of-type(2) {
  display: inline-block;
}

.ci-btn img {
  margin: 0 0 0 15px;
  vertical-align: middle;
  position: relative;
  bottom: 2px;
}

@media (max-width: 1320px) {
  .ci-btn img {
    margin-left: 10px;
  }
}

.ci-btn img:nth-of-type(2) {
  display: none;
}

@media (max-width: 1400px) {
  .hidden-custom {
    display: none;
  }
}

@media (max-width: 1050px) {
  .hidden-custom-1050 {
    display: none;
  }
}

.vision-tit {
  margin-bottom: 30px;
  font-size: 4rem;
  font-weight: 700;
  color: #003554;
  text-align: center;
}

@media (max-width: 1520px) {
  .vision-tit {
    font-size: 3.2rem;
  }
}

@media (max-width: 991px) {
  .vision-tit {
    font-size: 2.6rem;
  }
}

.vision-text {
  margin-bottom: 110px;
  padding: 80px 10px;
  position: relative;
}

@media (max-width: 1520px) {
  .vision-text {
    margin-bottom: 60px;
  }
}

@media (max-width: 1050px) {
  .vision-text {
    margin-bottom: 0;
  }
}

@media (max-width: 767px) {
  .vision-text {
    padding: 30px 0;
  }
}

.vision-text img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.vision-text p {
  font-size: 2rem;
  font-weight: 500;
  color: #666;
  text-align: center;
  line-height: 3rem;
}

@media (max-width: 1520px) {
  .vision-text p {
    font-size: 1.8rem;
    line-height: 2.8rem;
  }
}

@media (max-width: 991px) {
  .vision-text p {
    font-size: 1.4rem;
  }
}

.vision-wrap__padding {
  padding-top: 60px;
  text-align: center;
}

@media (max-width: 767px) {
  .vision-wrap__padding {
    padding-top: 0;
  }
}

.vision-wrap__padding:nth-of-type(2) {
  margin: 70px 0 -130px;
  padding-top: 0;
}

@media (max-width: 991px) {
  .vision-wrap__padding:nth-of-type(2) {
    margin: 40px 0 -180px;
  }
}

@media (max-width: 767px) {
  .vision-wrap__padding:nth-of-type(2) {
    margin: 30px 0 20px;
  }
}

.vision-wrap__padding:nth-of-type(2) h4:before {
  display: none;
}

.vision-wrap__padding:nth-of-type(2) h4:after {
  display: none;
}

.vision-wrap h4 {
  height: 60px;
  padding: 0 40px;
  border-radius: 60px;
  display: inline-block;
  background-color: #aeaeae;
  font-size: 2.6rem;
  font-weight: 500;
  color: #111;
  text-align: center;
  line-height: 60px;
  position: relative;
  z-index: 1;
}

@media (max-width: 1720px) {
  .vision-wrap h4 {
    font-size: 2.2rem;
  }
}

@media (max-width: 1600px) {
  .vision-wrap h4 {
    padding: 0 20px;
    font-size: 2rem;
  }
}

@media (max-width: 1520px) {
  .vision-wrap h4 {
    font-size: 1.8rem;
  }
}

@media (max-width: 1050px) {
  .vision-wrap h4 {
    font-size: 1.6rem;
  }
}

@media (max-width: 991px) {
  .vision-wrap h4 {
    width: 120px;
    height: 40px;
    padding: 0 10px;
    font-size: 1.2rem;
    line-height: 40px;
  }
}

@media (max-width: 767px) {
  .vision-wrap h4 {
    width: 200px;
    font-size: 1.6rem;
  }
}

.vision-wrap h4:before {
  content: '';
  width: 1px;
  height: 60px;
  display: inline-block;
  background-color: #ccc;
  position: absolute;
  top: auto;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 767px) {
  .vision-wrap h4:before {
    display: none;
  }
}

.vision-wrap h4:after {
  content: '';
  width: 11px;
  height: 11px;
  border-radius: 11px;
  display: inline-block;
  background-color: #ccc;
  position: absolute;
  top: auto;
  bottom: calc(100% + 60px);
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 767px) {
  .vision-wrap h4:after {
    display: none;
  }
}

.vision-wrap__one-depth {
  margin-top: -30px;
  padding-top: 125px;
  position: relative;
}

@media (max-width: 991px) {
  .vision-wrap__one-depth {
    margin-top: -20px;
  }
}

@media (max-width: 767px) {
  .vision-wrap__one-depth {
    padding-top: 40px;
  }
}

.vision-wrap__one-depth:before {
  content: '';
  width: calc(100% - (((100% - 160px) / 5)));
  height: 1px;
  display: inline-block;
  background-color: #ccc;
  position: absolute;
  top: 0;
  bottom: auto;
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 1350px) {
  .vision-wrap__one-depth:before {
    width: calc(100% - (((100% - 80px) / 5)));
  }
}

@media (max-width: 991px) {
  .vision-wrap__one-depth:before {
    width: calc(100% - (((100% - 40px) / 5)));
  }
}

@media (max-width: 767px) {
  .vision-wrap__one-depth:before {
    display: none;
  }
}

.vision-wrap__one-depth > li {
  width: calc((100% - 160px) / 5);
  margin-right: 40px;
  display: inline-block;
  vertical-align: top;
  position: relative;
}

@media (max-width: 1350px) {
  .vision-wrap__one-depth > li {
    width: calc((100% - 80px) / 5);
    margin-right: 20px;
  }
}

@media (max-width: 991px) {
  .vision-wrap__one-depth > li {
    width: calc((100% - 40px) / 5);
    margin-right: 10px;
  }
}

@media (max-width: 767px) {
  .vision-wrap__one-depth > li {
    width: 100%;
    margin-right: 0;
    margin-bottom: 30px;
  }
}

.vision-wrap__one-depth > li:before {
  content: '';
  width: 1px;
  height: 100px;
  display: inline-block;
  background-color: #ccc;
  position: absolute;
  top: auto;
  bottom: calc(100% + 25px);
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 767px) {
  .vision-wrap__one-depth > li:before {
    display: none;
  }
}

.vision-wrap__one-depth > li:after {
  content: '';
  width: 12px;
  height: 12px;
  display: inline-block;
  background-color: #ccc;
  position: absolute;
  top: auto;
  bottom: calc(100% + 25px);
  left: 50%;
  transform: translateX(-50%);
  transform: translateX(-50%) rotate(45deg);
}

@media (max-width: 767px) {
  .vision-wrap__one-depth > li:after {
    display: none;
  }
}

.vision-wrap__one-depth > li:nth-child(5) {
  margin-right: 0;
  margin-bottom: 0;
}

.vision-wrap__one-depth__circle {
  padding-bottom: 100%;
  border-radius: 1000px;
  background-color: #003554;
  position: relative;
}

.vision-wrap__one-depth__circle div {
  width: 100%;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 0;
  right: auto;
  transform: translateY(-50%);
}

@media (max-width: 1520px) {
  .vision-wrap__one-depth__circle div img {
    height: 35px;
  }
}

@media (max-width: 991px) {
  .vision-wrap__one-depth__circle div img {
    height: 30px;
  }
}

@media (max-width: 767px) {
  .vision-wrap__one-depth__circle div img {
    height: auto;
  }
}

.vision-wrap__one-depth__circle div p {
  margin-top: 25px;
  font-size: 2.6rem;
  font-weight: 700;
  color: #fff;
  line-height: 3.6rem;
}

@media (max-width: 1720px) {
  .vision-wrap__one-depth__circle div p {
    font-size: 2.2rem;
    line-height: 3.2rem;
  }
}

@media (max-width: 1600px) {
  .vision-wrap__one-depth__circle div p {
    font-size: 2rem;
    line-height: 3rem;
  }
}

@media (max-width: 1520px) {
  .vision-wrap__one-depth__circle div p {
    margin-top: 15px;
    font-size: 1.8rem;
    line-height: 2.6rem;
  }
}

@media (max-width: 1050px) {
  .vision-wrap__one-depth__circle div p {
    font-size: 1.6rem;
    line-height: 2.2rem;
  }
}

@media (max-width: 991px) {
  .vision-wrap__one-depth__circle div p {
    margin-top: 10px;
    font-size: 1.2rem;
    line-height: 1.6rem;
  }
}

@media (max-width: 767px) {
  .vision-wrap__one-depth__circle div p {
    margin-top: 20px;
    font-size: 1.6rem;
    line-height: 2.6rem;
  }
}

.vision-wrap__one-depth h4 {
  width: 250px;
  max-width: 100%;
  position: absolute;
  top: auto;
  bottom: 245px;
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 767px) {
  .vision-wrap__one-depth h4 {
    display: none;
  }
}

.vision-wrap__one-depth h4:before {
  display: none;
}

.vision-wrap__one-depth h4:after {
  display: none;
}

.vision-wrap__one-depth:nth-of-type(2) {
  padding-top: 0;
}

.vision-wrap__one-depth:nth-of-type(2):before {
  display: none;
}

.vision-wrap__one-depth:nth-of-type(2) li:before {
  display: none;
}

.vision-wrap__one-depth:nth-of-type(2) li:after {
  display: none;
}

@media (max-width: 767px) {
  .vision-wrap__one-depth:nth-of-type(2) > li {
    margin-bottom: 0;
  }
}

.vision-wrap__two-depth {
  min-height: 155px;
  margin-top: 230px;
  padding: 25px;
  border: 1px solid #ccc;
  position: relative;
}

@media (max-width: 1520px) {
  .vision-wrap__two-depth {
    padding: 15px;
  }
}

@media (max-width: 767px) {
  .vision-wrap__two-depth {
    height: auto !important;
    min-height: auto;
    margin-top: 50px;
  }
}

.vision-wrap__two-depth:before {
  content: '';
  width: 1px;
  height: 230px;
  border-left: 1px dashed #ccc;
  display: inline-block;
  position: absolute;
  top: auto;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 991px) {
  .vision-wrap__two-depth:before {
    height: 130px;
  }
}

@media (max-width: 767px) {
  .vision-wrap__two-depth:before {
    height: 50px;
  }
}

.vision-wrap__two-depth:after {
  content: '';
  width: 12px;
  height: 12px;
  background-color: #ccc;
  display: inline-block;
  position: absolute;
  top: auto;
  bottom: calc(100% - 6px);
  left: 50%;
  transform: translateX(-50%);
  transform: translateX(-50%) rotate(45deg);
}

.vision-wrap__two-depth li {
  margin-bottom: 10px;
  padding-left: 13px;
  font-size: 1.8rem;
  font-weight: 500;
  color: #222;
  position: relative;
}

@media (max-width: 1600px) {
  .vision-wrap__two-depth li {
    font-size: 1.6rem;
  }
}

@media (max-width: 991px) {
  .vision-wrap__two-depth li {
    font-size: 1.2rem;
  }
}

.vision-wrap__two-depth li:before {
  content: '';
  width: 3px;
  height: 3px;
  display: inline-block;
  background-color: #666;
  position: absolute;
  top: 10px;
  right: auto;
  bottom: auto;
  left: 0;
}

.vision-wrap__two-depth li:last-child {
  margin-bottom: 0;
}

.location-map {
  height: 570px;
  margin-bottom: 70px;
  position: relative;
  z-index: 0;
}

@media (max-width: 991px) {
  .location-map {
    height: 300px;
  }
}

@media (max-width: 767px) {
  .location-map {
    height: 150px;
    margin-bottom: 35px;
  }
}

.location-map .wrap_map {
  height: 100%;
}

.location-map .root_daum_roughmap {
  width: 100%;
  height: 100%;
}

.location-map .root_daum_roughmap .wrap_map {
  height: 100%;
}

.location-info__contact {
  margin-bottom: 40px;
  padding: 40px 0;
  border-top: 2px solid #9c7e29;
  border-bottom: 1px solid #ddd;
}

@media (max-width: 767px) {
  .location-info__contact {
    padding: 20px 0;
  }
}

.location-info__contact li {
  display: inline-block;
  vertical-align: middle;
}

@media (max-width: 991px) {
  .location-info__contact li {
    width: 100%;
    margin-bottom: 5px;
  }
}

.location-info__contact li:after {
  content: '';
  width: 1px;
  height: 16px;
  margin: 0 60px;
  display: inline-block;
  background-color: #ddd;
  position: relative;
  top: 8px;
}

@media (max-width: 1650px) {
  .location-info__contact li:after {
    margin: 0 40px;
  }
}

@media (max-width: 1420px) {
  .location-info__contact li:after {
    margin: 0 20px;
    top: 9px;
  }
}

@media (max-width: 1260px) {
  .location-info__contact li:after {
    margin: 0 10px;
  }
}

@media (max-width: 991px) {
  .location-info__contact li:after {
    display: none;
  }
}

.location-info__contact li:nth-child(3):after {
  display: none;
}

.location-info__contact li:last-child {
  width: 100%;
  margin-top: 20px;
}

@media (max-width: 1260px) {
  .location-info__contact li:last-child {
    margin-top: 10px;
  }
}

@media (max-width: 991px) {
  .location-info__contact li:last-child {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.location-info__contact li:last-child:after {
  display: none;
}

.location-info__contact li img {
  margin-right: 20px;
  vertical-align: middle;
}

@media (max-width: 1650px) {
  .location-info__contact li img {
    margin-right: 10px;
  }
}

@media (max-width: 1260px) {
  .location-info__contact li img {
    width: 30px;
  }
}

.location-info__contact li b {
  margin-right: 20px;
  display: inline-block;
  font-size: 1.8rem;
  font-weight: 500;
  color: #222;
  vertical-align: middle;
}

@media (max-width: 1650px) {
  .location-info__contact li b {
    margin-right: 10px;
  }
}

@media (max-width: 1420px) {
  .location-info__contact li b {
    font-size: 1.6rem;
  }
}

@media (max-width: 1260px) {
  .location-info__contact li b {
    font-size: 1.2rem;
  }
}

@media (max-width: 991px) {
  .location-info__contact li b {
    font-size: 1.4rem;
  }
}

.location-info__contact li p {
  display: inline-block;
  font-size: 1.8rem;
  font-weight: 500;
  color: #666;
  vertical-align: middle;
}

@media (max-width: 1420px) {
  .location-info__contact li p {
    font-size: 1.6rem;
  }
}

@media (max-width: 1260px) {
  .location-info__contact li p {
    font-size: 1.2rem;
  }
}

@media (max-width: 991px) {
  .location-info__contact li p {
    font-size: 1.4rem;
  }
}

@media (max-width: 767px) {
  .location-info__contact li p {
    width: 100%;
  }
}

.location-info__route {
  border-top: 2px solid #9c7e29;
}

.location-info__route li {
  background-color: #f9f9f9;
  border-bottom: 1px solid #ddd;
}

.location-info__route__img {
  width: 270px;
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

@media (max-width: 991px) {
  .location-info__route__img {
    width: 150px;
  }
}

@media (max-width: 767px) {
  .location-info__route__img {
    width: 100%;
  }
}

.location-info__route__img div {
  width: 100%;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 0;
  right: auto;
  transform: translateY(-50%);
}

@media (max-width: 767px) {
  .location-info__route__img div {
    padding: 15px 0;
    position: initial;
    transform: none;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
  }
}

.location-info__route__img p {
  margin-top: 10px;
  font-size: 1.8rem;
  font-weight: 500;
  color: #222;
}

@media (max-width: 1420px) {
  .location-info__route__img p {
    font-size: 1.6rem;
  }
}

@media (max-width: 1150px) {
  .location-info__route__img p {
    font-size: 1.4rem;
  }
}

.location-info__route__text {
  width: calc(100% - 270px);
  height: 220px;
  border-left: 1px solid #ddd;
  display: inline-block;
  background-color: #fff;
  vertical-align: middle;
  position: relative;
}

@media (max-width: 1150px) {
  .location-info__route__text {
    height: 200px;
  }
}

@media (max-width: 991px) {
  .location-info__route__text {
    width: calc(100% - 150px);
  }
}

@media (max-width: 767px) {
  .location-info__route__text {
    width: 100%;
    height: auto;
    border-left: none;
    border-top: 1px solid #ddd;
  }
}

.location-info__route__text div {
  width: 100%;
  padding: 0 60px;
  position: absolute;
  top: 50%;
  left: 0;
  right: auto;
  transform: translateY(-50%);
}

@media (max-width: 991px) {
  .location-info__route__text div {
    padding: 0 30px;
  }
}

@media (max-width: 767px) {
  .location-info__route__text div {
    padding: 30px 15px;
    position: initial;
    transform: none;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
  }
}

.location-info__route__text p {
  margin-bottom: 5px;
  font-size: 1.8rem;
  font-weight: 500;
  color: #222;
}

@media (max-width: 1420px) {
  .location-info__route__text p {
    font-size: 1.6rem;
  }
}

@media (max-width: 1150px) {
  .location-info__route__text p {
    font-size: 1.4rem;
  }
}

.location-info__route__text p:last-child {
  margin-bottom: 0;
}

.location-info__route__text p span.c-g {
  color: #278131;
}

.location-info__route__text p span.c-y {
  color: #8D6F22;
}

.location-info__route__text ul {
  margin-top: 15px;
}

.location-info__route__text li {
  margin-bottom: 5px;
  padding-left: 40px;
  border-bottom: none;
  background-color: transparent;
  font-size: 1.6rem;
  font-weight: 500;
  color: #222;
  position: relative;
}

@media (max-width: 1150px) {
  .location-info__route__text li {
    font-size: 1.4rem;
  }
}

.location-info__route__text li span {
  width: 30px;
  display: inline-block;
  font-size: 1.4rem;
  font-weight: 500;
  color: #fff;
  text-align: center;
  line-height: 1.9rem;
  position: absolute;
  top: 4px;
  right: auto;
  bottom: auto;
  left: 0;
}

@media (max-width: 1150px) {
  .location-info__route__text li span {
    top: 2px;
  }
}

.location-info__route__text li:nth-child(1) span {
  background-color: #3353a2;
}

.location-info__route__text li:nth-child(2) span {
  background-color: #48922d;
}

.location-info__route__text li:nth-child(3) span {
  background-color: #c91017;
}

.location-info__route__text li:nth-child(4) span {
  background-color: #70afdf;
  color: #111;
}

.taekwondo1-wrap {
  margin-bottom: 70px;
}

@media (max-width: 991px) {
  .taekwondo1-wrap {
    margin-bottom: 50px;
  }
}

@media (max-width: 767px) {
  .taekwondo1-wrap {
    margin-bottom: 30px;
  }
}

.taekwondo1-wrap p {
  margin-bottom: 20px;
  font-size: 1.8rem;
  font-weight: 500;
  color: #666;
  line-height: 1.5;
}

@media (max-width: 991px) {
  .taekwondo1-wrap p {
    font-size: 1.6rem;
  }
}

.taekwondo1-wrap p:last-of-type {
  margin-bottom: 0;
}

.taekwondo1-wrap img {
  display: block;
  margin: 70px auto 0;
}

@media (max-width: 991px) {
  .taekwondo1-wrap img {
    margin-top: 50px;
  }
}

@media (max-width: 767px) {
  .taekwondo1-wrap img {
    margin-top: 30px;
  }
}

.taekwondo2-top {
  margin-bottom: 80px;
  padding: 50px 55px;
  border: 4px solid #9c7e29;
}

@media (max-width: 1650px) {
  .taekwondo2-top {
    padding: 40px;
  }
}

@media (max-width: 1260px) {
  .taekwondo2-top {
    padding: 30px;
  }
}

@media (max-width: 991px) {
  .taekwondo2-top {
    padding: 30px 15px;
  }
}

@media (max-width: 767px) {
  .taekwondo2-top {
    margin-bottom: 50px;
  }
}

.taekwondo2-top p {
  margin-bottom: 30px;
  font-size: 2rem;
  font-weight: 500;
  color: #666;
  line-height: 3rem;
}

@media (max-width: 1650px) {
  .taekwondo2-top p {
    font-size: 1.8rem;
    line-height: 2.8rem;
  }
}

@media (max-width: 991px) {
  .taekwondo2-top p {
    font-size: 1.6rem;
    line-height: 2.6rem;
  }
}

.taekwondo2-top p:last-child {
  margin-bottom: 0;
}

.taekwondo2-wrap li {
  width: calc((100% - 160px) / 3);
  margin: 0 80px 60px 0;
  border-top: 2px solid #222;
  border-bottom: 1px solid #222;
  padding-bottom: 30px;
  display: inline-block;
  vertical-align: top;
}

@media (max-width: 1650px) {
  .taekwondo2-wrap li {
    width: calc((100% - 80px) / 3);
    margin-right: 40px;
  }
}

@media (max-width: 1420px) {
  .taekwondo2-wrap li {
    width: calc((100% - 40px) / 3);
    margin: 0 20px 30px 0;
  }
}

@media (max-width: 991px) {
  .taekwondo2-wrap li {
    width: calc(50% - 10px);
  }
}

@media (max-width: 767px) {
  .taekwondo2-wrap li {
    width: 100%;
    margin-right: 0;
  }
}

.taekwondo2-wrap li:nth-child(3n) {
  margin-right: 0;
}

@media (max-width: 991px) {
  .taekwondo2-wrap li:nth-child(3n) {
    margin-right: 20px;
  }
}

@media (max-width: 767px) {
  .taekwondo2-wrap li:nth-child(3n) {
    margin-right: 0;
  }
}

@media (max-width: 991px) {
  .taekwondo2-wrap li:nth-child(2n) {
    margin-right: 0;
  }
}

.taekwondo2-tit {
  padding: 35px 40px;
}

@media (max-width: 1260px) {
  .taekwondo2-tit {
    padding: 30px;
  }
}

@media (max-width: 991px) {
  .taekwondo2-tit {
    padding: 15px;
  }
}

.taekwondo2-tit h4 {
  margin-bottom: 5px;
  font-size: 2.4rem;
  font-weight: 700;
  color: #222;
}

@media (max-width: 1260px) {
  .taekwondo2-tit h4 {
    font-size: 2.2rem;
  }
}

@media (max-width: 767px) {
  .taekwondo2-tit h4 {
    font-size: 2rem;
  }
}

.taekwondo2-tit p {
  font-size: 1.8rem;
  font-weight: 700;
  color: #222;
  line-height: 2.8rem;
}

@media (max-width: 1650px) {
  .taekwondo2-tit p {
    font-size: 1.6rem;
    line-height: 2.6rem;
  }
}

@media (max-width: 991px) {
  .taekwondo2-tit p {
    font-size: 1.4rem;
    line-height: 2.4rem;
  }
}

.taekwondo2-text {
  padding: 35px 40px;
  background-color: #f9f9f9;
}

@media (max-width: 1260px) {
  .taekwondo2-text {
    padding: 30px;
  }
}

@media (max-width: 991px) {
  .taekwondo2-text {
    padding: 15px;
  }
}

@media (max-width: 767px) {
  .taekwondo2-text {
    height: auto !important;
  }
}

.taekwondo2-text p {
  font-size: 1.8rem;
  font-weight: 500;
  color: #666;
  line-height: 2.8rem;
}

@media (max-width: 1650px) {
  .taekwondo2-text p {
    font-size: 1.6rem;
    line-height: 2.6rem;
  }
}

@media (max-width: 991px) {
  .taekwondo2-text p {
    font-size: 1.4rem;
    line-height: 2.4rem;
  }
}

.taekwondo3-wrap {
  margin-bottom: 70px;
}

@media (max-width: 991px) {
  .taekwondo3-wrap {
    margin-bottom: 50px;
  }
}

@media (max-width: 767px) {
  .taekwondo3-wrap {
    margin-bottom: 30px;
  }
}

.taekwondo3-wrap p {
  margin-bottom: 20px;
  font-size: 1.8rem;
  font-weight: 500;
  color: #666;
  line-height: 1.5;
}

@media (max-width: 991px) {
  .taekwondo3-wrap p {
    font-size: 1.6rem;
  }
}

.taekwondo3-wrap p:last-of-type {
  margin-bottom: 0;
}

.taekwondo3-wrap img {
  display: block;
  margin: 70px auto 0;
}

@media (max-width: 991px) {
  .taekwondo3-wrap img {
    margin-top: 50px;
  }
}

@media (max-width: 767px) {
  .taekwondo3-wrap img {
    margin-top: 30px;
  }
}

.taekwondo3-text li {
  width: calc((100% - 110px) / 2);
  margin-right: 110px;
  padding: 30px 0 80px;
  border-top: 1px solid #222;
  display: inline-block;
  vertical-align: top;
}

@media (max-width: 1260px) {
  .taekwondo3-text li {
    width: calc((100% - 50px) / 2);
    margin-right: 50px;
  }
}

@media (max-width: 767px) {
  .taekwondo3-text li {
    width: 100%;
    margin-right: 0;
    padding: 30px 0;
  }
}

.taekwondo3-text li:nth-child(2n) {
  margin-right: 0;
}

.taekwondo3-text__num {
  width: 75px;
  display: inline-block;
  vertical-align: top;
  text-align: center;
}

.taekwondo3-text__num h4 {
  width: 30px;
  height: 30px;
  border-radius: 30px;
  display: inline-block;
  background-color: #f1f1f1;
  position: relative;
}

.taekwondo3-text__num h4 span {
  font-size: 1.6rem;
  font-weight: 700;
  color: #003554;
  letter-spacing: -1px;
  position: absolute;
  top: auto;
  right: 0;
  bottom: -3px;
  left: auto;
}

.taekwondo3-text__text {
  width: calc(100% - 75px);
  display: inline-block;
  vertical-align: top;
}

.taekwondo3-text__text h3 {
  margin-bottom: 10px;
  font-size: 2.4rem;
  font-weight: 700;
  color: #222;
}

@media (max-width: 767px) {
  .taekwondo3-text__text h3 {
    font-size: 2.2rem;
  }
}

.taekwondo3-text__text p {
  font-size: 1.8rem;
  font-weight: 500;
  color: #666;
  line-height: 2.8rem;
}

@media (max-width: 991px) {
  .taekwondo3-text__text p {
    font-size: 1.6rem;
    line-height: 2.6rem;
  }
}

@media (max-width: 1650px) {
  .taekwondo3-text__text p br {
    display: none;
  }
}

.test {
  word-wrap: break-word;
}

.test-bg {
  padding: 100px 160px;
  background-color: #f9f9f9;
}

@media (max-width: 1420px) {
  .test-bg {
    padding: 100px 80px;
  }
}

@media (max-width: 991px) {
  .test-bg {
    padding: 50px 30px;
  }
}

.test-info__wrap {
  padding: 60px 0 85px;
  border-bottom: 1px solid #ddd;
  position: relative;
}

@media (max-width: 991px) {
  .test-info__wrap {
    padding: 30px 0 45px;
  }
}

@media (max-width: 767px) {
  .test-info__wrap {
    padding: 30px 0;
  }
}

.test-info__wrap:nth-child(2n-1) .test-info__img {
  text-align: right;
}

.test-info__wrap:nth-child(2n-1) .test-info__img:first-child {
  display: none;
}

@media (max-width: 767px) {
  .test-info__wrap:nth-child(2n-1) .test-info__img:first-child {
    display: inline-block;
  }
}

@media (max-width: 767px) {
  .test-info__wrap:nth-child(2n-1) .test-info__img:last-child {
    display: none;
  }
}

.test-info__wrap:nth-child(2n-1) .test-info__img:before {
  border: 6px solid #ddd4ba;
  position: absolute;
  top: auto;
  right: auto;
  bottom: -30px;
  left: -30px;
}

@media (max-width: 1420px) {
  .test-info__wrap:nth-child(2n-1) .test-info__img:before {
    bottom: -15px;
    left: -15px;
  }
}

@media (max-width: 767px) {
  .test-info__wrap:nth-child(2n-1) .test-info__img:before {
    left: 0;
  }
}

.test-info__img {
  width: 385px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
}

@media (max-width: 1420px) {
  .test-info__img {
    width: 200px;
  }
}

@media (max-width: 767px) {
  .test-info__img {
    width: 100%;
    margin-bottom: 30px;
  }
}

.test-info__img:before {
  content: '';
  width: 100%;
  height: 100%;
  border: 6px solid #aebec7;
  display: inline-block;
  position: absolute;
  top: auto;
  right: -30px;
  bottom: -30px;
  left: auto;
  box-sizing: border-box;
}

@media (max-width: 1420px) {
  .test-info__img:before {
    right: -15px;
    bottom: -15px;
  }
}

@media (max-width: 767px) {
  .test-info__img:before {
    width: calc(100% - 15px);
    right: 0;
  }
}

.test-info__img img {
  position: relative;
  z-index: 2;
  box-shadow: 1px 1px 20px 1px rgba(0, 0, 0, 0.2);
}

@media (max-width: 767px) {
  .test-info__img img {
    width: calc(100% - 15px);
  }
}

.test-info__text {
  width: calc(100% - 385px);
  padding: 0 140px;
  display: inline-block;
  vertical-align: middle;
}

@media (max-width: 1650px) {
  .test-info__text {
    padding: 0 80px;
  }
}

@media (max-width: 1420px) {
  .test-info__text {
    width: calc(100% - 200px);
    padding: 0 40px;
  }
}

@media (max-width: 767px) {
  .test-info__text {
    width: 100%;
    padding: 0;
  }
}

.test-info__text p {
  font-size: 2rem;
  font-weight: 500;
  color: #666;
  line-height: 2.8rem;
}

@media (max-width: 1650px) {
  .test-info__text p {
    font-size: 1.8rem;
  }
}

@media (max-width: 991px) {
  .test-info__text p {
    font-size: 1.6rem;
    line-height: 2.6rem;
  }
}

.test-info__text p + img {
  position: absolute;
  top: 60px;
  right: 0;
  bottom: auto;
  left: auto;
}

@media (max-width: 1420px) {
  .test-info__text p + img {
    display: none;
  }
}

.test-info__text img {
  position: absolute;
  top: 60px;
  right: auto;
  bottom: auto;
  left: 0;
}

@media (max-width: 1420px) {
  .test-info__text img {
    display: none;
  }
}

.basic-text-box {
  padding: 160px;
  background-color: #f9f9f9;
}

@media (max-width: 1420px) {
  .basic-text-box {
    padding: 80px;
  }
}

@media (max-width: 991px) {
  .basic-text-box {
    padding: 40px;
  }
}

@media (max-width: 767px) {
  .basic-text-box {
    padding: 20px 15px;
  }
}

.basic-text-box__img {
  margin-bottom: 20px;
  text-align: center;
}

.basic-text-box__img ul.col-3 li {
  width: calc((100% - 40px) / 3);
}

@media (max-width: 767px) {
  .basic-text-box__img ul.col-3 li {
    width: 100%;
  }
}

.basic-text-box__img ul.col-4 li {
  width: calc((100% - 60px) / 4);
}

@media (max-width: 767px) {
  .basic-text-box__img ul.col-4 li {
    width: 100%;
  }
}

.basic-text-box__img ul.col-5 li {
  width: calc((100% - 80px) / 5);
}

@media (max-width: 767px) {
  .basic-text-box__img ul.col-5 li {
    width: 100%;
  }
}

.basic-text-box__img li {
  margin-right: 20px;
  margin: 0 20px 80px 0;
  display: inline-block;
  vertical-align: middle;
}

@media (max-width: 1420px) {
  .basic-text-box__img li {
    margin: 0 20px 50px 0;
  }
}

@media (max-width: 991px) {
  .basic-text-box__img li {
    margin: 0 20px 30px 0;
  }
}

.basic-text-box__img li:last-child {
  margin-right: 0;
}

.basic-text-box__img li img {
  -webkit-box-reflect: below 1px linear-gradient(to bottom, transparent 80%, rgba(255, 255, 255, 0.3));
}

.basic-text-box__text {
  text-align: center;
}

.basic-text-box__text h3 {
  margin-bottom: 20px;
  font-size: 4rem;
  font-weight: 700;
  color: #003554;
}

@media (max-width: 1420px) {
  .basic-text-box__text h3 {
    font-size: 3rem;
  }
}

@media (max-width: 991px) {
  .basic-text-box__text h3 {
    font-size: 2.6rem;
  }
}

@media (max-width: 767px) {
  .basic-text-box__text h3 {
    font-size: 2.2rem;
  }
}

.basic-text-box__text p {
  font-size: 2rem;
  font-weight: 500;
  color: #666;
  text-align: center;
  line-height: 2.8rem;
}

@media (max-width: 991px) {
  .basic-text-box__text p {
    font-size: 1.8rem;
    line-height: 2.6rem;
  }
}

@media (max-width: 767px) {
  .basic-text-box__text p {
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
}

.basic-text-box__text a {
  height: 64px;
  margin-top: 40px;
  padding: 0 30px;
  background-color: #003554;
  font-size: 1.8rem;
  font-weight: 500;
  color: #fff;
  text-align: center;
  line-height: 64px;
}

@media (max-width: 767px) {
  .basic-text-box__text a {
    width: 100%;
    height: 50px;
    padding: 0 10px;
    font-size: 1.4rem;
    line-height: 50px;
  }
}

.dispatch-text h3:nth-of-type(2) {
  margin-top: 110px;
  margin-bottom: 40px;
}

@media (max-width: 767px) {
  .dispatch-text h3:nth-of-type(2) {
    margin-top: 60px;
    margin-bottom: 20px;
  }
}

.dispatch-text ul {
  text-align: left;
}

.dispatch-text li {
  width: calc((100% - 180px) / 5);
  margin-right: 45px;
  display: inline-block;
  vertical-align: top;
  position: relative;
}

@media (max-width: 1250px) {
  .dispatch-text li {
    width: calc((100% - 80px) / 5);
    margin-right: 20px;
  }
}

@media (max-width: 991px) {
  .dispatch-text li {
    width: calc((100% - 60px) / 3);
    margin: 0 30px 30px 0;
  }
  .dispatch-text li:nth-child(3) {
    margin-right: 0;
  }
}

@media (max-width: 991px) {
  .dispatch-text li {
    width: 100%;
    margin-right: 0;
  }
}

.dispatch-text li:last-child {
  margin-right: 0;
}

.dispatch-text li:last-child .dispatch-text__step:before {
  display: none;
}

.dispatch-text li:last-child .dispatch-text__step:after {
  display: none;
}

.dispatch-text li > p {
  font-size: 1.6rem;
  font-weight: 500;
  color: #666;
  line-height: 2.6rem;
}

@media (max-width: 1250px) {
  .dispatch-text li > p {
    word-break: break-all;
  }
}

.dispatch-text li > p img {
  position: relative;
  top: 6px;
}

.dispatch-text__step {
  width: 100%;
  margin-bottom: 30px;
  padding-bottom: 100%;
  border: 2px solid #003554;
  border-radius: 300px;
  position: relative;
  z-index: 1;
}

@media (max-width: 767px) {
  .dispatch-text__step {
    margin-bottom: 10px;
  }
}

.dispatch-text__step:before {
  content: '';
  width: 43px;
  height: 1px;
  display: inline-block;
  border-top: 1px dotted #ccc;
  position: absolute;
  top: 50%;
  left: auto;
  right: -45px;
  transform: translateY(-50%);
  box-sizing: border-box;
  z-index: -1;
}

@media (max-width: 1250px) {
  .dispatch-text__step:before {
    width: 18px;
    right: -20px;
  }
}

@media (max-width: 991px) {
  .dispatch-text__step:before {
    width: 28px;
    right: -30px;
  }
}

@media (max-width: 767px) {
  .dispatch-text__step:before {
    display: none;
  }
}

.dispatch-text__step:after {
  content: '';
  width: 10px;
  height: 13px;
  border-left: 10px solid #ccc;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-right: none;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: auto;
  right: -30px;
  transform: translateY(-50%);
  box-sizing: border-box;
  z-index: -1;
}

@media (max-width: 1250px) {
  .dispatch-text__step:after {
    right: -17px;
  }
}

@media (max-width: 991px) {
  .dispatch-text__step:after {
    right: -23px;
  }
}

@media (max-width: 767px) {
  .dispatch-text__step:after {
    display: none;
  }
}

.dispatch-text__step.step-bg {
  background-color: #003554;
}

.dispatch-text__step.step-bg p {
  color: #fff;
}

.dispatch-text__step.step-bg h4 {
  color: #fff;
}

.dispatch-text__step div {
  width: 100%;
  display: inline-block;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 0;
  right: auto;
  transform: translateY(-50%);
  letter-spacing: -1px;
}

.dispatch-text__step div p {
  margin-bottom: 10px;
  font-size: 1.3rem;
  font-weight: 500;
  color: #003554;
  line-height: 1;
}

.dispatch-text__step div h4 {
  font-size: 2.2rem;
  font-weight: 500;
  color: #222;
}

@media (max-width: 1650px) {
  .dispatch-text__step div h4 {
    font-size: 1.8rem;
  }
}

.dispatch-map {
  margin-bottom: 40px;
  padding: 60px 99px;
  border: 1px solid #ddd;
  position: relative;
}

@media (max-width: 991px) {
  .dispatch-map {
    padding: 0;
    border: none;
  }
}

@media (max-width: 991px) {
  .dispatch-map > img {
    display: none;
  }
}

.dispatch-map__point {
  font-size: 0;
  position: absolute;
}

@media (max-width: 991px) {
  .dispatch-map__point {
    width: 20%;
    border: 1px solid #ddd;
    position: initial;
    transform: none;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
    font-size: 2rem;
    font-weight: 700;
    color: #000;
    text-align: center;
    line-height: 3.6rem;
    opacity: 1;
  }
}

@media (max-width: 767px) {
  .dispatch-map__point {
    width: 100%;
    font-size: 1.6rem;
    line-height: 3rem;
  }
}

.dispatch-map__point[data-dispatch='0'] {
  top: 10%;
  right: 6.4%;
}

@media (max-width: 1720px) {
  .dispatch-map__point[data-dispatch='0'] {
    top: 11%;
    right: 7.2%;
  }
}

@media (max-width: 1520px) {
  .dispatch-map__point[data-dispatch='0'] {
    top: 12.3%;
    right: 8.4%;
  }
}

@media (max-width: 1320px) {
  .dispatch-map__point[data-dispatch='0'] {
    top: 13.8%;
    right: 10%;
  }
}

@media (max-width: 1200px) {
  .dispatch-map__point[data-dispatch='0'] {
    top: 15.8%;
    right: 11.8%;
  }
}

.dispatch-map__point[data-dispatch='1'] {
  bottom: 12.8%;
  right: 9.1%;
}

@media (max-width: 1720px) {
  .dispatch-map__point[data-dispatch='1'] {
    bottom: 13.6%;
    right: 9.8%;
  }
}

@media (max-width: 1520px) {
  .dispatch-map__point[data-dispatch='1'] {
    bottom: 14.9%;
    right: 11.1%;
  }
}

@media (max-width: 1320px) {
  .dispatch-map__point[data-dispatch='1'] {
    bottom: 16.3%;
    right: 12.5%;
  }
}

@media (max-width: 1200px) {
  .dispatch-map__point[data-dispatch='1'] {
    bottom: 18.2%;
    right: 14.3%;
  }
}

.dispatch-map__point[data-dispatch='2'] {
  top: 43.5%;
  right: 40.3%;
}

@media (max-width: 1720px) {
  .dispatch-map__point[data-dispatch='2'] {
    top: 43.6%;
    right: 40.5%;
  }
}

@media (max-width: 1520px) {
  .dispatch-map__point[data-dispatch='2'] {
    top: 43.8%;
    right: 40.8%;
  }
}

@media (max-width: 1320px) {
  .dispatch-map__point[data-dispatch='2'] {
    top: 44.2%;
    right: 41.2%;
  }
}

@media (max-width: 1200px) {
  .dispatch-map__point[data-dispatch='2'] {
    top: 44.4%;
    right: 41.5%;
  }
}

.dispatch-map__point[data-dispatch='3'] {
  top: 7.4%;
  left: 6.2%;
}

@media (max-width: 1720px) {
  .dispatch-map__point[data-dispatch='3'] {
    top: 8.5%;
    left: 7.1%;
  }
}

@media (max-width: 1520px) {
  .dispatch-map__point[data-dispatch='3'] {
    top: 9.7%;
    left: 8.3%;
  }
}

@media (max-width: 1320px) {
  .dispatch-map__point[data-dispatch='3'] {
    top: 11.8%;
    left: 9.9%;
  }
}

@media (max-width: 1200px) {
  .dispatch-map__point[data-dispatch='3'] {
    top: 13.7%;
    left: 11.7%;
  }
}

.dispatch-map__point[data-dispatch='4'] {
  top: 20.2%;
  right: 41.9%;
}

@media (max-width: 1720px) {
  .dispatch-map__point[data-dispatch='4'] {
    top: 21%;
    right: 42%;
  }
}

@media (max-width: 1520px) {
  .dispatch-map__point[data-dispatch='4'] {
    top: 22%;
    right: 42.3%;
  }
}

@media (max-width: 1320px) {
  .dispatch-map__point[data-dispatch='4'] {
    top: 23.2%;
    right: 42.6%;
  }
}

@media (max-width: 1200px) {
  .dispatch-map__point[data-dispatch='4'] {
    top: 24.6%;
    right: 42.8%;
  }
}

.dispatch-map__point:hover img {
  opacity: 1;
}

.dispatch-map__point.selected img {
  opacity: 1;
}

@media (max-width: 991px) {
  .dispatch-map__point.selected {
    border: 1px solid #9c7e29;
    background-color: #9c7e29;
    color: #fff;
  }
}

.dispatch-map__point img {
  opacity: 0;
}

@media (max-width: 1720px) {
  .dispatch-map__point img:nth-child(1) {
    display: none;
  }
}

.dispatch-map__point img:nth-child(2) {
  display: none;
}

@media (max-width: 1720px) {
  .dispatch-map__point img:nth-child(2) {
    display: inline-block;
  }
}

@media (max-width: 1520px) {
  .dispatch-map__point img:nth-child(2) {
    display: none;
  }
}

.dispatch-map__point img:nth-child(3) {
  display: none;
}

@media (max-width: 1520px) {
  .dispatch-map__point img:nth-child(3) {
    display: inline-block;
  }
}

@media (max-width: 1320px) {
  .dispatch-map__point img:nth-child(3) {
    display: none;
  }
}

.dispatch-map__point img:nth-child(4) {
  display: none;
}

@media (max-width: 1320px) {
  .dispatch-map__point img:nth-child(4) {
    display: inline-block;
  }
}

@media (max-width: 1200px) {
  .dispatch-map__point img:nth-child(4) {
    display: none;
  }
}

.dispatch-map__point img:nth-child(5) {
  display: none;
}

@media (max-width: 1200px) {
  .dispatch-map__point img:nth-child(5) {
    display: inline-block;
  }
}

@media (max-width: 991px) {
  .dispatch-map__point img:nth-child(5) {
    display: none;
  }
}

.dispatch-list ul {
  display: none;
}

.dispatch-list ul.visible {
  display: block;
}

.dispatch-list li {
  width: calc((100% - 90px) / 4);
  margin: 0 30px 30px 0;
  padding: 20px;
  border: 1px solid #ddd;
  border-top: 2px solid #9c7e29;
  display: inline-block;
  vertical-align: top;
  position: relative;
}

@media (max-width: 1350px) {
  .dispatch-list li {
    width: calc((100% - 90px) / 4);
    margin: 0 30px 30px 0;
  }
}

@media (max-width: 1260px) {
  .dispatch-list li {
    width: calc((100% - 60px) / 3);
  }
}

@media (max-width: 991px) {
  .dispatch-list li {
    width: calc((100% - 30px) / 2);
  }
}

@media (max-width: 767px) {
  .dispatch-list li {
    width: calc((100% - 15px) / 2);
    margin: 0 15px 15px 0;
  }
}

.dispatch-list li:nth-child(4n) {
  margin-right: 0;
}

@media (max-width: 1260px) {
  .dispatch-list li:nth-child(4n) {
    margin-right: 30px;
  }
}

@media (max-width: 1260px) {
  .dispatch-list li:nth-child(3n) {
    margin-right: 0;
  }
}

@media (max-width: 991px) {
  .dispatch-list li:nth-child(3n) {
    margin-right: 30px;
  }
}

@media (max-width: 767px) {
  .dispatch-list li:nth-child(3n) {
    margin-right: 15px;
  }
}

@media (max-width: 991px) {
  .dispatch-list li:nth-child(2n) {
    margin-right: 0;
  }
}

.dispatch-list__img {
  width: 120px;
  display: inline-block;
  vertical-align: top;
}

@media (max-width: 1600px) {
  .dispatch-list__img {
    width: 100px;
  }
}

@media (max-width: 991px) {
  .dispatch-list__img {
    width: 80px;
  }
}

@media (max-width: 767px) {
  .dispatch-list__img {
    width: 100%;
  }
}

.dispatch-list__img img {
  width: 100%;
}

.dispatch-list__info {
  width: calc(100% - 120px);
  padding-left: 20px;
  display: inline-block;
  vertical-align: top;
}

@media (max-width: 1600px) {
  .dispatch-list__info {
    width: calc(100% - 100px);
    padding-left: 10px;
  }
}

@media (max-width: 991px) {
  .dispatch-list__info {
    width: calc(100% - 80px);
  }
}

@media (max-width: 767px) {
  .dispatch-list__info {
    width: 100%;
    padding-bottom: 5px;
    padding-left: 0;
  }
}

.dispatch-list__info h3 {
  height: 50px;
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px dashed #ddd;
  font-size: 2.4rem;
  font-weight: 500;
  color: #222;
  line-height: 50px;
}

@media (max-width: 1600px) {
  .dispatch-list__info h3 {
    height: 40px;
    font-size: 2rem;
    line-height: 40px;
  }
}

@media (max-width: 991px) {
  .dispatch-list__info h3 {
    font-size: 1.8rem;
  }
}

.dispatch-list__info p {
  margin-bottom: 10px;
  font-size: 1.6rem;
  font-weight: 500;
  color: #222;
  line-height: 1;
}

@media (max-width: 1600px) {
  .dispatch-list__info p {
    font-size: 1.4rem;
  }
}

.dispatch-list__info p:last-child {
  margin-bottom: 0;
}

.dispatch-list__info p span {
  padding-right: 5px;
  border-right: 1px solid #ddd;
  color: #666;
  display: inline-block;
  vertical-align: top;
}

@media (max-width: 991px) {
  .dispatch-list__info p span {
    display: block;
    margin-bottom: 5px;
    border-right: none;
  }
}

.dispatch-table {
  overflow-x: auto;
}

.dispatch-table table {
  width: 100%;
  min-width: 800px;
  border-top: 2px solid #9c7e29;
}

.dispatch-table th {
  padding: 24px 0;
  border: 1px solid #ddd;
  background-color: #f9f9f9;
  font-size: 1.8rem;
  font-weight: 500;
  color: #222;
  text-align: center;
  vertical-align: middle;
}

@media (max-width: 767px) {
  .dispatch-table th {
    padding: 15px 0;
    font-size: 1.6rem;
  }
}

.dispatch-table th:first-child {
  border-left: none;
}

.dispatch-table th:last-child {
  border-right: none;
}

.dispatch-table td {
  padding: 24px 0;
  border: 1px solid #ddd;
  background-color: #fff;
  font-size: 1.8rem;
  font-weight: 500;
  color: #666;
  text-align: center;
  vertical-align: middle;
}

@media (max-width: 767px) {
  .dispatch-table td {
    padding: 15px 0;
    font-size: 1.6rem;
  }
}

.dispatch-table td:first-child {
  border-left: none;
}

.dispatch-table td:last-child {
  border-right: none;
}

.dispatch-table tbody tr:first-child th {
  border-top: none;
}

.dispatch-table tbody tr:first-child td {
  border-top: none;
}

.world .gallery-img {
  position: relative;
}

.world .gallery-img span {
  width: 100%;
  height: 100%;
  display: none;
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 0;
  right: auto;
  bottom: auto;
  left: 0;
}

.world .gallery-img span img {
  width: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.world .gallery-wrap li:hover .gallery-img span {
  display: inline-block;
}

.honor-list__li {
  width: calc(50% - 20px);
  margin: 0 40px 40px 0;
  border: 1px solid #ddd;
  border-top: 2px solid #9c7e29;
  display: inline-block;
  background-color: #f9f9f9;
  vertical-align: top;
}

@media (max-width: 1250px) {
  .honor-list__li {
    width: calc(50% - 10px);
    margin: 0 20px 20px 0;
  }
}

@media (max-width: 991px) {
  .honor-list__li {
    width: 100%;
    margin-right: 0;
  }
}

.honor-list__li:nth-child(2n) {
  margin-right: 0;
}

.honor-list__img {
  width: 225px;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
}

@media (max-width: 1420px) {
  .honor-list__img {
    width: 180px;
  }
}

@media (max-width: 767px) {
  .honor-list__img {
    width: 100%;
    padding: 10px 0;
  }
}

.honor-list__text {
  width: calc(100% - 225px);
  padding: 45px;
  display: inline-block;
  background-color: #fff;
  vertical-align: middle;
  position: relative;
}

@media (max-width: 1420px) {
  .honor-list__text {
    width: calc(100% - 180px);
  }
}

@media (max-width: 1250px) {
  .honor-list__text {
    padding: 45px 30px;
  }
}

@media (max-width: 991px) {
  .honor-list__text {
    height: auto !important;
  }
}

@media (max-width: 767px) {
  .honor-list__text {
    width: 100%;
    padding: 30px 15px;
  }
}

.honor-list__text__insignia {
  width: 80px;
  height: 54px;
  padding-top: 20px;
  display: inline-block;
  background-color: #9c7e29;
  font-size: 1.6rem;
  font-weight: 500;
  color: #fff;
  text-align: center;
  position: absolute;
  top: 0;
  right: 45px;
  bottom: auto;
  left: auto;
}

@media (max-width: 767px) {
  .honor-list__text__insignia {
    right: 15px;
  }
}

.honor-list__text__insignia:after {
  content: '';
  width: 80px;
  height: 15px;
  border-top: 15px solid #9c7e29;
  border-left: 40px solid transparent;
  border-right: 40px solid transparent;
  border-bottom: none;
  display: inline-block;
  position: absolute;
  top: 100%;
  right: auto;
  bottom: auto;
  left: 0;
  box-sizing: border-box;
}

.honor-list__text div:not(.honor-list__text__insignia) {
  padding-right: 80px;
  padding-bottom: 10px;
  border-bottom: 1px dashed #ddd;
}

@media (max-width: 1420px) {
  .honor-list__text div:not(.honor-list__text__insignia) {
    padding-right: 0;
    padding-top: 20px;
  }
}

@media (max-width: 767px) {
  .honor-list__text div:not(.honor-list__text__insignia) {
    padding-top: 40px;
  }
}

.honor-list__text h4 {
  margin-bottom: 5px;
  font-size: 2.4rem;
  font-weight: 500;
  color: #222;
}

@media (max-width: 1650px) {
  .honor-list__text h4 {
    font-size: 2rem;
  }
}

@media (max-width: 1420px) {
  .honor-list__text h4 {
    font-size: 1.8rem;
  }
}

.honor-list__text p {
  font-size: 1.4rem;
  font-weight: 500;
  color: #666;
}

.honor-list__text ul {
  padding: 25px 0 0;
}

.honor-list__text li {
  margin-bottom: 20px;
  padding-left: 100px;
  font-size: 1.6rem;
  font-weight: 500;
  color: #666;
  position: relative;
}

.honor-list__text li:last-child {
  margin-bottom: 0;
}

@media (max-width: 1250px) {
  .honor-list__text li {
    padding-left: 90px;
    font-size: 1.4rem;
  }
}

.honor-list__text li:before {
  content: '';
  width: 5px;
  height: 2px;
  display: inline-block;
  background-color: #666;
  position: absolute;
  top: 10px;
  right: auto;
  bottom: auto;
  left: 0;
}

@media (max-width: 1250px) {
  .honor-list__text li:before {
    top: 8px;
  }
}

.honor-list__text li span {
  width: 80px;
  border-right: 1px solid #ddd;
  display: inline-block;
  position: absolute;
  top: 0;
  right: auto;
  bottom: auto;
  left: 10px;
}

@media (max-width: 1250px) {
  .honor-list__text li span {
    width: 70px;
  }
}

.demonstration-history .history-list__info li {
  padding-left: 130px;
}

.demonstration-history .history-list__info__date {
  width: 130px;
}

.en-main-container {
  width: 1440px;
  margin: 0 auto;
  padding: 0 15px;
}

@media (max-width: 1550px) {
  .en-main-container {
    width: 1200px;
  }
}

@media (max-width: 1320px) {
  .en-main-container {
    width: 960px;
  }
}

@media (max-width: 991px) {
  .en-main-container {
    width: 720px;
  }
}

@media (max-width: 767px) {
  .en-main-container {
    width: 100%;
  }
}

.en-main-container .wrap {
  width: 100%;
  font-size: 0;
  position: relative;
}

.en-header {
  width: 100%;
  position: absolute;
  top: 0;
  right: auto;
  bottom: auto;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 22;
  overflow: hidden;
}

@media (max-width: 991px) {
  .en-header {
    height: 115px;
    background-color: #000;
    position: fixed;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    overflow: unset;
  }
}

@media (max-width: 767px) {
  .en-header {
    height: 90px;
  }
}

.en-header-top {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.en-header-top__link {
  padding: 17px 0;
  text-align: right;
}

@media (max-width: 767px) {
  .en-header-top__link {
    padding: 10px 0;
  }
}

.en-header-top__link a {
  font-size: 1.3rem;
  font-weight: 400;
  color: #fff;
  vertical-align: middle;
}

@media (max-width: 767px) {
  .en-header-top__link a {
    font-size: 1.2rem;
  }
}

.en-header-top__link span {
  width: 1px;
  height: 8px;
  margin: 0 20px;
  display: inline-block;
  background-color: #fff;
  vertical-align: middle;
}

@media (max-width: 767px) {
  .en-header-top__link span {
    margin: 0 15px;
  }
}

.en-header-bottom {
  position: relative;
}

.en-header-bottom > .en-main-container > .wrap {
  position: initial;
}

.en-header-bottom__gnb-side {
  width: 30%;
  padding: 40px 15px 0 0;
  background-color: #f2f2f4;
  position: absolute;
  top: 100%;
  right: auto;
  bottom: auto;
  left: 0;
  background-image: url("/template/resources/images/cont/en-gnb-bg.png");
  background-size: cover;
}

@media (max-width: 1720px) {
  .en-header-bottom__gnb-side {
    width: 25%;
  }
}

@media (max-width: 991px) {
  .en-header-bottom__gnb-side {
    display: none;
  }
}

.en-header-bottom__gnb-side h3 {
  display: inline-block;
  font-size: 2.3rem;
  font-weight: 700;
  color: #111;
  line-height: 2.9rem;
}

.en-header-bottom__logo {
  width: 247px;
  display: inline-block;
  vertical-align: middle;
}

@media (max-width: 991px) {
  .en-header-bottom__logo {
    width: calc(100% - 107px);
    padding: 9px 0;
  }
}

@media (max-width: 767px) {
  .en-header-bottom__logo {
    width: calc(100% - 27px);
  }
}

@media (max-width: 767px) {
  .en-header-bottom__logo img {
    width: 130px;
  }
}

.en-header-bottom__gnb {
  width: calc(100% - 494px);
  display: inline-block;
  vertical-align: middle;
  text-align: center;
}

@media (max-width: 1320px) {
  .en-header-bottom__gnb {
    width: calc(100% - 374px);
  }
}

@media (max-width: 991px) {
  .en-header-bottom__gnb {
    display: none;
  }
}

.en-header-bottom__gnb .one-depth__list {
  margin: 0 30px;
  display: inline-block;
  vertical-align: middle;
}

@media (max-width: 1550px) {
  .en-header-bottom__gnb .one-depth__list {
    margin: 0 20px;
  }
}

@media (max-width: 1320px) {
  .en-header-bottom__gnb .one-depth__list {
    margin: 0 15px;
  }
}

.en-header-bottom__gnb .one-depth__list > a p {
  font-size: 1.8rem;
  font-weight: 700;
  color: #fff;
  line-height: 80px;
  position: relative;
}

@media (max-width: 1320px) {
  .en-header-bottom__gnb .one-depth__list > a p {
    font-size: 1.6rem;
  }
}

.en-header-bottom__gnb .one-depth__list:hover > a p:after {
  content: '';
  width: 100%;
  height: 5px;
  display: inline-block;
  background-color: #0078c7;
  position: absolute;
  top: auto;
  right: auto;
  bottom: 0;
  left: 0;
}

.en-header-bottom__gnb .two-depth {
  padding: 25px 15px;
  position: absolute;
  top: 80px;
  right: auto;
  bottom: auto;
  left: 0;
}

.en-header-bottom__gnb .two-depth:after {
  content: '';
  width: 1px;
  height: calc(100% - 50px);
  border-left: 1px dashed #e2e3e6;
  display: inline-block;
}

.en-header-bottom__gnb .two-depth:last-child:after {
  display: none;
}

.en-header-bottom__gnb .two-depth__tit p {
  margin-bottom: 20px;
  font-size: 1.6rem;
  font-weight: 700;
  color: #222;
}

.en-header-bottom__gnb .two-depth__list {
  margin-bottom: 15px;
}

.en-header-bottom__gnb .two-depth__list:last-child {
  margin-bottom: 0;
}

.en-header-bottom__gnb .two-depth__list a {
  font-size: 1.3rem;
  font-weight: 400;
  color: #999;
}

.en-header-bottom__gnb .two-depth__list a:hover {
  font-weight: 700;
  color: #0078c7;
  text-decoration: underline;
}

.en-header-bottom__lang {
  width: 100px;
  height: 80px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
}

@media (max-width: 1420px) {
  .en-header-bottom__lang {
    width: 90px;
  }
}

@media (max-width: 1260px) {
  .en-header-bottom__lang {
    width: 60px;
  }
}

@media (max-width: 991px) {
  .en-header-bottom__lang {
    height: 61px;
  }
}

@media (max-width: 767px) {
  .en-header-bottom__lang {
    display: none;
  }
}

.en-header-bottom__lang > a {
  padding-right: 20px;
  font-size: 1.4rem;
  font-weight: 700;
  color: #222;
  position: absolute;
  top: 50%;
  left: auto;
  right: 40px;
  transform: translateY(-50%);
}

.en-header-bottom__lang > a:after {
  content: '';
  width: 8px;
  height: 8px;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: auto;
  right: 0;
  transform: translateY(-50%);
  transform: translateY(-70%) rotate(135deg);
  box-sizing: border-box;
}

@media (max-width: 1320px) {
  .en-header-bottom__lang > a {
    font-size: 1.2rem;
  }
}

@media (max-width: 1260px) {
  .en-header-bottom__lang > a {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 0;
    right: auto;
    transform: translateY(-50%);
  }
}

.en-header-bottom__lang__list {
  height: 0;
  border-radius: 5px;
  background-color: #fff;
  position: absolute;
  top: 60px;
  right: 40px;
  bottom: auto;
  left: auto;
  overflow: hidden;
  z-index: 1;
}

@media (max-width: 1260px) {
  .en-header-bottom__lang__list {
    left: 0;
    right: auto;
  }
}

.en-header-bottom__lang__list li {
  padding: 3px 10px;
}

.en-header-bottom__lang__list li:first-child {
  padding: 10px 10px 3px 10px;
}

.en-header-bottom__lang__list li:last-child {
  padding: 3px 10px 10px 10px;
}

.en-header-bottom__lang__list a {
  font-size: 1.4rem;
  font-weight: 700;
  color: #222;
}

@media (max-width: 1320px) {
  .en-header-bottom__lang__list a {
    font-size: 1.2rem;
  }
}

.en-header-bottom__all-btn {
  margin-left: 120px;
  display: inline-block;
  vertical-align: middle;
}

@media (max-width: 1320px) {
  .en-header-bottom__all-btn {
    margin-left: 0px;
  }
}

@media (max-width: 991px) {
  .en-header-bottom__all-btn {
    margin-left: 20px;
  }
}

@media (max-width: 767px) {
  .en-header-bottom__all-btn {
    margin-left: 0;
  }
}

.en-header-bottom__all-btn a {
  width: 27px;
  height: 23px;
  position: relative;
}

.en-header-bottom__all-btn a span {
  width: 27px;
  height: 3px;
  display: inline-block;
  background-color: #fff;
  position: absolute;
  left: 0;
  transition-duration: .3s;
}

.en-header-bottom__all-btn a span:nth-child(1) {
  top: 0;
}

.en-header-bottom__all-btn a span:nth-child(2) {
  top: 10px;
}

.en-header-bottom__all-btn a span:nth-child(3) {
  top: 20px;
}

.en-header-bottom__all-btn a.active span:nth-child(1) {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: translate(-50%, -50%) rotate(135deg);
}

.en-header-bottom__all-btn a.active span:nth-child(2) {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: translate(-50%, -50%) scale(0);
}

.en-header-bottom__all-btn a.active span:nth-child(3) {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: translate(-50%, -50%) rotate(45deg);
}

.en-header.hover {
  height: auto;
  background-color: #fff;
}

.en-header.hover .en-header-top {
  border-bottom: 1px solid #eee;
}

.en-header.hover .en-header-top__link a {
  color: #111;
}

.en-header.hover .en-header-top__link span {
  background-color: #111;
}

.en-header.hover .en-header-bottom:after {
  content: '';
  width: 100%;
  height: 1px;
  display: inline-block;
  background-color: #eee;
  position: absolute;
  top: auto;
  right: auto;
  bottom: 0;
  left: 0;
}

@media (max-width: 991px) {
  .en-header.hover .en-header-bottom:after {
    display: none;
  }
}

.en-header.hover .en-header-bottom__gnb .one-depth__list > a p {
  color: #111;
}

.en-header.hover .en-header-bottom__lang > a:after {
  border-top: 2px solid #111;
  border-right: 2px solid #111;
}

.en-header.hover .en-header-bottom__all-btn .en-all-btn span {
  background-color: #111;
}

.en-header__all-menu {
  width: 100%;
  height: 100vh;
  padding: 30px 0;
  position: fixed;
  top: 0;
  right: auto;
  bottom: auto;
  left: -100%;
  background-color: #000;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.en-header__all-menu::-webkit-scrollbar {
  display: none;
}

@media (max-width: 991px) {
  .en-header__all-menu {
    height: calc(100vh - 115px);
    top: 115px;
  }
}

@media (max-width: 767px) {
  .en-header__all-menu {
    height: calc(100vh - 90px);
    top: 90px;
  }
}

.en-header__all-menu.visible {
  left: 0;
}

.en-header__all-menu__logo {
  margin: 40px 0 20px;
  position: relative;
}

@media (max-width: 991px) {
  .en-header__all-menu__logo {
    display: none;
  }
}

.en-header__all-menu__close {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 50%;
  left: auto;
  right: 0;
  transform: translateY(-50%);
}

.en-header__all-menu__close:before {
  content: '';
  width: 30px;
  height: 3px;
  display: inline-block;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: translate(-50%, -50%) rotate(45deg);
}

.en-header__all-menu__close:after {
  content: '';
  width: 30px;
  height: 3px;
  display: inline-block;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: translate(-50%, -50%) rotate(135deg);
}

.en-header__all-menu .one-depth__list {
  width: calc(100% / 3);
  margin-bottom: 30px;
  padding-top: 15px;
  border-top: 2px solid #fff;
  display: inline-block;
  vertical-align: top;
}

@media (max-width: 991px) {
  .en-header__all-menu .one-depth__list {
    width: 50%;
  }
}

@media (max-width: 767px) {
  .en-header__all-menu .one-depth__list {
    width: 100%;
    margin-bottom: 0;
    padding: 15px 15px 0;
  }
}

.en-header__all-menu .one-depth__list:last-child {
  margin-bottom: 0;
}

.en-header__all-menu .one-depth__list > a {
  margin-bottom: 20px;
}

@media (max-width: 767px) {
  .en-header__all-menu .one-depth__list > a {
    margin-bottom: 10px;
  }
}

.en-header__all-menu .one-depth__list > a p {
  font-size: 2.4rem;
  font-weight: 700;
  color: #fff;
}

@media (max-width: 767px) {
  .en-header__all-menu .one-depth__list > a p {
    font-size: 2rem;
  }
}

.en-header__all-menu .two-depth__list {
  padding-bottom: 10px;
}

.en-header__all-menu .two-depth__list > a {
  margin-bottom: 10px;
  font-size: 1.8rem;
  font-weight: 500;
  color: #fff;
}

@media (max-width: 767px) {
  .en-header__all-menu .two-depth__list > a {
    font-size: 1.6rem;
  }
}

.en-header__all-menu .three-depth {
  padding-left: 15px;
}

.en-header__all-menu .three-depth__list {
  margin-bottom: 5px;
}

.en-header__all-menu .three-depth__list a {
  padding-left: 15px;
  font-size: 1.6rem;
  font-weight: 500;
  color: #fff;
  position: relative;
}

@media (max-width: 767px) {
  .en-header__all-menu .three-depth__list a {
    font-size: 1.4rem;
  }
}

.en-header__all-menu .three-depth__list a:before {
  content: '';
  width: 5px;
  height: 5px;
  display: inline-block;
  background-color: #fff;
  position: absolute;
  top: 8px;
  right: auto;
  bottom: auto;
  left: 0;
}

.en-quick {
  position: fixed;
  top: 50%;
  right: 45px;
  opacity: 0;
  transition-duration: .5s;
  z-index: 21;
  pointer-events: none;
}

@media (max-width: 991px) {
  .en-quick {
    top: auto;
    right: 10px;
    bottom: 0;
  }
}

.en-quick.visible {
  position: fixed;
  top: 50%;
  left: auto;
  right: 45px;
  transform: translateY(-50%);
  opacity: 1;
  pointer-events: all;
}

@media (max-width: 991px) {
  .en-quick.visible {
    top: auto;
    right: 10px;
    bottom: 15px;
    transform: none;
  }
}

.en-quick a {
  width: 67px;
  height: 67px;
  border-radius: 67px;
  display: block;
  background-color: #677377;
  position: relative;
}

@media (max-width: 991px) {
  .en-quick a {
    width: 50px;
    height: 50px;
  }
}

.en-quick a.en-quick__link {
  margin-bottom: 10px;
  background-color: #2d8ccf;
}

.en-quick a p {
  width: 100%;
  font-size: 1.3rem;
  font-weight: 700;
  color: #fff;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 0;
  right: auto;
  transform: translateY(-50%);
}

@media (max-width: 991px) {
  .en-quick a p {
    font-size: 1.2rem;
  }
}

.en-quick a img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.en-main-banner {
  overflow: hidden;
  position: relative;
}

@media (max-width: 767px) {
  .en-main-banner {
    margin-top: 90px;
  }
}

.en-main-banner .slick-prev {
  display: none !important;
}

.en-main-banner .item {
  width: 100%;
}

.en-main-banner .item a {
  width: 100%;
}

.en-main-banner .item img {
  width: 100%;
}

.en-main-banner__tools {
  width: 100%;
  position: absolute;
  top: auto;
  right: auto;
  bottom: 85px;
  left: 0;
}

@media (max-width: 991px) {
  .en-main-banner__tools {
    bottom: 40px;
  }
}

@media (max-width: 767px) {
  .en-main-banner__tools {
    bottom: 15px;
  }
}

.en-main-banner__tools:after {
  content: '';
  display: block;
  clear: both;
}

.en-main-banner__tools__wrap {
  font-size: 0;
}

.en-main-banner__tools__next {
  width: 78px;
  height: 78px;
  border-radius: 78px;
  display: inline-block;
  background-color: #2d8ccf;
  font-size: 0;
  vertical-align: middle;
  position: relative;
}

@media (max-width: 767px) {
  .en-main-banner__tools__next {
    display: none !important;
  }
}

.en-main-banner__tools__next img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.en-main-banner__tools__right {
  width: calc(100% - 78px);
  display: inline-block;
  vertical-align: middle;
  text-align: right;
}

@media (max-width: 767px) {
  .en-main-banner__tools__right {
    width: 100%;
  }
}

.en-main-banner__tools__dots {
  display: inline-block;
  vertical-align: middle;
}

.en-main-banner__tools__paging {
  display: inline-block;
  vertical-align: middle;
}

.en-main-banner__tools__paging p {
  margin: 0 7px 0 17px;
  display: inline-block;
  font-size: 1.5rem;
  font-weight: 400;
  color: #fff;
  line-height: 1;
  vertical-align: bottom;
}

@media (max-width: 767px) {
  .en-main-banner__tools__paging p {
    margin: 0 7px;
    font-size: 1.2rem;
  }
}

.en-main-banner__tools__paging .current-num {
  margin: 0;
  font-size: 7rem;
  font-weight: 700;
  color: #fff;
  font-family: 'NanumMyeongjo';
}

@media (max-width: 767px) {
  .en-main-banner__tools__paging .current-num {
    font-size: 3rem;
  }
}

.en-main-banner__tools__paging .tot-num {
  margin: 0;
  color: rgba(255, 255, 255, 0.5);
}

@media (max-width: 767px) {
  .en-main-banner__tools__paging .tot-num {
    color: #fff;
  }
}

.en-main-section1 {
  padding: 50px 0 80px;
}

@media (max-width: 767px) {
  .en-main-section1 {
    padding: 30px 0;
  }
}

.en-main-section3 {
  background-image: url("/template/resources/images/cont/en-main-issue-bg.png");
  background-size: cover;
  background-position: center;
}

.en-main-section5 {
  padding: 80px 0;
  background-color: #f0282d;
  position: relative;
}

@media (max-width: 767px) {
  .en-main-section5 {
    padding: 30px 0;
  }
}

.en-main-section5:before {
  content: '';
  width: 150px;
  height: 150px;
  border-top: 75px solid #fff;
  border-right: 75px solid transparent;
  border-bottom: 75px solid transparent;
  border-left: 75px solid #fff;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  right: auto;
  bottom: auto;
  left: 0;
}

@media (max-width: 767px) {
  .en-main-section5:before {
    width: 80px;
    height: 80px;
    border-top: 40px solid #fff;
    border-right: 40px solid transparent;
    border-bottom: 40px solid transparent;
    border-left: 40px solid #fff;
  }
}

.en-main-tit {
  margin-bottom: 40px;
  font-family: 'pretendard';
}

@media (max-width: 767px) {
  .en-main-tit {
    margin-bottom: 15px;
  }
}

.en-main-tit h3 {
  padding-right: 20px;
  display: inline-block;
  font-size: 4.1rem;
  font-weight: 700;
  color: #b2b2b2;
  line-height: 1;
  position: relative;
}

@media (max-width: 767px) {
  .en-main-tit h3 {
    font-size: 3rem;
  }
}

.en-main-tit h3:after {
  content: '';
  width: 8px;
  height: 8px;
  border-radius: 8px;
  display: inline-block;
  background-color: #2d8ccf;
  position: absolute;
  top: 0;
  right: 0;
  bottom: auto;
  left: auto;
}

.en-main-tit h3 span {
  color: #333f48;
}

.en-main-notice {
  width: calc(50% - 15px);
  display: inline-block;
  vertical-align: top;
  letter-spacing: -1px;
  font-family: 'pretendard';
  font-size: 0;
}

@media (max-width: 991px) {
  .en-main-notice {
    width: 100%;
  }
}

.en-main-notice__tab {
  width: 140px;
  display: inline-block;
  vertical-align: top;
}

@media (max-width: 767px) {
  .en-main-notice__tab {
    width: 100%;
    margin-bottom: 15px;
  }
}

.en-main-notice__tab a {
  margin-bottom: 30px;
  padding-right: 50px;
  display: block;
  font-size: 2.1rem;
  font-weight: 700;
  color: #90979c;
  position: relative;
}

@media (max-width: 767px) {
  .en-main-notice__tab a {
    width: calc(50% - 10px);
    margin: 0 5px;
    padding-right: 0;
    display: inline-block;
    background-color: #90979c;
    font-size: 1.6rem;
    color: #fff;
    text-align: center;
    vertical-align: top;
    line-height: 3rem;
  }
  .en-main-notice__tab a br {
    display: none;
  }
}

.en-main-notice__tab a:before {
  content: '';
  width: 29px;
  height: 29px;
  display: inline-block;
  background-image: url("/template/resources/images/icons/en-main-news-icon.png");
  position: absolute;
  top: 0;
  right: 0;
  bottom: auto;
  left: auto;
}

@media (max-width: 767px) {
  .en-main-notice__tab a:before {
    display: none;
  }
}

.en-main-notice__tab a:last-child {
  margin-bottom: 0;
}

.en-main-notice__tab a.selected {
  color: #2d8ccf;
}

@media (max-width: 767px) {
  .en-main-notice__tab a.selected {
    background-color: #2d8ccf;
    color: #fff;
  }
}

.en-main-notice__tab a.selected:before {
  background-image: url("/template/resources/images/icons/en-main-news-icon_active.png");
}

.en-main-notice__list {
  width: calc(100% - 140px);
  padding-left: 50px;
  display: inline-block;
  vertical-align: top;
  position: relative;
}

@media (max-width: 1320px) {
  .en-main-notice__list {
    padding-left: 25px;
  }
}

@media (max-width: 767px) {
  .en-main-notice__list {
    width: 100%;
    padding-left: 5px;
  }
}

.en-main-notice__list ul {
  width: 100%;
  display: none;
}

.en-main-notice__list ul.visible {
  display: block;
}

.en-main-notice__list li {
  margin-bottom: 30px;
}

@media (max-width: 1320px) {
  .en-main-notice__list li {
    margin-bottom: 15px;
  }
}

.en-main-notice__list a {
  max-width: 100%;
}

.en-main-notice__list h3 {
  width: 100%;
  margin-bottom: 10px;
  padding-right: 50px;
  font-size: 1.8rem;
  font-weight: 700;
  color: #727b81;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 2.4rem;
  position: relative;
}

@media (max-width: 1320px) {
  .en-main-notice__list h3 {
    margin-bottom: 5px;
  }
}

.en-main-notice__list h3 span {
  width: 23px;
  height: 23px;
  border-radius: 23px;
  display: inline-block;
  background-color: #ee542d;
  font-size: 1.6rem;
  color: rgba(255, 255, 255, 0.5);
  text-align: center;
  line-height: 2.3rem;
  position: absolute;
  top: 50%;
  left: auto;
  right: 0;
  transform: translateY(-50%);
}

.en-main-notice__list p {
  font-size: 1.4rem;
  font-weight: 600;
  color: #333f48;
}

.en-main-side-popup {
  width: calc(50% - 15px);
  margin-left: 30px;
  padding-left: 80px;
  display: inline-block;
  vertical-align: top;
}

@media (max-width: 1550px) {
  .en-main-side-popup {
    padding-left: 30px;
  }
}

@media (max-width: 1320px) {
  .en-main-side-popup {
    padding-left: 0;
  }
}

@media (max-width: 991px) {
  .en-main-side-popup {
    width: 100%;
    margin-top: 50px;
    margin-left: 0;
  }
}

@media (max-width: 767px) {
  .en-main-side-popup {
    margin-top: 30px;
  }
}

.en-main-side-popup__tools h3 {
  display: inline-block;
  font-size: 2.1rem;
  font-weight: 700;
  color: #111;
  vertical-align: middle;
}

@media (max-width: 767px) {
  .en-main-side-popup__tools h3 {
    margin-bottom: 5px;
    display: block;
  }
}

.en-main-side-popup__tools > div {
  width: calc(100% - 140px);
  display: inline-block;
  vertical-align: middle;
  text-align: right;
}

@media (max-width: 767px) {
  .en-main-side-popup__tools > div {
    width: 100%;
  }
}

.en-main-side-popup__tools__paging {
  margin-right: 25px;
  display: inline-block;
  vertical-align: middle;
}

.en-main-side-popup__tools__paging p {
  display: inline-block;
  font-size: 1.8rem;
  font-weight: 500;
  color: #969696;
  vertical-align: middle;
}

.en-main-side-popup__tools__paging p.current-num {
  color: #000;
}

.en-main-side-popup__tools a {
  font-size: 0;
  vertical-align: middle;
  position: relative;
}

.en-main-side-popup__tools__prev {
  width: 8px;
  height: 14px;
}

.en-main-side-popup__tools__prev:after {
  content: '';
  width: 10px;
  height: 10px;
  border-top: 2px solid #7a7d96;
  border-right: 2px solid #7a7d96;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: translate(-30%, -50%) rotate(-135deg);
  box-sizing: border-box;
}

.en-main-side-popup__tools__pause {
  width: 7px;
  height: 12px;
  margin: 0 14px;
}

.en-main-side-popup__tools__pause:after {
  content: '';
  width: 7px;
  height: 12px;
  border-left: 2px solid #333;
  border-right: 2px solid #333;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
}

.en-main-side-popup__tools__play {
  width: 7px;
  height: 12px;
  margin: 0 14px;
  display: none;
}

.en-main-side-popup__tools__play:after {
  content: '';
  width: 7px;
  height: 12px;
  border-left: 7px solid #333;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-right: none;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
}

.en-main-side-popup__tools__next {
  width: 8px;
  height: 14px;
}

.en-main-side-popup__tools__next:after {
  content: '';
  width: 10px;
  height: 10px;
  border-top: 2px solid #7a7d96;
  border-right: 2px solid #7a7d96;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: translate(-70%, -50%) rotate(45deg);
  box-sizing: border-box;
}

.en-main-side-popup__slide {
  margin-top: 30px;
}

@media (max-width: 767px) {
  .en-main-side-popup__slide {
    margin-top: 5px;
  }
}

.en-main-information {
  font-size: 0;
  position: relative;
}

.en-main-information__left {
  width: 50%;
  display: inline-block;
  vertical-align: top;
  position: relative;
}

@media (max-width: 991px) {
  .en-main-information__left {
    height: 350px;
    background-image: url("/template/resources/images/cont/en-main-meaning-bg.png");
    background-size: cover;
    background-position: center;
    overflow: hidden;
  }
}

@media (max-width: 767px) {
  .en-main-information__left {
    width: 100%;
    height: 300px;
  }
}

@media (max-width: 991px) {
  .en-main-information__left > img {
    display: none;
  }
}

.en-main-information__left span {
  width: 100%;
  height: 100%;
  display: none;
  background-color: rgba(69, 131, 225, 0.85);
  position: absolute;
  top: 0;
  right: auto;
  bottom: auto;
  left: 0;
}

.en-main-information__left:hover span {
  display: inline-block;
}

.en-main-information__left:before {
  content: '';
  width: 1px;
  height: 100%;
  display: inline-block;
  background-color: rgba(255, 255, 255, 0.3);
  position: absolute;
  top: 0;
  right: auto;
  bottom: auto;
  left: 40px;
}

@media (max-width: 767px) {
  .en-main-information__left:before {
    display: none;
  }
}

.en-main-information__left > div {
  width: 100%;
  padding-left: 180px;
  position: absolute;
  top: 50%;
  left: 0;
  right: auto;
  transform: translateY(-50%);
  z-index: 1;
}

@media (max-width: 1550px) {
  .en-main-information__left > div {
    padding-left: 150px;
  }
}

@media (max-width: 1320px) {
  .en-main-information__left > div {
    padding-left: 120px;
  }
}

@media (max-width: 1120px) {
  .en-main-information__left > div {
    padding-left: 105px;
  }
}

@media (max-width: 991px) {
  .en-main-information__left > div {
    top: 60%;
  }
}

@media (max-width: 767px) {
  .en-main-information__left > div {
    top: 50%;
    padding: 15px;
    text-align: center;
  }
}

.en-main-information__left > div img {
  margin-right: 60px;
  vertical-align: middle;
}

@media (max-width: 1550px) {
  .en-main-information__left > div img {
    margin-right: 30px;
  }
}

@media (max-width: 1120px) {
  .en-main-information__left > div img {
    margin-right: 15px;
  }
}

@media (max-width: 767px) {
  .en-main-information__left > div img {
    display: none;
  }
}

.en-main-information__left h4 {
  display: inline-block;
  font-size: 5rem;
  font-weight: 700;
  color: transparent;
  line-height: 1;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fff;
  position: absolute;
  top: 50%;
  left: -20px;
  right: auto;
  transform: translateY(-50%);
  transform: translateY(-50%) rotate(-90deg);
}

@media (max-width: 1320px) {
  .en-main-information__left h4 {
    font-size: 3rem;
    left: 0;
  }
}

@media (max-width: 767px) {
  .en-main-information__left h4 {
    display: none;
  }
}

.en-main-information__right {
  width: 50%;
  display: inline-block;
  vertical-align: top;
  position: relative;
}

@media (max-width: 991px) {
  .en-main-information__right {
    height: 350px;
    background-image: url("/template/resources/images/cont/en-main-history-bg.png");
    background-size: cover;
    background-position: center;
    overflow: hidden;
  }
}

@media (max-width: 767px) {
  .en-main-information__right {
    width: 100%;
    height: 300px;
  }
}

@media (max-width: 991px) {
  .en-main-information__right > img {
    display: none;
  }
}

.en-main-information__right span {
  width: 100%;
  height: 100%;
  display: none;
  background-color: rgba(69, 131, 225, 0.85);
  position: absolute;
  top: 0;
  right: auto;
  bottom: auto;
  left: 0;
}

.en-main-information__right:hover span {
  display: inline-block;
}

.en-main-information__right:before {
  content: '';
  width: 1px;
  height: 100%;
  display: inline-block;
  background-color: rgba(255, 255, 255, 0.3);
  position: absolute;
  top: 0;
  right: 40px;
  bottom: auto;
  left: auto;
}

@media (max-width: 767px) {
  .en-main-information__right:before {
    display: none;
  }
}

.en-main-information__right:after {
  content: '';
  width: 185px;
  height: 185px;
  border-top: calc(185px / 2) solid transparent;
  border-right: calc(185px / 2) solid #fff;
  border-bottom: calc(185px / 2) solid #fff;
  border-left: calc(185px / 2) solid transparent;
  box-sizing: border-box;
  position: absolute;
  top: auto;
  right: 0;
  bottom: 0;
  left: auto;
}

@media (max-width: 1120px) {
  .en-main-information__right:after {
    width: 120px;
    height: 120px;
    border-top: 60px solid transparent;
    border-right: 60px solid #fff;
    border-bottom: 60px solid #fff;
    border-left: 60px solid transparent;
  }
}

@media (max-width: 767px) {
  .en-main-information__right:after {
    display: none;
  }
}

.en-main-information__right > div {
  width: 100%;
  padding-right: 180px;
  position: absolute;
  top: 50%;
  left: 0;
  right: auto;
  transform: translateY(-50%);
  text-align: right;
  z-index: 1;
}

@media (max-width: 1550px) {
  .en-main-information__right > div {
    padding-right: 150px;
  }
}

@media (max-width: 1320px) {
  .en-main-information__right > div {
    padding-right: 120px;
  }
}

@media (max-width: 1120px) {
  .en-main-information__right > div {
    padding-right: 105px;
  }
}

@media (max-width: 991px) {
  .en-main-information__right > div {
    top: 60%;
  }
}

@media (max-width: 767px) {
  .en-main-information__right > div {
    top: 50%;
    padding: 15px;
    text-align: center;
  }
}

.en-main-information__right > div img {
  margin-left: 60px;
  vertical-align: middle;
}

@media (max-width: 1550px) {
  .en-main-information__right > div img {
    margin-left: 30px;
  }
}

@media (max-width: 1120px) {
  .en-main-information__right > div img {
    margin-left: 15px;
  }
}

@media (max-width: 767px) {
  .en-main-information__right > div img {
    display: none;
  }
}

.en-main-information__right h4 {
  display: inline-block;
  font-size: 5rem;
  font-weight: 700;
  color: transparent;
  line-height: 1;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fff;
  position: absolute;
  top: 50%;
  left: auto;
  right: 0;
  transform: translateY(-50%);
  transform: translateY(-50%) rotate(-90deg);
}

@media (max-width: 1320px) {
  .en-main-information__right h4 {
    font-size: 3rem;
    right: 20px;
  }
}

@media (max-width: 1120px) {
  .en-main-information__right h4 {
    right: 15px;
  }
}

@media (max-width: 767px) {
  .en-main-information__right h4 {
    display: none;
  }
}

.en-main-information__center-text {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

@media (max-width: 991px) {
  .en-main-information__center-text {
    position: absolute;
    top: 30px;
    bottom: auto;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media (max-width: 767px) {
  .en-main-information__center-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.en-main-information__center-text h4 {
  margin-bottom: 15px;
  font-size: 3rem;
  font-weight: 700;
  color: #fff;
  line-height: 1;
}

@media (max-width: 1550px) {
  .en-main-information__center-text h4 {
    margin-bottom: 10px;
    font-size: 2.2rem;
  }
}

@media (max-width: 1320px) {
  .en-main-information__center-text h4 {
    margin-bottom: 5px;
    font-size: 1.8rem;
  }
}

.en-main-information__center-text h3 {
  font-size: 5rem;
  font-weight: 700;
  color: transparent;
  line-height: 1;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fff;
}

@media (max-width: 1550px) {
  .en-main-information__center-text h3 {
    font-size: 4rem;
  }
}

@media (max-width: 1320px) {
  .en-main-information__center-text h3 {
    font-size: 3rem;
  }
}

.en-main-information__text {
  width: 400px;
  height: 401px;
  padding: 92px 15px;
  display: inline-block;
  text-align: center;
  background-image: url("/template/resources/images/cont/en-main-information-text-bg.png");
  background-size: cover;
  background-position: center;
  vertical-align: middle;
}

@media (max-width: 1720px) {
  .en-main-information__text {
    width: 300px;
    height: 301px;
    padding: 35px 21px;
  }
}

@media (max-width: 1320px) {
  .en-main-information__text {
    width: 260px;
    height: 261px;
    padding: 25px 15px;
  }
}

@media (max-width: 1120px) {
  .en-main-information__text {
    width: 201px;
    height: 201px;
  }
}

.en-main-information__text h3 {
  margin-bottom: 50px;
  font-size: 3rem;
  font-weight: 700;
  color: #fff;
  line-height: 1;
}

@media (max-width: 1720px) {
  .en-main-information__text h3 {
    margin-bottom: 30px;
    font-size: 2.4rem;
  }
}

@media (max-width: 1320px) {
  .en-main-information__text h3 {
    margin-bottom: 10px;
    font-size: 2.2rem;
  }
}

@media (max-width: 1120px) {
  .en-main-information__text h3 {
    font-size: 2rem;
  }
}

.en-main-information__text p {
  margin-bottom: 20px;
  font-size: 1.5rem;
  font-weight: 600;
  color: #fff;
  letter-spacing: -1px;
  line-height: 1.6;
}

@media (max-width: 1720px) {
  .en-main-information__text p {
    font-size: 1.4rem;
  }
  .en-main-information__text p br {
    display: none;
  }
}

@media (max-width: 1320px) {
  .en-main-information__text p {
    line-height: 2.4rem;
    height: auto;
    max-height: 12rem;
    line-height: 2.4rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    white-space: normal;
  }
}

@media (max-width: 1120px) {
  .en-main-information__text p {
    max-height: 7.2rem;
    line-height: 1.8rem;
    margin-bottom: 10px;
    -webkit-line-clamp: 4;
  }
}

.en-main-information__text a {
  font-size: 1.5rem;
  font-weight: 600;
  color: #fff;
  text-decoration: underline;
}

.en-main-issue {
  margin-top: 30px;
  padding: 100px 0 40px;
  position: relative;
  z-index: 1;
}

@media (max-width: 767px) {
  .en-main-issue {
    margin-top: 0;
    padding: 50px 0 30px;
  }
}

.en-main-issue .en-main-tit {
  width: 50%;
  margin-bottom: 0;
  display: inline-block;
  vertical-align: middle;
}

@media (max-width: 767px) {
  .en-main-issue .en-main-tit {
    width: 100%;
    margin-bottom: 15px;
  }
}

.en-main-issue__text {
  width: 50%;
  display: inline-block;
  vertical-align: middle;
  text-align: right;
}

@media (max-width: 767px) {
  .en-main-issue__text {
    width: 100%;
  }
}

.en-main-issue__text p {
  font-size: 1.8rem;
  font-weight: 600;
  color: #fff;
}

@media (max-width: 767px) {
  .en-main-issue__text p {
    font-size: 1.4rem;
  }
}

.en-main-issue__wrap {
  width: calc(100% + 20px);
  margin-left: -10px;
  margin-top: 50px;
  padding-bottom: 180px;
  position: relative;
}

@media (max-width: 1320px) {
  .en-main-issue__wrap {
    padding-bottom: 100px;
  }
}

@media (max-width: 1120px) {
  .en-main-issue__wrap {
    width: 100%;
    margin-left: 0;
  }
}

@media (max-width: 991px) {
  .en-main-issue__wrap {
    padding-bottom: 60px;
  }
}

.en-main-issue__slide .item {
  margin: 0 10px;
}

.en-main-issue__slide .item__text {
  padding: 40px;
  background-color: #2e2e2e;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.en-main-issue__slide .item__text h4 {
  margin-bottom: 10px;
  font-size: 1.4rem;
  font-weight: 600;
  color: #d4262c;
  letter-spacing: -1px;
}

.en-main-issue__slide .item__text h3 {
  font-size: 2.5rem;
  font-weight: 700;
  color: #fff;
  line-height: 1.6;
  height: 8rem;
  line-height: 1.6;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

@media (max-width: 1320px) {
  .en-main-issue__slide .item__text h3 {
    height: 6.4rem;
    font-size: 2.2rem;
    line-height: 3.2rem;
  }
}

.en-main-issue__slide .item__img {
  padding-bottom: 56.25%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  position: relative;
  overflow: hidden;
}

.en-main-issue__slide .item__img img {
  width: 100% !important;
  height: 100% !important;
  position: absolute;
  top: 0;
  right: auto;
  bottom: auto;
  left: 0;
}

.en-main-issue__slide .item__img iframe {
  width: 100% !important;
  height: 100% !important;
  position: absolute;
  top: 0;
  right: auto;
  bottom: auto;
  left: 0;
}

.en-main-issue__slide .slick-prev {
  width: 21px;
  height: 17px;
  background-image: url("/template/resources/images/icons/en-main-issue-prev.png");
  font-size: 0;
  left: -50px;
}

@media (max-width: 1120px) {
  .en-main-issue__slide .slick-prev {
    left: -20px;
  }
}

@media (max-width: 991px) {
  .en-main-issue__slide .slick-prev {
    display: none !important;
  }
}

.en-main-issue__slide .slick-prev:before {
  display: none;
}

.en-main-issue__slide .slick-next {
  width: 21px;
  height: 17px;
  background-image: url("/template/resources/images/icons/en-main-issue-next.png");
  font-size: 0;
  right: -50px;
}

@media (max-width: 1120px) {
  .en-main-issue__slide .slick-next {
    right: -20px;
  }
}

@media (max-width: 991px) {
  .en-main-issue__slide .slick-next {
    display: none !important;
  }
}

.en-main-issue__slide .slick-next:before {
  display: none;
}

.en-main-issue__tools {
  position: absolute;
  top: auto;
  right: auto;
  bottom: 0;
  left: 10px;
}

.en-main-issue__tools .slick-dots {
  margin-left: -6px;
}

.en-main-issue__tools .slick-dots li {
  margin: 0 6px;
}

.en-main-issue__tools .slick-dots li button {
  width: 13px;
  height: 13px;
  border-radius: 13px;
  display: inline-block;
  background-color: #686868;
  font-size: 0;
}

.en-main-issue__tools .slick-dots li.slick-active button {
  background-color: #fff;
}

.en-main-text {
  margin-top: -140px;
  padding-bottom: 60px;
}

@media (max-width: 1320px) {
  .en-main-text {
    margin-top: -60px;
  }
}

@media (max-width: 991px) {
  .en-main-text {
    margin-top: 30px;
  }
}

.en-main-text__text {
  width: calc(100% - 705px);
  margin-bottom: 40px;
  display: inline-block;
  vertical-align: bottom;
}

@media (max-width: 1320px) {
  .en-main-text__text {
    width: calc(100% - 500px);
  }
}

@media (max-width: 991px) {
  .en-main-text__text {
    width: calc(100% - 300px);
    margin-bottom: 20px;
  }
}

@media (max-width: 767px) {
  .en-main-text__text {
    width: 100%;
  }
}

.en-main-text__text h4 {
  margin-bottom: 20px;
  font-size: 1.8rem;
  font-weight: 500;
  color: #888;
}

@media (max-width: 767px) {
  .en-main-text__text h4 {
    margin-bottom: 10px;
    font-size: 1.4rem;
  }
}

.en-main-text__text h3 {
  font-size: 3.1rem;
  font-weight: 700;
  color: #111;
  line-height: 3.5rem;
}

@media (max-width: 1550px) {
  .en-main-text__text h3 {
    font-size: 2.6rem;
    line-height: 3rem;
  }
}

@media (max-width: 1320px) {
  .en-main-text__text h3 {
    font-size: 2.4rem;
    line-height: 2.8rem;
  }
}

@media (max-width: 991px) {
  .en-main-text__text h3 {
    font-size: 2.2rem;
    line-height: 2.6rem;
  }
}

@media (max-width: 767px) {
  .en-main-text__text h3 {
    font-size: 1.8rem;
    line-height: 2.2rem;
  }
  .en-main-text__text h3 br:last-child {
    display: none;
  }
}

.en-main-text__img {
  width: 705px;
  display: inline-block;
  vertical-align: bottom;
}

@media (max-width: 1320px) {
  .en-main-text__img {
    width: 500px;
  }
}

@media (max-width: 991px) {
  .en-main-text__img {
    width: 300px;
  }
}

@media (max-width: 767px) {
  .en-main-text__img {
    width: 100%;
  }
}

.en-main-history__img {
  width: 567px;
  display: inline-block;
  vertical-align: middle;
}

@media (max-width: 1320px) {
  .en-main-history__img {
    width: 400px;
  }
}

@media (max-width: 991px) {
  .en-main-history__img {
    width: 250px;
  }
}

@media (max-width: 767px) {
  .en-main-history__img {
    width: 100%;
    margin-bottom: 15px;
    text-align: center;
  }
  .en-main-history__img img {
    width: 60%;
  }
}

.en-main-history__text {
  width: calc(100% - 567px);
  padding-left: 200px;
  display: inline-block;
  vertical-align: middle;
}

@media (max-width: 1320px) {
  .en-main-history__text {
    width: calc(100% - 400px);
    padding-left: 150px;
  }
}

@media (max-width: 991px) {
  .en-main-history__text {
    width: calc(100% - 250px);
    padding-left: 100px;
  }
}

@media (max-width: 767px) {
  .en-main-history__text {
    width: 100%;
    padding-left: 0;
  }
}

.en-main-history__text h3 {
  margin-bottom: 30px;
  font-size: 4rem;
  font-weight: 700;
  color: #fff;
  line-height: 4.5rem;
}

@media (max-width: 1550px) {
  .en-main-history__text h3 {
    font-size: 3.4rem;
    line-height: 3.9rem;
  }
}

@media (max-width: 1320px) {
  .en-main-history__text h3 {
    font-size: 3.2rem;
    line-height: 3.7rem;
  }
}

@media (max-width: 991px) {
  .en-main-history__text h3 {
    font-size: 2.8rem;
    line-height: 3.3rem;
  }
}

@media (max-width: 767px) {
  .en-main-history__text h3 {
    margin-bottom: 15px;
    font-size: 2.4rem;
    line-height: 2.9rem;
  }
  .en-main-history__text h3 br {
    display: none;
  }
}

.en-main-history__text p {
  margin-bottom: 70px;
  font-size: 1.8rem;
  font-weight: 500;
  color: #fff;
  line-height: 1.5;
}

@media (max-width: 991px) {
  .en-main-history__text p {
    margin-bottom: 50px;
    font-size: 1.6rem;
  }
}

.en-main-history__text a {
  width: 190px;
  height: 50px;
  border-radius: 5px;
  background-color: #fff;
  font-size: 1.6rem;
  font-weight: 300;
  color: #000;
  text-align: center;
  line-height: 50px;
}

@media (max-width: 767px) {
  .en-main-history__text a {
    width: 100%;
  }
}

.en-main-site {
  padding: 25px 0;
}

.en-main-site__tools {
  width: 135px;
  display: inline-block;
  vertical-align: middle;
  text-align: right;
}

@media (max-width: 767px) {
  .en-main-site__tools {
    width: 68px;
  }
}

.en-main-site__tools a {
  width: 32px;
  height: 32px;
  margin-left: 4px;
  border-radius: 5px;
  border: 1px solid #ebebeb;
  position: relative;
}

.en-main-site__tools a:first-child {
  margin-left: 0;
}

.en-main-site__prev:before {
  content: '';
  width: 9px;
  height: 9px;
  display: inline-block;
  border-top: 3px solid #000;
  border-left: 3px solid #000;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: translate(-30%, -50%) rotate(-45deg);
  box-sizing: border-box;
}

@media (max-width: 767px) {
  .en-main-site__pause {
    display: none;
  }
}

.en-main-site__pause:before {
  content: '';
  width: 8px;
  height: 8px;
  display: inline-block;
  background-color: #000;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.en-main-site__play {
  display: none;
}

.en-main-site__play:before {
  content: '';
  width: 7px;
  height: 10px;
  display: inline-block;
  border-top: 5px solid transparent;
  border-left: 7px solid #000;
  border-bottom: 5px solid transparent;
  border-right: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
}

.en-main-site__next:before {
  content: '';
  width: 9px;
  height: 9px;
  display: inline-block;
  border-top: 3px solid #000;
  border-left: 3px solid #000;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: translate(-70%, -50%) rotate(135deg);
  box-sizing: border-box;
}

.en-main-site__slide {
  width: calc(100% - 135px);
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
}

@media (max-width: 767px) {
  .en-main-site__slide {
    width: calc(100% - 68px);
  }
}

.en-footer {
  padding: 50px 0;
  background-color: #000;
}

@media (max-width: 767px) {
  .en-footer {
    padding: 30px 0;
  }
}

.en-footer__logo {
  width: 153px;
  display: inline-block;
  vertical-align: middle;
}

@media (max-width: 767px) {
  .en-footer__logo {
    width: 100%;
  }
}

.en-footer__info {
  width: calc(100% - 338px);
  padding-left: 40px;
  display: inline-block;
  vertical-align: middle;
}

@media (max-width: 767px) {
  .en-footer__info {
    width: 100%;
    margin: 10px 0;
    padding-left: 0;
  }
}

.en-footer__info p {
  margin-top: 10px;
  font-size: 1.4rem;
  font-weight: 300;
  color: #fff;
}

.en-footer__info p:first-child {
  margin-bottom: 0;
}

.en-footer__sns {
  display: inline-block;
  vertical-align: middle;
}

@media (max-width: 767px) {
  .en-footer__sns {
    display: block;
    text-align: right;
  }
}

.en-footer__sns a {
  margin-left: 15px;
  vertical-align: middle;
}

.en-footer__sns a:first-child {
  margin-left: 0;
}

.en-sub-banner {
  height: 435px;
  background-size: cover;
  background-position: center;
  position: relative;
}

@media (max-width: 991px) {
  .en-sub-banner {
    height: 350px;
  }
}

@media (max-width: 767px) {
  .en-sub-banner {
    height: 200px;
  }
}

.en-sub-banner.bg1 {
  background-image: url("/template/resources/images/cont/en-sub-banner-bg.png");
}

.en-sub-banner__text {
  width: 100%;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 0;
  right: auto;
  transform: translateY(-50%);
  transform: translateY(-10%);
}

@media (max-width: 991px) {
  .en-sub-banner__text {
    transform: translateY(-30%);
  }
}

@media (max-width: 767px) {
  .en-sub-banner__text {
    transform: translateY(0);
  }
}

.en-sub-banner__text h3 {
  margin-bottom: 10px;
  font-size: 3rem;
  font-weight: 700;
  color: #fff;
}

@media (max-width: 767px) {
  .en-sub-banner__text h3 {
    font-size: 2rem;
  }
}

.en-sub-banner__text li {
  display: inline-block;
  font-size: 1.3rem;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.5);
  font-family: 'NanumBarunGothic';
  vertical-align: middle;
  position: relative;
}

.en-sub-banner__text li:after {
  content: '';
  width: 6px;
  height: 6px;
  margin: 0 10px;
  border-top: 1px solid #fff;
  border-right: 1px solid #fff;
  display: inline-block;
  box-sizing: border-box;
  vertical-align: middle;
  transform: rotate(45deg);
}

.en-sub-banner__text li:last-child:after {
  display: none;
}

.en-sub-banner__nav {
  width: 100%;
  background-color: #1f1f1f;
  text-align: center;
  position: absolute;
  top: auto;
  right: auto;
  bottom: 0;
  left: 0;
}

@media (max-width: 767px) {
  .en-sub-banner__nav {
    display: none;
  }
}

.en-sub-banner__nav li {
  padding: 0 15px;
  display: inline-block;
  vertical-align: middle;
}

.en-sub-banner__nav li a {
  font-size: 1.5rem;
  font-weight: 700;
  color: #fff;
  line-height: 77px;
  font-family: 'NanumBarunGothic';
  position: relative;
}

@media (max-width: 1400px) {
  .en-sub-banner__nav li a {
    line-height: 60px;
  }
}

@media (max-width: 991px) {
  .en-sub-banner__nav li a {
    font-size: 1.4rem;
    line-height: 50px;
  }
}

.en-sub-banner__nav li a.selected:after {
  content: '';
  width: 100%;
  height: 5px;
  display: inline-block;
  background-color: #0078c7;
  position: absolute;
  top: auto;
  right: auto;
  bottom: 0;
  left: 0;
}

.en-sub-top {
  padding: 60px 0 0;
}

@media (max-width: 767px) {
  .en-sub-top {
    padding: 30px 0 0;
  }
}

.en-sub-top__tit {
  text-align: center;
}

.en-sub-top__tit h3 {
  font-size: 3rem;
  font-weight: 700;
  color: #111;
}

@media (max-width: 767px) {
  .en-sub-top__tit h3 {
    font-size: 2.4rem;
  }
}

.en-sub-top__tab {
  margin-top: 40px;
  text-align: center;
}

@media (max-width: 767px) {
  .en-sub-top__tab {
    margin-top: 20px;
  }
}

.en-sub-top__tab li {
  margin: 0 20px 10px;
  display: inline-block;
  vertical-align: middle;
}

@media (max-width: 767px) {
  .en-sub-top__tab li {
    width: 100%;
    margin: 0;
  }
}

.en-sub-top__tab li a {
  padding-bottom: 20px;
  font-size: 1.9rem;
  font-weight: 400;
  color: #999;
  font-family: 'NanumBarunGothic';
  position: relative;
}

@media (max-width: 767px) {
  .en-sub-top__tab li a {
    width: 100%;
    padding: 5px;
    font-size: 1.6rem;
    text-align: center;
  }
}

.en-sub-top__tab li a.selected {
  color: #111;
}

@media (max-width: 767px) {
  .en-sub-top__tab li a.selected {
    background-color: #111;
    color: #fff;
  }
}

.en-sub-top__tab li a.selected:after {
  content: '';
  width: 100%;
  height: 5px;
  display: inline-block;
  background-color: #111;
  position: absolute;
  top: auto;
  right: auto;
  bottom: 0;
  left: 0;
}

@media (max-width: 767px) {
  .en-sub-top__tab li a.selected:after {
    display: none;
  }
}

.en-sub-contents {
  padding: 80px 0;
}

@media (max-width: 991px) {
  .en-sub-contents {
    padding: 40px 0;
  }
}

@media (max-width: 767px) {
  .en-sub-contents {
    padding: 30px 0;
  }
}

.gnb-bg {
  width: 100%;
  height: 0px;
  display: block;
  transition-duration: .3s;
}

.en-about-top {
  margin-bottom: 50px;
}

@media (max-width: 767px) {
  .en-about-top {
    margin-bottom: 30px;
  }
}

.en-about-top__text {
  padding: 15px;
  background-image: url("/template/resources/images/cont/en-about-top-bg.png");
  background-position: center;
}

@media (max-width: 767px) {
  .en-about-top__text {
    margin-bottom: 10px;
  }
}

.en-about-top__text div {
  padding: 50px 450px 110px 50px;
  background-color: #fff;
  position: relative;
}

@media (max-width: 1320px) {
  .en-about-top__text div {
    padding: 50px 400px 70px 50px;
  }
}

@media (max-width: 991px) {
  .en-about-top__text div {
    padding: 50px 200px 70px 50px;
  }
}

@media (max-width: 767px) {
  .en-about-top__text div {
    padding: 30px 15px;
  }
}

.en-about-top__text h3 {
  margin-bottom: 25px;
  padding-bottom: 25px;
  border-bottom: 1px solid #e0e0e0;
  font-size: 3.6rem;
  font-weight: 400;
  color: #003561;
}

@media (max-width: 1320px) {
  .en-about-top__text h3 {
    font-size: 2.4rem;
    position: relative;
    z-index: 1;
  }
}

@media (max-width: 767px) {
  .en-about-top__text h3 {
    margin-bottom: 15px;
    padding-bottom: 15px;
    font-size: 2rem;
  }
}

.en-about-top__text h3 span {
  color: #089799;
}

.en-about-top__text p {
  font-size: 1.6rem;
  font-weight: 400;
  color: #003561;
  line-height: 2.9rem;
}

@media (max-width: 1320px) {
  .en-about-top__text p {
    position: relative;
    z-index: 1;
  }
  .en-about-top__text p br {
    display: none;
  }
}

@media (max-width: 767px) {
  .en-about-top__text p {
    font-size: 1.4rem;
    line-height: 2.7rem;
  }
}

.en-about-top__text img {
  position: absolute;
  top: 25px;
  right: 40px;
  bottom: auto;
  left: auto;
}

@media (max-width: 1320px) {
  .en-about-top__text img {
    width: 300px;
  }
}

@media (max-width: 767px) {
  .en-about-top__text img {
    width: 200px;
    right: 15px;
  }
}

.en-about-top__president {
  width: calc(100% - 120px);
  height: 145px;
  margin-left: 120px;
  margin-top: -70px;
  padding: 45px 500px 45px 50px;
  position: relative;
  background: linear-gradient(to right, #06aba7, #0175c0);
}

@media (max-width: 991px) {
  .en-about-top__president {
    height: 120px;
    padding: 32px 300px 32px 50px;
  }
}

@media (max-width: 767px) {
  .en-about-top__president {
    width: 100%;
    height: auto;
    margin-left: 0;
    margin-top: 0;
    padding: 15px;
    text-align: center;
  }
}

.en-about-top__president h4 {
  font-size: 1.6rem;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.5);
}

@media (max-width: 767px) {
  .en-about-top__president h4 {
    text-align: center;
  }
}

.en-about-top__president h3 {
  font-size: 2.6rem;
  font-weight: 400;
  color: #fff;
}

@media (max-width: 767px) {
  .en-about-top__president h3 {
    text-align: center;
  }
}

.en-about-top__president img {
  position: absolute;
  top: auto;
  right: 0;
  bottom: 0;
  left: auto;
}

@media (max-width: 1320px) {
  .en-about-top__president img {
    width: 300px;
  }
}

@media (max-width: 991px) {
  .en-about-top__president img {
    width: 200px;
  }
}

@media (max-width: 767px) {
  .en-about-top__president img {
    margin-top: 15px;
    position: initial;
    transform: none;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
  }
}

.en-about-bottom__text p {
  margin-bottom: 40px;
  font-size: 1.6rem;
  font-weight: 400;
  color: #777;
  line-height: 2.9rem;
}

@media (max-width: 991px) {
  .en-about-bottom__text p {
    margin-bottom: 20px;
  }
}

@media (max-width: 767px) {
  .en-about-bottom__text p {
    margin-bottom: 15px;
    font-size: 1.4rem;
    line-height: 2.7rem;
  }
}

.en-about-bottom__list {
  margin-top: 60px;
  padding-top: 45px;
  border-top: 5px solid #111;
}

@media (max-width: 767px) {
  .en-about-bottom__list {
    padding-top: 20px;
  }
}

.en-about-bottom__list > div {
  width: 50%;
  display: inline-block;
  vertical-align: top;
}

@media (max-width: 767px) {
  .en-about-bottom__list > div {
    width: 100%;
  }
}

.en-about-bottom__list > div:first-child {
  padding-right: 40px;
}

@media (max-width: 767px) {
  .en-about-bottom__list > div:first-child {
    padding-right: 0;
  }
}

.en-about-bottom__list > div:last-child {
  padding-left: 40px;
  border-left: 1px solid #e0e0e0;
}

@media (max-width: 767px) {
  .en-about-bottom__list > div:last-child {
    margin-top: 20px;
    padding-top: 20px;
    padding-left: 0;
    border-top: 1px solid #e0e0e0;
    border-left: none;
  }
}

.en-about-bottom__list h4 {
  font-size: 2.9rem;
  font-weight: 700;
  color: #111;
  line-height: 1;
}

.en-about-bottom__list ul {
  margin-top: 15px;
}

.en-about-bottom__list li {
  padding-left: 10px;
  font-size: 1.6rem;
  font-weight: 400;
  color: #555;
  line-height: 3.9rem;
  position: relative;
}

@media (max-width: 767px) {
  .en-about-bottom__list li {
    font-size: 1.4rem;
    line-height: 3.7rem;
  }
}

.en-about-bottom__list li:before {
  content: '';
  width: 2px;
  height: 2px;
  display: inline-block;
  background-color: #111;
  position: absolute;
  top: 20px;
  right: auto;
  bottom: auto;
  left: 0;
}

@media (max-width: 767px) {
  .en-about-bottom__list li:before {
    top: 15px;
  }
}

.en-board-top__text {
  width: calc(100% - 405px);
  display: inline-block;
  vertical-align: middle;
}

@media (max-width: 991px) {
  .en-board-top__text {
    width: calc(100% - 355px);
  }
}

@media (max-width: 767px) {
  .en-board-top__text {
    width: 100%;
    margin-bottom: 10px;
  }
}

.en-board-top__text p {
  font-size: 1.5rem;
  font-weight: 400;
  color: #555;
}

@media (max-width: 767px) {
  .en-board-top__text p {
    font-size: 1.4rem;
  }
}

.en-board-top__text p b {
  color: #111;
}

.en-board-top__text p span {
  width: 1px;
  height: 15px;
  margin: 0 10px;
  display: inline-block;
  background-color: #ddd;
  position: relative;
  top: 2px;
}

@media (max-width: 767px) {
  .en-board-top__text p span {
    height: 13px;
    margin: 0 5px;
  }
}

.en-board-top__search {
  width: 405px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
}

@media (max-width: 991px) {
  .en-board-top__search {
    width: 355px;
  }
}

@media (max-width: 767px) {
  .en-board-top__search {
    width: 100%;
  }
}

.en-board-top__search select {
  width: 120px;
  height: 48px;
  margin-right: 5px;
  padding: 0 15px;
  border: 1px solid #dedede;
  border-radius: 5px;
  font-size: 1.5rem;
  font-weight: 700;
  color: #555;
}

@media (max-width: 767px) {
  .en-board-top__search select {
    width: 100%;
    height: 35px;
    margin-bottom: 5px;
  }
}

.en-board-top__search input {
  width: 280px;
  height: 48px;
  padding: 0 15px;
  border: 1px solid #dedede;
  border-radius: 5px;
  font-size: 1.5rem;
  font-weight: 700;
  color: #555;
}

@media (max-width: 991px) {
  .en-board-top__search input {
    width: 230px;
  }
}

@media (max-width: 767px) {
  .en-board-top__search input {
    width: 100%;
    height: 35px;
    margin-bottom: 5px;
  }
}

.en-board-top__search input::placeholder {
  font-size: 1.5rem;
  font-weight: 700;
  color: #999;
}

.en-board-top__search button {
  width: 45px;
  height: 48px;
  border-radius: 5px;
  display: inline-block;
  background-color: #12217d;
  position: absolute;
  top: 0;
  right: 0;
  bottom: auto;
  left: auto;
}

@media (max-width: 767px) {
  .en-board-top__search button {
    width: 100%;
    height: 35px;
    position: initial;
    transform: none;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
    position: relative;
  }
}

.en-board-top__search button img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.en-board-table {
  margin-top: 30px;
}

@media (max-width: 767px) {
  .en-board-table {
    overflow-x: auto;
  }
}

.en-board-table .w-5 {
  width: 5%;
}

.en-board-table .w-10 {
  width: 10%;
}

.en-board-table .w-15 {
  width: 15%;
}

.en-board-table .w-20 {
  width: 20%;
}

.en-board-table .w-25 {
  width: 25%;
}

.en-board-table .w-30 {
  width: 30%;
}

.en-board-table .w-35 {
  width: 35%;
}

.en-board-table .w-40 {
  width: 40%;
}

.en-board-table .w-45 {
  width: 45%;
}

.en-board-table .w-50 {
  width: 50%;
}

.en-board-table .w-55 {
  width: 55%;
}

.en-board-table .w-60 {
  width: 60%;
}

.en-board-table .w-65 {
  width: 65%;
}

.en-board-table .w-70 {
  width: 70%;
}

.en-board-table .w-75 {
  width: 75%;
}

.en-board-table .w-80 {
  width: 80%;
}

.en-board-table .w-85 {
  width: 85%;
}

.en-board-table .w-90 {
  width: 90%;
}

.en-board-table .w-95 {
  width: 95%;
}

.en-board-table .w-100 {
  width: 100%;
}

.en-board-table .ta-l {
  text-align: left;
}

.en-board-table .ta-c {
  text-align: center;
}

.en-board-table .ta-r {
  text-align: right;
}

.en-board-table .va-t {
  vertical-align: top;
}

.en-board-table .va-m {
  vertical-align: middle;
}

.en-board-table .va-b {
  vertical-align: bottom;
}

@media (max-width: 1200px) {
  .en-board-table .w-md-5 {
    width: 5%;
  }
  .en-board-table .w-md-10 {
    width: 10%;
  }
  .en-board-table .w-md-15 {
    width: 15%;
  }
  .en-board-table .w-md-20 {
    width: 20%;
  }
  .en-board-table .w-md-25 {
    width: 25%;
  }
  .en-board-table .w-md-30 {
    width: 30%;
  }
  .en-board-table .w-md-35 {
    width: 35%;
  }
  .en-board-table .w-md-40 {
    width: 40%;
  }
  .en-board-table .w-md-45 {
    width: 45%;
  }
  .en-board-table .w-md-50 {
    width: 50%;
  }
  .en-board-table .w-md-55 {
    width: 55%;
  }
  .en-board-table .w-md-60 {
    width: 60%;
  }
  .en-board-table .w-md-65 {
    width: 65%;
  }
  .en-board-table .w-md-70 {
    width: 70%;
  }
  .en-board-table .w-md-75 {
    width: 75%;
  }
  .en-board-table .w-md-80 {
    width: 80%;
  }
  .en-board-table .w-md-85 {
    width: 85%;
  }
  .en-board-table .w-md-90 {
    width: 90%;
  }
  .en-board-table .w-md-95 {
    width: 95%;
  }
  .en-board-table .w-md-100 {
    width: 100%;
  }
  .en-board-table .w-md-n {
    display: none;
  }
}

@media (max-width: 991px) {
  .en-board-table .w-sm-5 {
    width: 5%;
  }
  .en-board-table .w-sm-10 {
    width: 10%;
  }
  .en-board-table .w-sm-15 {
    width: 15%;
  }
  .en-board-table .w-sm-20 {
    width: 20%;
  }
  .en-board-table .w-sm-25 {
    width: 25%;
  }
  .en-board-table .w-sm-30 {
    width: 30%;
  }
  .en-board-table .w-sm-35 {
    width: 35%;
  }
  .en-board-table .w-sm-40 {
    width: 40%;
  }
  .en-board-table .w-sm-45 {
    width: 45%;
  }
  .en-board-table .w-sm-50 {
    width: 50%;
  }
  .en-board-table .w-sm-55 {
    width: 55%;
  }
  .en-board-table .w-sm-60 {
    width: 60%;
  }
  .en-board-table .w-sm-65 {
    width: 65%;
  }
  .en-board-table .w-sm-70 {
    width: 70%;
  }
  .en-board-table .w-sm-75 {
    width: 75%;
  }
  .en-board-table .w-sm-80 {
    width: 80%;
  }
  .en-board-table .w-sm-85 {
    width: 85%;
  }
  .en-board-table .w-sm-90 {
    width: 90%;
  }
  .en-board-table .w-sm-95 {
    width: 95%;
  }
  .en-board-table .w-sm-100 {
    width: 100%;
  }
  .en-board-table .w-sm-n {
    display: none;
  }
}

@media (max-width: 767px) {
  .en-board-table .w-xs-5 {
    width: 5%;
  }
  .en-board-table .w-xs-10 {
    width: 10%;
  }
  .en-board-table .w-xs-15 {
    width: 15%;
  }
  .en-board-table .w-xs-20 {
    width: 20%;
  }
  .en-board-table .w-xs-25 {
    width: 25%;
  }
  .en-board-table .w-xs-30 {
    width: 30%;
  }
  .en-board-table .w-xs-35 {
    width: 35%;
  }
  .en-board-table .w-xs-40 {
    width: 40%;
  }
  .en-board-table .w-xs-45 {
    width: 45%;
  }
  .en-board-table .w-xs-50 {
    width: 50%;
  }
  .en-board-table .w-xs-55 {
    width: 55%;
  }
  .en-board-table .w-xs-60 {
    width: 60%;
  }
  .en-board-table .w-xs-65 {
    width: 65%;
  }
  .en-board-table .w-xs-70 {
    width: 70%;
  }
  .en-board-table .w-xs-75 {
    width: 75%;
  }
  .en-board-table .w-xs-80 {
    width: 80%;
  }
  .en-board-table .w-xs-85 {
    width: 85%;
  }
  .en-board-table .w-xs-90 {
    width: 90%;
  }
  .en-board-table .w-xs-95 {
    width: 95%;
  }
  .en-board-table .w-xs-100 {
    width: 100%;
  }
  .en-board-table .w-xs-n {
    display: none;
  }
}

.en-board-table table {
  width: 100%;
  border-top: 2px solid #142b48;
  table-layout: fixed;
}

@media (max-width: 767px) {
  .en-board-table table {
    width: 700px;
  }
}

.en-board-table thead tr {
  border-bottom: 1px solid #aaa;
}

.en-board-table tr {
  border-bottom: 1px solid #ddd;
}

.en-board-table th {
  font-size: 1.7rem;
  font-weight: 700;
  color: #142b48;
  height: 60px;
}

.en-board-table p, .en-board-table a {
  font-size: 1.5rem;
  font-weight: 400;
  color: #111;
  line-height: 54px;
}

.en-board-table a {
  width: 100%;
  padding-left: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media (max-width: 991px) {
  .en-board-table a {
    padding-left: 15px;
  }
}

.en-board-gallery {
  margin-top: 30px;
  padding-top: 40px;
  border-top: 2px solid #142b48;
  border-bottom: 1px solid #ddd;
}

@media (max-width: 767px) {
  .en-board-gallery {
    padding-top: 30px;
  }
}

.en-board-gallery ul {
  padding-bottom: 20px;
}

@media (max-width: 767px) {
  .en-board-gallery ul {
    padding-bottom: 0;
  }
}

.en-board-gallery li {
  width: calc((100% - 90px) / 4);
  margin-right: 30px;
  margin-bottom: 30px;
  border: 1px solid #ddd;
  display: inline-block;
  vertical-align: top;
}

@media (max-width: 1320px) {
  .en-board-gallery li {
    width: calc((100% - 60px) / 3);
  }
}

@media (max-width: 991px) {
  .en-board-gallery li {
    width: calc((100% - 30px) / 2);
  }
}

@media (max-width: 767px) {
  .en-board-gallery li {
    width: 100%;
    margin-right: 0;
  }
}

.en-board-gallery li:nth-child(4n) {
  margin-right: 0;
}

@media (max-width: 1320px) {
  .en-board-gallery li:nth-child(4n) {
    margin-right: 30px;
  }
}

@media (max-width: 1320px) {
  .en-board-gallery li:nth-child(3n) {
    margin-right: 0;
  }
}

@media (max-width: 991px) {
  .en-board-gallery li:nth-child(3n) {
    margin-right: 30px;
  }
}

@media (max-width: 767px) {
  .en-board-gallery li:nth-child(3n) {
    margin-right: 0;
  }
}

@media (max-width: 991px) {
  .en-board-gallery li:nth-child(2n) {
    margin-right: 0;
  }
}

.en-board-gallery__img img {
  width: 100%;
}

.en-board-gallery__text {
  padding: 30px 25px;
  border-top: 1px solid #ddd;
}

@media (max-width: 767px) {
  .en-board-gallery__text {
    padding: 15px;
  }
}

.en-board-gallery__text h3 {
  margin-bottom: 25px;
  font-size: 1.5rem;
  font-weight: 400;
  color: #111;
  height: 7.5rem;
  line-height: 2.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

@media (max-width: 767px) {
  .en-board-gallery__text h3 {
    margin-bottom: 15px;
  }
}

.en-board-gallery__text p {
  font-size: 1.5rem;
  font-weight: 400;
  color: #999;
}

.en-paging {
  margin-top: 30px;
  text-align: center;
}

.en-paging ul {
  margin: 0 10px;
  display: inline-block;
  vertical-align: middle;
}

@media (max-width: 767px) {
  .en-paging ul:not(.en-paging__num) {
    display: none;
  }
}

.en-paging ul:not(.en-paging__num) a {
  border: 1px solid #dfdfdf;
}

.en-paging li {
  margin: 0 3px;
  display: inline-block;
  vertical-align: middle;
}

.en-paging a {
  width: 40px;
  height: 40px;
  border-radius: 40px;
  font-size: 1.3rem;
  font-weight: 700;
  color: #555;
  line-height: 40px;
  position: relative;
}

.en-paging a.selected {
  background-color: #333;
  color: #fff;
}

.en-paging a img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.en-intro-top {
  margin-bottom: 80px;
}

@media (max-width: 991px) {
  .en-intro-top {
    margin-bottom: 60px;
  }
}

@media (max-width: 767px) {
  .en-intro-top {
    margin-bottom: 30px;
  }
}

.en-intro-top__text {
  width: calc(50% - 50px);
  padding-right: 30px;
  display: inline-block;
  vertical-align: top;
}

@media (max-width: 1550px) {
  .en-intro-top__text {
    width: 50%;
  }
}

@media (max-width: 991px) {
  .en-intro-top__text {
    width: 100%;
    margin-bottom: 30px;
  }
}

.en-intro-top__text h3 {
  margin-bottom: 30px;
  font-size: 3.6rem;
  font-weight: 400;
  color: #003561;
  line-height: 4.6rem;
}

@media (max-width: 1320px) {
  .en-intro-top__text h3 {
    font-size: 3rem;
    line-height: 4rem;
  }
}

@media (max-width: 767px) {
  .en-intro-top__text h3 {
    font-size: 2rem;
    line-height: 3rem;
  }
  .en-intro-top__text h3 br {
    display: none;
  }
}

.en-intro-top__text h3 span {
  color: #089799;
}

.en-intro-top__text p {
  margin-bottom: 30px;
  font-size: 1.6rem;
  font-weight: 400;
  color: #555;
  line-height: 2.9rem;
}

@media (max-width: 767px) {
  .en-intro-top__text p {
    font-size: 1.4rem;
    line-height: 2.7rem;
  }
}

.en-intro-top__text p:last-child {
  margin-bottom: 0;
}

.en-intro-top__img {
  width: calc(50% + 50px);
  display: inline-block;
  vertical-align: top;
}

@media (max-width: 1550px) {
  .en-intro-top__img {
    width: 50%;
  }
}

@media (max-width: 991px) {
  .en-intro-top__img {
    width: 100%;
  }
  .en-intro-top__img img {
    width: 100%;
  }
}

.en-intro-bottom__tit {
  margin-bottom: 20px;
}

.en-intro-bottom__tit h3 {
  padding-top: 20px;
  font-size: 2.6rem;
  font-weight: 700;
  color: #111;
  position: relative;
}

@media (max-width: 767px) {
  .en-intro-bottom__tit h3 {
    padding-top: 10px;
    font-size: 2rem;
  }
}

.en-intro-bottom__tit h3:before {
  content: '';
  width: 7px;
  height: 7px;
  border-radius: 7px;
  display: inline-block;
  background-color: #8b8e91;
  position: absolute;
  top: 0;
  right: auto;
  bottom: auto;
  left: 0;
}

.en-intro-bottom__tit h3:after {
  content: '';
  width: 7px;
  height: 7px;
  border-radius: 7px;
  display: inline-block;
  background-color: #0078c7;
  position: absolute;
  top: 0;
  right: auto;
  bottom: auto;
  left: 10px;
}

.en-intro-bottom__map .root_daum_roughmap {
  width: 100%;
  height: 480px;
}

@media (max-width: 991px) {
  .en-intro-bottom__map .root_daum_roughmap {
    height: 300px;
  }
}

@media (max-width: 767px) {
  .en-intro-bottom__map .root_daum_roughmap {
    height: 200px;
  }
}

.en-intro-bottom__map .root_daum_roughmap .wrap_map {
  height: 100%;
}

.en-intro-bottom__map-info {
  padding: 40px;
  background-color: #33373b;
}

@media (max-width: 991px) {
  .en-intro-bottom__map-info {
    padding: 20px;
  }
}

.en-intro-bottom__map-info ul {
  width: calc(100% - 115px);
  display: inline-block;
  vertical-align: middle;
}

@media (max-width: 767px) {
  .en-intro-bottom__map-info ul {
    width: 100%;
  }
}

.en-intro-bottom__map-info li {
  padding-left: 17px;
  font-size: 1.6rem;
  font-weight: 400;
  color: #fff;
  position: relative;
}

@media (max-width: 767px) {
  .en-intro-bottom__map-info li {
    margin-bottom: 15px;
    padding-left: 12px;
    font-size: 1.4rem;
  }
}

.en-intro-bottom__map-info li:before {
  content: '';
  width: 3px;
  height: 3px;
  display: inline-block;
  background-color: #fff;
  position: absolute;
  top: 15px;
  right: auto;
  bottom: auto;
  left: 0;
}

@media (max-width: 767px) {
  .en-intro-bottom__map-info li:before {
    top: 10px;
  }
}

.en-intro-bottom__map-info li span {
  display: inline-block;
  font-weight: 700;
  vertical-align: middle;
}

@media (max-width: 767px) {
  .en-intro-bottom__map-info li span {
    display: block;
  }
}

.en-intro-bottom__map-info li span:after {
  content: '';
  width: 1px;
  height: 12px;
  margin: 0 15px;
  display: inline-block;
  background-color: #fff;
}

@media (max-width: 767px) {
  .en-intro-bottom__map-info li span:after {
    display: none;
  }
}

.en-intro-bottom__map-info a {
  width: 115px;
  height: 76px;
  border: 2px solid #fff;
  display: inline-block;
  background-color: #1c92eb;
  font-size: 1.6rem;
  font-weight: 700;
  color: #fff;
  text-align: center;
  line-height: 72px;
  vertical-align: middle;
}

@media (max-width: 767px) {
  .en-intro-bottom__map-info a {
    width: 100%;
  }
}

.en-intro-bottom__map-info a img {
  margin-right: 5px;
  vertical-align: middle;
  position: relative;
  bottom: 2px;
}

.en-director__list > ul > li {
  width: calc((100% - 80px) / 3);
  margin-right: 40px;
  margin-bottom: 60px;
  border: 1px solid #dfdfdf;
  border-radius: 5px;
  display: inline-block;
  vertical-align: top;
}

@media (max-width: 1320px) {
  .en-director__list > ul > li {
    width: calc((100% - 40px) / 3);
    margin-right: 20px;
  }
}

@media (max-width: 991px) {
  .en-director__list > ul > li {
    width: calc((100% - 20px) / 2);
  }
}

@media (max-width: 767px) {
  .en-director__list > ul > li {
    width: 100%;
    margin-bottom: 30px;
  }
}

.en-director__list > ul > li:nth-child(3n) {
  margin-right: 0;
}

@media (max-width: 991px) {
  .en-director__list > ul > li:nth-child(3n) {
    margin-right: 20px;
  }
}

@media (max-width: 767px) {
  .en-director__list > ul > li:nth-child(3n) {
    margin-right: 0;
  }
}

@media (max-width: 991px) {
  .en-director__list > ul > li:nth-child(2n) {
    margin-right: 0;
  }
}

.en-director__tit {
  background-color: #e4f4ff;
  padding: 25px 15px;
  text-align: center;
  position: relative;
}

@media (max-width: 767px) {
  .en-director__tit {
    padding: 15px;
  }
}

.en-director__tit img {
  position: absolute;
  top: -1px;
  right: auto;
  bottom: auto;
  left: 20px;
}

@media (max-width: 1320px) {
  .en-director__tit img {
    width: 44px;
    left: 10px;
  }
}

@media (max-width: 767px) {
  .en-director__tit img {
    width: 24px;
  }
}

.en-director__tit h3 {
  font-size: 2rem;
  font-weight: 400;
  color: #111;
}

@media (max-width: 1550px) {
  .en-director__tit h3 {
    font-size: 1.4rem;
  }
}

.en-director__tit p {
  margin-top: 5px;
  font-size: 1.6rem;
  font-weight: 400;
  color: #111;
}

@media (max-width: 1550px) {
  .en-director__tit p {
    font-size: 1.4rem;
  }
}

.en-director__info {
  padding: 30px 40px;
  text-align: center;
}

@media (max-width: 1320px) {
  .en-director__info {
    padding: 30px;
  }
}

@media (max-width: 767px) {
  .en-director__info {
    padding: 30px 15px;
  }
}

.en-director__img {
  margin-bottom: 40px;
  padding: 17px;
  border: 3px solid #0078c7;
  display: inline-block;
  box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.2);
}

@media (max-width: 1320px) {
  .en-director__img {
    margin-bottom: 30px;
  }
}

.en-director__text {
  text-align: left;
}

@media (max-width: 767px) {
  .en-director__text {
    height: auto !important;
  }
}

.en-director__text li {
  margin-bottom: 5px;
  padding-left: 11px;
  font-size: 1.6rem;
  font-weight: 400;
  color: #555;
  position: relative;
}

@media (max-width: 767px) {
  .en-director__text li {
    font-size: 1.4rem;
  }
}

.en-director__text li:before {
  content: '';
  width: 2px;
  height: 2px;
  display: inline-block;
  background-color: #111;
  position: absolute;
  top: 12px;
  right: auto;
  bottom: auto;
  left: 0;
}

.en-director__text li:last-child {
  margin-bottom: 0;
}

.en-history__l {
  width: 375px;
  display: inline-block;
  vertical-align: top;
}

@media (max-width: 991px) {
  .en-history__l {
    width: 250px;
  }
}

@media (max-width: 767px) {
  .en-history__l {
    width: 100%;
  }
}

.en-history__r {
  width: calc(100% - 375px);
  display: inline-block;
  vertical-align: top;
}

@media (max-width: 991px) {
  .en-history__r {
    width: calc(100% - 250px);
  }
}

@media (max-width: 767px) {
  .en-history__r {
    width: 100%;
  }
}

.en-history__tit {
  margin-bottom: 50px;
  font-size: 3.6rem;
  font-weight: 700;
  color: #111;
  line-height: 5rem;
}

@media (max-width: 991px) {
  .en-history__tit {
    font-size: 3rem;
    line-height: 4.4rem;
  }
}

@media (max-width: 767px) {
  .en-history__tit {
    font-size: 2.4rem;
    line-height: 3.8rem;
  }
  .en-history__tit br {
    display: none;
  }
}

.en-history__tab {
  position: relative;
}

.en-history__tab:after {
  content: '';
  width: 2px;
  height: calc(100% - 15px);
  display: inline-block;
  background-color: #ddd;
  position: absolute;
  top: 7px;
  right: auto;
  bottom: auto;
  left: 7px;
}

@media (max-width: 767px) {
  .en-history__tab:after {
    display: none;
  }
}

.en-history__tab li {
  margin-bottom: 30px;
}

@media (max-width: 767px) {
  .en-history__tab li {
    width: 50%;
    margin-bottom: 15px;
    display: inline-block;
    vertical-align: top;
  }
}

.en-history__tab a {
  padding-left: 25px;
  font-size: 2.6rem;
  font-weight: 700;
  color: #aaa;
  line-height: 1;
  position: relative;
  z-index: 1;
}

@media (max-width: 767px) {
  .en-history__tab a {
    padding-left: 20px;
    font-size: 2.2rem;
  }
}

.en-history__tab a:before {
  content: '';
  width: 16px;
  height: 16px;
  border: 4px solid #aaa;
  border-radius: 16px;
  display: inline-block;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 0;
  right: auto;
  transform: translateY(-50%);
  box-sizing: border-box;
}

@media (max-width: 767px) {
  .en-history__tab a:before {
    width: 12px;
    height: 12px;
    border: 3px solid #aaa;
  }
}

.en-history__tab a.selected {
  color: #0078c7;
}

.en-history__tab a.selected:before {
  border: 4px solid #0078c7;
}

@media (max-width: 767px) {
  .en-history__tab a.selected:before {
    border: 3px solid #0078c7;
  }
}

.en-history__list {
  border-top: 1px solid #ddd;
  padding: 45px 0;
}

@media (max-width: 767px) {
  .en-history__list {
    padding: 20px 0;
  }
}

.en-history__year {
  width: 120px;
  display: inline-block;
  font-size: 2.6rem;
  font-weight: 700;
  color: #111;
  vertical-align: top;
}

@media (max-width: 991px) {
  .en-history__year {
    width: 80px;
    font-size: 2.2rem;
  }
}

@media (max-width: 767px) {
  .en-history__year {
    width: 100%;
  }
}

.en-history__info {
  width: calc(100% - 120px);
  padding-top: 5px;
  display: inline-block;
  vertical-align: top;
}

@media (max-width: 991px) {
  .en-history__info {
    width: calc(100% - 80px);
    padding-top: 2px;
  }
}

@media (max-width: 767px) {
  .en-history__info {
    width: 100%;
    padding-top: 0;
  }
}

.en-history__info li {
  margin-bottom: 15px;
  padding-left: 100px;
  font-size: 1.6rem;
  font-weight: 400;
  color: #666;
  line-height: 3.1rem;
  position: relative;
}

@media (max-width: 991px) {
  .en-history__info li {
    padding-left: 60px;
    line-height: 2.8rem;
  }
}

@media (max-width: 767px) {
  .en-history__info li {
    padding-left: 0;
    font-size: 1.4rem;
    line-height: 2.4rem;
  }
}

.en-history__info li:last-child {
  margin-bottom: 0;
}

.en-history__info li span {
  width: 100px;
  font-weight: 700;
  color: #0078c7;
  position: absolute;
  top: 0;
  right: auto;
  bottom: auto;
  left: 0;
}

@media (max-width: 991px) {
  .en-history__info li span {
    width: 60px;
  }
}

@media (max-width: 767px) {
  .en-history__info li span {
    width: 100%;
    display: block;
    position: initial;
    transform: none;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
  }
}

.en-text-box__l-text {
  margin-bottom: 50px;
  font-size: 2.6rem;
  font-weight: 400;
  color: #111;
  line-height: 3.6rem;
}

@media (max-width: 767px) {
  .en-text-box__l-text {
    font-size: 2.2rem;
    font-weight: 3.2rem;
  }
}

.en-text-box__text {
  font-size: 1.6rem;
  font-weight: 400;
  color: #555;
  line-height: 2.9rem;
}

.en-text-box__text.mb-15 {
  margin-bottom: 15px;
}

.en-text-box__text.mb-30 {
  margin-bottom: 30px;
}

@media (max-width: 767px) {
  .en-text-box__text {
    font-size: 1.4rem;
    line-height: 2.7rem;
  }
}

.en-text-box__img {
  margin-top: 50px;
}

.en-text-box__img li {
  width: calc((100% - 30px) / 3);
  margin-right: 15px;
  display: inline-block;
  vertical-align: top;
}

@media (max-width: 767px) {
  .en-text-box__img li {
    width: 100%;
    margin-right: 0;
    margin-bottom: 15px;
  }
}

.en-text-box__img li:nth-child(3n) {
  margin-right: 0;
  margin-bottom: 0;
}

.en-text-box__img li img {
  width: 100%;
}

.en-text-box__tit {
  margin-bottom: 15px;
  padding-top: 15px;
  font-size: 2.6rem;
  font-weight: 700;
  color: #111;
  position: relative;
}

@media (max-width: 767px) {
  .en-text-box__tit {
    font-size: 2.2rem;
  }
}

.en-text-box__tit.mt-50 {
  margin-top: 50px;
}

@media (max-width: 767px) {
  .en-text-box__tit.mt-50 {
    margin-top: 25px;
  }
}

.en-text-box__tit span {
  margin-left: 15px;
  display: inline-block;
  font-size: 1.6rem;
  font-weight: 400;
  color: #555;
  vertical-align: middle;
}

@media (max-width: 767px) {
  .en-text-box__tit span {
    margin-left: 0;
    display: block;
    font-size: 1.4rem;
  }
}

.en-text-box__tit:before {
  content: '';
  width: 7px;
  height: 7px;
  border-radius: 7px;
  display: inline-block;
  background-color: #8b8e91;
  position: absolute;
  top: 0;
  right: auto;
  bottom: auto;
  left: 0;
  box-sizing: border-box;
}

.en-text-box__tit:after {
  content: '';
  width: 7px;
  height: 7px;
  border-radius: 7px;
  display: inline-block;
  background-color: #0078c7;
  position: absolute;
  top: 0;
  right: auto;
  bottom: auto;
  left: 10px;
  box-sizing: border-box;
}

.en-text-box__sub-tit {
  margin-bottom: 15px;
  padding-left: 25px;
  font-size: 1.6rem;
  font-weight: 700;
  color: #111;
  position: relative;
}

.en-text-box__sub-tit:before {
  content: '';
  width: 16px;
  height: 16px;
  border: 4px solid #0078c7;
  border-radius: 16px;
  display: inline-block;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 0;
  right: auto;
  transform: translateY(-50%);
  top: 60%;
  box-sizing: border-box;
}

@media (max-width: 767px) {
  .en-text-box__sub-tit:before {
    width: 12px;
    height: 12px;
    border: 3px solid #0078c7;
  }
}

.en-text-box__sub-tit + .en-text-box__text {
  margin-bottom: 30px;
}

.en-text-box__list {
  margin-bottom: 30px;
}

.en-text-box__list.sub {
  padding-left: 25px;
}

@media (max-width: 767px) {
  .en-text-box__list.sub {
    padding-left: 15px;
  }
}

.en-text-box__list.mb-15 {
  margin-bottom: 15px;
}

.en-text-box__list li {
  margin-bottom: 10px;
  padding-left: 10px;
  font-size: 1.6rem;
  font-weight: 400;
  color: #555;
  position: relative;
}

@media (max-width: 767px) {
  .en-text-box__list li {
    font-size: 1.4rem;
  }
}

.en-text-box__list li:before {
  content: '';
  width: 2px;
  height: 2px;
  display: inline-block;
  background-color: #111;
  position: absolute;
  top: 12px;
  right: auto;
  bottom: auto;
  left: 0;
}

.en-text-box__list li:last-child {
  margin-bottom: 0;
}

.en-text-box__table {
  border-top: 2px solid #4d4d4d;
  overflow-x: auto;
}

.en-text-box__table.mb-30 {
  margin-bottom: 30px;
}

.en-text-box__table table {
  width: 100%;
  min-width: 800px;
  table-layout: fixed;
}

.en-text-box__table tr {
  border-bottom: 1px solid #c1c1c1;
}

.en-text-box__table th {
  height: 50px;
  border-right: 1px solid #c1c1c1;
  background-color: #fafafa;
  font-size: 1.4rem;
  font-weight: 700;
  color: #111;
}

.en-text-box__table th:last-child {
  border-right: none;
}

.en-text-box__table td {
  height: 50px;
  padding: 15px;
  border-right: 1px solid #c1c1c1;
  font-size: 1.4rem;
  font-weight: 400;
  color: #555;
  text-align: center;
  vertical-align: middle;
}

.en-text-box__table td:last-child {
  border-right: none;
}

.en-text-box__c-t {
  margin-bottom: 10px;
  padding-left: 35px;
  font-size: 1.4rem;
  font-weight: 400;
  color: #0078c7;
}

@media (max-width: 767px) {
  .en-text-box__c-t {
    padding-left: 25px;
  }
}

.en-text-box__c-t.pl-0 {
  padding-left: 0;
}

.en-text-box__btn {
  margin-top: 40px;
  padding-top: 40px;
  border-top: 1px solid #c1c1c1;
  text-align: center;
}

.en-text-box__btn.bd-n {
  border-top: none;
}

.en-text-box__btn.pt-0 {
  padding-top: 0;
}

.en-text-box__btn a {
  max-width: 100%;
  padding: 15px 100px 20px;
  border-radius: 100px;
  background-color: #e3ebf4;
  font-size: 1.6rem;
  font-weight: 700;
  color: #111;
}

@media (max-width: 767px) {
  .en-text-box__btn a {
    width: 100%;
    padding: 15px 10px 20px;
    font-size: 1.4rem;
  }
}

.en-text-box__btn a span {
  font-weight: 400;
  color: #555;
}

.en-text-box__route-list {
  margin-bottom: 25px;
  padding-left: 170px;
  position: relative;
}

@media (max-width: 991px) {
  .en-text-box__route-list {
    padding-left: 15px;
    padding-top: 45px;
  }
}

.en-text-box__route-list:last-child {
  margin-bottom: 0;
}

.en-text-box__route-list h4 {
  width: 45px;
  height: 45px;
  display: inline-block;
  background-color: #000;
  font-size: 2.2rem;
  font-weight: 700;
  color: #fff;
  text-align: center;
  line-height: 45px;
  position: absolute;
  top: 50%;
  left: 0;
  right: auto;
  transform: translateY(-50%);
  z-index: 2;
}

@media (max-width: 991px) {
  .en-text-box__route-list h4 {
    width: 35px;
    height: 35px;
    line-height: 35px;
    font-size: 2rem;
    top: 4px;
    transform: none;
  }
}

.en-text-box__route-list h3 {
  width: 340px;
  height: 63px;
  display: inline-block;
  background-color: #1976d2;
  font-size: 1.4rem;
  font-weight: 700;
  color: #fff;
  text-align: center;
  line-height: 63px;
  position: absolute;
  top: 50%;
  left: 15px;
  right: auto;
  transform: translateY(-50%);
  z-index: 1;
}

@media (max-width: 991px) {
  .en-text-box__route-list h3 {
    width: calc(100% - 15px);
    height: 45px;
    line-height: 45px;
    transform: none;
    top: 0;
  }
}

.en-text-box__route-list h3:after {
  content: '';
  border-left: 25px solid #1976d2;
  border-top: 32px solid transparent;
  border-bottom: 32px solid transparent;
  border-right: none;
  display: inline-block;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: auto;
  right: -25px;
  transform: translateY(-50%);
}

@media (max-width: 991px) {
  .en-text-box__route-list h3:after {
    display: none;
  }
}

.en-text-box__route-list div {
  width: 100%;
  height: 83px;
  display: inline-block;
  border-radius: 10px;
  background-color: #f2f5f9;
  position: relative;
}

@media (max-width: 1320px) {
  .en-text-box__route-list div {
    height: 110px;
  }
}

@media (max-width: 991px) {
  .en-text-box__route-list div {
    height: auto;
    padding: 15px;
    border-radius: 0;
  }
}

.en-text-box__route-list div p {
  padding-right: 15px;
  font-size: 1.6rem;
  font-weight: 400;
  color: #111;
  position: absolute;
  top: 50%;
  left: 245px;
  right: auto;
  transform: translateY(-50%);
}

@media (max-width: 991px) {
  .en-text-box__route-list div p {
    position: initial;
    transform: none;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
  }
}

.en-view-top {
  border-top: 2px solid #555;
}

.en-view-top__tit {
  padding: 31px 40px 29px;
  font-size: 1.9rem;
  font-weight: 700;
  color: #111;
  text-align: center;
  line-height: 2.9rem;
}

@media (max-width: 767px) {
  .en-view-top__tit {
    padding: 15px;
    font-size: 1.8rem;
  }
}

.en-view-top__info {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  background-color: #f8f9fa;
  text-align: center;
}

.en-view-top__info p {
  font-size: 1.6rem;
  font-weight: 500;
  color: #666;
  line-height: 60px;
}

@media (max-width: 767px) {
  .en-view-top__info p {
    font-size: 1.4rem;
  }
}

.en-view-top__info p span {
  display: inline-block;
  color: #999;
}

.en-view-top__info p span:after {
  content: '';
  width: 1px;
  height: 10px;
  margin: 0 10px;
  display: inline-block;
  background-color: #ddd;
}

.en-view-contents {
  padding: 100px 40px;
  border-bottom: 1px solid #ddd;
  font-size: 1.5rem;
  font-weight: 400;
  color: #333;
  line-height: 2.9rem;
}

@media (max-width: 767px) {
  .en-view-contents {
    padding: 30px 15px;
    font-size: 1.4rem;
    line-height: 2.4rem;
  }
}

.en-view-bottom__file {
  min-height: 71px;
  padding: 20px 40px 20px 140px;
  border-bottom: 1px solid #ddd;
  position: relative;
}

@media (max-width: 767px) {
  .en-view-bottom__file {
    min-height: auto;
    padding: 15px;
  }
}

.en-view-bottom__file h4 {
  width: 100px;
  display: inline-block;
  font-size: 1.6rem;
  font-weight: 700;
  color: #333;
  vertical-align: middle;
  position: absolute;
  top: 24px;
  right: auto;
  bottom: auto;
  left: 40px;
}

@media (max-width: 767px) {
  .en-view-bottom__file h4 {
    width: 100%;
    text-align: center;
    position: initial;
    transform: none;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
  }
}

.en-view-bottom__file li {
  margin-bottom: 10px;
}

.en-view-bottom__file li:last-child {
  margin-bottom: 0;
}

.en-view-bottom__file div {
  width: calc(100% - 130px);
  padding-right: 15px;
  display: inline-block;
  vertical-align: middle;
}

@media (max-width: 767px) {
  .en-view-bottom__file div {
    width: 100%;
    padding: 10px 0;
  }
}

.en-view-bottom__file p {
  max-width: 100%;
  padding-right: 170px;
  display: inline-block;
  font-size: 1.5rem;
  font-weight: 400;
  color: #333;
  vertical-align: middle;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
}

@media (max-width: 767px) {
  .en-view-bottom__file p {
    padding-right: 0;
    padding-bottom: 30px;
  }
}

.en-view-bottom__file p span {
  display: inline-block;
  font-size: 1.3rem;
  font-weight: 500;
  color: #999;
  position: absolute;
  top: 50%;
  left: auto;
  right: 0;
  transform: translateY(-50%);
}

@media (max-width: 767px) {
  .en-view-bottom__file p span {
    font-size: 1.2rem;
    position: absolute;
    top: auto;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    right: auto;
  }
}

.en-view-bottom__file a {
  width: 130px;
  height: 30px;
  border: 1px solid #999;
  font-size: 1.4rem;
  font-weight: 500;
  color: #111;
  text-align: center;
  vertical-align: middle;
  line-height: 28px;
}

@media (max-width: 767px) {
  .en-view-bottom__file a {
    width: 100%;
  }
}

.en-view-bottom__file a img {
  margin-left: 8px;
  vertical-align: middle;
}

.en-view-bottom__btn {
  margin-top: 50px;
  text-align: center;
}

@media (max-width: 767px) {
  .en-view-bottom__btn {
    margin-top: 30px;
  }
}

.en-view-bottom__btn a {
  width: 120px;
  height: 50px;
  background-color: #333;
  font-size: 1.4rem;
  font-weight: 700;
  color: #fff;
  line-height: 50px;
}

@media (max-width: 767px) {
  .en-view-bottom__btn a {
    width: 100%;
  }
}

.en-poomsae__list-tit {
  margin-bottom: 10px;
  padding-left: 25px;
  font-size: 1.6rem;
  font-weight: 700;
  color: #111;
  position: relative;
}

.en-poomsae__list-tit:before {
  content: '';
  width: 16px;
  height: 16px;
  border: 4px solid #0078c7;
  border-radius: 16px;
  display: inline-block;
  box-sizing: border-box;
  position: absolute;
  top: 6px;
  right: auto;
  bottom: auto;
  left: 0;
}

.en-poomsae__list li {
  margin-bottom: 40px;
  padding: 40px 30px;
  border: 1px solid #c1c1c1;
}

@media (max-width: 991px) {
  .en-poomsae__list li {
    padding: 30px 15px;
  }
}

@media (max-width: 767px) {
  .en-poomsae__list li {
    padding: 15px;
  }
}

.en-poomsae__list p {
  padding-left: 11px;
  margin-left: 5px;
  font-size: 1.6rem;
  font-weight: 400;
  color: #555;
  line-height: 2.6rem;
  position: relative;
}

@media (max-width: 767px) {
  .en-poomsae__list p {
    font-size: 1.4rem;
    line-height: 2.4rem;
  }
}

.en-poomsae__list p:before {
  content: '';
  width: 2px;
  height: 2px;
  display: inline-block;
  background-color: #111;
  position: absolute;
  top: 14px;
  right: auto;
  bottom: auto;
  left: 0;
}

.en-poomsae__text {
  width: 60%;
  padding-right: 30px;
  display: inline-block;
  vertical-align: middle;
}

@media (max-width: 767px) {
  .en-poomsae__text {
    width: 100%;
    margin-bottom: 15px;
  }
}

.en-poomsae__img {
  width: 40%;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  position: relative;
}

@media (max-width: 991px) {
  .en-poomsae__img {
    text-align: left;
  }
}

@media (max-width: 767px) {
  .en-poomsae__img {
    width: 100%;
  }
}

.en-poomsae__qr {
  position: absolute;
  top: 0;
  right: 0;
  bottom: auto;
  left: auto;
}

@media (max-width: 767px) {
  .en-poomsae__qr {
    position: absolute;
    top: auto;
    right: 0;
    bottom: 0;
    left: auto;
  }
}

.en-organization-list__area0 {
  margin-bottom: 30px;
  position: relative;
}

.en-organization-list__area0:after {
  content: '';
  width: 1px;
  height: 30px;
  display: inline-block;
  background-color: #ddd;
  position: absolute;
  top: 100%;
  bottom: auto;
  left: 50%;
  transform: translateX(-50%);
}

.en-organization-list__area0 ul {
  text-align: center;
  position: relative;
}

.en-organization-list__area0 li {
  display: inline-block;
}

.en-organization-list__area0 li:nth-child(1) {
  width: 179px;
  height: 179px;
  border-radius: 179px;
  background-image: url("/template/resources/images/cont/en-organization-bg.png");
  background-size: cover;
  position: relative;
  z-index: 1;
}

@media (max-width: 767px) {
  .en-organization-list__area0 li:nth-child(1) {
    width: 100px;
    height: 100px;
    display: inline-block;
    vertical-align: middle;
  }
}

.en-organization-list__area0 li:nth-child(2) {
  width: 175px;
  height: 60px;
  background-color: #111987;
  position: absolute;
  top: 50%;
  left: auto;
  right: calc((50% - 85px) - 300px);
  transform: translateY(-50%);
}

@media (max-width: 991px) {
  .en-organization-list__area0 li:nth-child(2) {
    right: calc((50% - 85px) - 250px);
  }
}

@media (max-width: 767px) {
  .en-organization-list__area0 li:nth-child(2) {
    width: 60px;
    right: calc((50% - 30px) - 100px);
  }
}

.en-organization-list__area0 li:nth-child(2):before {
  content: '';
  width: 150px;
  height: 1px;
  display: inline-block;
  background-color: #ddd;
  position: absolute;
  top: 50%;
  left: auto;
  right: 100%;
  transform: translateY(-50%);
}

@media (max-width: 767px) {
  .en-organization-list__area0 li:nth-child(2):before {
    width: 50px;
  }
}

.en-organization-list__area0 li:nth-child(2) p {
  font-size: 2rem;
  font-weight: 700;
  color: #fff;
}

@media (max-width: 767px) {
  .en-organization-list__area0 li:nth-child(2) p {
    font-size: 1.2rem;
  }
}

.en-organization-list__area0 li:nth-child(3) {
  width: 175px;
  height: 60px;
  background-color: #111987;
  position: absolute;
  top: 130px;
  right: calc((50% - 85px) - 300px);
  bottom: auto;
  left: auto;
}

@media (max-width: 991px) {
  .en-organization-list__area0 li:nth-child(3) {
    right: calc((50% - 85px) - 250px);
  }
}

@media (max-width: 767px) {
  .en-organization-list__area0 li:nth-child(3) {
    width: 60px;
    right: calc((50% - 30px) - 100px);
    top: 90px;
  }
}

.en-organization-list__area0 li:nth-child(3):before {
  content: '';
  width: 1px;
  height: 10px;
  display: inline-block;
  background-color: #ddd;
  position: absolute;
  top: auto;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.en-organization-list__area0 li:nth-child(3) p {
  font-size: 2rem;
  font-weight: 700;
  color: #fff;
}

@media (max-width: 767px) {
  .en-organization-list__area0 li:nth-child(3) p {
    font-size: 1.2rem;
  }
}

.en-organization-list__area0 p {
  width: 100%;
  font-size: 2.6rem;
  font-weight: 700;
  color: #fff;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 0;
  right: auto;
  transform: translateY(-50%);
}

@media (max-width: 767px) {
  .en-organization-list__area0 p {
    font-size: 1.4rem;
  }
}

@media (max-width: 767px) {
  .en-organization-list__area1 {
    margin-bottom: 15px;
  }
}

.en-organization-list__area1 ul {
  text-align: center;
  position: relative;
}

.en-organization-list__area1 li {
  display: inline-block;
}

.en-organization-list__area1 li:nth-child(1) {
  width: 175px;
  height: 60px;
  background-color: #111987;
}

@media (max-width: 767px) {
  .en-organization-list__area1 li:nth-child(1) {
    width: 100px;
  }
}

.en-organization-list__area1 li:nth-child(1) p {
  font-size: 2rem;
  font-weight: 700;
  color: #fff;
}

@media (max-width: 767px) {
  .en-organization-list__area1 li:nth-child(1) p {
    font-size: 1.2rem;
  }
}

.en-organization-list__area1 li p {
  width: 100%;
  font-size: 2.6rem;
  font-weight: 700;
  color: #fff;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 0;
  right: auto;
  transform: translateY(-50%);
}

@media (max-width: 767px) {
  .en-organization-list__area1 li p {
    font-size: 1.4rem;
  }
}

.en-organization-list__area2 {
  padding-bottom: 15px;
  position: relative;
}

.en-organization-list__area2-line {
  width: 1px;
  height: 100%;
  display: inline-block;
  background-color: #c1c1c1;
  position: absolute;
  top: 0;
  bottom: auto;
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 767px) {
  .en-organization-list__area2-line {
    display: none;
  }
}

.en-organization-list__area2-left {
  width: 50%;
  padding-right: 245px;
  display: inline-block;
  text-align: right;
  vertical-align: top;
}

@media (max-width: 991px) {
  .en-organization-list__area2-left {
    padding-right: 170px;
  }
}

@media (max-width: 767px) {
  .en-organization-list__area2-left {
    width: 70px;
    padding-right: 0;
  }
}

.en-organization-list__area2-left li {
  margin-top: 65px;
  border: 1px solid #111987;
}

@media (max-width: 767px) {
  .en-organization-list__area2-left li {
    margin-top: 0;
  }
}

.en-organization-list__area2-left li:before {
  width: 245px;
  position: absolute;
  top: 50%;
  left: 100%;
  right: auto;
  transform: translateY(-50%);
}

@media (max-width: 991px) {
  .en-organization-list__area2-left li:before {
    width: 170px;
  }
}

.en-organization-list__area2-left li p {
  padding: 0 5px;
  font-size: 1.6rem;
  font-weight: 400;
  color: #111987;
}

@media (max-width: 767px) {
  .en-organization-list__area2-left li p {
    font-size: 1.4rem;
  }
}

.en-organization-list__area2-right {
  width: 50%;
  padding-left: 200px;
  display: inline-block;
  vertical-align: top;
}

@media (max-width: 991px) {
  .en-organization-list__area2-right {
    padding-left: 170px;
  }
}

@media (max-width: 767px) {
  .en-organization-list__area2-right {
    width: calc(100% - 70px);
    padding-left: 10px;
  }
}

.en-organization-list__area2-right ul {
  margin-top: 10px;
  padding-top: 10px;
}

@media (max-width: 767px) {
  .en-organization-list__area2-right ul {
    width: 100%;
  }
}

.en-organization-list__area2-right ul:first-child {
  margin-top: 0;
  padding-top: 0;
}

.en-organization-list__area2-right ul:first-child li {
  display: block;
}

.en-organization-list__area2-right ul:first-child li:before {
  content: '';
  width: 200px;
  height: 1px;
  display: inline-block;
  background-color: #c1c1c1;
  position: absolute;
  top: 50%;
  left: auto;
  right: 100%;
  transform: translateY(-50%);
}

@media (max-width: 991px) {
  .en-organization-list__area2-right ul:first-child li:before {
    width: 170px;
  }
}

.en-organization-list__area2-right ul:first-child li:after {
  content: '';
  width: 1px;
  height: 10px;
  display: inline-block;
  background-color: #c1c1c1;
  position: absolute;
  top: calc(100% + 1px);
  bottom: auto;
  left: 50%;
  transform: translateX(-50%);
}

.en-organization-list__area2-right li {
  margin-right: 10px;
  border: 1px solid #009ba9;
  display: inline-block;
  vertical-align: top;
}

@media (max-width: 767px) {
  .en-organization-list__area2-right li {
    width: 100% !important;
  }
}

.en-organization-list__area2-right li:before {
  content: '';
  width: 1px;
  height: 10px;
  display: inline-block;
  background-color: #c1c1c1;
  position: absolute;
  top: auto;
  bottom: calc(100% + 1px);
  left: 50%;
  transform: translateX(-50%);
}

.en-organization-list__area2-right li:last-child {
  margin-right: 0;
}

.en-organization-list__area2-right li:last-child:after {
  content: '';
  width: calc(100% + 12px);
  height: 1px;
  display: inline-block;
  background-color: #c1c1c1;
  position: absolute;
  top: -11px;
  right: 50%;
  bottom: auto;
  left: auto;
}

.en-organization-list__area2-right li p {
  padding: 0 5px;
  font-size: 1.6rem;
  font-weight: 400;
  color: #009ba9;
}

@media (max-width: 767px) {
  .en-organization-list__area2-right li p {
    font-size: 1.4rem;
  }
}

.en-organization-list__area2 li {
  width: 175px;
  height: 60px;
  margin-bottom: 5px;
  background-color: #fff;
  position: relative;
}

@media (max-width: 767px) {
  .en-organization-list__area2 li {
    width: 70px;
    height: 85px;
  }
}

.en-organization-list__area2 li:last-child {
  margin-bottom: 0;
}

.en-organization-list__area2 li p {
  width: 100%;
  padding: 0 5px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 0;
  right: auto;
  transform: translateY(-50%);
}

@media (max-width: 767px) {
  .en-organization-list__area2 li p {
    word-break: break-all;
  }
}

.en-organization-list__area3 {
  position: relative;
}

@media (max-width: 991px) {
  .en-organization-list__area3 {
    word-break: break-all;
  }
}

@media (max-width: 767px) {
  .en-organization-list__area3 {
    border-top: 1px solid #c1c1c1;
    word-break: normal;
  }
}

.en-organization-list__area3:after {
  content: '';
  width: calc(((100% - 90px) / 10) * 6 + 60px);
  height: 1px;
  display: inline-block;
  background-color: #c1c1c1;
  position: absolute;
  top: 0;
  right: calc(((100% - 90px) / 10) / 2);
  bottom: auto;
  left: auto;
}

@media (max-width: 767px) {
  .en-organization-list__area3:after {
    display: none;
  }
}

.en-organization-list__area3-col {
  margin-right: 10px;
  padding-top: 15px;
  display: inline-block;
  vertical-align: top;
  position: relative;
}

.en-organization-list__area3-line {
  width: 1px;
  height: 100%;
  display: inline-block;
  background-color: #c1c1c1;
  position: absolute;
  top: 0;
  bottom: auto;
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 767px) {
  .en-organization-list__area3-line {
    display: none;
  }
}

.en-organization-list__area3-col1 {
  width: calc(((100% - 90px) / 10) * 6 + 50px);
  position: relative;
}

@media (max-width: 767px) {
  .en-organization-list__area3-col1 {
    width: 100%;
  }
}

.en-organization-list__area3-col1 .en-organization-list__area3-line {
  left: calc((((100% - 50px) / 6) * 3 + 30px) + (((100% - 50px) / 6) / 2));
  transform: none;
}

@media (max-width: 767px) {
  .en-organization-list__area3-col1 .en-organization-list__area3-line {
    display: none;
  }
}

.en-organization-list__area3-col1 .en-organization-list__area3-1depth > li, .en-organization-list__area3-col1 .en-organization-list__area3-2depth > li {
  width: calc((100% - 50px) / 6);
  margin-left: calc(((100% - 50px) / 6) * 3 + 30px);
}

@media (max-width: 767px) {
  .en-organization-list__area3-col1 .en-organization-list__area3-1depth > li, .en-organization-list__area3-col1 .en-organization-list__area3-2depth > li {
    width: 100%;
    margin-left: 0;
  }
}

.en-organization-list__area3-col1 .en-organization-list__area3-3depth {
  position: relative;
}

.en-organization-list__area3-col1 .en-organization-list__area3-3depth:before {
  content: '';
  width: calc(((100% - 50px) / 6) * 5 + 50px);
  height: 1px;
  display: inline-block;
  background-color: #c1c1c1;
  position: absolute;
  top: 0;
  right: auto;
  bottom: auto;
  left: calc(((100% - 50px) / 6) / 2);
}

@media (max-width: 767px) {
  .en-organization-list__area3-col1 .en-organization-list__area3-3depth:before {
    display: none;
  }
}

.en-organization-list__area3-col1 .en-organization-list__area3-3depth > li {
  width: calc((100% - 50px) / 6);
  margin-right: 10px;
  display: inline-block;
}

@media (max-width: 767px) {
  .en-organization-list__area3-col1 .en-organization-list__area3-3depth > li {
    width: 100%;
    margin-right: 0;
  }
}

.en-organization-list__area3-col1 .en-organization-list__area3-3depth > li:nth-child(3) {
  width: calc(((100% - 50px) / 6) * 3 + 20px);
}

@media (max-width: 767px) {
  .en-organization-list__area3-col1 .en-organization-list__area3-3depth > li:nth-child(3) {
    width: 100%;
  }
}

.en-organization-list__area3-col1 .en-organization-list__area3-3depth > li:nth-child(3) div {
  width: calc((100% - 20px) / 3);
  display: inline-block;
}

@media (max-width: 767px) {
  .en-organization-list__area3-col1 .en-organization-list__area3-3depth > li:nth-child(3) div {
    width: 100%;
  }
}

.en-organization-list__area3-col1 .en-organization-list__area3-3depth > li:nth-child(3) div:after {
  display: none;
}

.en-organization-list__area3-col1 .en-organization-list__area3-3depth > li:nth-child(3) ul {
  position: relative;
}

.en-organization-list__area3-col1 .en-organization-list__area3-3depth > li:nth-child(3) ul:before {
  content: '';
  width: calc(((100% - 20px) / 3) * 2 + 20px);
  height: 1px;
  display: inline-block;
  background-color: #c1c1c1;
  position: absolute;
  top: -13px;
  right: auto;
  bottom: auto;
  left: calc(((100% - 20px) / 3) / 2);
}

@media (max-width: 767px) {
  .en-organization-list__area3-col1 .en-organization-list__area3-3depth > li:nth-child(3) ul:before {
    display: none;
  }
}

.en-organization-list__area3-col1 .en-organization-list__area3-3depth > li:nth-child(3) li {
  width: calc((100% - 20px) / 3);
  margin-right: 10px;
  display: inline-block;
  vertical-align: top;
}

.en-organization-list__area3-col1 .en-organization-list__area3-3depth > li:nth-child(3) li:nth-child(2):after {
  display: none;
}

.en-organization-list__area3-col1 .en-organization-list__area3-3depth > li:nth-child(3) li:last-child {
  margin-right: 0;
}

.en-organization-list__area3-col1 .en-organization-list__area3-3depth > li:nth-child(3) li:after {
  height: 13px;
}

.en-organization-list__area3-col1 .en-organization-list__area3-3depth > li li:after {
  content: '';
  width: 1px;
  height: 25px;
  display: inline-block;
  background-color: #c1c1c1;
  position: absolute;
  top: auto;
  bottom: calc(100% + 1px);
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 767px) {
  .en-organization-list__area3-col1 .en-organization-list__area3-3depth > li li:after {
    display: none;
  }
}

.en-organization-list__area3-col1 .en-organization-list__area3-3depth > li div:after {
  content: '';
  width: 1px;
  height: 15px;
  display: inline-block;
  background-color: #c1c1c1;
  position: absolute;
  top: auto;
  bottom: calc(100% + 1px);
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 767px) {
  .en-organization-list__area3-col1 .en-organization-list__area3-3depth > li div:after {
    display: none;
  }
}

.en-organization-list__area3-col1 .en-organization-list__area3-3depth > li:last-child {
  margin-right: 0;
}

.en-organization-list__area3-col2 {
  width: calc(((100% - 90px) / 10) * 2 + 10px);
}

@media (max-width: 767px) {
  .en-organization-list__area3-col2 {
    width: 100%;
  }
}

.en-organization-list__area3-col2 .en-organization-list__area3-line {
  height: calc(100% - 64px);
}

.en-organization-list__area3-col2 .en-organization-list__area3-1depth, .en-organization-list__area3-col2 .en-organization-list__area3-2depth {
  text-align: center;
}

.en-organization-list__area3-col2 .en-organization-list__area3-1depth > li, .en-organization-list__area3-col2 .en-organization-list__area3-2depth > li {
  width: calc((100% - 10px) / 2);
}

@media (max-width: 767px) {
  .en-organization-list__area3-col2 .en-organization-list__area3-1depth > li, .en-organization-list__area3-col2 .en-organization-list__area3-2depth > li {
    width: 100%;
  }
}

.en-organization-list__area3-col2 .en-organization-list__area3-3depth {
  text-align: center;
  position: relative;
}

.en-organization-list__area3-col2 .en-organization-list__area3-3depth > li div {
  width: calc((100% - 10px) / 2);
  display: inline-block;
}

@media (max-width: 767px) {
  .en-organization-list__area3-col2 .en-organization-list__area3-3depth > li div {
    width: 100%;
  }
}

.en-organization-list__area3-col2 .en-organization-list__area3-3depth > li ul {
  position: relative;
}

.en-organization-list__area3-col2 .en-organization-list__area3-3depth > li ul:before {
  content: '';
  width: calc((100% - 10px) / 2 + 10px);
  height: 1px;
  display: inline-block;
  background-color: #c1c1c1;
  position: absolute;
  top: -13px;
  right: auto;
  bottom: auto;
  left: calc(((100% - 10px) / 2) / 2);
}

@media (max-width: 767px) {
  .en-organization-list__area3-col2 .en-organization-list__area3-3depth > li ul:before {
    display: none;
  }
}

.en-organization-list__area3-col2 .en-organization-list__area3-3depth > li li {
  width: calc((100% - 10px) / 2);
  margin-right: 10px;
  display: inline-block;
  vertical-align: top;
  position: relative;
}

.en-organization-list__area3-col2 .en-organization-list__area3-3depth > li li:after {
  content: '';
  width: 1px;
  height: 13px;
  display: inline-block;
  background-color: #c1c1c1;
  position: absolute;
  top: auto;
  bottom: calc(100% + 1px);
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 767px) {
  .en-organization-list__area3-col2 .en-organization-list__area3-3depth > li li:after {
    display: none;
  }
}

.en-organization-list__area3-col2 .en-organization-list__area3-3depth > li li:last-child {
  margin-right: 0;
}

.en-organization-list__area3-col3 {
  width: calc(((100% - 90px) / 10) * 1);
}

@media (max-width: 767px) {
  .en-organization-list__area3-col3 {
    width: 100%;
  }
}

.en-organization-list__area3-col3 .en-organization-list__area3-1depth, .en-organization-list__area3-col3 .en-organization-list__area3-2depth {
  text-align: center;
}

.en-organization-list__area3-col3 .en-organization-list__area3-1depth > li, .en-organization-list__area3-col3 .en-organization-list__area3-2depth > li {
  width: 100%;
}

.en-organization-list__area3-col4 {
  width: calc(((100% - 90px) / 10) * 1);
  margin-right: 0;
}

@media (max-width: 767px) {
  .en-organization-list__area3-col4 {
    width: 100%;
  }
}

.en-organization-list__area3-col4 .en-organization-list__area3-1depth, .en-organization-list__area3-col4 .en-organization-list__area3-2depth {
  text-align: center;
}

.en-organization-list__area3-col4 .en-organization-list__area3-1depth > li, .en-organization-list__area3-col4 .en-organization-list__area3-2depth > li {
  width: 100%;
}

.en-organization-list__area3-1depth {
  margin-bottom: 35px;
}

@media (max-width: 767px) {
  .en-organization-list__area3-1depth {
    margin-bottom: 15px;
  }
}

.en-organization-list__area3-1depth > li {
  height: 52px;
  background-color: #4ac7f0;
  display: inline-block;
  position: relative;
}

@media (max-width: 1550px) {
  .en-organization-list__area3-1depth > li {
    height: 76px;
  }
}

@media (max-width: 991px) {
  .en-organization-list__area3-1depth > li {
    height: 105px;
  }
}

.en-organization-list__area3-1depth > li.null {
  background-color: transparent;
}

@media (max-width: 767px) {
  .en-organization-list__area3-1depth > li.null {
    display: none;
  }
}

.en-organization-list__area3-1depth > li p {
  width: 100%;
  padding: 0 5px;
  font-size: 1.2rem;
  font-weight: 700;
  color: #fff;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 0;
  right: auto;
  transform: translateY(-50%);
}

.en-organization-list__area3-2depth {
  margin-bottom: 15px;
}

@media (max-width: 767px) {
  .en-organization-list__area3-2depth {
    margin-bottom: 0;
  }
}

.en-organization-list__area3-2depth > li {
  height: 52px;
  background-color: #f0b74a;
  display: inline-block;
  position: relative;
}

@media (max-width: 991px) {
  .en-organization-list__area3-2depth > li {
    height: 70px;
  }
}

.en-organization-list__area3-2depth > li.null {
  background-color: transparent;
}

@media (max-width: 767px) {
  .en-organization-list__area3-2depth > li.null {
    display: none;
  }
}

.en-organization-list__area3-2depth > li p {
  width: 100%;
  padding: 0 5px;
  font-size: 1.2rem;
  font-weight: 700;
  color: #fff;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 0;
  right: auto;
  transform: translateY(-50%);
}

@media (max-width: 767px) {
  .en-organization-list__area3-3depth {
    padding-bottom: 10px;
    border-bottom: 1px solid #c1c1c1;
  }
}

.en-organization-list__area3-3depth > li {
  padding-top: 15px;
  vertical-align: top;
  text-align: center;
  position: relative;
  z-index: 1;
}

@media (max-width: 767px) {
  .en-organization-list__area3-3depth > li {
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid #c1c1c1;
  }
}

.en-organization-list__area3-3depth > li div {
  height: 52px;
  border: 1px solid #dfdfdf;
  background-color: #f1f1f1;
  position: relative;
}

@media (max-width: 991px) {
  .en-organization-list__area3-3depth > li div {
    height: 90px;
  }
}

.en-organization-list__area3-3depth > li ul {
  margin-top: 25px;
}

@media (max-width: 767px) {
  .en-organization-list__area3-3depth > li ul {
    margin-top: 10px;
  }
}

.en-organization-list__area3-3depth > li li {
  height: 52px;
  border: 1px solid #dfdfdf;
  background-color: #fff;
  position: relative;
}

@media (max-width: 991px) {
  .en-organization-list__area3-3depth > li li {
    height: 90px;
  }
}

.en-organization-list__area3-3depth > li p {
  width: 100%;
  padding: 0 5px;
  font-size: 1.2rem;
  font-weight: 400;
  color: #111;
  position: absolute;
  top: 50%;
  left: 0;
  right: auto;
  transform: translateY(-50%);
}

.en-sub-contents .organization p {
  width: 100%;
  padding: 0 5px;
  word-break: normal;
}

@media (max-width: 767px) {
  .en-sub-contents .organization p {
    padding: 0 3px;
  }
}

.en-sub-contents .organization-list__area0 a {
  line-height: 1.4;
  position: relative;
}

@media (max-width: 767px) {
  .en-sub-contents .organization-list__area0 a {
    width: 80px;
    height: 80px;
    font-size: 1.4rem;
  }
}

.en-sub-contents .organization-list__area0 a p {
  position: absolute;
  top: 50%;
  left: 0;
  right: auto;
  transform: translateY(-50%);
}

@media (max-width: 767px) {
  .en-sub-contents .organization-list__area0 div {
    padding-top: 90px;
  }
}

@media (max-width: 767px) {
  .en-sub-contents .organization-list__area0 div {
    right: calc((50% - 40px) - 90px);
  }
}

.en-sub-contents .organization-list__area0 div a {
  line-height: 1.4;
}

@media (max-width: 767px) {
  .en-sub-contents .organization-list__area0 div a {
    width: 75px;
    height: 80px;
  }
}

.en-sub-contents .organization-list__area0 div a p {
  font-size: 1.6rem;
  position: absolute;
  top: 50%;
  left: 0;
  right: auto;
  transform: translateY(-50%);
}

@media (max-width: 1200px) {
  .en-sub-contents .organization-list__area0 div a p {
    font-size: 1.4rem;
  }
}

@media (max-width: 767px) {
  .en-sub-contents .organization-list__area0 div a p {
    font-size: 1.2rem;
  }
}

@media (max-width: 767px) {
  .en-sub-contents .organization-list__area1 a {
    width: 80px;
    height: 80px;
    font-size: 1.4rem;
  }
}

.en-sub-contents .organization-list__area1 a p {
  position: absolute;
  top: 50%;
  left: 0;
  right: auto;
  transform: translateY(-50%);
}

.en-sub-contents .organization-list__area2 a {
  margin-bottom: 0;
  font-size: 1.6rem;
  line-height: 1.4;
}

@media (max-width: 1200px) {
  .en-sub-contents .organization-list__area2 a {
    font-size: 1.4rem;
  }
}

@media (max-width: 767px) {
  .en-sub-contents .organization-list__area2 a {
    width: 75px;
    height: 80px;
    font-size: 1.2rem;
  }
}

@media (max-width: 370px) {
  .en-sub-contents .organization-list__area2 a {
    width: 55px;
  }
}

.en-sub-contents .organization-list__area2 a p {
  position: absolute;
  top: 50%;
  left: 0;
  right: auto;
  transform: translateY(-50%);
}

@media (max-width: 370px) {
  .en-sub-contents .organization-list__area2 a p {
    word-break: break-all;
  }
}

@media (max-width: 1320px) {
  .en-sub-contents .organization-list__area2__right {
    padding-left: 45px;
  }
}

@media (max-width: 767px) {
  .en-sub-contents .organization-list__area2__right {
    padding-left: 10px;
  }
}

.en-sub-contents .organization-list__area2__right a:nth-of-type(1) {
  margin: 0 0 20px 0;
}

@media (max-width: 767px) {
  .en-sub-contents .organization-list__area2__right a:nth-of-type(1) {
    margin: 0 0 20px 0;
  }
}

@media (max-width: 1320px) {
  .en-sub-contents .organization-list__area2__right a:nth-of-type(1):after {
    width: 45px;
  }
}

@media (max-width: 767px) {
  .en-sub-contents .organization-list__area2__right a:nth-of-type(1):after {
    width: 10px;
  }
}

.en-sub-contents .organization-list__area3 a {
  max-width: 208px;
  height: 82px;
}

@media (max-width: 1550px) {
  .en-sub-contents .organization-list__area3 a {
    max-width: 168px;
  }
}

@media (max-width: 1320px) {
  .en-sub-contents .organization-list__area3 a {
    max-width: 128px;
    height: 110px;
  }
}

@media (max-width: 1200px) {
  .en-sub-contents .organization-list__area3 a {
    height: 82px;
  }
}

@media (max-width: 991px) {
  .en-sub-contents .organization-list__area3 a {
    max-width: 106px;
  }
}

@media (max-width: 767px) {
  .en-sub-contents .organization-list__area3 a {
    max-width: none;
    height: 60px;
  }
}

.en-sub-contents .organization-list__area3 .null {
  height: 82px;
}

@media (max-width: 1320px) {
  .en-sub-contents .organization-list__area3 .null {
    height: 110px;
  }
}

@media (max-width: 1200px) {
  .en-sub-contents .organization-list__area3 .null {
    height: 82px;
  }
}

@media (max-width: 767px) {
  .en-sub-contents .organization-list__area3 .null {
    height: 60px;
  }
}

.en-sub-contents .organization-list__area3__col .f-depth > li > a p {
  font-size: 1.6rem;
}

@media (max-width: 1200px) {
  .en-sub-contents .organization-list__area3__col .f-depth > li > a p {
    font-size: 1.4rem;
  }
}

.en-sub-contents .organization-list__area3__col .s-depth > li > a p {
  font-size: 1.6rem;
}

@media (max-width: 1200px) {
  .en-sub-contents .organization-list__area3__col .s-depth > li > a p {
    font-size: 1.4rem;
  }
}

.en-sub-contents .organization-list__area3__col__wrap.n-line:before {
  height: 344px;
}

@media (max-width: 1320px) {
  .en-sub-contents .organization-list__area3__col__wrap.n-line:before {
    height: 400px;
  }
}

@media (max-width: 1200px) {
  .en-sub-contents .organization-list__area3__col__wrap.n-line:before {
    height: 344px;
  }
}

.en-sub-contents .organization-list__area3__col__wrap .t-depth > li > a p {
  font-size: 1.6rem;
}

@media (max-width: 1200px) {
  .en-sub-contents .organization-list__area3__col__wrap .t-depth > li > a p {
    font-size: 1.4rem;
  }
}

.en-sub-contents .organization-list__area3__col__wrap .t-depth li a p {
  font-size: 1.6rem;
}

@media (max-width: 1200px) {
  .en-sub-contents .organization-list__area3__col__wrap .t-depth li a p {
    font-size: 1.4rem;
  }
}

.en-sub-contents .organization-list__area3__col.col1 .f-depth > li > a {
  margin-right: 210px;
}

@media (max-width: 1520px) {
  .en-sub-contents .organization-list__area3__col.col1 .f-depth > li > a {
    margin-right: 170px;
  }
}

@media (max-width: 1320px) {
  .en-sub-contents .organization-list__area3__col.col1 .f-depth > li > a {
    margin-right: 130px;
  }
}

@media (max-width: 767px) {
  .en-sub-contents .organization-list__area3__col.col1 .f-depth > li > a {
    margin-right: 0;
  }
}

.en-dispatch-tab {
  margin-bottom: 20px;
  padding: 16px 0;
  background: linear-gradient(to right, #1d92a0, #12226e);
}

.en-dispatch-tab li {
  width: calc(100% / 6);
  display: inline-block;
  vertical-align: middle;
  text-align: center;
}

@media (max-width: 767px) {
  .en-dispatch-tab li {
    width: 50%;
    margin: 5px 0;
  }
}

.en-dispatch-tab a {
  height: 35px;
  padding: 0 28px;
  border-radius: 50px;
  display: inline-block;
  font-size: 1.6rem;
  font-weight: 700;
  color: #fff;
  line-height: 35px;
}

.en-dispatch-tab a.selected {
  background-color: #fff;
  color: #111;
}

.en-dispatch-contents div {
  display: none;
}

@media (max-width: 991px) {
  .en-dispatch-contents div {
    overflow-x: auto;
  }
  .en-dispatch-contents div .en-basic-table {
    min-width: 800px;
  }
}

@media (max-width: 767px) {
  .en-dispatch-contents div .en-basic-table {
    min-width: 500px;
  }
}

.en-dispatch-contents div.visible {
  display: block;
}

@media (max-width: 991px) {
  .en-festival-status {
    overflow-x: auto;
  }
}

@media (max-width: 991px) {
  .en-festival-status table {
    min-width: 800px;
  }
}

@media (max-width: 991px) {
  .en-demonstration-status {
    overflow-x: auto;
  }
}

@media (max-width: 991px) {
  .en-demonstration-status table {
    min-width: 580px;
  }
}

.en-basic-table {
  width: 100%;
  border-top: 2px solid #4d4d4d;
  table-layout: fixed;
}

.en-basic-table .w-5 {
  width: 5%;
}

.en-basic-table .w-10 {
  width: 10%;
}

.en-basic-table .w-15 {
  width: 15%;
}

.en-basic-table .w-20 {
  width: 20%;
}

.en-basic-table .w-25 {
  width: 25%;
}

.en-basic-table .w-30 {
  width: 30%;
}

.en-basic-table .w-35 {
  width: 35%;
}

.en-basic-table .w-40 {
  width: 40%;
}

.en-basic-table .w-45 {
  width: 45%;
}

.en-basic-table .w-50 {
  width: 50%;
}

.en-basic-table .w-55 {
  width: 55%;
}

.en-basic-table .w-60 {
  width: 60%;
}

.en-basic-table .w-65 {
  width: 65%;
}

.en-basic-table .w-70 {
  width: 70%;
}

.en-basic-table .w-75 {
  width: 75%;
}

.en-basic-table .w-80 {
  width: 80%;
}

.en-basic-table .w-85 {
  width: 85%;
}

.en-basic-table .w-90 {
  width: 90%;
}

.en-basic-table .w-95 {
  width: 95%;
}

.en-basic-table .w-100 {
  width: 100%;
}

.en-basic-table .ta-l {
  text-align: left;
}

.en-basic-table .ta-c {
  text-align: center;
}

.en-basic-table .ta-r {
  text-align: right;
}

.en-basic-table .va-t {
  vertical-align: top;
}

.en-basic-table .va-m {
  vertical-align: middle;
}

.en-basic-table .va-b {
  vertical-align: bottom;
}

@media (max-width: 1200px) {
  .en-basic-table .w-md-5 {
    width: 5%;
  }
  .en-basic-table .w-md-10 {
    width: 10%;
  }
  .en-basic-table .w-md-15 {
    width: 15%;
  }
  .en-basic-table .w-md-20 {
    width: 20%;
  }
  .en-basic-table .w-md-25 {
    width: 25%;
  }
  .en-basic-table .w-md-30 {
    width: 30%;
  }
  .en-basic-table .w-md-35 {
    width: 35%;
  }
  .en-basic-table .w-md-40 {
    width: 40%;
  }
  .en-basic-table .w-md-45 {
    width: 45%;
  }
  .en-basic-table .w-md-50 {
    width: 50%;
  }
  .en-basic-table .w-md-55 {
    width: 55%;
  }
  .en-basic-table .w-md-60 {
    width: 60%;
  }
  .en-basic-table .w-md-65 {
    width: 65%;
  }
  .en-basic-table .w-md-70 {
    width: 70%;
  }
  .en-basic-table .w-md-75 {
    width: 75%;
  }
  .en-basic-table .w-md-80 {
    width: 80%;
  }
  .en-basic-table .w-md-85 {
    width: 85%;
  }
  .en-basic-table .w-md-90 {
    width: 90%;
  }
  .en-basic-table .w-md-95 {
    width: 95%;
  }
  .en-basic-table .w-md-100 {
    width: 100%;
  }
  .en-basic-table .w-md-n {
    display: none;
  }
}

@media (max-width: 991px) {
  .en-basic-table .w-sm-5 {
    width: 5%;
  }
  .en-basic-table .w-sm-10 {
    width: 10%;
  }
  .en-basic-table .w-sm-15 {
    width: 15%;
  }
  .en-basic-table .w-sm-20 {
    width: 20%;
  }
  .en-basic-table .w-sm-25 {
    width: 25%;
  }
  .en-basic-table .w-sm-30 {
    width: 30%;
  }
  .en-basic-table .w-sm-35 {
    width: 35%;
  }
  .en-basic-table .w-sm-40 {
    width: 40%;
  }
  .en-basic-table .w-sm-45 {
    width: 45%;
  }
  .en-basic-table .w-sm-50 {
    width: 50%;
  }
  .en-basic-table .w-sm-55 {
    width: 55%;
  }
  .en-basic-table .w-sm-60 {
    width: 60%;
  }
  .en-basic-table .w-sm-65 {
    width: 65%;
  }
  .en-basic-table .w-sm-70 {
    width: 70%;
  }
  .en-basic-table .w-sm-75 {
    width: 75%;
  }
  .en-basic-table .w-sm-80 {
    width: 80%;
  }
  .en-basic-table .w-sm-85 {
    width: 85%;
  }
  .en-basic-table .w-sm-90 {
    width: 90%;
  }
  .en-basic-table .w-sm-95 {
    width: 95%;
  }
  .en-basic-table .w-sm-100 {
    width: 100%;
  }
  .en-basic-table .w-sm-n {
    display: none;
  }
}

@media (max-width: 767px) {
  .en-basic-table .w-xs-5 {
    width: 5%;
  }
  .en-basic-table .w-xs-10 {
    width: 10%;
  }
  .en-basic-table .w-xs-15 {
    width: 15%;
  }
  .en-basic-table .w-xs-20 {
    width: 20%;
  }
  .en-basic-table .w-xs-25 {
    width: 25%;
  }
  .en-basic-table .w-xs-30 {
    width: 30%;
  }
  .en-basic-table .w-xs-35 {
    width: 35%;
  }
  .en-basic-table .w-xs-40 {
    width: 40%;
  }
  .en-basic-table .w-xs-45 {
    width: 45%;
  }
  .en-basic-table .w-xs-50 {
    width: 50%;
  }
  .en-basic-table .w-xs-55 {
    width: 55%;
  }
  .en-basic-table .w-xs-60 {
    width: 60%;
  }
  .en-basic-table .w-xs-65 {
    width: 65%;
  }
  .en-basic-table .w-xs-70 {
    width: 70%;
  }
  .en-basic-table .w-xs-75 {
    width: 75%;
  }
  .en-basic-table .w-xs-80 {
    width: 80%;
  }
  .en-basic-table .w-xs-85 {
    width: 85%;
  }
  .en-basic-table .w-xs-90 {
    width: 90%;
  }
  .en-basic-table .w-xs-95 {
    width: 95%;
  }
  .en-basic-table .w-xs-100 {
    width: 100%;
  }
  .en-basic-table .w-xs-n {
    display: none;
  }
}

.en-basic-table tr {
  border-bottom: 1px solid #c1c1c1;
}

.en-basic-table th {
  height: 52px;
  padding: 0 10px;
  border-right: 1px solid #d9d9d9;
  background-color: #fafafa;
  font-size: 1.4rem;
  font-weight: 700;
  color: #111;
}

.en-basic-table th:last-child {
  border-right: none;
}

.en-basic-table td {
  padding: 15px 10px;
  border-right: 1px solid #d9d9d9;
  font-size: 1.4rem;
  font-weight: 400;
  color: #555;
}

.en-basic-table td:last-child {
  border-right: none;
}

.en-basic-table td.bg {
  background-color: #fdfdfd;
}

.cn {
  font-family: 'Noto Sans SC';
  word-wrap: break-word;
}

.cn .header {
  display: block !important;
  top: 0;
}

.cn .side-area {
  height: 100vh;
  display: block !important;
  top: 0;
}

.cn .section0 {
  height: 100vh;
}

@media (max-width: 991px) {
  .cn .section0 {
    margin-top: 115px;
    height: auto !important;
  }
}

@media (max-width: 767px) {
  .cn .section0 {
    margin-top: 50px;
  }
}

.cn .section1 {
  background-color: #fbf9f2;
}

.cn .section2 {
  padding-bottom: 0;
  background-color: #fff;
}

.cn .footer__top {
  padding-bottom: 0;
  border-bottom: none;
}

.cn .footer__link {
  margin-top: 0;
  margin-bottom: 10px;
  text-align: left;
}

@media (max-width: 991px) {
  .cn .footer__link li {
    margin-right: 0;
  }
}

.cn .footer__link li:after {
  bottom: auto;
}

.cn .one-depth {
  word-break: break-all;
}

.cn .vision-wrap__one-depth__circle div p {
  padding: 0 5px;
}

.login-contents {
  position: relative;
}

.login-contents__tit {
  margin-bottom: 45px;
  text-align: center;
}

.login-contents__tit h3 {
  margin-bottom: 20px;
  font-size: 3.2rem;
  font-weight: 500;
  color: #000;
  font-family: 'GmarketSans';
}

.login-contents__tit h3 span {
  font-weight: 700;
}

@media (max-width: 767px) {
  .login-contents__tit h3 {
    font-size: 2.4rem;
  }
}

.login-contents__tit p {
  font-size: 1.6rem;
  font-weight: 400;
  color: #888;
  line-height: 2.4rem;
}

@media (max-width: 767px) {
  .login-contents__tit p {
    font-size: 1.4rem;
  }
}

.login-contents__info {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  padding: 60px 100px 80px;
  border-top: 5px solid #0f8940;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  background-color: #fff;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.1);
}

@media (max-width: 1250px) {
  .login-contents__info {
    padding: 60px 50px 80px;
  }
}

@media (max-width: 767px) {
  .login-contents__info {
    padding: 60px 15px;
  }
}

.login-contents__info form {
  margin-bottom: 50px;
  font-family: 'GmarketSans';
}

@media (max-width: 767px) {
  .login-contents__info form {
    margin-bottom: 30px;
  }
}

.login-contents__info__user {
  width: calc(100% - 170px);
  margin-right: 20px;
  display: inline-block;
  vertical-align: top;
}

.login-contents__info__user label {
  width: 70px;
  height: 75px;
  border: 1px solid #dfdfdf;
  border-right: none;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  display: inline-block;
  vertical-align: top;
  position: relative;
}

.login-contents__info__user label img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (max-width: 767px) {
  .login-contents__info__user label img {
    width: 40%;
  }
}

@media (max-width: 767px) {
  .login-contents__info__user label {
    width: 50px;
    height: 50px;
  }
}

.login-contents__info__user input {
  width: calc(100% - 70px);
  height: 75px;
  border: 1px solid #dfdfdf;
  border-left: none;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  font-size: 1.8rem;
  font-weight: 500;
  color: #000;
}

.login-contents__info__user input::placeholder {
  color: #999;
}

@media (max-width: 767px) {
  .login-contents__info__user input {
    width: calc(100% - 50px);
    height: 50px;
  }
}

.login-contents__info__user__id {
  margin-bottom: 10px;
}

@media (max-width: 991px) {
  .login-contents__info__user {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
}

.login-contents__info__btn {
  width: 150px;
  display: inline-block;
  vertical-align: top;
}

.login-contents__info__btn button {
  width: 100%;
  height: 160px;
  background-color: #0f8940;
  font-size: 2.2rem;
  font-weight: 500;
  color: #fff;
}

@media (max-width: 991px) {
  .login-contents__info__btn button {
    height: 50px;
  }
}

@media (max-width: 991px) {
  .login-contents__info__btn {
    width: 100%;
  }
}

.login-contents__btn {
  text-align: center;
}

.login-contents__btn a {
  font-size: 1.6rem;
  font-weight: 400;
  color: #333;
  vertical-align: middle;
}

.login-contents__btn a:hover {
  color: #0f8940;
}

@media (max-width: 767px) {
  .login-contents__btn a {
    font-size: 1.4rem;
  }
}

.login-contents__btn span {
  width: 1px;
  height: 15px;
  margin: 0 12px;
  display: inline-block;
  background-color: #bebebe;
  vertical-align: middle;
}

@media (max-width: 767px) {
  .login-contents__btn span {
    margin: 0 5px;
  }
}

@media (max-width: 767px) {
  .login-contents {
    padding-top: 30px;
    padding-bottom: 0;
  }
}

.sign {
  padding-top: 70px;
}

.sign-contents__btn {
  margin-top: 50px;
  text-align: center;
}

.sign-contents__btn a {
  width: 150px;
  height: 50px;
  border: 1px solid #dfdfdf;
  font-size: 1.6rem;
  font-weight: 500;
  color: #888;
  line-height: 48px;
  transition-duration: .3s;
}

.sign-contents__btn a:hover {
  background-color: #dfdfdf;
  color: #fff;
}

.sign-contents__btn a:last-child {
  margin-left: 10px;
  border: 1px solid #0f8940;
  color: #0f8940;
}

.sign-contents__btn a:last-child:hover {
  background-color: #0f8940;
  color: #fff;
}

@media (max-width: 767px) {
  .sign-contents__btn a:last-child {
    margin-left: 0;
  }
}

@media (max-width: 767px) {
  .sign-contents__btn a {
    width: 50%;
  }
}

@media (max-width: 767px) {
  .sign-contents__btn {
    margin-top: 30px;
  }
}

.sign-step1-contents__type {
  width: calc(50% - 20px);
  margin-right: 40px;
  padding: 55px 0 70px;
  border: 1px solid #e5e5e5;
  border-top: 5px solid #0f8940;
  display: inline-block;
  text-align: center;
  vertical-align: top;
}

.sign-step1-contents__type:last-child {
  margin-right: 0;
  margin-bottom: 0;
}

.sign-step1-contents__type img {
  margin-bottom: 30px;
  vertical-align: middle;
}

.sign-step1-contents__type h3 {
  margin-bottom: 20px;
  font-size: 2.4rem;
  font-weight: 700;
  color: #000;
  font-family: 'GmarketSans';
}

.sign-step1-contents__type p {
  margin-bottom: 30px;
  font-size: 1.6rem;
  font-weight: 500;
  color: #666;
  line-height: 2.4rem;
}

@media (max-width: 767px) {
  .sign-step1-contents__type p {
    font-size: 1.4rem;
  }
}

.sign-step1-contents__type a {
  width: 190px;
  height: 50px;
  margin-bottom: 30px;
  padding-left: 25px;
  border: 2px solid #dfdfdf;
  border-radius: 5px;
  font-size: 1.6rem;
  font-weight: 700;
  color: #000016;
  line-height: 46px;
  text-align: left;
  position: relative;
  transition-duration: .3s;
}

.sign-step1-contents__type a:after {
  content: '';
  width: 7px;
  height: 7px;
  border-top: 1px solid #000;
  border-right: 1px solid #000;
  position: absolute;
  top: 50%;
  left: auto;
  right: 20px;
  transform: translateY(-50%);
  transform: translateY(-50%) rotate(45deg);
}

.sign-step1-contents__type a:hover {
  border: 2px solid #0f8940;
  background-color: #0f8940;
  color: #fff;
}

.sign-step1-contents__type a:hover:after {
  border-top: 1px solid #fff;
  border-right: 1px solid #fff;
}

.sign-step1-contents__type .c-t {
  margin-bottom: 0;
  color: #d34e12;
  vertical-align: middle;
}

.sign-step1-contents__type .c-t img {
  margin-bottom: 0;
  position: relative;
  bottom: 2px;
}

@media (max-width: 991px) {
  .sign-step1-contents__type {
    padding: 55px 15px 70px;
  }
}

@media (max-width: 767px) {
  .sign-step1-contents__type {
    width: 100%;
    margin-right: 0;
    margin-bottom: 30px;
    padding: 30px 15px;
  }
}

.sign-step2-contents__terms {
  margin-top: 50px;
}

.sign-step2-contents__terms h3 {
  margin-bottom: 10px;
  font-size: 2.4rem;
  font-weight: 700;
  color: #000;
}

@media (max-width: 767px) {
  .sign-step2-contents__terms h3 {
    font-size: 2rem;
  }
}

.sign-step2-contents__terms__wrap {
  padding: 20px 25px;
  border: 1px solid #e5e5e5;
  border-top: 3px solid #0f8940;
}

.sign-step2-contents__terms__text {
  width: 100%;
  height: 210px;
  margin-bottom: 20px;
  overflow-y: auto;
  border-bottom: 1px solid #eaeaea;
}

.sign-step2-contents__terms__text h4 {
  margin-top: 20px;
  font-size: 2rem;
  font-weight: 700;
  color: #222;
}

@media (max-width: 767px) {
  .sign-step2-contents__terms__text h4 {
    font-size: 1.8rem;
  }
}

.sign-step2-contents__terms__text p {
  font-size: 1.6rem;
  font-weight: 700;
  color: #888;
  line-height: 2.4rem;
}

.sign-step2-contents__terms__text p.terms-sub-title {
  margin-top: 30px;
  font-size: 1.8rem;
  font-weight: 700;
  color: #0f8940;
}

@media (max-width: 767px) {
  .sign-step2-contents__terms__text p.terms-sub-title {
    font-size: 1.6rem;
  }
}

.sign-step2-contents__terms__text p:last-child {
  margin-top: 30px;
}

@media (max-width: 767px) {
  .sign-step2-contents__terms__text p {
    font-size: 1.4rem;
  }
}

.sign-step2-contents__terms__text ul {
  padding-left: 20px;
}

@media (max-width: 767px) {
  .sign-step2-contents__terms__text ul {
    padding-left: 10px;
  }
}

.sign-step2-contents__terms__text li {
  font-size: 1.6rem;
  font-weight: 700;
  color: #888;
  line-height: 2.4rem;
}

@media (max-width: 767px) {
  .sign-step2-contents__terms__text li {
    font-size: 1.4rem;
  }
}

.sign-step2-contents__terms__text table {
  width: 100%;
  table-layout: fixed;
}

.sign-step2-contents__terms__text th {
  padding: 10px;
  border: 1px solid #ddd;
  background-color: #ddd;
  font-size: 1.6rem;
  font-weight: 700;
  color: #888;
  word-break: break-all;
}

@media (max-width: 767px) {
  .sign-step2-contents__terms__text th {
    font-size: 1.4rem;
  }
}

.sign-step2-contents__terms__text td {
  padding: 5px;
  border: 1px solid #ddd;
  font-size: 1.6rem;
  font-weight: 700;
  color: #888;
  text-align: center;
  vertical-align: middle;
  word-break: break-all;
}

@media (max-width: 767px) {
  .sign-step2-contents__terms__text td {
    font-size: 1.4rem;
  }
}

.sign-step2-contents__terms__agree {
  text-align: center;
}

.sign-step2-contents__terms__agree input:checked + label:before {
  border: 2px solid #0f8940;
  background-color: #0f8940;
}

.sign-step2-contents__terms__agree label {
  padding-left: 30px;
  font-size: 1.7rem;
  font-weight: 700;
  color: #5d5d5d;
  text-align: left;
  position: relative;
}

.sign-step2-contents__terms__agree label:nth-of-type(1) {
  margin-right: 50px;
}

.sign-step2-contents__terms__agree label:before {
  content: '';
  width: 15px;
  height: 15px;
  border-radius: 20px;
  border: 2px solid #c1c1c1;
  position: absolute;
  top: 50%;
  left: 0;
  right: auto;
  transform: translateY(-50%);
}

@media (max-width: 767px) {
  .sign-step2-contents__terms__agree label {
    margin: 5px 0;
    display: block;
    font-size: 1.4rem;
  }
}

@media (max-width: 767px) {
  .sign-step2-contents__terms {
    margin-top: 30px;
  }
}

.sign-step2-contents__all-agree {
  margin-top: 50px;
  padding: 36px 0;
  background-color: #f8f8f8;
  text-align: center;
}

.sign-step2-contents__all-agree input:checked + label:before {
  border: 2px solid #0f8940;
  background-color: #0f8940;
}

.sign-step2-contents__all-agree label {
  padding-left: 45px;
  font-size: 2rem;
  font-weight: 700;
  color: #000;
  position: relative;
}

.sign-step2-contents__all-agree label:before {
  content: '';
  width: 18px;
  height: 18px;
  border: 2px solid #c1c1c1;
  display: inline-block;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 0;
  right: auto;
  transform: translateY(-50%);
}

@media (max-width: 767px) {
  .sign-step2-contents__all-agree label {
    padding-left: 35px;
    font-size: 1.6rem;
  }
}

@media (max-width: 767px) {
  .sign-step2-contents__all-agree {
    margin-top: 30px;
  }
}

.sign-step3-contents__text {
  margin-top: 50px;
  padding: 26px 0;
  background-color: #f8f8f8;
  text-align: center;
}

.sign-step3-contents__text p {
  font-size: 1.6rem;
  font-weight: 500;
  color: #666;
}

@media (max-width: 767px) {
  .sign-step3-contents__text p {
    font-size: 1.4rem;
  }
}

@media (max-width: 767px) {
  .sign-step3-contents__text {
    margin-top: 30px;
  }
}

.sign-step3-contents__certification {
  margin-top: 50px;
  padding: 50px 75px;
  border: 1px solid #f7f7f7;
  border-top: 3px solid #0f8940;
}

.sign-step3-contents__certification > div {
  display: inline-block;
  vertical-align: middle;
}

.sign-step3-contents__certification__img {
  width: 170px;
  margin-right: 75px;
}

@media (max-width: 1230px) {
  .sign-step3-contents__certification__img {
    width: 150px;
    margin-right: 50px;
  }
}

@media (max-width: 991px) {
  .sign-step3-contents__certification__img {
    width: 100%;
    margin-right: 0;
    margin-bottom: 30px;
    text-align: center;
  }
}

@media (max-width: 767px) {
  .sign-step3-contents__certification__img {
    margin-bottom: 10px;
  }
  .sign-step3-contents__certification__img img {
    width: 130px;
  }
}

.sign-step3-contents__certification__text {
  width: calc(100% - 600px);
}

.sign-step3-contents__certification__text h3 {
  margin-bottom: 20px;
  font-size: 2.4rem;
  font-weight: 700;
  color: #222;
}

@media (max-width: 767px) {
  .sign-step3-contents__certification__text h3 {
    margin-bottom: 10px;
  }
}

.sign-step3-contents__certification__text p {
  margin-bottom: 20px;
  font-size: 1.6rem;
  font-weight: 500;
  color: #666;
  line-height: 2.4rem;
}

.sign-step3-contents__certification__text p.c-t {
  margin-bottom: 0;
  padding-left: 30px;
  color: #d34e12;
  position: relative;
}

.sign-step3-contents__certification__text p.c-t img {
  position: absolute;
  top: 5px;
  right: auto;
  bottom: auto;
  left: 0;
}

@media (max-width: 767px) {
  .sign-step3-contents__certification__text p.c-t img {
    position: initial;
    transform: none;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
    vertical-align: middle;
  }
}

@media (max-width: 767px) {
  .sign-step3-contents__certification__text p.c-t {
    padding-left: 0;
  }
}

@media (max-width: 767px) {
  .sign-step3-contents__certification__text p {
    font-size: 1.4rem;
  }
}

@media (max-width: 1230px) {
  .sign-step3-contents__certification__text {
    width: calc(100% - 450px);
  }
}

@media (max-width: 991px) {
  .sign-step3-contents__certification__text {
    width: 100%;
    margin-bottom: 30px;
  }
}

.sign-step3-contents__certification__btn {
  width: 200px;
  margin-left: 155px;
}

.sign-step3-contents__certification__btn a {
  width: 200px;
  height: 80px;
  border-radius: 10px;
  border: 2px solid #dfdfdf;
  font-size: 1.8rem;
  font-weight: 700;
  color: #000016;
  line-height: 76px;
  text-align: center;
}

.sign-step3-contents__certification__btn a.certification-btn {
  margin-bottom: 10px;
  border: 2px solid #0f8940;
  background-color: #0f8940;
  color: #fff;
}

@media (max-width: 991px) {
  .sign-step3-contents__certification__btn a.certification-btn {
    margin-right: 15px;
    margin-bottom: 0;
  }
}

@media (max-width: 767px) {
  .sign-step3-contents__certification__btn a.certification-btn {
    margin-right: 0;
    margin-bottom: 10px;
  }
}

@media (max-width: 991px) {
  .sign-step3-contents__certification__btn a {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .sign-step3-contents__certification__btn a {
    height: 50px;
    line-height: 50px;
  }
}

@media (max-width: 1230px) {
  .sign-step3-contents__certification__btn {
    margin-left: 50px;
  }
}

@media (max-width: 991px) {
  .sign-step3-contents__certification__btn {
    width: 100%;
    margin-left: 0;
    text-align: center;
  }
}

@media (max-width: 1230px) {
  .sign-step3-contents__certification {
    padding: 50px;
  }
}

@media (max-width: 991px) {
  .sign-step3-contents__certification {
    padding: 30px 50px;
  }
}

@media (max-width: 767px) {
  .sign-step3-contents__certification {
    margin-top: 30px;
    padding: 30px 15px;
  }
}

.sign-step4-contents__essential {
  margin-top: 50px;
}

@media (max-width: 767px) {
  .sign-step4-contents__essential {
    margin-top: 30px;
  }
}

.sign-step4-contents__option {
  margin-top: 50px;
}

@media (max-width: 767px) {
  .sign-step4-contents__option {
    margin-top: 30px;
  }
}

.sign-step4-contents__tit {
  margin-bottom: 10px;
}

.sign-step4-contents__tit h3 {
  width: 50%;
  display: inline-block;
  font-size: 2.4rem;
  font-weight: 700;
  color: #222;
  vertical-align: bottom;
}

@media (max-width: 767px) {
  .sign-step4-contents__tit h3 {
    width: 100%;
    margin-bottom: 5px;
  }
}

.sign-step4-contents__tit p {
  width: 50%;
  display: inline-block;
  font-size: 1.6rem;
  font-weight: 500;
  color: #d34e12;
  vertical-align: bottom;
  text-align: right;
}

.sign-step4-contents__tit p img {
  vertical-align: middle;
}

@media (max-width: 767px) {
  .sign-step4-contents__tit p {
    width: 100%;
    font-size: 1.2rem;
  }
}

@media (max-width: 767px) {
  .sign-step4-contents__tit {
    margin-bottom: 5px;
  }
}

.sign-step4-contents__info col {
  width: 215px;
}

.sign-step4-contents__info col:last-child {
  width: calc(100% - 215px);
}

.sign-step4-contents__info .id-confirm {
  width: 130px;
  height: 40px;
  margin-left: 5px;
  border: 1px solid #0f8940;
  font-size: 1.4rem;
  font-weight: 700;
  color: #0f8940;
  text-align: center;
  line-height: 38px;
  vertical-align: middle;
  transition-duration: .3s;
}

.sign-step4-contents__info .id-confirm:hover {
  background-color: #0f8940;
  color: #fff;
}

@media (max-width: 767px) {
  .sign-step4-contents__info .id-confirm {
    width: 100%;
    margin-left: 0;
  }
}

.sign-step4-contents__info .sub-text {
  margin-top: 5px;
  font-size: 1.5rem;
  font-weight: 500;
  color: #999;
}

@media (max-width: 767px) {
  .sign-step4-contents__info .sub-text {
    font-size: 1.3rem;
  }
}

.sign-step4-contents__info span {
  font-size: 1.5rem;
  font-weight: 500;
  color: #d45251;
}

@media (max-width: 767px) {
  .sign-step4-contents__info span {
    font-size: 1.3rem;
  }
}

.sign-step4-contents__info .between-text {
  display: inline-block;
  vertical-align: middle;
  font-size: 1.5rem;
  font-weight: 500;
  color: #000;
}

@media (max-width: 767px) {
  .sign-step4-contents__info .between-text {
    margin-left: 5px;
  }
}

@media (max-width: 767px) {
  .sign-step4-contents__info .global-board {
    width: 100%;
    display: inline-block;
  }
}

@media (max-width: 767px) {
  .sign-step4-contents__info tbody {
    width: 100%;
    display: inline-block;
  }
}

@media (max-width: 767px) {
  .sign-step4-contents__info tr {
    width: 100%;
    border-bottom: 1px solid #000;
    display: inline-block;
  }
}

@media (max-width: 767px) {
  .sign-step4-contents__info th {
    width: 100%;
    border-bottom: 1px solid #ddd;
    display: inline-block;
  }
}

@media (max-width: 767px) {
  .sign-step4-contents__info td {
    width: 100%;
    display: inline-block;
  }
}

@media (max-width: 767px) {
  .sign-step4-contents__info input {
    margin: 5px 0;
  }
}

@media (max-width: 767px) {
  .sign-step4-contents__info input:not([type="radio"]), .sign-step4-contents__info input:not([type="checkbox"]) {
    max-width: 100%;
  }
}

@media (max-width: 767px) {
  .sign-step4-contents__info input[type="number"] {
    max-width: 100%;
  }
}

@media (max-width: 767px) {
  .sign-step4-contents__info select {
    width: 100%;
    margin: 5px 0;
  }
}

@media (max-width: 767px) {
  .sign-step4-contents__info .birth select {
    width: calc(100% - 19px);
  }
}

@media (max-width: 767px) {
  .sign-step4-contents__info .phone select {
    width: calc(100% - 11px);
  }
}

@media (max-width: 767px) {
  .sign-step4-contents__info .phone input[type="number"] {
    max-width: calc(100% - 11px);
  }
}

.sign-step5-contents__text {
  margin-top: 50px;
  text-align: center;
}

.sign-step5-contents__text img {
  margin-bottom: 80px;
}

@media (max-width: 767px) {
  .sign-step5-contents__text img {
    margin-bottom: 30px;
  }
}

.sign-step5-contents__text h3 {
  margin-bottom: 25px;
  font-size: 2.4rem;
  font-weight: 500;
  color: #222;
}

.sign-step5-contents__text h3 span {
  font-weight: 700;
  color: #0f8940;
}

@media (max-width: 767px) {
  .sign-step5-contents__text h3 {
    font-size: 2rem;
  }
}

.sign-step5-contents__text p {
  font-size: 1.6rem;
  font-weight: 500;
  color: #666;
}

@media (max-width: 767px) {
  .sign-step5-contents__text p {
    font-size: 1.4rem;
  }
}

@media (max-width: 767px) {
  .sign-step5-contents__text {
    margin-top: 30px;
  }
}

.calendar-contents__date {
  margin-bottom: 20px;
}

.calendar-contents__date > * {
  height: 50px;
  border: 1px solid #e5e5e5;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  position: relative;
}

.calendar-contents__date p {
  width: 150px;
  font-size: 1.6rem;
  font-weight: 500;
  color: #555;
  line-height: 50px;
}

.calendar-contents__date a {
  width: 50px;
  font-size: 0;
}

.calendar-contents__date img {
  display: inline-block;
  margin-right: 10px;
  vertical-align: middle;
  position: relative;
  bottom: 2px;
}

.calendar-contents__date .prev-month {
  border-right: none;
}

.calendar-contents__date .prev-month:before {
  content: '';
  width: 9px;
  height: 9px;
  border-top: 2px solid #ccc;
  border-left: 2px solid #ccc;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: translate(-30%, -50%) rotate(-45deg);
}

.calendar-contents__date .next-month {
  border-left: none;
}

.calendar-contents__date .next-month:before {
  content: '';
  width: 9px;
  height: 9px;
  border-top: 2px solid #ccc;
  border-right: 2px solid #ccc;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: translate(-70%, -50%) rotate(45deg);
}

.calendar-contents__table {
  overflow: visible;
}

.calendar-contents__table .c1:before {
  content: '빨강';
  background-color: #f05050;
}

.calendar-contents__table .c2:before {
  content: '노랑';
  background-color: #fbaa1d;
}

.calendar-contents__table .c3:before {
  content: '초록';
  background-color: #0f874a;
}

.calendar-contents__table .c4:before {
  content: '파랑';
  background-color: #5187f1;
}

.calendar-contents__table .c5:before {
  content: '검정';
  background-color: #000;
}

.calendar-contents__table table {
  width: 100%;
  table-layout: fixed;
  font-family: 'GmarketSans';
}

.calendar-contents__table table th {
  height: 50px;
  border: 1px solid #0f8940;
  background-color: #0f8940;
  font-size: 1.6rem;
  font-weight: 500;
  color: #fff;
}

.calendar-contents__table table td {
  padding: 15px 10px;
  border: 1px solid #dfdfdf;
  position: relative;
  overflow: visible;
}

.calendar-contents__table table td:before {
  content: '';
  width: 100%;
  height: 100%;
  border: 1px solid #0f8940;
  display: none;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: -1px;
  right: auto;
  bottom: auto;
  left: -1px;
}

.calendar-contents__table table td:hover {
  z-index: 1;
}

.calendar-contents__table table td:hover:before {
  display: inline-block;
}

@media (max-width: 767px) {
  .calendar-contents__table table td {
    padding: 10px 5px;
  }
  .calendar-contents__table table td:after {
    content: '';
    width: 100%;
    height: 100%;
    display: inline-block;
    position: absolute;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
  }
}

.calendar-contents__table table p {
  margin-bottom: 5px;
  font-size: 1.5rem;
  font-weight: 300;
  color: #888;
  text-align: center;
}

.calendar-contents__table table ul {
  min-height: 72px;
}

.calendar-contents__table table li {
  padding: 5px 0;
}

@media (max-width: 767px) {
  .calendar-contents__table table li {
    width: auto;
    display: inline-block;
    padding: 2px;
    vertical-align: top;
  }
}

.calendar-contents__table table a {
  width: 100%;
  padding-left: 15px;
  font-size: 1.2rem;
  font-weight: 400;
  color: #444;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
}

.calendar-contents__table table a:before {
  content: '';
  width: 8px;
  height: 8px;
  border-radius: 8px;
  display: inline-block;
  font-size: 0;
  position: absolute;
  top: 50%;
  left: 0;
  right: auto;
  transform: translateY(-50%);
}

@media (max-width: 767px) {
  .calendar-contents__table table a {
    width: 8px;
    height: 8px;
    padding-left: 0;
    font-size: 0;
  }
}

.calendar-contents__table > ul {
  margin-top: 15px;
  padding-left: 10px;
}

.calendar-contents__table > ul li {
  margin-right: 20px;
  padding-left: 15px;
  display: inline-block;
  font-size: 1.6rem;
  font-weight: 500;
  color: #555;
  vertical-align: middle;
  position: relative;
}

.calendar-contents__table > ul li:before {
  content: '';
  width: 8px;
  height: 8px;
  border-radius: 8px;
  display: inline-block;
  font-size: 0;
  position: absolute;
  top: 50%;
  left: 0;
  right: auto;
  transform: translateY(-50%);
}

.calendar-contents__table > ul li:last-child {
  margin-right: 0;
}

@media (max-width: 767px) {
  .calendar-contents__table > ul li {
    margin-right: 10px;
  }
}

@media (max-width: 767px) {
  .calendar-contents__table > ul {
    padding: 0 5px;
  }
}

.calendar-contents__table > p {
  margin-top: 10px;
  font-size: 1.4rem;
  font-weight: 400;
  color: #888;
}

.calendar-contents__m-list {
  margin-top: 50px;
  text-align: left;
  display: none;
}

.calendar-contents__m-list .c1:before {
  content: '빨강';
  background-color: #f05050;
}

.calendar-contents__m-list .c2:before {
  content: '노랑';
  background-color: #fbaa1d;
}

.calendar-contents__m-list .c3:before {
  content: '초록';
  background-color: #0f874a;
}

.calendar-contents__m-list .c4:before {
  content: '파랑';
  background-color: #5187f1;
}

.calendar-contents__m-list .c5:before {
  content: '검정';
  background-color: #000;
}

.calendar-contents__m-list h3 {
  height: 50px;
  background-color: #0f8940;
  border: 1px solid #0f8940;
  font-size: 1.6rem;
  font-weight: 500;
  color: #fff;
  line-height: 50px;
  text-align: center;
}

.calendar-contents__m-list ul {
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-top: none;
}

.calendar-contents__m-list li {
  margin-bottom: 10px;
}

.calendar-contents__m-list a {
  width: 100%;
  padding-left: 15px;
  font-size: 1.6rem;
  font-weight: 400;
  color: #444;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
}

.calendar-contents__m-list a:before {
  content: '';
  width: 8px;
  height: 8px;
  border-radius: 8px;
  display: inline-block;
  font-size: 0;
  position: absolute;
  top: 50%;
  left: 0;
  right: auto;
  transform: translateY(-50%);
}

@media (max-width: 991px) {
  .calendar-contents__m-list {
    display: block;
  }
}

.rental-list-contents__contents {
  display: none;
}

.rental-list-contents__contents.visible {
  display: block;
}

.rental-list-contents__selector {
  margin-bottom: 70px;
  text-align: center;
}

.rental-list-contents__selector li {
  margin: 0 10px;
  display: inline-block;
  vertical-align: top;
}

.rental-list-contents__selector a {
  width: 190px;
  height: 58px;
  border: 2px solid #f5f5f5;
  background-color: #f5f5f5;
  font-size: 1.6rem;
  font-weight: 500;
  color: #888;
  text-align: center;
  line-height: 54px;
}

.rental-list-contents__selector a.selected {
  border: 2px solid #0f8940;
  background-color: #fff;
  color: #0f8940;
  box-shadow: 3px 3px 10px 3px rgba(15, 137, 64, 0.2);
}

.rental-list-contents__tit {
  margin-bottom: 20px;
  position: relative;
}

.rental-list-contents__tit:after {
  content: '';
  display: block;
  clear: both;
}

.rental-list-contents__tit h3 {
  padding-left: 30px;
  display: inline-block;
  font-size: 2.2rem;
  font-weight: 700;
  color: #000;
  position: relative;
}

.rental-list-contents__tit h3:before {
  content: '';
  width: 10px;
  height: 10px;
  margin-top: 2px;
  border: 3px solid #0f8940;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 0;
  right: auto;
  transform: translateY(-50%);
}

@media (max-width: 767px) {
  .rental-list-contents__tit h3 {
    margin-bottom: 5px;
  }
}

.rental-list-contents__tit a {
  padding: 5px 10px;
  border: 1px solid #0f8940;
  display: inline-block;
  font-size: 1.6rem;
  font-weight: 700;
  color: #0f8940;
  float: right;
  transition-duration: .3s;
}

.rental-list-contents__tit a:hover {
  background-color: #0f8940;
  color: #fff;
}

.rental-list-contents__list {
  margin-bottom: 30px;
  padding: 30px;
  border: 1px solid #e5e5e5;
  position: relative;
}

.rental-list-contents__list:last-child {
  margin-bottom: 0;
}

.rental-list-contents__list__img {
  width: 350px;
  margin-right: 40px;
  display: inline-block;
  vertical-align: top;
}

@media (max-width: 1350px) {
  .rental-list-contents__list__img {
    width: 200px;
    margin-right: 20px;
  }
}

@media (max-width: 767px) {
  .rental-list-contents__list__img {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
  .rental-list-contents__list__img img {
    width: 100%;
  }
}

.rental-list-contents__list__info {
  width: calc(100% - 390px);
  display: inline-block;
  vertical-align: top;
}

.rental-list-contents__list__info h4 {
  height: 30px;
  margin-bottom: 10px;
  padding: 0 17px;
  border-radius: 30px;
  display: inline-block;
  line-height: 28px;
  font-size: 1.5rem;
  font-weight: 400;
  color: #000;
}

.rental-list-contents__list__info h4.c1 {
  border: 1px solid #0f8749;
  color: #0f8749;
}

.rental-list-contents__list__info h4.c2 {
  border: 1px solid #5088f0;
  color: #5088f0;
}

.rental-list-contents__list__info h4.c3 {
  border: 1px solid #1c1c1c;
  color: #1c1c1c;
}

@media (max-width: 767px) {
  .rental-list-contents__list__info h4 {
    font-size: 1.4rem;
  }
}

.rental-list-contents__list__info h3 {
  max-width: 100%;
  margin-bottom: 20px;
  display: inline-block;
  font-size: 2.4rem;
  font-weight: 700;
  color: #111;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.rental-list-contents__list__info h3:before {
  content: '';
  width: 100%;
  height: 15px;
  display: inline-block;
  background-color: #feeed2;
  position: absolute;
  top: auto;
  right: auto;
  bottom: 0;
  left: 0;
  z-index: -1;
}

@media (max-width: 767px) {
  .rental-list-contents__list__info h3 {
    font-size: 2rem;
  }
}

@media (max-width: 767px) {
  .rental-list-contents__list__info ul {
    margin-bottom: 15px;
  }
}

.rental-list-contents__list__info li {
  width: calc(100% - 140px);
  margin-bottom: 20px;
  padding-left: 95px;
  font-size: 1.6rem;
  font-weight: 400;
  color: #111;
  line-height: 1.2;
  position: relative;
}

.rental-list-contents__list__info li:last-child {
  margin-bottom: 0;
}

.rental-list-contents__list__info li:before {
  content: '';
  width: 5px;
  height: 5px;
  border-radius: 5px;
  display: inline-block;
  background-color: #0f8940;
  position: absolute;
  top: 7px;
  right: auto;
  bottom: auto;
  left: 0;
}

@media (max-width: 767px) {
  .rental-list-contents__list__info li:before {
    transform: none;
  }
}

.rental-list-contents__list__info li span {
  width: 80px;
  display: inline-block;
  font-weight: 700;
  position: absolute;
  top: 0;
  right: auto;
  bottom: auto;
  left: 15px;
}

@media (max-width: 767px) {
  .rental-list-contents__list__info li span {
    width: 100%;
    margin-bottom: 5px;
  }
}

@media (max-width: 1350px) {
  .rental-list-contents__list__info li {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .rental-list-contents__list__info li {
    font-size: 1.4rem;
  }
}

.rental-list-contents__list__info div {
  width: 140px;
  display: inline-block;
  position: absolute;
  top: auto;
  right: 30px;
  bottom: 30px;
  left: auto;
}

@media (max-width: 1350px) {
  .rental-list-contents__list__info div {
    width: 100%;
    margin-top: 15px;
    position: initial;
    transform: none;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
  }
}

@media (max-width: 767px) {
  .rental-list-contents__list__info div {
    width: 100%;
  }
}

.rental-list-contents__list__info a {
  width: 140px;
  height: 50px;
  background-color: #0f8940;
  font-size: 1.8rem;
  font-weight: 500;
  color: #fff;
  text-align: center;
  line-height: 48px;
}

@media (max-width: 1350px) {
  .rental-list-contents__list__info a {
    width: calc(50% - 5px);
    vertical-align: middle;
  }
}

@media (max-width: 767px) {
  .rental-list-contents__list__info a {
    width: 100%;
  }
}

@media (max-width: 1350px) {
  .rental-list-contents__list__info {
    width: calc(100% - 220px);
  }
}

@media (max-width: 767px) {
  .rental-list-contents__list__info {
    width: 100%;
  }
}

@media (max-width: 991px) {
  .rental-list-contents__list {
    padding: 15px;
  }
}

.rental-step-contents-tit {
  margin-bottom: 20px;
}

.rental-step-contents-tit h3 {
  padding-left: 30px;
  font-size: 2.2rem;
  font-weight: 700;
  color: #000;
  position: relative;
}

.rental-step-contents-tit h3:before {
  content: '';
  width: 10px;
  height: 10px;
  margin-top: 2px;
  border: 3px solid #0f8940;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 0;
  right: auto;
  transform: translateY(-50%);
}

.rental-step-contents-table {
  margin-bottom: 50px;
  word-break: break-all;
}

@media (max-width: 767px) {
  .rental-step-contents-table table {
    display: block;
  }
}

@media (max-width: 767px) {
  .rental-step-contents-table tbody {
    display: block;
  }
}

@media (max-width: 767px) {
  .rental-step-contents-table tr {
    display: block;
  }
}

.rental-step-contents-table th {
  text-align: center;
}

@media (max-width: 991px) {
  .rental-step-contents-table th {
    padding: 5px;
  }
}

@media (max-width: 767px) {
  .rental-step-contents-table th {
    display: block;
  }
}

.rental-step-contents-table td {
  font-size: 1.6rem;
  font-weight: 500;
  color: #333;
}

@media (max-width: 767px) {
  .rental-step-contents-table td {
    display: block;
  }
}

.rental-step-contents-table .bd-r {
  border-right: 1px solid #ddd;
}

@media (max-width: 767px) {
  .rental-step-contents-table .bd-r {
    border-right: none;
    border-bottom: 1px solid #000;
  }
}

.rental-step-contents-table input[type="number"] {
  max-width: 270px;
}

@media (max-width: 767px) {
  .rental-step-contents-table select {
    width: 100%;
  }
}

.rental-step-contents-table .address button {
  width: 100px;
  height: 40px;
  background-color: #ddd;
}

.rental-step-contents-table .sub-text {
  margin-top: 5px;
  color: #ccc;
}

.rental-step-contents-table .between-text {
  display: inline-block;
  vertical-align: middle;
  font-size: 1.6rem;
  font-weight: 500;
  color: #000;
}

.rental-step-contents-table label {
  cursor: text;
}

.rental-step-contents-table .address-num {
  width: 100px;
}

.rental-step-contents-table .bank-user-name {
  width: 120px;
}

@media (max-width: 767px) {
  .rental-step-contents-table .bank-user-name {
    width: 100%;
  }
}

.rental-step-contents-table .bank-name {
  width: 120px;
}

@media (max-width: 767px) {
  .rental-step-contents-table .bank-name {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .rental-step-contents-table .use-personnel {
    width: calc(100% - 18px);
  }
}

.rental-step-contents-terms {
  margin-bottom: 50px;
}

.rental-step-contents-terms h3 {
  margin-bottom: 10px;
  font-size: 2.4rem;
  font-weight: 700;
  color: #000;
}

@media (max-width: 767px) {
  .rental-step-contents-terms h3 {
    font-size: 2rem;
  }
}

.rental-step-contents-terms__wrap {
  padding: 20px 25px;
  border: 1px solid #e5e5e5;
  border-top: 3px solid #0f8940;
}

.rental-step-contents-terms__text {
  width: 100%;
  margin-bottom: 20px;
  padding-bottom: 20px;
  overflow-y: auto;
  border-bottom: 1px solid #eaeaea;
}

.rental-step-contents-terms__text h4 {
  margin-top: 20px;
  font-size: 2rem;
  font-weight: 700;
  color: #222;
}

@media (max-width: 767px) {
  .rental-step-contents-terms__text h4 {
    font-size: 1.8rem;
  }
}

.rental-step-contents-terms__text p {
  font-size: 1.6rem;
  font-weight: 700;
  color: #888;
  line-height: 2.4rem;
}

.rental-step-contents-terms__text p.terms-sub-title {
  margin-top: 30px;
  font-size: 1.8rem;
  font-weight: 700;
  color: #0f8940;
}

@media (max-width: 767px) {
  .rental-step-contents-terms__text p.terms-sub-title {
    font-size: 1.6rem;
  }
}

.rental-step-contents-terms__text p.terms-sub-title:first-child {
  margin-top: 0;
}

.rental-step-contents-terms__text p:last-child {
  margin-top: 30px;
}

.rental-step-contents-terms__text p b {
  font-weight: 900;
}

@media (max-width: 767px) {
  .rental-step-contents-terms__text p {
    font-size: 1.4rem;
  }
}

.rental-step-contents-terms__text ul {
  padding-left: 20px;
}

@media (max-width: 767px) {
  .rental-step-contents-terms__text ul {
    padding-left: 10px;
  }
}

.rental-step-contents-terms__text li {
  font-size: 1.6rem;
  font-weight: 700;
  color: #888;
  line-height: 2.4rem;
}

@media (max-width: 767px) {
  .rental-step-contents-terms__text li {
    font-size: 1.4rem;
  }
}

.rental-step-contents-terms__text table {
  width: 100%;
  table-layout: fixed;
}

.rental-step-contents-terms__text th {
  padding: 10px;
  border: 1px solid #ddd;
  background-color: #ddd;
  font-size: 1.6rem;
  font-weight: 700;
  color: #888;
  word-break: break-all;
}

@media (max-width: 767px) {
  .rental-step-contents-terms__text th {
    font-size: 1.4rem;
  }
}

.rental-step-contents-terms__text td {
  padding: 5px;
  border: 1px solid #ddd;
  font-size: 1.6rem;
  font-weight: 700;
  color: #888;
  text-align: center;
  vertical-align: middle;
  word-break: break-all;
}

.rental-step-contents-terms__text td.ta-l {
  text-align: left;
}

@media (max-width: 767px) {
  .rental-step-contents-terms__text td {
    font-size: 1.4rem;
  }
}

.rental-step-contents-terms__agree {
  text-align: center;
}

.rental-step-contents-terms__agree p {
  margin-bottom: 15px;
  font-size: 1.5rem;
  font-weight: 400;
  color: #5d5d5d;
}

.rental-step-contents-terms__agree input:checked + label:before {
  border: 2px solid #0f8940;
  background-color: #0f8940;
}

.rental-step-contents-terms__agree label {
  margin: 0 20px;
  padding-left: 30px;
  font-size: 1.7rem;
  font-weight: 700;
  color: #5d5d5d;
  text-align: left;
  position: relative;
}

.rental-step-contents-terms__agree label:before {
  content: '';
  width: 15px;
  height: 15px;
  border-radius: 20px;
  border: 2px solid #c1c1c1;
  position: absolute;
  top: 50%;
  left: 0;
  right: auto;
  transform: translateY(-50%);
}

@media (max-width: 767px) {
  .rental-step-contents-terms__agree label {
    margin: 5px 0;
    display: block;
    font-size: 1.4rem;
  }
}

@media (max-width: 767px) {
  .rental-step-contents-terms__agree {
    text-align: left;
  }
}

@media (max-width: 767px) {
  .rental-step-contents-terms {
    margin-top: 30px;
  }
}

.rental-step-contents-btn {
  text-align: center;
}

.rental-step-contents-btn a {
  width: 150px;
  height: 50px;
  border: 1px solid #0f8940;
  font-size: 1.6rem;
  font-weight: 500;
  color: #0f8940;
  line-height: 48px;
  transition-duration: .3s;
}

.rental-step-contents-btn a:hover {
  background-color: #0f8940;
  color: #fff;
}

.rental-step-contents-fi-text {
  margin-bottom: 50px;
  text-align: center;
}

.rental-step-contents-fi-text h3 {
  font-size: 3rem;
  font-weight: 700;
  color: #000;
}

.rental-calendar {
  margin-bottom: 60px;
}

.rental-calendar-contents-tit {
  margin-bottom: 20px;
}

.rental-calendar-contents-tit h3 {
  padding-left: 30px;
  font-size: 2.2rem;
  font-weight: 700;
  color: #000;
  position: relative;
}

.rental-calendar-contents-tit h3:before {
  content: '';
  width: 10px;
  height: 10px;
  margin-top: 2px;
  border: 3px solid #0f8940;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 0;
  right: auto;
  transform: translateY(-50%);
}

.rental-calendar-contents__selector {
  margin-bottom: 75px;
}

.rental-calendar-contents__selector__option-dn {
  width: 260px;
  height: 50px;
  margin-bottom: 10px;
  border: 2px solid #0f8940;
  padding: 0 20px;
  font-size: 2rem;
  font-weight: 700;
  color: #515456;
}

@media (max-width: 767px) {
  .rental-calendar-contents__selector__option-dn {
    width: 100%;
  }
}

.rental-calendar-contents__selector__text {
  height: 55px;
  margin-bottom: 45px;
  padding: 0 25px;
  background-color: #0f8940;
  font-size: 0;
  position: relative;
}

.rental-calendar-contents__selector__text h3 {
  display: inline-block;
  font-size: 2.6rem;
  font-weight: 700;
  color: #fff;
  line-height: 55px;
}

@media (max-width: 767px) {
  .rental-calendar-contents__selector__text h3 {
    display: block;
  }
}

.rental-calendar-contents__selector__text a {
  width: 200px;
  height: 35px;
  padding-left: 40px;
  border: 1px solid #fff;
  border-radius: 35px;
  font-size: 1.6rem;
  font-weight: 700;
  color: #fff;
  text-align: center;
  line-height: 31px;
  position: absolute;
  top: 50%;
  right: 25px;
  bottom: auto;
  left: auto;
  transform: translateY(-50%);
}

.rental-calendar-contents__selector__text a:before {
  content: '';
  width: 18px;
  height: 18px;
  display: inline-block;
  background-image: url("/template/resources/images/icons/re-icon.png");
  position: absolute;
  top: 50%;
  right: auto;
  bottom: auto;
  left: 22px;
  transform: translateY(-50%);
}

@media (max-width: 767px) {
  .rental-calendar-contents__selector__text a {
    margin-top: 15px;
    position: relative;
    top: auto;
    right: auto;
  }
}

@media (max-width: 991px) {
  .rental-calendar-contents__selector__text {
    margin-bottom: 0;
  }
}

@media (max-width: 767px) {
  .rental-calendar-contents__selector__text {
    height: auto;
    text-align: center;
  }
}

.rental-calendar-contents__selector__calender {
  width: 50%;
  padding: 0 60px;
  display: inline-block;
  border-right: 1px solid #d4d4d4;
  text-align: right;
  vertical-align: middle;
}

.rental-calendar-contents__selector__calender__year {
  margin-bottom: 50px;
}

.rental-calendar-contents__selector__calender__year h3 {
  font-size: 4.2rem;
  font-weight: 700;
  color: #515456;
  font-family: 'Jalnan';
  text-align: left;
  line-height: 1;
}

@media (max-width: 767px) {
  .rental-calendar-contents__selector__calender__year h3 {
    font-size: 3rem;
  }
}

.rental-calendar-contents__selector__calender__year .year-prev {
  margin-right: 10px;
}

.rental-calendar-contents__selector__calender__date table {
  width: 100%;
  margin-bottom: 50px;
  table-layout: fixed;
  text-align: center;
}

.rental-calendar-contents__selector__calender__date table th {
  padding-bottom: 20px;
  font-size: 2.4rem;
  font-weight: 700;
  color: #515456;
  font-family: 'Jalnan';
  line-height: 1;
}

@media (max-width: 767px) {
  .rental-calendar-contents__selector__calender__date table th {
    font-size: 1.6rem;
  }
}

.rental-calendar-contents__selector__calender__date table td {
  height: 80px;
  font-size: 2.4rem;
  font-weight: 700;
  color: #515456;
  vertical-align: middle;
}

.rental-calendar-contents__selector__calender__date table td:not(.calendar_date) {
  opacity: .5;
}

.rental-calendar-contents__selector__calender__date table td.full {
  opacity: .5;
  pointer-events: none;
}

.rental-calendar-contents__selector__calender__date table td a {
  font-size: 2.4rem;
  font-weight: 700;
  color: #515456;
  line-height: 1;
  position: relative;
}

.rental-calendar-contents__selector__calender__date table td a.disabled {
  opacity: .5;
  pointer-events: none;
}

.rental-calendar-contents__selector__calender__date table td a.selected {
  color: #fff;
}

.rental-calendar-contents__selector__calender__date table td a.selected:before {
  content: '';
  width: 50px;
  height: 50px;
  border-radius: 50px;
  background-color: #0f8940;
  position: absolute;
  top: 50%;
  right: auto;
  bottom: auto;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}

@media (max-width: 767px) {
  .rental-calendar-contents__selector__calender__date table td a.selected:before {
    width: 30px;
    height: 30px;
  }
}

.rental-calendar-contents__selector__calender__date table td a.on {
  color: #fff;
}

.rental-calendar-contents__selector__calender__date table td a.on:before {
  content: '';
  width: 50px;
  height: 50px;
  border-radius: 50px;
  background-color: #0f8940;
  position: absolute;
  top: 50%;
  right: auto;
  bottom: auto;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}

@media (max-width: 767px) {
  .rental-calendar-contents__selector__calender__date table td a.on:before {
    width: 30px;
    height: 30px;
  }
}

@media (max-width: 767px) {
  .rental-calendar-contents__selector__calender__date table td a {
    font-size: 1.6rem;
  }
}

@media (max-width: 767px) {
  .rental-calendar-contents__selector__calender__date table td {
    height: 60px;
    font-size: 1.6rem;
  }
}

.rental-calendar-contents__selector__calender__date p {
  padding-left: 25px;
  font-size: 2.2rem;
  font-weight: 500;
  color: #515456;
  text-align: left;
  position: relative;
  line-height: 1;
}

.rental-calendar-contents__selector__calender__date p:before {
  content: '';
  width: 16px;
  height: 16px;
  border-radius: 16px;
  display: inline-block;
  background-color: #0f8940;
  position: absolute;
  top: 50%;
  left: 0;
  right: auto;
  transform: translateY(-50%);
}

@media (max-width: 1650px) {
  .rental-calendar-contents__selector__calender {
    width: 60%;
    padding: 0 30px;
  }
}

@media (max-width: 991px) {
  .rental-calendar-contents__selector__calender {
    width: 100%;
    padding: 30px;
    border-right: none;
    border-bottom: 1px solid #d4d4d4;
  }
}

@media (max-width: 767px) {
  .rental-calendar-contents__selector__calender {
    padding: 30px 0;
  }
}

.rental-calendar-contents__selector__time {
  width: 50%;
  display: inline-block;
  vertical-align: middle;
}

.rental-calendar-contents__selector__time ul {
  width: 90%;
  max-width: 375px;
  margin: 0 auto;
}

.rental-calendar-contents__selector__time ul li {
  width: calc(50% - 7px);
  margin-bottom: 20px;
  margin-right: 14px;
  display: inline-block;
  font-size: 0;
  text-align: center;
}

.rental-calendar-contents__selector__time ul li:nth-child(2n) {
  margin-right: 0;
}

.rental-calendar-contents__selector__time ul li:nth-last-child(1) {
  margin-bottom: 0;
}

.rental-calendar-contents__selector__time ul li:nth-last-child(2) {
  margin-bottom: 0;
}

.rental-calendar-contents__selector__time ul li.full {
  opacity: .5;
  pointer-events: none;
}

.rental-calendar-contents__selector__time ul li a {
  width: 100%;
  height: 45px;
  border: 2px solid #c1c1c1;
  border-radius: 45px;
  font-size: 2rem;
  font-weight: 400;
  color: #515456;
  line-height: 41px;
}

.rental-calendar-contents__selector__time ul li a.disabled {
  opacity: .5;
  pointer-events: none;
}

.rental-calendar-contents__selector__time ul li a.selected {
  border: 2px solid #0f8940;
  background-color: #0f8940;
  color: #fff;
}

.rental-calendar-contents__selector__time ul li a.on {
  border: 2px solid #0f8940;
  background-color: #0f8940;
  color: #fff;
}

@media (max-width: 1250px) {
  .rental-calendar-contents__selector__time ul li {
    width: 100%;
    margin-right: 0;
    margin-bottom: 15px;
  }
  .rental-calendar-contents__selector__time ul li:nth-child(2n) {
    margin-right: 0;
  }
  .rental-calendar-contents__selector__time ul li:nth-child(4n) {
    margin-right: 0;
  }
  .rental-calendar-contents__selector__time ul li:nth-last-child(2) {
    margin-bottom: 15px;
  }
}

@media (max-width: 991px) {
  .rental-calendar-contents__selector__time ul li {
    width: calc((100% - 45px) / 4);
    margin-right: 15px;
  }
  .rental-calendar-contents__selector__time ul li:nth-child(2n) {
    margin-right: 15px;
  }
  .rental-calendar-contents__selector__time ul li:nth-child(4n) {
    margin-right: 0;
  }
  .rental-calendar-contents__selector__time ul li:nth-last-child(3) {
    margin-bottom: 0;
  }
  .rental-calendar-contents__selector__time ul li:nth-last-child(4) {
    margin-bottom: 0;
  }
}

@media (max-width: 767px) {
  .rental-calendar-contents__selector__time ul li {
    width: calc((100% - 15px) / 2);
  }
  .rental-calendar-contents__selector__time ul li:nth-child(2n) {
    margin-right: 0;
  }
  .rental-calendar-contents__selector__time ul li:nth-last-child(3) {
    margin-bottom: 20px;
  }
  .rental-calendar-contents__selector__time ul li:nth-last-child(4) {
    margin-bottom: 20px;
  }
}

@media (max-width: 320px) {
  .rental-calendar-contents__selector__time ul li {
    width: 100%;
    margin-right: 0;
  }
  .rental-calendar-contents__selector__time ul li:nth-last-child(2) {
    margin-bottom: 20px;
  }
  .rental-calendar-contents__selector__time ul li:nth-last-child(3) {
    margin-bottom: 20px;
  }
  .rental-calendar-contents__selector__time ul li:nth-last-child(4) {
    margin-bottom: 20px;
  }
}

@media (max-width: 1220px) {
  .rental-calendar-contents__selector__time ul {
    width: 100%;
    padding: 0 15px;
  }
}

@media (max-width: 767px) {
  .rental-calendar-contents__selector__time ul {
    padding: 0;
  }
}

.rental-calendar-contents__selector__time > a {
  width: 180px;
  height: 50px;
  margin-top: 80px;
  padding-right: 30px;
  border-radius: 50px;
  background-color: #0f8940;
  font-size: 2.6rem;
  font-weight: 700;
  color: #fff;
  font-family: 'Jalnan';
  text-align: center;
  line-height: 50px;
  position: relative;
}

.rental-calendar-contents__selector__time > a:after {
  content: '';
  width: 26px;
  height: 25px;
  display: inline-block;
  background-image: url("/template/resources/images/icons/btn-arrow-icon-w.png");
  position: absolute;
  top: 50%;
  right: 14px;
  bottom: auto;
  left: auto;
  transform: translateY(-50%);
}

@media (max-width: 767px) {
  .rental-calendar-contents__selector__time > a {
    width: 250px;
    font-size: 2.2rem;
  }
}

@media (max-width: 1650px) {
  .rental-calendar-contents__selector__time {
    width: 40%;
  }
}

@media (max-width: 991px) {
  .rental-calendar-contents__selector__time {
    width: 100%;
    padding-top: 30px;
  }
}

.rental-calendar-contents__selector__time__fixed-text {
  max-width: 375px;
  margin: 0 auto;
  margin-top: 30px;
  font-size: 1.6rem;
  font-weight: 500;
  color: #515456;
}

.rental-calendar-contents__selector__time__selected-time {
  max-width: 375px;
  margin: 0 auto;
  font-size: 2rem;
  font-weight: 500;
  color: #0f8940;
}

.rental-calendar-contents-list {
  margin-bottom: 20px;
}

.rental-calendar-contents-list li {
  margin: 5px;
  padding: 5px 10px;
  border: 1px solid #0f8940;
  border-radius: 30px;
  display: inline-block;
  font-size: 1.6rem;
  font-weight: 400;
  color: #515456;
}

.rental-calendar-contents-notice {
  margin-bottom: 15px;
  padding: 25px;
  border: 2px solid #0f8940;
  border-radius: 30px;
}

.rental-calendar-contents-notice__text {
  overflow: auto;
  font-size: 1.8rem;
  font-weight: 500;
  color: #515456;
  letter-spacing: -1px;
  line-height: 3rem;
}

@media (max-width: 767px) {
  .rental-calendar-contents-notice {
    margin-bottom: 5px;
    padding: 15px;
  }
}

.rental-calendar-contents-agree {
  margin-bottom: 50px;
  padding-right: 20px;
  text-align: right;
}

.rental-calendar-contents-agree h3 {
  display: inline-block;
  font-size: 1.8rem;
  font-weight: 400;
  color: #515456;
  letter-spacing: -1px;
  line-height: 1;
  vertical-align: middle;
}

@media (max-width: 767px) {
  .rental-calendar-contents-agree h3 {
    margin-bottom: 10px;
    display: block;
    font-size: 1.6rem;
  }
}

.rental-calendar-contents-agree input {
  position: absolute;
  left: -9999px;
}

.rental-calendar-contents-agree input:focus + label {
  outline: 2px dashed #0f78ad !important;
}

.rental-calendar-contents-agree input + label {
  margin-left: 15px;
  padding-left: 20px;
  font-size: 1.8rem;
  font-weight: 400;
  color: #515456;
  letter-spacing: -1px;
  line-height: 1;
  position: relative;
}

.rental-calendar-contents-agree input + label:before {
  content: '';
  width: 13px;
  height: 13px;
  border: 1px solid #515456;
  border-radius: 13px;
  display: inline-block;
  position: absolute;
  top: 7px;
  right: auto;
  bottom: auto;
  left: 0;
}

@media (max-width: 767px) {
  .rental-calendar-contents-agree input + label {
    font-size: 1.6rem;
  }
}

.rental-calendar-contents-agree input:checked + label:before {
  border: 1px solid #0f8940;
  background-color: #0f8940;
}

@media (max-width: 767px) {
  .rental-calendar-contents-agree {
    padding-right: 0;
  }
}

.rental-calendar-contents-btn {
  text-align: center;
}

.rental-calendar-contents-btn a {
  width: 150px;
  height: 50px;
  border: 1px solid #0f8940;
  font-size: 1.6rem;
  font-weight: 500;
  color: #0f8940;
  line-height: 48px;
  transition-duration: .3s;
}

.rental-calendar-contents-btn a:hover {
  background-color: #0f8940;
  color: #fff;
}

.rental-guide-contents__selector {
  margin-bottom: 70px;
  text-align: center;
}

.rental-guide-contents__selector li {
  margin: 0 10px;
  display: inline-block;
  vertical-align: top;
}

.rental-guide-contents__selector a {
  width: 190px;
  height: 58px;
  border: 2px solid #f5f5f5;
  background-color: #f5f5f5;
  font-size: 1.6rem;
  font-weight: 500;
  color: #888;
  text-align: center;
  line-height: 54px;
}

.rental-guide-contents__selector a.selected {
  border: 2px solid #0f8940;
  background-color: #fff;
  color: #0f8940;
  box-shadow: 3px 3px 10px 3px rgba(15, 137, 64, 0.2);
}

.rental-guide-contents__info {
  display: none;
}

.rental-guide-contents__info.visible {
  display: block;
}

.rental-guide-contents__info__map {
  margin-bottom: 65px;
  background-color: #f7f7f7;
  text-align: center;
}

.rental-guide-contents__info__map__tit {
  padding: 14px 0;
  background-color: #0f8940;
  font-size: 2.2rem;
  font-weight: 500;
  color: #fff;
  font-family: 'GmarketSans';
}

.rental-guide-contents__info__map__tit img {
  vertical-align: middle;
}

.rental-guide-contents__info__map__tit h3 {
  display: inline-block;
  vertical-align: middle;
}

.rental-guide-contents__info__map__selector {
  padding-top: 30px;
}

.rental-guide-contents__info__map__selector ul {
  position: relative;
}

.rental-guide-contents__info__map__selector ul:before {
  content: '';
  width: 1px;
  height: 14px;
  display: inline-block;
  background-color: #ddd;
  position: absolute;
  top: 6px;
  bottom: auto;
  left: 50%;
  transform: translateX(-50%);
}

.rental-guide-contents__info__map__selector li {
  margin: 0 30px;
  display: inline-block;
  vertical-align: middle;
}

.rental-guide-contents__info__map__selector a {
  width: 60px;
  padding-bottom: 10px;
  font-size: 1.8rem;
  font-weight: 500;
  color: #222;
  text-align: center;
  position: relative;
}

.rental-guide-contents__info__map__selector a.selected:after {
  content: '';
  width: 100%;
  height: 2px;
  display: inline-block;
  background-color: #0f8940;
  position: absolute;
  top: auto;
  right: auto;
  bottom: 0;
  left: 0;
}

.rental-guide-contents__info__map__img {
  margin-bottom: 65px;
  padding: 0 65px 65px;
  text-align: center;
  position: relative;
}

.rental-guide-contents__info__map__img__list {
  display: none;
  text-align: left;
}

.rental-guide-contents__info__map__img__list.visible {
  display: block;
}

.rental-guide-contents__info__map__img__list p {
  font-size: 10rem;
  font-weight: 700;
  color: #888;
  font-family: 'GmarketSans';
}

.rental-guide-contents__info__map__img__list img {
  display: block;
  margin: 0 auto;
}

.rental-guide-contents__info__table {
  overflow-x: auto;
}

@media (max-width: 767px) {
  .rental-guide-contents__info__table table {
    min-width: 800px;
  }
}

.rental-guide-contents__info__table table th {
  border-right: 1px solid #ddd;
}

.rental-guide-contents__info__table table th.b-l {
  border-left: 1px solid #ddd;
}

.rental-guide-contents__info__table table th.b-r {
  border-right: 1px solid #ddd;
}

.rental-guide-contents__info__table table td {
  border-right: 1px solid #ddd;
}

.rental-guide-contents__info__table table td:last-child {
  border-right: none;
}

.rental-guide-contents__info__table table td.b-l {
  border-left: 1px solid #ddd;
}

.rental-guide-contents__info__table table td.b-r {
  border-right: 1px solid #ddd;
}

.rental-rule-contents__selector {
  margin-bottom: 70px;
  text-align: center;
}

.rental-rule-contents__selector li {
  margin: 0 10px;
  display: inline-block;
  vertical-align: top;
}

.rental-rule-contents__selector a {
  width: 190px;
  height: 58px;
  border: 2px solid #f5f5f5;
  background-color: #f5f5f5;
  font-size: 1.6rem;
  font-weight: 500;
  color: #888;
  text-align: center;
  line-height: 54px;
}

.rental-rule-contents__selector a.selected {
  border: 2px solid #0f8940;
  background-color: #fff;
  color: #0f8940;
  box-shadow: 3px 3px 10px 3px rgba(15, 137, 64, 0.2);
}

.rental-rule-contents__info {
  display: none;
}

.rental-rule-contents__info.visible {
  display: block;
}

.rental-rule-contents__info__text h4 {
  font-size: 2.4rem;
  font-weight: 500;
  color: #0f8940;
}

.rental-rule-contents__info__text ul {
  width: 50%;
  display: inline-block;
  vertical-align: top;
}

@media (max-width: 991px) {
  .rental-rule-contents__info__text ul {
    width: 100%;
  }
}

.rental-rule-contents__info__text li {
  margin: 5px 0;
  font-size: 1.6rem;
  font-weight: 500;
  color: #222;
}

@media (max-width: 767px) {
  .rental-rule-contents__info__text li {
    font-size: 1.4rem;
  }
}

.rental-rule-contents__info__table {
  margin-bottom: 40px;
  overflow-x: auto;
}

.rental-rule-contents__info__table h4 {
  font-size: 2.4rem;
  font-weight: 500;
  color: #0f8940;
}

@media (max-width: 767px) {
  .rental-rule-contents__info__table table {
    min-width: 800px;
  }
}

.rental-rule-contents__info__table table th {
  border-right: 1px solid #ddd;
}

.rental-rule-contents__info__table table th:last-child {
  border-right: none;
}

.rental-rule-contents__info__table table th.b-l {
  border-left: 1px solid #ddd;
}

.rental-rule-contents__info__table table th.b-r {
  border-right: 1px solid #ddd;
}

.rental-rule-contents__info__table table td {
  border-right: 1px solid #ddd;
}

.rental-rule-contents__info__table table td:last-child {
  border-right: none;
}

.rental-rule-contents__info__table table td.b-l {
  border-left: 1px solid #ddd;
}

.rental-rule-contents__info__table table td.b-r {
  border-right: 1px solid #ddd;
}

.business-contents-year {
  position: absolute;
  top: 0;
  right: 0;
  bottom: auto;
  left: auto;
  z-index: 1;
}

@media (max-width: 767px) {
  .business-contents-year {
    margin-bottom: 20px;
    position: initial;
    transform: none;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
    text-align: right;
  }
}

.business-contents-year > * {
  height: 50px;
  border: 1px solid #e5e5e5;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
}

.business-contents-year a {
  width: 50px;
  position: relative;
}

.business-contents-year a:before {
  content: '';
  width: 9px;
  height: 9px;
  border-top: 2px solid #ccc;
  border-left: 2px solid #ccc;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.business-contents-year a.year-prev:before {
  transform: translate(-30%, -50%) rotate(-45deg);
}

.business-contents-year a.year-next:before {
  transform: translate(-70%, -50%) rotate(135deg);
}

.business-contents-year p {
  width: 130px;
  border-left: none;
  border-right: none;
  font-size: 1.6rem;
  font-weight: 500;
  color: #555;
  line-height: 48px;
  text-align: center;
}

.business-contents-year p img {
  margin-right: 10px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  bottom: 1px;
}

.business-contents-wrap {
  margin-bottom: 40px;
  padding-bottom: 50px;
  border-bottom: 1px solid #e5e5e5;
}

.business-contents-wrap:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

.business-contents-wrap__tit {
  margin-bottom: 15px;
}

.business-contents-wrap__tit h3 {
  font-size: 2.6rem;
  font-weight: 700;
  color: #000;
  font-family: 'GmarketSans';
  position: relative;
}

.business-contents-wrap__tit h3:before {
  content: '';
  width: 25px;
  height: 25px;
  border-radius: 25px;
  display: inline-block;
  background-color: #fdedd3;
  position: absolute;
  top: 0;
  right: auto;
  bottom: auto;
  left: -12px;
  z-index: -1;
}

.business-contents-wrap__list li {
  width: calc((100% - 21px) / 4);
  height: 160px;
  margin-right: 7px;
  margin-bottom: 7px;
  padding: 30px 20px 55px;
  border: 1px solid #e5e5e5;
  display: inline-block;
  vertical-align: top;
  position: relative;
  transition-duration: .3s;
}

@media (max-width: 1750px) {
  .business-contents-wrap__list li {
    height: 180px;
  }
}

@media (max-width: 1350px) {
  .business-contents-wrap__list li {
    height: 180px;
  }
}

@media (max-width: 1250px) {
  .business-contents-wrap__list li {
    width: calc((100% - 7px) / 2);
  }
}

@media (max-width: 767px) {
  .business-contents-wrap__list li {
    width: 100%;
    height: auto;
    padding-bottom: 65px;
  }
}

.business-contents-wrap__list li:hover {
  border: 1px solid #0f8940;
  box-shadow: 1px 1px 10px 1px rgba(1, 2, 2, 0.1);
}

.business-contents-wrap__list li:nth-child(4n) {
  margin-right: 0;
}

@media (max-width: 991px) {
  .business-contents-wrap__list li:nth-child(4n) {
    margin-right: 7px;
  }
}

@media (max-width: 767px) {
  .business-contents-wrap__list li:nth-child(4n) {
    margin-right: 0;
  }
}

@media (max-width: 1250px) {
  .business-contents-wrap__list li:nth-child(2n) {
    margin-right: 0;
  }
}

.business-contents-wrap__list li h4 {
  padding-left: 15px;
  font-size: 1.6rem;
  font-weight: 500;
  color: #000;
  position: relative;
}

@media (max-width: 1350px) {
  .business-contents-wrap__list li h4 {
    font-size: 1.4rem;
  }
}

.business-contents-wrap__list li h4:before {
  content: '';
  width: 7px;
  height: 7px;
  border-radius: 7px;
  display: inline-block;
  background-color: #0f8940;
  position: absolute;
  top: 9px;
  right: auto;
  bottom: auto;
  left: 0;
}

.business-contents-wrap__list li a {
  width: 100px;
  height: 36px;
  border: 1px solid #0f8940;
  font-size: 1.4rem;
  font-weight: 500;
  color: #0f8940;
  text-align: center;
  line-height: 34px;
  position: absolute;
  top: auto;
  right: 20px;
  bottom: 20px;
  left: auto;
  transition-duration: .3s;
}

.business-contents-wrap__list li a:hover {
  background-color: #0f8940;
  color: #fff;
}

.board-search {
  padding: 20px 35px;
  border: 3px solid #f5f5f5;
  border-top: 3px solid #0f8940;
}

@media (max-width: 1250px) {
  .board-search {
    padding: 20px;
  }
}

.board-search__img {
  width: 180px;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
}

@media (max-width: 991px) {
  .board-search__img {
    display: none;
  }
}

.board-search__form {
  width: calc(100% - 180px);
  display: inline-block;
  vertical-align: middle;
}

@media (max-width: 991px) {
  .board-search__form {
    width: 100%;
  }
}

.board-search__form p {
  margin-bottom: 15px;
  font-size: 1.6rem;
  font-weight: 400;
  color: #000;
}

.board-search__form p img {
  margin-right: 5px;
  vertical-align: middle;
}

.board-search__form form > * {
  display: inline-block;
  vertical-align: middle;
}

@media (max-width: 767px) {
  .board-search__form form > div {
    display: block;
  }
}

.board-search__form select {
  width: 150px;
  height: 42px;
  margin-right: 10px;
  border: 1px solid #d1d1d1;
  display: inline-block;
  font-size: 1.6rem;
  font-weight: 400;
  color: #000;
  vertical-align: middle;
}

@media (max-width: 1250px) {
  .board-search__form select {
    margin-bottom: 5px;
  }
}

@media (max-width: 767px) {
  .board-search__form select {
    width: 100%;
    margin-right: 0;
  }
}

.board-search__form input[type="text"] {
  width: 260px;
  height: 42px;
  margin-right: 10px;
  border: 1px solid #d1d1d1;
  display: inline-block;
  font-size: 1.6rem;
  font-weight: 400;
  color: #000;
  vertical-align: middle;
}

@media (max-width: 767px) {
  .board-search__form input[type="text"] {
    width: 100%;
    margin-right: 0;
    margin-bottom: 5px;
  }
}

.board-search__form a {
  width: 110px;
  height: 42px;
  display: inline-block;
  background-color: #3b3c3d;
  vertical-align: middle;
  position: relative;
}

@media (max-width: 767px) {
  .board-search__form a {
    width: 100%;
  }
}

.board-search__form a img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.search-bg {
  width: 100%;
  height: 100vh;
  display: none;
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  right: auto;
  bottom: auto;
  left: 0;
  z-index: 10;
}

@media (max-width: 991px) {
  .search-bg {
    display: none !important;
  }
}

.search-wrap {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  right: auto;
  transform: translateY(-50%);
  text-align: center;
}

.search-form {
  width: 100%;
  max-width: 500px;
  display: inline-block;
  position: relative;
}

.search-form form {
  width: 100%;
}

.search-form > * {
  display: inline-block;
  vertical-align: middle;
}

.search-form__close-btn {
  width: 30px;
  height: 30px;
  position: absolute;
  top: -60px;
  right: 0;
  bottom: auto;
  left: auto;
}

.search-form__close-btn:before {
  content: '';
  width: 30px;
  height: 2px;
  display: inline-block;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: translate(-50%, -50%) rotate(135deg);
}

.search-form__close-btn:after {
  content: '';
  width: 30px;
  height: 2px;
  display: inline-block;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: translate(-50%, -50%) rotate(-135deg);
}

.search-form input {
  width: calc(100% - 100px);
  max-width: 400px;
  height: 50px;
  border: none;
  border-bottom: 2px solid #0f8940;
  background-color: transparent;
  font-size: 1.8rem;
  font-weight: 500;
  color: #fff;
  vertical-align: middle;
  box-sizing: border-box;
}

.search-form input::placeholder {
  font-size: 1.8rem;
  font-weight: 500;
  color: #fff;
  text-align: center;
}

@media (max-width: 767px) {
  .search-form input {
    width: calc(100% - 60px);
  }
}

.search-form .search-btn {
  width: 100px;
  height: 50px;
  background-color: #0f8940;
  position: relative;
  vertical-align: middle;
}

@media (max-width: 767px) {
  .search-form .search-btn {
    width: 60px;
  }
}

.search-form .search-btn img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.mypage {
  padding-top: 50px;
}

@media (max-width: 767px) {
  .mypage {
    padding-top: 30px;
  }
}

.mypage-contents__l-area {
  width: calc(100% - 950px);
  padding: 40px;
  display: inline-block;
  background-color: #f8f8f8;
  vertical-align: top;
  font-family: 'GmarketSans';
}

@media (max-width: 1750px) {
  .mypage-contents__l-area {
    width: calc(100% - 790px);
  }
}

@media (max-width: 1550px) {
  .mypage-contents__l-area {
    width: calc(100% - 700px);
  }
}

@media (max-width: 1350px) {
  .mypage-contents__l-area {
    width: calc(100% - 500px);
    padding: 20px;
  }
}

@media (max-width: 1250px) {
  .mypage-contents__l-area {
    width: 100%;
    margin-bottom: 15px;
  }
}

.mypage-contents__l-area h3 {
  margin-bottom: 25px;
  font-size: 2.4rem;
  font-weight: 500;
  color: #222;
}

.mypage-contents__l-area p {
  margin-bottom: 5px;
  font-size: 1.8rem;
  font-weight: 500;
  color: #555;
}

.mypage-contents__l-area h4 {
  margin-bottom: 20px;
  font-size: 2.4rem;
  font-weight: 500;
  color: #222;
}

.mypage-contents__l-area h4.c-t {
  color: #0f8940;
}

.mypage-contents__l-area__btn {
  margin-top: 15px;
  padding-top: 20px;
  border-top: 1px solid #eaeaea;
}

.mypage-contents__l-area__btn a {
  width: 50%;
  height: 40px;
  display: inline-block;
  background-color: #eee;
  font-size: 1.5rem;
  font-weight: 500;
  color: #999;
  text-align: center;
  line-height: 38px;
  vertical-align: top;
}

.mypage-contents__l-area__btn a:first-of-type {
  background-color: #0f8940;
  color: #fff;
}

@media (max-width: 767px) {
  .mypage-contents__l-area__btn a {
    width: 100%;
  }
}

.mypage-contents__r-area {
  width: 910px;
  margin-left: 40px;
  padding-top: 10px;
  display: inline-block;
  vertical-align: top;
}

@media (max-width: 1750px) {
  .mypage-contents__r-area {
    width: 750px;
  }
}

@media (max-width: 1550px) {
  .mypage-contents__r-area {
    width: 660px;
  }
}

@media (max-width: 1350px) {
  .mypage-contents__r-area {
    width: 460px;
  }
}

@media (max-width: 1250px) {
  .mypage-contents__r-area {
    width: 100%;
    margin-left: 0;
  }
}

.mypage-contents__r-area__tit {
  margin-top: 40px;
  margin-bottom: 10px;
  font-family: 'GmarketSans';
}

.mypage-contents__r-area__tit:first-of-type {
  margin-top: 0;
}

.mypage-contents__r-area__tit h3 {
  font-size: 2rem;
  font-weight: 700;
  color: #222;
}

.mypage-contents__r-area__btn {
  margin-top: 40px;
  text-align: center;
}

.mypage-contents__r-area__btn a {
  width: 180px;
  height: 52px;
  margin: 0 5px;
  border: 1px solid #dfdfdf;
  font-size: 1.6rem;
  font-weight: 700;
  color: #888;
  text-align: center;
  line-height: 50px;
  transition-duration: .3s;
}

.mypage-contents__r-area__btn a:last-child {
  border: 1px solid #0f8940;
  font-size: 1.6rem;
  font-weight: 700;
  color: #0f8940;
}

.mypage-contents__r-area__btn a:last-child:hover {
  background-color: #0f8940;
  color: #fff;
}

.mypage-contents__r-area__btn a:hover {
  background-color: #dfdfdf;
  color: #fff;
}

@media (max-width: 767px) {
  .mypage-contents__r-area__btn a {
    width: 100%;
    margin: 5px 0;
  }
}

.mypage-contents__r-area__table {
  margin-bottom: 40px;
}

.mypage-contents__r-area__table a {
  width: 85px;
  height: 40px;
  border: 1px solid #dfdfdf;
  border-radius: 5px;
  font-size: 1.5rem;
  font-weight: 500;
  color: #000;
  text-align: center;
  line-height: 38px;
  transition-duration: .3s;
}

.mypage-contents__r-area__table a:hover {
  background-color: #dfdfdf;
  color: #fff;
}

@media (max-width: 767px) {
  .mypage-contents__r-area__table a {
    width: 100%;
    margin-top: 15px;
  }
}

.mypage-contents__r-area__table.type2 span {
  margin-bottom: 15px;
  padding: 10px 15px;
  border-bottom: 1px solid #ddd;
  background-color: #fffcf8;
  display: none;
  font-size: 1.6rem;
  font-weight: 700;
  color: #222;
}

@media (max-width: 767px) {
  .mypage-contents__r-area__table.type2 span {
    display: block;
  }
}

@media (max-width: 767px) {
  .mypage-contents__r-area__table.type2 td {
    padding: 0 0 15px;
    border-bottom: 1px solid #000;
  }
  .mypage-contents__r-area__table.type2 td:last-child {
    border-bottom: none;
  }
  .mypage-contents__r-area__table.type2 td:nth-last-child(2) {
    border-bottom: none;
  }
}

@media (max-width: 767px) {
  .mypage-contents__r-area__table table {
    display: block;
  }
  .mypage-contents__r-area__table thead {
    display: none;
  }
  .mypage-contents__r-area__table tbody {
    display: block;
  }
  .mypage-contents__r-area__table tr {
    display: block;
  }
  .mypage-contents__r-area__table th {
    display: block;
  }
  .mypage-contents__r-area__table td {
    display: block;
  }
}

.mypage-contents__r-area__info col {
  width: 215px;
}

.mypage-contents__r-area__info col:last-child {
  width: calc(100% - 215px);
}

.mypage-contents__r-area__info .id-confirm {
  width: 130px;
  height: 40px;
  margin-left: 5px;
  border: 1px solid #0f8940;
  font-size: 1.4rem;
  font-weight: 700;
  color: #0f8940;
  text-align: center;
  line-height: 38px;
  vertical-align: middle;
  transition-duration: .3s;
}

.mypage-contents__r-area__info .id-confirm:hover {
  background-color: #0f8940;
  color: #fff;
}

@media (max-width: 767px) {
  .mypage-contents__r-area__info .id-confirm {
    width: 100%;
    margin-left: 0;
  }
}

.mypage-contents__r-area__info .sub-text {
  margin-top: 5px;
  font-size: 1.5rem;
  font-weight: 500;
  color: #999;
}

@media (max-width: 767px) {
  .mypage-contents__r-area__info .sub-text {
    font-size: 1.3rem;
  }
}

.mypage-contents__r-area__info .between-text {
  display: inline-block;
  vertical-align: middle;
  font-size: 1.5rem;
  font-weight: 500;
  color: #000;
}

@media (max-width: 767px) {
  .mypage-contents__r-area__info .between-text {
    margin-left: 5px;
  }
}

@media (max-width: 767px) {
  .mypage-contents__r-area__info .global-board {
    width: 100%;
    display: inline-block;
  }
}

@media (max-width: 767px) {
  .mypage-contents__r-area__info tbody {
    width: 100%;
    display: inline-block;
  }
}

@media (max-width: 767px) {
  .mypage-contents__r-area__info tr {
    width: 100%;
    border-bottom: 1px solid #000;
    display: inline-block;
  }
}

@media (max-width: 767px) {
  .mypage-contents__r-area__info th {
    width: 100%;
    border-bottom: 1px solid #ddd;
    display: inline-block;
  }
}

@media (max-width: 767px) {
  .mypage-contents__r-area__info td {
    width: 100%;
    display: inline-block;
  }
}

@media (max-width: 767px) {
  .mypage-contents__r-area__info input {
    margin: 5px 0;
  }
}

@media (max-width: 767px) {
  .mypage-contents__r-area__info input:not([type="radio"]), .mypage-contents__r-area__info input:not([type="checkbox"]) {
    max-width: 100%;
  }
}

@media (max-width: 767px) {
  .mypage-contents__r-area__info input[type="number"] {
    max-width: 100%;
  }
}

@media (max-width: 767px) {
  .mypage-contents__r-area__info select {
    width: 100%;
    margin: 5px 0;
  }
}

@media (max-width: 767px) {
  .mypage-contents__r-area__info .birth select {
    width: calc(100% - 19px);
  }
}

@media (max-width: 767px) {
  .mypage-contents__r-area__info .phone select {
    width: calc(100% - 11px);
  }
}

@media (max-width: 767px) {
  .mypage-contents__r-area__info .phone input[type="number"] {
    max-width: calc(100% - 11px);
  }
}

.mypage-modify-fi {
  padding-top: 70px;
}

.mypage-modify-fi-contents__text {
  padding-top: 40px;
  border-top: 2px solid #0f8940;
  text-align: center;
}

.mypage-modify-fi-contents__text h3 {
  font-size: 2.4rem;
  font-weight: 500;
  color: #222;
}

.mypage-modify-fi-contents__text h3 span {
  font-weight: 700;
  color: #0f8940;
}

@media (max-width: 767px) {
  .mypage-modify-fi-contents__text h3 {
    font-size: 2rem;
  }
}

.mypage-modify-fi-contents__text p {
  font-size: 1.6rem;
  font-weight: 500;
  color: #666;
}

@media (max-width: 767px) {
  .mypage-modify-fi-contents__text p {
    font-size: 1.4rem;
  }
}

@media (max-width: 767px) {
  .mypage-modify-fi-contents__text {
    margin-top: 30px;
  }
}

.mypage-modify-fi-contents__btn {
  margin-top: 50px;
  padding-bottom: 40px;
  border-bottom: 1px solid #ddd;
  text-align: center;
}

.mypage-modify-fi-contents__btn a {
  width: 150px;
  height: 50px;
  margin-right: 10px;
  border: 1px solid #dfdfdf;
  font-size: 1.6rem;
  font-weight: 500;
  color: #888;
  line-height: 48px;
  transition-duration: .3s;
}

.mypage-modify-fi-contents__btn a:hover {
  background-color: #dfdfdf;
  color: #fff;
}

.mypage-modify-fi-contents__btn a:last-child {
  margin-right: 0;
  border: 1px solid #0f8940;
  color: #0f8940;
}

.mypage-modify-fi-contents__btn a:last-child:hover {
  background-color: #0f8940;
  color: #fff;
}

@media (max-width: 767px) {
  .mypage-modify-fi-contents__btn a {
    width: 50%;
    margin-right: 0;
  }
}

@media (max-width: 767px) {
  .mypage-modify-fi-contents__btn {
    margin-top: 30px;
  }
}

.input-pw {
  padding-top: 70px;
}

.input-pw-contents__text {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 40px 17px;
  border-top: 2px solid #0f8940;
  border-bottom: 1px solid #ddd;
}

@media (max-width: 767px) {
  .input-pw-contents__text {
    padding: 40px 0;
  }
}

.input-pw-contents__text h3 {
  margin-bottom: 30px;
  font-size: 2.4rem;
  font-weight: 500;
  color: #222;
  text-align: center;
}

.input-pw-contents__text h3 span {
  font-weight: 700;
  color: #0f8940;
}

@media (max-width: 767px) {
  .input-pw-contents__text h3 {
    font-size: 2rem;
  }
}

.input-pw-contents__text label {
  width: 91px;
  margin-right: 15px;
  display: inline-block;
  font-size: 1.6rem;
  font-weight: 500;
  color: #666;
}

@media (max-width: 767px) {
  .input-pw-contents__text label {
    width: 100%;
    margin-right: 0;
    margin-top: 20px;
    margin-bottom: 10px;
    display: block;
    text-align: left;
  }
}

.input-pw-contents__text input {
  width: 300px;
  height: 50px;
  margin: 5px 0;
  padding: 0 10px;
  border: 1px solid #dfdfdf;
  font-size: 1.6rem;
  font-weight: 500;
  color: #666;
}

@media (max-width: 767px) {
  .input-pw-contents__text input {
    width: 100%;
    margin: 0;
    font-size: 1.4rem;
  }
}

.input-pw-contents__text button {
  width: 150px;
  height: 50px;
  margin-left: 10px;
  border: 1px solid #0f8940;
  display: inline-block;
  font-size: 1.6rem;
  font-weight: 500;
  color: #0f8940;
  line-height: 48px;
  transition-duration: .3s;
}

.input-pw-contents__text button:hover {
  background-color: #0f8940;
  color: #fff;
}

@media (max-width: 767px) {
  .input-pw-contents__text button {
    width: 100%;
    margin-left: 0;
    margin-top: 10px;
  }
}

@media (max-width: 767px) {
  .input-pw-contents__text {
    margin-top: 30px;
  }
}

.terms-tit {
  margin-bottom: 20px;
  font-size: 3.6rem;
  font-weight: 700;
  color: #003554;
}

@media (max-width: 767px) {
  .terms-tit {
    margin-bottom: 10px;
    font-size: 2.2rem;
  }
}

.terms-top {
  margin-bottom: 75px;
  padding: 50px;
  border: 4px solid #9c7e29;
  text-align: center;
}

@media (max-width: 767px) {
  .terms-top {
    margin-bottom: 40px;
    padding: 30px 15px;
  }
}

.terms-top p {
  font-size: 1.8rem;
  font-weight: 500;
  color: #666;
  line-height: 2.8rem;
  margin-bottom: 20px;
}

@media (max-width: 767px) {
  .terms-top p {
    font-size: 1.4rem;
    line-height: 2.4rem;
  }
}

.terms-top p:last-child {
  margin-bottom: 0;
}

.terms-label {
  margin-bottom: 75px;
  text-align: center;
}

@media (max-width: 767px) {
  .terms-label {
    margin-bottom: 40px;
  }
}

.terms-label__list {
  padding: 80px 0 20px;
  border: 1px solid #ddd;
  text-align: left;
}

@media (max-width: 767px) {
  .terms-label__list {
    padding: 40px 0 20px;
  }
}

.terms-label__list li {
  width: calc(100% / 8);
  margin-bottom: 60px;
  display: inline-block;
  text-align: center;
  vertical-align: top;
}

@media (max-width: 1520px) {
  .terms-label__list li {
    width: calc(100% / 6);
  }
}

@media (max-width: 1120px) {
  .terms-label__list li {
    width: calc(100% / 5);
  }
}

@media (max-width: 991px) {
  .terms-label__list li {
    width: calc(100% / 4);
  }
}

@media (max-width: 767px) {
  .terms-label__list li {
    width: 50%;
    margin-bottom: 20px;
  }
}

.terms-label__img {
  width: 80px;
  height: 80px;
  border: 1px solid #003554;
  display: inline-block;
  position: relative;
}

.terms-label__img a {
  width: 100%;
  height: 100%;
  position: relative;
}

.terms-label__img img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.terms-label__text {
  margin-top: 20px;
  font-size: 1.6rem;
  font-weight: 500;
  color: #666;
}

@media (max-width: 991px) {
  .terms-label__text {
    font-size: 1.4rem;
  }
}

@media (max-width: 767px) {
  .terms-label__text {
    font-size: 1.2rem;
  }
}

.terms-info__wrap {
  margin-bottom: 65px;
}

@media (max-width: 767px) {
  .terms-info__wrap {
    margin-bottom: 40px;
  }
}

.terms-info p {
  font-size: 1.8rem;
  font-weight: 500;
  color: #666;
  line-height: 2.8rem;
}

@media (max-width: 767px) {
  .terms-info p {
    font-size: 1.4rem;
    line-height: 2.4rem;
  }
}

@media (max-width: 767px) {
  .terms .board-wrap.ofa table {
    width: 600px;
    min-width: 0;
  }
}

.terms .board-wrap th {
  height: 40px;
  font-size: 2rem;
  font-weight: 500;
  color: #333;
  line-height: 3rem;
}

@media (max-width: 767px) {
  .terms .board-wrap th {
    font-size: 1.6rem;
    line-height: 2.6rem;
  }
}

.terms .board-wrap td {
  padding: 10px 5px;
  font-size: 1.6rem;
  font-weight: 500;
  color: #666;
  line-height: 2.6rem;
}

@media (max-width: 767px) {
  .terms .board-wrap td {
    font-size: 1.4rem;
    line-height: 2.4rem;
  }
}

.terms .board-wrap a {
  font-size: 1.6rem;
}
