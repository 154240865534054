.faq{
  &-wrap{
    border-top: 2px solid #222;

    li{
      border-bottom: 1px solid #ddd;
    }
  }

  &-q{
    width: 100%;
    padding: 30px 40px;
    position: relative;

    @media (max-width: 767px) {
      padding: 15px 15px 30px;
    }

    &__mark{
      width: 50px;
      height: 50px;
      margin-right: 30px;
      border-radius: 50px;
      display: inline-block;
      background-color: #9c7e29;
      @include font(2.5rem, 700, #fff);
      text-align: center;
      line-height: 48px;
      vertical-align: middle;

      @media (max-width: 767px) {
        width: 30px;
        height: 30px;
        margin-right: 15px;
        font-size: 1.6rem;
        line-height: 28px;
      }
    }

    &__text{
      width: calc(100% - 80px);
      padding-right: 100px;
      display: inline-block;
      @include font(1.8rem, 500, #222);
      vertical-align: middle;
      position: relative;

      @media (max-width: 767px) {
        width: calc(100% - 45px);
        padding-right: 10px;
        font-size: 1.4rem;
        @include po-cancel;
      }

      &:after{
        content: '';
        width: 18px;
        height: 18px;
        border-top: 2px solid #999;
        border-left: 2px solid #999;
        @include middle(auto, 40px);
        transform: translateY(-70%) rotate(-135deg);

        @media (max-width: 767px) {
          width: 15px;
          height: 15px;
          @include center(auto, 10px);
          transform: translateX(-50%) rotate(-135deg);
        }
      }
    }
    
    &.click{
      .faq-q__text{
        &:after{
          transform: translateY(-30%) rotate(45deg);

          @media (max-width: 767px) {
            transform: translateX(-50%) translateY(8px) rotate(45deg);
          }
        }
      }
    }
  }

  &-a{
    height: 0;
    background-color: #f9f9f9;
    overflow: hidden;

    > div{
      padding: 40px;

      @media (max-width: 767px) {
        padding: 15px;
      }
    }

    &__mark{
      width: 50px;
      height: 50px;
      margin-right: 30px;
      border-radius: 50px;
      display: inline-block;
      background-color: #003554;
      @include font(2.5rem, 700, #fff);
      text-align: center;
      line-height: 48px;
      vertical-align: top;

      @media (max-width: 767px) {
        width: 30px;
        height: 30px;
        margin-bottom: 10px;
        font-size: 1.6rem;
        line-height: 28px;
      }
    }

    &__text{
      width: calc(100% - 80px);
      display: inline-block;
      vertical-align: top;

      p{
        @include font(1.8rem, 500, #222);

        @media (max-width: 767px) {
          font-size: 1.4rem;
        }
      }

      @media (max-width: 767px) {
        width: 100%;
      }
    }
  }
}