.en{
    &-main-container{
        width: 1440px;
        margin: 0 auto;
        padding: 0 15px;

        @media (max-width: 1550px) {
            width: 1200px;
        }

        @media (max-width: 1320px) {
            width: 960px;
        }

        @media (max-width: 991px) {
            width: 720px;
        }

        @media (max-width: 767px) {
            width: 100%;
        }

        .wrap{
            width: 100%;
            font-size: 0;
            position: relative;
        }
    }

    &-header{
        width: 100%;
        @include poa(0, auto, auto, 0);
        background-color: rgba(0, 0, 0, .8);
        z-index: 22;
        overflow: hidden;

        @media (max-width: 991px) {
            height: 115px;
            background-color: #000;
            @include pof(0, auto, auto, 0);
            overflow: unset;
        }

        @media (max-width: 767px) {
            height: 90px;
        }

        &-top{
            border-bottom: 1px solid rgba(255, 255, 255, .1);

            &__link{
                padding: 17px 0;
                text-align: right;

                @media (max-width: 767px) {
                    padding: 10px 0;
                }

                a{
                    @include font(1.3rem, 400, #fff);
                    vertical-align: middle;

                    @media (max-width: 767px) {
                        font-size: 1.2rem;
                    }
                }

                span{
                    width: 1px;
                    height: 8px;
                    margin: 0 20px;
                    display: inline-block;
                    background-color: #fff;
                    vertical-align: middle;

                    @media (max-width: 767px) {
                        margin: 0 15px;
                    }
                }
            }
        }

        &-bottom{
            position: relative;

            >.en-main-container{
                >.wrap{
                    position: initial;
                }
            }

            &__gnb-side{
                width: 30%;
                padding: 40px 15px 0 0;
                background-color: #f2f2f4;
                @include poa(100%, auto, auto, 0);
                background-image: url('/template/resources/images/cont/en-gnb-bg.png');
                background-size: cover;

                @media (max-width: 1720px) {
                    width: 25%;
                }

                @media (max-width: 991px) {
                    display: none;
                }

                h3{
                    display: inline-block;
                    @include font(2.3rem, 700, #111);
                    line-height: 2.9rem;
                }
            }

            &__logo{
                width: 247px;
                display: inline-block;
                vertical-align: middle;

                @media (max-width: 991px) {
                    width: calc(100% - 107px);
                    padding: 9px 0;
                }

                @media (max-width: 767px) {
                    width: calc(100% - 27px);
                }

                img{
                    @media (max-width: 767px) {
                        width: 130px;
                    }
                }
            }

            &__gnb{
                width: calc(100% - 494px);
                display: inline-block;
                vertical-align: middle;
                text-align: center;

                @media (max-width: 1320px) {
                    width: calc(100% - 374px);
                }

                @media (max-width: 991px) {
                    display: none;
                }

                .one-depth{
                    &__list{
                        margin: 0 30px;
                        display: inline-block;
                        vertical-align: middle;

                        @media (max-width: 1550px) {
                            margin: 0 20px;
                        }

                        @media (max-width: 1320px) {
                            margin: 0 15px;
                        }

                        >a{
                            p{
                                @include font(1.8rem, 700, #fff);
                                line-height: 80px;
                                position: relative;

                                @media (max-width: 1320px) {
                                    font-size: 1.6rem;
                                }
                            }
                        }

                        &:hover{
                            >a{
                                p{
                                    &:after{
                                        content: '';
                                        width: 100%;
                                        height: 5px;
                                        display: inline-block;
                                        background-color: #0078c7;
                                        @include poa(auto, auto, 0, 0);
                                    }
                                }
                            }
                        }
                    }
                }

                .two-depth{
                    padding: 25px 15px;
                    @include poa(80px, auto, auto, 0);
                    
                    &:after{
                        content: '';
                        width: 1px;
                        height: calc(100% - 50px);
                        border-left: 1px dashed #e2e3e6;
                        display: inline-block;
                        // @include poa(25px, 0, auto, auto);
                    }

                    &:last-child{
                        &:after{
                            display: none;
                        }
                    }

                    &__tit{
                        p{
                            margin-bottom: 20px;
                            @include font(1.6rem, 700, #222);
                        }
                    }

                    &__list{
                        margin-bottom: 15px;

                        &:last-child{
                            margin-bottom: 0;
                        }

                        a{
                            @include font(1.3rem, 400, #999);

                            &:hover{
                                font-weight: 700;
                                color: #0078c7;
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }

            &__lang{
                width: 100px;
                height: 80px;
                display: inline-block;
                vertical-align: middle;
                position: relative;
                      
                @media (max-width: 1420px) {
                  width: 90px;
                }
              
                @media (max-width: 1260px) {
                  width: 60px;
                }

                @media (max-width: 991px) {
                  height: 61px;
                }

                @media (max-width: 767px) {
                    display: none;
                }
              
                > a{
                    padding-right: 20px;
                    @include font(1.4rem, 700, #222);
                    @include middle(auto, 40px);
                    
                    &:after{
                      content: '';
                      width: 8px;
                      height: 8px;
                      border-top: 2px solid #fff;
                      border-right: 2px solid #fff;
                      display: inline-block;
                      @include middle(auto, 0);
                      transform: translateY(-70%) rotate(135deg);
                      box-sizing: border-box;
                    }
                  
                    @media (max-width: 1320px) {
                      font-size: 1.2rem;
                    }
                  
                    @media (max-width: 1260px) {
                      display: inline-block;
                      @include middle;
                    }
                }
              
                &__list{
                    height: 0;
                    border-radius: 5px;
                    background-color: #fff;
                    @include poa(60px, 40px, auto, auto);
                    overflow: hidden;
                    z-index: 1;
                
                    @media (max-width: 1260px) {
                      left: 0;
                      right: auto;
                    }
                
                    li{
                        padding: 3px 10px;
                        
                        &:first-child{
                          padding: 10px 10px 3px 10px;
                        }
                      
                        &:last-child{
                          padding: 3px 10px 10px 10px;
                        }
                    }
                
                    a{
                        @include font(1.4rem, 700, #222);

                        @media (max-width: 1320px) {
                          font-size: 1.2rem;
                        }
                    }
                }
            }

            &__all-btn{
                margin-left: 120px;
                display: inline-block;
                vertical-align: middle;

                @media (max-width: 1320px) {
                    margin-left: 0px;
                }

                @media (max-width: 991px) {
                    margin-left: 20px;
                }

                @media (max-width: 767px) {
                    margin-left: 0;
                }

                a{
                    width: 27px;
                    height: 23px;
                    position: relative;
                    
                    span{
                        width: 27px;
                        height: 3px;
                        display: inline-block;
                        background-color: #fff;
                        position: absolute;
                        left: 0;
                        transition-duration: .3s;

                        &:nth-child(1){
                            top: 0;
                        }

                        &:nth-child(2){
                            top: 10px;
                        }

                        &:nth-child(3){
                            top: 20px;
                        }
                    }

                    &.active{
                        span{
                            &:nth-child(1){
                                @include poa-center;
                                transform: translate(-50%, -50%) rotate(135deg);
                            }

                            &:nth-child(2){
                                @include poa-center;
                                transform: translate(-50%, -50%) scale(0);
                            }

                            &:nth-child(3){
                                @include poa-center;
                                transform: translate(-50%, -50%) rotate(45deg);
                            }
                        }
                    }
                }
            }
        }

        &.hover{
            height: auto;
            background-color: #fff;

            .en-header{
                &-top{
                    border-bottom: 1px solid #eee;
                    &__link{
                        a{
                            color: #111;
                        }

                        span{
                            background-color: #111;
                        }
                    }
                }

                &-bottom{
                    &:after{
                        content: '';
                        width: 100%;
                        height: 1px;
                        display: inline-block;
                        background-color: #eee;
                        @include poa(auto, auto, 0, 0);

                        @media (max-width: 991px) {
                            display: none;
                        }
                    }

                    &__logo{}

                    &__gnb{
                        .one-depth{
                            &__list{
                                >a{
                                    p{
                                        color: #111;
                                    }
                                }
                            }
                        }
                    }

                    &__lang{
                        >a{
                            &:after{
                                border-top: 2px solid #111;
                                border-right: 2px solid #111;
                            }
                        }
                    }

                    &__all-btn{
                        .en-all-btn{
                            span{
                                background-color: #111;
                            }
                        }
                    }
                }
            }
        }

        &__all-menu{
            width: 100%;
            height: 100vh;
            padding: 30px 0;
            @include pof(0, auto, auto, -100%);
            background-color: #000;
            overflow-y: auto;
            -ms-overflow-style: none;
            scrollbar-width: none;
            
            &::-webkit-scrollbar{
                display: none;
            }

            @media (max-width: 991px) {
                height: calc(100vh - 115px);
                top: 115px;
            }

            @media (max-width: 767px) {
                height: calc(100vh - 90px);
                top: 90px;
            }

            &.visible{
                left: 0;
            }

            &__logo{
                margin: 40px 0 20px;
                position: relative;

                @media (max-width: 991px) {
                    display: none;
                }
            }

            &__close{
                width: 30px;
                height: 30px;
                @include middle(auto, 0);

                &:before{
                    content: '';
                    width: 30px;
                    height: 3px;
                    display: inline-block;
                    background-color: #fff;
                    @include poa-center;
                    transform: translate(-50%, -50%) rotate(45deg);
                }

                &:after{
                    content: '';
                    width: 30px;
                    height: 3px;
                    display: inline-block;
                    background-color: #fff;
                    @include poa-center;
                    transform: translate(-50%, -50%) rotate(135deg);
                }
            }

            .one-depth{
                &__list{
                    width: calc(100% / 3);
                    margin-bottom: 30px;
                    padding-top: 15px;
                    border-top: 2px solid #fff;
                    display: inline-block;
                    vertical-align: top;

                    @media (max-width: 991px) {
                        width: 50%;
                    }

                    @media (max-width: 767px) {
                        width: 100%;
                        margin-bottom: 0;
                        padding: 15px 15px 0;
                    }

                    &:last-child{
                        margin-bottom: 0;
                    }

                    > a{
                        margin-bottom: 20px;

                        @media (max-width: 767px) {
                            margin-bottom: 10px;
                        }

                        p{
                            @include font(2.4rem, 700, #fff);

                            @media (max-width: 767px) {
                                font-size: 2rem;
                            }
                        }
                    }
                }
            }

            .two-depth{
                &__list{
                    padding-bottom: 10px;

                    > a{
                        margin-bottom: 10px;
                        @include font(1.8rem, 500, #fff);

                        @media (max-width: 767px) {
                            font-size: 1.6rem;
                        }
                    }
                }
            }

            .three-depth{
                padding-left: 15px;

                &__list{
                    margin-bottom: 5px;

                    a{
                        padding-left: 15px;
                        @include font(1.6rem, 500, #fff);
                        position: relative;

                        @media (max-width: 767px) {
                            font-size: 1.4rem;
                        }

                        &:before{
                            content: '';
                            width: 5px;
                            height: 5px;
                            display: inline-block;
                            background-color: #fff;
                            @include poa(8px, auto, auto, 0);
                        }
                    }
                }
            }
        }
    }

    &-quick{
        position: fixed;
        top: 50%;
        right: 45px;
        opacity: 0;
        transition-duration: .5s;
        z-index: 21;
        pointer-events: none;

        @media (max-width: 991px) {
            top: auto;
            right: 10px;
            bottom: 0;
        }

        &.visible{
            @include pof-middle(auto, 45px);
            opacity: 1;
            pointer-events: all;

            @media (max-width: 991px) {
                top: auto;
                right: 10px;
                bottom: 15px;
                transform: none;
            }
        }

        a{
            width: 67px;
            height: 67px;
            border-radius: 67px;
            display: block;
            background-color: #677377;
            position: relative;

            @media (max-width: 991px) {
                width: 50px;
                height: 50px;
            }

            &.en-quick__link{
                margin-bottom: 10px;
                background-color: #2d8ccf;
            }

            p{
                width: 100%;
                @include font(1.3rem, 700, #fff);
                text-align: center;
                @include middle;

                @media (max-width: 991px) {
                    font-size: 1.2rem;
                }
            }

            img{
                @include poa-center;
            }
        }
    }

    &-main-banner{
        overflow: hidden;
        position: relative;

        @media (max-width: 767px) {
            margin-top: 90px;
        }

        .slick-prev{
            display: none!important;
        }

        .item{
            width: 100%;

            a{
                width: 100%;
            }

            img{
                width: 100%;
            }
        }

        &__tools{
            width: 100%;
            @include poa(auto, auto, 85px, 0);

            @media (max-width: 991px) {
                bottom: 40px;
            }

            @media (max-width: 767px) {
                bottom: 15px;
            }

            &:after{
                content: '';
                display: block;
                clear: both;
            }

            &__wrap{
                font-size: 0;
            }

            &__next{
                width: 78px;
                height: 78px;
                border-radius: 78px;
                display: inline-block;
                background-color: #2d8ccf;
                font-size: 0;
                vertical-align: middle;
                position: relative;

                @media (max-width: 767px) {
                    display: none!important;
                }

                img{
                    @include poa-center;
                }
            }

            &__right{
                width: calc(100% - 78px);
                display: inline-block;
                vertical-align: middle;
                text-align: right;

                @media (max-width: 767px) {
                    width: 100%;
                }
            }

            &__dots{
                display: inline-block;
                vertical-align: middle;
            }

            &__paging{
                display: inline-block;
                vertical-align: middle;

                p{
                    margin: 0 7px 0 17px;
                    display: inline-block;
                    @include font(1.5rem, 400, #fff);
                    line-height: 1;
                    vertical-align: bottom;

                    @media (max-width: 767px) {
                        margin: 0 7px;
                        font-size: 1.2rem;
                    }
                }

                .current-num{
                    margin: 0;
                    @include font(7rem, 700, #fff);
                    font-family: 'NanumMyeongjo';

                    @media (max-width: 767px) {
                        font-size: 3rem;
                    }
                }

                .tot-num{
                    margin: 0;
                    color: rgba(255, 255, 255, .5);

                    @media (max-width: 767px) {
                        color: #fff;
                    }
                }
            }
        }
    }

    &-main{
        &-section1{
            padding: 50px 0 80px;

            @media (max-width: 767px) {
                padding: 30px 0;
            }
        }

        &-section3{
            background-image: url('/template/resources/images/cont/en-main-issue-bg.png');
            background-size: cover;
            background-position: center;
        }

        &-section5{
            padding: 80px 0;
            background-color: #f0282d;
            position: relative;

            @media (max-width: 767px) {
                padding: 30px 0;
            }

            &:before{
                content: '';
                width: 150px;
                height: 150px;
                border-top: 75px solid #fff;
                border-right: 75px solid transparent;
                border-bottom: 75px solid transparent;
                border-left: 75px solid #fff;
                box-sizing: border-box;
                @include poa(0, auto, auto, 0);

                @media (max-width: 767px) {
                    width: 80px;
                    height: 80px;
                    border-top: 40px solid #fff;
                    border-right: 40px solid transparent;
                    border-bottom: 40px solid transparent;
                    border-left: 40px solid #fff;
                }
            }
        }

        &-tit{
            margin-bottom: 40px;
            font-family: 'pretendard';

            @media (max-width: 767px) {
                margin-bottom: 15px;
            }

            h3{
                padding-right: 20px;
                display: inline-block;
                @include font(4.1rem, 700, #b2b2b2);
                line-height: 1;
                position: relative;

                @media (max-width: 767px) {
                    font-size: 3rem;
                }

                &:after{
                    content: '';
                    width: 8px;
                    height: 8px;
                    border-radius: 8px;
                    display: inline-block;
                    background-color: #2d8ccf;
                    @include poa(0, 0, auto, auto);
                }

                span{
                    color: #333f48;
                }
            }
        }

        &-notice{
            width: calc(50% - 15px);
            display: inline-block;
            vertical-align: top;
            letter-spacing: -1px;
            font-family: 'pretendard';
            font-size: 0;

            @media (max-width: 991px) {
                width: 100%;
            }

            &__tab{
                width: 140px;
                display: inline-block;
                vertical-align: top;

                @media (max-width: 767px) {
                    width: 100%;
                    margin-bottom: 15px;
                }

                a{
                    margin-bottom: 30px;
                    padding-right: 50px;
                    display: block;
                    @include font(2.1rem, 700, #90979c);
                    position: relative;

                    @media (max-width: 767px) {
                        width: calc(50% - 10px);
                        margin: 0 5px;
                        padding-right: 0;
                        display: inline-block;
                        background-color: #90979c;
                        font-size: 1.6rem;
                        color: #fff;
                        text-align: center;
                        vertical-align: top;
                        line-height: 3rem;

                        br{
                            display: none;
                        }
                    }

                    &:before{
                        content: '';
                        width: 29px;
                        height: 29px;
                        display: inline-block;
                        background-image: url('/template/resources/images/icons/en-main-news-icon.png');
                        @include poa(0, 0, auto, auto);

                        @media (max-width: 767px) {
                            display: none;
                        }
                    }

                    &:last-child{
                        margin-bottom: 0;
                    }

                    &.selected{
                        color: #2d8ccf;

                        @media (max-width: 767px) {
                            background-color: #2d8ccf;
                            color: #fff;
                        }

                        &:before{
                            background-image: url('/template/resources/images/icons/en-main-news-icon_active.png');
                        }
                    }
                }
            }

            &__list{
                width: calc(100% - 140px);
                padding-left: 50px;
                display: inline-block;
                vertical-align: top;
                position: relative;

                @media (max-width: 1320px) {
                    padding-left: 25px;
                }

                @media (max-width: 767px) {
                    width: 100%;
                    padding-left: 5px;
                }

                ul{
                    width: 100%;
                    display: none;

                    &.visible{
                        display: block;
                    }
                }

                li{
                    margin-bottom: 30px;

                    @media (max-width: 1320px) {
                        margin-bottom: 15px;
                    }
                }

                a{
                    max-width: 100%;
                }

                h3{
                    width: 100%;
                    margin-bottom: 10px;
                    padding-right: 50px;
                    @include font(1.8rem, 700, #727b81);
                    @include line-1;
                    line-height: 2.4rem;
                    position: relative;

                    @media (max-width: 1320px) {
                        margin-bottom: 5px;
                    }

                    span{
                        width: 23px;
                        height: 23px;
                        border-radius: 23px;
                        display: inline-block;
                        background-color: #ee542d;
                        font-size: 1.6rem;
                        color: rgba(255, 255, 255, .5);
                        text-align: center;
                        line-height: 2.3rem;
                        @include middle(auto, 0);
                    }
                }
                
                p{
                    @include font(1.4rem, 600, #333f48);
                }
            }
        }

        &-side-popup{
            width: calc(50% - 15px);
            margin-left: 30px;
            padding-left: 80px;
            display: inline-block;
            vertical-align: top;

            @media (max-width: 1550px) {
                padding-left: 30px;
            }

            @media (max-width: 1320px) {
                padding-left: 0;
            }

            @media (max-width: 991px) {
                width: 100%;
                margin-top: 50px;
                margin-left: 0;
            }

            @media (max-width: 767px) {
                margin-top: 30px;
            }

            &__tools{
                h3{
                    display: inline-block;
                    @include font(2.1rem, 700, #111);
                    vertical-align: middle;

                    @media (max-width: 767px) {
                        margin-bottom: 5px;
                        display: block;
                    }
                }

                >div{
                    width: calc(100% - 140px);
                    display: inline-block;
                    vertical-align: middle;
                    text-align: right;

                    @media (max-width: 767px) {
                        width: 100%;
                    }
                }
                
                &__paging{
                    margin-right: 25px;
                    display: inline-block;
                    vertical-align: middle;

                    p{
                        display: inline-block;
                        @include font(1.8rem, 500, #969696);
                        vertical-align: middle;

                        &.current-num{
                            color: #000;
                        }
                    }
                }

                a{
                    font-size: 0;
                    vertical-align: middle;
                    position: relative;
                }

                &__prev{
                    width: 8px;
                    height: 14px;

                    &:after{
                        content: '';
                        width: 10px;
                        height: 10px;
                        border-top: 2px solid #7a7d96;
                        border-right: 2px solid #7a7d96;
                        display: inline-block;
                        @include poa-center;
                        transform: translate(-30%, -50%) rotate(-135deg);
                        box-sizing: border-box;
                    }
                }

                &__pause{
                    width: 7px;
                    height: 12px;
                    margin: 0 14px;

                    &:after{
                        content: '';
                        width: 7px;
                        height: 12px;
                        border-left: 2px solid #333;
                        border-right: 2px solid #333;
                        display: inline-block;
                        @include poa-center;
                        box-sizing: border-box;
                    }
                }

                &__play{
                    width: 7px;
                    height: 12px;
                    margin: 0 14px;
                    display: none;
                
                    &:after {
                        content: '';
                        width: 7px;
                        height: 12px;
                        border-left: 7px solid #333;
                        border-top: 6px solid transparent;
                        border-bottom: 6px solid transparent;
                        border-right: none;
                        display: inline-block;
                        @include poa-center;
                        box-sizing: border-box;
                    }
                }

                &__next{
                    width: 8px;
                    height: 14px;

                    &:after{
                        content: '';
                        width: 10px;
                        height: 10px;
                        border-top: 2px solid #7a7d96;
                        border-right: 2px solid #7a7d96;
                        display: inline-block;
                        @include poa-center;
                        transform: translate(-70%, -50%) rotate(45deg);
                        box-sizing: border-box;
                    }
                }
            }

            &__slide{
                margin-top: 30px;

                @media (max-width: 767px) {
                    margin-top: 5px;
                }
            }
        }

        &-information{
            font-size: 0;
            position: relative;

            &__left{
                width: 50%;
                display: inline-block;
                vertical-align: top;
                position: relative;
                
                @media (max-width: 991px) {
                    height: 350px;
                    background-image: url('/template/resources/images/cont/en-main-meaning-bg.png');
                    background-size: cover;
                    background-position: center;
                    overflow: hidden;
                }

                @media (max-width: 767px) {
                    width: 100%;
                    height: 300px;
                }

                > img{
                    @media (max-width: 991px) {
                        display: none;
                    }
                }

                span{
                    width: 100%;
                    height: 100%;
                    display: none;
                    background-color: rgba(69, 131, 225, .85);
                    @include poa(0, auto, auto, 0);
                }

                &:hover{
                    span{display: inline-block;}
                }

                &:before{
                    content: '';
                    width: 1px;
                    height: 100%;
                    display: inline-block;
                    background-color: rgba(255, 255, 255, .3);
                    @include poa(0, auto, auto, 40px);

                    @media (max-width: 767px) {
                        display: none;
                    }
                }

                > div{
                    width: 100%;
                    padding-left: 180px;
                    @include middle;
                    z-index: 1;

                    @media (max-width: 1550px) {
                        padding-left: 150px;
                    }

                    @media (max-width: 1320px) {
                        padding-left: 120px;
                    }

                    @media (max-width: 1120px) {
                        padding-left: 105px;
                    }

                    @media (max-width: 991px) {
                        top: 60%;
                    }

                    @media (max-width: 767px) {
                        top: 50%;
                        padding: 15px;
                        text-align: center;
                    }


                    img{
                        margin-right: 60px;
                        vertical-align: middle;
                        
                        @media (max-width: 1550px) {
                            margin-right: 30px;
                        }

                        @media (max-width: 1120px) {
                            margin-right: 15px;
                        }

                        @media (max-width: 767px) {
                            display: none;
                        }
                    }
                }

                h4{
                    display: inline-block;
                    @include font(5rem, 700, transparent);
                    line-height: 1;
                    -webkit-text-stroke-width: 1px;
                    -webkit-text-stroke-color: #fff;
                    @include middle(-20px);
                    transform: translateY(-50%) rotate(-90deg);

                    @media (max-width: 1320px) {
                        font-size: 3rem;
                        left: 0;
                    }

                    @media (max-width: 767px) {
                        display: none;
                    }
                }
            }

            &__right{
                width: 50%;
                display: inline-block;
                vertical-align: top;
                position: relative;

                @media (max-width: 991px) {
                    height: 350px;
                    background-image: url('/template/resources/images/cont/en-main-history-bg.png');
                    background-size: cover;
                    background-position: center;
                    overflow: hidden;
                }

                @media (max-width: 767px) {
                    width: 100%;
                    height: 300px;
                }

                > img{
                    @media (max-width: 991px) {
                        display: none;
                    }
                }

                span{
                    width: 100%;
                    height: 100%;
                    display: none;
                    background-color: rgba(69, 131, 225, .85);
                    @include poa(0, auto, auto, 0);
                }

                &:hover{
                    span{display: inline-block;}
                }

                &:before{
                    content: '';
                    width: 1px;
                    height: 100%;
                    display: inline-block;
                    background-color: rgba(255, 255, 255, .3);
                    @include poa(0, 40px, auto, auto);

                    @media (max-width: 767px) {
                        display: none;
                    }
                }


                &:after{
                    content: '';
                    width: 185px;
                    height: 185px;
                    border-top: calc(185px / 2) solid transparent;
                    border-right: calc(185px / 2) solid #fff;
                    border-bottom: calc(185px / 2) solid #fff;
                    border-left: calc(185px / 2) solid transparent;
                    box-sizing: border-box;
                    @include poa(auto, 0, 0, auto);

                    @media (max-width: 1120px) {
                        width: 120px;
                        height: 120px;
                        border-top: 60px solid transparent;
                        border-right: 60px solid #fff;
                        border-bottom: 60px solid #fff;
                        border-left: 60px solid transparent;
                    }

                    @media (max-width: 767px) {
                        display: none;
                    }
                }

                > div{
                    width: 100%;
                    padding-right: 180px;
                    @include middle;
                    text-align: right;
                    z-index: 1;

                    @media (max-width: 1550px) {
                        padding-right: 150px;
                    }

                    @media (max-width: 1320px) {
                        padding-right: 120px;
                    }

                    @media (max-width: 1120px) {
                        padding-right: 105px;
                    }

                    @media (max-width: 991px) {
                        top: 60%;
                    }

                    @media (max-width: 767px) {
                        top: 50%;
                        padding: 15px;
                        text-align: center;
                    }


                    img{
                        margin-left: 60px;
                        vertical-align: middle;

                        @media (max-width: 1550px) {
                            margin-left: 30px;
                        }

                        @media (max-width: 1120px) {
                            margin-left: 15px;
                        }

                        @media (max-width: 767px) {
                            display: none;
                        }
                    }
                }

                h4{
                    display: inline-block;
                    @include font(5rem, 700, transparent);
                    line-height: 1;
                    -webkit-text-stroke-width: 1px;
                    -webkit-text-stroke-color: #fff;
                    @include middle(auto, 0);
                    transform: translateY(-50%) rotate(-90deg);

                    @media (max-width: 1320px) {
                        font-size: 3rem;
                        right: 20px;
                    }

                    @media (max-width: 1120px) {
                        right: 15px;
                    }

                    @media (max-width: 767px) {
                        display: none;
                    }
                }
            }

            &__center-text{
                text-align: center;
                @include poa-center;
                z-index: 1;

                @media (max-width: 991px) {
                    @include center(30px);
                }

                @media (max-width: 767px) {
                    @include poa-center;
                }

                h4{
                    margin-bottom: 15px;
                    @include font(3rem, 700, #fff);
                    line-height: 1;

                    @media (max-width: 1550px) {
                        margin-bottom: 10px;
                        font-size: 2.2rem;
                    }

                    @media (max-width: 1320px) {
                        margin-bottom: 5px;
                        font-size: 1.8rem;
                    }
                }

                h3{
                    @include font(5rem, 700, transparent);
                    line-height: 1;
                    -webkit-text-stroke-width: 1px;
                    -webkit-text-stroke-color: #fff;
                    
                    @media (max-width: 1550px) {
                        font-size: 4rem;
                    }

                    @media (max-width: 1320px) {
                        font-size: 3rem;
                    }
                }
            }

            &__text{
                width: 400px;
                height: 401px;
                padding: 92px 15px;
                display: inline-block;
                text-align: center;
                background-image: url('/template/resources/images/cont/en-main-information-text-bg.png');
                background-size: cover;
                background-position: center;
                vertical-align: middle;

                @media (max-width: 1720px) {
                    width: 300px;
                    height: 301px;
                    padding: 35px 21px;
                }

                @media (max-width: 1320px) {
                    width: 260px;
                    height: 261px;
                    padding: 25px 15px;
                }

                @media (max-width: 1120px) {
                    width: 201px;
                    height: 201px;
                }

                h3{
                    margin-bottom: 50px;
                    @include font(3rem, 700, #fff);
                    line-height: 1;

                    @media (max-width: 1720px) {
                        margin-bottom: 30px;
                        font-size: 2.4rem;
                    }

                    @media (max-width: 1320px) {
                        margin-bottom: 10px;
                        font-size: 2.2rem;
                    }

                    @media (max-width: 1120px) {
                        font-size: 2rem;
                    }
                }

                p{
                    margin-bottom: 20px;
                    @include font(1.5rem, 600, #fff);
                    letter-spacing: -1px;
                    line-height: 1.6;

                    @media (max-width: 1720px) {
                        font-size: 1.4rem;

                        br{
                            display: none;
                        }
                    }

                    @media (max-width: 1320px) {
                        line-height: 2.4rem;
                        @include line-clamp-ha(12rem, 2.4rem, 5);
                    }

                    @media (max-width: 1120px) {
                        max-height: 7.2rem;
                        line-height: 1.8rem;
                        margin-bottom: 10px;
                        -webkit-line-clamp: 4;
                    }
                }

                a{
                    @include font(1.5rem, 600, #fff);
                    text-decoration: underline;
                }
            }
        }

        &-issue{
            margin-top: 30px;
            padding: 100px 0 40px;
            position: relative;
            z-index: 1;

            @media (max-width: 767px) {
                margin-top: 0;
                padding: 50px 0 30px;
            }

            .en-main-tit{
                width: 50%;
                margin-bottom: 0;
                display: inline-block;
                vertical-align: middle;

                @media (max-width: 767px) {
                    width: 100%;
                    margin-bottom: 15px;
                }
            }

            &__text{
                width: 50%;
                display: inline-block;
                vertical-align: middle;
                text-align: right;

                @media (max-width: 767px) {
                    width: 100%;
                }

                p{
                    @include font(1.8rem, 600, #fff);

                    @media (max-width: 767px) {
                        font-size: 1.4rem;
                    }
                }
            }

            &__wrap{
                width: calc(100% + 20px);
                margin-left: -10px;
                margin-top: 50px;
                padding-bottom: 180px;
                position: relative;

                @media (max-width: 1320px) {
                    padding-bottom: 100px;
                }

                @media (max-width: 1120px) {
                    width: 100%;
                    margin-left: 0;
                }

                @media (max-width: 991px) {
                    padding-bottom: 60px;
                }
            }

            &__slide{
                .item{
                    margin: 0 10px;

                    &__text{
                        padding: 40px;
                        background-color: #2e2e2e;
                        border-top-left-radius: 10px;
                        border-top-right-radius: 10px;

                        h4{
                            margin-bottom: 10px;
                            @include font(1.4rem, 600, #d4262c);
                            letter-spacing: -1px
                        }

                        h3{
                            @include font(2.5rem, 700, #fff);
                            line-height: 1.6;
                            @include line-clamp(8rem, 1.6, 2);

                            @media (max-width: 1320px) {
                                height: 6.4rem;
                                font-size: 2.2rem;
                                line-height: 3.2rem;
                            }
                        }
                    }

                    &__img{
                        padding-bottom: 56.25%;
                        border-bottom-left-radius: 10px;
                        border-bottom-right-radius: 10px;
                        position: relative;
                        overflow: hidden;

                        // &:before{
                        //     content: '';
                        //     width: 83px;
                        //     height: 83px;
                        //     border-radius: 83px;
                        //     display: inline-block;
                        //     background-image: url('/template/resources/images/icons/en-main-issue-play.png');
                        //     background-size: cover;
                        //     @include poa-center;
                        // }

                        img{
                            width: 100%!important;
                            height: 100%!important;
                            @include poa(0, auto, auto, 0);
                        }

                        iframe{
                            width: 100%!important;
                            height: 100%!important;
                            @include poa(0, auto, auto, 0);
                        }
                    }
                }

                .slick-prev{
                    width: 21px;
                    height: 17px;
                    background-image: url('/template/resources/images/icons/en-main-issue-prev.png');
                    font-size: 0;
                    left: -50px;

                    @media (max-width: 1120px) {
                        left: -20px;
                    }

                    @media (max-width: 991px) {
                        display: none!important;
                    }

                    &:before{
                        display: none;
                    }
                }

                .slick-next{
                    width: 21px;
                    height: 17px;
                    background-image: url('/template/resources/images/icons/en-main-issue-next.png');
                    font-size: 0;
                    right: -50px;

                    @media (max-width: 1120px) {
                        right: -20px;
                    }

                    @media (max-width: 991px) {
                        display: none!important;
                    }

                    &:before{
                        display: none;
                    }
                }
            }

            &__tools{
                @include poa(auto, auto, 0, 10px);

                .slick-dots{
                    margin-left: -6px;

                    li{
                        margin: 0 6px;

                        button{
                            width: 13px;
                            height: 13px;
                            border-radius: 13px;
                            display: inline-block;
                            background-color: #686868;
                            font-size: 0;
                        }

                        &.slick-active{
                            button{
                                background-color: #fff;
                            }
                        }
                    }
                }
            }
        }

        &-text{
            margin-top: -140px;
            padding-bottom: 60px;

            @media (max-width: 1320px) {
                margin-top: -60px;
            }

            @media (max-width: 991px) {
                margin-top: 30px;
            }

            &__text{
                width: calc(100% - 705px);
                margin-bottom: 40px;
                display: inline-block;
                vertical-align: bottom;

                @media (max-width: 1320px) {
                    width: calc(100% - 500px);
                }

                @media (max-width: 991px) {
                    width: calc(100% - 300px);
                    margin-bottom: 20px;
                }

                @media (max-width: 767px) {
                    width: 100%;
                }

                h4{
                    margin-bottom: 20px;
                    @include font(1.8rem, 500, #888);

                    @media (max-width: 767px) {
                        margin-bottom: 10px;
                        font-size: 1.4rem;
                    }
                }

                h3{
                    @include font(3.1rem, 700, #111);
                    line-height: 3.5rem;

                    @media (max-width: 1550px) {
                        font-size: 2.6rem;
                        line-height: 3rem;
                    }

                    @media (max-width: 1320px) {
                        font-size: 2.4rem;
                        line-height: 2.8rem;
                    }

                    @media (max-width: 991px) {
                        font-size: 2.2rem;
                        line-height: 2.6rem;
                    }

                    @media (max-width: 767px) {
                        font-size: 1.8rem;
                        line-height: 2.2rem;

                        br:last-child{
                            display: none;
                        }
                    }
                }
            }

            &__img{
                width: 705px;
                display: inline-block;
                vertical-align: bottom;

                @media (max-width: 1320px) {
                    width: 500px;
                }

                @media (max-width: 991px) {
                    width: 300px;
                }

                @media (max-width: 767px) {
                    width: 100%;
                }
            }
        }

        &-history{
            &__img{
                width: 567px;
                display: inline-block;
                vertical-align: middle;

                @media (max-width: 1320px) {
                    width: 400px;
                }

                @media (max-width: 991px) {
                    width: 250px;
                }

                @media (max-width: 767px) {
                    width: 100%;
                    margin-bottom: 15px;
                    text-align: center;

                    img{
                        width: 60%;
                    }
                }
            }

            &__text{
                width: calc(100% - 567px);
                padding-left: 200px;
                display: inline-block;
                vertical-align: middle;

                @media (max-width: 1320px) {
                    width: calc(100% - 400px);
                    padding-left: 150px;
                }

                @media (max-width: 991px) {
                    width: calc(100% - 250px);
                    padding-left: 100px;
                }

                @media (max-width: 767px) {
                    width: 100%;
                    padding-left: 0;
                }

                h3{
                    margin-bottom: 30px;
                    @include font(4rem, 700, #fff);
                    line-height: 4.5rem;

                    @media (max-width: 1550px) {
                        font-size: 3.4rem;
                        line-height: 3.9rem;
                    }

                    @media (max-width: 1320px) {
                        font-size: 3.2rem;
                        line-height: 3.7rem;
                    }

                    @media (max-width: 991px) {
                        font-size: 2.8rem;
                        line-height: 3.3rem;
                    }

                    @media (max-width: 767px) {
                        margin-bottom: 15px;
                        font-size: 2.4rem;
                        line-height: 2.9rem;

                        br{
                            display: none;
                        }
                    }
                }

                p{
                    margin-bottom: 70px;
                    @include font(1.8rem, 500, #fff);
                    line-height: 1.5;

                    @media (max-width: 991px) {
                        margin-bottom: 50px;
                        font-size: 1.6rem;
                    }
                }

                a{
                    width: 190px;
                    height: 50px;
                    border-radius: 5px;
                    background-color: #fff;
                    @include font(1.6rem, 300, #000);
                    text-align: center;
                    line-height: 50px;

                    @media (max-width: 767px) {
                        width: 100%;
                    }
                }
            }
        }

        &-site{
            padding: 25px 0;

            &__tools{
                width: 135px;
                display: inline-block;
                vertical-align: middle;
                text-align: right;

                @media (max-width: 767px) {
                    width: 68px;
                }

                a{
                    width: 32px;
                    height: 32px;
                    margin-left: 4px;
                    border-radius: 5px;
                    border: 1px solid #ebebeb;
                    position: relative;

                    &:first-child{
                        margin-left: 0;
                    }
                }
            }

            &__prev{
                &:before{
                    content: '';
                    width: 9px;
                    height: 9px;
                    display: inline-block;
                    border-top: 3px solid #000;
                    border-left: 3px solid #000;
                    @include poa-center;
                    transform: translate(-30%, -50%) rotate(-45deg);
                    box-sizing: border-box;
                }
            }

            &__pause{
                @media (max-width: 767px) {
                    display: none;
                }

                &:before{
                    content: '';
                    width: 8px;
                    height: 8px;
                    display: inline-block;
                    background-color: #000;
                    @include poa-center;
                }
            }

            &__play{
                display: none;

                &:before{
                    content: '';
                    width: 7px;
                    height: 10px;
                    display: inline-block;
                    border-top: 5px solid transparent;
                    border-left: 7px solid #000;
                    border-bottom: 5px solid transparent;
                    border-right: none;
                    @include poa-center;
                    box-sizing: border-box;
                }
            }

            &__next{
                &:before{
                    content: '';
                    width: 9px;
                    height: 9px;
                    display: inline-block;
                    border-top: 3px solid #000;
                    border-left: 3px solid #000;
                    @include poa-center;
                    transform: translate(-70%, -50%) rotate(135deg);
                    box-sizing: border-box;
                }
            }

            &__slide{
                width: calc(100% - 135px);
                display: inline-block;
                vertical-align: middle;
                overflow: hidden;

                @media (max-width: 767px) {
                    width: calc(100% - 68px);
                }
            }
        }
    }

    &-footer{
        padding: 50px 0;
        background-color: #000;

        @media (max-width: 767px) {
            padding: 30px 0;
        }

        &__logo{
            width: 153px;
            display: inline-block;
            vertical-align: middle;

            @media (max-width: 767px) {
                width: 100%;
            }
        }

        &__info{
            width: calc(100% - 338px);
            padding-left: 40px;
            display: inline-block;
            vertical-align: middle;

            @media (max-width: 767px) {
                width: 100%;
                margin: 10px 0;
                padding-left: 0;
            }

            p{
                margin-top: 10px;
                @include font(1.4rem, 300, #fff);

                &:first-child{
                    margin-bottom: 0;
                }
            }
        }

        &__sns{
            display: inline-block;
            vertical-align: middle;

            @media (max-width: 767px) {
                display: block;
                text-align: right;
            }

            a{
                margin-left: 15px;
                vertical-align: middle;

                &:first-child{
                    margin-left: 0;
                }
            }
        }
    }

    &-sub{
        &-banner{
            height: 435px;
            background-size: cover;
            background-position: center;
            position: relative;

            @media (max-width: 991px) {
                height: 350px;
            }

            @media (max-width: 767px) {
                height: 200px;
            }

            &.bg1{
                background-image: url('/template/resources/images/cont/en-sub-banner-bg.png');
            }

            &__text{
                width: 100%;
                text-align: center;
                @include middle;
                transform: translateY(-10%);

                @media (max-width: 991px) {
                    transform: translateY(-30%);
                }

                @media (max-width: 767px) {
                    transform: translateY(0);
                }

                h3{
                    margin-bottom: 10px;
                    @include font(3rem, 700, #fff);

                    @media (max-width: 767px) {
                        font-size: 2rem;
                    }
                }

                li{
                    display: inline-block;
                    @include font(1.3rem, 400, rgba(255, 255, 255, .5));
                    font-family: 'NanumBarunGothic';
                    vertical-align: middle;
                    position: relative;

                    &:after{
                        content: '';
                        width: 6px;
                        height: 6px;
                        margin: 0 10px;
                        border-top: 1px solid #fff;
                        border-right: 1px solid #fff;
                        display: inline-block;
                        box-sizing: border-box;
                        vertical-align: middle;
                        transform: rotate(45deg);
                    }

                    &:last-child{
                        &:after{
                            display: none;
                        }
                    }
                }
            }

            &__nav{
                width: 100%;
                background-color: #1f1f1f;
                text-align: center;
                @include poa(auto, auto, 0, 0);

                @media (max-width: 767px) {
                    display: none;
                }

                li{
                    padding: 0 15px;
                    display: inline-block;
                    vertical-align: middle;

                    a{
                        @include font(1.5rem, 700, #fff);
                        line-height: 77px;
                        font-family: 'NanumBarunGothic';
                        position: relative;

                        @media (max-width: 1400px) {
                            line-height: 60px;
                        }

                        @media (max-width: 991px) {
                            font-size: 1.4rem;
                            line-height: 50px;
                        }

                        &.selected{
                            &:after{
                                content: '';
                                width: 100%;
                                height: 5px;
                                display: inline-block;
                                background-color: #0078c7;
                                @include poa(auto, auto, 0, 0);
                            }
                        }
                    }
                }
            }
        }

        &-top{
            padding: 60px 0 0;

            @media (max-width: 767px) {
                padding: 30px 0 0;
            }

            &__tit{
                text-align: center;

                h3{
                    @include font(3rem, 700, #111);

                    @media (max-width: 767px) {
                        font-size: 2.4rem;
                    }
                }
            }

            &__tab{
                margin-top: 40px;
                text-align: center;

                @media (max-width: 767px) {
                    margin-top: 20px;
                }

                li{
                    margin: 0 20px 10px;
                    display: inline-block;
                    vertical-align: middle;

                    @media (max-width: 767px) {
                        width: 100%;
                        margin: 0;
                    }

                    a{
                        padding-bottom: 20px;
                        @include font(1.9rem, 400, #999);
                        font-family: 'NanumBarunGothic';
                        position: relative;

                        @media (max-width: 767px) {
                            width: 100%;
                            padding: 5px;
                            font-size: 1.6rem;
                            text-align: center;
                        }

                        &.selected{
                            color: #111;

                            @media (max-width: 767px) {
                                background-color: #111;
                                color: #fff;
                            }

                            &:after{
                                content: '';
                                width: 100%;
                                height: 5px;
                                display: inline-block;
                                background-color: #111;
                                @include poa(auto, auto, 0, 0);

                                @media (max-width: 767px) {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }

        &-contents{
            padding: 80px 0;

            @media (max-width: 991px) {
                padding: 40px 0;
            }

            @media (max-width: 767px) {
                padding: 30px 0;
            }
        }
    }
}

.gnb-bg {
    width: 100%;
    height: 0px;
    display: block;
    transition-duration: .3s;
}