.login{
  &-contents{
    position: relative;

    // &__bg{width: 100%;
    //   height: 450px;
    //   background-image: url("/template/resources/images/cont/login-bg.png");
    //   background-size: cover;
    //   background-repeat: no-repeat;
    //   @include poa(0, auto, auto ,0);
    //   z-index: -1;
    // }

    &__tit{
      margin-bottom: 45px;
      text-align: center;

      h3{
        margin-bottom: 20px;
        @include font(3.2rem, 500, #000);
        font-family: 'GmarketSans';

        span{
          font-weight: 700;
        }

        @media (max-width: 767px) {
          font-size: 2.4rem;
        }
      }

      p{
        @include font(1.6rem, 400, #888);
        line-height: 2.4rem;

        @media (max-width: 767px) {
          font-size: 1.4rem;
        }
      }
    }

    &__info{
      width: 100%;
      max-width: 1000px;
      margin: 0 auto;
      padding: 60px 100px 80px;
      border-top: 5px solid #0f8940;
      border-bottom-left-radius: 30px;
      border-bottom-right-radius: 30px;
      background-color: #fff;
      box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, .1);

      @media (max-width: 1250px) {
        padding: 60px 50px 80px;
      }

      @media (max-width: 767px) {
        padding: 60px 15px;
      }

      form{
        margin-bottom: 50px;
        font-family: 'GmarketSans';

        @media (max-width: 767px) {
          margin-bottom: 30px;
        }
      }

      &__user{
        width: calc(100% - 170px);
        margin-right: 20px;
        display: inline-block;
        vertical-align: top;

        label{
          width: 70px;
          height: 75px;
          border: 1px solid #dfdfdf;
          border-right: none;
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
          display: inline-block;
          vertical-align: top;
          position: relative;

          img{
            @include poa-center;

            @media (max-width: 767px) {
              width: 40%;
            }
          }

          @media (max-width: 767px) {
            width: 50px;
            height: 50px;
          }
        }

        input{
          width: calc(100% - 70px);
          height: 75px;
          border: 1px solid #dfdfdf;
          border-left: none;
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
          @include font(1.8rem, 500, #000);

          &::placeholder{
            color: #999;
          }

          @media (max-width: 767px) {
            width: calc(100% - 50px);
            height: 50px;
          }
        }

        &__id{
          margin-bottom: 10px;
        }

        @media (max-width: 991px) {
          width: 100%;
          margin-right: 0;
          margin-bottom: 20px;
        }
      }

      &__btn{
        width: 150px;
        display: inline-block;
        vertical-align: top;

        button{
          width: 100%;
          height: 160px;
          background-color: #0f8940;
          @include font(2.2rem, 500, #fff);

          @media (max-width: 991px) {
            height: 50px;
          }
        }

        @media (max-width: 991px) {
          width: 100%;
        }
      }
    }

    &__btn{
      text-align: center;

      a{
        @include font(1.6rem, 400, #333);
        vertical-align: middle;

        &:hover{
          color: #0f8940;
        }

        @media (max-width: 767px) {
          font-size: 1.4rem;
        }
      }

      span{
        width: 1px;
        height: 15px;
        margin: 0 12px;
        display: inline-block;
        background-color: #bebebe;
        vertical-align: middle;

        @media (max-width: 767px) {
          margin: 0 5px;
        }
      }
    }

    @media (max-width: 767px) {
      padding-top: 30px;
      padding-bottom: 0;
    }
  }
}
