.rental{
  &-list{
    &-contents{
      &__contents{
        display: none;

        &.visible{
          display: block;
        }
      }

      &__selector{
        margin-bottom: 70px;
        text-align: center;

        li{
          margin: 0 10px;
          display: inline-block;
          vertical-align: top;
        }

        a{
          width: 190px;
          height: 58px;
          border: 2px solid #f5f5f5;
          background-color: #f5f5f5;
          @include font(1.6rem, 500, #888);
          text-align: center;
          line-height: 54px;

          &.selected{
            border: 2px solid #0f8940;
            background-color: #fff;
            color: #0f8940;
            box-shadow: 3px 3px 10px 3px rgba(15, 137, 64, .2);
          }
        }
      }

      // &__tit{
      //   margin-bottom: 20px;
      //
      //   h3{
      //     padding-left: 30px;
      //     @include font(2.2rem, 700, #000);
      //     position: relative;
      //
      //     &:before{
      //       content: '';
      //       width: 10px;
      //       height: 10px;
      //       margin-top: 2px;
      //       border: 3px solid #0f8940;
      //       display: inline-block;
      //       @include middle;
      //     }
      //   }
      // }

      &__tit{
        margin-bottom: 20px;
        position: relative;

        &:after{
          content: '';
          display: block;
          clear: both;
        }

        h3{
          padding-left: 30px;
          display: inline-block;
          @include font(2.2rem, 700, #000);
          position: relative;

          &:before{
            content: '';
            width: 10px;
            height: 10px;
            margin-top: 2px;
            border: 3px solid #0f8940;
            display: inline-block;
            @include middle;
          }

          @media (max-width: 767px) {
            margin-bottom: 5px;
          }
        }

        a{
          padding: 5px 10px;
          border: 1px solid #0f8940;
          display: inline-block;
          @include font(1.6rem, 700, #0f8940);
          float: right;
          transition-duration: .3s;

          &:hover{
            background-color: #0f8940;
            color: #fff;
          }
        }
      }

      &__list{
        margin-bottom: 30px;
        padding: 30px;
        border: 1px solid #e5e5e5;
        position: relative;

        &:last-child{
          margin-bottom: 0;
        }

        &__img{
          width: 350px;
          margin-right: 40px;
          display: inline-block;
          vertical-align: top;

          @media (max-width: 1350px) {
            width: 200px;
            margin-right: 20px;
          }

          @media (max-width: 767px) {
            width: 100%;
            margin-right: 0;
            margin-bottom: 20px;

            img{
              width: 100%;
            }
          }
        }

        &__info{
          width: calc(100% - 390px);
          display: inline-block;
          vertical-align: top;

          h4{
            height: 30px;
            margin-bottom: 10px;
            padding: 0 17px;
            border-radius: 30px;
            display: inline-block;
            line-height: 28px;
            @include font(1.5rem, 400, #000);

            &.c1{
              border: 1px solid #0f8749;
              color: #0f8749;
            }

            &.c2{
              border: 1px solid #5088f0;
              color: #5088f0;
            }

            &.c3{
              border: 1px solid #1c1c1c;
              color: #1c1c1c;
            }

            @media (max-width: 767px) {
              font-size: 1.4rem;
            }
          }

          h3{
            max-width: 100%;
            margin-bottom: 20px;
            display: inline-block;
            @include font(2.4rem, 700, #111);
            position: relative;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            &:before{
              content: '';
              width: 100%;
              height: 15px;
              display: inline-block;
              background-color: #feeed2;
              @include poa(auto, auto, 0, 0);
              z-index: -1;
            }

            @media (max-width: 767px) {
              font-size: 2rem;
            }
          }

          ul{
            @media (max-width: 767px) {
              margin-bottom: 15px;
            }
          }

          li{
            width: calc(100% - 140px);
            margin-bottom: 20px;
            padding-left: 95px;
            @include font(1.6rem, 400, #111);
            line-height: 1.2;
            position: relative;

            &:last-child{
              margin-bottom: 0;
            }

            &:before{
              content: '';
              width: 5px;
              height: 5px;
              border-radius: 5px;
              display: inline-block;
              background-color: #0f8940;
              @include poa(7px, auto, auto, 0);

              @media (max-width: 767px) {
                transform: none;
              }
            }

            span{
              width: 80px;
              display: inline-block;
              font-weight: 700;
              @include poa(0, auto, auto, 15px);

              @media (max-width: 767px) {
                width: 100%;
                margin-bottom: 5px;
              }
            }

            @media (max-width: 1350px) {
              width: 100%;
            }

            @media (max-width: 767px) {
              font-size: 1.4rem;
            }
          }

          div{
            width: 140px;
            display: inline-block;
            @include poa(auto, 30px, 30px, auto);

            @media (max-width: 1350px) {
              width: 100%;
              margin-top: 15px;
              @include po-cancel;
            }

            @media (max-width: 767px) {
              width: 100%;
            }
          }

          a{
            width: 140px;
            height: 50px;
            background-color: #0f8940;
            @include font(1.8rem, 500, #fff);
            text-align: center;
            line-height: 48px;

            @media (max-width: 1350px) {
              width: calc(50% - 5px);
              vertical-align: middle;
            }

            @media (max-width: 767px) {
              width: 100%;
            }

            // &:first-child{
            //   margin-bottom: 10px;
            //   border: 1px solid #ddd;
            //   background-color: #fff;
            //   @include font(1.8rem, 500, #222);
            //
            //   @media (max-width: 1350px) {
            //     margin-bottom: 0;
            //     margin-right: 10px;
            //   }
            // }
          }

          @media (max-width: 1350px) {
            width: calc(100% - 220px);
          }

          @media (max-width: 767px) {
            width: 100%;
          }
        }

        @media (max-width: 991px) {
          padding: 15px;
        }
      }
    }
  }

  &-step{
    &-contents{
      &-tit{
        margin-bottom: 20px;

        h3{
          padding-left: 30px;
          @include font(2.2rem, 700, #000);
          position: relative;

          &:before{
            content: '';
            width: 10px;
            height: 10px;
            margin-top: 2px;
            border: 3px solid #0f8940;
            display: inline-block;
            @include middle;
          }
        }
      }

      &-table{
        margin-bottom: 50px;
        word-break: break-all;

        table{
          @media (max-width: 767px) {
            display: block;
          }
        }

        tbody{
          @media (max-width: 767px) {
            display: block;
          }
        }

        tr{
          @media (max-width: 767px) {
            display: block;
          }
        }

        th{
          text-align: center;

          @media (max-width: 991px) {
            padding: 5px;
          }

          @media (max-width: 767px) {
            display: block;
          }
        }

        td{
          @include font(1.6rem, 500, #333);

          @media (max-width: 767px) {
            display: block;
          }
        }

        .bd-r{
          border-right: 1px solid #ddd;

          @media (max-width: 767px) {
            border-right: none;
            border-bottom: 1px solid #000;
          }
        }

        input[type="number"]{
          max-width: 270px;
        }

        select{
          @media (max-width: 767px) {
            width: 100%;
          }
        }

        .address{
          button{
            width: 100px;
            height: 40px;
            background-color: #ddd;
          }
        }

        .sub-text{
          margin-top: 5px;
          color: #ccc;
        }

        .between-text{
          display: inline-block;
          vertical-align: middle;
          @include font(1.6rem, 500, #000);
        }

        label{
          cursor: text;
        }

        .address-num{
          width: 100px;
        }

        .bank-user-name{
          width: 120px;

          @media (max-width: 767px) {
            width: 100%;
          }
        }

        .bank-name{
          width: 120px;

          @media (max-width: 767px) {
            width: 100%;
          }
        }

        .use-personnel{
          @media (max-width: 767px) {
            width: calc(100% - 18px);
          }
        }
      }

      &-terms{
        margin-bottom: 50px;

        h3{
          margin-bottom: 10px;
          @include font(2.4rem, 700, #000);

          @media (max-width: 767px) {
            font-size: 2rem;
          }
        }

        &__wrap{
          padding: 20px 25px;
          border: 1px solid #e5e5e5;
          border-top: 3px solid #0f8940;
        }

        &__text{
          width: 100%;
          // max-height: 240px;
          margin-bottom: 20px;
          padding-bottom: 20px;
          overflow-y: auto;
          border-bottom: 1px solid #eaeaea;

          h4{
            margin-top: 20px;
            @include font(2rem, 700, #222);

            @media (max-width: 767px) {
              font-size: 1.8rem;
            }
          }

          p{
            @include font(1.6rem, 700, #888);
            line-height: 2.4rem;

            &.terms-sub-title{
              margin-top: 30px;
              @include font(1.8rem, 700, #0f8940);

              @media (max-width: 767px) {
                font-size: 1.6rem;
              }

              &:first-child{
                margin-top: 0;
              }
            }

            &:last-child{
              margin-top: 30px;
            }

            b{
              font-weight: 900;
            }

            @media (max-width: 767px) {
              font-size: 1.4rem;
            }
          }

          ul{
            padding-left: 20px;

            @media (max-width: 767px) {
              padding-left: 10px;
            }
          }

          li{
            @include font(1.6rem, 700, #888);
            line-height: 2.4rem;

            @media (max-width: 767px) {
              font-size: 1.4rem;
            }
          }

          table{
            width: 100%;
            table-layout: fixed;
          }

          th{
            padding: 10px;
            border: 1px solid #ddd;
            background-color: #ddd;
            @include font(1.6rem, 700, #888);
            word-break: break-all;

            @media (max-width: 767px) {
              font-size: 1.4rem;
            }
          }

          td{
            padding: 5px;
            border: 1px solid #ddd;
            @include font(1.6rem, 700, #888);
            text-align: center;
            vertical-align: middle;
            word-break: break-all;

            &.ta-l{
              text-align: left;
            }

            @media (max-width: 767px) {
              font-size: 1.4rem;
            }
          }
        }

        &__agree{
          text-align: center;

          p{
            margin-bottom: 15px;
            @include font(1.5rem, 400, #5d5d5d);
          }

          input:checked+label{
            &:before{
              border: 2px solid #0f8940;
              background-color: #0f8940;
            }
          }

          label{
            margin: 0 20px;
            padding-left: 30px;
            @include font(1.7rem, 700, #5d5d5d);
            text-align: left;
            position: relative;

            &:before{
              content: '';
              width: 15px;
              height: 15px;
              border-radius: 20px;
              border: 2px solid #c1c1c1;
              @include middle;
            }

            @media (max-width: 767px) {
              margin: 5px 0;
              display: block;
              font-size: 1.4rem;
            }
          }

          @media (max-width: 767px) {
            text-align: left;
          }
        }

        @media (max-width: 767px) {
          margin-top: 30px;
        }
      }

      &-btn{
        text-align: center;

        a{
          width: 150px;
          height: 50px;
          border: 1px solid #0f8940;
          @include font(1.6rem, 500, #0f8940);
          line-height: 48px;
          transition-duration: .3s;

          &:hover{
            background-color: #0f8940;
            color: #fff;
          }
        }
      }

      &-fi-text{
        margin-bottom: 50px;
        text-align: center;

        h3{
          @include font(3rem, 700, #000);
        }
      }
    }
  }

  &-calendar{
    margin-bottom: 60px;

    &-contents{
      &-tit{
        margin-bottom: 20px;

        h3{
          padding-left: 30px;
          @include font(2.2rem, 700, #000);
          position: relative;

          &:before{
            content: '';
            width: 10px;
            height: 10px;
            margin-top: 2px;
            border: 3px solid #0f8940;
            display: inline-block;
            @include middle;
          }
        }
      }

      &__selector{
        margin-bottom: 75px;

        &__option-dn{
          width: 260px;
          height: 50px;
          margin-bottom: 10px;
          border: 2px solid #0f8940;
          padding: 0 20px;
          // display: none;
          @include font(2rem, 700, #515456);

          @media (max-width: 767px) {
             width: 100%;
          }
        }

        &__text{
          height: 55px;
          margin-bottom: 45px;
          padding: 0 25px;
          background-color: #0f8940;
          font-size: 0;
          position: relative;

          h3{
            display: inline-block;
            @include font(2.6rem, 700, #fff);
            line-height: 55px;

            @media (max-width: 767px) {
              display: block;
            }
          }

          a{
            width: 200px;
            height: 35px;
            padding-left: 40px;
            border: 1px solid #fff;
            border-radius: 35px;
            @include font(1.6rem, 700, #fff);
            text-align: center;
            line-height: 31px;
            @include poa(50%, 25px, auto, auto);
            transform: translateY(-50%);

            &:before{
              content: '';
              width: 18px;
              height: 18px;
              display: inline-block;
              background-image: url('/template/resources/images/icons/re-icon.png');
              @include poa(50%, auto, auto, 22px);
              transform: translateY(-50%);
            }

            @media (max-width: 767px) {
              margin-top: 15px;
              position: relative;
              top: auto;
              right: auto;
            }
          }

          @media (max-width: 991px) {
            margin-bottom: 0;
          }

          @media (max-width: 767px) {
            height: auto;
            text-align: center;
          }
        }

        &__calender{
          width: 50%;
          padding: 0 60px;
          display: inline-block;
          border-right: 1px solid #d4d4d4;
          text-align: right;
          vertical-align: middle;

          &__year{
            margin-bottom: 50px;

            h3{
              @include font(4.2rem, 700, #515456);
              font-family: 'Jalnan';
              text-align: left;
              line-height: 1;

              @media (max-width: 767px) {
                font-size: 3rem;
              }
            }

            .year-prev{
              margin-right: 10px;
            }
          }

          &__date{
            table{
              width: 100%;
              margin-bottom: 50px;
              table-layout: fixed;
              text-align: center;

              th{
                padding-bottom: 20px;
                @include font(2.4rem, 700, #515456);
                font-family: 'Jalnan';
                line-height: 1;

                @media (max-width: 767px) {
                  font-size: 1.6rem;
                }
              }

              td{
                height: 80px;
                @include font(2.4rem, 700, #515456);
                vertical-align: middle;

                &:not(.calendar_date){
                  opacity: .5;
                }

                &.full{
                  opacity: .5;
                  pointer-events: none;
                }

                a{
                  @include font(2.4rem, 700, #515456);
                  line-height: 1;
                  position: relative;

                  &.disabled{
                    opacity: .5;
                    pointer-events:none;
                  }

                  &.selected{
                    color: #fff;

                    &:before{
                      content: '';
                      width: 50px;
                      height: 50px;
                      border-radius: 50px;
                      background-color: #0f8940;
                      @include poa(50%, auto, auto, 50%);
                      transform: translate(-50%, -50%);
                      z-index: -1;

                      @media (max-width: 767px) {
                        width: 30px;
                        height: 30px;
                      }
                    }
                  }

                  &.on{
                    color: #fff;

                    &:before{
                      content: '';
                      width: 50px;
                      height: 50px;
                      border-radius: 50px;
                      background-color: #0f8940;
                      @include poa(50%, auto, auto, 50%);
                      transform: translate(-50%, -50%);
                      z-index: -1;

                      @media (max-width: 767px) {
                        width: 30px;
                        height: 30px;
                      }
                    }
                  }

                  @media (max-width: 767px) {
                    font-size: 1.6rem;
                  }
                }

                @media (max-width: 767px) {
                  height: 60px;
                  font-size: 1.6rem;
                }
              }
            }

            p{
              padding-left: 25px;
              @include font(2.2rem, 500, #515456);
              text-align: left;
              position: relative;
              line-height: 1;

              &:before{
                content: '';
                width: 16px;
                height: 16px;
                border-radius: 16px;
                display: inline-block;
                background-color: #0f8940;
                @include middle();
              }
            }
          }

          @media (max-width: 1650px) {
            width: 60%;
            padding: 0 30px;
          }

          @media (max-width: 991px) {
            width: 100%;
            padding: 30px;
            border-right: none;
            border-bottom: 1px solid #d4d4d4;
          }

          @media (max-width: 767px) {
            padding: 30px 0;
          }
        }

        &__time{
          width: 50%;
          // padding-top: 124px;
          display: inline-block;
          vertical-align: middle;

          ul{
            width: 90%;
            max-width: 375px;
            margin: 0 auto;

            li{
              width: calc(50% - 7px);
              margin-bottom: 20px;
              margin-right: 14px;
              display: inline-block;
              font-size: 0;
              text-align: center;

              &:nth-child(2n){
                margin-right: 0;
              }

              &:nth-last-child(1){
                margin-bottom: 0;
              }

              &:nth-last-child(2){
                margin-bottom: 0;
              }

              &.full{
                opacity: .5;
                pointer-events: none;
              }

              a{
                width: 100%;
                height: 45px;
                border: 2px solid #c1c1c1;
                border-radius: 45px;
                @include font(2rem, 400, #515456);
                line-height: 41px;

                &.disabled{
                  opacity: .5;
                  pointer-events:none;
                }

                &.selected{
                  border: 2px solid #0f8940;
                  background-color: #0f8940;
                  color: #fff;
                }

                &.on{
                  border: 2px solid #0f8940;
                  background-color: #0f8940;
                  color: #fff;
                }
              }

              @media (max-width: 1250px) {
                width: 100%;
                margin-right: 0;
                margin-bottom: 15px;

                &:nth-child(2n){
                  margin-right: 0;
                }

                &:nth-child(4n){
                  margin-right: 0;
                }

                &:nth-last-child(2){
                  margin-bottom: 15px;
                }
              }

              @media (max-width: 991px) {
                width: calc((100% - 45px) / 4);
                margin-right: 15px;

                &:nth-child(2n){
                  margin-right: 15px;
                }

                &:nth-child(4n){
                  margin-right: 0;
                }

                &:nth-last-child(3){
                  margin-bottom: 0;
                }

                &:nth-last-child(4){
                  margin-bottom: 0;
                }
              }

              @media (max-width: 767px) {
                width: calc((100% - 15px) / 2);


                &:nth-child(2n){
                  margin-right: 0;
                }

                &:nth-last-child(3){
                  margin-bottom: 20px;
                }

                &:nth-last-child(4){
                  margin-bottom: 20px;
                }
              }

              @media (max-width: 320px) {
                width: 100%;
                margin-right: 0;

                &:nth-last-child(2){
                  margin-bottom: 20px;
                }

                &:nth-last-child(3){
                  margin-bottom: 20px;
                }

                &:nth-last-child(4){
                  margin-bottom: 20px;
                }
              }
            }

            @media (max-width: 1220px) {
              width: 100%;
              padding: 0 15px;
            }

            @media (max-width: 767px) {
              padding: 0;
            }
          }

          > a{
            width: 180px;
            height: 50px;
            margin-top: 80px;
            padding-right: 30px;
            border-radius: 50px;
            background-color: #0f8940;
            @include font(2.6rem, 700, #fff);
            font-family: 'Jalnan';
            text-align: center;
            line-height: 50px;
            position: relative;

            &:after{
              content: '';
              width: 26px;
              height: 25px;
              display: inline-block;
              background-image: url('/template/resources/images/icons/btn-arrow-icon-w.png');
              @include poa(50%, 14px, auto, auto);
              transform: translateY(-50%);
            }

            @media (max-width: 767px) {
              width: 250px;
              font-size: 2.2rem;
            }
          }

          @media (max-width: 1650px) {
            width: 40%;
          }

          @media (max-width: 991px) {
            width: 100%;
            padding-top: 30px;
          }

          &__fixed-text{
            max-width: 375px;
            margin: 0 auto;
            margin-top: 30px;
            @include font(1.6rem, 500, #515456);
          }

          &__selected-time{
            max-width: 375px;
            margin: 0 auto;
            @include font(2rem, 500, #0f8940);
          }
        }
      }

      &-list{
        margin-bottom: 20px;

        li{
          margin: 5px;
          padding: 5px 10px;
          border: 1px solid #0f8940;
          border-radius: 30px;
          display: inline-block;
          @include font(1.6rem, 400, #515456);
        }
      }

      &-notice{
        // height: 110px;
        margin-bottom: 15px;
        padding: 25px;
        border: 2px solid #0f8940;
        border-radius: 30px;

        &__text{
          overflow: auto;
          @include font(1.8rem, 500, #515456);
          letter-spacing: -1px;
          line-height: 3rem;
        }

        @media (max-width: 767px) {
          margin-bottom: 5px;
          padding: 15px;
        }
      }

      &-agree{
        margin-bottom: 50px;
        padding-right: 20px;
        text-align: right;

        h3{
          display: inline-block;
          @include font(1.8rem, 400, #515456);
          letter-spacing: -1px;
          line-height: 1;
          vertical-align: middle;

          @media (max-width: 767px) {
            margin-bottom: 10px;
            display: block;
            font-size: 1.6rem;
          }
        }

        input{
          position: absolute;
          left: -9999px;

          &:focus+label{
            outline: 2px dashed #0f78ad !important;
          }

          &+label{
            margin-left: 15px;
            padding-left: 20px;
            @include font(1.8rem, 400, #515456);
            letter-spacing: -1px;
            line-height: 1;
            position: relative;

            &:before{
              content: '';
              width: 13px;
              height: 13px;
              border: 1px solid #515456;
              border-radius: 13px;
              display: inline-block;
              @include poa(7px, auto, auto, 0);
            }

            @media (max-width: 767px) {
              font-size: 1.6rem;
            }
          }

          &:checked+label:before{
            border: 1px solid #0f8940;
            background-color: #0f8940;
          }
        }

        @media (max-width: 767px) {
          padding-right: 0;
        }
      }

      &-btn{
        text-align: center;

        a{
          width: 150px;
          height: 50px;
          border: 1px solid #0f8940;
          @include font(1.6rem, 500, #0f8940);
          line-height: 48px;
          transition-duration: .3s;

          &:hover{
            background-color: #0f8940;
            color: #fff;
          }
        }
      }
    }
  }

  &-guide{
    &-contents{
      &__selector{
        margin-bottom: 70px;
        text-align: center;

        li{
          margin: 0 10px;
          display: inline-block;
          vertical-align: top;
        }

        a{
          width: 190px;
          height: 58px;
          border: 2px solid #f5f5f5;
          background-color: #f5f5f5;
          @include font(1.6rem, 500, #888);
          text-align: center;
          line-height: 54px;

          &.selected{
            border: 2px solid #0f8940;
            background-color: #fff;
            color: #0f8940;
            box-shadow: 3px 3px 10px 3px rgba(15, 137, 64, .2);
          }
        }
      }

      &__info{
        display: none;

        &.visible{
          display: block;
        }

        &__map{
          margin-bottom: 65px;
          background-color: #f7f7f7;
          text-align: center;

          &__tit{
            padding: 14px 0;
            background-color: #0f8940;
            @include font(2.2rem, 500, #fff);
            font-family: 'GmarketSans';

            img{
              vertical-align: middle;
            }

            h3{
              display: inline-block;
              vertical-align: middle;
            }
          }

          &__selector{
            padding-top: 30px;

            ul{
              position: relative;

              &:before{
                content: '';
                width: 1px;
                height: 14px;
                display: inline-block;
                background-color: #ddd;
                @include center(6px);
              }
            }

            li{
              margin: 0 30px;
              display: inline-block;
              vertical-align: middle;
            }

            a{
              width: 60px;
              padding-bottom: 10px;
              @include font(1.8rem, 500, #222);
              text-align: center;
              position: relative;

              &.selected:after{
                content: '';
                width: 100%;
                height: 2px;
                display: inline-block;
                background-color: #0f8940;
                @include poa(auto, auto, 0, 0);
              }
            }
          }

          &__img{
            margin-bottom: 65px;
            padding: 0 65px 65px;
            text-align: center;
            position: relative;

            &__list{
              display: none;
              text-align: left;

              &.visible{
                display: block;
              }

              p{
                @include font(10rem, 700, #888);
                font-family: 'GmarketSans';
              }

              img{
                display: block;
                margin: 0 auto;
              }
            }
          }
        }

        &__table{
          overflow-x: auto;

          table{
            @media (max-width: 767px) {
              min-width: 800px;
            }

            th{
              border-right: 1px solid #ddd;

              &.b-l{
                border-left: 1px solid #ddd;
              }

              &.b-r{
                border-right: 1px solid #ddd;
              }
            }

            td{
              border-right: 1px solid #ddd;

              &:last-child{
                border-right: none;
              }

              &.b-l{
                border-left: 1px solid #ddd;
              }

              &.b-r{
                border-right: 1px solid #ddd;
              }
            }
          }
        }
      }
    }
  }

  &-rule{
    &-contents{
      &__selector{
        margin-bottom: 70px;
        text-align: center;

        li{
          margin: 0 10px;
          display: inline-block;
          vertical-align: top;
        }

        a{
          width: 190px;
          height: 58px;
          border: 2px solid #f5f5f5;
          background-color: #f5f5f5;
          @include font(1.6rem, 500, #888);
          text-align: center;
          line-height: 54px;

          &.selected{
            border: 2px solid #0f8940;
            background-color: #fff;
            color: #0f8940;
            box-shadow: 3px 3px 10px 3px rgba(15, 137, 64, .2);
          }
        }
      }

      &__info{
        display: none;

        &.visible{
          display: block;
        }

        &__text{

          h4{
            @include font(2.4rem, 500, #0f8940);
          }

          ul{
            width: 50%;
            display: inline-block;
            vertical-align: top;

            @media (max-width: 991px) {
              width: 100%;
            }
          }

          li{
            margin: 5px 0;
            @include font(1.6rem, 500, #222);

            @media (max-width: 767px) {
              font-size: 1.4rem;
            }
          }
        }

        &__table{
          margin-bottom: 40px;
          overflow-x: auto;

          h4{
            @include font(2.4rem, 500, #0f8940);
          }

          table{
            @media (max-width: 767px) {
              min-width: 800px;
            }

            th{
              border-right: 1px solid #ddd;

              &:last-child{
                border-right: none;
              }

              &.b-l{
                border-left: 1px solid #ddd;
              }

              &.b-r{
                border-right: 1px solid #ddd;
              }
            }

            td{
              border-right: 1px solid #ddd;

              &:last-child{
                border-right: none;
              }

              &.b-l{
                border-left: 1px solid #ddd;
              }

              &.b-r{
                border-right: 1px solid #ddd;
              }
            }
          }
        }
      }
    }
  }
}
