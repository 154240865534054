.past{
  &-list{
    li{
      width: calc((100% - 120px) / 4);
      height: 285px;
      margin: 0 40px 40px 0;
      border: 1px solid #ddd;
      display: inline-block;
      vertical-align: top;
      position: relative;

      @media (max-width: 1650px) {
        width: calc((100% - 60px) / 4);
        margin: 0 20px 20px 0;
      }

      @media (max-width: 1440px) {
        width: calc((100% - 40px) / 3);
      }

      @media (max-width: 991px) {
        width: calc(50% - 10px);
      }

      @media (max-width: 767px) {
        width: 100%;
        margin-right: 0px;
      }

      &:nth-child(4n){
        margin-right: 0;

        @media (max-width: 1440px) {
          margin-right: 20px;
        }
      }

      &:nth-child(3n){
        @media (max-width: 1440px) {
          margin-right: 0;
        }

        @media (max-width: 991px) {
          margin-right: 20px;
        }

        @media (max-width: 767px) {
          margin-right: 0px;
        }
      }

      &:nth-child(2n){
        @media (max-width: 991px) {
          margin-right: 0px;
        }
      }

      > div{
        width: 100%;
        @include middle;
        text-align: center;

        > img{
          margin-bottom: 15px;
        }
      }
    }

    &__text{
      img{
        display: inline-block;
        vertical-align: middle;
      }

      h3{
        padding: 0 5px;
        display: inline-block;
        @include font(2.4rem, 700, #003554);
        vertical-align: middle;

        @media (max-width: 1790px) {
          font-size: 2.1rem;
        }

        @media (max-width: 1650px) {
          font-size: 2rem;
        }

        @media (max-width: 1550px) {
          font-size: 1.8rem;
        }

        @media (max-width: 1050px) {
          font-size: 1.7rem;
        }

        @media (max-width: 767px) {
          font-size: 1.6rem;
        }
      }

      // img{
      //   margin-bottom: 15px;
      // }

      p{
        margin-top: 5px;
        @include font(1.6rem, 500, #666);

        @media (max-width: 1050px) {
          font-size: 1.4rem;
        }
      }
    }
  }
}