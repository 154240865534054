.history{
  &-banner{
    width: 100%;
  }

  &-wrap{
    padding: 0 80px;
    background-color: #f9f9f9;
    position: relative;

    @media (max-width: 1150px) {
      padding: 0 40px;
    }

    @media (max-width: 991px) {
      padding: 0 20px;
    }

    @media (max-width: 767px) {
      padding: 0 15px;
    }
  }

  &-fixed{
    width: 350px;
    margin-top: -80px;
    padding: 30px 50px 30px 0;
    display: inline-block;
    background-color: #003554;
    vertical-align: top;
    position: sticky;
    top: 115px;
    box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, .3);
    z-index: 1;

    @media (max-width: 1150px) {
      width: 250px;
      padding: 30px 20px 30px 0;
    }

    @media (max-width: 991px) {
      width: calc(100% + 40px);
      margin-left: -20px;
      padding: 20px 10px 20px 0;
    }

    @media (max-width: 767px) {
      width: calc(100% + 30px);
      margin: -5px 0 0 -15px;
      padding: 10px 10px 10px 0;
      top: 50px;
    }

    .bg-text{
      margin: 0 -50px 10px 0;
      @include font(4rem, 700, rgba(255, 255, 255, .1));
      text-align: right;

      @media (max-width: 1150px) {
        margin: 0 -20px 10px 0;
      }

      @media (max-width: 991px) {
        margin: 0 -10px 0 0;
        font-size: 3rem;
      }

      @media (max-width: 767px) {
        display: none;
      }
    }

    h3{
      margin-bottom: 15px;
      padding: 0 0 20px 40px;
      border-bottom: 1px solid rgba(255, 255, 255, .5);
      @include font(3.6rem, 700, #fff);

      @media (max-width: 1150px) {
        padding-left: 20px;
        font-size: 3.2rem;
      }

      @media (max-width: 991px) {
        padding: 0 0 10px 10px;
        font-size: 2.6rem;
      }

      @media (max-width: 767px) {
        margin-bottom: 0;
        padding: 0 0 0 10px;
        border-bottom: none;
      }
    }

    p{
      padding-left: 40px;
      @include font(1.6rem, 500, rgba(255, 255, 255, .5));

      @media (max-width: 1150px) {
        padding-left: 20px;
        font-size: 1.4rem;
      }

      @media (max-width: 991px) {
        padding-left: 10px;
      }

      @media (max-width: 767px) {
        display: none;
      }
    }
  }

  &-list{
    width: calc(100% - 350px);
    padding: 20px 0 0 210px;
    display: inline-block;
    vertical-align: top;
    position: relative;
    overflow: hidden;

    @media (max-width: 1420px) {
      padding: 20px 0 0 40px;
    }

    @media (max-width: 1150px) {
      width: calc(100% - 250px);
    }

    @media (max-width: 991px) {
      width: 100%;
      padding: 20px 0 0 0;
    }

    @media (max-width: 767px) {
      padding: 0;
    }

    &__line{
      width: 1px;
      background-color: #ddd;
      position: absolute;
      top: 0;

      @media (max-width: 767px) {
        display: none;
      }
    }

    &__wrap{
      padding-top: 80px;

      @media (max-width: 767px) {
        padding-top: 40px;
      }

      &:last-child{
        padding-bottom: 100px;

        @media (max-width: 767px) {
          padding-bottom: 40px;
        }
      }

      h3{
        width: 160px;
        padding-right: 60px;
        display: inline-block;
        vertical-align: top;
        @include font(3.6rem, 700, #222);
        line-height: 1;
        position: relative;

        @media (max-width: 1150px) {
          width: 130px;
          padding-right: 30px;
        }

        @media (max-width: 767px) {
          width: 100%;
          margin-bottom: 5px;
          padding-right: 0;
        }

        &:after{
          content: '';
          width: 23px;
          height: 23px;
          display: inline-block;
          background-image: url('/template/resources/images/icons/history-list-icon.png');
          background-size: cover;
          @include middle(auto, -12px);

          @media (max-width: 767px) {
            display: none;
          }
        }
      }
    }

    &__info{
      width: calc(100% - 160px);
      padding: 5px 0 0 50px;
      display: inline-block;
      vertical-align: top;

      @media (max-width: 1150px) {
        width: calc(100% - 130px);
        padding: 5px 0 0 30px;
      }

      @media (max-width: 767px) {
        width: 100%;
        padding: 5px 0 0 0;
      }

      li{
        margin-bottom: 15px;
        padding-left: 80px;
        position: relative;

        @media (max-width: 767px) {
          padding-left: 0;
        }

        &:last-child{
          margin-bottom: 0;
        }
      }

      &__date{
        width: 80px;
        display: inline-block;
        @include font(1.6rem, 700, #9c7e29);
        vertical-align: top;
        @include poa(0, auto, auto, 0);

        @media (max-width: 767px) {
          width: 100%;
          margin-bottom: 5px;
          @include po-cancel;
        }
      }

      &__text{
        width: 100%;
        padding-left: 13px;
        display: inline-block;
        @include font(1.6rem, 500, #666);
        vertical-align: top;
        position: relative;

        &:before{
          content: '';
          width: 3px;
          height: 3px;
          display: inline-block;
          background-color: #666;
          @include poa(12px, auto, auto, 0);
        }
      }
    }
  }
}