.business{
  &-contents{
    &-year{
      @include poa(0, 0, auto, auto);
      z-index: 1;

      @media (max-width: 767px) {
        margin-bottom: 20px;
        @include po-cancel;
        text-align: right;
      }

      >*{
        height: 50px;
        border: 1px solid #e5e5e5;
        display: inline-block;
        vertical-align: middle;
        text-align: center;
      }

      a{
        width: 50px;
        position: relative;

        &:before{
          content: '';
          width: 9px;
          height: 9px;
          border-top: 2px solid #ccc;
          border-left: 2px solid #ccc;
          @include poa-center;
        }

        &.year-prev:before{
          transform: translate(-30%, -50%) rotate(-45deg);
        }

        &.year-next:before{
          transform: translate(-70%, -50%) rotate(135deg);
        }
      }

      p{
        width: 130px;
        border-left: none;
        border-right: none;
        @include font(1.6rem, 500, #555);
        line-height: 48px;
        text-align: center;

        img{
          margin-right: 10px;
          display: inline-block;
          vertical-align: middle;
          position: relative;
          bottom: 1px;
        }
      }
    }

    &-wrap{
      margin-bottom: 40px;
      padding-bottom: 50px;
      border-bottom: 1px solid #e5e5e5;

      &:last-child{
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: none;
      }

      &__tit{
        margin-bottom: 15px;

        h3{
          @include font(2.6rem, 700, #000);
          font-family: 'GmarketSans';
          position: relative;

          &:before{
            content: '';
            width: 25px;
            height: 25px;
            border-radius: 25px;
            display: inline-block;
            background-color: #fdedd3;
            @include poa(0, auto, auto, -12px);
            z-index: -1;
          }
        }
      }

      &__list{
        li{
          width: calc((100% - 21px) / 4);
          height: 160px;
          margin-right: 7px;
          margin-bottom: 7px;
          padding: 30px 20px 55px;
          border: 1px solid #e5e5e5;
          display: inline-block;
          vertical-align: top;
          position: relative;
          transition-duration: .3s;

          @media (max-width: 1750px) {
            height: 180px;
          }

          @media (max-width: 1350px) {
            height: 180px;
          }

          @media (max-width: 1250px) {
            width: calc((100% - 7px) / 2);
          }

          @media (max-width: 767px) {
            width: 100%;
            height: auto;
            padding-bottom: 65px;
          }

          &:hover{
            border: 1px solid #0f8940;
            box-shadow: 1px 1px 10px 1px rgba(1, 2, 2, .1);
          }

          &:nth-child(4n){
            margin-right: 0;

            @media (max-width: 991px) {
              margin-right: 7px;
            }

            @media (max-width: 767px) {
              margin-right: 0;
            }
          }

          &:nth-child(2n){
            @media (max-width: 1250px) {
              margin-right: 0;
            }
          }

          h4{
            padding-left: 15px;
            @include font(1.6rem, 500, #000);
            position: relative;


            @media (max-width: 1350px) {
              font-size: 1.4rem;
            }

            &:before{
              content: '';
              width: 7px;
              height: 7px;
              border-radius: 7px;
              display: inline-block;
              background-color: #0f8940;
              @include poa(9px, auto, auto, 0);
            }
          }

          a{
            width: 100px;
            height: 36px;
            border: 1px solid #0f8940;
            @include font(1.4rem, 500, #0f8940);
            text-align: center;
            line-height: 34px;
            @include poa(auto, 20px, 20px, auto);
            transition-duration: .3s;

            &:hover{
              background-color: #0f8940;
              color: #fff;
            }
          }
        }
      }
    }
  }
}
