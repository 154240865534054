.en{
    &-intro{
        &-top{
            margin-bottom: 80px;

            @media (max-width: 991px) {
                margin-bottom: 60px;
            }

            @media (max-width: 767px) {
                margin-bottom: 30px;
            }

            &__text{
                width: calc(50% - 50px);
                padding-right: 30px;
                display: inline-block;
                vertical-align: top;

                @media (max-width: 1550px) {
                    width: 50%;
                }

                @media (max-width: 991px) {
                    width: 100%;
                    margin-bottom: 30px;
                }

                h3{
                    margin-bottom: 30px;
                    @include font(3.6rem, 400, #003561);
                    line-height: 4.6rem;

                    @media (max-width: 1320px) {
                        font-size: 3rem;
                        line-height: 4rem;
                    }

                    @media (max-width: 767px) {
                        font-size: 2rem;
                        line-height: 3rem;

                        br{
                            display: none;
                        }
                    }

                    span{
                        color: #089799;
                    }
                }

                p{
                    margin-bottom: 30px;
                    @include font(1.6rem, 400, #555);
                    line-height: 2.9rem;

                    @media (max-width: 767px) {
                        font-size: 1.4rem;
                        line-height: 2.7rem;
                    }

                    &:last-child{
                        margin-bottom: 0;
                    }
                }
            }

            &__img{
                width: calc(50% + 50px);
                display: inline-block;
                vertical-align: top;

                @media (max-width: 1550px) {
                    width: 50%;
                }

                @media (max-width: 991px) {
                    width: 100%;

                    img{
                        width: 100%;
                    }
                }
            }
        }

        &-bottom{
            &__tit{
                margin-bottom: 20px;

                h3{
                    padding-top: 20px;
                    @include font(2.6rem, 700, #111);
                    position: relative;

                    @media (max-width: 767px) {
                        padding-top: 10px;
                        font-size: 2rem;
                    }

                    &:before{
                        content: '';
                        width: 7px;
                        height: 7px;
                        border-radius: 7px;
                        display: inline-block;
                        background-color: #8b8e91;
                        @include poa(0, auto, auto, 0);
                    }

                    &:after{
                        content: '';
                        width: 7px;
                        height: 7px;
                        border-radius: 7px;
                        display: inline-block;
                        background-color: #0078c7;
                        @include poa(0, auto, auto, 10px);
                    }
                }
            }

            &__map{
                .root_daum_roughmap{
                    width: 100%;
                    height: 480px;

                    @media (max-width: 991px) {
                        height: 300px;
                    }

                    @media (max-width: 767px) {
                        height: 200px;
                    }

                    .wrap_map{
                        height: 100%;
                    }
                }

                &-info{
                    padding: 40px;
                    background-color: #33373b;

                    @media (max-width: 991px) {
                        padding: 20px;
                    }

                    ul{
                        width: calc(100% - 115px);
                        display: inline-block;
                        vertical-align: middle;

                        @media (max-width: 767px) {
                            width: 100%;
                        }
                    }

                    li{
                        padding-left: 17px;
                        @include font(1.6rem, 400, #fff);
                        position: relative;

                        @media (max-width: 767px) {
                            margin-bottom: 15px;
                            padding-left: 12px;
                            font-size: 1.4rem;
                        }

                        &:before{
                            content: '';
                            width: 3px;
                            height: 3px;
                            display: inline-block;
                            background-color: #fff;
                            @include poa(15px, auto, auto, 0);

                            @media (max-width: 767px) {
                                top: 10px;
                            }
                        }

                        span{
                            display: inline-block;
                            font-weight: 700;
                            vertical-align: middle;

                            @media (max-width: 767px) {
                                display: block;
                            }

                            &:after{
                                content: '';
                                width: 1px;
                                height: 12px;
                                margin: 0 15px;
                                display: inline-block;
                                background-color: #fff;

                                @media (max-width: 767px) {
                                    display: none;
                                }
                            }
                        }
                    }

                    a{
                        width: 115px;
                        height: 76px;
                        border: 2px solid #fff;
                        display: inline-block;
                        background-color: #1c92eb;
                        @include font(1.6rem, 700, #fff);
                        text-align: center;
                        line-height: 72px;
                        vertical-align: middle;

                        @media (max-width: 767px) {
                            width: 100%;
                        }

                        img{
                            margin-right: 5px;
                            vertical-align: middle;
                            position: relative;
                            bottom: 2px;
                        }
                    }
                }
            }
        }
    }
}