.en{
  &-poomsae{
    &__list{
      &-tit{
        margin-bottom: 10px;
        padding-left: 25px;
        @include font(1.6rem, 700, #111);
        position: relative;

        &:before{
          content: '';
          width: 16px;
          height: 16px;
          border: 4px solid #0078c7;
          border-radius: 16px;
          display: inline-block;
          box-sizing: border-box;
          @include poa(6px, auto, auto, 0);
        }
      }

      li{
        margin-bottom: 40px;
        padding: 40px 30px;
        border: 1px solid #c1c1c1;

        @media (max-width: 991px) {
          padding: 30px 15px;
        }

        @media (max-width: 767px) {
          padding: 15px;
        }
      }

      p{
        padding-left: 11px;
        margin-left: 5px;
        @include font(1.6rem, 400, #555);
        line-height: 2.6rem;
        position: relative;

        @media (max-width: 767px) {
          font-size: 1.4rem;
          line-height: 2.4rem;
        }

        &:before{
          content: '';
          width: 2px;
          height: 2px;
          display: inline-block;
          background-color: #111;
          @include poa(14px, auto, auto, 0);
        }
      }
    }

    &__text{
      width: 60%;
      padding-right: 30px;
      display: inline-block;
      vertical-align: middle;

      @media (max-width: 767px) {
        width: 100%;
        margin-bottom: 15px;
      }
    }

    &__img{
      width: 40%;
      display: inline-block;
      vertical-align: middle;
      text-align: center;
      position: relative;

      @media (max-width: 991px) {
        text-align: left;
      }

      @media (max-width: 767px) {
        width: 100%;
      }
    }

    &__qr{
      @include poa(0, 0, auto, auto);
      
      @media (max-width: 767px) {
        @include poa(auto, 0, 0, auto);
      }
    }
  }
}