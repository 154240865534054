.mypage{
  padding-top: 50px;

  @media (max-width: 767px) {
    padding-top: 30px;
  }

  &-contents{
    &__l-area{
      width: calc(100% - 950px);
      padding: 40px;
      display: inline-block;
      background-color: #f8f8f8;
      vertical-align: top;
      font-family: 'GmarketSans';

      @media (max-width: 1750px) {
        width: calc(100% - 790px);
      }

      @media (max-width: 1550px) {
        width: calc(100% - 700px);
      }

      @media (max-width: 1350px) {
        width: calc(100% - 500px);
        padding: 20px;
      }

      @media (max-width: 1250px) {
        width: 100%;
        margin-bottom: 15px;
      }

      h3{
        margin-bottom: 25px;
        @include font(2.4rem, 500, #222);
      }

      p{
        margin-bottom: 5px;
        @include font(1.8rem, 500, #555);
      }

      h4{
        margin-bottom: 20px;
        @include font(2.4rem, 500, #222);

        &.c-t{
          color: #0f8940;
        }
      }

      &__btn{
        margin-top: 15px;
        padding-top: 20px;
        border-top: 1px solid #eaeaea;

        a{
          width: 50%;
          height: 40px;
          display: inline-block;
          background-color: #eee;
          @include font(1.5rem, 500, #999);
          text-align: center;
          line-height: 38px;
          vertical-align: top;

          &:first-of-type{
            background-color: #0f8940;
            color: #fff;
          }

          @media (max-width: 767px) {
            width: 100%;
          }
        }
      }
    }

    &__r-area{
      width: 910px;
      margin-left: 40px;
      padding-top: 10px;
      display: inline-block;
      vertical-align: top;

      @media (max-width: 1750px) {
        width: 750px;
      }

      @media (max-width: 1550px) {
        width: 660px;
      }

      @media (max-width: 1350px) {
        width: 460px;
      }

      @media (max-width: 1250px) {
        width: 100%;
        margin-left: 0;
      }

      &__tit{
        margin-top: 40px;
        margin-bottom: 10px;
        font-family: 'GmarketSans';

        &:first-of-type{
          margin-top: 0;
        }

        h3{
          @include font(2rem, 700, #222);
        }
      }

      &__btn{
        margin-top: 40px;
        text-align: center;

        a{
          width: 180px;
          height: 52px;
          margin: 0 5px;
          border: 1px solid #dfdfdf;
          @include font(1.6rem, 700, #888);
          text-align: center;
          line-height: 50px;
          transition-duration: .3s;

          &:last-child{
            border: 1px solid #0f8940;
            @include font(1.6rem, 700, #0f8940);

            &:hover{
              background-color: #0f8940;
              color: #fff;
            }
          }

          &:hover{
            background-color: #dfdfdf;
            color: #fff;
          }

          @media (max-width: 767px) {
            width: 100%;
            margin: 5px 0;
          }
        }
      }

      &__table{
        margin-bottom: 40px;

        a{
          width: 85px;
          height: 40px;
          border: 1px solid #dfdfdf;
          border-radius: 5px;
          @include font(1.5rem, 500, #000);
          text-align: center;
          line-height: 38px;
          transition-duration: .3s;

          &:hover{
            background-color: #dfdfdf;
            color: #fff;
          }

          @media (max-width: 767px) {
            width: 100%;
            margin-top: 15px;
          }
        }

        &.type2{
          span{
            margin-bottom: 15px;
            padding: 10px 15px;
            border-bottom: 1px solid #ddd;
            background-color: #fffcf8;
            display: none;
            @include font(1.6rem, 700, #222);

            @media (max-width: 767px) {
              display: block;
            }
          }

          @media (max-width: 767px) {
            td{
              padding: 0 0 15px;
              border-bottom: 1px solid #000;

              &:last-child{
                border-bottom: none;
              }

              &:nth-last-child(2){
                border-bottom: none;
              }
            }
          }
        }

        @media (max-width: 767px) {
          table{
            display: block;
          }

          thead{
            display: none;
          }

          tbody{
            display: block;
          }

          tr{
            display: block;
          }

          th{
            display: block;
          }

          td{
            // padding: 0;
            display: block;
          }
        }
      }

      &__info{
        col{
          width: 215px;

          &:last-child{
            width: calc(100% - 215px);
          }
        }

        .id-confirm{
          width: 130px;
          height: 40px;
          margin-left: 5px;
          border: 1px solid #0f8940;
          @include font(1.4rem, 700, #0f8940);
          text-align: center;
          line-height: 38px;
          vertical-align: middle;
          transition-duration: .3s;

          &:hover{
            background-color: #0f8940;
            color: #fff;
          }

          @media (max-width: 767px) {
            width: 100%;
            margin-left: 0;
          }
        }

        .sub-text{
          margin-top: 5px;
          @include font(1.5rem, 500, #999);

          @media (max-width: 767px) {
            font-size: 1.3rem;
          }
        }

        .between-text{
          display: inline-block;
          vertical-align: middle;
          @include font(1.5rem, 500, #000);

          @media (max-width: 767px) {
            margin-left: 5px;
          }
        }

        .global-board{
          @media (max-width: 767px) {
            width: 100%;
            display: inline-block;
          }
        }

        tbody{
          @media (max-width: 767px) {
            width: 100%;
            display: inline-block;
          }
        }

        tr{
          @media (max-width: 767px) {
            width: 100%;
            border-bottom: 1px solid #000;
            display: inline-block;
          }
        }

        th{
          @media (max-width: 767px) {
            width: 100%;
            border-bottom: 1px solid #ddd;
            display: inline-block;
          }
        }

        td{
          @media (max-width: 767px) {
            width: 100%;
            display: inline-block;
          }
        }

        input{
          @media (max-width: 767px) {
            margin: 5px 0;
          }
        }

        input:not([type="radio"]), input:not([type="checkbox"]){
          @media (max-width: 767px) {
            max-width: 100%;
          }
        }

        input[type="number"]{
          @media (max-width: 767px) {
            max-width: 100%;
          }
        }

        select{
          @media (max-width: 767px) {
            width: 100%;
            margin: 5px 0;
          }
        }

        .birth{
          select{
            @media (max-width: 767px) {
              width: calc(100% - 19px);
            }
          }
        }

        .phone{
          select{
            @media (max-width: 767px) {
              width: calc(100% - 11px);
            }
          }

          input[type="number"]{
            @media (max-width: 767px) {
              max-width: calc(100% - 11px);
            }
          }
        }
      }
    }
  }

  &-modify-fi{
      padding-top: 70px;

      &-contents{
        &__text{
          padding-top: 40px;
          border-top: 2px solid #0f8940;
          text-align: center;

          h3{
            @include font(2.4rem, 500, #222);

            span{
              font-weight: 700;
              color: #0f8940;
            }

            @media (max-width: 767px) {
              font-size: 2rem;
            }
          }

          p{
            @include font(1.6rem, 500, #666);

            @media (max-width: 767px) {
              font-size: 1.4rem;
            }
          }

          @media (max-width: 767px) {
            margin-top: 30px;
          }
        }

        &__btn{
          margin-top: 50px;
          padding-bottom: 40px;
          border-bottom: 1px solid #ddd;
          text-align: center;

          a{
            width: 150px;
            height: 50px;
            margin-right: 10px;
            border: 1px solid #dfdfdf;
            @include font(1.6rem, 500, #888);
            line-height: 48px;
            transition-duration: .3s;

            &:hover{
              background-color: #dfdfdf;
              color: #fff;
            }

            &:last-child{
              margin-right: 0;
              border: 1px solid #0f8940;
              color: #0f8940;

              &:hover{
                background-color: #0f8940;
                color: #fff;
              }
            }

            @media (max-width: 767px) {
              width: 50%;
              margin-right: 0;
            }
          }

          @media (max-width: 767px) {
            margin-top: 30px;
          }
        }
      }
  }
}

.input-pw{
  padding-top: 70px;

  &-contents{
    &__text{
      width: 100%;
      max-width: 600px;
      margin: 0 auto;
      padding: 40px 17px;
      border-top: 2px solid #0f8940;
      border-bottom: 1px solid #ddd;

      @media (max-width: 767px) {
        padding: 40px 0;
      }

      h3{
        margin-bottom: 30px;
        @include font(2.4rem, 500, #222);
        text-align: center;

        span{
          font-weight: 700;
          color: #0f8940;
        }

        @media (max-width: 767px) {
          font-size: 2rem;
        }
      }

      label{
        width: 91px;
        margin-right: 15px;
        display: inline-block;
        @include font(1.6rem, 500, #666);

        @media (max-width: 767px) {
          width: 100%;
          margin-right: 0;
          margin-top: 20px;
          margin-bottom: 10px;
          display: block;
          text-align: left;
        }
      }

      input{
        width: 300px;
        height: 50px;
        margin: 5px 0;
        padding: 0 10px;
        border: 1px solid #dfdfdf;
        @include font(1.6rem, 500, #666);

        @media (max-width: 767px) {
          width: 100%;
          margin: 0;
          font-size: 1.4rem;
        }
      }

      button{
        width: 150px;
        height: 50px;
        margin-left: 10px;
        border: 1px solid #0f8940;
        display: inline-block;
        @include font(1.6rem, 500, #0f8940);
        line-height: 48px;
        transition-duration: .3s;

        &:hover{
          background-color: #0f8940;
          color: #fff;
        }

        @media (max-width: 767px) {
          width: 100%;
          margin-left: 0;
          margin-top: 10px;
        }
      }

      @media (max-width: 767px) {
        margin-top: 30px;
      }
    }
  }
}
