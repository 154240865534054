.about{
  &-contents{
    &1{
      &__img{
        width: 350px;
        display: inline-block;
        vertical-align: top;
        position: relative;
        text-align: right;

        @media (max-width: 1420px) {
          width: 250px;
        }

        @media (max-width: 991px) {
          width: 350px;
          margin: 0 auto;
          display: block;
          text-align: left;
        }
        
        @media (max-width: 767px) {
          width: 100%;
          text-align: center;
        }

        img{
          vertical-align: bottom;

          @media (max-width: 991px) {
            width: 100%;
          }
        }

        &__text{
          width: 100%;
          padding: 30px 40px;
          display: inline-block;
          background-color: #13233b;
          text-align: center;


          @media (max-width: 767px) {
            padding: 15px;
          }

          h4{
            margin-right: 15px;
            display: inline-block;
            @include font(1.8rem, 500, #fff);
            vertical-align: middle;
          }

          p{
            display: inline-block;
            @include font(3rem, 700, #fff);
            font-family: 'HakgyoansimGaeulsopung';
            vertical-align: middle;
          }
        }
      }

      &__text{
        width: calc(100% - 350px);
        padding-left: 80px;
        display: inline-block;
        vertical-align: top;

        @media (max-width: 1420px) {
          width: calc(100% - 250px);
        }

        @media (max-width: 1050px) {
          padding-left: 40px;
        }

        @media (max-width: 991px) {
          width: 100%;
          margin-top: 30px;
          padding-left: 0;
        }

        @media (max-width: 767px) {
          margin-top: 0;
        }

        &__top{
          margin-bottom: 50px;
          position: relative;

          @media (max-width: 767px) {
            margin-bottom: 15px;
            padding: 15px;
            border: 5px solid #003554;

            img{
              display: none;
            }
          }

          p{
            width: 100%;
            @include font(4rem, 700, #003554);
            line-height: 5.2rem;
            text-align: center;
            @include middle;
            top: 53%;

            @media (max-width: 1650px) {
              font-size: 3rem;
              line-height: 4.2rem;
            }

            @media (max-width: 1550px) {
              font-size: 2.2rem;
              line-height: 3.4rem;
            }

            @media (max-width: 1050px) {
              font-size: 2rem;
              line-height: 3.2rem;
            }

            @media (max-width: 767px) {
              padding: 0 10px;
              font-size: 1.6rem;
              line-height: 2.2rem;
              @include po-cancel;

              br{
                display: none;
              }
            }

            span{
              margin-bottom: 5px;
              display: block;
              @include font(2.4rem, 500, #003554);

              @media (max-width: 1550px) {
                margin-bottom: 0;
                font-size: 2rem;
              }

              @media (max-width: 1050px) {
                font-size: 1.6rem;
              }

              @media (max-width: 767px) {
                font-size: 1.4rem;
              }
            }
          }
        }

        &__bottom{
          p{
            @include font(2rem, 500, #666);
            line-height: 2.8rem;

            @media (max-width: 1050px) {
              font-size: 1.6rem;
              line-height: 2.4rem;
            }
          }
        }
      }

      &__list{
        margin-top: 80px;

        @media (max-width: 767px) {
          margin-top: 30px;
        }

        &__col{
          width: calc(50% - 20px);
          margin-right: 40px;
          padding: 55px;
          border: 1px solid #ddd;
          display: inline-block;
          vertical-align: top;

          @media (max-width: 991px) {
            width: 100%;
            height: auto!important;
            margin-right: 0;
            margin-bottom: 20px;
          }

          @media (max-width: 767px) {
            padding: 20px;
          }

          &:last-child{
            margin-right: 0;
            margin-bottom: 0;
          }
        }

        h4{
          margin-bottom: 30px;
          padding-right: 100px;
          display: inline-block;
          @include font(3.6rem, 700, #003554);
          line-height: 1;
          position: relative;

          &:before{
            content: '';
            width: 67px;
            height: 1px;
            display: inline-block;
            background-color: #003554;
            @include poa(auto, 20px, 0, auto);
          }

          &:after{
            content: '';
            width: 10px;
            height: 10px;
            display: inline-block;
            background-color: #003554;
            @include poa(auto, 0, 0, auto);
          }
        }

        ul{
          width: calc((100% - 70px) / 2);
          margin-right: 70px;
          display: inline-block;
          vertical-align: top;

          @media (max-width: 1050px) {
            width: calc((100% - 30px) / 2);
            margin-right: 30px;
          }

          @media (max-width: 767px) {
            width: 100%;
            margin-right: 0;
            margin-bottom: 20px;
          }

          &:last-child{
            margin-right: 0;
            margin-bottom: 0;
          }
        }

        li{
          margin-bottom: 20px;
          padding-left: 13px;
          @include font(1.8rem, 500, #666);
          position: relative;

          @media (max-width: 1420px) {
            font-size: 1.6rem;
          }

          &:last-child{
            margin-bottom: 0;
          }

          &:before{
            content: '';
            width: 3px;
            height: 3px;
            display: inline-block;
            background-color: #666;
            @include poa(10px, auto, auto, 0);
          }
        }
      }
    }
  }
}