.en{
    &-director{
        &__list{
            > ul{
                > li {
                    width: calc((100% - 80px) / 3);
                    margin-right: 40px;
                    margin-bottom: 60px;
                    border: 1px solid #dfdfdf;
                    border-radius: 5px;
                    display: inline-block;
                    vertical-align: top;

                    @media (max-width: 1320px) {
                        width: calc((100% - 40px) / 3);
                        margin-right: 20px;
                    }

                    @media (max-width: 991px) {
                        width: calc((100% - 20px) / 2);
                    }

                    @media (max-width: 767px) {
                        width: 100%;
                        margin-bottom: 30px;
                    }
                
                    &:nth-child(3n) {
                        margin-right: 0;

                        @media (max-width: 991px) {
                            margin-right: 20px;
                        }

                        @media (max-width: 767px) {
                            margin-right: 0;
                        }
                    }

                    &:nth-child(2n) {
                        @media (max-width: 991px) {
                            margin-right: 0;
                        }
                    }
                }
            }
        }

        &__tit{
            background-color: #e4f4ff;
            padding: 25px 15px;
            text-align: center;
            position: relative;

            @media (max-width: 767px) {
                padding: 15px;
            }

            img{
                @include poa(-1px, auto, auto, 20px);

                @media (max-width: 1320px) {
                    width: 44px;
                    left: 10px;
                }

                @media (max-width: 767px) {
                    width: 24px;
                }
            }

            h3{
                @include font(2rem, 400, #111);

                @media (max-width: 1550px) {
                    font-size: 1.4rem;
                }
            }

            p{
                margin-top: 5px;
                @include font(1.6rem, 400, #111);

                @media (max-width: 1550px) {
                    font-size: 1.4rem;
                }
            }
        }

        &__info{
            padding: 30px 40px;
            text-align: center;

            @media (max-width: 1320px) {
                padding: 30px;
            }

            @media (max-width: 767px) {
                padding: 30px 15px;
            }
        }

        &__img{
            margin-bottom: 40px;
            padding: 17px;
            border: 3px solid #0078c7;
            display: inline-block;
            box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, .2);

            @media (max-width: 1320px) {
                margin-bottom: 30px;
            }
        }

        &__text{
            text-align: left;

            @media (max-width: 767px) {
                height: auto!important;
            }

            li{
                margin-bottom: 5px;
                padding-left: 11px;
                @include font(1.6rem, 400, #555);
                position: relative;

                @media (max-width: 767px) {
                    font-size: 1.4rem;
                }

                &:before{
                    content: '';
                    width: 2px;
                    height: 2px;
                    display: inline-block;
                    background-color: #111;
                    @include poa(12px, auto, auto, 0);
                }

                &:last-child{
                    margin-bottom: 0;
                }
            }
        }
    }
}