.sign{
  padding-top: 70px;

  &-contents{
    &__btn{
      margin-top: 50px;
      text-align: center;

      a{
        width: 150px;
        height: 50px;
        border: 1px solid #dfdfdf;
        @include font(1.6rem, 500, #888);
        line-height: 48px;
        transition-duration: .3s;

        &:hover{
          background-color: #dfdfdf;
          color: #fff;
        }

        &:last-child{
          margin-left: 10px;
          border: 1px solid #0f8940;
          color: #0f8940;

          &:hover{
            background-color: #0f8940;
            color: #fff;
          }

          @media (max-width: 767px) {
            margin-left: 0;
          }
        }

        @media (max-width: 767px) {
          width: 50%;
        }
      }

      @media (max-width: 767px) {
        margin-top: 30px;
      }
    }
  }

  &-step1{
    &-contents{
      &__type{
        width: calc(50% - 20px);
        margin-right: 40px;
        padding: 55px 0 70px;
        border: 1px solid #e5e5e5;
        border-top: 5px solid #0f8940;
        display: inline-block;
        text-align: center;
        vertical-align: top;

        &:last-child{
          margin-right: 0;
          margin-bottom: 0;
        }

        img{
          margin-bottom: 30px;
          vertical-align: middle;
        }

        h3{
          margin-bottom: 20px;
          @include font(2.4rem, 700, #000);
          font-family: 'GmarketSans';
        }

        p{
          margin-bottom: 30px;
          @include font(1.6rem, 500, #666);
          line-height: 2.4rem;

          @media (max-width: 767px) {
            font-size: 1.4rem;
          }
        }

        a{
          width: 190px;
          height: 50px;
          margin-bottom: 30px;
          padding-left: 25px;
          border: 2px solid #dfdfdf;
          border-radius: 5px;
          @include font(1.6rem, 700, #000016);
          line-height: 46px;
          text-align: left;
          position: relative;
          transition-duration: .3s;

          &:after{
            content: '';
            width: 7px;
            height: 7px;
            border-top: 1px solid #000;
            border-right: 1px solid #000;
            @include middle(auto, 20px);
            transform: translateY(-50%) rotate(45deg);
          }

          &:hover{
            border: 2px solid #0f8940;
            background-color: #0f8940;
            color: #fff;

            &:after{
              border-top: 1px solid #fff;
              border-right: 1px solid #fff;
            }
          }
        }

        .c-t{
          margin-bottom: 0;
          color: #d34e12;
          vertical-align: middle;

          img{
            margin-bottom: 0;
            position: relative;
            bottom: 2px;
          }
        }

        @media (max-width: 991px) {
          padding: 55px 15px 70px;
        }

        @media (max-width: 767px) {
          width: 100%;
          margin-right: 0;
          margin-bottom: 30px;
          padding: 30px 15px;
        }
      }
    }
  }

  &-step2{
    &-contents{
      &__terms{
        margin-top: 50px;

        h3{
          margin-bottom: 10px;
          @include font(2.4rem, 700, #000);

          @media (max-width: 767px) {
            font-size: 2rem;
          }
        }

        &__wrap{
          padding: 20px 25px;
          border: 1px solid #e5e5e5;
          border-top: 3px solid #0f8940;
        }

        &__text{
          width: 100%;
          height: 210px;
          margin-bottom: 20px;
          overflow-y: auto;
          border-bottom: 1px solid #eaeaea;

          h4{
            margin-top: 20px;
            @include font(2rem, 700, #222);

            @media (max-width: 767px) {
              font-size: 1.8rem;
            }
          }

          p{
            @include font(1.6rem, 700, #888);
            line-height: 2.4rem;

            &.terms-sub-title{
              margin-top: 30px;
              @include font(1.8rem, 700, #0f8940);

              @media (max-width: 767px) {
                font-size: 1.6rem;
              }
            }

            &:last-child{
              margin-top: 30px;
            }

            @media (max-width: 767px) {
              font-size: 1.4rem;
            }
          }

          ul{
            padding-left: 20px;

            @media (max-width: 767px) {
              padding-left: 10px;
            }
          }

          li{
            @include font(1.6rem, 700, #888);
            line-height: 2.4rem;

            @media (max-width: 767px) {
              font-size: 1.4rem;
            }
          }

          table{
            width: 100%;
            table-layout: fixed;
          }

          th{
            padding: 10px;
            border: 1px solid #ddd;
            background-color: #ddd;
            @include font(1.6rem, 700, #888);
            word-break: break-all;

            @media (max-width: 767px) {
              font-size: 1.4rem;
            }
          }

          td{
            padding: 5px;
            border: 1px solid #ddd;
            @include font(1.6rem, 700, #888);
            text-align: center;
            vertical-align: middle;
            word-break: break-all;

            @media (max-width: 767px) {
              font-size: 1.4rem;
            }
          }
        }

        &__agree{
          text-align: center;

          input:checked+label{
            &:before{
              border: 2px solid #0f8940;
              background-color: #0f8940;
            }
          }

          label{
            padding-left: 30px;
            @include font(1.7rem, 700, #5d5d5d);
            text-align: left;
            position: relative;

            &:nth-of-type(1){
              margin-right: 50px;
            }

            &:before{
              content: '';
              width: 15px;
              height: 15px;
              border-radius: 20px;
              border: 2px solid #c1c1c1;
              @include middle;
            }

            @media (max-width: 767px) {
              margin: 5px 0;
              display: block;
              font-size: 1.4rem;
            }
          }
        }

        @media (max-width: 767px) {
          margin-top: 30px;
        }
      }

      &__all-agree{
        margin-top: 50px;
        padding: 36px 0;
        background-color: #f8f8f8;
        text-align: center;

        input:checked+label{
          &:before{
            border: 2px solid #0f8940;
            background-color: #0f8940;
          }
        }

        label{
          padding-left: 45px;
          @include font(2rem, 700, #000);
          position: relative;

          &:before{
            content: '';
            width: 18px;
            height: 18px;
            border: 2px solid #c1c1c1;
            display: inline-block;
            background-color: #fff;
            @include middle;
          }

          @media (max-width: 767px) {
            padding-left: 35px;
            font-size: 1.6rem;
          }
        }

        @media (max-width: 767px) {
          margin-top: 30px;
        }
      }
    }
  }

  &-step3{
    &-contents{
      &__text{
        margin-top: 50px;
        padding: 26px 0;
        background-color: #f8f8f8;
        text-align: center;

        p{
          @include font(1.6rem, 500, #666);

          @media (max-width: 767px) {
            font-size: 1.4rem;
          }
        }

        @media (max-width: 767px) {
          margin-top: 30px;
        }
      }

      &__certification{
        margin-top: 50px;
        padding: 50px 75px;
        border: 1px solid #f7f7f7;
        border-top: 3px solid #0f8940;

        > div{
          display: inline-block;
          vertical-align: middle;
        }

        &__img{
          width: 170px;
          margin-right: 75px;

          @media (max-width: 1230px) {
            width: 150px;
            margin-right: 50px;
          }

          @media (max-width: 991px) {
            width: 100%;
            margin-right: 0;
            margin-bottom: 30px;
            text-align: center;
          }

          @media (max-width: 767px) {
            margin-bottom: 10px;

            img{
              width: 130px;
            }
          }
        }

        &__text{
          width: calc(100% - 600px);

          h3{
            margin-bottom: 20px;
            @include font(2.4rem, 700, #222);

            @media (max-width: 767px) {
              margin-bottom: 10px;
            }
          }

          p{
            margin-bottom: 20px;
            @include font(1.6rem, 500, #666);
            line-height: 2.4rem;

            &.c-t{
              margin-bottom: 0;
              padding-left: 30px;
              color: #d34e12;
              position: relative;

              img{
                @include poa(5px, auto, auto, 0);

                @media (max-width: 767px) {
                  @include po-cancel;
                  vertical-align: middle;
                }
              }

              @media (max-width: 767px) {
                padding-left: 0;
              }
            }

            @media (max-width: 767px) {
              font-size: 1.4rem;
            }
          }

          @media (max-width: 1230px) {
            width: calc(100% - 450px);
          }

          @media (max-width: 991px) {
            width: 100%;
            margin-bottom: 30px;
          }
        }

        &__btn{
          width: 200px;
          margin-left: 155px;

          a{
            width: 200px;
            height: 80px;
            border-radius: 10px;
            border: 2px solid #dfdfdf;
            @include font(1.8rem, 700, #000016);
            line-height: 76px;
            text-align: center;

            &.certification-btn{
              margin-bottom: 10px;
              border: 2px solid #0f8940;
              background-color: #0f8940;
              color: #fff;

              @media (max-width: 991px) {
                margin-right: 15px;
                margin-bottom: 0;
              }

              @media (max-width: 767px) {
                margin-right: 0;
                margin-bottom: 10px;
              }
            }

            @media (max-width: 991px) {
              width: 100%;
            }

            @media (max-width: 767px) {
              height: 50px;
              line-height: 50px;
            }
          }

          @media (max-width: 1230px) {
            margin-left: 50px;
          }

          @media (max-width: 991px) {
            width: 100%;
            margin-left: 0;
            text-align: center;
          }
        }

        @media (max-width: 1230px) {
          padding: 50px;
        }

        @media (max-width: 991px) {
          padding: 30px 50px;
        }

        @media (max-width: 767px) {
          margin-top: 30px;
          padding: 30px 15px;
        }
      }
    }
  }

  &-step4{
    &-contents{
      &__essential{
        margin-top: 50px;

        @media (max-width: 767px) {
          margin-top: 30px;
        }
      }

      &__option{
        margin-top: 50px;

        @media (max-width: 767px) {
          margin-top: 30px;
        }
      }

      &__tit{
        margin-bottom: 10px;

        h3{
          width: 50%;
          display: inline-block;
          @include font(2.4rem, 700, #222);
          vertical-align: bottom;

          @media (max-width: 767px) {
            width: 100%;
            margin-bottom: 5px;
          }
        }

        p{
          width: 50%;
          display: inline-block;
          @include font(1.6rem, 500, #d34e12);
          vertical-align: bottom;
          text-align: right;

          img{
            vertical-align: middle;
          }

          @media (max-width: 767px) {
            width: 100%;
            font-size: 1.2rem;
          }
        }

        @media (max-width: 767px) {
          margin-bottom: 5px;
        }
      }

      &__info{
        col{
          width: 215px;

          &:last-child{
            width: calc(100% - 215px);
          }
        }

        .id-confirm{
          width: 130px;
          height: 40px;
          margin-left: 5px;
          border: 1px solid #0f8940;
          @include font(1.4rem, 700, #0f8940);
          text-align: center;
          line-height: 38px;
          vertical-align: middle;
          transition-duration: .3s;

          &:hover{
            background-color: #0f8940;
            color: #fff;
          }

          @media (max-width: 767px) {
            width: 100%;
            margin-left: 0;
          }
        }

        .sub-text{
          margin-top: 5px;
          @include font(1.5rem, 500, #999);

          @media (max-width: 767px) {
            font-size: 1.3rem;
          }
        }

        span{
          @include font(1.5rem, 500, #d45251);

          @media (max-width: 767px) {
            font-size: 1.3rem;
          }
        }

        .between-text{
          display: inline-block;
          vertical-align: middle;
          @include font(1.5rem, 500, #000);

          @media (max-width: 767px) {
            margin-left: 5px;
          }
        }

        .global-board{
          @media (max-width: 767px) {
            width: 100%;
            display: inline-block;
          }
        }

        tbody{
          @media (max-width: 767px) {
            width: 100%;
            display: inline-block;
          }
        }

        tr{
          @media (max-width: 767px) {
            width: 100%;
            border-bottom: 1px solid #000;
            display: inline-block;
          }
        }

        th{
          @media (max-width: 767px) {
            width: 100%;
            border-bottom: 1px solid #ddd;
            display: inline-block;
          }
        }

        td{
          @media (max-width: 767px) {
            width: 100%;
            display: inline-block;
          }
        }

        input{
          @media (max-width: 767px) {
            margin: 5px 0;
          }
        }

        input:not([type="radio"]), input:not([type="checkbox"]){
          @media (max-width: 767px) {
            max-width: 100%;
          }
        }

        input[type="number"]{
          @media (max-width: 767px) {
            max-width: 100%;
          }
        }

        select{
          @media (max-width: 767px) {
            width: 100%;
            margin: 5px 0;
          }
        }

        .birth{
          select{
            @media (max-width: 767px) {
              width: calc(100% - 19px);
            }
          }
        }

        .phone{
          select{
            @media (max-width: 767px) {
              width: calc(100% - 11px);
            }
          }

          input[type="number"]{
            @media (max-width: 767px) {
              max-width: calc(100% - 11px);
            }
          }
        }
      }
    }
  }

  &-step5{
    &-contents{
      &__text{
        margin-top: 50px;
        text-align: center;

        img{
          margin-bottom: 80px;

          @media (max-width: 767px) {
            margin-bottom: 30px;
          }
        }

        h3{
          margin-bottom: 25px;
          @include font(2.4rem, 500, #222);

          span{
            font-weight: 700;
            color: #0f8940;
          }

          @media (max-width: 767px) {
            font-size: 2rem;
          }
        }

        p{
          @include font(1.6rem, 500, #666);

          @media (max-width: 767px) {
            font-size: 1.4rem;
          }
        }

        @media (max-width: 767px) {
          margin-top: 30px;
        }
      }
    }
  }
}
