.en{
    &-text-box{
        &__l-text{
            margin-bottom: 50px;
            @include font(2.6rem, 400, #111);
            line-height: 3.6rem;

            @media (max-width: 767px) {
                font-size: 2.2rem;
                font-weight: 3.2rem;
            }
        }

        &__text{
            @include font(1.6rem, 400, #555);
            line-height: 2.9rem;

            &.mb-15{
                margin-bottom: 15px;
            }

            &.mb-30{
                margin-bottom: 30px;
            }

            @media (max-width: 767px) {
                font-size: 1.4rem;
                line-height: 2.7rem;
            }
        }

        &__img{
            margin-top: 50px;

            li{
                width: calc((100% - 30px) / 3);
                margin-right: 15px;
                display: inline-block;
                vertical-align: top;

                @media (max-width: 767px) {
                    width: 100%;
                    margin-right: 0;
                    margin-bottom: 15px;
                }

                &:nth-child(3n){
                    margin-right: 0;
                    margin-bottom: 0;
                }

                img{
                    width: 100%;
                }
            }
        }

        &__tit{
            margin-bottom: 15px;
            padding-top: 15px;
            @include font(2.6rem, 700, #111);
            position: relative;
            
            @media (max-width: 767px) {
                font-size: 2.2rem;
            }

            &.mt-50{
                margin-top: 50px;

                @media (max-width: 767px) {
                    margin-top: 25px;
                }
            }

            span{
                margin-left: 15px;
                display: inline-block;
                @include font(1.6rem, 400, #555);
                vertical-align: middle;

                @media (max-width: 767px) {
                    margin-left: 0;
                    display: block;
                    font-size: 1.4rem;
                }
            }

            &:before{
                content: '';
                width: 7px;
                height: 7px;
                border-radius: 7px;
                display: inline-block;
                background-color: #8b8e91;
                @include poa(0, auto, auto, 0);
                box-sizing: border-box;
            }

            &:after{
                content: '';
                width: 7px;
                height: 7px;
                border-radius: 7px;
                display: inline-block;
                background-color: #0078c7;
                @include poa(0, auto, auto, 10px);
                box-sizing: border-box;
            }
        }

        &__sub-tit{
            margin-bottom: 15px;
            padding-left: 25px;
            @include font(1.6rem, 700, #111);
            position: relative;

            &:before {
                content: '';
                width: 16px;
                height: 16px;
                border: 4px solid #0078c7;
                border-radius: 16px;
                display: inline-block;
                background-color: #fff;
                @include middle;
                top: 60%;
                box-sizing: border-box;

                @media (max-width: 767px) {
                    width: 12px;
                    height: 12px;
                    border: 3px solid #0078c7;
                }
            }

            &+.en-text-box__text{
                margin-bottom: 30px;
            }
        }

        &__list{
            margin-bottom: 30px;

            &.sub{
                padding-left: 25px;

                @media (max-width: 767px) {
                    padding-left: 15px;
                }
            }

            &.mb-15{
                margin-bottom: 15px;
            }

            li{
                margin-bottom: 10px;
                padding-left: 10px;
                @include font(1.6rem, 400, #555);
                position: relative;

                @media (max-width: 767px) {
                    font-size: 1.4rem;
                }

                &:before{
                    content: '';
                    width: 2px;
                    height: 2px;
                    display: inline-block;
                    background-color: #111;
                    @include poa(12px, auto, auto, 0);
                }

                &:last-child{
                    margin-bottom: 0;
                }
            }
        }

        &__table{
            border-top: 2px solid #4d4d4d;
            overflow-x: auto;

            &.mb-30{
                margin-bottom: 30px;
            }

            table{
                width: 100%;
                min-width: 800px;
                table-layout: fixed;
            }

            tr{
                border-bottom: 1px solid #c1c1c1;
            }

            th{
                height: 50px;
                border-right: 1px solid #c1c1c1;
                background-color: #fafafa;
                @include font(1.4rem, 700, #111);

                &:last-child{
                    border-right: none;
                }
            }

            td{
                height: 50px;
                padding: 15px;
                border-right: 1px solid #c1c1c1;
                @include font(1.4rem, 400, #555);
                text-align: center;
                vertical-align: middle;

                &:last-child{
                    border-right: none;
                }
            }
        }

        &__c-t{
            margin-bottom: 10px;
            padding-left: 35px;
            @include font(1.4rem, 400, #0078c7);

            @media (max-width: 767px) {
                padding-left: 25px;
            }

            &.pl-0{
                padding-left: 0;
            }
        }

        &__btn{
            margin-top: 40px;
            padding-top: 40px;
            border-top: 1px solid #c1c1c1;
            text-align: center;

            &.bd-n{
                border-top: none;
            }

            &.pt-0{
                padding-top: 0;
            }

            a{
                max-width: 100%;
                padding: 15px 100px 20px;
                border-radius: 100px;
                background-color: #e3ebf4;
                @include font(1.6rem, 700, #111);

                @media (max-width: 767px) {
                    width: 100%;
                    padding: 15px 10px 20px;
                    font-size: 1.4rem;
                }

                span{
                    font-weight: 400;
                    color: #555;
                }
            }
        }

        &__route{
            &-list{
                margin-bottom: 25px;
                padding-left: 170px;
                position: relative;

                @media (max-width: 991px) {
                    padding-left: 15px;
                    padding-top: 45px;
                }

                &:last-child{
                    margin-bottom: 0;
                }

                h4{
                    width: 45px;
                    height: 45px;
                    display: inline-block;
                    background-color: #000;
                    @include font(2.2rem, 700, #fff);
                    text-align: center;
                    line-height: 45px;
                    @include middle;
                    z-index: 2;

                    @media (max-width: 991px) {
                        width: 35px;
                        height: 35px;
                        line-height: 35px;
                        font-size: 2rem;
                        top: 4px;
                        transform: none;
                    }
                }

                h3{
                    width: 340px;
                    height: 63px;
                    display: inline-block;
                    background-color: #1976d2;
                    @include font(1.4rem, 700, #fff);
                    text-align: center;
                    line-height: 63px;
                    @include middle(15px);
                    z-index: 1;

                    @media (max-width: 991px) {
                        width: calc(100% - 15px);
                        height: 45px;
                        line-height: 45px;
                        transform: none;
                        top: 0;
                    }

                    &:after{
                        content: '';
                        border-left: 25px solid #1976d2;
                        border-top: 32px solid transparent;
                        border-bottom: 32px solid transparent;
                        border-right: none;
                        display: inline-block;
                        box-sizing: border-box;
                        @include middle(auto, -25px);

                        @media (max-width: 991px) {
                            display: none;
                        }
                    }
                }

                div{
                    width: 100%;
                    height: 83px;
                    display: inline-block;
                    border-radius: 10px;
                    background-color: #f2f5f9;
                    position: relative;

                    @media (max-width: 1320px) {
                        height: 110px;
                    }

                    @media (max-width: 991px) {
                        height: auto;
                        padding: 15px;
                        border-radius: 0;
                    }

                    p{
                        padding-right: 15px;
                        @include font(1.6rem, 400, #111);
                        @include middle(245px);

                        @media (max-width: 991px) {
                            @include po-cancel;
                        }
                    }
                }
            }
        }
    }
}