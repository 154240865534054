.ci{
  .board-text{
    @media (max-width: 767px) {
      margin-bottom: 10px;
    }
  }

  &-contents1{
    margin-bottom: 60px;

    @media (max-width: 767px) {
      margin-bottom: 30px;
    }

    img{
      margin: 0 100px;
      vertical-align: middle;

      @media (max-width: 1320px) {
        margin: 0 50px;
      }

      @media (max-width: 991px) {
        margin: 0 30px;
      }
    }
  }

  &-contents2{
    margin-bottom: 60px;

    @media (max-width: 767px) {
      margin-bottom: 30px;
    }
  }

  p{
    @include font(1.8rem, 500, #666);
    line-height: 2.8rem;

    @media (max-width: 767px) {
      font-size: 1.6rem;
      line-height: 2.6rem;
    }
  }

  &-img{
    margin-top: 40px;
    padding: 60px 15px;
    border: 1px solid #ddd;
    text-align: center;

    @media (max-width: 767px) {
      margin-top: 15px;
    }
  }

  li{
    margin-bottom: 30px;
    @include font(1.8rem, 500, #666);
    line-height: 2.8rem;

    @media (max-width: 767px) {
      margin-bottom: 15px;
      font-size: 1.6rem;
      line-height: 2.6rem;
    }

    span{
      font-weight: 700;
      color: #9c7e29;
    }
  }

  &-btn{
    margin-top: 80px;
    text-align: center;

    @media (max-width: 767px) {
      margin-top: 15px;
    }

    a{
      width: 240px;
      height: 62px;
      margin-right: 10px;
      border: 1px solid #003554;
      @include font(1.8rem, 500, #003554);
      text-align: center;
      line-height: 60px;

      @media (max-width: 1320px) {
        width: 180px;
        font-size: 1.6rem;
      }

      @media (max-width: 991px) {
        width: calc(50% - 5px);
        margin-bottom: 10px;
        
        &:nth-child(2n) {
          margin-right: 0;
        }
      }

      @media (max-width: 767px) {
        width: 100%;
        margin-right: 0;
      }

      &:nth-child(2n) {
        @media (max-width: 991px) {        
          margin-right: 0;
        }
      }

      &:last-child{
        margin-right: 0;
      }

      &:hover{
        background-color: #003554;
        transition-duration: .3s;
        color: #fff;

        img{
          &:nth-of-type(1){
            display: none;
          }

          &:nth-of-type(2){
            display: inline-block;
          }
        }
      }
    }

    // p{
    //   display: inline-block;
    //   @include font(1.8rem, 500, #003554);
    //   line-height: 60px;
    //   vertical-align: middle;

    //   @media (max-width: 1320px) {
    //     font-size: 1.6rem;
    //   }
    // }
    
    img{
      margin: 0 0 0 15px;
      vertical-align: middle;
      position: relative;
      bottom: 2px;

      @media (max-width: 1320px) {
        margin-left: 10px;
      }

      &:nth-of-type(2){
        display: none;
      }
    }
  }
}

.hidden-custom{
  @media (max-width: 1400px) {
    display: none;
  }

  &-1050{
    @media (max-width: 1050px) {
      display: none;
    }
  }
}