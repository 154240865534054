.board-text{
  margin-bottom: 30px;
  padding-right: 100px;
  display: inline-block;
  @include font(3.6rem, 700, #003554);
  position: relative;

  @media (max-width: 991px) {
    font-size: 3rem;
  }

  @media (max-width: 767px) {
    margin-bottom: 20px;
    font-size: 2.2rem;
    padding-right: 0;
  }

  &:before {
    content: '';
    width: 67px;
    height: 1px;
    display: inline-block;
    background-color: #003554;
    @include poa(auto, 20px, 0, auto);

    @media (max-width: 767px) {
      display: none;
    }
  }
  
  &:after {
    content: '';
    width: 10px;
    height: 10px;
    display: inline-block;
    background-color: #003554;
    @include poa(auto, 0, 0, auto);

    @media (max-width: 767px) {
      display: none;
    }
  }
}

.board-link{
  margin-bottom: 40px;

  @media (max-width: 767px) {
    margin-bottom: 30px;
  }

  a{
    margin-right: 10px;
    padding: 0 30px;
    background-color: #003554;
    @include font(1.8rem, 500, #fff);
    vertical-align: middle;
    line-height: 64px;

    @media (max-width: 767px) {
      width: 100%;
      margin-right: 0;
      margin-bottom: 10px;
      text-align: center;
    }

    &:last-child{
      margin-right: 0;
      margin-bottom: 0;
    }

    img{
      margin-left: 10px;
      vertical-align: middle;
      position: relative;
      bottom: 2px;
    }
  }
}

.board-search{
  &-area{
    margin-bottom: 40px;
    padding: 50px 15px;
    background-color: #f9f9f9;
    text-align: center;

    @media (max-width: 767px) {
      margin-bottom: 30px;
      padding: 30px 15px;
    }
  }

  &-option, &-text{
    height: 50px;
    padding: 0 20px;
    border: 1px solid #ddd;
    display: inline-block;
    background-color: #fff;
    @include font(1.4rem, 500, #666);
  }

  &-option{
    width: 150px;
    margin-right: 5px;

    @media (max-width: 767px) {
      width: 100%;
      margin-right: 0;
      margin-bottom: 5px;
    }
  }

  &-text{
    width: 320px;

    @media (max-width: 767px) {
      width: 100%;
    }

    &::placeholder{
      @include font(1.4rem, 500, #666);
    }
  }

  &-enter{
    width: 85px;
    height: 50px;
    display: inline-block;
    background-color: #222;
    @include font(1.4rem, 500, #fff);
    text-align: center;
    line-height: 50px;

    @media (max-width: 767px) {
      width: 100%;
    }
  }
}

.board-wrap{
  width: 100%;
  word-break: break-all;

  &.ofa{
    overflow-x: auto;

    table{
      min-width: 1200px;

      @media (max-width: 767px) {
        width: 800px;
      }
    }
  }

  table{
    width: 100%;
    border-top: 2px solid #222;
    table-layout: fixed;
  }

  .w-5{width: 5%;}
  .w-10{width: 10%;}
  .w-15{width: 15%;}
  .w-20{width: 20%;}
  .w-25{width: 25%;}
  .w-30{width: 30%;}
  .w-35{width: 35%;}
  .w-40{width: 40%;}
  .w-45{width: 45%;}
  .w-50{width: 50%;}
  .w-55{width: 55%;}
  .w-60{width: 60%;}
  .w-65{width: 65%;}
  .w-70{width: 70%;}
  .w-75{width: 75%;}
  .w-80{width: 80%;}
  .w-85{width: 85%;}
  .w-90{width: 90%;}
  .w-95{width: 95%;}
  .w-100{width: 100%;}

  .ta-l{text-align: left;}
  .ta-c{text-align: center;}
  .ta-r{text-align: right;}
  .va-t{vertical-align: top;}
  .va-m{vertical-align: middle;}
  .va-b{vertical-align: bottom;}

  @media (max-width: 1200px) {
    .w-md-5{width: 5%;}
    .w-md-10{width: 10%;}
    .w-md-15{width: 15%;}
    .w-md-20{width: 20%;}
    .w-md-25{width: 25%;}
    .w-md-30{width: 30%;}
    .w-md-35{width: 35%;}
    .w-md-40{width: 40%;}
    .w-md-45{width: 45%;}
    .w-md-50{width: 50%;}
    .w-md-55{width: 55%;}
    .w-md-60{width: 60%;}
    .w-md-65{width: 65%;}
    .w-md-70{width: 70%;}
    .w-md-75{width: 75%;}
    .w-md-80{width: 80%;}
    .w-md-85{width: 85%;}
    .w-md-90{width: 90%;}
    .w-md-95{width: 95%;}
    .w-md-100{width: 100%;}
    .w-md-n{display: none;}
  }

  @media (max-width: 991px) {
    .w-sm-5{width: 5%;}
    .w-sm-10{width: 10%;}
    .w-sm-15{width: 15%;}
    .w-sm-20{width: 20%;}
    .w-sm-25{width: 25%;}
    .w-sm-30{width: 30%;}
    .w-sm-35{width: 35%;}
    .w-sm-40{width: 40%;}
    .w-sm-45{width: 45%;}
    .w-sm-50{width: 50%;}
    .w-sm-55{width: 55%;}
    .w-sm-60{width: 60%;}
    .w-sm-65{width: 65%;}
    .w-sm-70{width: 70%;}
    .w-sm-75{width: 75%;}
    .w-sm-80{width: 80%;}
    .w-sm-85{width: 85%;}
    .w-sm-90{width: 90%;}
    .w-sm-95{width: 95%;}
    .w-sm-100{width: 100%;}
    .w-sm-n{display: none;}
  }

  @media (max-width: 767px) {
    .w-xs-5{width: 5%;}
    .w-xs-10{width: 10%;}
    .w-xs-15{width: 15%;}
    .w-xs-20{width: 20%;}
    .w-xs-25{width: 25%;}
    .w-xs-30{width: 30%;}
    .w-xs-35{width: 35%;}
    .w-xs-40{width: 40%;}
    .w-xs-45{width: 45%;}
    .w-xs-50{width: 50%;}
    .w-xs-55{width: 55%;}
    .w-xs-60{width: 60%;}
    .w-xs-65{width: 65%;}
    .w-xs-70{width: 70%;}
    .w-xs-75{width: 75%;}
    .w-xs-80{width: 80%;}
    .w-xs-85{width: 85%;}
    .w-xs-90{width: 90%;}
    .w-xs-95{width: 95%;}
    .w-xs-100{width: 100%;}
    .w-xs-n{display: none;}
  }

  tr{
    border-bottom: 1px solid #ddd;

    &.bg{
      background-color: #f9f9f9;
    }
  }

  th{
    height: 70px;
    @include font(1.4rem, 500, #222);
  }

  td{
    padding: 30px 5px;
    vertical-align: middle;
  }

  p{
    @include font(1.6rem, 500, #666);
    text-align: center;
  }

  h4{
    width: 45px;
    height: 23px;
    margin: 0 auto;
    background-color: #9c7e29;
    @include font(1.4rem, 500, #fff);
    text-align: center;
    line-height: 23px;
  }

  a{
    max-width: 100%;
    @include line-1;
    @include font(1.8rem, 500, #222);

    span{
      margin-right: 5px;
      display: inline-block;
      color: #003554;
    }
  }

  h3{
    width: 100%;

    span{
      margin-right: 5px;
      display: inline-block;
      color: #003554;
    }
  }

  img{
    margin: 0 auto;
    display: block;
  }
}

.gallery{
  &-wrap{
    width: 100%;

    &+.paging{
      margin-top: 20px;
    }

    ul{
      padding-top: 40px;
      border-top: 2px solid #222;

      @media (max-width: 1260px) {
        padding-top: 20px;
      }
    }

    li{
      width: calc((100% - 80px) / 3);
      margin: 0 40px 40px 0;
      border: 1px solid #ddd;
      display: inline-block;
      vertical-align: top;

      @media (max-width: 1260px) {
        width: calc((100% - 40px) / 3);
        margin: 0 20px 20px 0;
      }

      @media (max-width: 991px) {
        width: calc((100% - 20px) / 2);
      }

      @media (max-width: 767px) {
        width: 100%;
        margin-right: 0;
      }

      &:nth-child(3n){
        margin-right: 0;

        @media (max-width: 991px) {
          margin-right: 20px;
        }

        @media (max-width: 767px) {
          margin-right: 0;
        }
      }

      &:nth-child(2n){
        @media (max-width: 991px) {
          margin-right: 0;
        }
      }
    }

    a{
      width: 100%;
    }
  }

  &-img{
    width: 100%;
    padding-bottom: 56.25%;
    position: relative;
    overflow: hidden;

    img{
      width: 100%;
      @include poa(0, auto, auto, 0);
    }

    &.ha{
      padding-bottom: 0;

      img{
        @include po-cancel;
      }
    }
  }

  &-text{
    padding: 20px 0;
    border-top: 1px solid #ddd;
    border-top: none;
    text-align: center;
  }

  &-tit{
    padding: 0 10px;
    @include font(1.8rem, 500, #222);
    @include line-1;

    @media (max-width: 1260px) {
      font-size: 1.6rem;
    }

    @media (max-width: 767px) {
      font-size: 1.4rem;
    }
  }
}


.poomsae-board{
  &-wrap{
    border-top: 2px solid #222;

    li{
      padding: 40px;
      border-bottom: 1px solid #ddd;

      @media (max-width: 1150px) {
        padding: 20px;
      }

      a{
        width: 100%;

        > p{
          width: 130px;
          height: 130px;
          display: inline-block;
          background-color: #003554;
          vertical-align: middle;
          position: relative;

          @media (max-width: 991px) {
            width: 90px;
            height: 215px;
          }

          @media (max-width: 767px) {
            width: 100%;
            height: 40px;
          }

          span{
            width: 100%;
            padding-bottom: 30px;
            @include font(1.6rem, 500, #fff);
            text-align: center;
            @include middle;

            @media (max-width: 991px) {
              font-size: 1.4rem;
            }

            @media (max-width: 767px) {
              padding-bottom: 0;
            }

            &:after{
              content: '';
              width: 10px;
              height: 10px;
              border-top: 1px solid #fff;
              border-left: 1px solid #fff;
              display: inline-block;
              @include center(auto, 0);
              transform: translateX(-70%) rotate(135deg);
              box-sizing: border-box;

              @media (max-width: 767px) {
                display: none;
              }
            }
          }
        }
      }
    }
  }

  &__img{
    width: 320px;
    height: 215px;
    border: 1px solid #ddd;
    display: inline-block;
    background-color: #f9f9f9;
    vertical-align: middle;
    position: relative;
    overflow: hidden;

    @media (max-width: 1150px) {
      width: 250px;
    }

    @media (max-width: 991px) {
      width: 180px;
    }

    @media (max-width: 767px) {
      width: 100%;
      height: auto;
      padding-bottom: 100%;
    }

    img{
      @include poa-center;

      @media (max-width: 991px) {
        max-height: 90%;
      }
    }
  }

  &__text{
    width: calc(100% - 450px);
    padding: 0 60px;
    display: inline-block;
    vertical-align: middle;

    @media (max-width: 1420px) {
      padding: 0 30px;
    }

    @media (max-width: 1150px) {
      width: calc(100% - 380px);
    }

    @media (max-width: 991px) {
      width: calc(100% - 270px);
    }

    @media (max-width: 767px) {
      width: 100%;
      padding: 10px 0;
    }

    h4{
      margin-bottom: 20px;
      @include font(3rem, 700, #222);
      line-height: 1;

      @media (max-width: 1420px) {
        font-size: 2.4rem;
      }

      @media (max-width: 991px) {
        margin-bottom: 10px;
        font-size: 2.2rem;
      }
    }

    div{
      height: 14rem;
      @include font(1.6rem!important, 500, #666);
      line-height: 2.6rem;
      overflow: hidden;

      *{
        @include font(1.6rem !important, 500, #666);
        line-height: 2.6rem!important;
      }

      @media (max-width: 991px) {
        font-size: 1.4rem!important;
        line-height: 2.4rem;

        *{
          font-size: 1.4rem!important;
        }
      }
    }
  }
}