@mixin round ($w) {
  width: $w;
  height: $w;
  border-radius: $w;
}

@mixin button ($w, $h, $bd, $bg) {
  width: $w;
  height: $h;
  border: $bd;
  background-color: $bg;
  cursor: pointer;
}

@mixin poa ($t: auto, $r: auto, $b: auto, $l: auto){
  position: absolute;
  top: $t;
  right: $r;
  bottom: $b;
  left: $l;
}

@mixin pof ($t: auto, $r: auto, $b: auto, $l: auto){
  position: fixed;
  top: $t;
  right: $r;
  bottom: $b;
  left: $l;
}

@mixin middle ($l: 0, $r: auto){
  position: absolute;
  top: 50%;
  left: $l;
  right: $r;
  transform: translateY(-50%);
}

@mixin center ($t: 0, $b: auto){
  position: absolute;
  top: $t;
  bottom: $b;
  left: 50%;
  transform: translateX(-50%);
}

@mixin poa-center{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin pof-center{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin pof-middle ($l: 0, $r: auto) {
  position: fixed;
  top: 50%;
  left: $l;
  right: $r;
  transform: translateY(-50%);
}

@mixin po-cancel{
  position: initial;
  transform: none;
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
}

@mixin bgi($url){
  background-image: url($url);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

@mixin slick-arrows($w, $h, $url){
  width: $w;
  height: $h;
  text-indent: -9999px;
  background-image: url($url);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  transform: none;
  z-index: 1;
}

@mixin line-clamp ($h, $lh, $line) {
  height: $h;
  line-height: $lh;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
}

@mixin line-clamp-ha ($h, $lh, $line) {
  height: auto;
  max-height: $h;
  line-height: $lh;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
  white-space: normal;
}

@mixin line-1 () {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
