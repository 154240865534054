.dispatch{
  &-text{
    h3{
      &:nth-of-type(2){
        margin-top: 110px;
        margin-bottom: 40px;

        @media (max-width: 767px) {
          margin-top: 60px;
          margin-bottom: 20px;
        }
      }
    }

    ul{
      text-align: left;
    }

    li{
      width: calc((100% - 180px) / 5);
      margin-right: 45px;
      display: inline-block;
      vertical-align: top;
      position: relative;

      @media (max-width: 1250px) {
        width: calc((100% - 80px) / 5);
        margin-right: 20px;
      }

      @media (max-width: 991px) {
        width: calc((100% - 60px) / 3);
        margin: 0 30px 30px 0;

        &:nth-child(3) {
          margin-right: 0;
        }
      }

      @media (max-width: 991px) {
        width: 100%;
        margin-right: 0;
      }

      &:last-child{
        margin-right: 0;

        .dispatch-text__step{
          &:before{
            display: none;
          }

          &:after{
            display: none;
          }
        }
      }

      >p{
        @include font(1.6rem, 500, #666);
        line-height: 2.6rem;

        @media (max-width: 1250px) {
          word-break: break-all;
        }

        img{
          position: relative;
          top: 6px;
        }
      }
    }

    &__step{
      width: 100%;
      margin-bottom: 30px;
      padding-bottom: 100%;
      border: 2px solid #003554;
      border-radius: 300px;
      position: relative;
      z-index: 1;

      @media (max-width: 767px) {
        margin-bottom: 10px;
      }

      &:before{
        content: '';
        width: 43px;
        height: 1px;
        display: inline-block;
        border-top: 1px dotted #ccc;
        @include middle(auto, -45px);
        box-sizing: border-box;
        z-index: -1;

        @media (max-width: 1250px) {
          width: 18px;
          right: -20px;
        }

        @media (max-width: 991px) {
          width: 28px;
          right: -30px;
        }

        @media (max-width: 767px) {
          display: none;
        }
      }

      &:after{
        content: '';
        width: 10px;
        height: 13px;
        border-left: 10px solid #ccc;
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
        border-right: none;
        display: inline-block;
        @include middle(auto, -30px);
        box-sizing: border-box;
        z-index: -1;

        @media (max-width: 1250px) {
          right: -17px;
        }

        @media (max-width: 991px) {
          right: -23px;
        }

        @media (max-width: 767px) {
          display: none;
        }
      }

      &.step-bg{
        background-color: #003554;

        p{
          color: #fff;
        }

        h4{
          color: #fff;
        }
      }

      div{
        width: 100%;
        display: inline-block;
        text-align: center;
        @include middle;
        letter-spacing: -1px;

        p{
          margin-bottom: 10px;
          @include font(1.3rem, 500, #003554);
          line-height: 1;
        }

        h4{
          @include font(2.2rem, 500, #222);

          @media (max-width: 1650px) {
            font-size: 1.8rem;
          }
        }
      }
    }
  }

  &-map{
    margin-bottom: 40px;
    padding: 60px 99px;
    border: 1px solid #ddd;
    position: relative;

    @media (max-width: 991px) {
      padding: 0;
      border: none;
    }

    >img{
      @media (max-width: 991px) {
        display: none;
      }
    }

    &__point{
      font-size: 0;
      position: absolute;
      
      @media (max-width: 991px) {
        width: 20%;
        border: 1px solid #ddd;
        @include po-cancel;
        @include font(2rem, 700, #000);
        text-align: center;
        line-height: 3.6rem;
        opacity: 1;
      }

      @media (max-width: 767px) {
        width: 100%;
        font-size: 1.6rem;
        line-height: 3rem;
      }

      &[data-dispatch='0']{
        top: 10%;
        right: 6.4%;

        @media (max-width: 1720px) {
          top: 11%;
          right: 7.2%;
        }

        @media (max-width: 1520px) {
          top: 12.3%;
          right: 8.4%;
        }

        @media (max-width: 1320px) {
          top: 13.8%;
          right: 10%;
        }

        @media (max-width: 1200px) {
          top: 15.8%;
          right: 11.8%;
        }
      }

      &[data-dispatch='1']{
        bottom: 12.8%;
        right: 9.1%;

        @media (max-width: 1720px) {
          bottom: 13.6%;
          right: 9.8%;
        }

        @media (max-width: 1520px) {
          bottom: 14.9%;
          right: 11.1%;
        }

        @media (max-width: 1320px) {
          bottom: 16.3%;
          right: 12.5%;
        }

        @media (max-width: 1200px) {
          bottom: 18.2%;
          right: 14.3%;
        }
      }

      &[data-dispatch='2']{
        top: 43.5%;
        right: 40.3%;

        @media (max-width: 1720px) {
          top: 43.6%;
          right: 40.5%;
        }

        @media (max-width: 1520px) {
          top: 43.8%;
          right: 40.8%;
        }

        @media (max-width: 1320px) {
          top: 44.2%;
          right: 41.2%;
        }

        @media (max-width: 1200px) {
          top: 44.4%;
          right: 41.5%;
        }
      }

      &[data-dispatch='3']{
        top: 7.4%;
        left: 6.2%;

        @media (max-width: 1720px) {
          top: 8.5%;
          left: 7.1%;
        }

        @media (max-width: 1520px) {
          top: 9.7%;
          left: 8.3%;
        }

        @media (max-width: 1320px) {
          top: 11.8%;
          left: 9.9%;
        }

        @media (max-width: 1200px) {
          top: 13.7%;
          left: 11.7%;
        }
      }

      &[data-dispatch='4']{
        top: 20.2%;
        right: 41.9%;

        @media (max-width: 1720px) {
          top: 21%;
          right: 42%;
        }

        @media (max-width: 1520px) {
          top: 22%;
          right: 42.3%;
        }

        @media (max-width: 1320px) {
          top: 23.2%;
          right: 42.6%;
        }

        @media (max-width: 1200px) {
          top: 24.6%;
          right: 42.8%;
        }
      }

      &:hover{
        img{
          opacity: 1;
        }
      }

      &.selected{
        img{
          opacity: 1;
        }

        @media (max-width: 991px) {
          border: 1px solid #9c7e29;
          background-color: #9c7e29;
          color: #fff;
        }
      }

      img{
        opacity: 0;

        &:nth-child(1){
          @media (max-width: 1720px) {
            display: none;
          }
        }

        &:nth-child(2){
          display: none;

          @media (max-width: 1720px) {
            display: inline-block;
          }

          @media (max-width: 1520px) {
            display: none;
          }
        }

        &:nth-child(3){
          display: none;

          @media (max-width: 1520px) {
            display: inline-block;
          }

          @media (max-width: 1320px) {
            display: none;
          }
        }

        &:nth-child(4){
          display: none;

          @media (max-width: 1320px) {
            display: inline-block;
          }

          @media (max-width: 1200px) {
            display: none;
          }
        }

        &:nth-child(5){
          display: none;

          @media (max-width: 1200px) {
            display: inline-block;
          }

          @media (max-width: 991px) {
            display: none;
          }
        }
      }
    }
  }

  &-list{
    ul{
      display: none;

      &.visible{
        display: block;
      }
    }

    li{
      width: calc((100% - 90px) / 4);
      margin: 0 30px 30px 0;
      padding: 20px;
      border: 1px solid #ddd;
      border-top: 2px solid #9c7e29;
      display: inline-block;
      vertical-align: top;
      position: relative;

      @media (max-width: 1350px) {
        width: calc((100% - 90px) / 4);
        margin: 0 30px 30px 0;
      }

      @media (max-width: 1260px) {
        width: calc((100% - 60px) / 3);
      }

      @media (max-width: 991px) {
        width: calc((100% - 30px) / 2);
      }

      @media (max-width: 767px) {
        width: calc((100% - 15px) / 2);
        margin: 0 15px 15px 0;
      }

      &:nth-child(4n){
        margin-right: 0;

        @media (max-width: 1260px) {
          margin-right: 30px;
        }
      }

      &:nth-child(3n){
        @media (max-width: 1260px) {
          margin-right: 0;
        }

        @media (max-width: 991px) {
          margin-right: 30px;
        }

        @media (max-width: 767px) {
          margin-right: 15px;
        }
      }

      &:nth-child(2n){        
        @media (max-width: 991px) {
          margin-right: 0;
        }
      }
    }

    &__img{
      width: 120px;
      display: inline-block;
      vertical-align: top;

      @media (max-width: 1600px) {
        width: 100px;
      }

      @media (max-width: 991px) {
        width: 80px;
      }

      @media (max-width: 767px) {
        width: 100%;
      }

      img{
        width: 100%;
      }
    }

    &__info{
      width: calc(100% - 120px);
      padding-left: 20px;
      display: inline-block;
      vertical-align: top;

      @media (max-width: 1600px) {
        width: calc(100% - 100px);
        padding-left: 10px;
      }

      @media (max-width: 991px) {
        width: calc(100% - 80px);
      }

      @media (max-width: 767px) {
        width: 100%;
        padding-bottom: 5px;
        padding-left: 0;
      }

      h3{
        height: 50px;
        margin-bottom: 15px;
        padding-bottom: 15px;
        border-bottom: 1px dashed #ddd;
        @include font(2.4rem, 500, #222);
        line-height: 50px;

        @media (max-width: 1600px) {
          height: 40px;
          font-size: 2rem;
          line-height: 40px;
        }

        @media (max-width: 991px) {
          font-size: 1.8rem;
        }
      }

      p{
        margin-bottom: 10px;
        @include font(1.6rem, 500, #222);
        line-height: 1;

        @media (max-width: 1600px) {
          font-size: 1.4rem;
        }

        &:last-child{
          margin-bottom: 0;
        }

        span{
          padding-right: 5px;
          border-right: 1px solid #ddd;
          color: #666;
          display: inline-block;
          vertical-align: top;

          @media (max-width: 991px) {
            display: block;
            margin-bottom: 5px;
            border-right: none;
          }
        }
      }
    }
  }

  &-table{
    overflow-x: auto;

    table{
      width: 100%;
      min-width: 800px;
      border-top: 2px solid #9c7e29;
    }

    th{
      padding: 24px 0;
      border: 1px solid #ddd;
      background-color: #f9f9f9;
      @include font(1.8rem, 500, #222);
      text-align: center;
      vertical-align: middle;

      @media (max-width: 767px) {
        padding: 15px 0;
        font-size: 1.6rem;
      }

      &:first-child{
        border-left: none;
      }

      &:last-child{
        border-right: none;
      }
    }
    
    td{
      padding: 24px 0;
      border: 1px solid #ddd;
      background-color: #fff;
      @include font(1.8rem, 500, #666);
      text-align: center;
      vertical-align: middle;

      @media (max-width: 767px) {
        padding: 15px 0;
        font-size: 1.6rem;
      }

      &:first-child{
        border-left: none;
      }

      &:last-child{
        border-right: none;
      }
    }

    tbody{
      tr{
        &:first-child{
          th{
            border-top: none;
          }
        
          td{
            border-top: none;
          }
        }
      }
    }
  }
}