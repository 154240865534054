.test{
  word-wrap: break-word;
  
  &-bg{
    padding: 100px 160px;
    background-color: #f9f9f9;

    @media (max-width: 1420px) {
      padding: 100px 80px;
    }

    @media (max-width: 991px) {
      padding: 50px 30px;
    }
  }

  &-info{
    &__wrap{
      padding: 60px 0 85px;
      border-bottom: 1px solid #ddd;
      position: relative;

      @media (max-width: 991px) {
        padding: 30px 0 45px;
      }

      @media (max-width: 767px) {
        padding: 30px 0;
      }

      &:nth-child(2n-1) {
        .test-info__img{
          text-align: right;

          &:first-child{
            display: none;

            @media (max-width: 767px) {
              display: inline-block;
            }
          }

          &:last-child{
            @media (max-width: 767px) {
              display: none;
            }
          }

          &:before{
            border: 6px solid #ddd4ba;
            @include poa(auto, auto, -30px, -30px);

            @media (max-width: 1420px) {
              bottom: -15px;
              left: -15px;
            }

            @media (max-width: 767px) {
              left: 0;
            }
          }
        }
      }
    }

    &__img{
      width: 385px;
      display: inline-block;
      vertical-align: middle;
      position: relative;

      @media (max-width: 1420px) {
        width: 200px;
      }

      @media (max-width: 767px) {
        width: 100%;
        margin-bottom: 30px;
      }

      &:before{
        content: '';
        width: 100%;
        height: 100%;
        border: 6px solid #aebec7;
        display: inline-block;
        @include poa(auto, -30px, -30px, auto);
        box-sizing: border-box;

        @media (max-width: 1420px) {
          right: -15px;
          bottom: -15px;
        }

        @media (max-width: 767px) {
          width: calc(100% - 15px);
          right: 0;
        }
      }

      img{
        position: relative;
        z-index: 2;
        box-shadow: 1px 1px 20px 1px rgba(0, 0, 0, .2);

        @media (max-width: 767px) {
          width: calc(100% - 15px);
        }
      }
    }

    &__text{
      width: calc(100% - 385px);
      padding: 0 140px;
      display: inline-block;
      vertical-align: middle;

      @media (max-width: 1650px) {
        padding: 0 80px;
      }

      @media (max-width: 1420px) {
        width: calc(100% - 200px);
        padding: 0 40px;
      }

      @media (max-width: 767px) {
        width: 100%;
        padding: 0;
      }

      p{
        @include font(2rem, 500, #666);
        line-height: 2.8rem;

        @media (max-width: 1650px) {
          font-size: 1.8rem;
        }

        @media (max-width: 991px) {
          font-size: 1.6rem;
          line-height: 2.6rem;
        }

        &+img{
          @include poa(60px, 0, auto, auto);

          @media (max-width: 1420px) {
            display: none;
          }
        }
      }

      img{
        @include poa(60px, auto, auto, 0);

        @media (max-width: 1420px) {
          display: none;
        }
      }
    }
  }
}