.director{
  &-list{
    > ul{
      > li {
        width: calc(50% - 20px);
        margin: 0 40px 40px 0;
        border: 1px solid #ddd;
        border-top: 2px solid #9c7e29;
        display: inline-block;
        background-color: #f9f9f9;
        vertical-align: top;

        @media (max-width: 1260px) {
          width: calc(50% - 10px);
          margin: 0 20px 20px 0;
        }

        @media (max-width: 991px) {
          width: 100%;
          margin-right: 0;
        }
      
        &:nth-child(2n) {
          margin-right: 0;
        }
      
        &:nth-last-child(1) {
          margin-bottom: 0;
        }
      
        &:nth-last-child(2) {
          margin-bottom: 0;

          @media (max-width: 991px) {
            margin-bottom: 20px;
          }
        }
      }
    }

    &__img{
      width: 225px;
      display: inline-block;
      background-color: #f9f9f9;
      vertical-align: middle;
      position: relative;

      @media (max-width: 1420px) {
        width: 180px;
      }

      @media (max-width: 767px) {
        width: 100%;
        padding: 15px 0;
        text-align: center;
      }

      img{
        @include poa-center;

        @media (max-width: 767px) {
          @include po-cancel;
        }
      }
    }

    &__text{
      width: calc(100% - 225px);
      padding: 50px;
      display: inline-block;
      background-color: #fff;
      vertical-align: middle;

      @media (max-width: 1420px) {
        width: calc(100% - 180px);
        padding: 30px;
      }

      @media (max-width: 991px) {
        height: auto!important;
      }

      @media (max-width: 767px) {
        width: 100%;
        padding: 15px;
      }

      h3{
        padding-bottom: 10px;
        border-bottom: 1px dashed #ddd;
        @include font(2.4rem, 500, #222);

        span{
          color: #9c7e29;
        }
      }

      ul{
        padding-top: 20px;
      }

      li{
        margin-bottom: 20px;
        padding-left: 12px;
        @include font(1.6rem, 500, #666);
        word-break: break-all;
        position: relative;

        &:before{
          content: '';
          width: 5px;
          height: 1px;
          display: inline-block;
          background-color: #666;
          @include poa(12px, auto, auto, 0);
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}