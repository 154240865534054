 
*{margin: 0; padding: 0;}
*:focus{outline: 2px dashed #9c7e29!important;}
ul{font-size: 0; list-style: none;}
img{max-width: 100%; display: inline-block;}
a{display: inline-block;}
.hidden-text{display: none;}
.visible-sm{display: none!important;}
.visible-sm-dib{display: none!important;}
.visible-xs{display: none!important;}
.visible-xs-ib{display: none!important;}
input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
}

input[type="radio"], input[type="checkbox"]{
  opacity: 0;
  position: absolute;
  left: -9999px;

  &:focus{
    &+label{
      outline: 2px dashed #9c7e29!important;
    }
  }
}

// main{
//   margin-top: 146px;
//
//   &.pt{
//     padding-top: 109px;
//   }
// }

.main-container{
  width: 100%;
  margin: 0 auto;
  padding: 0 100px;
  font-size: 0;

  .wrap{
    width: 100%;
    // position: relative;
  }
}

.container {
  width: 1600px;
  margin: 0 auto;
  // padding: 0 100px;
  font-size: 0;

  .wrap {
    width: 100%;
    // position: relative;
  }
}

@media (max-width: 1720px) {
  .container {
    width: 1400px;
  }
}

@media (max-width: 1520px) {
  .container {
    width: 1200px;
  }
}

@media (max-width: 1320px) {
  .container {
    width: 1000px;
  }
}

@media (max-width: 1260px) {
  .main-container{
    padding: 0 50px;
  }

  // .container{
  //   padding: 0 50px;
  // }
}

@media (max-width: 1200px) {
  .container {
    width: 850px;
  }
}

// @media (max-width: 1000px) {
//   .container {
//     width: 800px;
//   }
// }

@media (max-width: 991px) {
  .main-container{
    padding: 0 30px;
  }

  .container{
    width: 100%;
    padding: 0 30px;
  }

  .visible-sm{display: block!important;}
  .visible-sm-dib{display: inline-block!important;}
  .hidden-sm{display: none!important;}
}

@media (max-width: 767px) {
  .main-container{
    padding: 0 15px;
  }

  .container{
    padding: 0 15px;
  }

  .visible-xs{display: block!important;}
  .visible-xs-ib{display: inline-block!important;}
  .hidden-xs{display: none!important;}
}

@media (max-width: 320px) {
  .container {
    padding: 0 15px;
  }
}

.sound-only{
  width: 0!important;
  height: 0!important;
  opacity: 0!important;
  overflow: hidden!important;
  position: absolute!important;
}

.btn-css{
  font-size: 0;
}

/* 다음맵 접근성 추가 .warp_map으로 감쌈 */
.wrap_map a:focus,
.wrap_map button:focus,
.roughmap_maker_label a:focus .roughmap_lebel_text,
.root_daum_roughmap .wrap_btn_zoom button:focus {border:2px dashed #9c7e29;}
.wrap_controllers.hide {display:none;}
