//헤더 수정 css
// .header{
//   &-bottom{
//     &__gnb{
//       .one-depth {

//         @media (max-width: 991px) {
//           display: none;
//         }

//         &__list {
//           display: inline-block;
//           vertical-align: top;

//           .two-depth {
//             &:first-child {
//               border-left: 1px solid #ddd;
//             }
//           }

//           >a {
//             padding: 0 30px;
//             @include font(2.4rem, 700, #222);
//             line-height: 115px;

//             @media (max-width: 1800px) {
//               font-size: 2.2rem;
//               padding: 0 15px;
//             }

//             @media (max-width: 1600px) {
//               font-size: 1.8rem;
//             }

//             @media (max-width: 1460px) {
//               font-size: 1.6rem;
//             }

//             @media (max-width: 1320px) {
//               font-size: 1.4rem;
//             }

//             @media (max-width: 1150px) {
//               padding: 0 5px;
//             }
//           }
//         }
//       }

//       .two-depth {
//         width: calc(100% - 100px);
//         height: 0;
//         border-top: 1px solid #ddd;
//         background-color: #fff;
//         @include poa(115px, auto, auto, 0);
//         overflow: hidden;

//         &.visible{
//           height: auto;
//           min-height: 190px;
//         }

//         &__tit {
//           width: 25%;
//           height: 100%;
//           padding: 50px 0 0 100px;
//           display: inline-block;
//           vertical-align: top;
//           background-image: url('/template/resources/images/cont/header-bg.png');
//           background-size: cover;
//           background-position: center;
//           @include poa(0, auto, auto, 0);

//           @media (max-width: 1260px) {
//             padding: 50px 0 0 50px;
//           }

//           h3{
//             margin-bottom: 10px;
//             padding-right: 15px;
//             @include font(4rem, 700, #fff);

//             @media (max-width: 1600px) {
//               font-size: 3rem;
//             }

//             @media (max-width: 1420px) {
//               font-size: 2.4rem;
//             }
//           }

//           p{
//             padding-right: 15px;
//             @include font(1.4rem, 300, #fff);
//           }
//         }

//         > ul{
//           width: 75%;
//           margin-left: 25%;
//           padding: 30px 100px 30px 25px;
//           height: auto;
//           display: inline-block;
//           vertical-align: top;

//           @media (max-width: 1260px) {
//             padding: 30px 50px 30px 25px;
//           }
//         }

//         &__list {
//           width: calc((100% - 90px) / 4);
//           margin: 0 30px 30px 0;
//           display: inline-block;
//           vertical-align: top;

//           @media (max-width: 1420px) {
//             width: calc((100% - 60px) / 3);
//           }

//           @media (max-width: 1260px) {
//             width: calc((100% - 30px) / 3);
//             margin: 0 15px 15px 0;
//           }

//           &:nth-child(4n){
//             margin-right: 0;
//           }

//           &:nth-child(3n){
//             @media (max-width: 1420px) {
//               margin-right: 0;
//             }
//           }
          
//           > a {
//             width: 100%;
//             height: 50px;
//             padding: 15px 20px;
//             border: 1px solid #ddd;
//             @include font(1.6rem, 500, #222);
//             position: relative;

//             &:after{
//               content: '';
//               width: 6px;
//               height: 6px;
//               border-top: 2px solid #ddd;
//               border-right: 2px solid #ddd;
//               @include middle(auto, 20px);
//               transform: translateY(-50%) rotate(45deg);
//               box-sizing: border-box;
//             }
//           }

//           &:hover {
//             > a{
//               background-color: #9c7e29;
//               border: 1px solid #9c7e29;
//               color: #fff;
//               box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, .15);
              
//               &:after {
//                 border-top: 2px solid #fff;
//                 border-right: 2px solid #fff;
//               }
//             }
//           }
//         }
//       }

//       .three-depth{
//         padding: 20px 10px 0 25px;

//         @media (max-width: 1260px) {
//           padding: 10px 10px 0 15px;
//         }

//         &__list{
//           margin-bottom: 10px;
//           padding-left: 12px;
//           position: relative;

//           &:before{
//             content: '';
//             width: 3px;
//             height: 3px;
//             display: inline-block;
//             background-color: #999;
//             @include middle;
//           }

//           a{
//             @include font(1.4rem, 500, #999);

//             &:hover{
//               color: #222;
//               text-decoration: underline;
//             }
//           }
//         }
//       }
//     }
//   }

//   &:not(.n-first){
//     .header-bottom__gnb{
//       .one-depth {
//         &__list {
//           >a {
//             color: #fff;
//           }
//         }
//       }
//     }

//     .header-bottom__lang{
//       a{
//         color: #fff;

//         @media (max-width: 991px) {
//           color: #222;
//         }

//         &:after{
//           border-top: 2px solid #fff;
//           border-right: 2px solid #fff;

//           @media (max-width: 991px) {
//             border-top: 2px solid #222;
//             border-right: 2px solid #222;
//           }
//         }
//       }
//     }
//   }

//   &.hover{
//     .header-bottom__gnb{
//       .one-depth {
//         &__list {
//           >a {
//             color: #222;
//           }
//         }
//       }
//     }

//     .header-bottom__lang{
//       a{
//         color: #222;

//         &:after{
//           border-top: 2px solid #222;
//           border-right: 2px solid #222;
//         }
//       }
//     }
//   }

//   &__all-menu{
//     padding: 0 100px;
//     display: none;

//     @media (max-width: 991px) {
//       padding: 0 50px;
//     }

//     @media (max-width: 767px) {
//       padding: 0 15px;
//     }

//     &.visible{
//       display: block;
//     }

//     &__logo{
//       height: 115px;
//       margin-bottom: 40px;
//       position: relative;

//       @media (max-width: 991px) {
//         margin-bottom: 20px;
//       }

//       @media (max-width: 767px) {
//         width: 100%;
//         height: 50px;
//         margin-bottom: 10px;
//       }

//       img{
//         width: 200px;
//         @include middle;

//         @media (max-width: 767px) {
//           width: 120px;
//           @include middle(auto, 0);
//         }
//       }
//     }

//     .one-depth{
//       max-height: calc(100% - 155px);
//       padding-bottom: 30px;
//       overflow-y: auto;

//       @media (max-width: 991px) {
//         max-height: calc(100vh - 135px);
//       }

//       @media (max-width: 767px) {
//         height: calc(100vh - 50px);
//         overflow-y: auto;
//       }

//       &__list{
//         width: calc((100% - 150px) / 6);
//         margin-right: 30px;
//         display: inline-block;
//         vertical-align: top;

//         @media (max-width: 1260px) {
//           width: calc((100% - 75px) / 6);
//           margin-right: 15px;
//         }

//         @media (max-width: 991px) {
//           width: calc((100% - 30px) / 3);
//           margin-bottom: 30px;

//           &:nth-child(3n){
//             margin-right: 0;
//           }
//         }

//         @media (max-width: 767px) {
//           width: 100%;
//           margin-right: 0;
//           margin-bottom: 30px;
//         }

//         &:last-child{
//           margin-right: 0;
//         }

//         > a{
//           @include font(3rem, 700, #fff);

//           @media (max-width: 1420px) {
//             font-size: 2.4rem;
//           }

//           @media (max-width: 1260px) {
//             font-size: 2rem;
//           }

//           @media (max-width: 1050px) {
//             font-size: 1.8rem;
//           }

//           @media (max-width: 991px) {
//             font-size: 2.2rem;
//           }

//           @media (max-width: 767px) {
//             font-size: 2.2rem;
//           }
//         }
//       }
//     }

//     .two-depth{
//       margin-top: 20px;
//       padding-top: 30px;
//       border-top: 2px solid #fff;

//       @media (max-width: 767px) {
//         margin-top: 10px;
//         padding-top: 10px;
//       }

//       &__tit{
//         display: none;
//       }

//       &__list{
//         margin-bottom: 20px;

//         &:last-child{
//           margin-bottom: 0;
//         }

//         > a{
//           @include font(1.6rem, 500, #fff);
//         }
//       }
//     }

//     .three-depth{
//       padding-top: 10px;

//       &__list{
//         width: 50%;
//         margin-bottom: 10px;
//         display: inline-block;
//         vertical-align: top;

//         &:last-child{
//           margin-bottom: 0;
//         }
        
//         a{
//           padding-left: 10px;
//           @include font(1.3rem, 500, #aeb4c6);
//           position: relative;

//           &:before{
//             content: '';
//             width: 3px;
//             height: 3px;
//             display: inline-block;
//             background-color: #aeb4c6;
//             @include poa(5px, auto, auto, 0);
//           }
//         }
//       }
//     }

//     .hasThirdDepth + .three-depth{
//       padding-top: 20px;
//     }
//   }
// }


//헤더 기존 css
.header {
  &-bottom {
    &__gnb {
      word-break: break-all;

      .one-depth {

        @media (max-width: 991px) {
          display: none;
        }

        &__list {
          display: inline-block;
          vertical-align: top;

          .two-depth {
            &:first-child {
              border-left: 1px solid #ddd;
            }
          }

          &:hover {
            >a{
              p{
                color: #9c7e29;
              }
            }

            .two-depth {
              background-color: #fafafa;
              border-bottom: 1px solid #ddd;
              
              &__tit {
                color: #9c7e29;
              }
            }
          }

          >a {
            padding: 0 30px;
            @include font(2.4rem, 700, #222);
            line-height: 115px;

            @media (max-width: 1800px) {
              font-size: 2.2rem;
              padding: 0 15px;
            }

            @media (max-width: 1600px) {
              font-size: 1.8rem;
            }

            @media (max-width: 1460px) {
              font-size: 1.6rem;
            }

            @media (max-width: 1340px) {
              padding: 0 10px;
              font-size: 1.4rem;
            }

            @media (max-width: 1150px) {
              padding: 0 5px;
            }
          }
        }
      }

      .two-depth {
        padding: 40px;
        border-right: 1px solid #ddd;
        @include poa(115px, auto, auto, 0);

        @media (max-width: 1320px) {
          padding: 30px;
        }

        @media (max-width: 1260px) {
          padding: 20px;
        }

        @media (max-width: 1150px) {
          padding: 20px 10px;
        }

        &:first-child{
          border-left: 1px solid #ddd;
        }

        &__list {
          margin-bottom: 20px;

          @media (max-width: 1420px) {
            margin-bottom: 10px;
          }

          &:last-child {
            margin-bottom: 0;
          }

          > a {
            @include font(1.6rem, 500, #222);

            @media (max-width: 1420px) {
              font-size: 1.4rem;
            }

            &:hover {
              color: #222;
              text-decoration: underline;
            }
          }
        }

        &__tit {
          margin-bottom: 20px;
          padding-bottom: 20px;
          border-bottom: 1px solid #ddd;
          @include font(1.8rem, 700, #222);

          @media (max-width: 1420px) {
            margin-bottom: 10px;
            padding-bottom: 10px;
            font-size: 1.8rem;
          }

          @media (max-width: 1260px) {
            font-size: 1.6rem;
          }
        }
      }

      .three-depth {
        padding-top: 10px;

        &__list {
          margin-bottom: 10px;

          &:last-child {
            margin-bottom: 0;
          }

          a {
            padding-left: 12px;
            @include font(1.4rem, 500, #999);
            position: relative;

            &:before{
              content: '';
              width: 3px;
              height: 3px;
              display: inline-block;
              background-color: #999;
              @include poa(7px, auto, auto, 0);
            }

            &:hover {
              color: #222;
              text-decoration: underline;
            }
          }
        }
      }
    }
  }

  &:not(.n-first) {
    .header-bottom__gnb {
      .one-depth {
        &__list {
          >a {
            // color: #fff;//임시
          }
        }
      }
    }

    .header-bottom__lang {
      a {
        color: #fff;

        @media (max-width: 991px) {
          color: #222;
        }

        &:after {
          border-top: 2px solid #fff;
          border-right: 2px solid #fff;

          @media (max-width: 991px) {
            border-top: 2px solid #222;
            border-right: 2px solid #222;
          }
        }
      }
    }
  }

  &.hover {
    .header-bottom__gnb {
      .one-depth {
        &__list {
          >a {
            color: #222;
          }
        }
      }
    }

    .header-bottom__lang {
      a {
        color: #222;

        &:after {
          border-top: 2px solid #222;
          border-right: 2px solid #222;
        }
      }
    }
  }

  &__all-menu {
    padding: 0 100px;
    display: none;

    @media (max-width: 991px) {
      padding: 0 50px;
    }

    @media (max-width: 767px) {
      padding: 0 15px;
    }

    &.visible {
      display: block;
    }

    &__logo {
      height: 115px;
      margin-bottom: 40px;
      position: relative;

      @media (max-width: 991px) {
        margin-bottom: 20px;
      }

      @media (max-width: 767px) {
        width: 100%;
        height: 50px;
        margin-bottom: 10px;
      }

      img {
        width: 200px;
        @include middle;

        @media (max-width: 767px) {
          width: 120px;
          @include middle(auto, 0);
        }
      }
    }

    .one-depth {
      max-height: calc(100% - 155px);
      padding: 3px 3px 30px;
      overflow-y: auto;

      @media (max-width: 991px) {
        max-height: calc(100vh - 135px);
      }

      @media (max-width: 767px) {
        height: calc(100vh - 50px);
        overflow-y: auto;
      }

      &__list {
        width: calc((100% - 150px) / 6);
        margin-right: 30px;
        display: inline-block;
        vertical-align: top;

        @media (max-width: 1260px) {
          width: calc((100% - 75px) / 6);
          margin-right: 15px;
        }

        @media (max-width: 991px) {
          width: calc((100% - 30px) / 3);
          margin-bottom: 30px;

          &:nth-child(3n) {
            margin-right: 0;
          }
        }

        @media (max-width: 767px) {
          width: 100%;
          margin-right: 0;
          margin-bottom: 30px;
        }

        &:last-child {
          margin-right: 0;
        }

        >a {
          @include font(3rem, 700, #fff);

          @media (max-width: 1420px) {
            font-size: 2.4rem;
          }

          @media (max-width: 1260px) {
            font-size: 2rem;
          }

          @media (max-width: 1050px) {
            font-size: 1.8rem;
          }

          @media (max-width: 991px) {
            font-size: 2.2rem;
          }

          @media (max-width: 767px) {
            font-size: 2.2rem;
          }
        }
      }
    }

    .two-depth {
      margin-top: 20px;
      padding-top: 30px;
      border-top: 2px solid #fff;

      @media (max-width: 767px) {
        margin-top: 10px;
        padding-top: 10px;
      }

      &__tit {
        display: none;
      }

      &__list {
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }

        >a {
          @include font(1.6rem, 500, #fff);
        }
      }
    }

    .three-depth {
      padding-top: 10px;

      &__list {
        width: 50%;
        margin-bottom: 10px;
        display: inline-block;
        vertical-align: top;

        &:last-child {
          margin-bottom: 0;
        }

        a {
          padding-left: 10px;
          @include font(1.3rem, 500, #aeb4c6);
          position: relative;

          &:before {
            content: '';
            width: 3px;
            height: 3px;
            display: inline-block;
            background-color: #aeb4c6;
            @include poa(5px, auto, auto, 0);
          }
        }
      }
    }

    .hasThirdDepth+.three-depth {
      padding-top: 20px;
    }
  }
}