//기존 css
.skip {
  padding: 10px 15px;
  background-color: #000;
  text-align: center;
  @include pof(-44px, auto, auto, 50%);
  transform: translateX(-50%);
  z-index: 22;

  &.focus {
    top: 0;
  }

  a {
    @include font(1.6rem, 500, #fff);
    vertical-align: middle;

    &:first-child {
      margin-right: 30px;

      @media (max-width: 767px) {
        margin-right: 0;
        margin-bottom: 15px;
      }
    }
  }

  @media (max-width: 767px) {
    width: 100%;
    @include pof(-77px, auto, auto, 50%);
  }
}

.prev_page_btn {
  width: 100%;
  height: 25px;
  display: block;
  display: none;//임시
  background-color: #003554;
  position: relative;

  @media (max-width: 991px) {
    display: none;
  }

  img {
    @include poa-center;
  }
}

.header {
  width: calc(100% - 100px);
  height: 115px;
  font-family: 'GmarketSans';
  // @include pof(25px, auto, auto, 100px);//임시
  @include pof(0, auto, auto, 100px);//임시
  background-color: #fff;//임시
  z-index: 3;
  overflow: hidden;

  @media (max-width: 991px) {
    top: 0;
    background-color: #fff;
    overflow: unset;
  }

  @media (max-width: 767px) {
    width: calc(100% - 50px);
    height: 50px;
    border-bottom: 1px solid #ddd;
    left: 50px;
  }

  &:before {
    content: '';
    width: 100%;
    height: 1px;
    display: none;
    background-color: #ddd;
    @include poa(115px, auto, auto, 0);
    z-index: 1;
  }

  .gnb-bg {
    width: 100%;
    height: 0px;
    border-bottom: 1px solid #ddd;
    display: block;
    transition-duration: .3s;

    @media (max-width: 991px) {
      display: none;
    }
  }

  &.n-first {
    top: 0;
    transition-duration: .3s;

    .header-top {
      display: none;
    }
  }

  &.hover {
    height: auto;
    background-color: #fff;
    transition-duration: .3s;

    &:before {
      display: block;
    }
  }

  &-bottom {
    width: 100%;
    padding: 0 100px;
    font-size: 0;

    @media (max-width: 1260px) {
      padding: 0 50px;
    }

    @media (max-width: 767px) {
      width: 100%;
      padding: 0 15px;
    }

    &__logo {
      width: 200px;
      height: 115px;
      display: inline-block;
      vertical-align: top;
      position: relative;

      @media (max-width: 1420px) {
        width: 160px;
      }

      @media (max-width: 767px) {
        width: calc(100% - 60px);
        height: 50px;
      }

      a {
        @include middle;

        @media (max-width: 767px) {
          width: 120px;
          left: auto;
          right: 0;
        }
      }
    }

    &__gnb {
      width: calc(100% - 430px);
      padding: 0 30px;
      display: inline-block;
      vertical-align: top;

      @media (max-width: 1420px) {
        width: calc(100% - 340px);
      }

      @media (max-width: 1260px) {
        width: calc(100% - 280px);
        padding: 0 15px;
      }

      @media (max-width: 767px) {
        display: none;
      }
    }

    &__lang {
      width: 115px;
      height: 115px;
      display: inline-block;
      vertical-align: top;
      text-align: right;
      position: relative;

      @media (max-width: 1420px) {
        width: 90px;
      }

      @media (max-width: 1260px) {
        width: 60px;
      }

      @media (max-width: 767px) {
        height: 50px;
        display: none;
      }

      &.m-lang{
        display: none;

        @media (max-width: 767px) {
          display: inline-block;
        }
      }

      >a {
        padding-right: 20px;
        @include font(1.4rem, 700, #222);
        @include middle(auto, 40px);

        &:after {
          content: '';
          width: 8px;
          height: 8px;
          border-top: 2px solid #000;
          border-right: 2px solid #000;
          display: inline-block;
          @include middle(auto, 0);
          transform: translateY(-70%) rotate(135deg);
          box-sizing: border-box;
        }

        @media (max-width: 1320px) {
          font-size: 1.2rem;
        }

        @media (max-width: 1260px) {
          display: inline-block;
          @include middle;
        }
      }

      &__list{
        height: 0;
        border-radius: 5px;
        display: none;
        background-color: #fff;
        // @include middle(auto, 40px);
        @include poa(80px, 40px, auto, auto);
        overflow: hidden;
        // transition-duration: .3s;
        z-index: 1;

        @media (max-width: 1260px) {
          left: 0;
          right: auto;
        }

        @media (max-width: 767px) {
          top: 50px;
        }

        .active{
          border: 1px solid #ddd;
        }

        li{
          padding: 3px 10px;

          &:first-child{
            padding: 10px 10px 3px 10px;
          }

          &:last-child{
            padding: 3px 10px 10px 10px;
          }
        }

        a{
          @include font(1.4rem, 700, #222);

          @media (max-width: 1320px) {
            font-size: 1.2rem;
          }
        }
      }
    }

    &__link {
      width: 115px;
      height: 115px;
      display: inline-block;
      vertical-align: top;

      @media (max-width: 1420px) {
        width: 90px;
      }

      @media (max-width: 1260px) {
        width: 60px;
      }

      @media (max-width: 767px) {
        display: none;
      }

      a {
        width: 100%;
        height: 100%;
        background-image: url('/template/resources/images/cont/q-link_bg.png');
        background-size: cover;
        position: relative;

        div {
          text-align: center;
          @include poa-center;
        }

        p {
          margin-bottom: 5px;
          @include font(1.8rem, 700, #fff);
          line-height: 2.3rem;

          @media (max-width: 1260px) {
            font-size: 1.6rem;
          }
        }
      }
    }
  }

  &__all-menu {
    width: calc(100% - 100px);
    height: 100vh;
    display: inline-block;
    background-color: #162038;
    @include pof(0, auto, auto, 100px);
    z-index: 3;

    @media (max-width: 767px) {
      width: calc(100% - 50px);
      left: 50px;
    }
  }
}

.side {
  &-area {
    width: 100px;
    // height: calc(100vh - 25px);//임시
    height: calc(100vh);//임시
    border-right: 1px solid #ddd;
    display: inline-block!important;//임시
    // @include pof(25px, auto, auto, 0);//임시
    @include pof(0, auto, auto, 0);//임시
    background-color: #fff;
    z-index: 3;

    @media (max-width: 991px) {
      height: 100vh;
      top: 0;
      display: inline-block!important;
    }

    @media (max-width: 767px) {
      width: 50px;
      height: 50px;
      border-right: none;
      border-bottom: 1px solid #ddd;
      top: 0;
    }

    &.all-menu {
      height: 100vh !important;
      top: 0;
      transition-duration: .3s;

      @media (max-width: 767px) {
        transition-duration: 0s;
      }

      ul {
        display: inline-block;
      }
    }

    &.n-first {
      height: 100vh;
      top: 0;
      transition-duration: .3s;

      @media (max-width: 767px) {
        width: 50px;
        height: 50px;
        border-right: none;
        border-bottom: 1px solid #ddd;
        top: 0;
      }
    }

    >div {
      width: 100px;
      height: 115px;
      position: relative;

      @media (max-width: 767px) {
        width: 50px;
        height: 50px;
      }
    }

    .all-btn {
      width: 24px;
      height: 24px;
      display: inline-block;
      @include poa-center;

      span {
        width: 6px;
        height: 6px;
        display: inline-block;
        background-color: #000;
        position: absolute;
        transition-duration: .3s;

        &:nth-child(1) {
          top: 0;
          left: 0;
        }

        &:nth-child(2) {
          top: 0;
          left: 9px;
        }

        &:nth-child(3) {
          top: 0;
          left: 18px;
        }

        &:nth-child(4) {
          top: 9px;
          left: 0;
        }

        &:nth-child(5) {
          top: 9px;
          left: 9px;
        }

        &:nth-child(6) {
          top: 9px;
          left: 18px;
        }

        &:nth-child(7) {
          top: 18px;
          left: 0;
        }

        &:nth-child(8) {
          top: 18px;
          left: 9px;
        }

        &:nth-child(9) {
          top: 18px;
          left: 18px;
        }
      }

      &.active {
        span {
          &:nth-child(2) {
            top: 9px;
            left: 9px;
          }

          &:nth-child(4) {
            top: 9px;
            left: 9px;
          }

          &:nth-child(6) {
            top: 9px;
            left: 9px;
          }

          &:nth-child(8) {
            top: 9px;
            left: 9px;
          }
        }
      }
    }

    ul {
      @include poa-center;

      @media (max-width: 767px) {
        display: none;
      }
    }

    li {
      margin: 15px 0;
      text-align: center;
    }
  }
}

.nav-bg {
  width: 100%;
  height: 100vh;
  display: none;
  background-color: rgba(0, 0, 0, .5);
  @include pof(0, auto, auto, 0);
  z-index: 1;

  &.visible {
    display: block;
  }
}

.z-index {
  z-index: 10;
}