.en{
    &-dispatch{
        &-tab{
            margin-bottom: 20px;
            padding: 16px 0;
            background: linear-gradient(to right, #1d92a0, #12226e);

            li{
                width: calc(100% / 6);
                display: inline-block;
                vertical-align: middle;
                text-align: center;

                @media (max-width: 767px) {
                    width: 50%;
                    margin: 5px 0;
                }
            }

            a{
                height: 35px;
                padding: 0 28px;
                border-radius: 50px;
                display: inline-block;
                @include font(1.6rem, 700, #fff);
                line-height: 35px;

                &.selected{
                    background-color: #fff;
                    color: #111;
                }
            }
        }

        &-contents{
            div{
                display: none;

                @media (max-width: 991px) {
                    overflow-x: auto;

                    .en-basic-table{
                        min-width: 800px;
                    }
                }

                @media (max-width: 767px) {
                    .en-basic-table{
                        min-width: 500px;
                    }
                }

                &.visible{
                    display: block;
                }
            }
        }
    }
}