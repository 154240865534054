.calendar{
  &-contents{
    &__date{
      margin-bottom: 20px;

      >*{
        height: 50px;
        border: 1px solid #e5e5e5;
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        position: relative;
      }

      p{
        width: 150px;
        @include font(1.6rem, 500, #555);
        line-height: 50px;
      }

      a{
        width: 50px;
        font-size: 0;
      }

      img{
        display: inline-block;
        margin-right: 10px;
        vertical-align: middle;
        position: relative;
        bottom: 2px;
      }

      .prev-month{
        border-right: none;

        &:before{
          content: '';
          width: 9px;
          height: 9px;
          border-top: 2px solid #ccc;
          border-left: 2px solid #ccc;
          display: inline-block;
          @include poa-center;
          transform: translate(-30%, -50%) rotate(-45deg);
        }
      }

      .next-month{
        border-left: none;

        &:before{
          content: '';
          width: 9px;
          height: 9px;
          border-top: 2px solid #ccc;
          border-right: 2px solid #ccc;
          display: inline-block;
          @include poa-center;
          transform: translate(-70%, -50%) rotate(45deg);
        }
      }
    }

    &__table{
      overflow: visible;

      .c1{
        &:before{
          content: '빨강';
          background-color: #f05050;
        }
      }

      .c2{
        &:before{
          content: '노랑';
          background-color: #fbaa1d;
        }
      }

      .c3{
        &:before{
          content: '초록';
          background-color: #0f874a;
        }
      }

      .c4{
        &:before{
          content: '파랑';
          background-color: #5187f1;
        }
      }

      .c5{
        &:before{
          content: '검정';
          background-color: #000;
        }
      }

      table{
        width: 100%;
        table-layout: fixed;
        font-family: 'GmarketSans';

        th{
          height: 50px;
          border: 1px solid #0f8940;
          background-color: #0f8940;
          @include font(1.6rem, 500, #fff);
        }

        td{
          padding: 15px 10px;
          border: 1px solid #dfdfdf;
          // border-top: none;
          position: relative;
          overflow: visible;

          &:before{
            content: '';
            width: 100%;
            height: 100%;
            border: 1px solid #0f8940;
            display: none;
            box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, .1);
            @include poa(-1px, auto, auto, -1px);
          }

          &:hover{
            z-index: 1;

            &:before{
              display: inline-block;
            }
          }

          @media (max-width: 767px) {
            padding: 10px 5px;

            &:after{
              content: '';
              width: 100%;
              height: 100%;
              display: inline-block;
              @include poa(0, auto, auto, 0);
            }
          }
        }

        p{
          margin-bottom: 5px;
          @include font(1.5rem, 300, #888);
          text-align: center;
        }

        ul{
          min-height: 72px;
        }

        li{
          padding: 5px 0;

          @media (max-width: 767px) {
            width: auto;
            display: inline-block;
            padding: 2px;
            vertical-align: top;
          }
        }

        a{
          width: 100%;
          padding-left: 15px;
          @include font(1.2rem, 400, #444);
          text-align: left;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          position: relative;

          &:before{
            content: '';
            width: 8px;
            height: 8px;
            border-radius: 8px;
            display: inline-block;
            font-size: 0;
            @include middle;
          }

          @media (max-width: 767px) {
            width: 8px;
            height: 8px;
            padding-left: 0;
            font-size: 0;
          }
        }
      }

      > ul{
        margin-top: 15px;
        padding-left: 10px;

        li{
          margin-right: 20px;
          padding-left: 15px;
          display: inline-block;
          @include font(1.6rem, 500, #555);
          vertical-align: middle;
          position: relative;

          &:before{
            content: '';
            width: 8px;
            height: 8px;
            border-radius: 8px;
            display: inline-block;
            font-size: 0;
            @include middle;
          }

          &:last-child{
            margin-right: 0;
          }

          @media (max-width: 767px) {
            margin-right: 10px;
          }
        }

        @media (max-width: 767px) {
          padding: 0 5px;
        }
      }

      > p{
        margin-top: 10px;
        @include font(1.4rem, 400, #888);
      }
    }

    &__m-list{
      margin-top: 50px;
      text-align: left;
      display: none;

      .c1{
        &:before{
          content: '빨강';
          background-color: #f05050;
        }
      }

      .c2{
        &:before{
          content: '노랑';
          background-color: #fbaa1d;
        }
      }

      .c3{
        &:before{
          content: '초록';
          background-color: #0f874a;
        }
      }

      .c4{
        &:before{
          content: '파랑';
          background-color: #5187f1;
        }
      }

      .c5{
        &:before{
          content: '검정';
          background-color: #000;
        }
      }

      h3{
        height: 50px;
        background-color: #0f8940;
        border: 1px solid #0f8940;
        @include font(1.6rem, 500, #fff);
        line-height: 50px;
        text-align: center;
      }

      ul{
        padding: 10px;
        border: 1px solid #e0e0e0;
        border-top: none;
      }

      li{
        margin-bottom: 10px;
      }

      a{
        width: 100%;
        padding-left: 15px;
        @include font(1.6rem, 400, #444);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        position: relative;

        &:before{
          content: '';
          width: 8px;
          height: 8px;
          border-radius: 8px;
          display: inline-block;
          font-size: 0;
          @include middle;
        }
      }

      @media (max-width: 991px) {
        display: block;
      }
    }
  }
}
