@mixin font ($fz, $fw, $c) {
  font-size: $fz;
  font-weight: $fw;
  color: $c;
}

@mixin font-14 ($fw, $c) {
  font-size: calc(1.2rem + 0.1042vw);
  font-weight: $fw;
  color: $c;

  @media(min-width: 1920px) {
    font-size: 1.4rem;
  }
}

@mixin font-16 ($fw, $c) {
  font-size: calc(1.2rem + 0.2083vw);
  font-weight: $fw;
  color: $c;

  @media(min-width: 1920px) {
    font-size: 1.6rem;
  }
}

@mixin font-18 ($fw, $c) {
  font-size: calc(1.2rem + 0.3125vw);
  font-weight: $fw;
  color: $c;

  @media(min-width: 1920px) {
    font-size: 1.8rem;
  }
}

@mixin font-20 ($fw, $c) {
  font-size: calc(1.2rem + 0.4167vw);
  font-weight: $fw;
  color: $c;

  @media(min-width: 1920px) {
    font-size: 2rem;
  }
}

@mixin font-22 ($fw, $c) {
  font-size: calc(1.2rem + 0.5208vw);
  font-weight: $fw;
  color: $c;

  @media(min-width: 1920px) {
    font-size: 2.2rem;
  }
}

@mixin font-24 ($fw, $c) {
  font-size: calc(1.2rem + 0.6250vw);
  font-weight: $fw;
  color: $c;

  @media(min-width: 1920px) {
    font-size: 2.4rem;
  }
}

@mixin font-26 ($fw, $c) {
  font-size: calc(1.2rem + 0.7292vw);
  font-weight: $fw;
  color: $c;

  @media(min-width: 1920px) {
    font-size: 2.6rem;
  }
}

@mixin font-28 ($fw, $c) {
  font-size: calc(1.2rem + 0.8333vw);
  font-weight: $fw;
  color: $c;

  @media(min-width: 1920px) {
    font-size: 2.8rem;
  }
}

@mixin font-30 ($fw, $c) {
  font-size: calc(1.2rem + 0.9375vw);
  font-weight: $fw;
  color: $c;

  @media(min-width: 1920px) {
    font-size: 3rem;
  }
}

@mixin font-32 ($fw, $c) {
  font-size: calc(1.2rem + 1.0417vw);
  font-weight: $fw;
  color: $c;

  @media(min-width: 1920px) {
    font-size: 3.2rem;
  }
}

@mixin font-34 ($fw, $c) {
  font-size: calc(1.2rem + 1.1458vw);
  font-weight: $fw;
  color: $c;

  @media(min-width: 1920px) {
    font-size: 3.4rem;
  }
}

@mixin font-36 ($fw, $c) {
  font-size: calc(1.2rem + 1.2500vw);
  font-weight: $fw;
  color: $c;

  @media(min-width: 1920px) {
    font-size: 3.6rem;
  }
}

@mixin font-38 ($fw, $c) {
  font-size: calc(1.2rem + 1.3542vw);
  font-weight: $fw;
  color: $c;

  @media(min-width: 1920px) {
    font-size: 3.8rem;
  }
}

@mixin font-40 ($fw, $c) {
  font-size: calc(1.2rem + 1.4583vw);
  font-weight: $fw;
  color: $c;

  @media(min-width: 1920px) {
    font-size: 4rem;
  }
}

@mixin font-42 ($fw, $c) {
  font-size: calc(1.2rem + 1.5625vw);
  font-weight: $fw;
  color: $c;

  @media(min-width: 1920px) {
    font-size: 4.2rem;
  }
}

@mixin font-44 ($fw, $c) {
  font-size: calc(1.2rem + 1.6667vw);
  font-weight: $fw;
  color: $c;

  @media(min-width: 1920px) {
    font-size: 4.4rem;
  }
}

@mixin font-46 ($fw, $c) {
  font-size: calc(1.2rem + 1.7708vw);
  font-weight: $fw;
  color: $c;

  @media(min-width: 1920px) {
    font-size: 4.6rem;
  }
}

@mixin font-48 ($fw, $c) {
  font-size: calc(1.2rem + 1.8750vw);
  font-weight: $fw;
  color: $c;

  @media(min-width: 1920px) {
    font-size: 4.8rem;
  }
}

@mixin font-50 ($fw, $c) {
  font-size: calc(1.2rem + 1.9792vw);
  font-weight: $fw;
  color: $c;

  @media(min-width: 1920px) {
    font-size: 5rem;
  }
}

@mixin font-52 ($fw, $c) {
  font-size: calc(1.2rem + 2.0833vw);
  font-weight: $fw;
  color: $c;

  @media(min-width: 1920px) {
    font-size: 5.2rem;
  }
}

@mixin font-54 ($fw, $c) {
  font-size: calc(1.2rem + 2.1875vw);
  font-weight: $fw;
  color: $c;

  @media(min-width: 1920px) {
    font-size: 5.4rem;
  }
}

@mixin font-56 ($fw, $c) {
  font-size: calc(1.2rem + 2.2917vw);
  font-weight: $fw;
  color: $c;

  @media(min-width: 1920px) {
    font-size: 5.6rem;
  }
}

@mixin font-58 ($fw, $c) {
  font-size: calc(1.2rem + 2.3958vw);
  font-weight: $fw;
  color: $c;

  @media(min-width: 1920px) {
    font-size: 5.8rem;
  }
}

@mixin font-60 ($fw, $c) {
  font-size: calc(1.2rem + 2.5000vw);
  font-weight: $fw;
  color: $c;

  @media(min-width: 1920px) {
    font-size: 6rem;
  }
}
