.cn{
    .vision{
        &-wrap{
            &__one-depth{
                &__circle{
                    div{
                        p{
                            padding: 0 5px;
                        }
                    }
                }
            }
        }
    }
}