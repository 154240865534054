.sub{
  .header{
    background-color: #fff;

    &-bottom{
      width: 1600px;
      margin: 0 auto;
      padding: 0;

      @media (max-width: 1720px) {
        width: 1400px;
      }

      @media (max-width: 1520px) {
        width: 1200px;
      }

      @media (max-width: 1320px) {
        width: 1000px;
      }

      @media (max-width: 1200px) {
        width: 850px;
      }

      @media (max-width: 991px) {
        width: 100%;
        padding: 0 30px;
      }

      @media (max-width: 767px) {
        padding: 0 15px;
      }
    }
  }

  .side-area{
    &.n-first{
      transition-duration: 0s;
    }
  }

  &-footer{
    margin-top: 100px;
    padding-left: 100px;
    position: relative;
    z-index: 3;

    @media (max-width: 767px) {
      margin-top: 50px;
      padding-left: 0;
    }
  }

  &-top{
    margin-top: 145px;
    margin-bottom: 80px;
    padding-left: 100px;

    @media (max-width: 767px) {
      margin-top: 50px;
      margin-bottom: 30px;
      padding-left: 0;

      .container{
        padding: 0;
      }
    }
  }

  &-banner{
    height: 400px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    text-align: center;
    position: relative;

    @media (max-width: 991px) {
      height: 230px;
    }

    &.bg1{
      background-image: url('/template/resources/images/cont/sub-bg1.png');
    }

    &.bg2{
      background-image: url('/template/resources/images/cont/sub-bg2.png');
    }

    &.bg3{
      background-image: url('/template/resources/images/cont/sub-bg3.png');
    }

    &.bg4{
      background-image: url('/template/resources/images/cont/sub-bg4.png');
    }

    &.bg5{
      background-image: url('/template/resources/images/cont/sub-bg5.png');
    }

    &.bg6{
      background-image: url('/template/resources/images/cont/sub-bg6.png');
    }

    div{
      width: 100%;
      display: inline-block;
      @include middle;
    }

    h3{
      @include font(6rem, 700, #fff);

      @media (max-width: 991px) {
        font-size: 4rem;
      }
    }

    h4{
      margin-bottom: 20px;
      @include font(2rem, 500, #fff);

      @media (max-width: 991px) {
        font-size: 1.8rem;
      }
    }
  }

  &-route{
    margin-top: -30px;
    padding: 30px 50px;
    background-color: #222;
    display: inline-block;
    position: relative;
    z-index: 1;

    @media (max-width: 991px) {
      padding: 20px 40px;
    }

    @media (max-width: 767px) {
      padding: 15px;
    }

    li{
      display: inline-block;
      vertical-align: middle;
      @include font(1.8rem, 500, #fff);

      @media (max-width: 991px) {
        font-size: 1.6rem;
      }

      @media (max-width: 767px) {
        font-size: 1.2rem;
      }

      img{
        @media (max-width: 767px) {
          height: 16px;
        }
      }

      &:after{
        content: '';
        width: 3px;
        height: 3px;
        margin: 0 30px;
        display: inline-block;
        background-color: #fff;
        vertical-align: middle;
        position: relative;
        bottom: 2px;

        @media (max-width: 991px) {
          margin: 0 15px;
        }

        @media (max-width: 767px) {
          margin: 0 10px;
          bottom: 1px;
        }
      }

      &:last-child{
        &:after{
          display: none;
        }
      }
    }
  }

  &-contents{
    padding-left: 100px;

    @media (max-width: 767px) {
      padding-left: 0;
    }

    >div{
      display: none;

      &.visible{
        display: block;
      }
    }
  }

  &-tab{
    &-wrap{
      margin-bottom: 60px;

      @media (max-width: 767px) {
        margin-bottom: 30px;
      }

      &--two-depth{
        margin-bottom: 80px;

        @media (max-width: 767px) {
          margin-bottom: 30px;
        }
      }
    }

    a{
      height: 70px;
      border: 1px solid #ddd;
      background-color: #fff;
      position: relative;

      @media (max-width: 767px) {
        width: 100%!important;
        height: 50px;
      }

      p{
        width: 100%;
        padding: 0 10px;
        @include font(1.8rem, 500, #666);
        text-align: center;
        @include middle;
        word-break: break-all;
      }

      &.selected{
        border: 1px solid #9c7e29;
        background-color: #9c7e29;

        p{
          color: #fff;
        }
      }
    }

    &-contents{
      >div{
        display: none;

        &.visible{
          display: block;
        }
      }
    }

    &--two-depth{
      border-bottom: 1px solid #ddd;
      text-align: center;

      @media (max-width: 767px) {
        border-bottom: none;
      }

      a{
        padding: 0 50px 20px;
        @include font(1.8rem, 500, #999);
        text-align: center;
        position: relative;

        @media (max-width: 767px) {
          width: 100%;
          padding: 10px;
          border: 1px solid #ddd;
        }

        &.selected{
          color: #222;

          @media (max-width: 767px) {
            border: 1px solid #222;
            background-color: #222;
            color: #fff;
          }

          &:after{
            content: '';
            width: 100%;
            height: 2px;
            display: inline-block;
            background-color: #222;
            @include poa(auto, auto, -1px, 0);
          }
        }
      }
    }
  }
}

.paging{
  margin-top: 60px;
  text-align: center;

  >a{
    width: 40px;
    height: 40px;
    display: inline-block;
    vertical-align: middle;
    position: relative;

    img{
      @include poa-center;
    }
  }

  ul{
    display: inline-block;
    vertical-align: middle;

    @media (max-width: 767px) {
      margin: 0 5px;
    }
  }

  li{
    margin: 0 5px;
    display: inline-block;
    vertical-align: middle;

    @media (max-width: 767px) {
      margin: 0;
    }

    a{
      width: 40px;
      height: 40px;
      @include font(1.4rem, 500, #666);
      line-height: 40px;

      @media (max-width: 767px) {
        width: 30px;
        height: 30px;
        line-height: 30px;
      }

      &.selected{
        background-color: #000;
        font-weight: 700;
        color: #fff;
      }
    }
  }

  &__first, &__last{
    @media (max-width: 767px) {
      display: none!important;
    }
  }
}