.basic-text-box{
  padding: 160px;
  background-color: #f9f9f9;

  @media (max-width: 1420px) {
    padding: 80px;
  }

  @media (max-width: 991px) {
    padding: 40px;
  }

  @media (max-width: 767px) {
    padding: 20px 15px;
  }

  &__img{
    margin-bottom: 20px;
    text-align: center;

    ul{
      &.col-3{
        li{
          width: calc((100% - 40px) / 3);

          @media (max-width: 767px) {
            width: 100%;
          }
        }
      }

      &.col-4{
        li{
          width: calc((100% - 60px) / 4);

          @media (max-width: 767px) {
            width: 100%;
          }
        }
      }

      &.col-5{
        li{
          width: calc((100% - 80px) / 5);

          @media (max-width: 767px) {
            width: 100%;
          }
        }
      }
    }

    li{
      margin-right: 20px;
      margin: 0 20px 80px 0;
      display: inline-block;
      vertical-align: middle;

      @media (max-width: 1420px) {
        margin: 0 20px 50px 0;
      }

      @media (max-width: 991px) {
        margin: 0 20px 30px 0;
      }

      &:last-child{
        margin-right: 0;
      }

      img{
        -webkit-box-reflect: below 1px linear-gradient(to bottom, transparent 80%, rgba(255, 255, 255, .3));
      }
    }
  }

  &__text{
    text-align: center;

    h3{
      margin-bottom: 20px;
      @include font(4rem, 700, #003554);

      @media (max-width: 1420px) {
        font-size: 3rem;
      }

      @media (max-width: 991px) {
        font-size: 2.6rem;
      }

      @media (max-width: 767px) {
        font-size: 2.2rem;
      }
    }

    p{
      @include font(2rem, 500, #666);
      text-align: center;
      line-height: 2.8rem;

      @media (max-width: 991px) {
        font-size: 1.8rem;
        line-height: 2.6rem;
      }

      @media (max-width: 767px) {
        font-size: 1.6rem;
        line-height: 2.4rem;
      }
    }

    a{
      height: 64px;
      margin-top: 40px;
      padding: 0 30px;
      background-color: #003554;
      @include font(1.8rem, 500, #fff);
      text-align: center;
      line-height: 64px;

      @media (max-width: 767px) {
        width: 100%;
        height: 50px;
        padding: 0 10px;
        font-size: 1.4rem;
        line-height: 50px;
      }
    }
  }
}