.en{
    &-view{
        &-top{
            border-top: 2px solid #555;

            &__tit{
                padding: 31px 40px 29px;
                @include font(1.9rem, 700, #111);
                text-align: center;
                line-height: 2.9rem;

                @media (max-width: 767px) {
                    padding: 15px;
                    font-size: 1.8rem;
                }
            }

            &__info{
                border-top: 1px solid #ddd;
                border-bottom: 1px solid #ddd;
                background-color: #f8f9fa;
                text-align: center;

                p{
                    @include font(1.6rem, 500, #666);
                    line-height: 60px;

                    @media (max-width: 767px) {
                        font-size: 1.4rem;
                    }

                    span{
                        display: inline-block;
                        color: #999;

                        &:after{
                            content: '';
                            width: 1px;
                            height: 10px;
                            margin: 0 10px;
                            display: inline-block;
                            background-color: #ddd;
                        }
                    }
                }
            }
        }

        &-contents{
            padding: 100px 40px;
            border-bottom: 1px solid #ddd;
            @include font(1.5rem, 400, #333);
            line-height: 2.9rem;

            @media (max-width: 767px) {
                padding: 30px 15px;
                font-size: 1.4rem;
                line-height: 2.4rem;
            }
        }

        &-bottom{
            &__file{
                min-height: 71px;
                padding: 20px 40px 20px 140px;
                border-bottom: 1px solid #ddd;
                position: relative;

                @media (max-width: 767px) {
                    min-height: auto;
                    padding: 15px;
                }

                h4{
                    width: 100px;
                    display: inline-block;
                    @include font(1.6rem, 700, #333);
                    vertical-align: middle;
                    @include poa(24px, auto, auto, 40px);

                    @media (max-width: 767px) {
                        width: 100%;
                        text-align: center;
                        @include po-cancel;
                    }
                }

                li{
                    margin-bottom: 10px;

                    &:last-child{
                        margin-bottom: 0;
                    }
                }

                div{
                    width: calc(100% - 130px);
                    padding-right: 15px;
                    display: inline-block;
                    vertical-align: middle;

                    @media (max-width: 767px) {
                        width: 100%;
                        padding: 10px 0;
                    }
                }

                p{
                    max-width: 100%;
                    padding-right: 170px;
                    display: inline-block;
                    @include font(1.5rem, 400, #333);
                    vertical-align: middle;
                    @include line-1;
                    position: relative;

                    @media (max-width: 767px) {
                        padding-right: 0;
                        padding-bottom: 30px;
                    }

                    span{
                        display: inline-block;
                        @include font(1.3rem, 500, #999);
                        @include middle(auto, 0);

                        @media (max-width: 767px) {
                            font-size: 1.2rem;
                            @include center(auto, 0);
                            right: auto;
                        }
                    }
                }

                a{
                    width: 130px;
                    height: 30px;
                    border: 1px solid #999;
                    @include font(1.4rem, 500, #111);
                    text-align: center;
                    vertical-align: middle;
                    line-height: 28px;

                    @media (max-width: 767px) {
                        width: 100%;
                    }

                    img{
                        margin-left: 8px;
                        vertical-align: middle;
                    }
                }
            }

            &__btn{
                margin-top: 50px;
                text-align: center;

                @media (max-width: 767px) {
                    margin-top: 30px;
                }

                a{
                    width: 120px;
                    height: 50px;
                    background-color: #333;
                    @include font(1.4rem, 700, #fff);
                    line-height: 50px;

                    @media (max-width: 767px) {
                        width: 100%;
                    }
                }
            }
        }
    }
}