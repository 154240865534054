.vision{
  &-tit{
    margin-bottom: 30px;
    @include font(4rem, 700, #003554);
    text-align: center;

    @media (max-width: 1520px) {
      font-size: 3.2rem;
    }

    @media (max-width: 991px) {
      font-size: 2.6rem;
    }
  }

  &-text{
    margin-bottom: 110px;
    padding: 80px 10px;
    position: relative;

    @media (max-width: 1520px) {
      margin-bottom: 60px;
    }

    @media (max-width: 1050px) {
      margin-bottom: 0;
    }

    @media (max-width: 767px) {
      padding: 30px 0;
    }

    img{
      @include poa-center;
    }

    p{
      @include font(2rem, 500, #666);
      text-align: center;
      line-height: 3rem;

      @media (max-width: 1520px) {
        font-size: 1.8rem;
        line-height: 2.8rem;
      }

      @media (max-width: 991px) {
        font-size: 1.4rem;
      }
    }
  }

  &-wrap{
    &__padding{
      padding-top: 60px;
      text-align: center;

      @media (max-width: 767px) {
        padding-top: 0;
      }

      &:nth-of-type(2){
        margin: 70px 0 -130px;
        padding-top: 0;

        @media (max-width: 991px) {
          margin: 40px 0 -180px;
        }

        @media (max-width: 767px) {
          margin: 30px 0 20px;
        }

        h4{
          &:before{
            display: none;
          }

          &:after{
            display: none;
          }
        }
      }
    }

    h4{
      height: 60px;
      padding: 0 40px;
      border-radius: 60px;
      display: inline-block;
      background-color: #aeaeae;
      @include font(2.6rem, 500, #111);
      text-align: center;
      line-height: 60px;
      position: relative;
      z-index: 1;

      @media (max-width: 1720px) {
        font-size: 2.2rem;
      }

      @media (max-width: 1600px) {
        padding: 0 20px;
        font-size: 2rem;
      }

      @media (max-width: 1520px) {
        font-size: 1.8rem;
      }

      @media (max-width: 1050px) {
        font-size: 1.6rem;
      }

      @media (max-width: 991px) {
        width: 120px;
        height: 40px;
        padding: 0 10px;
        font-size: 1.2rem;
        line-height: 40px;
      }

      @media (max-width: 767px) {
        width: 200px;
        font-size: 1.6rem;
      }

      &:before{
        content: '';
        width: 1px;
        height: 60px;
        display: inline-block;
        background-color: #ccc;
        @include center(auto, 100%);

        @media (max-width: 767px) {
          display: none;
        }
      }

      &:after{
        content: '';
        width: 11px;
        height: 11px;
        border-radius: 11px;
        display: inline-block;
        background-color: #ccc;
        @include center(auto, calc(100% + 60px));

        @media (max-width: 767px) {
          display: none;
        }
      }
    }

    &__one-depth{
      margin-top: -30px;
      padding-top: 125px;
      position: relative;

      @media (max-width: 991px) {
        margin-top: -20px;
      }

      @media (max-width: 767px) {
        padding-top: 40px;
      }

      &:before{
        content: '';
        width: calc(100% - (((100% - 160px) / 5)));
        height: 1px;
        display: inline-block;
        background-color: #ccc;
        @include center;

        @media (max-width: 1350px) {
          width: calc(100% - (((100% - 80px) / 5)));
        }

        @media (max-width: 991px) {
          width: calc(100% - (((100% - 40px) / 5)));
        }

        @media (max-width: 767px) {
          display: none;
        }
      }

      >li{
        width: calc((100% - 160px) / 5);
        margin-right: 40px;
        display: inline-block;
        vertical-align: top;
        position: relative;

        @media (max-width: 1350px) {
          width: calc((100% - 80px) / 5);
          margin-right: 20px;
        }

        @media (max-width: 991px) {
          width: calc((100% - 40px) / 5);
          margin-right: 10px;
        }

        @media (max-width: 767px) {
          width: 100%;
          margin-right: 0;
          margin-bottom: 30px;
        }

        &:before{
          content: '';
          width: 1px;
          height: 100px;
          display: inline-block;
          background-color: #ccc;
          @include center(auto, calc(100% + 25px));

          @media (max-width: 767px) {
            display: none;
          }
        }

        &:after{
          content: '';
          width: 12px;
          height: 12px;
          display: inline-block;
          background-color: #ccc;
          @include center(auto, calc(100% + 25px));
          transform: translateX(-50%) rotate(45deg);

          @media (max-width: 767px) {
            display: none;
          }
        }

        &:nth-child(5){
          margin-right: 0;
          margin-bottom: 0;
        }
      }

      &__circle{
        padding-bottom: 100%;
        border-radius: 1000px;
        background-color: #003554;
        position: relative;

        div{
          width: 100%;
          text-align: center;
          @include middle;

          img{
            @media (max-width: 1520px) {
              height: 35px;
            }

            @media (max-width: 991px) {
              height: 30px;
            }

            @media (max-width: 767px) {
              height: auto;
            }
          }

          p{
            margin-top: 25px;
            @include font(2.6rem, 700, #fff);
            line-height: 3.6rem;

            @media (max-width: 1720px) {
              font-size: 2.2rem;
              line-height: 3.2rem;
            }

            @media (max-width: 1600px) {
              font-size: 2rem;
              line-height: 3rem;
            }

            @media (max-width: 1520px) {
              margin-top: 15px;
              font-size: 1.8rem;
              line-height: 2.6rem;
            }

            @media (max-width: 1050px) {
              font-size: 1.6rem;
              line-height: 2.2rem;
            }

            @media (max-width: 991px) {
              margin-top: 10px;
              font-size: 1.2rem;
              line-height: 1.6rem;
            }

            @media (max-width: 767px) {
              margin-top: 20px;
              font-size: 1.6rem;
              line-height: 2.6rem;
            }
          }
        }
      }

      h4{
        width: 250px;
        max-width: 100%;
        @include center(auto, 245px);

        @media (max-width: 767px) {
          display: none;
        }

        &:before{
          display: none;
        }

        &:after{
          display: none;
        }
      }

      &:nth-of-type(2){
        padding-top: 0;

        &:before{
          display: none;
        }

        li{
          &:before{
            display: none;
          }

          &:after{
            display: none;
          }
        }

        > li{
          @media (max-width: 767px) {
            margin-bottom: 0;
          }
        }
      }
    }

    &__two-depth{
      min-height: 155px;
      margin-top: 230px;
      padding: 25px;
      border: 1px solid #ccc;
      position: relative;

      @media (max-width: 1520px) {
        padding: 15px;
      }

      @media (max-width: 767px) {
        height: auto!important;
        min-height: auto;
        margin-top: 50px;
      }

      &:before{
        content: '';
        width: 1px;
        height: 230px;
        border-left: 1px dashed #ccc;
        display: inline-block;
        @include center(auto, 100%);

        @media (max-width: 991px) {
          height: 130px;
        }

        @media (max-width: 767px) {
          height: 50px;
        }
      }

      &:after{
        content: '';
        width: 12px;
        height: 12px;
        background-color: #ccc;
        display: inline-block;
        @include center(auto, calc(100% - 6px));
        transform: translateX(-50%) rotate(45deg);
      }

      li{
        margin-bottom: 10px;
        padding-left: 13px;
        @include font(1.8rem, 500, #222);
        position: relative;

        @media (max-width: 1600px) {
          font-size: 1.6rem;
        }

        @media (max-width: 991px) {
          font-size: 1.2rem;
        }

        &:before{
          content: '';
          width: 3px;
          height: 3px;
          display: inline-block;
          background-color: #666;
          @include poa(10px, auto, auto, 0);
        }

        &:last-child{
          margin-bottom: 0;
        }
      }
    }
  }
}