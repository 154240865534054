.organization{
    &-list{
        &__area0{
            margin-bottom: 30px;
            text-align: center;
            position: relative;

            &:after{
                content: '';
                width: 1px;
                height: 30px;
                display: inline-block;
                background-color: #ddd;
                @include center(100%);
            }

            a{
                width: 140px;
                height: 140px;
                border-radius: 140px;
                display: inline-block;
                background-color: #162038;
                @include font(2rem, 500, #fff);
                line-height: 140px;
                position: relative;
                z-index: 1;

                @media (max-width: 767px) {
                    width: 75px;
                    height: 75px;
                    line-height: 75px;
                    font-size: 1.8rem;
                }
            }

            div{
                padding-top: 79px;
                @include middle(auto, calc((50% - 97px) - 250px));
                z-index: 0;

                @media (max-width: 991px) {
                    right: calc((50% - 97px) - 200px);
                }

                @media (max-width: 767px) {
                    width: 80px;
                    padding-top: 51px;
                    right: calc((50% - 40px) - 85px);
                }

                &:after{
                    content: '';
                    width: 150px;
                    height: 1px;
                    display: inline-block;
                    background-color: #ddd;
                    @include middle(auto, 100%);

                    @media (max-width: 767px) {
                        width: 100%;
                    }
                }

                a{
                    width: 195px;
                    height: 60px;
                    border-radius: 0;
                    display: block;
                    background-color: #444;
                    @include font(1.8rem, 500, #fff);
                    line-height: 60px;
                    position: relative;

                    @media (max-width: 767px) {
                        width: 75px;
                        height: 40px;
                        font-size: 1.4rem;
                        line-height: 40px;
                    }

                    &:first-child{
                        margin-bottom: 20px;

                        @media (max-width: 767px) {
                            margin-bottom: 10px;
                        }
                        
                        &:before{
                            content: '';
                            width: 1px;
                            height: calc(100% + 20px);
                            // height: 20px;
                            display: inline-block;
                            background-color: #ddd;
                            @include poa(50%, auto, auto, -20px);

                            @media (max-width: 767px) {
                                height: calc(100% + 10px);
                                left: -5px;
                                // height: 10px;
                            }
                        }
                    }

                    &:last-child{
                        &:before{
                            content: '';
                            width: 20px;
                            height: 1px;
                            display: inline-block;
                            background-color: #ddd;
                            @include middle(auto, 100%);

                            @media (max-width: 767px) {
                                width: 5px;
                            }
                        }
                    }
                }
            }
        }

        &__area1{
            text-align: center;
            position: relative;

            a{
                width: 140px;
                height: 140px;
                border-radius: 140px;
                display: inline-block;
                background-color: #162038;
                @include font(2rem, 500, #fff);
                line-height: 140px;

                @media (max-width: 767px) {
                    width: 75px;
                    height: 75px;
                    line-height: 75px;
                    font-size: 1.8rem;
                }
            }
        }

        &__area2{
            padding: 60px 0;
            position: relative;

            @media (max-width: 767px) {
                padding: 15px 0 0;
            }

            &:after{
                content: '';
                width: 1px;
                height: 100%;
                display: inline-block;
                background-color: #ddd;
                @include center;

                // @media (max-width: 767px) {
                //     height: 60px;
                // }
            }

            >div{
                width: 50%;
                display: inline-block;
                vertical-align: middle;

                // @media (max-width: 767px) {
                //     width: 100%;
                // }
            }

            a{
                width: 200px;
                height: 60px;
                margin-bottom: 20px;
                background-color: #444;
                @include font(1.8rem, 500, #fff);
                text-align: center;
                position: relative;
                line-height: 60px;

                @media (max-width: 1200px) {
                    width: 130px;
                }

                @media (max-width: 991px) {
                    width: 115px;
                }

                // @media (max-width: 767px) {
                //     width: 100%;
                // }

                @media (max-width: 767px) {
                    width: 55px;
                    height: 40px;
                    font-size: 1.2rem;
                    line-height: 40px;
                }

                &:last-child{
                    margin-bottom: 0;
                }
            }

            &__left{
                padding-right: 150px;
                text-align: right;

                @media (max-width: 991px) {
                    padding-right: 60px;
                }

                @media (max-width: 767px) {
                    padding-right: 0;
                    padding-bottom: 0;
                    text-align: left;
                }

                a{
                    &:nth-of-type(1){
                        margin-bottom: 80px;

                        @media (max-width: 767px) {
                            margin-bottom: 20px;
                        }

                        &:after{
                            content: '';
                            width: 150px;
                            height: 1px;
                            display: inline-block;
                            background-color: #ddd;
                            @include middle(100%);

                            @media (max-width: 991px) {
                                width: 60px;
                            }

                            @media (max-width: 767px) {
                                display: none;
                            }
                        }
                    }
                }
            }

            &__right{
                padding-left: 150px;
                position: relative;

                @media (max-width: 1320px) {
                    padding-left: 80px;
                }
                
                @media (max-width: 991px) {
                    padding-left: 40px;
                }

                @media (max-width: 767px) {
                    // padding-left: 0;
                    padding-left: 10px;
                }

                // &:before{
                //     content: '';
                //     width: 1px;
                //     height: calc(100% - 60px);
                //     display: inline-block;
                //     background-color: #ddd;
                //     @include poa(30px, auto, auto, 100px);

                //     @media (max-width: 991px) {
                //         left: 30px;
                //     }

                //     @media (max-width: 767px) {
                //         @include center;
                //     }
                // }

                a{
                    margin-right: 20px;

                    @media (max-width: 767px) {
                        margin-right: 5px;
                    }

                    // &:after {
                    //     content: '';
                    //     width: 50px;
                    //     height: 1px;
                    //     display: inline-block;
                    //     background-color: #ddd;
                    //     @include middle(auto, 100%);
                    
                    //     @media (max-width: 991px) {
                    //         width: 30px;
                    //     }
                    
                    //     @media (max-width: 767px) {
                    //         display: none;
                    //     }
                    // }

                    &:after{
                        content: '';
                        width: 1px;
                        height: 20px;
                        display: inline-block;
                        background-color: #ddd;
                        @include center(auto, 100%);

                        @media (max-width: 767px) {
                            height: 10px;
                        }
                    }

                    &:nth-of-type(1){
                        margin: 0 0 20px 0;

                        @media (max-width: 767px) {
                            margin: 0 0 20px 0;
                        }

                        &:after{
                            content: '';
                            width: 150px;
                            height: 1px;
                            display: inline-block;
                            background-color: #ddd;
                            @include middle(auto, 100%);

                            @media (max-width: 1320px) {
                                width: 80px;
                            }

                            @media (max-width: 991px) {
                                width: 40px;
                            }

                            @media (max-width: 767px) {
                                width: 10px;
                            }

                            // @media (max-width: 767px) {
                            //     display: none;
                            // }
                        }

                        &:before{
                            content: '';
                            width: 1px;
                            height: 20px;
                            display: inline-block;
                            background-color: #ddd;
                            @include center(100%);

                            @media (max-width: 767px) {
                                height: 10px;
                            }
                        }
                    }

                    // &:nth-of-type(2){
                    //     &:before{
                    //         content: '';
                    //         width: calc(100% + 20px);
                    //         height: 1px;
                    //         display: inline-block;
                    //         background-color: #ddd;
                    //         @include poa(auto, auto, calc(100% + 20px), 50%);

                    //         @media (max-width: 767px) {
                    //             width: calc(100% + 5px);
                    //             bottom: calc(100% + 10px);
                    //         }
                    //     }
                    // }

                    &:last-child{
                        margin-right: 0;
                    }
                }
            }
        }

        &__area3{
            padding-top: 60px;
            position: relative;
            word-break: break-all;

            @media (max-width: 767px) {
                padding-top: 15px;
            }

            > span{
                content: '';
                height: 1px;
                display: inline-block;
                background-color: #ddd;

                @media (max-width: 767px) {
                    display: none;
                }
            }

            a{
                width: 100%;
                max-width: 244px;
                height: 60px;
                // line-height: 60px;

                @media (max-width: 1720px) {
                    max-width: 211px;
                }

                @media (max-width: 1520px) {
                    max-width: 178px;
                }

                @media (max-width: 1320px) {
                    max-width: 145px;
                }

                @media (max-width: 1200px) {
                    max-width: 120px;
                }

                @media (max-width: 767px) {
                    max-width: none;
                }
            }

            .null{
                // width: 195px;
                width: calc(((100% - (65px * 2)) / 6) * 1);
                margin: 60px 0 30px;
                height: 60px;

                @media (max-width: 767px) {
                    display: none;
                }
            }

            &__col{
                display: inline-block;
                vertical-align: top;
                text-align: center;

                @media (max-width: 767px) {
                    padding-bottom: 30px;
                    border-bottom: 1px solid #ddd;
                }

                .f-depth{
                    >li{
                        >a{
                            background-color: #003554;
                            position: relative;
                            z-index: 1;

                            p{
                                width: 100%;
                                @include middle;
                                @include font(1.8rem, 500, #fff);

                                @media (max-width: 1150px) {
                                    font-size: 1.4rem;
                                }
                            }

                            &:before{
                                content: '';
                                width: 1px;
                                height: 60px;
                                display: inline-block;
                                background-color: #ddd;
                                @include center(auto, 100%);
                            }
                        }

                        >.null{
                            margin-top: 0;
                        }
                    }
                }

                .s-depth{
                    >li{
                        >a{
                            margin: 60px 0 30px;
                            border: 2px solid #003554;
                            background-color: #fff;
                            position: relative;
                            z-index: 1;

                            @media (max-width: 767px) {
                                margin: 30px 0 0;
                            }

                            p{
                                width: 100%;
                                @include middle;
                                @include font(1.8rem, 500, #003554);

                                @media (max-width: 1150px) {
                                    font-size: 1.4rem;
                                }
                            }

                            &:before{
                                content: '';
                                width: 1px;
                                height: 60px;
                                display: inline-block;
                                background-color: #ddd;
                                @include center(auto, calc(100% + 2px));

                                @media (max-width: 767px) {
                                    display: none;
                                }
                            }

                            &:after{
                                content: '';
                                width: 1px;
                                height: 30px;
                                display: inline-block;
                                background-color: #ddd;
                                @include center(calc(100% + 2px), auto);

                                @media (max-width: 767px) {
                                    display: none;
                                }
                            }
                        }
                    }
                }

                &__wrap{
                    padding-top: 30px;
                    position: relative;

                    @media (max-width: 767px) {
                        padding-top: 0;
                    }

                    &.n-line{
                        &:before{
                            display: none;
                        }

                        &:before{
                            content: '';
                            width: 1px;
                            height: 300px;
                            display: inline-block;
                            background-color: #ddd;
                            @include center(auto, calc(100% - 30px));

                            @media (max-width: 767px) {
                                display: none;
                            }
                        }

                        li{
                            a:first-child:before{
                                display: none;
                            }
                        }
                    }

                    &:before {
                        content: '';
                        width: calc(66% + 8px);
                        height: 1px;
                        display: inline-block;
                        background-color: #ddd;
                        @include center;

                        @media (max-width: 767px) {
                            display: none;
                        }
                    }

                    .t-depth{
                        li{
                            &.col-1{
                                width: 100%;
                            }

                            &:last-child{
                                margin-right: 0;
                            }

                            a{
                                margin-top: 10px;
                                border: 2px solid #ccc;
                                position: relative;

                                p{
                                    width: 100%;
                                    @include font(1.8rem, 500, #666);
                                    @include middle;

                                    @media (max-width: 1200px) {
                                        font-size: 1.4rem;
                                    }
                                }
                            }
                        }

                        > li{
                            width: calc((100% - 20px) / 3);
                            margin-top: 0;
                            margin-right: 10px;
                            display: inline-block;
                            vertical-align: top;
                            position: relative;

                            @media (max-width: 767px) {
                                width: 100%;
                                margin-top: 15px;
                                margin-right: 0;
                            }
                            
                            >a{
                                margin-top: 0;
                                border: 2px solid #9c7e29;
                                background-color: #9c7e29;

                                p {
                                    width: 100%;
                                    @include font(1.8rem, 500, #fff);
                                    @include middle;
                            
                                    @media (max-width: 1200px) {
                                        font-size: 1.4rem;
                                    }
                                }
                            }
                            
                            &:before {
                                content: '';
                                width: 1px;
                                height: 30px;
                                display: inline-block;
                                background-color: #ddd;
                                @include center(auto, calc(100%));

                                @media (max-width: 767px) {
                                    display: none;
                                }
                            }
                        }
                    }
                }

                &.col1{
                    width: calc(((100% - (65px * 2 + 30px)) / 6) * 4 + 30px);
                    margin-right: 65px;

                    @media (max-width: 991px) {
                        width: calc(((100% - (10px * 2 + 30px)) / 6) * 4 + 30px);
                        margin-right: 10px;
                    }

                    @media (max-width: 767px) {
                        width: 100%;
                        margin-right: 0;
                        margin-bottom: 30px;
                    }

                    .f-depth{
                        >li{
                            >a{
                                margin-right: 250px;
                                position: relative;

                                @media (max-width: 1720px) {
                                    margin-right: 200px;
                                }

                                @media (max-width: 1520px) {
                                    margin-right: 170px;
                                }

                                @media (max-width: 1320px) {
                                    margin-right: 130px;
                                }

                                @media (max-width: 1200px) {
                                    margin-right: 110px;
                                }

                                @media (max-width: 767px) {
                                    margin-right: 0;
                                }

                                &:after{
                                    content: '';
                                    width: 1px;
                                    height: 30px;
                                    display: inline-block;
                                    background-color: #ddd;
                                    @include center(100%);

                                    @media (max-width: 767px) {
                                        display: none;
                                    }
                                }
                            }

                            >div{
                                position: relative;

                                &:before{
                                    content: '';
                                    width: calc(((100% / 4) * 2) + 6px);
                                    height: 1px;
                                    display: inline-block;
                                    background-color: #ddd;
                                    @include poa(30px, auto, auto, calc((((100% - 30px) / 4) * .5) + 2px));

                                    @media (max-width: 767px) {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }

                    .s-depth{                        
                        display: inline-block;
                        vertical-align: top;

                        &:first-of-type{
                            width: calc(((100% - 10px) / 4) * 1);
                            margin-right: 10px;

                            @media (max-width: 767px) {
                                width: 100%;
                            }

                            .organization-list__area3__col__wrap{
                                &:before{
                                    display: none;
                                }
                            }
                        }

                        &:last-of-type{
                            width: calc(((100% - 10px) / 4) * 3);

                            @media (max-width: 767px) {
                                width: 100%;
                            }
                        }

                        > li{
                            a{
                                &:before{
                                    height: 30px;
                                }
                            }
                        }
                    }
                }

                &.col2{
                    width: calc(((100% - (65px * 2 + 30px)) / 6) * 1);
                    margin-right: 65px;

                    @media (max-width: 991px) {
                        width: calc(((100% - (10px * 2 + 30px)) / 6) * 1);
                        margin-right: 10px;
                    }

                    @media (max-width: 767px) {
                        width: 100%;
                        margin-right: 0;
                        margin-bottom: 30px;
                    }

                    .f-depth{
                        >li{
                            a{
                                &:before{
                                    display: none;
                                }
                            }
                        }
                    }

                    .s-depth{
                        > li{
                            >a{
                                &:before{
                                    display: none;
                                }

                                &:after{
                                    display: none;
                                }
                            }
                        }
                    }

                    .t-depth{
                        >li{
                            &:before{
                                display: none;
                            }
                        }
                    }
                }

                &.col3{
                    width: calc(((100% - (65px * 2 + 30px)) / 6) * 1);
                    // margin-right: 65px;

                    @media (max-width: 991px) {
                        width: calc(((100% - (10px * 2 + 30px)) / 6) * 1);
                        // margin-right: 10px;
                    }

                    @media (max-width: 767px) {
                        width: 100%;
                        margin-right: 0;
                        margin-bottom: 30px;
                    }

                    .f-depth{
                        >li{
                            a{
                                &:before{
                                    display: none;
                                }
                            }
                        }
                    }

                    .s-depth{
                        > li{
                            >a{
                                &:before{
                                    display: none;
                                }

                                &:after{
                                    display: none;
                                }
                            }
                        }
                    }

                    .t-depth{
                        >li{
                            &:before{
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }

    &-info{
        margin-top: 80px;

        div{
            display: none;
            overflow-x: auto;

            &.visible{
                display: block;
            }
        }

        table{
            width: 100%;
            min-width: 767px;
            border-top: 2px solid #9c7e29;
            table-layout: fixed;
        }

        tr{
            border-bottom: 1px solid #ddd;
        }

        th{
            height: 70px;
            border-right: 1px solid #ddd;
            background-color: #f9f9f9;
            @include font(1.8rem, 500, #222);
            
            &:last-child{
                border-right: none;
            }
        }
        
        td{
            height: 70px;
            border-right: 1px solid #ddd;
            @include font(1.8rem, 500, #666);

            &:last-child{
                border-right: none;
            }
        }

        .w-5{width: 5%;}
        .w-10{width: 10%;}
        .w-15{width: 15%;}
        .w-20{width: 20%;}
        .w-25{width: 25%;}
        .w-30{width: 30%;}
        .w-35{width: 35%;}
        .w-40{width: 40%;}
        .w-45{width: 45%;}
        .w-50{width: 50%;}
        .w-55{width: 55%;}
        .w-60{width: 60%;}
        .w-65{width: 65%;}
        .w-70{width: 70%;}
        .w-75{width: 75%;}
        .w-80{width: 80%;}
        .w-85{width: 85%;}
        .w-90{width: 90%;}
        .w-95{width: 95%;}
        .w-100{width: 100%;}

        .ta-l{text-align: left;}
        .ta-c{text-align: center;}
        .ta-r{text-align: right;}
        .va-t{vertical-align: top;}
        .va-m{vertical-align: middle;}
        .va-b{vertical-align: bottom;}

        @media (max-width: 1200px) {
          .w-md-5{width: 5%;}
          .w-md-10{width: 10%;}
          .w-md-15{width: 15%;}
          .w-md-20{width: 20%;}
          .w-md-25{width: 25%;}
          .w-md-30{width: 30%;}
          .w-md-35{width: 35%;}
          .w-md-40{width: 40%;}
          .w-md-45{width: 45%;}
          .w-md-50{width: 50%;}
          .w-md-55{width: 55%;}
          .w-md-60{width: 60%;}
          .w-md-65{width: 65%;}
          .w-md-70{width: 70%;}
          .w-md-75{width: 75%;}
          .w-md-80{width: 80%;}
          .w-md-85{width: 85%;}
          .w-md-90{width: 90%;}
          .w-md-95{width: 95%;}
          .w-md-100{width: 100%;}
          .w-md-n{display: none;}
        }

        @media (max-width: 991px) {
          .w-sm-5{width: 5%;}
          .w-sm-10{width: 10%;}
          .w-sm-15{width: 15%;}
          .w-sm-20{width: 20%;}
          .w-sm-25{width: 25%;}
          .w-sm-30{width: 30%;}
          .w-sm-35{width: 35%;}
          .w-sm-40{width: 40%;}
          .w-sm-45{width: 45%;}
          .w-sm-50{width: 50%;}
          .w-sm-55{width: 55%;}
          .w-sm-60{width: 60%;}
          .w-sm-65{width: 65%;}
          .w-sm-70{width: 70%;}
          .w-sm-75{width: 75%;}
          .w-sm-80{width: 80%;}
          .w-sm-85{width: 85%;}
          .w-sm-90{width: 90%;}
          .w-sm-95{width: 95%;}
          .w-sm-100{width: 100%;}
          .w-sm-n{display: none;}
        }

        @media (max-width: 767px) {
          .w-xs-5{width: 5%;}
          .w-xs-10{width: 10%;}
          .w-xs-15{width: 15%;}
          .w-xs-20{width: 20%;}
          .w-xs-25{width: 25%;}
          .w-xs-30{width: 30%;}
          .w-xs-35{width: 35%;}
          .w-xs-40{width: 40%;}
          .w-xs-45{width: 45%;}
          .w-xs-50{width: 50%;}
          .w-xs-55{width: 55%;}
          .w-xs-60{width: 60%;}
          .w-xs-65{width: 65%;}
          .w-xs-70{width: 70%;}
          .w-xs-75{width: 75%;}
          .w-xs-80{width: 80%;}
          .w-xs-85{width: 85%;}
          .w-xs-90{width: 90%;}
          .w-xs-95{width: 95%;}
          .w-xs-100{width: 100%;}
          .w-xs-n{display: none;}
        }        
    }
}