.en{
    &-history{
        &__l{
            width: 375px;
            display: inline-block;
            vertical-align: top;

            @media (max-width: 991px) {
                width: 250px;
            }

            @media (max-width: 767px) {
                width: 100%;
            }
        }

        &__r{
            width: calc(100% - 375px);
            display: inline-block;
            vertical-align: top;

            @media (max-width: 991px) {
                width: calc(100% - 250px);
            }

            @media (max-width: 767px) {
                width: 100%;
            }
        }

        &__tit{
            margin-bottom: 50px;
            @include font(3.6rem, 700, #111);
            line-height: 5rem;

            @media (max-width: 991px) {
                font-size: 3rem;
                line-height: 4.4rem;
            }

            @media (max-width: 767px) {
                font-size: 2.4rem;
                line-height: 3.8rem;

                br{
                    display: none;
                }
            }
        }

        &__tab{
            position: relative;

            &:after{
                content: '';
                width: 2px;
                height: calc(100% - 15px);
                display: inline-block;
                background-color: #ddd;
                @include poa(7px, auto, auto, 7px);

                @media (max-width: 767px) {
                    display: none;
                }
            }

            li{
                margin-bottom: 30px;

                @media (max-width: 767px) {
                    width: 50%;
                    margin-bottom: 15px;
                    display: inline-block;
                    vertical-align: top;
                }
            }

            a{
                padding-left: 25px;
                @include font(2.6rem, 700, #aaa);
                line-height: 1;
                position: relative;
                z-index: 1;

                @media (max-width: 767px) {
                    padding-left: 20px;
                    font-size: 2.2rem;
                }

                &:before{
                    content: '';
                    width: 16px;
                    height: 16px;
                    border: 4px solid #aaa;
                    border-radius: 16px;
                    display: inline-block;
                    background-color: #fff;
                    @include middle;
                    box-sizing: border-box;

                    @media (max-width: 767px) {
                        width: 12px;
                        height: 12px;
                        border: 3px solid #aaa;
                    }
                }

                &.selected{
                    color: #0078c7;

                    &:before{
                        border: 4px solid #0078c7;

                        @media (max-width: 767px) {
                            border: 3px solid #0078c7;
                        }
                    }
                }

            }
        }

        &__list{
            border-top: 1px solid #ddd;
            padding: 45px 0;

            @media (max-width: 767px) {
                padding: 20px 0;
            }
        }

        &__year{
            width: 120px;
            display: inline-block;
            @include font(2.6rem, 700, #111);
            vertical-align: top;

            @media (max-width: 991px) {
                width: 80px;
                font-size: 2.2rem;
            }

            @media (max-width: 767px) {
                width: 100%;
            }
        }

        &__info{
            width: calc(100% - 120px);
            padding-top: 5px;
            display: inline-block;
            vertical-align: top;

            @media (max-width: 991px) {
                width: calc(100% - 80px);
                padding-top: 2px;
            }

            @media (max-width: 767px) {
                width: 100%;
                padding-top: 0;
            }

            li{
                margin-bottom: 15px;
                padding-left: 100px;
                @include font(1.6rem, 400, #666);
                line-height: 3.1rem;
                position: relative;

                @media (max-width: 991px) {
                    padding-left: 60px;
                    line-height: 2.8rem;
                }

                @media (max-width: 767px) {
                    padding-left: 0;
                    font-size: 1.4rem;
                    line-height: 2.4rem;
                }

                &:last-child{
                    margin-bottom: 0;
                }

                span{
                    width: 100px;
                    font-weight: 700;
                    color: #0078c7;
                    @include poa(0, auto, auto, 0);

                    @media (max-width: 991px) {
                        width: 60px;
                    }

                    @media (max-width: 767px) {
                        width: 100%;
                        display: block;
                        @include po-cancel;
                    }
                }
            }
        }
    }
}