.view{
  &-top{
    padding: 0 40px;
    border-top: 2px solid #222;
    border-bottom: 1px solid #ddd;

    @media (max-width: 991px) {
      padding: 0 20px;
    }

    &__tit{
      padding: 40px 0 30px;
      border-bottom: 1px dashed #ddd;
      position: relative;

      @media (max-width: 991px) {
        padding: 20px 0 15px;
      }

      h4{
        max-width: 74px;
        height: 23px;
        margin: 12px 15px 0 auto;
        // margin-right: 15px;
        padding: 0 10px;
        display: inline-block;
        background-color: #9c7e29;
        @include font(1.4rem, 500, #fff);
        text-align: center;
        line-height: 23px;
        vertical-align: top;

        @media (max-width: 991px) {
          margin: 0 auto 0;
        }

        @media (max-width: 767px) {
          margin: 0 0 10px 0;
          display: block;
        }
      }

      h3{
        max-width: calc(100% - 89px);
        display: inline-block;
        @include font(3rem, 700, #222);
        vertical-align: top;

        @media (max-width: 991px) {
          font-size: 2.2rem;
        }

        @media (max-width: 767px) {
          padding-left: 0;
          font-size: 2rem;
        }
      }
    }

    &__info{
      padding: 30px 0 40px;

      @media (max-width: 991px) {
        padding: 15px 0 20px;
      }

      li{
        margin: 0 230px 30px 0;
        display: inline-block;
        vertical-align: top;

        @media (max-width: 1260px) {
          margin-right: 100px;
        }

        @media (max-width: 991px) {
          margin-right: 50px;
        }

        @media (max-width: 767px) {
          width: 100%;
          margin: 0 0 15px 0;
        }

        &:nth-child(3n){
          margin-right: 0;
        }

        &.file{
          width: 100%;
          margin: 0;
        }

        p{
          padding-left: 20px;
          display: inline-block;
          @include font(1.6rem, 500, #666);
          vertical-align: top;
          position: relative;

          &:first-child{
            padding-left: 13px;

            @media (max-width: 767px) {
              width: 61px;
            }

            &:before{
              content: '';
              width: 3px;
              height: 3px;
              display: inline-block;
              background-color: #666;
              @include middle;
            }
          }
        }

        a{
          max-width: 100%;
          margin-bottom: 10px;
          padding-left: 25px;
          @include font(1.6rem, 500, #666);
          text-decoration: underline;
          @include line-1;
          position: relative;

          &:before{
            content: '';
            width: 16px;
            height: 19px;
            display: inline-block;
            background-image: url('/template/resources/images/icons/view-file-icon.png');
            @include middle;
          }
        }
      }

      &__file{
        width: calc(100% - 45px);
        padding-left: 35px;
        display: inline-block;
        vertical-align: top;

        @media (max-width: 767px) {
          width: 100%;
          margin-top: 5px;
          padding-left: 13px;
        }
      }
    }
  }

  &-cont{
    padding: 60px 140px 100px;
    border-bottom: 1px solid #ddd;
    @include font(1.8rem, 500, #666);

    img{
      height: auto!important;
      margin: 0 auto;
      display: block;
    }

    @media (max-width: 1320px) {
      padding: 60px 100px 100px;
    }

    @media (max-width: 1260px) {
      padding: 60px 80px 100px;
    }

    @media (max-width: 991px) {
      padding: 30px 20px 50px;
    }

    @media (max-width: 767px) {
      font-size: 1.6rem;
    }

    &__video{
      width: 100%;
      padding-bottom: 56.25%;
      position: relative;

      video{
        width: 100%;
        height: 100%;
        @include poa(0, auto, auto, 0);
      }
    }

    .cont{
      margin-top: 60px;

      @media (max-width: 767px) {
        margin-top: 30px;
      }
    }

    .step{
      margin-top: 10px;

      ul{
        margin-top: 0;

        li{
          width: 220px;
          margin-right: 45px;
          margin-bottom: 0;
          padding-left: 0;
          border: 2px solid #003554;
          display: inline-block;
          font-size: 0;
          position: relative;
          vertical-align: top;

          @media (max-width: 1200px) {
            width: 180px;
            margin-right: 30px;
          }

          @media (max-width: 767px) {
            width: 100%;
            margin-right: 0;
            margin-bottom: 30px;
          }

          &:before{
            content: '';
            width: 45px;
            height: 1px;
            border-top: 1px dotted #ccc;
            // border-bottom: 1px dotted #ccc;
            background-color: #fff;
            display: inline-block;
            @include middle(calc(100% + 2px));
            box-sizing: border-box;

            @media (max-width: 1200px) {
              width: 30px;
            }

            @media (max-width: 767px) {
              width: 1px;
              height: 30px;
              border-left: 1px dotted #ccc;
              border-top: none;
              @include center(calc(100% + 2px));
            }
          }

          &:after{
            content: '';
            width: 12px;
            height: 12px;
            border-top: 6px solid transparent;
            border-bottom: 6px solid transparent;
            border-left: 12px solid #ccc;
            border-right: none;
            display: inline-block;
            @include middle(calc(100% + 20px));
            box-sizing: border-box;

            @media (max-width: 1200px) {
              left: calc(100% + 12px);
            }

            @media (max-width: 767px) {
              border-top: 12px solid #ccc;
              border-left: 6px solid transparent;
              border-right: 6px solid transparent;
              border-bottom: none;
              @include center(calc(100% + 12px));
            }
          }

          &:last-child{
            margin-right: 0;

            &:before{
              display: none;
            }

            &:after{
              display: none;
            }
          }
        }
      }

      &-tit{
        padding: 9px 5px;
        background-color: #003554;
        @include font(1.8rem, 700, #fff);
        text-align: center;

        @media (max-width: 1200px) {
          font-size: 1.6rem;
        }
      }

      &-text{
        height: 70px;
        position: relative;

        p{
          width: 100%;
          padding: 0 5px;
          display: inline-block;
          @include middle;
          text-align: center;
          box-sizing: border-box;
          line-height: 1.3;

          @media (max-width: 1200px) {
            font-size: 1.6rem;
          }
        }
      }
    }

    h3{
      margin-bottom: 50px;
      display: block;
      @include font(3.6rem, 700, #222);
      text-align: center;

      @media (max-width: 767px) {
        margin-bottom: 30px;
        font-size: 2.6rem;
      }
    }

    h4{
      margin-bottom: 30px;
      padding-left: 14px;
      @include font(2.4rem, 700, #222);
      position: relative;

      @media (max-width: 767px) {
        margin-bottom: 15px;
        font-size: 1.8rem;
      }

      &:before{
        content: '';
        width: 4px;
        height: 24px;
        display: inline-block;
        background-color: #9c7e29;
        @include poa(7px, auto, auto, 0);

        @media (max-width: 767px) {
          height: 18px;
          top: 6px;
        }
      }
    }

    h5{
      margin-bottom: 15px;
      @include font(1.8rem, 700, #9c7e29);

      @media (max-width: 767px) {
        margin-bottom: 10px;
        font-size: 1.6rem;
      }
    }

    b{
      display: block;
      @include font(2rem, 700, #222);

      @media (max-width: 767px) {
        font-size: 1.8rem;
      }
    }

    p{
      @include font(1.8rem, 400, #666);
      line-height: 3.3rem;

      @media (max-width: 767px) {
        font-size: 1.6rem;
        line-height: 3rem;
      }
    }

    ul{
      margin-top: 10px;

      li{
        margin-bottom: 10px;
        padding-left: 13px;
        @include font(1.8rem, 400, #666);
        position: relative;

        &:before{
          content: '';
          width: 3px;
          height: 3px;
          display: inline-block;
          background-color: #666;
          @include poa(12px, auto, auto, 0);
        }

        &:last-child{
          margin-bottom: 0;
        }
      }
    }

    .bg{
      padding: 20px 40px;
      background-color: #f7f7f7;
    }

    .view-table{
      margin: 20px 0 40px;
      overflow-x: auto;

      @media (max-width: 767px) {
        margin: 15px 0 25px;
      }

      .w-5{width: 5%;}
      .w-10{width: 10%;}
      .w-15{width: 15%;}
      .w-20{width: 20%;}
      .w-25{width: 25%;}
      .w-30{width: 30%;}
      .w-35{width: 35%;}
      .w-40{width: 40%;}
      .w-45{width: 45%;}
      .w-50{width: 50%;}
      .w-55{width: 55%;}
      .w-60{width: 60%;}
      .w-65{width: 65%;}
      .w-70{width: 70%;}
      .w-75{width: 75%;}
      .w-80{width: 80%;}
      .w-85{width: 85%;}
      .w-90{width: 90%;}
      .w-95{width: 95%;}
      .w-100{width: 100%;}

      .ta-l{text-align: left;}
      .ta-c{text-align: center;}
      .ta-r{text-align: right;}
      .va-t{vertical-align: top;}
      .va-m{vertical-align: middle;}
      .va-b{vertical-align: bottom;}

      @media (max-width: 1200px) {
        .w-md-5{width: 5%;}
        .w-md-10{width: 10%;}
        .w-md-15{width: 15%;}
        .w-md-20{width: 20%;}
        .w-md-25{width: 25%;}
        .w-md-30{width: 30%;}
        .w-md-35{width: 35%;}
        .w-md-40{width: 40%;}
        .w-md-45{width: 45%;}
        .w-md-50{width: 50%;}
        .w-md-55{width: 55%;}
        .w-md-60{width: 60%;}
        .w-md-65{width: 65%;}
        .w-md-70{width: 70%;}
        .w-md-75{width: 75%;}
        .w-md-80{width: 80%;}
        .w-md-85{width: 85%;}
        .w-md-90{width: 90%;}
        .w-md-95{width: 95%;}
        .w-md-100{width: 100%;}
        .w-md-n{display: none;}
      }
    
      @media (max-width: 991px) {
        .w-sm-5{width: 5%;}
        .w-sm-10{width: 10%;}
        .w-sm-15{width: 15%;}
        .w-sm-20{width: 20%;}
        .w-sm-25{width: 25%;}
        .w-sm-30{width: 30%;}
        .w-sm-35{width: 35%;}
        .w-sm-40{width: 40%;}
        .w-sm-45{width: 45%;}
        .w-sm-50{width: 50%;}
        .w-sm-55{width: 55%;}
        .w-sm-60{width: 60%;}
        .w-sm-65{width: 65%;}
        .w-sm-70{width: 70%;}
        .w-sm-75{width: 75%;}
        .w-sm-80{width: 80%;}
        .w-sm-85{width: 85%;}
        .w-sm-90{width: 90%;}
        .w-sm-95{width: 95%;}
        .w-sm-100{width: 100%;}
        .w-sm-n{display: none;}
      }
    
      @media (max-width: 767px) {
        .w-xs-5{width: 5%;}
        .w-xs-10{width: 10%;}
        .w-xs-15{width: 15%;}
        .w-xs-20{width: 20%;}
        .w-xs-25{width: 25%;}
        .w-xs-30{width: 30%;}
        .w-xs-35{width: 35%;}
        .w-xs-40{width: 40%;}
        .w-xs-45{width: 45%;}
        .w-xs-50{width: 50%;}
        .w-xs-55{width: 55%;}
        .w-xs-60{width: 60%;}
        .w-xs-65{width: 65%;}
        .w-xs-70{width: 70%;}
        .w-xs-75{width: 75%;}
        .w-xs-80{width: 80%;}
        .w-xs-85{width: 85%;}
        .w-xs-90{width: 90%;}
        .w-xs-95{width: 95%;}
        .w-xs-100{width: 100%;}
        .w-xs-n{display: none;}
      }

      table{
        width: 100%;
        min-width: 800px;
        border-top: 2px solid #9c7e29;
      }

      th{
        padding: 25px 10px;
        border: 1px solid #ddd;
        background-color: #f9f9f9;
        @include font(1.8rem, 500, #222);
        text-align: center;
        vertical-align: middle;
      }

      td{
        padding: 25px 30px;
        border: 1px solid #ddd;
      }

      ul{
        margin-top: 0;
      }
    }
  }

  &-bottom{
    margin-top: 40px;

    @media (max-width: 991px) {
      margin-top: 20px;
    }

    >a{
      width: 240px;
      height: 64px;
      margin: 0 auto 40px;
      display: block;
      background-color: #003554;
      @include font(1.8rem, 500, #fff);
      text-align: center;
      line-height: 64px;

      @media (max-width: 991px) {
        margin-bottom: 20px;
      }

      @media (max-width: 767px) {
        width: 100%;
        height: 50px;
        font-size: 1.6rem;
        line-height: 50px;
      }
    }

    &__link{
      li{
        width: calc(50% - 10px);
        display: inline-block;
        vertical-align: top;

        @media (max-width: 767px) {
          width: 100%;
        }

        &:nth-child(1){
          margin-right: 20px;

          @media (max-width: 767px) {
            margin-right: 0;
            margin-bottom: 10px;
          }

          a{
            padding: 50px 40px 50px 90px;
            text-align: right;

            @media (max-width: 991px) {
              padding: 25px 20px 25px 45px;
            }

            &:before{
              content: '';
              width: 13px;
              height: 13px;
              border-top: 2px solid #999;
              border-left: 2px solid #999;
              display: inline-block;
              @include middle(40px);
              transform: translateY(-50%) rotate(-45deg);

              @media (max-width: 991px) {
                left: 20px;
              }
            }
          }
        }

        &:nth-child(2){
          a{
            padding: 50px 90px 50px 40px;

            @media (max-width: 991px) {
              padding: 25px 45px 25px 20px;
            }

            &:before{
              content: '';
              width: 13px;
              height: 13px;
              border-top: 2px solid #999;
              border-left: 2px solid #999;
              display: inline-block;
              @include middle(auto, 40px);
              transform: translateY(-50%) rotate(135deg);

              @media (max-width: 991px) {
                right: 20px;
              }
            }
          }
        }

        a{
          width: 100%;
          border: 1px solid #ddd;
          position: relative;

          &:hover{
            border: 1px solid #003554;
            background-color: #003554;
            transition-duration: .3s;

            &:before{
              border-top: 2px solid #fff;
              border-left: 2px solid #fff;
            }

            .view-bottom__link__date{
              color: #fff;
            }

            .view-bottom__link__tit{
              color: #fff;
            }
          }
        }
      }

      &__date{
        margin-bottom: 10px;
        @include font(1.4rem, 500, #666);
      }

      &__tit{
        @include font(1.8rem, 500, #666);
        @include line-1;
      }
    }
  }
}


.poomsae-view{
  &-cont{
    &__img{
      width: calc(50% - 30px);
      height: 490px;
      margin-right: 60px;
      border: 1px solid #ddd;
      display: inline-block;
      background-color: #f9f9f9;
      vertical-align: top;
      position: relative;

      @media (max-width: 991px) {
        width: calc(50% - 15px);
        margin-right: 30px;
      }

      @media (max-width: 767px) {
        width: 100%;
        height: auto;
        margin-right: 0;
        padding-bottom: 100%;
      }

      img{
        @include poa-center;
        max-height: 90%;
      }
    }

    &__text{
      width: calc(50% - 30px);
      padding-bottom: 150px;
      display: inline-block;
      vertical-align: top;
      position: relative;

      @media (max-width: 991px) {
        width: calc(50% - 15px);
      }

      @media (max-width: 767px) {
        width: 100%;
        padding-top: 20px;
      }

      h4{
        margin-bottom: 20px;
        padding-bottom: 20px;
        border-bottom: 1px dashed #ddd;
        @include font(3rem, 700, #222);

        @media (max-width: 767px) {
          font-size: 2.2rem;
        }
      }

      p{
        @include font(1.8rem, 500, #666);
        line-height: 2.8rem;

        @media (max-width: 767px) {
          font-size: 1.6rem;
          line-height: 2.6rem;
        }
      }

      img{
        @include poa(auto, 0, 0, auto);
      }
    }
  }
}

.card-view-wrap{
  text-align: center;

  &__slide{
    width: 600px;
    margin: 0 auto;

    @media (max-width: 991px) {
      width: 450px;
    }

    @media (max-width: 767px) {
      width: 100%;
    }

    div{
      span{
        width: 100%;
        padding-bottom: 100%;
        display: inline-block;
        position: relative;

        img{
          max-width: none;
          height: 100%!important;
          @include poa(0, auto, auto, 0);
        }
      }
    }
  }

  .slick-prev{
    width: 60px;
    height: 60px;
    border: 2px solid #000;
    border-radius: 60px;
    background-color: #fff;
    z-index: 2;
    left: -100px;

    @media (max-width: 767px) {
      display: none!important;
    }

    &:before{
      content: '';
      width: 17px;
      height: 17px;
      border-top: 2px solid #000;
      border-right: 2px solid #000;
      @include poa-center;
      transform: translate(-30%, -50%) rotate(-135deg);
    }

    &:hover{
      border: 2px solid #9c7e29;
      background-color: #9c7e29;

      &:before{
        border-top: 2px solid #fff;
        border-right: 2px solid #fff;
      }
    }
  }

  .slick-next{
    width: 60px;
    height: 60px;
    border: 2px solid #000;
    border-radius: 60px;
    background-color: #fff;
    z-index: 2;
    right: -100px;

    @media (max-width: 767px) {
      display: none!important;
    }

    &:before{
      content: '';
      width: 17px;
      height: 17px;
      border-top: 2px solid #000;
      border-right: 2px solid #000;
      @include poa-center;
      transform: translate(-70%, -50%) rotate(45deg);
    }

    &:hover{
      border: 2px solid #9c7e29;
      background-color: #9c7e29;

      &:before{
        border-top: 2px solid #fff;
        border-right: 2px solid #fff;
      }
    }
  }

  &__dots{
    .slick-dots{
      li{
        width: 40px;
        height: 40px;
        margin: 0;
        padding: 0;

        @media (max-width: 767px) {
          width: 30px;
          height: 30px;
        }

        &:before{
          display: none;
        }

        button{
          width: 100%;
          height: 100%;
          position: relative;

          span{
            width: 100%;
            text-align: center;
            @include font(1.4rem, 500, #999);
            @include middle;
          }
        }

        &.slick-active{
          background-color: #000;

          button{
            span{
              color: #fff;
            }
          }
        }
      }
    }
  }
}