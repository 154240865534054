.footer{
  padding: 90px 0;
  background-color: #162038;

  @media (max-width: 1250px) {
    padding: 50px 0;
  }

  @media (max-width: 767px) {
    padding: 30px 0;
  }

  &__top{
    padding-bottom: 45px;
    border-bottom: 1px solid rgba(255, 255, 255, .4);

    @media (max-width: 767px) {
      padding-bottom: 15px;
    }
  }

  &__logo{
    width: 170px;
    display: inline-block;
    vertical-align: middle;

    @media (max-width: 991px) {
      width: 100%;
      margin-bottom: 15px;
    }
  }

  &__info{
    width: calc(100% - 277px);
    padding-left: 60px;
    padding-right: 10px;
    display: inline-block;
    vertical-align: middle;

    @media (max-width: 991px) {
      width: 100%;
      padding: 0;
    }

    li{
      margin-right: 45px;
      display: inline-block;
      @include font(1.6rem, 500, #aeb4c6);
      vertical-align: middle;

      @media (max-width: 1520px) {
        margin-right: 25px;
      }

      @media (max-width: 991px) {
        margin-right: 15px;
        font-size: 1.4rem;
      }

      &.address{
        width: 100%;
        margin: 0 0 15px 0;
      }
    }
  }

  &__mark{
    width: 107px;
    display: inline-block;
    vertical-align: middle;

    @media (max-width: 767px) {
      width: 100%;
      margin-top: 5px;
      text-align: right;
    }
  }

  &__bottom{
    padding-top: 50px;

    @media (max-width: 767px) {
      padding-top: 15px;
    }

    p{
      width: 350px;
      display: inline-block;
      @include font(1.4rem, 500, #aeb4c6);
      vertical-align: middle;

      @media (max-width: 1250px) {
        width: 100%;
      }

      @media (max-width: 991px) {
        font-size: 1.2rem;
      }
    }
  }

  &__link{
    width: calc(100% - 350px);
    display: inline-block;
    text-align: right;
    vertical-align: middle;

    @media (max-width: 1250px) {
      width: 100%;
      margin-top: 10px;
    }

    li{
      display: inline-block;
      vertical-align: middle;
      text-align: left;

      &:after{
        content: '';
        width: 1px;
        height: 14px;
        margin: 0 25px;
        display: inline-block;
        background-color: rgba(255, 255, 255, .4);
        vertical-align: middle;
        position: relative;
        bottom: 5px;

        @media (max-width: 1520px) {
          margin: 0 10px;
        }
      }

      &:last-child{
        &:after{
          display: none;
        }
      }
    }

    a{
      @include font(1.6rem, 500, #aeb4c6);

      @media (max-width: 991px) {
        font-size: 1.2rem;
      }

      &.c-w{
        color: #fff;
      }
    }
  }
}