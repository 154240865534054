main{
  @media (max-width: 991px) {
    transform: none!important;
  }
}
.main{
  // overflow: hidden;//임시

  @media (max-width: 991px) {
    overflow: auto;
  }

  .section{
    // min-height: 100vh;//임시
    overflow: hidden;
    position: relative;

    @media (max-width: 991px) {
      height: auto!important;
      min-height: auto;//임시
    }

    &.ha{
      height: auto!important;
      min-height: auto;
    }

    article{
      width: calc(100% - 100px);
      height: 100%;//임시
      margin-left: 100px;

      @media (max-width: 767px) {
        width: 100%;
        margin-left: 0;
      }

      &.intro{
        width: 100%;
        height: 100vh;//임시
        margin-left: 0;

        @media (max-width: 767px) {
          padding-bottom: 30px;
          height: auto;//임시
        }
      }
    }

    &0{
      @media (max-width: 991px) {
        height: 100vh!important;
      }

      @media (max-width: 767px) {
        height: auto!important;
      }
    }

    &1{
      height: 100vh;//임시

      article{
        &:nth-child(2){
          // height: calc(100% - 25px);//임시
          height: 100%;
        }
      }
    }

    &2{
      background-color: #fbf9f2;

      @media (max-width: 991px) {
        padding-bottom: 60px;
      }
    }
  }

  &-banner{
    width: 100%;
    height: 100%;
    position: relative;

    &__slide{
      height: 100%;

      .slick-list{
        height: 100%;
      }

      .slick-track{
        height: 100%;
      }
    }

    .item{
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: left;
      background-repeat: no-repeat;
      overflow: hidden;

      @media (max-width: 991px) {
        background-image: none!important;
      }

      &:focus{
        outline: none;
        border: 2px dashed #9c7e29;
      }

      img{
        width: 100%;
        display: none;

        @media (max-width: 991px) {
          display: block;
        }
      }
    }

    &__tools{
      width: 100%;
      padding: 0 25px 0 200px;
      @include poa(auto, 0, 160px, auto);

      @media (max-width: 1150px) {
        padding-left: 100px;
      }

      @media (max-width: 991px) {
        padding-left: 40px;
        bottom: 20px;
      }

      @media (max-width: 767px) {
        display: none;
      }

      &__paging{
        margin-right: 20px;
        display: inline-block;
        vertical-align: middle;

        p{
          display: inline-block;
          @include font(1.8rem, 700, #bcbcbc);
          vertical-align: middle;

          &.current-num{
            color: #fff;
          }
        }
      }

      a{
        margin-right: 30px;
        font-size: 0;
        vertical-align: middle;
        position: relative;

        &:last-child{
          margin-right: 0;
        }
      }

      &__prev{
        padding: 5px 0;
        position: relative;

        &:before{
          content: '';
          width: 44px;
          height: 2px;
          background-color: #bcbcbc;
          display: inline-block;
          box-sizing: border-box;
        }

        &:after{
          content: '';
          width: 8px;
          height: 8px;
          border-top: 2px solid #bcbcbc;
          border-left: 2px solid #bcbcbc;
          display: inline-block;
          box-sizing: border-box;
          @include middle;
          transform: translateY(-50%) rotate(-45deg);
        }
      }

      &__pause{
        &:before{
          content: '';
          width: 12px;
          height: 12px;
          border-left: 4px solid #bcbcbc;
          border-right: 4px solid #bcbcbc;
          display: inline-block;
          box-sizing: border-box;
        }
      }

      &__play{
        display: none;

        &:before{
          content: '';
          border-left: 10px solid #bcbcbc;
          border-top: 6px solid transparent;
          border-bottom: 6px solid transparent;
          border-right: none;
          display: inline-block;
        }
      }

      &__next{
        padding: 5px 0;
        position: relative;

        &:before{
          content: '';
          width: 44px;
          height: 2px;
          background-color: #bcbcbc;
          display: inline-block;
          box-sizing: border-box;
        }

        &:after{
          content: '';
          width: 8px;
          height: 8px;
          border-top: 2px solid #bcbcbc;
          border-left: 2px solid #bcbcbc;
          display: inline-block;
          box-sizing: border-box;
          @include middle(auto, 0);
          transform: translateY(-50%) rotate(135deg);
        }
      }
    }
  }

  &-tit{
    h3{
      @include font(6rem, 700, #222);

      @media (max-width: 1520px) {
        font-size: 5.2rem;
      }

      @media (max-width: 1350px) {
        font-size: 4.6rem;
      }

      @media (max-width: 1250px) {
        font-size: 4rem;
      }

      @media (max-width: 991px) {
        font-size: 3.6rem;
      }

      @media (max-height: 860px) {
        font-size: 4rem;
      }

      @media (max-width: 767px) {
        font-size: 2.6rem;
      }
    }

    p{
      margin-top: 10px;
      @include font(1.6rem, 500, #666);

      @media (max-width: 991px) {
        font-size: 1.4rem;
      }
    }
  }

  &-notice{
    padding-top: 170px;

    @media (max-width: 991px) {
      padding-top: 60px;
    }

    &__tab{
      text-align: right;

      @media (max-width: 1250px) {
        margin-bottom: 15px;
      }

      @media (max-width: 991px) {
        margin-top: 30px;
      }

      @media (max-width: 767px) {
        text-align: left;
      }

      a{
        margin-left: 60px;
        padding-bottom: 5px;
        @include font(2rem, 700, #666);
        text-align: center;
        position: relative;

        @media (max-width: 1350px) {
          margin-left: 30px;
        }

        @media (max-width: 767px) {
          width: calc(100% / 3);
          margin-left: 0;
          margin-bottom: 5px;
          font-size: 1.8rem;
        }

        &:first-child{
          margin-left: 0;
        }

        &:after{
          content: '';
          width: 5px;
          height: 5px;
          display: none;
          background-color: #003554;
          @include center(auto, 0);
        }

        &.selected{
          color: #003554;

          &:after{
            display: inline-block;
          }
        }
      }
    }

    &__list{
      position: relative;

      &__tit{
        width: 460px;
        height: 520px;
        padding: 100px 70px;
        display: inline-block;
        background-image: url('/template/resources/images/cont/main-notice-bg.png');
        background-size: cover;
        vertical-align: middle;

        @media (max-width: 1350px) {
          width: 400px;
          padding: 50px 40px;
        }

        @media (max-height: 860px) {
          height: 460px;
        }

        @media (max-height: 800px) {
          height: 400px;
        }

        @media (max-width: 991px) {
          width: 300px;
          height: 400px;
        }

        @media (max-width: 767px) {
          width: calc(100% + 30px);
          height: 520px;
          padding: 50px 15px;
          position: relative;
          left: -15px;
        }

        h3{
          margin-bottom: 20px;
          @include font(4.6rem, 500, #fff);

          @media (max-width: 1250px) {
            font-size: 3.6rem;
          }

          @media (max-width: 991px) {
            font-size: 3rem;
          }

          @media (max-width: 767px) {
            width: calc(100% - 93px);
            margin-bottom: 0;
            font-size: 2.6rem;
            display: inline-block;
          }
        }

        > a{
          padding-right: 30px;
          @include font(1.8rem, 500, #fff);
          line-height: 1;
          position: relative;

          @media (max-width: 991px) {
            font-size: 1.6rem;
          }

          @media (max-width: 767px) {
            padding-right: 15px;
            font-size: 1.4rem;
          }

          &:before{
            content: '';
            width: 14px;
            height: 2px;
            display: inline-block;
            background-color: #fff;
            @include middle(auto, 0);
            top: 53%;

            @media (max-width: 991px) {
              width: 12px;
            }

            @media (max-width: 767px) {
              width: 10px;
            }
          }

          &:after{
            content: '';
            width: 2px;
            height: 14px;
            display: inline-block;
            background-color: #fff;
            @include middle(auto, 6px);
            top: 53%;

            @media (max-width: 991px) {
              height: 12px;
              right: 5px;
            }

            @media (max-width: 767px) {
              height: 10px;
              right: 4px;
            }
          }
        }
      }

      &__progress{
        width: 250px;
        height: 3px;
        margin-top: 140px;
        background-color: rgba(255, 255, 255, .4);
        position: relative;

        @media (max-width: 991px) {
          width: 150px;
          margin-top: 80px;
        }

        @media (max-height: 800px) {
          margin-top: 80px;
        }

        @media (max-width: 767px) {
          width: 100%;
          margin-top: 320px;
        }

        span{
          width: 100px;
          height: 3px;
          background-color: #fff;
          @include poa(0, auto, auto, 0);
          transition-duration: .3s;
        }

        .slick-dots{
          width: 100%;

          li{
            height: 3px;
            margin: 0;
            padding: 0;
            cursor: revert;
            background-color: transparent;
          }

          button{
            display: none;
          }
        }
      }

      &__tools{
        margin-top: 30px;

        @media (max-width: 767px) {
          margin-top: 15px;
        }

        a{
          width: 50px;
          height: 50px;
          margin-right: 10px;
          border-radius: 50px;
          border: 2px solid #fff;
          vertical-align: middle;
          position: relative;

          &:last-child{
            margin-right: 0;
          }

          &:before{
            content: '';
            width: 13px;
            height: 13px;
            border-top: 2px solid #fff;
            border-left: 2px solid #fff;
            display: inline-block;
            box-sizing: border-box;
          }

          &:hover{
            background-color: #fff;

            &:before{
              border-top: 2px solid #222;
              border-left: 2px solid #222;
              transition-duration: .3s;
            }
          }

          &.main-notice__list__tools__pause{
            &:before{
              width: 8px;
              border-top: none;
              border-right: 2px solid #fff;
              @include poa-center;
            }

            &:hover{
              &:before{
                border-top: none;
                border-right: 2px solid #000;
              }
            }
          }

          &.main-notice__list__tools__play{
            display: none;

            &:before{
              width: 7px;
              height: 14px;
              border-left: 7px solid #fff;
              border-top: 7px solid transparent;
              border-bottom: 7px solid transparent;
              border-right: none;
              box-sizing: border-box;
              @include poa-center;
            }

            &:hover{
              &:before{
                border-left: 7px solid #000;
              }
            }
          }
        }

        &__prev{
          &:before{
            @include poa-center;
            transform: translate(-30%, -50%) rotate(-45deg);
          }
        }

        &__next{
          &:before {
              @include poa-center;
              transform: translate(-70%, -50%) rotate(135deg);
            }
        }
      }

      &__wrap{
        width: calc((100% - 460px) + 175px);
        margin-left: -75px;
        @include middle(auto, -100px);

        @media (max-width: 1350px) {
          width: calc((100% - 400px) + 175px);
        }

        @media (max-width: 991px) {
          width: calc((100% - 300px) + 175px);
        }

        @media (max-width: 767px) {
          width: 100%;
          margin-left: 0;
          @include middle(0);
        }

        .item{
          width: 380px;
          height: 380px;
          margin-right: 40px;
          padding: 55px 45px;
          border: 5px solid #fff;
          background-color: #fff;
          word-break: break-all;
          position: relative;

          @media (max-height: 800px) {
            width: 300px;
            height: 300px;
            padding: 45px;
          }

          @media (max-width: 991px) {
            width: 300px;
            height: 300px;
            padding: 30px;
          }

          @media (max-width: 767px) {
            width: 250px;
            height: 250px;
            padding: 20px;
          }

          &:hover{
            border: 5px solid #9c7e29;

            a{
              .link-arrow {
                background-color: #9c7e29;

                img {
                  &:first-child {
                    display: none;
                  }

                  &:last-child {
                    display: inline-block;
                  }
                }
              }
            }
          }

          h3{
            margin-bottom: 20px;
            @include font(2.4rem, 700, #222);
            @include line-clamp-ha(13.6rem ,3.4rem ,4);

            @media (max-height: 800px) {
              margin-bottom: 10px;
              @include line-clamp-ha(10.2rem, 3.4rem, 3);
            }

            @media (max-width: 767px) {
              font-size: 2.2rem;
              @include line-clamp-ha(9.6rem, 3.2rem, 3);
            }
          }

          p{
            @include font(1.8rem, 500, #666);
          }

          a{
            width: calc(100% - 90px);
            @include poa(auto, auto, 55px, 45px);

            @media (max-height: 800px) {
              width: calc(100% - 90px);
              left: 45px;
            }

            @media (max-width: 991px) {
              width: calc(100% - 60px);
              bottom: 20px;
              left: 30px;
            }

            @media (max-width: 767px) {
              width: calc(100% - 40px);
              left: 20px;
            }

            &:after{
              content: '';
              display: block;
              clear: both;
            }

            p{
              margin-top: 12px;
              border-bottom: 1px solid #000;
              display: inline-block;
              @include font(1.8rem, 500, #222);
              vertical-align: middle;
            }

            .link-arrow{
              width: 50px;
              height: 50px;
              border-radius: 50px;
              display: inline-block;
              vertical-align: middle;
              position: relative;
              float: right;

              img{
                @include poa-center;
                transform: translate(-50%, -70%);

                &:last-child{
                  display: none;
                }
              }
            }
          }
        }
      }

      &__slide{
        display: none;

        &.visible {
          display: block;
        }
      }
    }
  }

  &-sns{
    height: auto!important;
    padding-top: 170px;

    @media (max-height: 800px) {
      padding-top: 130px;
    }

    @media (max-width: 991px) {
      padding-top: 60px;
    }

    &__left{
      width: calc(100% - 1080px);
      padding-right: 10px;
      display: inline-block;
      vertical-align: middle;

      @media (max-width: 1650px) {
        width: calc(100% - 800px);
      }

      @media (max-width: 1410px) {
        width: calc(100% - 700px);
      }

      @media (max-height: 860px) {
        width: calc(100% - 700px);
      }

      @media (max-width: 1250px) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 15px;
        padding-right: 0;

        .main-tit{
          width: 50%;
          display: inline-block;
          vertical-align: middle;
        }
      }

      @media (max-height: 740px) {
        margin-bottom: 0;
      }

      @media (max-width: 767px) {
        .main-tit{
          width: 100%;
        }
      }

      &__link{
        margin-top: 60px;

        @media (max-width: 1250px) {
          width: 50%;
          margin-top: 0;
          display: inline-block;
          vertical-align: middle;
          text-align: right;
        }

        @media (max-width: 767px) {
          width: 100%;
          margin-top: 10px;
        }

        a{
          margin-right: 10px;
          font-size: 0;
          display: inline-block;
          vertical-align: middle;

          @media (max-width: 1350px) {
            margin-right: 5px;
          }

          &:last-child{
            margin-right: 0;
          }
        }
      }
    }

    &__tools{
      margin-top: 70px;

      @media (max-width: 1260px) {
        margin-top: 30px;
      }

      @media (max-height: 800px) {
        margin-top: 15px;
      }

      @media (max-width: 767px) {
        margin-top: 15px;
      }

      a{
        width: 50px;
        height: 50px;
        margin-right: 10px;
        border-radius: 50px;
        border: 2px solid #ddd;
        vertical-align: middle;
        position: relative;

        &:last-child{
          margin-right: 0;
        }

        &:before{
          content: '';
          width: 13px;
          height: 13px;
          border-top: 2px solid #ddd;
          border-left: 2px solid #ddd;
          display: inline-block;
          box-sizing: border-box;
        }

        &:hover{
          background-color: #ddd;

          &:before{
            border-top: 2px solid #fff;
            border-left: 2px solid #fff;
            transition-duration: .3s;
          }
        }

        &.main-sns__tools__pause{
          &:before{
            width: 8px;
            border-top: none;
            border-right: 2px solid #ddd;
            @include poa-center;
          }

          &:hover{
            &:before{
              border-top: none;
              border-right: 2px solid #fff;
            }
          }
        }

        &.main-sns__tools__play{
          display: none;

          &:before{
            width: 7px;
            height: 14px;
            border-left: 7px solid #ddd;
            border-top: 7px solid transparent;
            border-bottom: 7px solid transparent;
            border-right: none;
            box-sizing: border-box;
            @include poa-center;
          }

          &:hover{
            &:before{
              border-left: 7px solid #fff;
            }
          }
        }
      }

      &__prev{
        &:before{
          @include poa-center;
          transform: translate(-30%, -50%) rotate(-45deg);
        }
      }

      &__next{
        &:before {
          @include poa-center;
          transform: translate(-70%, -50%) rotate(135deg);
        }
      }
    }

    &__right{
      width: 1080px;
      display: inline-block;
      vertical-align: middle;

      @media (max-width: 1650px) {
        width: 800px;
      }

      @media (max-width: 1410px) {
        width: 700px;
      }

      @media (max-height: 860px) {
        width: 700px;
      }

      @media (max-width: 1250px) {
        width: 100%;
      }

      // li{
      //   width: calc((100% - 120px) / 3);
      //   margin-right: 60px;
      //   display: inline-block;
      //   vertical-align: top;
      //   box-shadow: 0 0 10px 5px rgba(0, 0, 0, .1);

      //   @media (max-width: 1650px) {
      //     width: calc((100% - 60px) / 3);
      //     margin-right: 30px;
      //   }

      //   @media (max-width: 1250px) {
      //     width: calc((100% - 30px) / 3);
      //     margin-right: 15px;
      //   }

      //   @media (max-height: 860px) {
      //     width: calc((100% - 30px) / 3);
      //     margin-right: 15px;
      //   }

      //   @media (max-width: 767px) {
      //     width: 100%;
      //     margin-right: 0;
      //     margin-bottom: 15px;
      //   }

      //   &:last-child{
      //     margin-right: 0;
      //   }

      //   a{
      //     width: 100%;

      //     &:hover{
      //       outline: 5px solid #9c7e29;
      //     }
      //   }
      // }

      .item{
        margin: 0 30px;
        padding: 5px;

        @media (max-width: 1650px) {
          margin: 0 15px;
        }

        @media (max-width: 1250px) {
          margin: 0 calc(15px / 2);
        }

        @media (max-width: 767px) {
          margin: 0;
        }

        a{
          width: 100%;

          &:hover{
            outline: 5px solid #9c7e29;
          }
        }
      }

      &__img{
        padding-bottom: 100%;
        position: relative;
        overflow: hidden;

        img{
          // width: 100%;
          // @include poa(0, auto, auto, 0);
          max-width: none;
          height: 100%;
          @include center;
        }
      }

      &__text{
        padding: 30px 25px;

        @media (max-width: 1650px) {
          padding: 20px 15px;
        }

        @media (max-width: 1250px) {
          padding: 15px;
        }

        h3{
          margin-bottom: 10px;
          @include font(2.4rem, 700, #222);
          @include line-1;

          @media (max-width: 1650px) {
            font-size: 2.2rem;
          }

          @media (max-width: 1250px) {
            font-size: 1.8rem;
          }
        }

        p{
          padding-left: 30px;
          @include font(1.8rem, 500, #666);
          position: relative;

          @media (max-width: 1250px) {
            font-size: 1.6rem;
          }

          img{
            @include middle;
          }
        }
      }
    }

    &__slide{
      padding: 5px;
    }
  }

  &-information{
    margin-top: 80px;

    @media (max-width: 1250px) {
      margin-top: 20px;
    }

    @media (max-height: 900px) {
      margin-top: 20px;
    }

    .wrap{
      >div{
        padding: 43px 0;
        background-image: url('/template/resources/images/cont/information-bg.png');
        background-size: cover;
        background-position: center;
      }
    }

    &__left{
      width: 430px;
      display: inline-block;
      vertical-align: middle;

      @media (max-width: 991px) {
        width: 100%;
        text-align: center;
      }

      @media (max-width: 767px) {
        padding: 0 10px;
      }
    }

    &__right{
      width: calc(100% - 430px);
      display: inline-block;
      vertical-align: middle;
      text-align: right;

      @media (max-width: 991px) {
        width: 100%;
        margin-top: 30px;
        text-align: center;
      }

      li{
        padding: 0 100px;
        display: inline-block;
        vertical-align: middle;
        position: relative;
        text-align: center;

        @media (max-width: 1650px) {
          padding: 0 50px;
        }

        @media (max-width: 1350px) {
          padding: 0 30px;
        }

        @media (max-width: 1150px) {
          padding: 0 15px;
        }

        @media (max-width: 991px) {
          padding: 0 30px;
        }

        @media (max-width: 767px) {
          width: 50%;
          padding: 0 10px;
        }

        &:first-child{
          &:after{
            content: '';
            width: 2px;
            height: 50px;
            display: inline-block;
            background-color: #ddd;
            @include middle(auto, 0);
          }
        }
      }

      h4{
        margin-bottom: 20px;
        @include font(1.8rem, 500, #9c7e29);

        @media (max-width: 1150px) {
          margin-bottom: 10px;
        }

        @media (max-width: 991px) {
          margin-bottom: 0;
        }

        @media (max-width: 767px) {
          font-size: 1.6rem;
        }
      }

      p{
        @include font(4.8rem, 700, #222);

        @media (max-width: 1450px) {
          font-size: 3.8rem;
        }

        @media (max-width: 1150px) {
          font-size: 3rem;
        }

        @media (max-width: 767px) {
          font-size: 2.2rem;
        }
      }
    }
  }

  &-quick{
    padding-top: 100px;

    @media (max-width: 991px) {
      padding-top: 60px;
    }

    @media (max-width: 767px) {
      padding-top: 30px;
    }

    &__left{
      width: calc(100% - 720px);
      display: inline-block;
      vertical-align: top;

      @media (max-width: 1520px) {
        width: calc(100% - 600px);
        padding-right: 20px;
      }

      @media (max-width: 1150px) {
        width: calc(100% - 450px);
      }

      @media (max-width: 991px) {
        width: 100%;
        padding-right: 0;
        margin-bottom: 30px;
      }

      &__link{
        margin-top: 60px;

        @media (max-width: 1520px) {
          margin-top: 30px;
        }

        @media (max-width: 1410px) {
          margin-top: 15px;
        }

        a{
          margin-right: 80px;
          vertical-align: top;

          @media (max-width: 1780px) {
            margin-right: 20px;
          }

          @media (max-width: 1650px) {
            width: calc(100% / 4);
            padding: 0 5px;
            margin-right: 0;
          }

          @media (max-width: 767px) {
            width: 50%;
          }

          &:last-child{
            margin-right: 0;
          }
        }

        br{
          display: none;

          @media (max-width: 991px) {
            display: none;
          }
        }

        &__img{
          width: 120px;
          height: 120px;
          margin: 0 auto;
          border-radius: 120px;
          background-color: #f5f5f5;
          position: relative;

          @media (max-width: 1750px) {
            width: 100px;
            height: 100px;
          }

          @media (max-width: 1650px) {
            width: 100%;
            height: auto;
            padding-bottom: 100%;
          }

          @media (max-width: 767px) {
            border-radius: 300px;
          }

          img{
            @include poa-center;

            @media (max-width: 1520px) {
              max-width: 80%;
            }
          }
        }

        &__text{
          margin-top: 30px;

          @media (max-width: 1750px) {
            margin-top: 15px;
          }

          @media (max-width: 1410px) {
            margin-top: 5px;
          }

          @media (max-height: 920px) {
            margin-top: 15px;
          }

          @media (max-height: 860px) {
            margin-top: 5px;
          }

          @media (max-width: 991px) {
            margin-top: 10px;
          }

          p{
            @include font(1.8rem, 500, #666);
            text-align: center;
            word-break: break-all;

            @media (max-width: 1750px) {
              font-size: 1.6rem;
            }

            @media (max-height: 800px) {
              font-size: 1.6rem;
            }

            @media (max-width: 1650px) {
              font-size: 1.4rem;
            }

            @media (max-width: 1150px) {
              font-size: 1.2rem;
            }

            @media (max-width: 991px) {
              font-size: 1.6rem;
            }
          }
        }
      }
    }

    &__right{
      width: 720px;
      display: inline-block;
      vertical-align: top;
      text-align: right;

      @media (max-width: 1520px) {
        width: 600px;
      }

      @media (max-width: 1150px) {
        width: 450px;
      }

      @media (max-width: 991px) {
        width: 100%;
      }

      a{
        display: inline-block;
        font-size: 0;
        vertical-align: top;
        box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, .1);
        position: relative;
        z-index: 1;

        @media (max-width: 1520px) {
          width: calc(50% - 20px);
        }

        @media (max-width: 1410px) {
          width: calc(50% - 10px);
        }

        @media (max-width: 767px) {
          width: calc((100% - 15px) / 2);
        }

        &:first-child{
          margin-right: 40px;

          @media (max-width: 1410px) {
            margin-right: 20px;
          }

          @media (max-width: 767px) {
            margin-right: 15px;
          }
        }

        img{
          height: auto;
          @media (max-height: 920px) {
            max-height: 550px;
          }

          @media (max-height: 860px) {
            max-height: 500px;
          }

          @media (max-height: 800px) {
            max-height: 420px;
          }

          @media (max-width: 991px) {
            width: 100%;
            max-height: none;
          }
        }
      }
    }

    &__bottom{
      width: 100%;
      margin-top: 30px;//임시
      // @include poa(auto, auto, 0, 0);//임시

      @media (max-width: 991px) {
        margin-top: 15px;
        @include po-cancel;
      }

      &__link{
        // padding: 75px 100px 75px 200px;//임시
        padding: 75px 100px;//임시
        background-image: url('/template/resources/images/cont/main-quick-bg.png');
        background-size: cover;
        background-position: center;

        @media (max-width: 1650px) {//임시
          // padding: 38px 100px 38px 200px;//임시
          padding: 38px 100px 38px 100px;
        }

        // @media (max-width: 1410px) {//임시
        //   padding: 30px 100px 30px 200px;
        // }

        // @media (max-width: 1250px) {//임시
        //   padding: 30px 50px 30px 150px;
        // }

        // @media (max-height: 860px) {//임시
        //   padding: 30px 100px 30px 150px;
        // }

        @media (max-width: 991px) {
          padding: 30px;
        }

        @media (max-width: 767px) {
          padding: 30px 15px;
        }


        &__wrap{
          // width: 720px;//임시
          width: 100%;//임시

          // @media (max-width: 1750px) { //임시
          //   width: calc(100% - 820px);
          // }

          // @media (max-width: 1520px) {
          //   width: calc(100% - 600px);
          //   padding-right: 20px;
          // }
          
          @media (max-width: 1150px) {
            width: 100%;
          }

          @media (max-height: 830px) {
            width: calc(100% - 400px);
          }
          
          @media (max-width: 991px) {
            width: 100%;
            padding-right: 0;
          }


          a{
            // width: calc((100% - 30px) / 3);//임시
            width: calc((100% - 75px) / 6);//임시
            height: 58px;
            margin-right: 15px;
            margin-bottom: 15px;
            padding: 17px 20px;
            border: 1px solid rgba(255, 255, 255, .6);
            display: inline-block;
            @include font(1.8rem, 500, #fff);
            vertical-align: top;
            position: relative;

            @media (max-width: 1750px) {
              padding: 17px 15px;
              font-size: 1.6rem;
            }

            @media (max-width: 1650px) {
              // width: calc((100% - 15px) / 2);//임시
              width: calc((100% - 30px) / 3);
            }

            @media (max-width: 1410px) {
              height: 48px;
              padding: 12px 20px;
            }

            @media (max-width: 1350px) {
              padding: 14px 15px;
              font-size: 1.4rem;
            }

            // @media (max-width: 1150px) and (min-height: 831px) {//임시
            //   width: calc((100% - 30px) / 3);
            // }

            @media (max-width: 767px) {
              width: 100%;
              margin-right: 0;
            }

            &:hover{
              background-color: rgba(156, 126, 41, .4);
            }

            &:nth-child(3n){
              // margin-right: 0;//임시

              @media (max-width: 1650px) {
                // margin-right: 15px;//임시
                margin-right: 0;//임시
              }

              @media (max-width: 1150px) and (min-height: 831px) {
                margin-right: 0;
              }
            }

            &:nth-child(2n){
              // @media (max-width: 1650px) {//임시
              //   margin-right: 0;
              // }

              @media (max-width: 1150px) and (min-height: 831px) {
                margin-right: 15px;
              }

              @media (max-width: 767px) {
                margin-right: 0;
              }
            }

            &:nth-child(6n){
              margin-right: 0;//임시

              @media (max-width: 1150px) {
                margin-right: 0;
              }
            }

            &:nth-child(n+4){
              margin-bottom: 0;

              @media (max-width: 767px) {
                margin-bottom: 15px;
              }
            }

            &:last-child{
              @media (max-width: 767px) {
                margin-bottom: 0;
              }
            }

            img{
              @include middle(auto, 20px);

              @media (max-width: 1750px) {
                right: 15px;
              }
            }
          }
        }
      }

      &__site{
        height: 105px;
        padding: 0 100px 0 200px;

        @media (max-width: 1250px) {
          padding: 0 50px 0 150px;
        }

        @media (max-width: 991px) {
          height: auto;
          padding: 20px 0;
        }

        h4{
          width: 170px;
          @include font(2.4rem, 700, #222);
          line-height: 105px;
          display: inline-block;
          vertical-align: middle;

          @media (max-width: 991px) {
            width: 120px;
            line-height: 1;
            font-size: 2rem;
          }

          @media (max-width: 767px) {
            width: 100%;
          }
        }

        &__slide{
          width: calc(100% - 340px);
          padding-right: 40px;
          display: inline-block;
          vertical-align: middle;

          @media (max-width: 991px) {
            width: calc(100% - 230px);
            padding-right: 15px;
          }

          @media (max-width: 767px) {
            width: 100%;
          }

          .item{
            // margin: 0 50px;
            margin-right: 3px;//임시
          }

          .slick-list{
            padding: 3px;
          }

          a{
            font-size: 0;
          }
        }

        &__tools{
          width: 170px;
          display: inline-block;
          vertical-align: middle;

          @media (max-width: 991px) {
            width: 110px;
          }

          @media (max-width: 767px) {
            display: none;
          }

          a{
            width: 50px;
            height: 50px;
            margin-right: 10px;
            border: 2px solid #ddd;
            border-radius: 50px;
            position: relative;

            @media (max-width: 991px) {
              width: 30px;
              height: 30px;
            }

            &:last-child{
              margin-right: 0;
            }

            &:before{
              content: '';
              display: inline-block;
              box-sizing: border-box;
            }
          }
        }

        &__prev{
          &:before{
            width: 12px;
            height: 12px;
            border-top: 2px solid #999;
            border-left: 2px solid #999;
            @include poa-center;
            transform: translate(-30%, -50%) rotate(-45deg);

            @media (max-width: 991px) {
              width: 8px;
              height: 8px;
            }
          }
        }

        &__next{
          &:before{
            width: 12px;
            height: 12px;
            border-top: 2px solid #999;
            border-left: 2px solid #999;
            @include poa-center;
            transform: translate(-70%, -50%) rotate(135deg);

            @media (max-width: 991px) {
              width: 8px;
              height: 8px;
            }
          }
        }

        &__pause{
          &:before{
            width: 10px;
            height: 17px;
            border-left: 2px solid #999;
            border-right: 2px solid #999;
            @include poa-center;

            @media (max-width: 991px) {
              width: 8px;
              height: 14px;
            }
          }
        }

        &__play{
          display: none;

          &:before{
            border-left: 10px solid #999;
            border-top: 6px solid transparent;
            border-bottom: 6px solid transparent;
            border-right: none;
            @include poa-center;
          }
        }
      }
    }
  }

  &__popup{
		width: 100%;
		height: 100vh;
		background-color: rgba(0, 0, 0, .6);
    @include pof(0, auto, auto, 0);
		z-index: 1002;

		.main-cont{
			width: 1200px;
			height: 100%;
      margin: 0 auto;
			position: relative;

			@media (max-width: 1220px) {
				width: 970px;
			}

			@media (max-width: 991px) {
				width: 750px;
			}

			@media (max-width: 767px) {
				width: 100%;
			}
		}

		&__wrap{
			width: 100%;
      @include middle;

			&__tools{
				width: calc(90% - 30px);
				margin: 0 auto 30px;
				text-align: right;

        &__paging{
          height: 45px;
          margin-right: 20px;
          padding: 0 20px;
          border-radius: 45px;
          display: inline-block;
          vertical-align: middle;
          background-color: rgba(0, 0, 0, .8);
        }

        p{
          display: inline-block;
          @include font(1.4rem, 500, #999);
          line-height: 45px;
          vertical-align: middle;

          &.main__popup__wrap__tools__current{
            color: #fff;
          }
        }

        a{
          vertical-align: middle;
        }

				a:first-of-type{
					margin-right: 15px;
				}
			}

			&__slide{
				width: 90%;
				margin: 0 auto;
			}
		}

		.item{
      max-height: 75vh;
			margin: 0 15px;
      overflow-y: auto;

			p{
				width: 100%;
			}

			a{
				width: 100%;
			}

      img{
				width: 100%!important;
				height: auto!important;
			}
		}

		&__btn{
			margin-top: 30px;
			text-align: center;
      white-space: nowrap;

      label{
        min-width: 170px;
        position: relative;
      }

      label:before{
        content: '';
        width: 12px;
        height: 12px;
        margin-right: 5px;
        display: inline-block;
        border: 1px solid #9c7e29;
        background-color: #fff;
        vertical-align: middle;
        position: relative;
        bottom: 1px;
      }

      input:checked + label:before{
        background-color: #9c7e29;
      }

			>*{
				height: 40px;
				margin: 0 10px;
				padding: 0 15px;
				border-radius: 40px;
				display: inline-block;
				background-color: rgba(0, 0, 0, .8);
				vertical-align: middle;
				font-size: 14px;
				font-weight: 500;
				line-height: 38px;
				color: #fff;
			}
		}
	}
}

.side-popup{
  @include poa(auto, -610px, 0, auto);
  // transform: translateY(-50%);
  z-index: -1;

  &.z-idx{
    z-index: 1;
  }

  @media (max-width: 767px) {
    width: calc(100% - 40px);
    right: calc(-100% + 40px);
  }

  &.visible{
    right: 0;
    transition-duration: .3s;
  }

  > a{
    width: 48px;
    height: 106px;
    background-color: #9c7e29;
    @include poa(0, auto, auto, -48px);

    @media (max-width: 991px) {
      width: 40px;
      left: -40px;
    }

    p{
      padding-right: 20px;
      @include font(1.4rem, 500, #fff);
      @include poa-center;
      transform: translate(-50%, -50%) rotate(-90deg);

      &:before{
        content: '';
        width: 8px;
        height: 8px;
        border-top: 2px solid #fff;
        border-left: 2px solid #fff;
        display: inline-block;
        @include poa(50%, 5px, auto, auto);
        transform: translateY(-10%) rotate(45deg);
        box-sizing: border-box;
      }
    }

    &.active{
      p{
        &:before{
          content: '';
          width: 1px;
          height: 13px;
          border-top: none;
          border-left: none;
          display: inline-block;
          background-color: #fff;
          @include poa(55%, 5px, auto, auto);
          transform: translateY(-50%) rotate(135deg);
        }
        
        &:after{
          content: '';
          width: 13px;
          height: 1px;
          display: inline-block;
          background-color: #fff;
          @include poa(55%, -1px, auto, auto);
          transform: translateY(-45%) rotate(-45deg);
        }
      }
    }
  }

  &__slide{
    // width: 100%;
    width: 610px;
    max-width: 610px;

    .item{
      width: 100%;

      img{
        width: 100%;
      }
    }
  }

  &__tools{
    width: 100%;
    padding: 0 25px;
    text-align: right;
    @include poa(auto, 0, 25px, auto);

    @media (max-width: 767px) {
      bottom: 10px;
    }

    &__paging{
      margin-right: 20px;
      display: inline-block;
      vertical-align: middle;

      p{
        display: inline-block;
        @include font(1.6rem, 700, #bcbcbc);
        vertical-align: middle;

        &.current-num{
          color: #fff;
        }
      }
    }

    a{
      width: 28px;
      height: 28px;
      margin-right: 5px;
      border-radius: 28px;
      background-color: rgba(0, 0, 0, .6);
      font-size: 0;
      vertical-align: middle;
      position: relative;

      &:last-child{
        margin-right: 0;
      }
    }

    &__prev{
      &:before{
        content: '';
        width: 8px;
        height: 8px;
        border-top: 2px solid #fff;
        border-left: 2px solid #fff;
        display: inline-block;
        box-sizing: border-box;
        @include poa-center;
        transform: translate(-30%, -50%) rotate(-45deg);
      }
    }

    &__pause{
      &:before{
        content: '';
        width: 8px;
        height: 10px;
        border-left: 2px solid #fff;
        border-right: 2px solid #fff;
        display: inline-block;
        box-sizing: border-box;
        @include poa-center;
      }
    }

    &__play{
      display: none;

      &:before{
        content: '';
        border-left: 8px solid #fff;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-right: none;
        display: inline-block;
        @include poa-center;
        transform: translate(-40%, -50%);
      }
    }

    &__next{
      &:before{
        content: '';
        width: 8px;
        height: 8px;
        border-top: 2px solid #fff;
        border-left: 2px solid #fff;
        display: inline-block;
        box-sizing: border-box;
        @include poa-center;
        transform: translate(-70%, -50%) rotate(135deg);
      }
    }
  }
}

.intro{
  height: 100%;
  background-image: url('/template/resources/images/cont/intro-bg.png');
  background-size: cover;
  position: relative;
  z-index: 5;

  // @media (max-width: 991px) {
  //   padding-left: 100px;
  // }

  // @media (max-width: 767px) {
  //   padding-left: 0;
  // }

  &-top{
    padding: 45px 0 110px;
    text-align: center;

    @media (max-height: 880px) {
      padding: 15px 0;
    }

    @media (max-width: 1450px) {
      padding: 45px 0 40px;
    }

    // @media (max-width: 991px) {
    //   padding: 120px 0 40px;
    // }

    @media (max-width: 767px) {
      padding: 60px 15px 40px;
    }

    img{
      margin: 0 auto;
      display: block;
    }

    h3{
      margin-top: 40px;
      display: inline-block;
      @include font(3rem, 500, #606060);
      position: relative;

      &:after{
        content: '';
        width: 100%;
        height: 16px;
        display: inline-block;
        background-color: #f0ebcb;
        @include poa(auto, auto, 0, 0);
        z-index: -1;

        @media (max-width: 767px) {
          bottom: -5px;
        }
      }

      @media (max-height: 880px) {
        font-size: 3rem;
      }

      @media (max-width: 767px) {
        font-size: 1.6rem;
      }
    }
  }

  &-list{
    width: 1240px;
    margin: 0 auto;

    @media (max-width: 1260px) {
      width: 850px;
    }

    @media (max-width: 991px) {
      width: 670px;
    }

    @media (max-width: 767px) {
      width: 100%;
      padding: 0 15px;
    }

    ul{
      @media (max-width: 991px) {
        text-align: center;
      }
    }

    li{
      width: calc((100% - 100px) / 5);
      height: 340px;
      margin: 0 10px;
      display: inline-block;
      vertical-align: top;

      @media (max-width: 1260px) {
        width: calc((100% - 50px) / 5);
        margin: 0 5px;
      }

      @media (max-width: 991px) {
        width: calc((100% - 30px) / 3);
        height: 220px;
        margin: 5px;
      }

      @media (max-width: 767px) {
        width: 100%;
        height: 180px;
        margin: 5px 0;
      }

      &.c1{
        a{
          background-image: url('/template/resources/images/cont/intro-list-bg1.png');

          &:hover{
            background-image: url('/template/resources/images/cont/intro-list-bg1_hover.png');
          }

          h4{
            color: #111;
          }

          p{
            color: #888;
          }
        }

        .intro-list__text{
          &:after{
            background-color: #888;
          }
        }
      }

      &.c2{
        a{
          background-image: url('/template/resources/images/cont/intro-list-bg2.png');

          &:hover{
            background-image: url('/template/resources/images/cont/intro-list-bg2_hover.png');
          }

          h4{
            color: #111;
          }

          p{
            color: #888;
          }
        }
      }

      &.c3{
        a{
          background-image: url('/template/resources/images/cont/intro-list-bg3.png');

          &:hover{
            background-image: url('/template/resources/images/cont/intro-list-bg3_hover.png');
          }
        }
      }

      &.c4{
        a{
          background-image: url('/template/resources/images/cont/intro-list-bg5.png');

          &:hover{
            background-image: url('/template/resources/images/cont/intro-list-bg4_hover.png');
          }
        }
      }

      &.c5{
        a{
          background-image: url('/template/resources/images/cont/intro-list-bg4.png');

          &:hover{
            background-image: url('/template/resources/images/cont/intro-list-bg5_hover.png');
          }
        }
      }
      
      a{
        width: 100%;
        height: 100%;
        border-radius: 10px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        overflow: hidden;
        position: relative;
        box-shadow: 3px 3px 10px 3px rgba(0, 0, 0, .05);

        &:hover{
          border: none;
          background-image: url('/template/resources/images/cont/intro-hover-bg.png');

          .intro-list__text{
            height: 93px;
            margin-top: 0;
            margin-bottom: 40px;
            padding-top: 0;

            @media (max-width: 991px) {
              height: 69px;
              margin-bottom: 15px;
            }

            &:after{
              display: none;
            }
          }

          > div{
            padding: 4px 15px;

            @media (max-width: 1450px) {
              padding: 0 14px;
            }

            @media (max-width: 767px) {
              padding: 15px 14px;
            }
          }

          img{
            &:first-of-type{
              display: none;
            }

            &:last-of-type{
              display: block;
            }
          }
        }

        > div {
          width: 100%;
          padding: 0 15px;
          display: inline-block;
          @include middle;

          @media (max-width: 1450px) {
            padding: 0 10px;
          }
        }
      }
    }

    &__text{
      margin-top: 50px;
      padding-top: 50px;
      text-align: center;
      position: relative;

      @media (max-width: 991px) {
        margin-top: 15px;
        padding-top: 15px;
      }

      p{
        margin-bottom: 10px;
        @include font(1.5rem, 500, rgba(255, 255, 255, .5));

        @media (max-width: 1450px) {
          font-size: 1.2rem;
        }
      }

      h4{
        @include font(2.6rem, 700, #fff);
        line-height: 3.1rem;
        word-break: break-all;

        @media (max-width: 1650px) {
          font-size: 2.2rem;
        }

        @media (max-width: 1450px) {
          font-size: 2rem;
          line-height: 2.5rem;
        }

        @media (max-width: 1260px) {
          font-size: 1.6rem;
          line-height: 2.1rem;
        }
      }

      &:after{
        content: '';
        width: 100%;
        max-width: 40px;
        height: 1px;
        display: inline-block;
        background-color: #fff;
        @include center;
      }
    }

    img{
      margin: 0 auto;
      display: block;

      &:last-of-type{
        display: none;
      }
    }
  }

  &-bottom{
    margin-top: 145px;

    @media (max-height: 760px) {
      margin-top: 100px;
    }

    @media (max-width: 991px) {
      margin-top: 30px;
    }
  }

  &-bottom{
    padding: 10px 0;
    text-align: center;

    @media (max-width: 767px) {
      display: none;
    }
  }
}