.en{
    &-board{
        &-top{
            &__text{
                width: calc(100% - 405px);
                display: inline-block;
                vertical-align: middle;

                @media (max-width: 991px) {
                    width: calc(100% - 355px);
                }

                @media (max-width: 767px) {
                    width: 100%;
                    margin-bottom: 10px;
                }

                p{
                    @include font(1.5rem, 400, #555);

                    @media (max-width: 767px) {
                        font-size: 1.4rem;
                    }

                    b{
                        color: #111;
                    }

                    span{
                        width: 1px;
                        height: 15px;
                        margin: 0 10px;
                        display: inline-block;
                        background-color: #ddd;
                        position: relative;
                        top: 2px;

                        @media (max-width: 767px) {
                            height: 13px;
                            margin: 0 5px;
                        }
                    }
                }
            }

            &__search{
                width: 405px;
                display: inline-block;
                vertical-align: middle;
                position: relative;

                @media (max-width: 991px) {
                    width: 355px;
                }

                @media (max-width: 767px) {
                    width: 100%;
                }

                select{
                    width: 120px;
                    height: 48px;
                    margin-right: 5px;
                    padding: 0 15px;
                    border: 1px solid #dedede;
                    border-radius: 5px;
                    @include font(1.5rem, 700, #555);

                    @media (max-width: 767px) {
                        width: 100%;
                        height: 35px;
                        margin-bottom: 5px;
                    }
                }

                input{
                    width: 280px;
                    height: 48px;
                    padding: 0 15px;
                    border: 1px solid #dedede;
                    border-radius: 5px;
                    @include font(1.5rem, 700, #555);

                    @media (max-width: 991px) {
                        width: 230px;
                    }

                    @media (max-width: 767px) {
                        width: 100%;
                        height: 35px;
                        margin-bottom: 5px;
                    }

                    &::placeholder{
                        @include font(1.5rem, 700, #999);
                    }
                }

                button{
                    width: 45px;
                    height: 48px;
                    border-radius: 5px;
                    display: inline-block;
                    background-color: #12217d;
                    @include poa(0, 0, auto, auto);

                    @media (max-width: 767px) {
                        width: 100%;
                        height: 35px;
                        @include po-cancel;
                        position: relative;
                    }

                    img{
                        @include poa-center;
                    }
                }
            }
        }

        &-table{
            margin-top: 30px;

            @media (max-width: 767px) {
                overflow-x: auto;
            }

            .w-5{width: 5%;}
            .w-10{width: 10%;}
            .w-15{width: 15%;}
            .w-20{width: 20%;}
            .w-25{width: 25%;}
            .w-30{width: 30%;}
            .w-35{width: 35%;}
            .w-40{width: 40%;}
            .w-45{width: 45%;}
            .w-50{width: 50%;}
            .w-55{width: 55%;}
            .w-60{width: 60%;}
            .w-65{width: 65%;}
            .w-70{width: 70%;}
            .w-75{width: 75%;}
            .w-80{width: 80%;}
            .w-85{width: 85%;}
            .w-90{width: 90%;}
            .w-95{width: 95%;}
            .w-100{width: 100%;}

            .ta-l{text-align: left;}
            .ta-c{text-align: center;}
            .ta-r{text-align: right;}
            .va-t{vertical-align: top;}
            .va-m{vertical-align: middle;}
            .va-b{vertical-align: bottom;}

            @media (max-width: 1200px) {
              .w-md-5{width: 5%;}
              .w-md-10{width: 10%;}
              .w-md-15{width: 15%;}
              .w-md-20{width: 20%;}
              .w-md-25{width: 25%;}
              .w-md-30{width: 30%;}
              .w-md-35{width: 35%;}
              .w-md-40{width: 40%;}
              .w-md-45{width: 45%;}
              .w-md-50{width: 50%;}
              .w-md-55{width: 55%;}
              .w-md-60{width: 60%;}
              .w-md-65{width: 65%;}
              .w-md-70{width: 70%;}
              .w-md-75{width: 75%;}
              .w-md-80{width: 80%;}
              .w-md-85{width: 85%;}
              .w-md-90{width: 90%;}
              .w-md-95{width: 95%;}
              .w-md-100{width: 100%;}
              .w-md-n{display: none;}
            }
        
            @media (max-width: 991px) {
              .w-sm-5{width: 5%;}
              .w-sm-10{width: 10%;}
              .w-sm-15{width: 15%;}
              .w-sm-20{width: 20%;}
              .w-sm-25{width: 25%;}
              .w-sm-30{width: 30%;}
              .w-sm-35{width: 35%;}
              .w-sm-40{width: 40%;}
              .w-sm-45{width: 45%;}
              .w-sm-50{width: 50%;}
              .w-sm-55{width: 55%;}
              .w-sm-60{width: 60%;}
              .w-sm-65{width: 65%;}
              .w-sm-70{width: 70%;}
              .w-sm-75{width: 75%;}
              .w-sm-80{width: 80%;}
              .w-sm-85{width: 85%;}
              .w-sm-90{width: 90%;}
              .w-sm-95{width: 95%;}
              .w-sm-100{width: 100%;}
              .w-sm-n{display: none;}
            }
        
            @media (max-width: 767px) {
              .w-xs-5{width: 5%;}
              .w-xs-10{width: 10%;}
              .w-xs-15{width: 15%;}
              .w-xs-20{width: 20%;}
              .w-xs-25{width: 25%;}
              .w-xs-30{width: 30%;}
              .w-xs-35{width: 35%;}
              .w-xs-40{width: 40%;}
              .w-xs-45{width: 45%;}
              .w-xs-50{width: 50%;}
              .w-xs-55{width: 55%;}
              .w-xs-60{width: 60%;}
              .w-xs-65{width: 65%;}
              .w-xs-70{width: 70%;}
              .w-xs-75{width: 75%;}
              .w-xs-80{width: 80%;}
              .w-xs-85{width: 85%;}
              .w-xs-90{width: 90%;}
              .w-xs-95{width: 95%;}
              .w-xs-100{width: 100%;}
              .w-xs-n{display: none;}
            }

            table{
                width: 100%;
                border-top: 2px solid #142b48;
                table-layout: fixed;

                @media (max-width: 767px) {
                    width: 700px;
                }
            }

            thead{
                tr{
                    border-bottom: 1px solid #aaa;
                }
            }

            tr{
                border-bottom: 1px solid #ddd;
            }

            th{
                @include font(1.7rem, 700, #142b48);
                height: 60px;
            }

            td{}

            p, a{
                @include font(1.5rem, 400, #111);
                line-height: 54px;
            }

            a{
                width: 100%;
                padding-left: 30px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;

                @media (max-width: 991px) {
                    padding-left: 15px;
                }
            }
        }

        &-gallery{
            margin-top: 30px;
            padding-top: 40px;
            border-top: 2px solid #142b48;
            border-bottom: 1px solid #ddd;

            @media (max-width: 767px) {
                padding-top: 30px;
            }

            ul{
                padding-bottom: 20px;

                @media (max-width: 767px) {
                    padding-bottom: 0;
                }
            }

            li{
                width: calc((100% - 90px) / 4);
                margin-right: 30px;
                margin-bottom: 30px;
                border: 1px solid #ddd;
                display: inline-block;
                vertical-align: top;

                @media (max-width: 1320px) {
                    width: calc((100% - 60px) / 3);
                }

                @media (max-width: 991px) {
                    width: calc((100% - 30px) / 2);
                }

                @media (max-width: 767px) {
                    width: 100%;
                    margin-right: 0;
                }

                &:nth-child(4n) {
                    margin-right: 0;

                    @media (max-width: 1320px) {
                        margin-right: 30px;
                    }
                }

                &:nth-child(3n) {
                    @media (max-width: 1320px) {
                        margin-right: 0;
                    }

                    @media (max-width: 991px) {
                        margin-right: 30px;
                    }

                    @media (max-width: 767px) {
                        margin-right: 0;
                    }
                }

                &:nth-child(2n) {
                    @media (max-width: 991px) {
                        margin-right: 0;
                    }
                }
            }

            &__img{
                img{
                    width: 100%;
                }
            }

            &__text{
                padding: 30px 25px;
                border-top: 1px solid #ddd;

                @media (max-width: 767px) {
                    padding: 15px;
                }

                h3{
                    margin-bottom: 25px;
                    @include font(1.5rem, 400, #111);
                    @include line-clamp(7.5rem, 2.5rem, 3);

                    @media (max-width: 767px) {
                        margin-bottom: 15px;
                    }
                }

                p{
                    @include font(1.5rem, 400, #999);
                }
            }
        }
    }

    &-paging{
        margin-top: 30px;
        text-align: center;

        ul{
            margin: 0 10px;
            display: inline-block;
            vertical-align: middle;

            &:not(.en-paging__num){
                @media (max-width: 767px) {
                    display: none;
                }

                a{
                    border: 1px solid #dfdfdf;
                }
            }
        }

        li{
            margin: 0 3px;
            display: inline-block;
            vertical-align: middle;
        }
        
        a{
            width: 40px;
            height: 40px;
            border-radius: 40px;
            @include font(1.3rem, 700, #555);
            line-height: 40px;
            position: relative;

            &.selected{
                background-color: #333;
                color: #fff;
            }

            img{
                @include poa-center;
            }
        }
    }
}