.board-search{
  padding: 20px 35px;
  border: 3px solid #f5f5f5;
  border-top: 3px solid #0f8940;

  @media (max-width: 1250px) {
    padding: 20px;
  }

  &__img{
    width: 180px;
    display: inline-block;
    vertical-align: middle;
    text-align: center;

    // @media (max-width: 1250px) {
    //   width: 70px;
    // }

    @media (max-width: 991px) {
      display: none;
    }
  }

  &__form{
    width: calc(100% - 180px);
    display: inline-block;
    vertical-align: middle;

    // @media (max-width: 1250px) {
    //   width: calc(100% - 70px);
    // }

    @media (max-width: 991px) {
      width: 100%;
    }

    p{
      margin-bottom: 15px;
      @include font(1.6rem, 400, #000);

      img{
        margin-right: 5px;
        vertical-align: middle;
      }
    }

    form{
      >*{
        display: inline-block;
        vertical-align: middle;
      }

      @media (max-width: 767px) {
        >div{
          display: block;
        }
      }
    }

    select{
      width: 150px;
      height: 42px;
      margin-right: 10px;
      border: 1px solid #d1d1d1;
      display: inline-block;
      @include font(1.6rem, 400, #000);
      vertical-align: middle;

      @media (max-width: 1250px) {
        margin-bottom: 5px;
      }

      @media (max-width: 767px) {
        width: 100%;
        margin-right: 0;
      }
    }

    input[type="text"]{
      width: 260px;
      height: 42px;
      margin-right: 10px;
      border: 1px solid #d1d1d1;
      display: inline-block;
      @include font(1.6rem, 400, #000);
      vertical-align: middle;

      @media (max-width: 767px) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 5px;
      }
    }

    a{
      width: 110px;
      height: 42px;
      display: inline-block;
      background-color: #3b3c3d;
      vertical-align: middle;
      position: relative;

      @media (max-width: 767px) {
        width: 100%;
      }

      img{
        @include poa-center;
      }
    }
  }
}

.search{
  &-bg{
    width: 100%;
    height: 100vh;
    display: none;
    background-color: rgba(0, 0, 0, .8);
    @include pof(0, auto, auto, 0);
    z-index: 10;

    @media (max-width: 991px) {
      display: none!important;
    }
  }

  &-wrap{
    width: 100%;
    @include middle;
    text-align: center;
  }

  &-form{
    width: 100%;
    max-width: 500px;
    display: inline-block;
    position: relative;

    form{
      width: 100%;
    }

    >*{
      display: inline-block;
      vertical-align: middle;
    }

    &__close-btn{
      width: 30px;
      height: 30px;
      @include poa(-60px, 0, auto, auto);

      &:before{
        content: '';
        width: 30px;
        height: 2px;
        display: inline-block;
        background-color: #fff;
        @include poa-center;
        transform: translate(-50%, -50%) rotate(135deg);
      }

      &:after{
        content: '';
        width: 30px;
        height: 2px;
        display: inline-block;
        background-color: #fff;
        @include poa-center;
        transform: translate(-50%, -50%) rotate(-135deg);
      }
    }

    input{
      width: calc(100% - 100px);
      max-width: 400px;
      height: 50px;
      border: none;
      border-bottom: 2px solid #0f8940;
      background-color: transparent;
      @include font(1.8rem, 500, #fff);
      vertical-align: middle;
      box-sizing: border-box;

      &::placeholder{
        @include font(1.8rem, 500, #fff);
        text-align: center;
      }

      @media (max-width: 767px) {
        width: calc(100% - 60px);
      }
    }

    .search-btn{
      width: 100px;
      height: 50px;
      // margin-left: 10px;
      // border: 2px solid #0f8940;
      background-color: #0f8940;
      // background-color: transparent;
      position: relative;
      vertical-align: middle;

      @media (max-width: 767px) {
        width: 60px;
      }

      img{
        @include poa-center;
      }
    }
  }
}
