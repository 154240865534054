.honor{
  &-list{
    &__li{
      width: calc(50% - 20px);
      margin: 0 40px 40px 0;
      border: 1px solid #ddd;
      border-top: 2px solid #9c7e29;
      display: inline-block;
      background-color: #f9f9f9;
      vertical-align: top;

      @media (max-width: 1250px) {
        width: calc(50% - 10px);
        margin: 0 20px 20px 0;
      }

      @media (max-width: 991px) {
        width: 100%;
        margin-right: 0;
      }

      &:nth-child(2n){
        margin-right: 0;
      }
    }

    &__img{
      width: 225px;
      display: inline-block;
      vertical-align: middle;
      text-align: center;

      @media (max-width: 1420px) {
        width: 180px;
      }

      @media (max-width: 767px) {
        width: 100%;
        padding: 10px 0; 
      }
    }

    &__text{
      width: calc(100% - 225px);
      padding: 45px;
      display: inline-block;
      background-color: #fff;
      vertical-align: middle;
      position: relative;

      @media (max-width: 1420px) {
        width: calc(100% - 180px);
      }

      @media (max-width: 1250px) {
        padding: 45px 30px;
      }

      @media (max-width: 991px) {
        height: auto!important;
      }

      @media (max-width: 767px) {
        width: 100%;
        padding: 30px 15px;
      }

      &__insignia{
        width: 80px;
        height: 54px;
        padding-top: 20px;
        display: inline-block;
        background-color: #9c7e29;
        @include font(1.6rem, 500, #fff);
        text-align: center;
        @include poa(0, 45px, auto, auto);

        @media (max-width: 767px) {
          right: 15px;
        }

        &:after{
          content: '';
          width: 80px;
          height: 15px;
          border-top: 15px solid #9c7e29;
          border-left: 40px solid transparent;
          border-right: 40px solid transparent;
          border-bottom: none;
          display: inline-block;
          @include poa(100%, auto, auto, 0);
          box-sizing: border-box;
        }
      }

      div:not(.honor-list__text__insignia){
        padding-right: 80px;
        padding-bottom: 10px;
        border-bottom: 1px dashed #ddd;

        @media (max-width: 1420px) {
          padding-right: 0;
          padding-top: 20px;
        }

        @media (max-width: 767px) {
          padding-top: 40px;
        }
      }

      h4{
        margin-bottom: 5px;
        @include font(2.4rem, 500, #222);

        @media (max-width: 1650px) {
          font-size: 2rem;
        }

        @media (max-width: 1420px) {
          font-size: 1.8rem;
        }
      }

      p{
        @include font(1.4rem, 500, #666);
      }

      ul{
        padding: 25px 0 0;
      }
      
      li{
        margin-bottom: 20px;
        padding-left: 100px;
        @include font(1.6rem, 500, #666);
        position: relative;

        &:last-child{
          margin-bottom: 0;
        }

        @media (max-width: 1250px) {
          padding-left: 90px;
          font-size: 1.4rem;
        }

        &:before{
          content: '';
          width: 5px;
          height: 2px;
          display: inline-block;
          background-color: #666;
          @include poa(10px, auto, auto, 0);

          @media (max-width: 1250px) {
            top: 8px;
          }
        }

        span{
          width: 80px;
          border-right: 1px solid #ddd;
          display: inline-block;
          @include poa(0, auto, auto, 10px);

          @media (max-width: 1250px) {
            width: 70px;
          }
        }
      }
    }
  }
}