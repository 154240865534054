.en{
    &-organization{
        &-list{
            &__area{
                &0{
                    margin-bottom: 30px;
                    position: relative;

                    &:after{
                        content: '';
                        width: 1px;
                        height: 30px;
                        display: inline-block;
                        background-color: #ddd;
                        @include center(100%);
                    }

                    ul{
                        text-align: center;
                        position: relative;
                    }

                    li{
                        display: inline-block;

                        &:nth-child(1){
                            width: 179px;
                            height: 179px;
                            border-radius: 179px;
                            background-image: url('/template/resources/images/cont/en-organization-bg.png');
                            background-size: cover;
                            position: relative;
                            z-index: 1;

                            @media (max-width: 767px) {
                                width: 100px;
                                height: 100px;
                                display: inline-block;
                                vertical-align: middle;
                            }
                        }

                        &:nth-child(2){
                            width: 175px;
                            height: 60px;
                            background-color: #111987;
                            @include middle(auto, calc((50% - 85px) - 300px));

                            @media (max-width: 991px) {
                                right: calc((50% - 85px) - 250px);
                            }

                            @media (max-width: 767px) {
                                width: 60px;
                                right: calc((50% - 30px) - 100px);
                            }

                            &:before{
                                content: '';
                                width: 150px;
                                height: 1px;
                                display: inline-block;
                                background-color: #ddd;
                                @include middle(auto, 100%);

                                @media (max-width: 767px) {
                                    width: 50px;
                                }
                            }

                            p{
                                @include font(2rem, 700, #fff);

                                @media (max-width: 767px) {
                                    font-size: 1.2rem;
                                }
                            }
                        }

                        &:nth-child(3){
                            width: 175px;
                            height: 60px;
                            background-color: #111987;
                            @include poa(130px, calc((50% - 85px) - 300px), auto, auto);

                            @media (max-width: 991px) {
                                right: calc((50% - 85px) - 250px);
                            }

                            @media (max-width: 767px) {
                                width: 60px;
                                right: calc((50% - 30px) - 100px);
                                top: 90px;
                            }

                            &:before{
                                content: '';
                                width: 1px;
                                height: 10px;
                                display: inline-block;
                                background-color: #ddd;
                                @include center(auto, 100%);
                            }

                            p{
                                @include font(2rem, 700, #fff);

                                @media (max-width: 767px) {
                                    font-size: 1.2rem;
                                }
                            }
                        }
                    }

                    p{
                        width: 100%;
                        @include font(2.6rem, 700, #fff);
                        text-align: center;
                        @include middle;

                        @media (max-width: 767px) {
                            font-size: 1.4rem;
                        }
                    }
                }

                &1{
                    @media (max-width: 767px) {
                        margin-bottom: 15px;
                    }

                    ul{
                        text-align: center;
                        position: relative;
                    }

                    li{
                        display: inline-block;

                        &:nth-child(1){
                            width: 175px;
                            height: 60px;
                            background-color: #111987;

                            @media (max-width: 767px) {
                                width: 100px;
                            }

                            p{
                                @include font(2rem, 700, #fff);

                                @media (max-width: 767px) {
                                    font-size: 1.2rem;
                                }
                            }
                        }

                        p{
                            width: 100%;
                            @include font(2.6rem, 700, #fff);
                            text-align: center;
                            @include middle;

                            @media (max-width: 767px) {
                                font-size: 1.4rem;
                            }
                        }
                    }
                }

                &2{
                    padding-bottom: 15px;
                    position: relative;

                    &-line{
                        width: 1px;
                        height: 100%;
                        display: inline-block;
                        background-color: #c1c1c1;
                        @include center;

                        @media (max-width: 767px) {
                            display: none;
                        }
                    }

                    ul{
                        // display: inline-block;
                    }

                    &-left{
                        width: 50%;
                        padding-right: 245px;
                        display: inline-block;
                        text-align: right;
                        vertical-align: top;

                        @media (max-width: 991px) {
                            padding-right: 170px;
                        }

                        @media (max-width: 767px) {
                            width: 70px;
                            padding-right: 0;
                        }

                        li{
                            margin-top: 65px;
                            border: 1px solid #111987;

                            @media (max-width: 767px) {
                                margin-top: 0;
                            }

                            &:before{
                                width: 245px;
                                @include middle(100%);

                                @media (max-width: 991px) {
                                    width: 170px;
                                }
                            }

                            // &:nth-child(1){
                            //     &:before{
                            //         display: none;
                            //     }
                            // }

                            p{
                                padding: 0 5px;
                                @include font(1.6rem, 400, #111987);

                                @media (max-width: 767px) {
                                    font-size: 1.4rem;
                                }
                            }
                        }
                    }

                    &-right{
                        width: 50%;
                        padding-left: 200px;
                        display: inline-block;
                        vertical-align: top;

                        @media (max-width: 991px) {
                            padding-left: 170px;
                        }

                        @media (max-width: 767px) {
                            width: calc(100% - 70px);
                            padding-left: 10px;
                        }

                        ul{
                            margin-top: 10px;
                            padding-top: 10px;
                            @media (max-width: 767px) {
                                width: 100%;
                            }

                            &:first-child{
                                margin-top: 0;
                                padding-top: 0;

                                li{
                                    display: block;

                                    &:before{
                                        content: '';
                                        width: 200px;
                                        height: 1px;
                                        display: inline-block;
                                        background-color: #c1c1c1;
                                        @include middle(auto, 100%);
                                        
                                        @media (max-width: 991px) {
                                            width: 170px;
                                        }
                                    }

                                    &:after{
                                        content: '';
                                        width: 1px;
                                        height: 10px;
                                        display: inline-block;
                                        background-color: #c1c1c1;
                                        @include center(calc(100% + 1px));
                                    }
                                }
                            }
                        }

                        li{
                            margin-right: 10px;
                            border: 1px solid #009ba9;
                            display: inline-block;
                            vertical-align: top;

                            @media (max-width: 767px) {
                                width: 100%!important;
                            }

                            &:before{
                                content: '';
                                width: 1px;
                                height: 10px;
                                display: inline-block;
                                background-color: #c1c1c1;
                                @include center(auto, calc(100% + 1px));
                            }

                            // &:nth-child(1){
                            //     &:before{
                            //         display: none;
                            //     }
                            // }

                            &:last-child{
                                margin-right: 0;

                                &:after{
                                    content: '';
                                    width: calc(100% + 12px);
                                    height: 1px;
                                    display: inline-block;
                                    background-color: #c1c1c1;
                                    @include poa(-11px, 50%, auto, auto);
                                }
                            }

                            p{
                                padding: 0 5px;
                                @include font(1.6rem, 400, #009ba9);

                                @media (max-width: 767px) {
                                    font-size: 1.4rem;
                                }
                            }
                        }
                    }

                    li{
                        width: 175px;
                        height: 60px;
                        margin-bottom: 5px;
                        background-color: #fff;
                        position: relative;

                        @media (max-width: 767px) {
                            width: 70px;
                            height: 85px;
                        }

                        &:last-child{
                            margin-bottom: 0;
                        }

                        // &:before{
                        //     content: '';
                        //     height: 1px;
                        //     display: inline-block;
                        //     background-color: #c1c1c1;

                        //     @media (max-width: 767px) {
                        //         display: none;
                        //     }
                        // }

                        p{
                            width: 100%;
                            padding: 0 5px;
                            text-align: center;
                            @include middle;

                            @media (max-width: 767px) {
                                word-break: break-all;
                            }
                        }
                    }
                }

                &3{
                    position: relative;

                    @media (max-width: 991px) {
                        word-break: break-all;
                    }

                    @media (max-width: 767px) {
                        border-top: 1px solid #c1c1c1;
                        word-break: normal;
                    }

                    &:after{
                        content: '';
                        width: calc(((100% - 90px) / 10) * 6 + 60px);
                        height: 1px;
                        display: inline-block;
                        background-color: #c1c1c1;
                        @include poa(0, calc(((100% - 90px) / 10) / 2), auto, auto);

                        @media (max-width: 767px) {
                            display: none;
                        }
                    }

                    &-col{
                        margin-right: 10px;
                        padding-top: 15px;
                        display: inline-block;
                        vertical-align: top;
                        position: relative;
                    }

                    &-line{
                        width: 1px;
                        height: 100%;
                        display: inline-block;
                        background-color: #c1c1c1;
                        @include center;

                        @media (max-width: 767px) {
                            display: none;
                        }
                    }

                    &-col1{
                        width: calc(((100% - 90px) / 10) * 6 + 50px);
                        position: relative;

                        @media (max-width: 767px) {
                            width: 100%;
                        }

                        .en-organization-list__area3-line{
                            left: calc((((100% - 50px) / 6) * 3 + 30px) + (((100% - 50px) / 6) / 2));
                            transform: none;

                            @media (max-width: 767px) {
                                display: none;
                            }
                        }

                        .en-organization-list__area3{
                            &-1depth, &-2depth{
                                > li{
                                    width: calc((100% - 50px) / 6);
                                    margin-left: calc(((100% - 50px) / 6) * 3 + 30px);

                                    @media (max-width: 767px) {
                                        width: 100%;
                                        margin-left: 0;
                                    }
                                }
                            }

                            &-3depth{
                                position: relative;

                                &:before{
                                    content: '';
                                    width: calc(((100% - 50px) / 6) * 5 + 50px);
                                    height: 1px;
                                    display: inline-block;
                                    background-color: #c1c1c1;
                                    @include poa(0, auto, auto, calc(((100% - 50px) / 6) / 2));

                                    @media (max-width: 767px) {
                                        display: none;
                                    }
                                }

                                > li{
                                    width: calc((100% - 50px) / 6);
                                    margin-right: 10px;
                                    display: inline-block;

                                    @media (max-width: 767px) {
                                        width: 100%;
                                        margin-right: 0;
                                    }

                                    &:nth-child(3){
                                        width: calc(((100% - 50px) / 6) * 3 + 20px);

                                        @media (max-width: 767px) {
                                            width: 100%;
                                        }

                                        div{
                                            width: calc((100% - 20px) / 3);
                                            display: inline-block;

                                            @media (max-width: 767px) {
                                                width: 100%;
                                            }

                                            &:after{
                                                display: none;
                                            }
                                        }

                                        ul{
                                            position: relative;

                                            &:before{
                                                content: '';
                                                width: calc(((100% - 20px) / 3) * 2 + 20px);
                                                height: 1px;
                                                display: inline-block;
                                                background-color: #c1c1c1;
                                                @include poa(-13px, auto, auto, calc(((100% - 20px) / 3) / 2));

                                                @media (max-width: 767px) {
                                                    display: none;
                                                }
                                            }
                                        }

                                        li{
                                            width: calc((100% - 20px) / 3);
                                            margin-right: 10px;
                                            display: inline-block;
                                            vertical-align: top;

                                            &:nth-child(2){
                                                &:after{
                                                    display: none;
                                                }
                                            }

                                            &:last-child{
                                                margin-right: 0;
                                            }

                                            &:after{
                                                height: 13px;
                                            }
                                        }
                                    }

                                    li{
                                        &:after {
                                            content: '';
                                            width: 1px;
                                            height: 25px;
                                            display: inline-block;
                                            background-color: #c1c1c1;
                                            @include center(auto, calc(100% + 1px));

                                            @media (max-width: 767px) {
                                                display: none;
                                            }
                                        }
                                    }

                                    div{
                                        &:after{
                                            content: '';
                                            width: 1px;
                                            height: 15px;
                                            display: inline-block;
                                            background-color: #c1c1c1;
                                            @include center(auto, calc(100% + 1px));

                                            @media (max-width: 767px) {
                                                display: none;
                                            }
                                        }
                                    }

                                    &:last-child{
                                        margin-right: 0;
                                    }
                                }
                            }
                        }
                    }

                    &-col2{
                        width: calc(((100% - 90px) / 10) * 2 + 10px);

                        @media (max-width: 767px) {
                            width: 100%;
                        }

                        .en-organization-list__area3-line{
                            height: calc(100% - 64px);
                        }

                        .en-organization-list__area3{
                            &-1depth, &-2depth{
                                text-align: center;

                                > li{
                                    width: calc((100% - 10px) / 2);

                                    @media (max-width: 767px) {
                                        width: 100%;
                                    }
                                }
                            }

                            &-3depth{
                                text-align: center;
                                position: relative;

                                > li{
                                    div{
                                        width: calc((100% - 10px) / 2);
                                        display: inline-block;

                                        @media (max-width: 767px) {
                                            width: 100%;
                                        }
                                    }

                                    ul{
                                        position: relative;

                                        &:before{
                                            content: '';
                                            width: calc((100% - 10px) / 2 + 10px);
                                            height: 1px;
                                            display: inline-block;
                                            background-color: #c1c1c1;
                                            @include poa(-13px, auto, auto, calc(((100% - 10px) / 2) / 2));

                                            @media (max-width: 767px) {
                                                display: none;
                                            }
                                        }
                                    }

                                    li{
                                        width: calc((100% - 10px) / 2);
                                        margin-right: 10px;
                                        display: inline-block;
                                        vertical-align: top;
                                        position: relative;

                                        &:after {
                                            content: '';
                                            width: 1px;
                                            height: 13px;
                                            display: inline-block;
                                            background-color: #c1c1c1;
                                            @include center(auto, calc(100% + 1px));

                                            @media (max-width: 767px) {
                                                display: none;
                                            }
                                        }

                                        &:last-child{
                                            margin-right: 0;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    &-col3{
                        width: calc(((100% - 90px) / 10) * 1);

                        @media (max-width: 767px) {
                            width: 100%;
                        }

                        .en-organization-list__area3{
                            &-1depth, &-2depth{
                                text-align: center;

                                > li{
                                    width: 100%;
                                }
                            }
                        }
                    }

                    &-col4{
                        width: calc(((100% - 90px) / 10) * 1);
                        margin-right: 0;

                        @media (max-width: 767px) {
                            width: 100%;
                        }

                        .en-organization-list__area3{
                            &-1depth, &-2depth{
                                text-align: center;

                                > li{
                                    width: 100%;
                                }
                            }
                        }
                    }

                    &-1depth{
                        margin-bottom: 35px;

                        @media (max-width: 767px) {
                            margin-bottom: 15px;
                        }

                        > li{
                            height: 52px;
                            background-color: #4ac7f0;
                            display: inline-block;
                            position: relative;

                            @media (max-width: 1550px) {
                                height: 76px;
                            }

                            @media (max-width: 991px) {
                                height: 105px;
                            }

                            &.null{
                                background-color: transparent;

                                @media (max-width: 767px) {
                                    display: none;
                                }
                            }

                            p{
                                width: 100%;
                                padding: 0 5px;
                                @include font(1.2rem, 700, #fff);
                                text-align: center;
                                @include middle;
                            }
                        }
                    }

                    &-2depth{
                        margin-bottom: 15px;

                        @media (max-width: 767px) {
                            margin-bottom: 0;
                        }

                        > li{
                            height: 52px;
                            background-color: #f0b74a;
                            display: inline-block;
                            position: relative;

                            @media (max-width: 991px) {
                                height: 70px;
                            }

                            &.null{
                                background-color: transparent;

                                @media (max-width: 767px) {
                                    display: none;
                                }
                            }

                            p{
                                width: 100%;
                                padding: 0 5px;
                                @include font(1.2rem, 700, #fff);
                                text-align: center;
                                @include middle;
                            }
                        }
                    }

                    &-3depth{
                        @media (max-width: 767px) {
                            padding-bottom: 10px;
                            border-bottom: 1px solid #c1c1c1;
                        }

                        > li{
                            padding-top: 15px;
                            vertical-align: top;
                            text-align: center;
                            position: relative;
                            z-index: 1;

                            @media (max-width: 767px) {
                                margin-top: 10px;
                                padding-top: 10px;
                                border-top: 1px solid #c1c1c1;
                            }

                            div{
                                height: 52px;
                                border: 1px solid #dfdfdf;
                                background-color: #f1f1f1;
                                position: relative;

                                @media (max-width: 991px) {
                                    height: 90px;
                                }
                            }

                            ul{
                                margin-top: 25px;

                                @media (max-width: 767px) {
                                    margin-top: 10px;
                                }
                            }

                            li{
                                height: 52px;
                                border: 1px solid #dfdfdf;
                                background-color: #fff;
                                position: relative;

                                @media (max-width: 991px) {
                                    height: 90px;
                                }
                            }

                            p{
                                width: 100%;
                                padding: 0 5px;
                                @include font(1.2rem, 400, #111);
                                @include middle;
                            }
                        }
                    }
                }
            }
        }
    }
}

.en-sub-contents{
    .organization{
        a{
            // pointer-events: none;
        }

        p{
            width: 100%;
            padding: 0 5px;
            word-break: normal;

            @media (max-width: 767px) {
                padding: 0 3px;
            }
        }

        &-list{
            &__area{
                &0{
                    a{
                        line-height: 1.4;
                        position: relative;

                        @media (max-width: 767px) {
                            width: 80px;
                            height: 80px;
                            font-size: 1.4rem;
                        }

                        p{
                            @include middle;
                        }
                    }

                    div{
                        @media (max-width: 767px) {
                            padding-top: 90px;
                        }

                        @media (max-width: 767px) {
                            right: calc((50% - 40px) - 90px);
                        }

                        a{
                            line-height: 1.4;

                            @media (max-width: 767px) {
                                width: 75px;
                                height: 80px;
                            }

                            p{
                                font-size: 1.6rem;
                                @include middle;

                                @media (max-width: 1200px) {
                                    font-size: 1.4rem;
                                }

                                @media (max-width: 767px) {
                                    font-size: 1.2rem;
                                }
                            }
                        }
                    }
                }

                &1{
                    a{
                        @media (max-width: 767px) {
                            width: 80px;
                            height: 80px;
                            font-size: 1.4rem;
                        }

                        p{
                            @include middle;
                        }
                    }
                }

                &2{
                    a{
                        margin-bottom: 0;
                        font-size: 1.6rem;
                        line-height: 1.4;

                        @media (max-width: 1200px) {
                            font-size: 1.4rem;
                        }

                        @media (max-width: 767px) {
                            width: 75px;
                            height: 80px;
                            font-size: 1.2rem;
                        }

                        @media (max-width: 370px) {
                            width: 55px;
                        }

                        p{
                            @include middle;

                            @media (max-width: 370px) {
                                word-break: break-all;
                            }
                        }
                    }

                    &__right{
                        @media (max-width: 1320px) {
                            padding-left: 45px;
                        }

                        @media (max-width: 767px) {
                            padding-left: 10px;
                        }

                        a{
                            &:nth-of-type(1){
                                margin: 0 0 20px 0;

                                @media (max-width: 767px) {
                                    margin: 0 0 20px 0;
                                }

                                &:after{
                                    @media (max-width: 1320px) {
                                        width: 45px;
                                    }

                                    @media (max-width: 767px) {
                                        width: 10px;
                                    }
                                }
                            }
                        }
                    }
                }

                &3{
                    a{
                        max-width: 208px;
                        height: 82px;

                        @media (max-width: 1550px) {
                            max-width: 168px;
                        }

                        @media (max-width: 1320px) {
                            max-width: 128px;
                            height: 110px;
                        }

                        @media (max-width: 1200px) {
                            height: 82px;
                        }

                        @media (max-width: 991px) {
                            max-width: 106px;
                        }

                        @media (max-width: 767px) {
                            max-width: none;
                            height: 60px;
                        }
                    }

                    .null{
                        height: 82px;

                        @media (max-width: 1320px) {
                            height: 110px;
                        }

                        @media (max-width: 1200px) {
                            height: 82px;
                        }

                        @media (max-width: 767px) {
                            height: 60px;
                        }
                    }

                    &__col{
                        .f-depth{
                            > li{
                                >a{
                                    p{
                                        font-size: 1.6rem;

                                        @media (max-width: 1200px) {
                                            font-size: 1.4rem;
                                        }
                                    }
                                }
                            }
                        }

                        .s-depth{
                            > li{
                                > a{
                                    p{
                                        font-size: 1.6rem;

                                        @media (max-width: 1200px) {
                                            font-size: 1.4rem;
                                        }
                                    }
                                }
                            }
                        }

                        &__wrap{
                            &.n-line{
                                &:before{
                                    height: 344px;

                                    @media (max-width: 1320px) {
                                        height: 400px;
                                    }

                                    @media (max-width: 1200px) {
                                        height: 344px;
                                    }
                                }
                            }

                            .t-depth{
                                > li{
                                    > a{
                                        p{
                                            font-size: 1.6rem;

                                            @media (max-width: 1200px) {
                                                font-size: 1.4rem;
                                            }
                                        }
                                    }
                                }

                                li{
                                    a{
                                        p{
                                            font-size: 1.6rem;

                                            @media (max-width: 1200px) {
                                                font-size: 1.4rem;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        &.col{
                            &1{
                                .f-depth{
                                    > li{
                                        > a{
                                            margin-right: 210px;

                                            @media (max-width: 1520px) {
                                                margin-right: 170px;
                                            }

                                            @media (max-width: 1320px) {
                                                margin-right: 130px;
                                            }

                                            @media (max-width: 767px) {
                                                margin-right: 0;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}