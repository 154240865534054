.en{
    &-basic-table{
        width: 100%;
        border-top: 2px solid #4d4d4d;
        table-layout: fixed;

        .w-5{width: 5%;}
        .w-10{width: 10%;}
        .w-15{width: 15%;}
        .w-20{width: 20%;}
        .w-25{width: 25%;}
        .w-30{width: 30%;}
        .w-35{width: 35%;}
        .w-40{width: 40%;}
        .w-45{width: 45%;}
        .w-50{width: 50%;}
        .w-55{width: 55%;}
        .w-60{width: 60%;}
        .w-65{width: 65%;}
        .w-70{width: 70%;}
        .w-75{width: 75%;}
        .w-80{width: 80%;}
        .w-85{width: 85%;}
        .w-90{width: 90%;}
        .w-95{width: 95%;}
        .w-100{width: 100%;}

        .ta-l{text-align: left;}
        .ta-c{text-align: center;}
        .ta-r{text-align: right;}
        .va-t{vertical-align: top;}
        .va-m{vertical-align: middle;}
        .va-b{vertical-align: bottom;}

        @media (max-width: 1200px) {
          .w-md-5{width: 5%;}
          .w-md-10{width: 10%;}
          .w-md-15{width: 15%;}
          .w-md-20{width: 20%;}
          .w-md-25{width: 25%;}
          .w-md-30{width: 30%;}
          .w-md-35{width: 35%;}
          .w-md-40{width: 40%;}
          .w-md-45{width: 45%;}
          .w-md-50{width: 50%;}
          .w-md-55{width: 55%;}
          .w-md-60{width: 60%;}
          .w-md-65{width: 65%;}
          .w-md-70{width: 70%;}
          .w-md-75{width: 75%;}
          .w-md-80{width: 80%;}
          .w-md-85{width: 85%;}
          .w-md-90{width: 90%;}
          .w-md-95{width: 95%;}
          .w-md-100{width: 100%;}
          .w-md-n{display: none;}
        }

        @media (max-width: 991px) {
          .w-sm-5{width: 5%;}
          .w-sm-10{width: 10%;}
          .w-sm-15{width: 15%;}
          .w-sm-20{width: 20%;}
          .w-sm-25{width: 25%;}
          .w-sm-30{width: 30%;}
          .w-sm-35{width: 35%;}
          .w-sm-40{width: 40%;}
          .w-sm-45{width: 45%;}
          .w-sm-50{width: 50%;}
          .w-sm-55{width: 55%;}
          .w-sm-60{width: 60%;}
          .w-sm-65{width: 65%;}
          .w-sm-70{width: 70%;}
          .w-sm-75{width: 75%;}
          .w-sm-80{width: 80%;}
          .w-sm-85{width: 85%;}
          .w-sm-90{width: 90%;}
          .w-sm-95{width: 95%;}
          .w-sm-100{width: 100%;}
          .w-sm-n{display: none;}
        }

        @media (max-width: 767px) {
          .w-xs-5{width: 5%;}
          .w-xs-10{width: 10%;}
          .w-xs-15{width: 15%;}
          .w-xs-20{width: 20%;}
          .w-xs-25{width: 25%;}
          .w-xs-30{width: 30%;}
          .w-xs-35{width: 35%;}
          .w-xs-40{width: 40%;}
          .w-xs-45{width: 45%;}
          .w-xs-50{width: 50%;}
          .w-xs-55{width: 55%;}
          .w-xs-60{width: 60%;}
          .w-xs-65{width: 65%;}
          .w-xs-70{width: 70%;}
          .w-xs-75{width: 75%;}
          .w-xs-80{width: 80%;}
          .w-xs-85{width: 85%;}
          .w-xs-90{width: 90%;}
          .w-xs-95{width: 95%;}
          .w-xs-100{width: 100%;}
          .w-xs-n{display: none;}
        }

        tr{
            border-bottom: 1px solid #c1c1c1;
        }

        th{
            height: 52px;
            padding: 0 10px;
            border-right: 1px solid #d9d9d9;
            background-color: #fafafa;
            @include font(1.4rem, 700, #111);

            &:last-child{
                border-right: none;
            }
        }

        td{
            padding: 15px 10px;
            border-right: 1px solid #d9d9d9;
            @include font(1.4rem, 400, #555);

            &:last-child{
                border-right: none;
            }

            &.bg{
                background-color: #fdfdfd;
            }
        }
    }
}