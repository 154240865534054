.en{
    &-about{
        &-top{
            margin-bottom: 50px;

            @media (max-width: 767px) {
                margin-bottom: 30px;
            }

            &__text{
                padding: 15px;
                background-image: url('/template/resources/images/cont/en-about-top-bg.png');
                background-position: center;

                @media (max-width: 767px) {
                    margin-bottom: 10px;
                }

                div{
                    padding: 50px 450px 110px 50px;
                    background-color: #fff;
                    position: relative;

                    @media (max-width: 1320px) {
                        padding: 50px 400px 70px 50px;
                    }

                    @media (max-width: 991px) {
                        padding: 50px 200px 70px 50px;
                    }

                    @media (max-width: 767px) {
                        padding: 30px 15px;
                    }
                }

                h3{
                    margin-bottom: 25px;
                    padding-bottom: 25px;
                    border-bottom: 1px solid #e0e0e0;
                    @include font(3.6rem, 400, #003561);

                    @media (max-width: 1320px) {
                        font-size: 2.4rem;
                        position: relative;
                        z-index: 1;
                    }

                    @media (max-width: 767px) {
                        margin-bottom: 15px;
                        padding-bottom: 15px;
                        font-size: 2rem;
                    }

                    span{
                        color: #089799;
                    }
                }

                p{
                    @include font(1.6rem, 400, #003561);
                    line-height: 2.9rem;

                    @media (max-width: 1320px) {
                        position: relative;
                        z-index: 1;

                        br{
                            display: none;
                        }
                    }

                    @media (max-width: 767px) {
                        font-size: 1.4rem;
                        line-height: 2.7rem;
                    }
                }

                img{
                    @include poa(25px, 40px, auto, auto);

                    @media (max-width: 1320px) {
                        width: 300px;
                    }

                    @media (max-width: 767px) {
                        width: 200px;
                        right: 15px;
                    }
                }
            }

            &__president{
                width: calc(100% - 120px);
                height: 145px;
                margin-left: 120px;
                margin-top: -70px;
                padding: 45px 500px 45px 50px;
                position: relative;
                background: linear-gradient(to right, #06aba7, #0175c0);

                @media (max-width: 991px) {
                    height: 120px;
                    padding: 32px 300px 32px 50px;
                }

                @media (max-width: 767px) {
                    width: 100%;
                    height: auto;
                    margin-left: 0;
                    margin-top: 0;
                    padding: 15px;
                    text-align: center;
                }

                h4{
                    @include font(1.6rem, 400, rgba(255, 255, 255, .5));

                    @media (max-width: 767px) {
                        text-align: center;
                    }
                }

                h3{
                    @include font(2.6rem, 400, #fff);

                    @media (max-width: 767px) {
                        text-align: center;
                    }
                }

                img{
                    @include poa(auto, 0, 0, auto);

                    @media (max-width: 1320px) {
                        width: 300px;
                    }

                    @media (max-width: 991px) {
                        width: 200px;
                    }

                    @media (max-width: 767px) {
                        margin-top: 15px;
                        @include po-cancel;
                    }
                }
            }
        }

        &-bottom{
            &__text{
                p{
                margin-bottom: 40px;
                @include font(1.6rem, 400, #777);
                line-height: 2.9rem;

                    @media (max-width: 991px) {
                        margin-bottom: 20px;
                    }

                    @media (max-width: 767px) {
                        margin-bottom: 15px;
                        font-size: 1.4rem;
                        line-height: 2.7rem;
                    }
                
                }
            }

            &__list{
                margin-top: 60px;
                padding-top: 45px;
                border-top: 5px solid #111;

                @media (max-width: 767px) {
                    padding-top: 20px;
                }

                >div{
                    width: 50%;
                    display: inline-block;
                    vertical-align: top;

                    @media (max-width: 767px) {
                        width: 100%;
                    }

                    &:first-child{
                        padding-right: 40px;

                        @media (max-width: 767px) {
                            padding-right: 0;
                        }
                    }

                    &:last-child{
                        padding-left: 40px;
                        border-left: 1px solid #e0e0e0;

                        @media (max-width: 767px) {
                            margin-top: 20px;
                            padding-top: 20px;
                            padding-left: 0;
                            border-top: 1px solid #e0e0e0;
                            border-left: none;
                        }
                    }
                }

                h4{
                    @include font(2.9rem, 700, #111);
                    line-height: 1;
                }

                ul{
                    margin-top: 15px;
                }

                li{
                    padding-left: 10px;
                    @include font(1.6rem, 400, #555);
                    line-height: 3.9rem;
                    position: relative;

                    @media (max-width: 767px) {
                        font-size: 1.4rem;
                        line-height: 3.7rem;
                    }

                    &:before{
                        content: '';
                        width: 2px;
                        height: 2px;
                        display: inline-block;
                        background-color: #111;
                        @include poa(20px, auto, auto, 0);

                        @media (max-width: 767px) {
                            top: 15px;
                        }
                    }
                }
            }
        }
    }
}