.demonstration{
  &-history{
    .history{
      &-list{
        &__info{
          li{
            padding-left: 130px;
          }
          
          &__date{
            width: 130px;
          }
        }
      }
    }
  }
}