.terms{
  &-tit{
    margin-bottom: 20px;
    @include font(3.6rem, 700, #003554);

    @media (max-width: 767px) {
      margin-bottom: 10px;
      font-size: 2.2rem;
    }
  }

  &-top{
    margin-bottom: 75px;
    padding: 50px;
    border: 4px solid #9c7e29;
    text-align: center;

    @media (max-width: 767px) {
      margin-bottom: 40px;
      padding: 30px 15px;
    }

    p{
      @include font(1.8rem, 500, #666);
      line-height: 2.8rem;
      margin-bottom: 20px;

      @media (max-width: 767px) {
        font-size: 1.4rem;
        line-height: 2.4rem;
      }

      &:last-child{
        margin-bottom: 0;
      }
    }
  }

  &-label{
    margin-bottom: 75px;
    text-align: center;

    @media (max-width: 767px) {
      margin-bottom: 40px;
    }
    
    &__list{
      padding: 80px 0 20px;
      border: 1px solid #ddd;
      text-align: left;

      @media (max-width: 767px) {
        padding: 40px 0 20px;
      }

      li{
        width: calc(100% / 8);
        margin-bottom: 60px;
        display: inline-block;
        text-align: center;
        vertical-align: top;

        @media (max-width: 1520px) {
          width: calc(100% / 6);
        }

        @media (max-width: 1120px) {
          width: calc(100% / 5);
        }

        @media (max-width: 991px) {
          width: calc(100% / 4);
        }

        @media (max-width: 767px) {
          width: 50%;
          margin-bottom: 20px;
        }
      }
    }

    &__img{
      width: 80px;
      height: 80px;
      border: 1px solid #003554;
      display: inline-block;
      position: relative;

      a{
        width: 100%;
        height: 100%;
        position: relative;
      }

      img{
        @include poa-center;
      }
    }

    &__text{
      margin-top: 20px;
      @include font(1.6rem, 500, #666);

      @media (max-width: 991px) {
        font-size: 1.4rem;
      }

      @media (max-width: 767px) {
        font-size: 1.2rem;
      }
    }
  }

  &-info{
    &__wrap{
      margin-bottom: 65px;

      @media (max-width: 767px) {
        margin-bottom: 40px;
      }
    }

    p{
      @include font(1.8rem, 500, #666);
      line-height: 2.8rem;

      @media (max-width: 767px) {
        font-size: 1.4rem;
        line-height: 2.4rem;
      }
    }
  }

  .board-wrap{
    &.ofa{
      table{
        @media (max-width: 767px) {
          width: 600px;
          min-width: 0;
        }
      }
    }

    th{
      height: 40px;
      @include font(2rem, 500, #333);
      line-height: 3rem;

      @media (max-width: 767px) {
        font-size: 1.6rem;
        line-height: 2.6rem;
      }
    }

    td{
      padding: 10px 5px;
      @include font(1.6rem, 500, #666);
      line-height: 2.6rem;

      @media (max-width: 767px) {
        font-size: 1.4rem;
        line-height: 2.4rem;
      }
    }

    a{
      font-size: 1.6rem;
    }
  }
}