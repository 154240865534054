.en{
    &-demonstration{
        &-status{
            @media (max-width: 991px) {
                overflow-x: auto;
            }

            table{
                @media (max-width: 991px) {
                    min-width: 580px;
                }
            }
        }
    }
}