.taekwondo{
  &1{
    &-wrap{
      margin-bottom: 70px;

      @media (max-width: 991px) {
        margin-bottom: 50px;
      }

      @media (max-width: 767px) {
        margin-bottom: 30px;
      }

      p{
        margin-bottom: 20px;
        @include font(1.8rem, 500, #666);
        line-height: 1.5;

        @media (max-width: 991px) {
          font-size: 1.6rem;
        }

        &:last-of-type{
          margin-bottom: 0;
        }
      }

      img{
        display: block;
        margin: 70px auto 0;

        @media (max-width: 991px) {
          margin-top: 50px;
        }

        @media (max-width: 767px) {
          margin-top: 30px;
        }
      }
    }
  }

  &2{
    &-top{
      margin-bottom: 80px;
      padding: 50px 55px;
      border: 4px solid #9c7e29;

      @media (max-width: 1650px) {
        padding: 40px;
      }

      @media (max-width: 1260px) {
        padding: 30px;
      }

      @media (max-width: 991px) {
        padding: 30px 15px;
      }
      
      @media (max-width: 767px) {
        margin-bottom: 50px;
      }

      p{
        margin-bottom: 30px;
        @include font(2rem, 500, #666);
        line-height: 3rem;

        @media (max-width: 1650px) {
          font-size: 1.8rem;
          line-height: 2.8rem;
        }

        @media (max-width: 991px) {
          font-size: 1.6rem;
          line-height: 2.6rem;
        }

        &:last-child{
          margin-bottom: 0;
        }
      }
    }

    &-wrap{
      li{
        width: calc((100% - 160px) / 3);
        margin: 0 80px 60px 0;
        border-top: 2px solid #222;
        border-bottom: 1px solid #222;
        padding-bottom: 30px;
        display: inline-block;
        vertical-align: top;

        @media (max-width: 1650px) {
          width: calc((100% - 80px) / 3);
          margin-right: 40px;
        }

        @media (max-width: 1420px) {
          width: calc((100% - 40px) / 3);
          margin: 0 20px 30px 0;
        }

        @media (max-width: 991px) {
          width: calc(50% - 10px);
        }

        @media (max-width: 767px) {
          width: 100%;
          margin-right: 0;
        }

        &:nth-child(3n){
          margin-right: 0;

          @media (max-width: 991px) {
            margin-right: 20px;
          }

          @media (max-width: 767px) {
            margin-right: 0;
          }
        }

        &:nth-child(2n){
          @media (max-width: 991px) {
            margin-right: 0;
          }
        }
      }
    }

    &-tit{
      padding: 35px 40px;

      @media (max-width: 1260px) {
        padding: 30px;
      }

      @media (max-width: 991px) {
        padding: 15px;
      }

      h4{
        margin-bottom: 5px;
        @include font(2.4rem, 700, #222);

        @media (max-width: 1260px) {
          font-size: 2.2rem;
        }

        @media (max-width: 767px) {
          font-size: 2rem;
        }
      }

      p{
        @include font(1.8rem, 700, #222);
        line-height: 2.8rem;

        @media (max-width: 1650px) {
          font-size: 1.6rem;
          line-height: 2.6rem;
        }

        @media (max-width: 991px) {
          font-size: 1.4rem;
          line-height: 2.4rem;
        }
      }
    }

    &-text{
      padding: 35px 40px;
      background-color: #f9f9f9;

      @media (max-width: 1260px) {
        padding: 30px;
      }
      
      @media (max-width: 991px) {
        padding: 15px;
      }

      @media (max-width: 767px) {
        height: auto!important;
      }

      p{
        @include font(1.8rem, 500, #666);
        line-height: 2.8rem;

        @media (max-width: 1650px) {
          font-size: 1.6rem;
          line-height: 2.6rem;
        }

        @media (max-width: 991px) {
          font-size: 1.4rem;
          line-height: 2.4rem;
        }
      }
    }
  }

  &3{
    &-wrap{
      margin-bottom: 70px;

      @media (max-width: 991px) {
        margin-bottom: 50px;
      }

      @media (max-width: 767px) {
        margin-bottom: 30px;
      }

      p{
        margin-bottom: 20px;
        @include font(1.8rem, 500, #666);
        line-height: 1.5;

        @media (max-width: 991px) {
          font-size: 1.6rem;
        }

        &:last-of-type{
          margin-bottom: 0;
        }
      }

      img{
        display: block;
        margin: 70px auto 0;

        @media (max-width: 991px) {
          margin-top: 50px;
        }

        @media (max-width: 767px) {
          margin-top: 30px;
        }
      }
    }

    &-text{
      li{
        width: calc((100% - 110px) / 2);
        margin-right: 110px;
        padding: 30px 0 80px;
        border-top: 1px solid #222;
        display: inline-block;
        vertical-align: top;

        @media (max-width: 1260px) {
          width: calc((100% - 50px) / 2);
          margin-right: 50px;
        }

        @media (max-width: 767px) {
          width: 100%;
          margin-right: 0;
          padding: 30px 0;
        }

        &:nth-child(2n){
          margin-right: 0;
        }
      }

      &__num{
        width: 75px;
        display: inline-block;
        vertical-align: top;
        text-align: center;

        h4{
          width: 30px;
          height: 30px;
          border-radius: 30px;
          display: inline-block;
          background-color: #f1f1f1;
          position: relative;

          span{
            @include font(1.6rem, 700, #003554);
            letter-spacing: -1px;
            @include poa(auto, 0, -3px, auto);
          }
        }
      }

      &__text{
        width: calc(100% - 75px);
        display: inline-block;
        vertical-align: top;

        h3{
          margin-bottom: 10px;
          @include font(2.4rem, 700, #222);

          @media (max-width: 767px) {
            font-size: 2.2rem;
          }
        }

        p{
          @include font(1.8rem, 500, #666);
          line-height: 2.8rem;

          @media (max-width: 991px) {
            font-size: 1.6rem;
            line-height: 2.6rem;
          }

          br{
            @media (max-width: 1650px) {
              display: none;
            }
          }
        }
      }
    }
  }
}