.location{
  &-map{
    height: 570px;
    margin-bottom: 70px;
    position: relative;
    z-index: 0;

    @media (max-width: 991px) {
      height: 300px;
    }

    @media (max-width: 767px) {
      height: 150px;
      margin-bottom: 35px;
    }

    .wrap_map{
      height: 100%;
    }

    .root_daum_roughmap{
      width: 100%;
      height: 100%;

      .wrap_map{
        height: 100%;
      }
    }
  }

  &-info{
    &__contact{
      margin-bottom: 40px;
      padding: 40px 0;
      border-top: 2px solid #9c7e29;
      border-bottom: 1px solid #ddd;

      @media (max-width: 767px) {
        padding: 20px 0;
      }

      li{
        display: inline-block;
        vertical-align: middle;

        @media (max-width: 991px) {
          width: 100%;
          margin-bottom: 5px;
        }

        &:after{
          content: '';
          width: 1px;
          height: 16px;
          margin: 0 60px;
          display: inline-block;
          background-color: #ddd;
          position: relative;
          top: 8px;

          @media (max-width: 1650px) {
            margin: 0 40px;
          }

          @media (max-width: 1420px) {
            margin: 0 20px;
            top: 9px;
          }

          @media (max-width: 1260px) {
            margin: 0 10px;
          }

          @media (max-width: 991px) {
            display: none;
          }
        }

        &:nth-child(3){
          &:after{
            display: none;
          }
        }

        &:last-child{
          width: 100%;
          margin-top: 20px;

          @media (max-width: 1260px) {
            margin-top: 10px;
          }

          @media (max-width: 991px) {
            margin-top: 0;
            margin-bottom: 0;
          }

          &:after{
            display: none;
          }
        }

        img{
          margin-right: 20px;
          vertical-align: middle;

          @media (max-width: 1650px) {
            margin-right: 10px;
          }

          @media (max-width: 1260px) {
            width: 30px;
          }
        }

        b{
          margin-right: 20px;
          display: inline-block;
          @include font(1.8rem, 500, #222)
          vertical-align: middle;

          @media (max-width: 1650px) {
            margin-right: 10px;
          }

          @media (max-width: 1420px) {
            font-size: 1.6rem;
          }

          @media (max-width: 1260px) {
            font-size: 1.2rem;
          }

          @media (max-width: 991px) {
            font-size: 1.4rem;
          }
        }

        p{
          display: inline-block;
          @include font(1.8rem, 500, #666);
          vertical-align: middle;

          @media (max-width: 1420px) {
            font-size: 1.6rem;
          }

          @media (max-width: 1260px) {
            font-size: 1.2rem;
          }

          @media (max-width: 991px) {
            font-size: 1.4rem;
          }

          @media (max-width: 767px) {
            width: 100%;
          }
        }
      }
    }

    &__route{
      border-top: 2px solid #9c7e29;

      li{
        background-color: #f9f9f9;
        border-bottom: 1px solid #ddd;
      }

      &__img{
        width: 270px;
        position: relative;
        display: inline-block;
        vertical-align: middle;

        @media (max-width: 991px) {
          width: 150px;
        }

        @media (max-width: 767px) {
          width: 100%;
        }
        
        div{
          width: 100%;
          text-align: center;
          @include middle;

          @media (max-width: 767px) {
            padding: 15px 0;
            @include po-cancel;
          }
        }

        p{
          margin-top: 10px;
          @include font(1.8rem, 500, #222);

          @media (max-width: 1420px) {
            font-size: 1.6rem;
          }

          @media (max-width: 1150px) {
            font-size: 1.4rem;
          }
        }
      }

      &__text{
        width: calc(100% - 270px);
        height: 220px;
        border-left: 1px solid #ddd;
        display: inline-block;
        background-color: #fff;
        vertical-align: middle;
        position: relative;

        @media (max-width: 1150px) {
          height: 200px;
        }

        @media (max-width: 991px) {
          width: calc(100% - 150px);
        }

        @media (max-width: 767px) {
          width: 100%;
          height: auto;
          border-left: none;
          border-top: 1px solid #ddd;
        }

        div{
          width: 100%;
          padding: 0 60px;
          @include middle;

          @media (max-width: 991px) {
            padding: 0 30px;
          }

          @media (max-width: 767px) {
            padding: 30px 15px;
            @include po-cancel;
          }
        }

        p{
          margin-bottom: 5px;
          @include font(1.8rem, 500, #222);

          @media (max-width: 1420px) {
            font-size: 1.6rem;
          }

          @media (max-width: 1150px) {
            font-size: 1.4rem;
          }

          &:last-child{
            margin-bottom: 0;
          }

          span{
            &.c-g{
              color: #278131;
            }

            &.c-y{
              color: #8D6F22;
            }
          }
        }

        ul{
          margin-top: 15px;
        }

        li{
          margin-bottom: 5px;
          padding-left: 40px;
          border-bottom: none;
          background-color: transparent;
          @include font(1.6rem, 500, #222);
          position: relative;

          @media (max-width: 1150px) {
            font-size: 1.4rem;
          }

          span{
            width: 30px;
            display: inline-block;
            @include font(1.4rem, 500, #fff);
            text-align: center;
            line-height: 1.9rem;
            @include poa(4px, auto, auto, 0);

            @media (max-width: 1150px) {
              top: 2px;
            }
          }

          &:nth-child(1){
            span{background-color: #3353a2;}
          }

          &:nth-child(2){
            span{background-color: #48922d;}
          }

          &:nth-child(3){
            span{background-color: #c91017;}
          }

          &:nth-child(4){
            span{background-color: #70afdf; color: #111;}
          }
        }
      }
    }
  }
}